import { useEffect, useState } from 'react'
import BlueTick from 'assets/icons/product/BlueTick'
import {
  useNavigate,
  Route,
  Routes,
  useParams,
  To,
  useLocation,
} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { scaleMotionProps2 } from 'utils/FramerProps'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import useTabTitle from 'libs/hooks/useTabTitle.ts'
import BasicDetails from './tabs/BasicDetails'
import AssignedOperators from './tabs/AssignedOperators'
import PartsDetails from './tabs/PartsDetails'
import Documents from './tabs/Documents'
import {
  useCreateMachineMutation,
  useGetMachineByIDQuery,
  useUpdateMachineMutation,
} from '../machine-endpoints/machine.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { toast } from 'libs/toast'
import { useMultipleFileUploadMutation } from 'store/services/utils.endpoints'
import FullPageLoader from 'libs/loader/FullPageLoader'

export interface PlanForm {
  planID: string
  description: string
  startDate: number | undefined
  endDate: number | undefined
  status: string | undefined
  manufactureOrders: string[]
  scheduledDate: string[]
}

const steps = [
  { label: 'Basic Details', path: 'basic-details' },
  { label: 'Assigned Operators', path: 'assigned-operators' },
  { label: 'Parts Details', path: 'parts-details' },
  { label: 'Documents', path: 'documents' },
]

const AddEditMachineWizard = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const location = useLocation()
  // Access the state object
  const { from } = location.state || {}

  const [addMachine, { isLoading: addMachineLoading }] =
    useCreateMachineMutation()
  const [uploadMachineDocs, { isLoading: uploadLoading }] =
    useMultipleFileUploadMutation()

  const [updateMachine, { isLoading: updateMachineLoading }] =
    useUpdateMachineMutation()

  const { data: singleMachineData, isLoading: machineLoading } =
    useGetMachineByIDQuery(
      { plantId: plant?.id, machineID: id },
      {
        skip: !plant?.id || !id,
      },
    )

  const [machineId, setMachineId] = useState(id ?? null)

  useTabTitle(machineId ? `Update Machine` : 'Add Machine')

  const [tabNumber, setTabNumber] = useState<number>(1)

  const machineFormik = useFormik<any>({
    initialValues: {
      basicDetails: {},
      documents: [],
      operators: [],
      partsDetails: [],
    },
    validationSchema: Yup.object().shape({}),

    onSubmit: async (values) => {
      // ----------------------------------- upload document ------------------
      const files = [] as any
      const descriptions = [] as any
      const fileName = [] as any
      let documents = [] as any
      let documentFlag = false
      if (values?.documents) {
        values.documents.forEach((item: any) => {
          if (item.document && item.description) {
            // in edit condition
            if (item.document?.url) {
              documents.push({
                description: item.description,
                url: item.document.url,
                fileName: item.document.name,
              })
            } else {
              files.push(item.document)

              descriptions.push(item.description)
              fileName.push(item.document.name)
            }
          } else {
            // Show a toast error if item is undefined
            toast.error(
              `Please fill in all required fields, or remove incomplete data.`,
            )
            documentFlag = true
            return
          }
        })
        if (documentFlag) return
        if (files.length) {
          try {
            // Upload the files with machine name
            const res = await uploadMachineDocs({
              files: files,
              fieldName: values?.basicDetails?.name,
            }).unwrap()

            // Ensure the description and file URLs are correctly paired
            let response = res.uploadedFiles.map((file: any, index: number) => {
              return {
                description: descriptions[index],
                url: file.url, // File URL returned from the upload response
                fileName: fileName[index],
              }
            })
            documents = [...documents, ...response]
          } catch (error: any) {
            if (error?.data)
              return toast.error(
                <div>
                  Failed to upload <b>documents</b>. {error?.data?.detail}
                </div>,
              )
          }
        }
      }

      // ------------ handle operatos values -------------------
      let operators =
        (values?.operators &&
          values?.operators.length &&
          values?.operators?.map((item: any) => item.machineManager._id)) ||
        []

      const body = {
        name: values?.basicDetails?.name,
        serialNumber: values?.basicDetails?.serialNumber,
        machineClassID: values?.basicDetails?.machineClassID,
        modelNumber: values?.basicDetails?.modelNumber,
        purchaseDate: values?.basicDetails?.purchaseDate,
        status: values?.basicDetails?.status,
        workCenterID: values?.basicDetails?.workCenterID || null,
        operators: operators,
        partsDetails: values?.partsDetails,
        documents: documents,
      } as any

      // ----------------------- create machine -----------------------------
      if (!machineId)
        addMachine({ plantId: plant.id, body })
          .unwrap()
          .then(() => {
            toast.success(
              <div>
                The Machine “<b>{values.basicDetails.name}</b>” is added
                successfully.
              </div>,
            )
            navigate('/maintenance/machines')
          })
          .catch((res) =>
            toast.error(
              <div>
                Failed to add “<b>{values.basicDetails.name}</b>” Machine.{' '}
                {res.data?.detail}.
              </div>,
            ),
          )
      // ----------------------- update machine -----------------------------
      else
        updateMachine({ machineID: machineId, plantId: plant.id, body })
          .unwrap()
          .then(() => {
            toast.success(
              <div>
                The Machine “<b>{values.basicDetails.name}</b>” is edited
                successfully.
              </div>,
            )
            {
              from?.pathname.includes('view')
                ? navigate(-1)
                : navigate('/maintenance/machines')
            }
          })
          .catch((res) =>
            toast.error(
              <div>
                Failed to update “<b>{values.basicDetails.name}</b>” Machine.{' '}
                {res.data?.detail}.
              </div>,
            ),
          )
    },
  })

  const handleNavigation = (stepIndex: number, path: To) => {
    if (machineId) {
      setTabNumber(stepIndex + 1)
      navigate(path)
    }
  }

  useEffect(() => {
    if (singleMachineData)
      machineFormik.resetForm({
        values: {
          basicDetails: {
            name: singleMachineData?.name || '',
            serialNumber: singleMachineData?.serialNumber || '',
            machineClassID: singleMachineData?.machineClass?._id || '',
            modelNumber: singleMachineData?.machineClass?.models?._id || '',
            status: singleMachineData?.status || '',
            workCenterID: singleMachineData?.workCenter?._id || '',
            purchaseDate: singleMachineData?.purchaseDate || null,
          },
          operators: singleMachineData.operators.map((item: any) => ({
            machineManager: {
              _id: item._id,
            },
          })),
          documents: singleMachineData?.documents?.map((item: any) => ({
            description: item.description,
            document: {
              name: item.fileName,
              description: item.description,
              url: item.url,
            },
          })),
          partsDetails: singleMachineData?.partsDetails,
        },
      })
  }, [singleMachineData])

  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2}>
        <div className="flex justify-between">
          <div className="flex items-baseline gap-2 ">
            <h2 className="mb-2 max-w-[500px] truncate h4-bold-black">
              {machineId ? 'Edit Machine' : 'Add New Machine'}
            </h2>
          </div>
          <div className="mt-4 flex items-baseline gap-1 p-md-regular">
            <div>{tabNumber === 2 ? 'Last updated :' : null}</div>
            <div>{tabNumber === 2 ? 'Just now' : null}</div>
          </div>
        </div>
        <div className="flex min-h-[65vh] w-full rounded  bg-[#FFFFFF] p-6 ">
          <div className="relative w-1/4 border-r border-[#D2DADF] p-2">
            {steps.map((step, index) => (
              <div key={index}>
                <button
                  onClick={() => handleNavigation(index, step.path)}
                  className="flex items-center"
                >
                  <div
                    className={`h-6 w-6 rounded-3xl border p-2 ${
                      tabNumber === index + 1
                        ? 'border-primary '
                        : 'border-[#B9C4CC]'
                    } ${
                      tabNumber > index + 1
                        ? 'border-primary bg-primary'
                        : 'border-[#B9C4CC]'
                    } flex items-center justify-center`}
                  >
                    <div className={`text-xs font-bold  `}>
                      {tabNumber > index + 1 ? <BlueTick /> : index + 1}
                    </div>
                  </div>
                  <p
                    className={`whitespace-nowrap rounded px-2 text-sm ${tabNumber == index + 1 ? 'font-bold' : ''} ${!machineId ? ' cursor-not-allowed' : ''}`}
                  >
                    {step.label}
                  </p>
                </button>
                {index < steps.length - 1 && (
                  <span
                    className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                      tabNumber > index + 1
                        ? 'border-primary'
                        : 'border-[#B9C4CC]'
                    }`}
                  ></span>
                )}
              </div>
            ))}
          </div>
          {!machineLoading ? (
            <div className="ml-2  flex w-full " style={{ overflowX: 'auto' }}>
              <Routes>
                <Route
                  path="basic-details"
                  element={
                    <BasicDetails
                      setId={setMachineId}
                      setTabNumber={setTabNumber}
                      isLoading={
                        addMachineLoading ||
                        uploadLoading ||
                        updateMachineLoading
                      }
                      formik={machineFormik}
                    />
                  }
                />
                <Route
                  path="assigned-operators"
                  element={
                    <AssignedOperators
                      setId={setMachineId}
                      setTabNumber={setTabNumber}
                      isLoading={
                        addMachineLoading ||
                        uploadLoading ||
                        updateMachineLoading
                      }
                      formik={machineFormik}
                    />
                  }
                />
                <Route
                  path="parts-details"
                  element={
                    <PartsDetails
                      setId={setMachineId}
                      setTabNumber={setTabNumber}
                      isLoading={
                        addMachineLoading ||
                        uploadLoading ||
                        updateMachineLoading
                      }
                      formik={machineFormik}
                    />
                  }
                />
                <Route
                  path="documents"
                  element={
                    <Documents
                      setId={setMachineId}
                      setTabNumber={setTabNumber}
                      isLoading={
                        addMachineLoading ||
                        uploadLoading ||
                        updateMachineLoading
                      }
                      formik={machineFormik}
                    />
                  }
                />
              </Routes>
            </div>
          ) : (
            <FullPageLoader />
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default AddEditMachineWizard
