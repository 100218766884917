import Button from '../../../libs/button/Button'
import { useUpdateWorkOrderStatusMutation } from '../work-orders-endpoints/workorders.endpoints'

import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  workOrder: any
  plantId: string
  quantityProduced: any
}

function CompleteWorkOrder({ closePopUp, workOrder, plantId, quantityProduced }: Props) {
  const [updateWorkOrder, { isLoading: updateWorkOrderLoading }] = useUpdateWorkOrderStatusMutation()

  const navigate = useNavigate()

  const handleUpdateWorkOrder = () => {
    updateWorkOrder({ plantId, woID: workOrder?._id, status: 'COMPLETED' })
      .unwrap()
      .then(() => {
        toast.success(`Work Order “${workOrder?.workOrderID}” is completed successfully.`)
        navigate(-1)
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(
          error?.data?.detail ?? `Failed to complete Work Order “${workOrder?.workOrderID}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to mark the work order{' '}
        <span className="break-words pr-1 p-xs-bold">“{workOrder?.workOrderID}”</span>
        `as complete. This Work Order is{' '}
        {parseFloat(quantityProduced.find((item: any) => item?.name === 'Completed')?.value?.toFixed(0)) ?? 0}%
        complete. {parseFloat(quantityProduced.find((item: any) => item?.name === 'Remaining')?.value?.toFixed(0)) ?? 0}
        % of the work remains. Are you sure you want to proceed?`
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Cancel</Button>
        <Button color="primary" onClick={handleUpdateWorkOrder} loading={updateWorkOrderLoading}>
          Mark as completed
        </Button>
      </div>
    </div>
  )
}

export default CompleteWorkOrder
