const ProgressBar = ({ percentage, title }: any) => {
  let className = ''
  switch (true) {
    case title == 'CANCELLED':
      className = ' bg-pink-150' // CANCELLED
      break
    case title === 'NOT_STARTED':
      className = 'bg-gray-200' // NOT_STARTED
      break
    case title === 'IN_PROGRESS':
      className = 'bg-warning-150' // IN_PROGRESS
      break
    case title === 'COMPLETED':
      className = 'bg-success-150' // COMPLETED
      break
    default:
      className = 'bg-primary-500' // Default case
  }

  return (
    <div className="relative my-2 flex h-5 w-full items-center overflow-hidden rounded-[4px] bg-gray-200">
      <div className={`${className} h-full`} style={{ width: `${percentage === null || 0 ? 100 : percentage}%` }}></div>
      <div className="absolute right-0 pr-2 text-black">{percentage != null ? `${percentage}%` : percentage}</div>
    </div>
  )
}

export default ProgressBar
