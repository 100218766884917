function ExcelIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <mask
        id="mask0_1573_11877"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_1573_11877)">
        <path
          fill="#75838D"
          d="M2.25 14.25V3.75c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44h10.5c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v10.5c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44H3.75c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 01-.44-1.06zm7.5-4.5v4.5h4.5v-4.5h-4.5zm0-1.5h4.5v-4.5h-4.5v4.5zm-1.5 0v-4.5h-4.5v4.5h4.5zm0 1.5h-4.5v4.5h4.5v-4.5z"
        ></path>
      </g>
    </svg>
  )
}

export default ExcelIcon
