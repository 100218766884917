import { LightLogo } from 'assets/icons'
import { Outlet, useNavigate } from 'react-router-dom'
import { bg, bg2, bg3 } from 'assets/images'
import { useEffect } from 'react'

const Login = () => {
  const bgImages = [bg, bg2, bg3]

  const navigate = useNavigate()

  useEffect(() => {
    navigate('/auth/login')
  }, [])

  return (
    <div
      style={{
        background: `url(${
          bgImages[Math.floor(Math.random() * bgImages.length)]
        }), lightgray 50% center / cover no-repeat`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className="login-page min-h-screen w-full px-20 py-4"
    >
      <div className="">
        <LightLogo width={140} height={50} />
      </div>
      <div className="flex ">
        <div className="my-auto w-[55%] font-inter text-[#FDFEFF]">
          <h1 className="mb-5 text-5xl font-bold">Welcome to Catalyst</h1>
          <h3 className="mb-4 text-4xl font-bold">Bridging Precision and Productivity</h3>
          <p className="text-xl font-medium">
            Shaping the future of manufacturing through a narrative of operational distinction and strategic finesse.
          </p>
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default Login
