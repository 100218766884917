import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  CreateVendorRequestType,
  DeleteVendorRequestType,
  UpdateVendorRequestType,
  VendorMutationResponse,
  VendorRequest,
  VendorResponse,
  VendorsRequest,
  VendorsResponse,
} from './vendorTypes'
import { CountRequest, CountResponse } from 'store/services/utilsTypes'

export const vendor = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    createVendor: builder.mutation<
      VendorMutationResponse,
      CreateVendorRequestType
    >({
      query: ({ plant_id, data }) => {
        return {
          url: `/plant/${plant_id}/vendor`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['vendorUpdate', 'getSingleVendor'],
    }),
    updateVendor: builder.mutation<
      VendorMutationResponse,
      UpdateVendorRequestType
    >({
      query: ({ data, plant_id, id }) => ({
        url: `/plant/${plant_id}/vendor/${id}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['vendorUpdate', 'getSingleVendor'],
    }),
    deleteVendor: builder.mutation<
      VendorMutationResponse,
      DeleteVendorRequestType
    >({
      query: ({ plant_id, id }) => ({
        url: `/plant/${plant_id}/vendor/${id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['vendorUpdate'],
    }),
    getVendorById: builder.query<VendorResponse, VendorRequest>({
      query: ({ plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/vendor/${id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the vendor by id')
            }
            return response.json()
          },
        }
      },
      providesTags: ['purchaseOrderUpdate', 'getSingleVendor'],
    }),
    getVendors: builder.query<VendorsResponse, VendorsRequest>({
      query: ({ plant_id, filters }) => {
        return {
          url: buildUrl(`/plant/${plant_id}/vendors`, filters),
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the vendors')
            }
            return response.json()
          },
        }
      },
      providesTags: ['vendorUpdate'],
    }),
    getVendorsCount: builder.query<CountResponse, CountRequest>({
      query: ({ plant_id }) => {
        return {
          url: `/plant/${plant_id}/vendors/count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the vendors count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['vendorUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateVendorMutation,
  useUpdateVendorMutation,
  useDeleteVendorMutation,
  useGetVendorByIdQuery,
  useGetVendorsQuery,
  useGetVendorsCountQuery,
} = vendor
