import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import {
  useGetCountryQuery,
  useGetTimezoneQuery,
  usePostPlantsMutation,
} from 'pages/plants/plants-endpoints/plants.endpoints'
import * as yup from 'yup'
import { useEffect, useRef } from 'react'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface Props {
  closePanel: () => void
}

const AddPlant = ({ closePanel }: Props) => {
  const { data: userInfo } = useUserInfoQuery({})
  const isInitialRender = useRef(true)
  const [addPlant, { isLoading }] = usePostPlantsMutation()
  const { data: country } = useGetCountryQuery()

  const initialValues = {
    plantName: '',
    countryName: '',
    countryCode: '',
    state: '',
    city: '',
    area_name: '',
    zip_code: '',
    timezone: '',
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      plantName: yup.string().required('Please enter plant name'),
      countryName: yup.string().required('Please enter country name'),
      countryCode: yup.string().required('Please enter country code'),
      state: yup.string().required('Please enter state name'),
      city: yup.string().required('Please enter city name'),
      area_name: yup.string().required('Please enter street name'),
      zip_code: yup.string().required('Please enter zipcode'),
      timezone: yup.string().required('Please enter timezone'),
    }),
    onSubmit: (values) => {
      addPlant({
        org_id: userInfo?.organization?._id,
        data: {
          name: values.plantName,
          country: { countryName: values.countryName, countryCode: values.countryCode },
          state: values.state,
          city: values.city,
          area_name: values.area_name,
          zip_code: values.zip_code,
          timezone: values.timezone,
        },
      })
        .unwrap()
        .then((data) => {
          toast.success(`New plant ${data.name} has been created successfully.`)
          closePanel()
        })
        .catch((error) => {
          if (error?.data?.detail === 'Plant already exist') {
            formik.setFieldError(
              'plantName',
              'This Plant already exists. Please choose a different name to avoid any confusions for your team.',
            )
          }

          toast.error(error?.data?.detail ?? 'Something went wrong. Please try again.')
        })
    },
  })
  // These country does not contain timezone
  const countryOptions = country
    ? Object.entries(country)
        .map(([key, value]) => ({
          label: value as string,
          value: value as string,
          sublabel: key as string,
          disabled: '',
        }))
        .filter((option) => option.sublabel !== 'BV' && option.sublabel !== 'HM')
    : []

  const timezoneFilters = {
    country_names: formik?.values.countryCode,
  }
  const { data: timezone } = useGetTimezoneQuery({ filters: timezoneFilters }, { skip: !formik.values.countryCode })

  const transformedTimeZones = timezone?.map((item: any) => ({
    label: item,
    value: item,
    sublabel: item,
    disabled: false,
  }))

  useEffect(() => {
    if (formik.values.countryName) {
      const selectedCountry = countryOptions.find((option) => option.label === formik.values.countryName)
      if (selectedCountry) {
        formik.setFieldValue('countryCode', selectedCountry.sublabel)
      }
    }
  }, [formik.values.countryName])
  //  on changing country made timezone null
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      formik.setFieldValue('timezone', null)
    }
  }, [formik?.values?.countryName])

  return (
    <>
      <div className="-mt-3 flex flex-col">
        <Field
          type="text"
          label="Plant name or code"
          formik={formik}
          required={true}
          placeholder="Enter a name or code to identify the plant"
          name="plantName"
        />
        <Field
          type="select"
          label="Country"
          placeholder="Enter country name"
          itemType="status"
          required={true}
          options={countryOptions}
          formik={formik}
          name="countryName"
        />
        <Field
          type="text"
          label="State / Province"
          required={true}
          formik={formik}
          placeholder="Enter state name"
          name="state"
        />
        <Field
          type="text"
          label="City / Town"
          required={true}
          formik={formik}
          placeholder="Enter city/town name"
          name="city"
        />
        <Field
          type="text"
          label="Street/Area name"
          required={true}
          formik={formik}
          placeholder="Enter street/area name"
          name="area_name"
        />
        <Field
          type="text"
          label="Zip code"
          formik={formik}
          placeholder="Enter zip code"
          required={true}
          name="zip_code"
        />
        <Field
          type="select"
          label="Timezone"
          placeholder="Select timezone"
          itemType="timezone"
          required={true}
          options={transformedTimeZones}
          disabled={formik.values.countryCode === ''}
          formik={formik}
          name="timezone"
        />
      </div>
      <div className="flex justify-end gap-2 md:mt-2 2xl:mt-4">
        <Button onClick={closePanel}>Cancel</Button>
        <Button
          color="primary"
          loading={isLoading}
          type="submit"
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          Add
        </Button>
      </div>
    </>
  )
}

export default AddPlant
