import PaginatedTable from 'libs/table/PaginatedTable'
import { useMemo } from 'react'
import { formatNumber } from 'utils/numberHandlers'
import { ProductResponse } from '../sales-order-endpoints/salesOrdersTypes'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { CellProps } from 'react-table'

interface Props {
  isOpen: boolean
  closePanel: () => void
  products: ProductResponse[]
  salesOrderID: string
}

const ViewSOProducts = ({ products, salesOrderID }: Props) => {
  const columns: ExtendedColumn<ProductResponse>[] = useMemo(
    () => [
      {
        Header: 'Products',
        accessor: 'name',
        width: 100,
        Cell: ({ row }: CellProps<ProductResponse>) => {
          return (
            <div className="">
              <div className="p-xs-regular">{row.original?.name ?? '-'}</div>
              <div className="p-xs-regular-dim">
                {row.original?.code ?? '-'}
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Quantity',
        accessor: 'quantity',
        align: 'right',
        Cell: ({ row }: CellProps<ProductResponse>) => (
          <span className="font-mono">
            {row.original.quantity != null
              ? formatNumber(row.original.quantity)
              : '-'}
          </span>
        ),
        disableSortBy: true,
        width: 60,
      },
      {
        Header: 'Unit of Measure',
        accessor: 'unit_of_measure',
        disableSortBy: true,
        width: 50,
      },
    ],
    [],
  )

  const categoryCounts = products.reduce(
    (acc: Record<string, number>, product: ProductResponse) => {
      acc[product.category] = (acc[product.category] || 0) + 1
      return acc
    },
    {},
  )

  // Format as "3 Finished Goods, 1 Raw Material"
  const formattedCategories = Object.entries(categoryCounts)
    .map(([category, count]) => `${count} ${category}`)
    .join(', ')

  return (
    <div>
      <div className="flex flex-col gap-2 rounded bg-gray-100 p-3">
        <div className="flex justify-between">
          <div className=" p-xs-regular-dim">Sales Order ID</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{salesOrderID}</div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="p-xs-regular-dim">Product Category</div>
          <div>
            <div className="w-60 p-xs-regular">
              {formattedCategories ?? '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <PaginatedTable
          columns={columns}
          emptyMessage={
            "You don't have any Products added for this Sales Order"
          }
          rows={products}
          hidePagination={true}
        />
      </div>
    </div>
  )
}

export default ViewSOProducts
