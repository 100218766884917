import Button from 'libs/button/Button'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import Field from 'libs/field'
import { Designations } from '../employee-departments-endpoints/employeedepartmentTypes'

import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'
import { useDeleteEmployeeDepartmentMutation } from '../employee-departments-endpoints/employeesdepartment.endpoints'

interface Props {
  closePopUp: () => void
  data: any
}

function DeleteEmployeeDepartment({ closePopUp, data }: Props) {
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [deleteEmployeeDepartment, { isLoading }] = useDeleteEmployeeDepartmentMutation()
  const EmployeeDesignationsFormik = useFormik({
    initialValues: {
      departmentName: '',
    },
    onSubmit: () => {
      deleteEmployeeDepartment({ plant_id: plant?.id, departmentID: data?._id })
        .unwrap()
        .then(() => {
          toast.success(`The department “${data?.name}” is removed successfully.`)
          closePopUp()
          EmployeeDesignationsFormik?.resetForm()
          navigate('/employee/employee-department')
        })
        .catch((error: any) => {
          toast.error(error?.data?.detail ?? `Failed to remove “${data?.name}” department. Please try again.`)
        })
    },
  })

  return (
    <div>
      <p className="p-xs-regular">
        Are you sure you want to delete form the <span className="break-words pr-1 p-xs-bold">“{data?.name}”.</span>
        {`department? It has following ${data?.designations?.length} Designations`}.
      </p>
      <div className="mb-2 mt-2 flex">
        {data?.designations?.map((item: Designations, index: number) => (
          <div key={index} className="mb-1 mr-1 rounded-[28px] bg-[#F2F2F2] px-2 py-0.5 text-[#434C52] p-xs-regular">
            {item?.name}
          </div>
        ))}
      </div>
      <p className="p-xs-regular">
        Your historical data related to this department will remain unaffected. However, to recover any data related to
        this department, please contact our tech support at support@catalystdomainname.
      </p>

      <div>
        <div>
          <Field
            type="text"
            required={true}
            label={'Department Name'}
            formik={EmployeeDesignationsFormik}
            name="departmentName"
            placeholder={'Please enter department name to confirm delete.'}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          onClick={() => {
            closePopUp()
            EmployeeDesignationsFormik.resetForm()
          }}
        >
          Close
        </Button>
        <Button
          disabled={EmployeeDesignationsFormik.values.departmentName !== data?.name}
          onClick={() => {
            EmployeeDesignationsFormik.handleSubmit()
          }}
          loading={isLoading}
          color="error"
        >
          {' '}
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteEmployeeDepartment
