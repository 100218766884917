import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  durationBetween: number[]
  createdBetween: any[]
  updatedBetween: any[]
  status: string
}

interface FormValues {
  durationBetween: number[]
  createdBetween: number[]
  updatedBetween: number[]
  status: any
}

const FilterProductionPlan = ({ closePanel, setFilters, filters }: Props) => {
  // ************************************************** Initial Form Value ***********************************//
  const initialFormValues: FormValues = {
    durationBetween: filters?.durationBetween?.length == 0 ? [] : filters?.durationBetween,
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
    status: filters.status ?? [],
  }

  // ********************************************** Reset Form Data ****************************************//
  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      createdBetween: [],
      updatedBetween: [],
      durationBetween: [],
      status: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  // **********************************************Form Update ***********************************************//
  const filterFormik = useFormik<FormValues>({
    initialValues: initialFormValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        durationBetween: values.durationBetween == null ? [] : values.durationBetween,
        createdBetween: values.durationBetween == null ? [] : values.createdBetween,
        updatedBetween: values.durationBetween == null ? [] : values.updatedBetween,
        status: values.status,
      }))
    },
  })

  // ***************************************** Checkbox Options ***************************************//
  const verificaton = [
    {
      name: 'In Progress',
      value: 'IN_PROGRESS',
      _id: '101',
    },
    {
      name: 'Not Started',
      value: 'NOT_STARTED',
      _id: '102',
    },
    {
      name: 'Completed',
      value: 'COMPLETED',
      _id: '104',
    },
  ]

  const verificatonOptions = verificaton.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Status */}
        <div>
          <Field
            type="multiselect"
            label="Status"
            placeholder="Select status"
            multiSelectPlaceholderTexts={['Category', 'Categories']}
            options={verificatonOptions}
            formik={filterFormik}
            name="status"
          />
        </div>
        <Field type="dateRange" fullWidth label="Duration range" formik={filterFormik} name="durationBetween" />
        <Field type="dateRange" fullWidth label="Created Between" formik={filterFormik} name="createdBetween" />
        <Field type="dateRange" fullWidth label="Last Edited" formik={filterFormik} name="updatedBetween" />
        {/* dead code */}
        <div className="flex justify-between pt-5 ">
          <div>
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset
            </Button>
          </div>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>

            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterProductionPlan
