import { useDebouncedSearch } from 'libs/hooks/useSearch'
import SearchBox from 'libs/searchbox/SearchBox'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useGetWorkOrdersQuery } from 'pages/work-orders/work-orders-endpoints/workorders.endpoints'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import ProgressBar from '../../../../libs/charts/ProgressBar'
import { formatString } from 'utils/stringHandlers'
import WhiteCard from 'libs/card/WhiteCard'
import { hasAccess } from 'utils/utils'

interface filters {
  page_no: number
  page_size: number
  searchText: string
  createdBetween: number[]
  sortBy: string
  sortDir: any
}

const ReportTable = ({ formik }: any) => {
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const workorderViewAccess = hasAccess('pla', { wo: 'v' })
  const navigate = useNavigate()
  const [key, setKey] = useState(0)
  const plant = useSelector(selectSelectedItem)
  const [filters, setFilters] = useState<filters>({
    page_no: 1,
    page_size: 10,
    searchText: '',
    createdBetween:
      formik?.values?.dataRange?.length > 0
        ? [formik?.values?.dateRange[0], formik?.values?.dateRange[1]]
        : [],
    sortBy: 'updated_on',
    sortDir: 'DESC',
  })

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      createdBetween:
        formik?.values?.dateRange?.length > 0
          ? [formik?.values?.dateRange[0], formik?.values?.dateRange[1]]
          : [],
    }))
  }, [formik.values.dateRange])

  const {
    data: workOrder,
    isLoading: isLoadingWorkOrder,
    isFetching: isFetchingWorkOrder,
  } = useGetWorkOrdersQuery(
    {
      plantId: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  //search
  useEffect(() => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  const handlePageChange = (page_no: number) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: page_no,
    }))
    setKey((prevKey) => prevKey + 1)
  }
  // handle sort for column
  const handleSort = (columnName: string) => {
    setFilters((prevFilters: any) => {
      const newSortDir =
        prevFilters?.sortBy === columnName
          ? prevFilters?.sortDir === 'ASC'
            ? 'DESC'
            : 'ASC'
          : 'ASC'
      return {
        ...prevFilters,
        sortBy: columnName,
        sortDir: newSortDir,
      }
    })
  }

  const handlePageSizeChange = (newPageSize: number) => {
    const totalItemsCount = workOrder?.paging?.total_items_count
    const currentPage = filters.page_no
    const startIndex = (currentPage - 1) * filters.page_size + 1
    const totalPages = totalItemsCount
      ? Math.ceil(totalItemsCount / newPageSize)
      : 0
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'WO ID',
        accessor: 'workOrderID',
        width: '10',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <div
              className="hyperlink"
              onClick={() => {
                if (workorderViewAccess)
                  navigate(`/planning/work-orders/view/${data?._id}`)
              }}
            >
              {data?.workOrderID}
            </div>
          )
        },
      },
      {
        Header: 'MO ID',
        accessor: 'manufactureOrder',
        width: '10',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <div
              className={`${data?.manufactureOrder?.manufactureOrderID ? '' : ''}`}
              onClick={() => {
                navigate(``)
              }}
            >
              {data?.manufactureOrder?.manufactureOrderID
                ? data?.manufactureOrder?.manufactureOrderID
                : '-'}
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Operations',
        accessor: 'operations',
        width: '10',
        Cell: ({ row }: any) => {
          const operations = row?.original.operations || []
          const operationCount =
            operations.length &&
            (operations.length === 1 && !operations[0]._id
              ? 0
              : operations.length)
          const operationLabel =
            operationCount === 1 ? 'Operation' : 'Operations'
          const displayText =
            operationCount > 0 ? `${operationCount} ${operationLabel}` : '-'

          return (
            <div
              className={`${operationCount > 0 ? 'hyperlink' : ''}`}
              onClick={() =>
                operationCount > 0 &&
                workorderViewAccess &&
                navigate(`/planning/work-orders/view/${row?.original?._id}`)
              }
            >
              {operationCount > 0 ? displayText : '-'}
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Duration',
        accessor: '',
        width: '200',
        Cell: ({ row }: any) => {
          const data = row?.original
          const start = data?.startDate
            ? formatUnixDate(data.startDate, plant.timezone)
            : null
          const end = data?.endDate
            ? formatUnixDate(data.endDate, plant.timezone)
            : null

          return <>{start && end ? `${start} - ${end}` : '-'}</>
        },
      },

      {
        Header: 'Assigned To',
        accessor: 'assignedTo',
        width: '400',
        Cell: ({ row }: any) => {
          const data = row?.original
          return (
            <>
              {data?.assignedTo?.length > 0 ? (
                <div
                  className="hyperlink"
                  onClick={() => {
                    workorderViewAccess &&
                      navigate(`/planning/work-orders/view/${data?._id}`)
                  }}
                >
                  {data?.assignedTo?.length} Assignees
                </div>
              ) : (
                <div>-</div>
              )}
            </>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Progress',
        accessor: 'status',
        width: '200',
        Cell: ({ row }: any) => {
          const data = row?.original
          const progressPercentage = parseInt(
            data?.workOrderProgressPercentage || 0,
          ).toFixed(0)
          return (
            <div className="w-[100px]">
              <span>
                {data?.status === 'CANCELLED'
                  ? 'Cancelled'
                  : `${progressPercentage}% ${formatString(data?.status)}`}
              </span>

              <ProgressBar
                percentage={parseInt(progressPercentage, 10)} // Convert the string back to a number
                title={data?.status} // Provide a fallback value
              />
            </div>
          )
        },
      },
    ],
    [],
  )
  return (
    <WhiteCard>
      <div className="my-2 flex justify-between">
        <h1 className=" my-1 text-base font-bold">Work Orders</h1>
        <SearchBox
          value={inputValue}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search Work Order"
          onClearSearchBox={() => {
            setFilters((prevFilters: any) => ({
              ...prevFilters,
              searchText: '',
            }))
            handleSearch('')
          }}
        />
      </div>
      <div className="table-scroll-bar h-[99vh] overflow-x-auto overflow-y-auto  ">
        <PaginatedTable
          onPageNumberChange={handlePageChange}
          key={key}
          loading={isLoadingWorkOrder || isFetchingWorkOrder}
          columns={columns}
          sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
          rows={workOrder?.data}
          pagination={workOrder?.paging}
          onPageSizeChange={handlePageSizeChange}
          currentPageNumber={filters.page_no}
          pageSize={filters.page_size}
          emptyMessage="No Work Orders Found"
          onColumnSort={handleSort}
        />
      </div>
    </WhiteCard>
  )
}

export default ReportTable
