import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import {
  ExportInventoryParams,
  GetInventoryRequest,
  GetinventoryResponse,
} from 'pages/inventory/inventory-endpoints/inventoryTypes'
import { buildUrl } from 'utils/stringHandlers'

export const inventory = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postInventory: builder.mutation({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/inventory/transaction`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['inventoryUpdate', 'warehouseUpdate', 'productUpdate'],
    }),
    //check
    getInventory: builder.query<GetinventoryResponse, GetInventoryRequest>({
      query: (params) => {
        const { plantId, filters } = params
        const URL = buildUrl(`/plant/${plantId}/inventory`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the inventory')
            }
            return response.json()
          },
        }
      },
      providesTags: ['inventoryUpdate'],
    }),
    editInventory: builder.mutation({
      query: ({ data, plant_id, transaction_id: transactionId }) => {
        return {
          url: `/plant/${plant_id}/inventory/transaction/${transactionId}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['inventoryUpdate', 'warehouseUpdate'],
    }),
    getTransactionById: builder.query({
      query: ({ plant_id, transactionId }) => {
        return {
          url: `/plant/${plant_id}/inventory/transaction/${transactionId}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the transaction ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['inventoryUpdate'],
    }),
    getInventoryById: builder.query({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(
          `/plant/${plantId}/inventory/transactions`,
          filters,
        )
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the inventory transaction ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['INVENTORY_TRANSACTIONS', 'inventoryUpdate'],
    }),
    getInventoryQuantityById: builder.query({
      query: ({ plant_id, inventoryId }) => {
        return {
          url: `/plant/${plant_id}/inventory/single-inventory/${inventoryId}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the inventory quantity ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['inventoryUpdate'],
    }),
    getReportingGraphById: builder.query({
      query: ({ plant_id, inventory_id, ...params }) => {
        return {
          url: `/plant/${plant_id}/inventory/reporting-graph/product/${inventory_id}`,
          method: 'GET',
          headers: getToken(),
          params: params,
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the reporting graph')
            }
            return response.json()
          },
        }
      },
      providesTags: ['inventoryUpdate'],
    }),
    deleteInventory: builder.mutation({
      query: ({ plant_id, id: transactionId }) => {
        return {
          url: `/plant/${plant_id}/inventory/transaction/${transactionId}`,
          method: 'DELETE',
          headers: getToken(),
        }
      },
      invalidatesTags: ['inventoryUpdate', 'INVENTORY_TRANSACTIONS'],
    }),
    exportInventory: builder.query<any, ExportInventoryParams>({
      query: (params) => {
        return {
          url: `/plant/${params.plantId}/inventory/export`,
          method: 'GET',
          headers: getToken(),
          params,
          responseHandler: async (response) => {
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            return url
          },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostInventoryMutation,
  useGetInventoryQuery,
  useGetInventoryByIdQuery,
  useDeleteInventoryMutation,
  useGetInventoryQuantityByIdQuery,
  useEditInventoryMutation,
  useGetTransactionByIdQuery,
  useLazyExportInventoryQuery,
  useGetReportingGraphByIdQuery,
} = inventory
