import { useEditDepartmentMutation } from 'pages/department/department-endpoints/department.endpoints'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'libs/toast'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { trimStringValues } from 'utils/stringHandlers'

type Props = {
  department: any
  handleDelete: (department: any) => void
  close: () => void
}

const UpdateDepartment = ({ department, handleDelete, close }: Props) => {
  const [EditDepartment, { isLoading }] = useEditDepartmentMutation()

  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      name: department.name,
      description: department.description,
    },
    validationSchema: yup.object({
      name: yup.string().required(t('departments.department_validation')),
      description: yup.string(),
    }),
    onSubmit: (values: any) => {
      let modifiedData = {} as any
      for (const key in values) {
        if (values.hasOwnProperty(key) && values[key] !== department[key]) {
          modifiedData[key] = values[key]
        }
      }

      const data = trimStringValues(values)

      if (Object.keys(modifiedData).length) {
        EditDepartment({
          departmentId: department?._id,
          data: data,
        })
          .unwrap()
          .then(() => {
            toast.success(
              `The department ${values?.name} was updated successfully.`,
            )
            close()
          })
          .catch((err) => {
            if (err?.data?.detail === 'Department name is already exists') {
              formik.setFieldError(
                'name',
                t('departments.duplicate_validation_message'),
              )
            }
            toast.error(err?.data?.detail)
            return
          })
      }
    },
  })

  return (
    <>
      <p className="p-xs-regular-dim2">
        {t('departments.new_department_subtitle')}
      </p>

      <div className="flex flex-col gap-2">
        <Field
          label={t('departments.department_name')}
          formik={formik}
          name="name"
          placeholder={t('departments.department_placeholder')}
        />
        <Field
          type="textarea"
          label={t('departments.description')}
          formik={formik}
          name="description"
          placeholder={t('departments.description_placeholder')}
        />
        <div className="mt-2 flex justify-between">
          <Button color="error" onClick={() => handleDelete(department)}>
            {t('departments.delete')}
          </Button>
          <div className="flex gap-2 ">
            <Button onClick={close}>{t('cancel')}</Button>
            <Button
              color="primary"
              onClick={() => formik.handleSubmit()}
              loading={isLoading}
            >
              {t('save')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateDepartment
