type PanelProps = {
  value: string | number
  title: string
  backgroundColor?: string
}
/**

 * @returns {ReactElement} A panel element that animates in and out based on the `isOpen` prop.
 */
const HeaderCard = ({ value, title, backgroundColor }: PanelProps) => {
  const valueToString = (value: string | number): string => {
    if (typeof value === 'number' && value < 10) {
      return `0${value}`
    }
    return String(value)
  }

  return (
    <>
      <div
        className={`w-[350px] ${backgroundColor ? `bg-[${backgroundColor}]` : 'bg-[#E7F5FF]'} rounded p-2 text-center`}
      >
        <h4 className="h4-bold">{valueToString(value)}</h4>
        <p className="p-xs-regular">{title}</p>
      </div>
    </>
  )
}

export default HeaderCard
