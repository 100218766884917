import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetShiftPolicyByIDQuery } from '../shift-mangement-endpoints/shiftManagement.endpoints'
import { useSelector } from 'react-redux'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useEffect, useState } from 'react'
import Popup from 'libs/popup/Popup'
import DeleteShiftPolicy from '../delete-shift-policy/DeleteShiftPolicy'
import useTabTitle from 'libs/hooks/useTabTitle'
import Warning from 'assets/icons/shift-management/Warning'
import {
  calculateTimeWithSeconds,
  calculateDuration,
} from 'utils/dateTimeHandlers'

const ViewShiftPolicy = () => {
  useTabTitle('View Shift ')
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState({
    isPopUpOpen: false,
    shiftPolicy: '',
  })
  const [overlappingShifts, setOverlappingShifts] = useState<any>([])

  const paramId = useParams()

  const { data: shiftPolicy, isLoading: isShiftPolicyLoading } =
    useGetShiftPolicyByIDQuery(
      {
        plantId: plantId?.id,
        shiftPolicyID: paramId?.id,
      },
      {
        skip: !paramId?.id || !plantId?.id,
      },
    )

  const columns = [
    {
      Header: 'Shift Name',
      accessor: 'shiftName',
      width: 150,
      Cell: ({ row }: any) => {
        const data = row?.original

        return <div>{data?.shiftName}</div>
      },
      disableSortBy: true,
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Start Time</div>
      ),
      accessor: 'startTime',
      width: 150,
      Cell: ({ row }: any) => {
        const startTime = row?.original?.startTime
        return calculateTimeWithSeconds(startTime)
      },
      disableSortBy: true,
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">End Time</div>
      ),
      accessor: 'endTime',
      width: 150,
      Cell: ({ row }: any) => {
        const endTime = row?.original?.endTime
        return calculateTimeWithSeconds(endTime)
      },
      disableSortBy: true,
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Duration</div>
      ),
      accessor: 'duration',
      width: 150,
      Cell: ({ row }: any) => {
        return calculateDuration(
          row?.original?.startTime,
          row?.original?.endTime,
        )
      },
      disableSortBy: true,
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Time Zone</div>
      ),
      accessor: 'timeZone',
      width: 150,
      Cell: () => <div>{plantId?.timezone}</div>,
      disableSortBy: true,
    },
  ]

  const checkForOverlap = (shifts: any) => {
    const overlappingShifts: string[] = [] // To store overlapping shift names

    for (let i = 0; i < shifts?.length; i++) {
      for (let j = i + 1; j < shifts?.length; j++) {
        const shift1 = shifts[i]
        const shift2 = shifts[j]

        let shift1Start = shift1?.startTime
        let shift1End = shift1?.endTime
        let shift2Start = shift2?.startTime
        let shift2End = shift2?.endTime

        // Handle shifts that cross midnight
        if (shift1End <= shift1Start) {
          shift1End += 24 * 60 * 60 * 1000 // Add 24 hours in milliseconds to shift1End
        }
        if (shift2End <= shift2Start) {
          shift2End += 24 * 60 * 60 * 1000 // Add 24 hours in milliseconds to shift2End
        }

        // Check if the shifts overlap
        if (
          (shift1Start < shift2End && shift1End > shift2Start) || // Shift 1 overlaps with Shift 2
          (shift2Start < shift1End && shift2End > shift1Start) // Shift 2 overlaps with Shift 1
        ) {
          overlappingShifts.push(
            `${shift1?.shiftName} and ${shift2?.shiftName} are overlapping. Please Adjust Timings.`,
          )
        }

        // If shift2 starts exactly when shift1 ends, this is not an overlap
      }
    }

    return overlappingShifts?.length > 0 ? overlappingShifts : [] // Return false if no overlaps found
  }

  useEffect(() => {
    if (shiftPolicy?.shifts) {
      const formattedShifts = shiftPolicy?.shifts?.map((shift: any) => ({
        ...shift,
        startTime: shift?.startTime && shift?.startTime / 1000,
        endTime: shift?.endTime && shift?.endTime / 1000,
      }))
      const overlapping = checkForOverlap(formattedShifts)
      setOverlappingShifts(overlapping)
    }
  }, [shiftPolicy?.shifts])

  return (
    <MotionDiv>
      {isShiftPolicyLoading ? (
        <FullPageLoader />
      ) : (
        <>
          <h1 className="h4-bold-black ">{shiftPolicy?.policyName}</h1>
          <div className="my-2 flex items-center justify-between">
            <h1 className="p-sm-regular-1">
              View the details of your added Shifts and easily add new ones as
              needed.
            </h1>
            <div className={`flex gap-2`}>
              <Button
                color="error"
                onClick={() =>
                  setDeletePopUp({
                    isPopUpOpen: true,
                    shiftPolicy: shiftPolicy,
                  })
                }
              >
                Delete Shift Policy
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  navigate(
                    `/settings/shift-management/edit/${shiftPolicy?._id}`,
                  )
                }
              >
                Edit Shift Policy
              </Button>
            </div>
          </div>
          <WhiteCard>
            <div className="py-3">
              <PaginatedTable
                columns={columns}
                rows={shiftPolicy?.shifts}
                hidePagination
              />
              {overlappingShifts?.length > 0 && (
                <div className="flex w-full items-center gap-2 bg-[#FFF3CD] p-3 p-xs-regular">
                  <Warning />
                  Warning: {overlappingShifts[overlappingShifts.length - 1]}
                </div>
              )}
              <Popup
                isOpen={deletePopUp.isPopUpOpen}
                title="Delete Shift Policy"
                onClose={() => {
                  setDeletePopUp({ isPopUpOpen: false, shiftPolicy: '' })
                }}
              >
                <DeleteShiftPolicy
                  closePopUp={() =>
                    setDeletePopUp({ isPopUpOpen: false, shiftPolicy: '' })
                  }
                  shiftPolicy={deletePopUp.shiftPolicy}
                  plantId={plantId?.id}
                />
              </Popup>
            </div>
          </WhiteCard>
        </>
      )}
    </MotionDiv>
  )
}

export default ViewShiftPolicy
