import FullPageLoader from 'libs/loader/FullPageLoader'
import { useState, useEffect } from 'react'

const Documents = ({ url }: { url: string }) => {
  const [loadError, setLoadError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const encodedURI = encodeURIComponent(url)

  useEffect(() => {
    // Reset the error and loading states when the URL changes
    setLoadError(false)
    setIsLoading(true)
  }, [url])

  const handleLoadSuccess = () => {
    setIsLoading(false)
    setLoadError(false)
  }

  const handleLoadError = () => {
    setIsLoading(false)
    setLoadError(true)
  }

  return (
    <>
      {isLoading && <FullPageLoader />}
      {!isLoading && loadError && (
        <p className="text-error">Unable to load document. Please check the URL or file type.</p>
      )}

      {!loadError && url && (
        <iframe
          src={`https://docs.google.com/gview?url=${encodedURI}&embedded=true&timestamp=${Date.now()}`}
          width="100%"
          height="100%"
          onLoad={handleLoadSuccess}
          onError={handleLoadError}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      )}
    </>
  )
}

export default Documents
