import { useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Field from 'libs/field'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { usePostOperationalProcessesMutation } from '../../operational-process-endpoints/operational_process.endpoints'
import { toast } from 'libs/toast'
import {
  useGetProcessByIdQuery,
  usePutOperationalProcessMutation,
} from '../../operational-process-endpoints/operational_process.endpoints'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import { trimStringValues } from 'utils/stringHandlers'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useGetDepartmentsQuery } from 'pages/department/department-endpoints/department.endpoints'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import SidePanel from 'libs/sidepanel/SidePanel'
import QuickAddProduct from 'libs/quick-add-edits/quick-add-product/QuickAddProduct'

export type OperationalProcessFormik = {
  processName: string
  processDescription: string
  inputProduct: {
    name: string
    code: string
    _id: string
    category: string
  }[]
  outputProduct: {
    name: string
    code: string
    _id: string
    category: string
  }[]
  department: null
}

interface Products {
  _id: string
  name: string
  code: string
  category?: string
}

const getChangeValues = (currentValues: any, initialValues: any) => {
  return Object.keys(currentValues).reduce((value: any, key) => {
    if (currentValues[key] !== initialValues[key]) {
      value[key] = currentValues[key]
    }
    return value
  }, {})
}

const GeneralDetails = ({ setPage, setParams }: any) => {
  const { processId } = useParams()

  const navigate = useNavigate()
  const selectedItem = useSelector(selectSelectedItem)
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const departmentFilters = {
    page_no: 1,
    page_size: 10,
    // searchText: '',
    createdBetween: [],
    updatedBetween: [],
    sortBy: 'updated_on',
    sortDir: 'DESC',
  }

  const { data: departments } = useGetDepartmentsQuery(
    { plantId: selectedItem?.id, filters: departmentFilters },
    {
      skip: !selectedItem?.id,
    },
  )

  const { data: process, isFetching: processLoading } = useGetProcessByIdQuery(
    {
      process_id: processId,
    },
    {
      skip: !processId,
    },
  )

  const filters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  }
  const { data: productsObj } = useGetProductQuery(
    {
      plantId: selectedItem?.id,
      filters: filters,
    },
    {
      skip: !selectedItem?.id,
    },
  )

  useEffect(() => {
    if (process) {
      setParams({
        processId: processId,
        productId: process?.input_products?.[0]?.product?._id,
      })
    }
  }, [process])

  const [addOperationalProcess, { isLoading: operationalLoading }] =
    usePostOperationalProcessesMutation()
  const [editOperationalProcess, { isLoading: editOperationalLoading }] =
    usePutOperationalProcessMutation()

  const operationalProcessFormik = useFormik({
    initialValues: {
      processName: '',
      processDescription: '',
      inputProduct: [],
      outputProduct: [],
      department: null,
    },
    validationSchema: yup.object({
      processName: yup.string().required('Process name is required'),
      // inputProduct: yup.array().min(1, 'Please select at least one input product'),
      // outProduct: yup.array().min(1, 'Please select at least one output product'),
      department: yup.object().required('Department is required'),
    }),
    onSubmit: async (values: any) => {
      const Values = getChangeValues(
        values,
        operationalProcessFormik.initialValues,
      )
      const isEmpty = Object.keys(Values).length === 0
      const payload = {
        name: values.processName,
        department_id: values.department?._id || null,
        description: values.processDescription,
        input_products: values?.inputProduct?.map((product: Products) => ({
          fields: [],
          product_id: product?._id,
        })),
        output_products: values?.outputProduct?.map((product: Products) => ({
          fields: [],
          product_id: product?._id,
        })),
      }

      const data = trimStringValues(payload)

      if (!processId) {
        addOperationalProcess({
          data: data,
          plant_id: selectedItem?.id,
        })
          .unwrap()
          .then((res) => {
            toast.success(
              `New operational process ${values.processName} is created.`,
            )
            setPage(2)
            const productId = res?.input_products?.[0]?.product?._id
              ? res?.input_products?.[0]?.product?._id
              : res?.output_products?.[0]?.product?._id
            const processId = res?._id
            if (productId) {
              navigate(
                `/settings/configure/operationalprocesses/form-fields/${processId}/product/${productId}`,
              )
            } else {
              navigate(
                `/settings/configure/operationalprocesses/form-fields/${processId}/product`,
              )
            }
          })
          .catch((err) => {
            toast.error(
              err?.data?.detail ?? 'Something went wrong. Please try again.',
            )
          })
      } else {
        const data = trimStringValues(payload)
        if (!isEmpty) {
          editOperationalProcess({
            data: data,
            processId: processId,
          })
            .unwrap()
            .then((res) => {
              const productId = res?.input_products?.[0]?.product?._id
                ? res?.input_products?.[0]?.product?._id
                : res?.output_products?.[0]?.product?._id
              const processId = res?._id
              toast.success(
                `Operational process ${values.processName} is successfully updated.`,
              )
              if (productId) {
                navigate(
                  `/settings/configure/operationalprocesses/form-fields/${processId}/product/${productId}`,
                )
              } else {
                navigate(
                  `/settings/configure/operationalprocesses/form-fields/${processId}/product`,
                )
              }
            })
            .catch((err) => {
              toast.error(
                err.data?.detail ?? 'Something went wrong. Please try again.',
              )
            })
        } else {
          const productId = values.inputProduct?.[0]?._id
            ? values.inputProduct?.[0]?._id
            : values.outputProduct?.[0]?._id
          if (productId) {
            navigate(
              `/settings/configure/operationalprocesses/form-fields/${processId}/product/${productId}`,
            )
          } else {
            navigate(
              `/settings/configure/operationalprocesses/form-fields/${processId}/product`,
            )
          }
        }
      }
    },
  })

  const isDirty = useMemo(() => {
    const Values = getChangeValues(
      operationalProcessFormik.values,
      operationalProcessFormik.initialValues,
    )
    return Object.keys(Values).length > 0
  }, [])

  useEffect(() => {
    if (processId && process) {
      operationalProcessFormik.resetForm({
        values: {
          processName: process?.name || '',
          processDescription: process?.description || '',
          inputProduct: process?.input_products?.map((input: any) => {
            return {
              name: input.product.name,
              _id: input.product._id,
            }
          }),
          outputProduct: process?.output_products?.map((output: any) => {
            return {
              name: output.product.name,
              _id: output.product._id,
            }
          }),
          department: {
            name: process?.department?.name || '',
            _id: process?.department?._id || '',
          },
        },
      })
    }
  }, [process])

  const department = {
    label: operationalProcessFormik?.values.department?.name ?? '',
  }

  const handelOperationalTabel = () => {
    navigate('/settings/configure/operationalprocesses')
  }
  useTabTitle(
    `${process?.name ? `${t('plannings.update')}  ${process?.name}` : ''}`,
  )

  const selectedInputProduct = useMemo(() => {
    return productsObj?.data
      ?.filter((product: any) =>
        operationalProcessFormik?.values?.inputProduct?.some(
          (inputProduct: any) => inputProduct._id === product._id,
        ),
      )
      .map(({ _id, name, code }: Products) => ({ _id, name, code }))
  }, [productsObj, operationalProcessFormik?.values?.inputProduct])

  const selectedOutputProduct = useMemo(() => {
    return productsObj?.data
      ?.filter((product: any) =>
        operationalProcessFormik?.values?.outputProduct?.some(
          (outputProduct: any) => outputProduct._id === product._id,
        ),
      )
      .map(({ _id, name, code }: Products) => ({ _id, name, code }))
  }, [productsObj, operationalProcessFormik?.values?.outputProduct])

  const openPanel = () => {
    setTimeout(() => {
      setIsPanelOpen(!isPanelOpen)
    }, 300)
  }

  const departmentDefaultValue = useMemo(() => {
    return departments?.data?.map((item: any) => ({
      label: item.name,
      value: item,
    }))
  }, [departments])

  const productOptions = useMemo(() => {
    return productsObj?.data?.map((product: any) => ({
      label: product.name,
      sublabel: product.code,
      value: product,
    }))
  }, [productsObj])

  return (
    <MotionDiv className=" w-full ">
      <>
        <h5 className="h5-1">{t('general_details')}</h5>
        <p className="mb-4   p-sm-regular-1">
          {t('add_the_basic_details_that_are_required_to_identify_this_task')}
        </p>

        {processLoading ? (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <FullPageLoader />
          </div>
        ) : (
          <div className=" flex flex-col gap-4">
            {/* --- process name and department */}
            <div className="flex gap-6">
              <div className="w-full max-w-[500px]">
                <Field
                  required={true}
                  label={t('process_name')}
                  formik={operationalProcessFormik}
                  name="processName"
                  placeholder={t('enter_process_name')}
                />
              </div>
              <div className="w-full max-w-[500px]">
                <Field
                  type="select"
                  required={true}
                  label={t('department')}
                  formik={operationalProcessFormik}
                  name="department"
                  placeholder={t('enter_process_name')}
                  defaultValue={department}
                  options={departmentDefaultValue}
                />
              </div>
            </div>
            {/* ---- process description  ----- */}
            <div className="max-w-[1030px] ">
              <Field
                type="textarea"
                label={t('process_description')}
                formik={operationalProcessFormik}
                name="processDescription"
                placeholder={t(
                  'describe_the_processes_to_help_people_understand_its_purpose_and_features',
                )}
              />
            </div>
            {/* ---- border ----- */}
            <div className="border-t-1  w-full border border-background-2 "></div>
            <SidePanel
              size="large"
              title="Add Product"
              isOpen={isPanelOpen}
              onClose={() => setIsPanelOpen(false)}
            >
              <QuickAddProduct close={() => setIsPanelOpen(false)} />
            </SidePanel>
            <div className=" flex gap-6 ">
              <div className="  flex w-full max-w-[500px] flex-col ">
                <span className="mb-[10px] p-sm-regular">Input Products</span>

                <Field
                  type="multiselect"
                  formik={operationalProcessFormik}
                  name="inputProduct"
                  placeholder="Select Input Product"
                  options={productOptions}
                  title={'Add Product'}
                  onAdd={openPanel}
                />
                {operationalProcessFormik.values.inputProduct.length > 0 && (
                  <div className="border-1 scroll-bar mb-6 mt-4 h-[160px] w-full max-w-[500px] overflow-y-auto rounded-md border p-1">
                    {selectedInputProduct?.map((product: any) => (
                      <div
                        key={product._id}
                        className="flex-col gap-3 px-3 py-2 "
                      >
                        <div className="w-[320px] truncate p-xs-regular">
                          {product?.name}
                        </div>
                        <div className="w-[320px] truncate p-xs-regular-dim">
                          {product?.code}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className=" flex w-full max-w-[500px] flex-col">
                <span className="mb-[10px] p-sm-regular">Output Products</span>
                <Field
                  type="multiselect"
                  formik={operationalProcessFormik}
                  name="outputProduct"
                  placeholder="Select Output Product"
                  options={productOptions}
                  title={'Add Product'}
                  onAdd={openPanel}
                />
                {operationalProcessFormik.values.outputProduct.length > 0 && (
                  <div className="border-1 scroll-bar mt-4 h-[160px] w-full max-w-[500px] overflow-y-auto  rounded-md border p-1">
                    {selectedOutputProduct?.map((product: any) => (
                      <div
                        key={product._id}
                        className="flex-col gap-3 px-3 py-2 "
                      >
                        <div className="w-[320px] truncate p-xs-regular">
                          {product?.name}
                        </div>
                        <div className="w-[320px] truncate p-xs-regular-dim">
                          {product?.code}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div
          className={`fixed bottom-0 left-0 right-0 flex h-[4rem] items-center justify-end gap-2 bg-white px-6 shadow-2xl`}
          style={{
            boxShadow:
              '0px 2px 4px 0px rgba(0, 0, 0, 1), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Button className="btn-cancel" onClick={handelOperationalTabel}>
            {t('cancel')}
          </Button>
          {processId && process ? (
            <Button
              loading={operationalLoading || editOperationalLoading}
              color="success"
              onClick={() => {
                operationalProcessFormik.handleSubmit()
              }}
            >
              {isDirty ? t('save') : t('next')}
            </Button>
          ) : (
            <Button
              loading={operationalLoading || editOperationalLoading}
              color="success"
              onClick={() => {
                operationalProcessFormik.handleSubmit()
              }}
            >
              {t('next')}
            </Button>
          )}
        </div>
      </>
    </MotionDiv>
  )
}

export default GeneralDetails
