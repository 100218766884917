import { useState } from 'react'
import MoreIcon from 'assets/icons/dashboard/MoreIcon'

const MoreOptionsSelect = ({ onMove, onRemove }: any) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return (
    <div className="relative ">
      <button className="p-2" onClick={toggleDropdown}>
        <MoreIcon /> {/* Your custom more icon */}
      </button>
      {dropdownVisible && (
        <div className="absolute right-0 z-10 mt-2 w-48 rounded border bg-white p-2 shadow-lg">
          <button
            className="block w-full border-b-2 px-4 py-2 text-sm text-brand-primary-shade-3 hover:bg-gray-200"
            onClick={() => {
              onMove()
              setDropdownVisible(false)
            }}
          >
            Move
          </button>
          <button
            className="block w-full px-4 py-2 text-sm text-brand-primary-shade-3 hover:bg-gray-200"
            onClick={() => {
              onRemove()
              setDropdownVisible(false)
            }}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  )
}

export default MoreOptionsSelect
