const RoutingIcon = () => {
  return (
    <svg
      data-testid="RoutingIcon"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.5C4.9 18.5 3.95833 18.1083 3.175 17.325C2.39167 16.5417 2 15.6 2 14.5V6.325C1.41667 6.10833 0.9375 5.74583 0.5625 5.2375C0.1875 4.72917 0 4.15 0 3.5C0 2.66667 0.291667 1.95833 0.875 1.375C1.45833 0.791667 2.16667 0.5 3 0.5C3.83333 0.5 4.54167 0.791667 5.125 1.375C5.70833 1.95833 6 2.66667 6 3.5C6 4.15 5.8125 4.72917 5.4375 5.2375C5.0625 5.74583 4.58333 6.10833 4 6.325V14.5C4 15.05 4.19583 15.5208 4.5875 15.9125C4.97917 16.3042 5.45 16.5 6 16.5C6.55 16.5 7.02083 16.3042 7.4125 15.9125C7.80417 15.5208 8 15.05 8 14.5V4.5C8 3.4 8.39167 2.45833 9.175 1.675C9.95833 0.891667 10.9 0.5 12 0.5C13.1 0.5 14.0417 0.891667 14.825 1.675C15.6083 2.45833 16 3.4 16 4.5V12.675C16.5833 12.8917 17.0625 13.2542 17.4375 13.7625C17.8125 14.2708 18 14.85 18 15.5C18 16.3333 17.7083 17.0417 17.125 17.625C16.5417 18.2083 15.8333 18.5 15 18.5C14.1667 18.5 13.4583 18.2083 12.875 17.625C12.2917 17.0417 12 16.3333 12 15.5C12 14.85 12.1875 14.2667 12.5625 13.75C12.9375 13.2333 13.4167 12.875 14 12.675V4.5C14 3.95 13.8042 3.47917 13.4125 3.0875C13.0208 2.69583 12.55 2.5 12 2.5C11.45 2.5 10.9792 2.69583 10.5875 3.0875C10.1958 3.47917 10 3.95 10 4.5V14.5C10 15.6 9.60833 16.5417 8.825 17.325C8.04167 18.1083 7.1 18.5 6 18.5ZM3 4.5C3.28333 4.5 3.52083 4.40417 3.7125 4.2125C3.90417 4.02083 4 3.78333 4 3.5C4 3.21667 3.90417 2.97917 3.7125 2.7875C3.52083 2.59583 3.28333 2.5 3 2.5C2.71667 2.5 2.47917 2.59583 2.2875 2.7875C2.09583 2.97917 2 3.21667 2 3.5C2 3.78333 2.09583 4.02083 2.2875 4.2125C2.47917 4.40417 2.71667 4.5 3 4.5ZM15 16.5C15.2833 16.5 15.5208 16.4042 15.7125 16.2125C15.9042 16.0208 16 15.7833 16 15.5C16 15.2167 15.9042 14.9792 15.7125 14.7875C15.5208 14.5958 15.2833 14.5 15 14.5C14.7167 14.5 14.4792 14.5958 14.2875 14.7875C14.0958 14.9792 14 15.2167 14 15.5C14 15.7833 14.0958 16.0208 14.2875 16.2125C14.4792 16.4042 14.7167 16.5 15 16.5Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}

export default RoutingIcon
