import Pen from 'assets/icons/camera-page/Pen'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'libs/button/Button'

interface props {
  data: any
  deletePopup: (id: string) => void
}

const ViewProduct = ({ data, deletePopup }: props) => {
  const navigate = useNavigate()

  const [formData, setFormData] = useState(data)

  useEffect(() => {
    setFormData(data)
  }, [data])

  const navigateToViewProducts = () => {
    const state = formData
    navigate('/settings/configure/products/editProducts', { state })
  }
  const navigateToEditProducts = () => {
    const state = {
      ...formData,
      page: 1,
      isEdit: true,
    }

    navigate('/settings/configure/products/editProducts', { state })
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <h6 className=" p-md-regular-2"></h6>
        <div onClick={navigateToEditProducts} className="cursor-pointer">
          <Pen color={'#1193F0'} />
        </div>
      </div>
      <div>
        {formData?.name && (
          <div className="mt-2 flex justify-between">
            <div className="mb-2 p-xs-regular-dim">Name</div>
            <div>
              <div className="w-60 break-words p-xs-regular">{formData?.name}</div>
            </div>
          </div>
        )}
        {formData?.code && (
          <div className="flex justify-between">
            <div className="mb-2 p-xs-regular-dim">Code</div>
            <div>
              <div className="w-60 p-xs-regular">{formData?.code}</div>
            </div>
          </div>
        )}
        {formData?.category && (
          <div className="flex justify-between">
            <div className="mb-2 p-xs-regular-dim">Category</div>
            <div>
              <div className="w-60 p-xs-regular">{formData?.category}</div>
            </div>
          </div>
        )}
        {formData?.unit_of_measure && (
          <div className="flex justify-between">
            <div className="mb-2 p-xs-regular-dim">Unit</div>
            <div>
              <div className="w-60 p-xs-regular">{formData?.unit_of_measure}</div>
            </div>
          </div>
        )}
        {formData?.description && (
          <div className="flex justify-between">
            <div className="mb-2 p-xs-regular-dim">Description</div>
            <div>
              <div className="w-60 break-words p-xs-regular">{formData?.description}</div>
            </div>
          </div>
        )}
        {formData?.tags?.length > 0 && (
          <div className="mt-2 flex justify-between">
            <div className="mb-2 p-xs-regular-dim">Product Tags</div>
            <div>
              <div className="flex w-60 flex-wrap p-xs-regular">
                {formData?.tags?.map((status: string, index: number) => (
                  <span key={index} className={`mb-1 mr-1 rounded-[28px] bg-[#F2F2F2] px-2 py-0.5 text-[#434C52] `}>
                    {status}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
        {formData?.process_details?.length > 0 && (
          <div className="mt-2 flex items-center justify-between ">
            <div className="mb-2 p-xs-regular-dim">Associated Process</div>
            <div>
              <div className="flex w-60 flex-wrap p-xs-regular ">
                {formData?.process_details?.map((status: { name: string }, index: number) => (
                  <span
                    key={index}
                    className={`mb-1 mr-1 rounded-[28px] bg-[#F2F2F2] px-3 py-1 text-[#434C52]  `}
                    style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
                  >
                    {status.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-end">
          <div className="mt-2"></div>
        </div>
        <div className="my-5 flex justify-between">
          <Button size="sm" color="error" onClick={() => deletePopup(formData.id)}>
            Delete
          </Button>
          <Button size="sm" color="primary" onClick={navigateToViewProducts}>
            {' '}
            View All Details
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ViewProduct
