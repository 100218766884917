import Button from 'libs/button/Button'

import { t } from 'i18next'
import { toast } from 'libs/toast'

import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useDeleteMaintenanceRequestMutation } from '../maintenance-request/maintenance-request'

interface Props {
  closePopUp: any
  batchData: string[]
  setBatchData: (data: string[]) => void
  deleteState: { state: boolean; ids: string[] } // Boolean to represent delete state
  setDeleteState: (state: { state: boolean; ids: string[] }) => void
}

const DeletePopUp = ({ closePopUp, batchData, setBatchData, deleteState, setDeleteState }: Props) => {
  const plant = useSelector(selectSelectedItem)

  const [deleteEntry, { isLoading: deleteLoading }] = useDeleteMaintenanceRequestMutation()


  const handleDelete = () => {
    const ids = batchData?.length > 0 ? batchData : [deleteState.ids]


    deleteEntry({ plantId: plant?._id, data: { data: ids } })
      .unwrap()
      .then(() => {
        closePopUp()
        setDeleteState({ state: false, ids: [] })
        setBatchData([])
        toast.success(`The Maintenance Request record ${ids?.length} is removed successfully.`)
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <>
      <p className="mb-3 p-xs-regular">
        {`You are about to delete "${batchData?.length > 0 ? batchData?.length : 1} requests". Are you sure you want to proceed?`}
      </p>

      <div className=" flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDelete} loading={deleteLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeletePopUp
