import Button from 'libs/button/Button'
import NewEditableTable from 'libs/table/NewEditableTable'
import { toast } from 'libs/toast'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const partDetailsColumns = [
  {
    title: 'Part Name',
    accessor: 'name',
    flex: 1,
    editable: true,
    placeholder: 'Enter Part Name',
  },
  {
    title: 'Serial Number',
    accessor: 'serialNumber',
    flex: 1,
    editable: true,
    placeholder: 'Enter Serial Number',
  },
  {
    title: 'Manufacturer',
    accessor: 'manufacturer',
    flex: 1,
    editable: true,
    placeholder: 'Enter Manufacturer',
  },
  {
    title: 'Last Replaced',
    accessor: 'lastReplaced',
    flex: 1,
    fieldType: 'date',
    editable: true,
    shouldDisableFutureDate: true,

    placeholder: 'Select Last Replaced',
  },
]

const PartsDetails = ({ setId, setTabNumber, formik, isLoading }: any) => {
  const { id } = useParams()
  const [data, setData] = useState([])
  const navigate = useNavigate()

  const handleSubmit = () => {
    if (data) {
      for (const item of data) {
        // Check all fields except 'lastReplaced'
        for (const key in item) {
          if (key !== 'lastReplaced' && item[key] === undefined) {
            return toast.error(`Please fill in all required fields, or remove incomplete data.`)
          }
        }
      }
      formik.setFieldValue('partsDetails', data)
    }
  }

  const handleNext = () => {
    setTabNumber((prev: number) => prev + 1)

    if (!id) {
      navigate('/maintenance/machines/add/documents')
    } else {
      navigate(`/maintenance/machines/${id}/edit/documents`)
    }
  }

  useEffect(() => {
    setId(id)
    setTabNumber(3)
  }, [])

  const handleSkipStep = () => {
    if (!id) {
      navigate('/maintenance/machines/add/documents')
    } else {
      navigate(`/maintenance/machines/${id}/edit/documents`)
    }
  }

  return (
    <div className="h-screen-90 flex w-full flex-col px-2">
      <div className="flex-grow">
        <div className="h5-1">Parts Details</div>
        <p className="primary-gray-4 p-sm-regular">Add parts details associated with the machine.</p>
        <div className="mt-4">
          <NewEditableTable
            defaultRows={formik?.values?.partsDetails}
            columns={partDetailsColumns}
            addButtonTitle="Add New Part"
            onChange={(values: any) => {
              setData(values)
            }}
            tooltipContent="Delete Part"
          />
        </div>
      </div>
      <div className="flex justify-between mt-6">
        <div className="flex gap-3">
          <Button type="button" onClick={() => navigate('/maintenance/machines')}>
            Cancel
          </Button>
        </div>
        <div className="flex justify-end gap-2">
          {id ? (
            <>
              <div className="flex gap-3">
                <Button type="button" disabled={isLoading} onClick={() => navigate(-1)}>
                  Previous
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    handleSubmit()
                    handleNext()
                  }}
                >
                  Next
                </Button>
              </div>
              <div>
                <Button
                  color="success"
                  type="submit"
                  loading={isLoading}
                  onClick={() => {
                    handleSubmit()
                    formik.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-3">
                <Button type="button" onClick={() => handleSkipStep()}>
                  Skip this step
                </Button>
              </div>
              <div className="flex gap-3">
                <Button type="button" onClick={() => navigate(-1)}>
                  Previous
                </Button>
              </div>
              <div>
                <Button
                  color="success"
                  type="submit"
                  onClick={() => {
                    handleSubmit()
                    handleNext()
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PartsDetails
