import { useFormik } from 'formik'
import Field from 'libs/field'
import ReportTable from './components/ReportTable'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  useGetWorkOrderChartByPlantIdQuery,
  useGetWorkOrdersBarStatusQuery,
  useGetWorkOrdersQuery,
} from 'pages/work-orders/work-orders-endpoints/workorders.endpoints'
import BarGraph from 'libs/charts/BarGraph'
import PieCharts from 'libs/charts/PieChart'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'
import useTabTitle from 'libs/hooks/useTabTitle'
import Loader from 'libs/loader/customloader/Loader'
import { useState } from 'react'
import {
  usePutUserPreferencesMutation,
  useUserInfoQuery,
} from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import { hasAccess } from 'utils/utils'

const WorkOrderDashboard = () => {
  const { data: userInfo, isLoading: userInfoLoading } = useUserInfoQuery({})
  const [updateUserPreference] = usePutUserPreferencesMutation()
  const plantId = useSelector(selectSelectedItem)
  const workorderAccess = hasAccess('pla', { wo: 'e' })
  const navigate = useNavigate()
  useTabTitle('Work Order Dashboard')

  const [isBarGraphLoading, setIsBarGraphLoading] = useState(false)
  const [isPieChartLoading, setIsPieChartLoading] = useState(false)

  const initialValues = {
    dateRange: null,
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
  })

  const handleWidgetToggle = async (
    widgetKey: string,
    setLoading: (loading: boolean) => void,
  ) => {
    try {
      setLoading(true)
      let updatedPreferences = { ...userInfo?.preferences }
      if (!Array.isArray(updatedPreferences.chart)) {
        updatedPreferences.chart = []
      }
      if (updatedPreferences?.chart?.includes(widgetKey)) {
        updatedPreferences.chart = updatedPreferences?.chart?.filter(
          (value: string) => value !== widgetKey,
        )
      } else {
        updatedPreferences.chart = [...updatedPreferences?.chart, widgetKey]
      }
      const payload = { pref: updatedPreferences }
      await updateUserPreference({ data: payload }).unwrap()
    } catch (error) {
      console.error('Failed to toggle widget order', error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }
  }

  const { data: workOrder } = useGetWorkOrdersQuery(
    {
      plantId: plantId?.id,
      filters: { page_no: 1, page_size: 10 },
    },
    {
      skip: !plantId?.id,
    },
  )
  const { data: workOrderChartData, isFetching: chartLoader } =
    useGetWorkOrderChartByPlantIdQuery(
      {
        plant_id: plantId?.id,
        filters: formik.values,
      },
      {
        skip: !plantId?.id || !formik.values,
      },
    )
  const { data: workOrderStatusBar, isFetching: statusLoader } =
    useGetWorkOrdersBarStatusQuery(
      {
        plantId: plantId?.id,
        filters: formik.values,
      },
      {
        skip: !plantId?.id || !formik.values,
      },
    )

  const workOrderChart = [
    { name: 'On-Time', value: workOrderChartData?.onTime },
    { name: 'Delayed', value: 100 - workOrderChartData?.onTime },
  ]

  if (userInfoLoading) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100">
        <Loader size="xl" color="primary" />
      </div>
    )
  }

  return (
    <>
      {workOrder && workOrder?.paging?.total_items_count < 1 ? (
        <div className="flex items-center justify-center bg-gray-100">
          <div className="text-center">
            <p className="mb-4 text-xl">
              There is currently no data to display in the Work Order Reports.
            </p>
            {workorderAccess && (
              <div className="flex justify-center">
                <Button
                  color="primary"
                  onClick={() => {
                    navigate('/planning/work-orders/add')
                  }}
                >
                  + Add Work Order
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="mb-4 flex items-center justify-between">
            <h1 className="text-3xl font-bold text-gray-900">
              Work Order Reports
            </h1>
            <div className="w-60">
              <Field
                type="dateRange"
                formik={formik}
                name="dateRange"
                shouldDisableFutureDate={true}
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-7/12 pr-4  ">
              <div className="rounded-lg bg-white p-4 shadow-md ">
                <ReportTable formik={formik} />
              </div>
            </div>
            {/* Bar graph and Pie chart */}
            <div className="grid w-5/12 grid-cols-1 gap-4">
              <div className="rounded-lg bg-white p-4 shadow-md">
                <div className="mb-2 flex items-center justify-between">
                  <h1 className="text-lg font-bold">Work Order Status</h1>
                  <h1
                    className={`hyperlink ${isBarGraphLoading ? 'pointer-events-none' : ''}`}
                    onClick={() => {
                      if (!isBarGraphLoading) {
                        handleWidgetToggle('barGraph', setIsBarGraphLoading)
                      }
                    }}
                  >
                    {userInfo?.preferences &&
                    userInfo?.preferences?.chart?.includes('barGraph')
                      ? isBarGraphLoading
                        ? 'Loading...'
                        : 'Remove from dashboard'
                      : isBarGraphLoading
                        ? 'Loading...'
                        : 'Add to dashboard'}
                  </h1>
                </div>
                <div>
                  {statusLoader ? (
                    <div className="flex h-full w-full items-center justify-center">
                      <Loader size="xl" color="primary" />
                    </div>
                  ) : (
                    <BarGraph formik={formik} apiData={workOrderStatusBar} />
                  )}
                </div>
              </div>
              <div className="rounded-lg bg-white px-4 shadow-md ">
                <div className="mb-1 flex items-center justify-between">
                  <h1 className="py-2 text-lg font-bold">
                    On-Time Work Orders
                  </h1>
                  <h1
                    className={`hyperlink ${isPieChartLoading ? 'pointer-events-none' : ''}`}
                    onClick={() => {
                      if (!isPieChartLoading) {
                        handleWidgetToggle(
                          'workOrderPieChart',
                          setIsPieChartLoading,
                        )
                      }
                    }}
                  >
                    {userInfo?.preferences &&
                    userInfo?.preferences?.chart?.includes('workOrderPieChart')
                      ? isPieChartLoading
                        ? 'Loading...'
                        : 'Remove from dashboard'
                      : isPieChartLoading
                        ? 'Loading...'
                        : 'Add to dashboard'}
                  </h1>
                </div>
                <div>
                  {chartLoader ? (
                    <div className="flex h-full w-full items-center justify-center">
                      <Loader size="xl" color="primary" />
                    </div>
                  ) : (
                    <PieCharts apiData={workOrderChart} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WorkOrderDashboard
