import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'

import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import Chip from 'libs/chip/Chip'
import SidePanel from 'libs/sidepanel/SidePanel'
import Popup from 'libs/popup/Popup'
import { hasAccess } from 'utils/utils'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import FilterableSearchTable from 'libs/table/filterable-search-table_new/FilterableSearchTable'
import { selectSelectedItem } from 'store/redux/navSlice'
import { ExtendedColumn } from 'store/services/utilsTypes'

//icons
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'

import DeletePurchaseOrder from './delete-purchase-order/DeletePurchaseOrder'
import FilterPurchaseOrder from './panel/FilterPurchaseOrder'
import { useGetPurchaseOrdersQuery } from './purchase-orders-endpoints/purchaseOrders.endpoints'
import ViewPOProducts from './panel/ViewPOProducts'
import { PurchaseOrder } from './purchase-orders-endpoints/purchaseOrdersTypes'

const additionalFilters = {
  created_between: [],
  updated_between: [],
  status: [],
  category: [],
  sort_by: 'created_on',
  sort_dir: 'DESC',
}

const PurchaseOrders = () => {
  const navigate = useNavigate()
  useTabTitle('Purchase Order')

  const plantId = useSelector(selectSelectedItem)

  const [deletePopUp, setDeletePopUp] = useState<{
    isPopUpOpen: boolean
    purchaseOrder: PurchaseOrder | null
  }>({
    isPopUpOpen: false,
    purchaseOrder: null,
  })

  const purchaseOrderAccess = hasAccess('ord', { po: 'v' })
  const purchaseOrderEditAccess = hasAccess('ord', { po: 'e' })

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<{
    showPanel: boolean
    data: PurchaseOrder | null
  }>({
    showPanel: false,
    data: null,
  })

  const handleSelectedProduct = (data: PurchaseOrder) => {
    if (data) {
      setSelectedPurchaseOrder({
        showPanel: true,
        data: data,
      })
    }
  }

  const column: ExtendedColumn<PurchaseOrder>[] = [
    {
      Header: 'Purchase Order ID',
      accessor: 'purchaseOrderID',
      width: 130,
      Cell: ({ row }: CellProps<PurchaseOrder>) => {
        const data = row?.original
        return (
          <div
            className={`${purchaseOrderAccess ? 'hyperlink' : ''}`}
            onClick={() => {
              purchaseOrderAccess &&
                data?.id &&
                navigate(`/orders/purchase-order/view/${data?.id}`)
            }}
          >
            {data?.purchaseOrderID ?? '-'}
          </div>
        )
      },
    },
    {
      Header: 'Supplier Name',
      accessor: 'vendor',
      width: 150,
      Cell: ({ row }: CellProps<PurchaseOrder>) => {
        return <div>{row.original.vendor?.name ?? '-'}</div>
      },
    },
    {
      Header: 'Raw Materials',
      accessor: 'products',
      width: 150,
      Cell: ({ row }: CellProps<PurchaseOrder>) => {
        const data = row?.original
        return (
          <div
            className={`${purchaseOrderAccess ? 'hyperlink' : ''}`}
            onClick={() => handleSelectedProduct(data)}
          >
            {data?.products?.length ?? 0} Raw Materials
          </div>
        )
      },
    },
    {
      Header: 'Arrival Date',
      accessor: 'arrivalDate',
      width: 130,
      Cell: ({ row }: CellProps<PurchaseOrder>) =>
        formatUnixDate(row.original.arrivalDate),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 50,
      Cell: ({ row }: CellProps<PurchaseOrder>) => (
        <Chip title={row.original.status} />
      ),
    },
    {
      Header: 'Actions',
      accessor: 'id',
      width: 50,
      Cell: ({ row }: CellProps<PurchaseOrder>) => (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row">
            <button
              className={`mr-2 ${purchaseOrderEditAccess ? '' : 'opacity-40'}`}
              onClick={(e) => {
                e.stopPropagation()
                purchaseOrderEditAccess &&
                  row.original.id &&
                  navigate(`edit/${row.original.id}`)
              }}
            >
              <Pen />
            </button>
            <button
              className={`mr-2 ${purchaseOrderEditAccess ? '' : 'opacity-40'}`}
              onClick={(event) => {
                event.stopPropagation()
                purchaseOrderEditAccess &&
                  row?.original &&
                  setDeletePopUp({
                    isPopUpOpen: true,
                    purchaseOrder: row?.original,
                  })
              }}
            >
              <Bin />
            </button>
          </div>
          <span
            className="cursor-pointer"
            onClick={() =>
              purchaseOrderAccess &&
              row?.original?.id &&
              navigate(`/orders/purchase-order/view/${row?.original?.id}`)
            }
          >
            <ClickableRowArrow />
          </span>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleNavigateToAddPurchaseOrder = () => {
    if (purchaseOrderAccess) navigate('add')
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Purchase Orders</h1>
        <p className="p-sm-regular-1">
          View the details of your added Purchase Orders and easily add new ones
          as needed.
        </p>
        <FilterableSearchTable
          useQuery={useGetPurchaseOrdersQuery}
          columns={column}
          addButton={{
            label: 'Add Purchase Order',
            onClick: handleNavigateToAddPurchaseOrder,
          }}
          placeholders={{
            emptyMessage:
              'No Purchase Order are added. Please add Purchase Order',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Purchase Orders by ID, Supplier Name or Status',
          }}
          filterPanelComponent={FilterPurchaseOrder}
          additionalFilters={additionalFilters}
          accessRights={purchaseOrderEditAccess}
        />
        {deletePopUp.purchaseOrder && (
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Purchase Order"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, purchaseOrder: null })
            }}
          >
            <DeletePurchaseOrder
              closePopUp={() =>
                setDeletePopUp({ isPopUpOpen: false, purchaseOrder: null })
              }
              purchaseOrder={deletePopUp.purchaseOrder}
              plantId={plantId?.id}
            />
          </Popup>
        )}
        {selectedPurchaseOrder.data && (
          <SidePanel
            size="large"
            isOpen={selectedPurchaseOrder.showPanel}
            title={'Product Details'}
            onClose={() => {
              setSelectedPurchaseOrder(() => ({
                showPanel: false,
                data: null,
              }))
            }}
          >
            <ViewPOProducts
              isOpen={selectedPurchaseOrder.showPanel}
              closePanel={() => {
                setSelectedPurchaseOrder(() => ({
                  showPanel: false,
                  data: null,
                }))
              }}
              purchaseOrder={selectedPurchaseOrder.data}
            />
          </SidePanel>
        )}
      </>
    </MotionDiv>
  )
}

export default PurchaseOrders
