import Loader from './customloader/Loader'

const FullPageLoader = () => {
  return (
    <div
      data-testid="full-page-loader"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Loader size="xl" color="primary" />
    </div>
  )
}

export default FullPageLoader
