const BlueTick: React.FC = () => {
  return (
    <svg
      data-testid="blue-tick-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <mask
        id="mask0_1930_8575"
        style={{ maskType: 'alpha' }}
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_1930_8575)">
        <path
          fill="#fff"
          d="M7.067 9.2L5.633 7.767a.632.632 0 00-.466-.184.632.632 0 00-.467.184.632.632 0 00-.183.466c0 .19.06.345.183.467l1.9 1.9a.64.64 0 00.467.2.64.64 0 00.466-.2L11.3 6.833a.632.632 0 00.183-.466.632.632 0 00-.183-.467.632.632 0 00-.466-.183.632.632 0 00-.467.183l-3.3 3.3zM8 14.667a6.492 6.492 0 01-2.6-.525 6.732 6.732 0 01-2.117-1.425A6.733 6.733 0 011.86 10.6 6.492 6.492 0 011.333 8c0-.922.175-1.789.526-2.6a6.732 6.732 0 011.425-2.117c.6-.6 1.305-1.075 2.116-1.425A6.492 6.492 0 018 1.333c.922 0 1.789.175 2.6.525.811.35 1.517.825 2.117 1.425.6.6 1.075 1.306 1.425 2.117.35.811.525 1.678.525 2.6 0 .922-.175 1.789-.525 2.6a6.733 6.733 0 01-1.425 2.117c-.6.6-1.306 1.075-2.117 1.425a6.492 6.492 0 01-2.6.525z"
        ></path>
      </g>
    </svg>
  )
}

export default BlueTick
