import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import arrowRight from 'assets/icons/misc/arrowRight.svg'
import SalesOrder from 'assets/icons/order/SalesOrder.svg'
import PurchaseOrder from 'assets/icons/order/PurchaseOrder.svg'
import Card from 'libs/card/Card'
import Chip from 'libs/chip/Chip'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { hasAccess } from 'utils/utils'
import { selectSelectedItem } from 'store/redux/navSlice'

import { useGetPurchaseOrdersStatsQuery } from './purchase-order/purchase-orders-endpoints/purchaseOrders.endpoints'
import { useGetSalesOrdersStatsQuery } from './sales-order/sales-order-endpoints/salesOrders.endpoints'

// Define types for card details
interface OrderDetail {
  name: string
  count: string // Ensure this is always a string
}

interface CardDetail {
  icon: string
  title: string
  year: string
  route: string
  Order: OrderDetail[]
}

const Order: React.FC = () => {
  const plant = useSelector(selectSelectedItem)
  const purchaseOrderAccess = hasAccess('ord', { po: 'view' })
  const salesOrderAccess = hasAccess('ord', { so: 'view' })

  useTabTitle('Orders')

  const { data: salesOrderStatistics, isLoading: isSalesOrdersStatsLoading } =
    useGetSalesOrdersStatsQuery(
      {
        plant_id: plant?.id,
      },
      {
        skip: !plant?.id,
      },
    )

  const {
    data: purchaseOrderStatistics,
    isLoading: isPurchaseOrdersStatsLoading,
  } = useGetPurchaseOrdersStatsQuery(
    {
      plant_id: plant?.id,
    },
    {
      skip: !plant?.id,
    },
  )

  // Convert count to string
  const cardDetails: CardDetail[] = [
    salesOrderAccess && {
      icon: SalesOrder,
      title: 'Sales Orders',
      year: 'FY 2024 - 2025',
      route: '/orders/sales-order',
      Order: [
        {
          name: 'IN_PROGRESS',
          count: (salesOrderStatistics?.IN_PROGRESS ?? '-').toString(),
        },
        {
          name: 'CANCELLED',
          count: (salesOrderStatistics?.CANCELLED ?? '-').toString(),
        },
        {
          name: 'COMPLETED',
          count: (salesOrderStatistics?.COMPLETED ?? '-').toString(),
        },
        {
          name: 'NOT_STARTED',
          count: (salesOrderStatistics?.NOT_STARTED ?? '-').toString(),
        },
      ],
    },
    purchaseOrderAccess && {
      icon: PurchaseOrder,
      title: 'Purchase Orders',
      year: 'FY 2024 - 2025',
      route: '/orders/purchase-order',
      Order: [
        {
          name: 'IN_PROGRESS',
          count: (purchaseOrderStatistics?.IN_PROGRESS ?? '-').toString(),
        },
        {
          name: 'CANCELLED',
          count: (purchaseOrderStatistics?.CANCELLED ?? '-').toString(),
        },
        {
          name: 'COMPLETED',
          count: (purchaseOrderStatistics?.COMPLETED ?? '-').toString(),
        },
        {
          name: 'NOT_STARTED',
          count: (purchaseOrderStatistics?.NOT_STARTED ?? '-').toString(),
        },
      ],
    },
  ].filter(Boolean) as CardDetail[]

  const navigate = useNavigate()

  return (
    <MotionDiv>
      <div>
        <h1 className="h4-bold-black">Orders</h1>
        <p className="p-sm-regular-1">
          Manage all purchase and sales orders in one place.
        </p>
        {isSalesOrdersStatsLoading || isPurchaseOrdersStatsLoading ? (
          <FullPageLoader />
        ) : (
          <div className="md:grid-flow-col-2 mt-2 grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
            {cardDetails.map((card) => (
              <Card
                key={card.title}
                title={card.title}
                subtitle={card.year}
                content={
                  <div className="grid grid-cols-2 gap-2 pt-4">
                    {card.Order.map((subCard) => (
                      <Chip
                        type="oval"
                        key={subCard.name}
                        title={subCard.name}
                        value={subCard.count} // This is now a string
                      />
                    ))}
                  </div>
                }
                rightIcon={
                  <img src={arrowRight} alt="" style={{ height: '15px' }} />
                }
                leftIcon={
                  <img src={card.icon} alt="" style={{ height: '24px' }} />
                }
                titleStyle="font-medium text-gray-800"
                subtitleStyle="text-gray-600 text-xs"
                onClick={() => {
                  localStorage.removeItem('PlanId')
                  navigate(card.route)
                }}
              />
            ))}
          </div>
        )}
      </div>
    </MotionDiv>
  )
}

export default Order
