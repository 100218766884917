import { CloseRounded } from '@mui/icons-material'
import { useState } from 'react'
import { toast } from 'libs/toast'
import { useTranslation } from 'react-i18next'
import Button from 'libs/button/Button'
import { useDeletePlantMutation } from 'pages/plants/plants-endpoints/plants.endpoints'

function DeletePlant({ plant, close }: any) {
  const [deletePlant, { isLoading }] = useDeletePlantMutation()
  const [formData, setFormData] = useState({ name: '' })
  const { t } = useTranslation()
  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }
  const handleDelete = () => {
    if (plant?._id && formData.name === plant?.name) {
      deletePlant({ plantId: plant?._id })
        .unwrap()
        .then(() => {
          toast.success(`The Plant ${plant?.name} is removed successfully.`)
          close()
        })
        .catch((error) => {
          toast.error(error?.data?.detail ?? `Failed to remove a Plant. Please try again.`)
        })
    }
  }
  return (
    <div>
      <div className="z-50 w-96 rounded-2xl bg-white p-4 shadow-2xl">
        <div className="mb-3 flex justify-between">
          <div>
            <h5 className="h4">Delete {plant?.name} ?</h5>
          </div>
          <div>
            <div className="cursor-pointer">
              <CloseRounded onClick={close} />
            </div>
          </div>
        </div>
        <p className="p-xs-regular">
          {t('delete_text')}
          <span className="break-words p-xs-bold"> "{plant?.name}" ? </span>
        </p>
        <div className="mb-2 mt-2 flex">
          {plant?.process?.map((item: any) => (
            <div key={item} className="mb-1 mr-1 rounded-[28px] bg-[#F2F2F2] px-2 py-0.5 text-[#434C52] p-xs-regular">
              {item}
            </div>
          ))}
        </div>
        <p className="p-xs-regular">
          Your historical data related to this plant will remain unaffected. However, to recover any data related to
          this plant, please contact our tech support at support@catalystdomainname.
        </p>

        <div>
          <label className="mb-1 p-sm-regular">
            Plant name <span className="text-error">*</span>
          </label>
          <div>
            <div className="relative py-1">
              <input
                required
                type="text"
                name="name"
                value={formData?.name}
                onChange={handleInputChange}
                className={`h-[28px] w-full rounded border border-solid border-[#E0E0E0] p-2 pr-8 text-sm outline-none p-xs-regular`}
                placeholder="Please enter plant name to delete"
              />
            </div>
          </div>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={close}> Close</Button>
          <Button disabled={formData.name !== plant?.name} onClick={handleDelete} loading={isLoading} color="error">
            {' '}
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeletePlant
