import { toast as _toast, ToastOptions } from 'react-toastify'
import NewToastFailed from 'assets/icons/toast/NewToastFailed'
import NewToastSuccess from 'assets/icons/toast/NewToastSuccess'

/**
 * `toast` is an object that provides custom toast notifications.
 * It has a `success` method that displays a success toast notification.
 *
 * @property {function} success - This method takes a message and optional ToastOptions.
 * It displays a success toast notification with the provided message and options.
 * The toast notification uses the 'NewToastSuccess' icon and has a green background.
 * Additional options can be provided to customize the toast notification.
 *
 * @param {string} message - The message to display in the toast notification.
 * @param {ToastOptions} [options] - Optional. Additional options to customize the toast notification.
 *
 * @returns {React.ReactText} A ReactText that can be used to remove the toast programmatically.
 */
export const toast = {
  success: (message: React.ReactNode, options?: ToastOptions) =>
    _toast.success(message, {
      icon: NewToastSuccess,
      style: {
        background: '#1F8035',
      },
      closeButton: false,
      ...options,
    }),
  error: (message: React.ReactNode, options?: ToastOptions) =>
    _toast.success(message, {
      icon: NewToastFailed,
      style: {
        background: '#B20707',
      },
      closeButton: false,
      ...options,
    }),
}
