import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useGetAllProcessesQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'

import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

const defaultFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
}

interface Filters {
  operations: string[]
  updated_between: number[]
  created_between: number[]
  work_centers: string[]
}
interface FormValues {
  operations: string[]
  work_centers: string[]
  updated_between: number[]
  created_between: number[]
}

const FilterRouting: React.FC<Props> = ({
  closePanel,
  setFilters,
  filters,
}: Props) => {
  const initialValues: FormValues = {
    work_centers: filters.work_centers ?? [],
    operations: filters.operations ?? [],
    created_between:
      filters?.created_between?.length == 0 ? [] : filters?.created_between,
    updated_between:
      filters?.updated_between?.length == 0 ? [] : filters?.updated_between,
  }
  const plantData = useSelector(selectSelectedItem)
  const { data: operations } = useGetAllProcessesQuery(
    { plantId: plantData?.id, filters: defaultFilters },
    { skip: !plantData?.id },
  )
  const operationsOptions = operations?.data?.map((item: any) => {
    return {
      label: item?.name,
      value: item?._id,
    }
  })

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      search_text: '',
      operations: [],
      work_centers: [],
      updated_between: [],
      created_between: [],
      sort_by: 'created_on',
      sort_dir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        created_between:
          values.created_between == null ? [] : values.created_between,
        updated_between:
          values.updated_between == null ? [] : values.updated_between,
        operations: values.operations,
        work_centers: values.work_centers,
      }))
    },
  })
  // Do not delete this comment
  // const workCenterOptions =
  //   operationsOptions?.data
  //     ?.filter((item: any) =>
  //       filterFormik?.values?.operations?.includes(item?._id),
  //     )
  //     .flatMap(
  //       (item: any) =>
  //         item?.work_center?.map((shift: any) => ({
  //           label: shift?.name,
  //           value: shift?.id,
  //         })) || [],
  //     ) || []

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Operations"
          placeholder="Select operation"
          options={operationsOptions}
          formik={filterFormik}
          name="operations"
        />
        {/* Work Cenrers */}
        <Field
          type="multiselect"
          label="Work Centers"
          placeholder="Select work center"
          options={[]}
          disabled={filterFormik?.values?.operations?.length === 0}
          formik={filterFormik}
          name="work_centers"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Created Between"
          formik={filterFormik}
          name="created_between"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Updated Between"
          formik={filterFormik}
          name="updated_between"
        />
      </div>
      <div className="flex justify-between pt-5">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterRouting
