import React from "react";

const PlusIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_998_2662"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_998_2662)">
        <path
          fill="#fff"
          d="M9.166 10.834H5a.806.806 0 01-.594-.24.806.806 0 01-.24-.594c0-.236.08-.434.24-.593.16-.16.358-.24.594-.24h4.166V5c0-.236.08-.434.24-.593.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.24.357.24.593v4.167H15c.236 0 .434.08.594.24.16.16.24.357.24.593s-.08.434-.24.594a.807.807 0 01-.594.24h-4.167V15c0 .236-.08.434-.24.594a.806.806 0 01-.593.24.806.806 0 01-.594-.24.806.806 0 01-.24-.594v-4.166z"
        ></path>
      </g>
    </svg>
  );
}

export default PlusIcon;
