import { useCallback, useState } from 'react'

import { debounce } from 'lodash'

export const useDebouncedSearch = (delay: number = 800) => {
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [inputValue, setInputValue] = useState<string>('')

  const debouncedSearch = useCallback(
    debounce((searchValue: string) => {
      setSearch(searchValue)
    }, delay),
    [delay], 
  )

  const handleSearch = (searchValue: string) => {
    setInputValue(searchValue)
    debouncedSearch(searchValue)
  }

  return { searchValue: search, handleSearch, inputValue }
}
