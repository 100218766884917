import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  category: string
  quantity_from: number | string
  quantity_to: number | string
  updatedBetween: number[]
  quantityBetween: number[]
  is_verified: string
}

interface FormValues {
  category: string
  quantityBetween: number[]
  updatedBetween: number[]
  is_verified: string
  quantity_from: number
  quantity_to: number
}

const FilterProduction: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const initialFormValues: FormValues = {
    category: filters?.category ?? 'ALL',
    quantity_from: filters?.quantityBetween[0] ?? '',
    quantity_to: filters?.quantityBetween[1] ?? '',
    updatedBetween: filters?.updatedBetween.length == 0 ? [] : filters.updatedBetween,
    quantityBetween: [],
    is_verified: filters?.is_verified ?? 'ALL',
  }

  // ********************************************** Reset Form Data ****************************************//
  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      category: 'ALL',
      quantityBetween: [],
      updatedBetween: [],
      is_verified: 'ALL',
      sortBy: 'verified_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  // **********************************************Form Update ***********************************************//
  const filterFormik = useFormik<FormValues>({
    initialValues: initialFormValues,
    onSubmit: (values: any) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        category: values.category,
        quantityBetween:
          values.quantity_from == 0 && values.quantity_to == 0
            ? []
            : [values.quantity_from == '' ? 0 : values.quantity_from, values.quantity_to],
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
        is_verified: values.is_verified,
      }))
    },
  })

  // ***************************************** Radio Options ***************************************//
  const verificatonOptions = [
    {
      label: 'All',
      value: 'ALL',
      sublabel: '',
      disabled: '',
    },
    {
      label: 'Verified',
      value: 'VERIFIED',
      sublabel: '',
      disabled: '',
    },
    {
      label: 'Unverified',
      value: 'UNVERIFIED',
      sublabel: '',
      disabled: '',
    },
  ]

  const categoryOptions = [
    {
      label: 'All',
      value: 'ALL',
      sublabel: '',
      disabled: '',
    },
    {
      label: 'Input',
      value: 'INPUT',
      sublabel: '',
      disabled: '',
    },
    {
      label: 'Output',
      value: 'OUTPUT',
      sublabel: '',
      disabled: '',
    },
  ]

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Production Category */}
        <div className="flex flex-col ">
          <Field
            type="radio"
            label="Production Category"
            options={categoryOptions}
            formik={filterFormik}
            name="category"
          />
        </div>
        {/* Quantity */}
        <div className="flex flex-col py-1">
          <div className="flex justify-between gap-4">
            <Field
              type="number"
              label="Quantity From"
              formik={filterFormik}
              placeholder="Quantity from"
              name="quantity_from"
            />
            <Field
              type="number"
              label="Quantity To"
              placeholder="Quantity to"
              formik={filterFormik}
              name="quantity_to"
            />
          </div>
        </div>
        {/* Last updated by */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last Edited Between"
          formik={filterFormik}
          name="updatedBetween"
        />
        {/* Varification Status */}
        <Field
          type="radio"
          label="Verficiation Status"
          options={verificatonOptions}
          formik={filterFormik}
          name="is_verified"
        />

        {/* dead code */}
        <div className="flex justify-between pt-5 ">
          <div>
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset
            </Button>
          </div>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>

            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterProduction
