import { useEffect, useMemo, useState } from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table'
import FirstPageArrow from 'assets/icons/paginated-table/FirstPageArrow'
import icons from 'assets/index'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { AnimatePresence, motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'
import { t } from 'i18next'
import { formatNumber } from 'utils/numberHandlers'
import Loader from 'libs/loader/customloader/Loader'
import { ExtendedColumn, Paging } from 'store/services/utilsTypes'
import LastPageArrow from 'assets/icons/paginated-table/LastPageArrow'
import PreviousPageArrow from 'assets/icons/paginated-table/PreviousPageArrow'
import NextPageArrow from 'assets/icons/paginated-table/NextPageArrow'
/**
 * Props for the PaginatedTable component
 *
 * @typedef {Object} PaginatedTableProps
 * @property {any} [emptyMessage] - Placeholder component to show when there is an error.
 * @property {Column[]} columns - Column definitions for the table.
 * @property {any[]} rows - Data rows to be displayed in the table.
 * @property {boolean} [loading] - Indicates whether the table is in a loading state.
 * @property {{ sortBy?: string; sortDir?: string }} [sorting] - Sorting configuration.
 * @property {(pageSize: number) => void} [onPageSizeChange] - Callback for when the page size changes.
 * @property {(pageNumber: number) => void} [onPageNumberChange] - Callback for when the page number changes.
 * @property {(row: any) => void} [onSingleClick] - Callback for a single row click.
 * @property {(row: any) => void} [onDoubleClick] - Callback for a double row click.
 * @property {number} [pageSize] - Current page size.
 * @property {number} [currentPageNumber] - Current page number.
 * @property {Paging} [pagination] - Pagination information.
 * @property {boolean} [showCheckbox] - Indicates whether to show checkboxes.
 *  * @property {boolean} [subRow] - Indicates whether sub-rows are enabled.
 * @property {string[]} [batchData] - Data for batch operations.
 * @property {any} [onSelectAll] - Callback for selecting all rows.
 *  * @property {boolean} [showArrow] - Indicates whether to show arrow icons.
 * @property {(columnId: any) => void} [onColumnSort] - Callback for column sorting.
 * @property {boolean} [hidePagination] - Indicates whether to hide pagination.
 */

const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10_per_page' },
  { value: 25, label: '25_per_page' },
  { value: 50, label: '50_per_page' },
]

interface PaginatedTableProps<T extends object> {
  emptyMessage?: string
  columns: ExtendedColumn<T>[]
  rows: any
  loading?: boolean
  sorting?: { sortBy?: string; sortDir?: string }
  onPageSizeChange?: (pageSize: number) => void
  onPageNumberChange?: (pageNumber: number) => void
  onSingleClick?: (Row: any) => void
  onDoubleClick?: (Row: any) => void
  pageSize?: number
  currentPageNumber?: number
  pagination?: Paging
  showCheckbox?: boolean
  subRow?: boolean
  showArrow?: boolean
  batchData?: string[]
  onSelectAll?: any
  onColumnSort?: (coulumnId: any) => void
  hidePagination?: boolean
}
const PaginatedTable = <T extends object>({
  emptyMessage,
  columns,
  rows: initialData,
  loading,
  sorting,
  onPageSizeChange,
  onPageNumberChange,
  onSingleClick,
  onDoubleClick,
  pageSize,
  currentPageNumber,
  pagination,
  showCheckbox = false,
  subRow = false,
  batchData = [],
  showArrow = false,
  onSelectAll,
  onColumnSort,
  hidePagination = false,
}: PaginatedTableProps<T>) => {
  const data = useMemo(() => initialData || [], [initialData])

  useEffect(() => {
    if (data?.length == 0) {
      // onPageNumberChange &&
      //   onPageNumberChange((currentPageNumber && currentPageNumber - 1) || 1)
    }
  }, [data])

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  ) as any

  const [expandedRows, setExpandedRows] = useState<any>({})

  const toggleRowExpansion = (rowIndex: any) => {
    setExpandedRows((prevState: any) => {
      if (prevState[rowIndex]) {
        return {}
      } else {
        return { [rowIndex]: true }
      }
    })
  }

  const calculatePageRange = (
    pageIndex: any,
    pageSize: any,
    filteredRows: any,
  ) => {
    const start = filteredRows > 0 ? (pageIndex - 1) * pageSize + 1 : 0
    const end = Math.min(pageIndex * pageSize, filteredRows)
    const totalFilteredRows = filteredRows

    return `${start} - ${end} ${t('of')} ${totalFilteredRows}`
  }

  const clickDelay = 200

  let clickTimer: any

  const totalNotApprovedRows = rows
    ?.filter(
      (entry: any) =>
        entry?.original?.is_order === false ||
        entry?.original?.verified_by === null ||
        entry?.original?.status === 'PENDING',
    )
    .map((entry: any) => entry.original?._id)

  const countTotalProducts = function (storageAreas: any) {
    let products = 0
    storageAreas?.forEach((storageArea: any) => {
      products += storageArea?.products?.length
    })
    const totalProducts = formatNumber(products)
    return totalProducts
  }

  return (
    <div>
      <div className="w-full overflow-x-auto rounded-b-md rounded-t-md bg-white ">
        <div>
          <table
            {...getTableProps()}
            className="resizable-te relative table min-w-full"
          >
            {/* heading */}
            <thead className="top-0  ">
              {headerGroups?.map((headerGroup: any, index: any) => {
                return (
                  <tr
                    key={index}
                    className=" overflow-hidden bg-[#0E1B28]"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup?.headers.map(
                      (column: any, columnIndex: number) => {
                        return (
                          <th
                            key={columnIndex}
                            {...column?.getHeaderProps(
                              column.getSortByToggleProps({}),
                            )}
                            className={`text-start p-xs-regular ${
                              columnIndex === headerGroup.headers.length - 1
                                ? 'sticky-header1'
                                : ''
                            } `}
                            onClick={() =>
                              column?.disableSortBy !== true &&
                              onColumnSort &&
                              onColumnSort(column?.id)
                            }
                          >
                            <div
                              className={`group flex items-center gap-1 ${column.align === 'right' ? ' justify-end ' : ''} ${column.align === 'center' ? ' justify-center ' : ''} }  border-[#75838D] py-1  text-start 
                            p-xs-regular`}
                              style={{
                                minWidth: column.width,
                                maxWidth: 'auto',
                                color: '#FFFFFF',
                              }}
                            >
                              <div
                                className={`group flex w-full  items-center gap-1 ${column.align === 'right' ? ' justify-end ' : ''} ${column.align === 'center' ? ' justify-center ' : ''} border-[#75838D] px-2  py-1 text-start  p-xs-regular  ${
                                  columnIndex === headerGroup.headers.length - 1
                                    ? 'border-r-0'
                                    : 'border-r-[1.4px]'
                                }`}
                                style={{
                                  minWidth: column.width,
                                  maxWidth: 'auto',
                                  color: '#FFFFFF',
                                }}
                              >
                                {showCheckbox && columnIndex === 0 && (
                                  <span className="ml-2 flex items-center">
                                    <input
                                      data-testid="select-all-checkbox"
                                      type="checkbox"
                                      className="form-checkbox mr-1 h-3 w-3 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                      checked={
                                        batchData.length === 0
                                          ? false
                                          : totalNotApprovedRows.length ===
                                            batchData.length
                                      }
                                      onChange={() =>
                                        onSelectAll && onSelectAll(rows)
                                      }
                                    />
                                  </span>
                                )}

                                <span
                                  data-testid="column-text"
                                  className={`flex items-center gap-1 ${column.align === 'right' ? ' flex-row-reverse justify-end ' : ''} ${column.align === 'center' ? ' justify-center ' : ''}`}
                                >
                                  {column?.render('Header')}
                                  {column.disableSortBy !== true && (
                                    <span>
                                      {sorting?.sortBy === column?.id ? (
                                        sorting?.sortDir === 'ASC' ? (
                                          <img
                                            src={icons.SortData}
                                            data-testid="sortIcon"
                                            alt="ascending"
                                          />
                                        ) : (
                                          <img
                                            src={icons.SortData}
                                            data-testid="sortIcon"
                                            alt="descending"
                                            style={{
                                              transform: 'rotate(180deg)',
                                            }}
                                          />
                                        )
                                      ) : (
                                        <img
                                          className="opacity-0 duration-200 group-hover:opacity-100"
                                          src={icons.SortData}
                                          data-testid="sortIcon"
                                          alt="unsorted"
                                        />
                                      )}
                                    </span>
                                  )}
                                </span>
                                {columnIndex ===
                                  headerGroup.headers.length - 1 && (
                                  <span></span>
                                )}
                              </div>
                            </div>
                          </th>
                        )
                      },
                    )}
                  </tr>
                )
              })}
            </thead>
            <tbody>
              {rows?.length > 0 &&
                rows?.map((row: any, rowIndex: number) => {
                  prepareRow(row)
                  const isEvenRow = rowIndex % 2 === 0
                  const isExpanded = expandedRows[rowIndex]

                  return (
                    <>
                      <tr
                        className={`
                            sticky-row1
                            border-x border-gray-100  hover:bg-primary-200  ${isEvenRow ? 'bg-white' : 'bg-gray-100'} `}
                        {...row.getRowProps()}
                        onClick={() => {
                          clearTimeout(clickTimer) // Clear any existing timeout
                          clickTimer = setTimeout(() => {
                            onSingleClick && onSingleClick(row.original)
                            toggleRowExpansion(row.index)

                            // Perform single-click action after the delay
                          }, clickDelay)
                        }}
                        onDoubleClick={() => {
                          clearTimeout(clickTimer) // Clear any existing timeout
                          onDoubleClick && onDoubleClick(row.original) // Perform double-click action
                        }}
                      >
                        {row.cells.map((cell: any, cellIndex: number) => {
                          return (
                            <td
                              key={cellIndex}
                              {...cell.getCellProps()}
                              className={`border-b border-gray-100  p-xs-regular ${
                                cellIndex === row.cells.length - 1
                                  ? 'sticky-cell1'
                                  : ''
                              } ${cellIndex === row.cells.length - 1 && !isEvenRow ? 'sticky-cell-even1' : ''}`}
                              style={{ width: cell.column.width }}
                            >
                              <div
                                className={`flex flex-row px-2 py-2 ${cell?.column?.align === 'right' ? ' justify-end ' : 'justify-start'} ${cell?.column?.align === 'center' ? ' justify-center ' : ''} `}
                              >
                                {showCheckbox && cellIndex === 0 ? (
                                  <div className="flex gap-2">
                                    <input
                                      onClick={onSingleClick}
                                      type="checkbox"
                                      className="ml-2"
                                      checked={batchData?.some(
                                        (item: any) =>
                                          item === row?.original?._id,
                                      )}
                                      disabled={
                                        row?.original?.verified_by
                                          ? row?.original?.verified_by !== null
                                          : !row?.original?.is_order
                                            ? row?.original?.is_order
                                            : row?.original?.status != 'PENDING'
                                      }
                                    />
                                    {cell.render('Cell')}
                                  </div>
                                ) : showArrow && cellIndex === 0 ? (
                                  <div className="flex items-center gap-3">
                                    <span
                                      className={`flex items-center justify-center duration-200 ${!isExpanded && '-rotate-90'}`}
                                    >
                                      <ArrowDown color="" />
                                    </span>
                                    {cell.render('Cell')}
                                  </div>
                                ) : (
                                  cell.render('Cell')
                                )}
                              </div>
                            </td>
                          )
                        })}
                      </tr>

                      <AnimatePresence>
                        {isExpanded && subRow && (
                          <motion.div
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={dropdownMotionProps}
                            style={{ zIndex: '2' }}
                          >
                            <div
                              data-testid="expanded-row"
                              className="flex gap-32 rounded bg-white px-6 py-4"
                            >
                              <div>
                                <p className="w-60 p-xs-regular">
                                  {t('warehouse.total_storage_areas')}:{' '}
                                  {formatNumber(
                                    row.original?.storage_areas?.length,
                                  )}
                                </p>
                                <p className="mt-3 p-xs-bold">
                                  {t('warehouse.storage_areas')}{' '}
                                </p>
                                {row.original?.storage_areas?.map(
                                  (storage: any) => {
                                    return (
                                      <p
                                        className="mt-3 p-xs-regular"
                                        key={storage._id}
                                      >
                                        {storage?.name}
                                      </p>
                                    )
                                  },
                                )}
                              </div>
                              <div>
                                <p className="w-60 p-xs-regular">
                                  {t('warehouse.total_products')}:
                                  {formatNumber(
                                    Number(
                                      countTotalProducts(
                                        row.original?.storage_areas,
                                      ),
                                    ),
                                  )}
                                </p>
                                <p className="mt-3 p-xs-bold">
                                  {t('warehouse.number_of_products')}
                                </p>
                                {row.original?.storage_areas?.map(
                                  (storage: any) => (
                                    <p
                                      className="mt-3 p-xs-regular"
                                      key={storage._id}
                                    >
                                      {formatNumber(storage?.products.length)}
                                    </p>
                                  ),
                                )}
                              </div>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </>
                  )
                })}
              {loading && rows.length ? (
                <div className="absolute left-1/2 top-1/2  z-30">
                  <Loader size="xl" color="primary" />
                </div>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {rows.length == 0 && (
        <div className="flex justify-center">
          <div className="p-4 text-center p-md-regular">
            {loading ? (
              <div className="flex  items-center justify-center">
                {' '}
                <Loader size="lg" color="primary" />
              </div>
            ) : (
              emptyMessage
            )}
          </div>
        </div>
      )}

      {!hidePagination && initialData?.length ? (
        <div className="relative flex items-center  justify-start gap-10 pt-6">
          <div className="flex gap-3">
            <button
              data-testid="first-btn"
              onClick={() => {
                if (pagination && pagination?.current_page > 1) {
                  onPageNumberChange && onPageNumberChange(1)
                }
              }}
              className={`p-[0.1rem] ${pagination && pagination?.current_page > 1 ? '' : 'cursor-default opacity-20'}`}
            >
              <FirstPageArrow />
            </button>
            <button
              data-testid="prev-btn"
              onClick={() => {
                if (pagination && pagination?.current_page > 1) {
                  previousPage()
                  onPageNumberChange &&
                    onPageNumberChange(
                      (currentPageNumber && currentPageNumber - 1) || 1,
                    )
                }
              }}
              className={`p-[0.1rem] ${pagination && pagination?.current_page > 1 ? '' : 'cursor-default opacity-20 '}`}
            >
              <PreviousPageArrow />
            </button>
            <button
              data-testid="next-btn"
              onClick={() => {
                if (currentPageNumber !== pagination?.total_pages) {
                  nextPage(currentPageNumber && currentPageNumber + 1)
                  onPageNumberChange &&
                    onPageNumberChange(
                      (currentPageNumber && currentPageNumber + 1) || 1,
                    )
                }
              }}
              className={`p-[0.1rem] ${currentPageNumber === pagination?.total_pages ? 'cursor-default opacity-20 ' : ''}`}
            >
              <NextPageArrow />
            </button>
            <button
              data-testid="last-btn"
              onClick={() => {
                if (currentPageNumber !== pagination?.total_pages) {
                  gotoPage(pagination?.total_pages)
                  onPageNumberChange &&
                    onPageNumberChange(pagination?.total_pages || 1)
                }
              }}
              className={`p-[0.1rem] ${currentPageNumber === pagination?.total_pages ? 'cursor-default opacity-20 ' : ''}`}
            >
              <LastPageArrow />
            </button>
          </div>

          <div className="flex flex-row items-center gap-2">
            <div
              style={{ paddingRight: '4px' }}
              className="rounded border border-[#D2DADF]  py-[2px] outline-none p-xs-regular"
            >
              <select
                className="outline-none p-xs-regular"
                id="pageSizeSelect"
                data-testid="pageSizeSelect"
                value={pageSize}
                onChange={(e) => {
                  onPageSizeChange && onPageSizeChange(Number(e.target.value))
                }}
              >
                {PAGE_SIZE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {t(option.label)}
                  </option>
                ))}
              </select>
            </div>
            <span className="p-xs-regular-dim">
              {t('showing')}{' '}
              {calculatePageRange(
                pagination?.current_page,
                pagination?.page_size,
                formatNumber(pagination?.total_items_count || 0),
              )}
            </span>
          </div>

          <div data-testid="columns-count" className="p-xs-regular-dim">
            {t('this_table_has_a_total_of')} {columns?.length} columns.
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default PaginatedTable
