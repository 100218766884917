import Button from 'libs/button/Button'
import NewEditableTable from 'libs/table/NewEditableTable'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const documentsColumns = [
  {
    title: 'Document Description',
    accessor: 'description',
    flex: 1,
    editable: true,
    placeholder: 'Enter Document Description',
  },
  {
    title: 'Document',
    accessor: 'document',
    flex: 1,
    fieldType: 'file',
    editable: true,
    placeholder: 'Select Document',
  },
]

const Documents = ({ setId, setTabNumber, formik, isLoading }: any) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [data, setData] = useState([])

  const handleSubmit = () => {
    formik.setFieldValue('documents', data)
    formik.handleSubmit()
  }

  useEffect(() => {
    setId(id)
    setTabNumber(4)
  }, [])

  function validateFile(file: any) {
    // Define allowed types and max size (in bytes)
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png']
    const maxSizeInBytes = 25 * 1024 * 1024 // 25 MB

    // Check if the file type is not one of the allowed types
    const isInvalidType = !allowedTypes.includes(file.type)

    // Check if the file size is more than 25 MB
    const isInvalidSize = file.size > maxSizeInBytes

    // Return true if the file size is more than 25 MB or the type is not allowed
    return isInvalidSize || isInvalidType
  }

  // Function to validate an array of files
  function validateFileArray(files: any) {
    return files?.some((item: any) => {
      if (item.document && item.document.size && item.document.type) {
        return validateFile(item.document) // Validate each document
      }
      return false // If no size or type, consider it valid
    })
  }

  return (
    <div className="h-screen-90 flex w-full flex-col px-2">
      <div className="flex-grow">
        <div className="h5-1">Upload Documents</div>
        <p className="primary-gray-4 p-sm-regular">
          Upload relevant documents such as manuals, warranties, and maintenance records.
        </p>
        <div className="mt-4">
          <NewEditableTable
            defaultRows={formik?.values?.documents}
            columns={documentsColumns}
            addButtonTitle="Upload New Document"
            onChange={(values: any) => {
              setData(values)
            }}
            tooltipContent="Delete Document"
            error={
              validateFileArray(data)
                ? 'Invalid file format or size. Please upload a document in PDF, JPEG, or PNG format, up to 25 MB.'
                : ''
            }
          />
        </div>
      </div>
      <div className="mt-5 flex justify-between">
        <div className="flex gap-3">
          <Button type="button" disabled={isLoading} onClick={() => navigate('/maintenance/machines')}>
            Cancel
          </Button>
        </div>
        <div className="flex justify-end gap-2">
          <div className="flex gap-3">
            <Button
              type="button"
              disabled={isLoading}
              onClick={() => {
                formik.setFieldValue('documents', data)

                navigate(-1)
              }}
            >
              Previous
            </Button>
          </div>
          <div>
            <Button color="success" type="submit" disabled={validateFileArray(data)} loading={isLoading} onClick={handleSubmit}>
              {id ? 'Save' : 'Add'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Documents
