import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import * as Yup from 'yup'
import FullPageLoader from 'libs/loader/FullPageLoader'
import {
  useGetPurchaseOrderByIDQuery,
  usePostPurchaseOrderMutation,
  usePutPurchaseOrderMutation,
} from 'pages/orders/purchase-order/purchase-orders-endpoints/purchaseOrders.endpoints'
import { useGetVendorsQuery } from 'pages/contacts/vendor-management/vendor-endpoints/vendor.endpoints'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import EditableTable from 'libs/table/EditableTable'
import { STATUS_OPTIONS } from 'pages/orders/sales-order/sales-order-endpoints/salesOrders.constants'
import { getTodaysDateTimeInUnix } from 'utils/dateTimeHandlers'

type Props = {
  close: () => void
  purchaseOrderID: any
  manufactureOrderFormik: any
  data: any
}
type productsFormik = {
  products: any
}

const productfilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'updated_on',
  sortDir: 'DESC',
  searchText: '',
}

const QuickAddEditPurchase = ({
  close,
  purchaseOrderID,
  manufactureOrderFormik,
  data,
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const plantId = useSelector(selectSelectedItem)
  const [updatePurchaseOrder, { isLoading: updatePurchaseOrderLoading }] =
    usePutPurchaseOrderMutation()
  const [addPurchaseOrder, { isLoading: addPurchaseOrderLoading }] =
    usePostPurchaseOrderMutation()

  const { data: purchaseOrder, isFetching: isPurchaseOrderFetching } =
    useGetPurchaseOrderByIDQuery(
      {
        plant_id: plantId?.id,
        id: purchaseOrderID,
      },
      {
        skip: !plantId.id || !purchaseOrderID,
      },
    )
  const { data: vendors, isLoading: isVendorLoading } = useGetVendorsQuery(
    {
      plant_id: plantId?.id,
      // filters: filters,
    },
    { skip: !plantId?.id },
  )

  const formik = useFormik({
    initialValues: {
      purchaseOrderID: '',
      vendorID: '',
      arrivalDate: getTodaysDateTimeInUnix(),
      status: '',
    },
    validationSchema: Yup.object({
      purchaseOrderID: Yup.string().required('Purchase Order ID is required'),
      vendorID: Yup.string().required('Supplier Name is required'),
      arrivalDate: Yup.date().required('Delivery Date is required'),
      status: Yup.string().required('Status is required'),
    }),
    onSubmit: (values) => {
      const product =
        productsFormik?.values?.products
          ?.filter((product: any) => product?.product != null)
          .map((product: any) => ({
            product_id: product?.product?._id,
            quantity: product?.quantity,
          })) || []
      const payload = {
        purchaseOrderID: values.purchaseOrderID,
        vendorID: values.vendorID,
        arrivalDate: values.arrivalDate,
        status: values.status,
        products: product,
        description: null,
      }

      if (purchaseOrder == null) {
        addPurchaseOrder({ plant_id: plantId?.id, data: payload })
          .unwrap()
          .then((res) => {
            const transformedData: any = [
              {
                purchaseOrderID: {
                  _id: purchaseOrderID,
                  vendorID: payload.vendorID,
                  arrivalDate: payload.arrivalDate,
                  products: payload.products.map((product: any) => ({
                    product_id: product._id,
                    quantity: product.quantity,
                  })),
                  purchaseOrderID: payload.purchaseOrderID,
                  status: payload.status,
                },
              },
            ]

            const existingPurchaseOrders = data?.flat() || []

            const updatedPurchaseOrders = [
              ...existingPurchaseOrders,
              transformedData,
            ]

            // once the quick add is done, we need to update the new editable table
            manufactureOrderFormik?.setFieldValue(
              'purchaseOrders',
              updatedPurchaseOrders.flat(),
            )

            toast.success(res)
            close()
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ??
                'Something went wrong while creating the purchase order',
            )
          })
      } else {
        updatePurchaseOrder({
          plant_id: plantId.id,
          id: purchaseOrder?.id,
          data: payload,
        })
          .unwrap()
          .then((res) => {
            const transformedData: any = [
              {
                purchaseOrderID: {
                  _id: purchaseOrderID,
                  vendorID: payload.vendorID,
                  arrivalDate: payload.arrivalDate,
                  products: payload.products.map((product: any) => ({
                    product_id: product._id,
                    quantity: product.quantity,
                  })),
                  purchaseOrderID: payload.purchaseOrderID,
                  status: payload.status,
                },
              },
            ]

            const existingPurchaseOrders = data?.flat() || []

            const updateExistingOrders = existingPurchaseOrders.filter(
              (po: any) => po.purchaseOrderID._id !== purchaseOrderID,
            )

            const updatedPurchaseOrders = [
              ...updateExistingOrders,
              transformedData,
            ]

            // check the ID that mathces the purchase order ID and update the existing purchase order
            manufactureOrderFormik.setFieldValue(
              'purchaseOrders',
              updatedPurchaseOrders.flat(),
            )

            toast.success(res)
            close()
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ??
                'Something went wrong while updating the purchase order',
            )
          })
      }
    },
  })

  const vendorsDataOption =
    vendors?.data?.map((vendor) => ({
      _id: vendor.id,
      label: vendor.name,
      sublabel: vendor.primaryContactNumber,
      value: vendor?.id,
    })) || []

  const { data: products, isLoading: productLoading } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productfilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const productOpitons = products?.data
    ?.filter((item: any) => item.category === 'Raw Materials')
    .map((item: any) => ({
      label: item.name,
      value: item,
    }))

  const newProductRow = {
    product: null,
    quantity: null,
    unit_of_measure: null,
  }

  const productsFormik = useFormik<productsFormik>({
    initialValues: {
      products: [],
    },
    onSubmit: () => {},
  })

  const productColumns = [
    {
      title: 'Raw Material',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: 'name',
        options: productOpitons,
        name: 'product',
        placeholder: 'Select Product',
        type: 'select',
      },
    },
    {
      title: 'Quantity',
      align: 'right',
      hideRightBorder: true,
      content: {
        placeholder: '0',
        type: 'number',
        name: 'quantity',
      },
    },
    {
      title: 'UOM',
      flex: 1,
      content: {
        placeholder: 'Unit',
        type: 'text',
        name: 'unit_of_measure',
        readOnly: true,
      },
    },
  ]

  useEffect(() => {
    if (purchaseOrder) {
      formik.resetForm({
        values: {
          purchaseOrderID: purchaseOrder.purchaseOrderID,
          vendorID: purchaseOrder.vendor?.id ?? ' ',
          arrivalDate: purchaseOrder.arrivalDate,
          status: purchaseOrder.status,
        },
      })
      productsFormik.resetForm({
        values: {
          products: purchaseOrder?.products?.map((product: any) => ({
            product: { _id: product?.id, name: product?.name },
            unit_of_measure: product?.unit_of_measure,
            quantity: product?.quantity,
          })),
        },
      })
    }
  }, [purchaseOrder])

  return (
    <>
      {isPurchaseOrderFetching || isVendorLoading ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <Field
              type="text"
              label="Purchase Order ID"
              formik={formik}
              required={true}
              name="purchaseOrderID"
              placeholder="Enter Purchase order ID"
            />
            <Field
              type="select"
              label="Supplier Name"
              formik={formik}
              options={vendorsDataOption}
              defaultValue={
                vendorsDataOption.find(
                  (option) => option?.value == formik?.values?.vendorID,
                ) ?? null
              }
              required={true}
              name="vendorID"
              placeholder="Enter Supplier Name"
            />
            <Field
              type="select"
              label="Status"
              formik={formik}
              required={true}
              name="status"
              defaultValue={STATUS_OPTIONS.find(
                (option) => option?.value == formik?.values?.status,
              )}
              options={
                purchaseOrderID ? STATUS_OPTIONS : STATUS_OPTIONS.slice(0, 2)
              }
              placeholder="Enter Status"
            />
            <Field
              type="date"
              required={true}
              label="Arrival Date"
              shouldDisablePastDate
              formik={formik}
              name="arrivalDate"
            />
            <EditableTable
              formik={productsFormik as any}
              heading={'Raw Materials'}
              onSelect={(product: any, index: number) => {
                productsFormik.setFieldValue(
                  `products.${index}.unit_of_measure`,
                  product.unit_of_measure,
                )
                productsFormik.setFieldValue(`products.${index}.quantity`, null)
              }}
              loading={productLoading}
              columns={productColumns}
              emptyMessage="+ Click on the Add Product button to add a new product"
              newRow={newProductRow}
              name="products"
              addButtonTitle="Add Product"
            />
            <div className="mt-4 flex justify-between ">
              <div>
                {purchaseOrder ? (
                  <Button
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/orders/purchase-order/edit/${purchaseOrder.id}`,
                        { state: { from: currentPath } },
                      )
                    }
                  >
                    Edit Details
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => navigate(`/orders/purchase-order/add`)}
                  >
                    Add Details
                  </Button>
                )}
              </div>
              <div className="flex gap-2">
                <Button onClick={close}>{t('cancel')}</Button>
                <Button
                  color="primary"
                  loading={
                    addPurchaseOrderLoading || updatePurchaseOrderLoading
                  }
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default QuickAddEditPurchase
