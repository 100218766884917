const keyboard = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_24017_13337)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28 13H12C10.9 13 10.01 13.9 10.01 15L10 25C10 26.1 10.9 27 12 27H28C29.1 27 30 26.1 30 25V15C30 13.9 29.1 13 28 13ZM28 15V25H12V15H28ZM21 16H19V18H21V16ZM19 19H21V21H19V19ZM18 16H16V18H18V16ZM16 19H18V21H16V19ZM15 19H13V21H15V19ZM13 16H15V18H13V16ZM24 22H16V24H24V22ZM22 19H24V21H22V19ZM24 16H22V18H24V16ZM25 19H27V21H25V19ZM27 16H25V18H27V16Z"
          fill="#75838D"
        />
      </g>
      <defs>
        <clipPath id="clip0_24017_13337">
          <rect width="40" height="40" rx="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default keyboard
