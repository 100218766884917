function FirstPageArrow() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.50016 5L9.75016 1.75C9.90294 1.59722 9.97933 1.40278 9.97933 1.16667C9.97933 0.930556 9.90294 0.736111 9.75016 0.583333C9.59738 0.430556 9.40294 0.354167 9.16683 0.354167C8.93072 0.354167 8.73628 0.430556 8.5835 0.583333L4.75016 4.41667C4.66683 4.5 4.60781 4.59028 4.57308 4.6875C4.53836 4.78472 4.52099 4.88889 4.52099 5C4.52099 5.11111 4.53836 5.21528 4.57308 5.3125C4.60781 5.40972 4.66683 5.5 4.75016 5.58333L8.5835 9.41667C8.73628 9.56944 8.93072 9.64583 9.16683 9.64583C9.40294 9.64583 9.59738 9.56944 9.75016 9.41667C9.90294 9.26389 9.97933 9.06944 9.97933 8.83333C9.97933 8.59722 9.90294 8.40278 9.75016 8.25L6.50016 5ZM0.833496 0C0.597385 0 0.399471 0.0798611 0.239754 0.239583C0.0800361 0.399306 0 0.597222 0 0.833333V9.16667C0 9.40278 0.0800361 9.60069 0.239754 9.76042C0.399471 9.92014 0.597385 10 0.833496 10C1.06961 10 1.26752 9.92014 1.42724 9.76042C1.58696 9.60069 1.66683 9.40278 1.66683 9.16667V0.833333C1.66683 0.597222 1.58696 0.399306 1.42724 0.239583C1.26752 0.0798611 1.06961 0 0.833496 0Z"
        fill="#041220"
      />
    </svg>
  )
}


export default FirstPageArrow;