import { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Field from 'libs/field'
import Button from 'libs/button/Button'
import { BaseFilters } from 'store/services/utilsTypes'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import { WarehouseAdditionalFilters } from '../warehouses-endpoints/warehouseTypes'

interface Props {
  closePanel: () => void
  setFilters: Dispatch<SetStateAction<BaseFilters & WarehouseAdditionalFilters>>
  filters: BaseFilters & WarehouseAdditionalFilters
}

const productFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
  search_text: '',
}

const FilterPanel = ({ closePanel, filters, setFilters }: Props) => {
  const plantId = useSelector(selectSelectedItem)

  const { data: products } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productFilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  // Convert the array of timestamps into an array of formatted dates

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      search_text: '',
      sort_by: 'created_on',
      sort_dir: 'DESC',
      created_between: [],
      updated_between: [],
      allowed_products: [],
    })
    closePanel()
  }

  const filterFormik = useFormik<WarehouseAdditionalFilters>({
    initialValues: {
      created_between:
        filters?.created_between?.length == 0 ? [] : filters?.created_between,
      updated_between:
        filters?.updated_between?.length == 0 ? [] : filters?.updated_between,
      allowed_products:
        filters?.allowed_products?.length == 0 ? [] : filters?.allowed_products,
    },

    onSubmit: (values) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_between:
          values.created_between == null ? [] : values.created_between,
        updated_between:
          values.updated_between == null ? [] : values.updated_between,
        allowed_products: values.allowed_products,
      }))
    },
  })

  const productOptions = products?.data?.map((item: any) => ({
    label: item.name,
    sublabel: item.code,
    value: item?._id,
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Allowed Products */}
        <Field
          type="multiselect"
          label="Allowed products"
          placeholder="Select products"
          options={productOptions}
          formik={filterFormik}
          name="allowed_products"
        />

        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date created between"
          formik={filterFormik}
          name="created_between"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last edited between"
          formik={filterFormik}
          name="updated_between"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            type="submit"
            color="primary"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterPanel
