import { useNavigate } from 'react-router-dom' //useLocation
import Cookies from 'js-cookie'
import { useState, useMemo } from 'react'
import { AlertIcon, HidePasswordIcon, SuccessIcon, TickIcon, ViewPasswordIcon } from 'assets/icons'
import { useResetPasswordMutation } from './auth.endpoints'
import SpinLoader from 'libs/loader/mega-spin-loader/Loader'
import { MiniSpinLoader } from 'libs/loader'
import { authErrors } from '../../../../mocks/ErrorMessages'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const ResetPasswordPage = (props: any) => {
  const navigate = useNavigate()
  // const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [focusedInput, setFocusedInput] = useState<string | null>(null)
  const [isPasswordResetClick, setIsPasswordResetClick] = useState<boolean>(false)
  // const [loginUser, { data: loginData, isLoading: isLoginLoading, error: loginErr }] = useLoginUserMutation()
  const [resetPassword] = useResetPasswordMutation()
  const [enterPassword, setEnterPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isEnterPasswordVisible, setIsEnterPasswordVisible] = useState(false)
  // const locationState = location.state
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)
  const [isEnterPasswordValid, setIsEnterPasswordValid] = useState(false)
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false)
  const [showConfirmPasswordErr, setShowConfirmPasswordErr] = useState(false)
  const [showEnterPasswordErr, setShowEnterPasswordErr] = useState(false)
  const [isUppercase, setIsUppercase] = useState(false)
  const [isSpecialChar, setIsSpecialChar] = useState(false)
  const [isNumber, setIsNumber] = useState(false)

  const [isMinLength, setIsMinLength] = useState(false)
  const toggleEnterPasswordVisibility = () => {
    setIsEnterPasswordVisible((prevState) => !prevState)
  }
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prevState) => !prevState)
  }
  const handleEnterPasswordChange = (event: any) => {
    setEnterPassword(event.target.value)
  }
  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value)
  }
  const handleLogIn = async () => {
    try {
      const otpValue = props.otpValue?.join('')
      if (!isEnterPasswordValid || !isConfirmPasswordValid) {
        if (!isEnterPasswordValid) setShowEnterPasswordErr(true)
        setShowConfirmPasswordErr(true)
        return
      }
      setIsPasswordResetClick(true)
      const res = await resetPassword({
        data: {
          username: props.email,
          confirmation_code: otpValue,
          new_password: enterPassword,
        },
      })
      if ('error' in res) {
        const fetchError = res.error as FetchBaseQueryError
        if (fetchError.status === 422) {
          setIsEnterPasswordValid(false)
          setIsConfirmPasswordValid(false)
          setShowEnterPasswordErr(true)
          setShowConfirmPasswordErr(true)
          setIsPasswordResetClick(false)
        } else if (fetchError.status === 404) {
          setIsPasswordResetClick(false)
          props.setIsInvalid(true)
        }
      } else {
        const responseData = res.data
        if (responseData && !responseData.is_temperory) {
          setIsPasswordResetClick(false)
          setIsLoggedIn(true)
          Cookies.set('catalyst-user-data', JSON.stringify(res))
          navigate('/')
        }
      }
    } catch (error) {
      console.error('Error occurred:', error)
    }
  }

  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (focusedInput === 'enter-password') {
        document.getElementById('confirmPasswordInput')?.focus()
      } else if (focusedInput === 'confirm-password' && isConfirmPasswordValid && isEnterPasswordValid) {
        await handleLogIn()
      } else if (focusedInput === 'confirm-password' && (!isConfirmPasswordValid || !isEnterPasswordValid))
        setShowConfirmPasswordErr(true)
      if (!isEnterPasswordValid) setShowEnterPasswordErr(true)
    }
  }
  useMemo(() => {
    setIsUppercase(/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(enterPassword))
    setIsSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(enterPassword))
    setIsNumber(/[0-9]/.test(enterPassword))
    setIsMinLength(enterPassword.length >= 8)
  }, [enterPassword])
  useMemo(() => {
    setShowConfirmPasswordErr(false)
    setIsConfirmPasswordValid(enterPassword === confirmPassword)
  }, [confirmPassword, enterPassword])
  useMemo(() => {
    setShowEnterPasswordErr(false)
    setIsEnterPasswordValid(isUppercase && isSpecialChar && isNumber && isMinLength)
  }, [isUppercase, isSpecialChar, isNumber, isMinLength, confirmPassword])
  return (
    // <Modal
    //   name={isLoggedIn && userData?.first_name}
    //   heading={isLoggedIn ? "Welcome Back " : "Reset Password"}
    // >
    <>
      {!isLoggedIn && (
        <>
          {' '}
          {/* <div className="flex py-1 gap-x-4 mt-2 mb-4">
            <Link to={"/auth/forgot-password"}>
              <LeftArrowIcon width={25} height={25} />
            </Link>
            <p>Create new password</p>
          </div> */}
          <div className="mb-3.5 mt-4">
            <p className="text-xs font-normal text-[#75838D] ">
              Your new password must be different from your last used password.
            </p>
          </div>
          <div className="mb-0.5">
            <div className="relative flex flex-col">
              <label className="font-inter text-xs font-normal text-[#212121]">Enter password</label>
              <div
                onMouseLeave={() => setShowEnterPasswordErr(!isEnterPasswordValid)}
                onMouseEnter={() => setShowEnterPasswordErr(false)}
              >
                <input
                  style={{
                    border: `1px solid ${
                      showEnterPasswordErr && enterPassword !== ''
                        ? '#8E0000'
                        : isEnterPasswordValid
                          ? '#28A745'
                          : 'black'
                    }`,
                  }}
                  className={`${
                    showEnterPasswordErr && enterPassword !== ''
                      ? 'bg-[#F8D7DA] text-[#8E0000]'
                      : isEnterPasswordValid
                        ? 'bg-[#DDFFE5] text-[#28A745]'
                        : 'bg-[#EBEFF3] text-[#000]'
                  } my-2 w-full rounded px-5 py-3.5 text-sm  font-normal`}
                  type={isEnterPasswordVisible ? 'text' : 'password'}
                  placeholder="Enter new password"
                  onChange={handleEnterPasswordChange}
                  onFocus={() => {
                    setFocusedInput('enter-password')
                    setShowEnterPasswordErr(false)
                  }}
                  onKeyDown={handleKeyDown}
                />
                <button
                  className="absolute right-0 top-10 px-4 text-gray-600"
                  onClick={() => (!showEnterPasswordErr || enterPassword !== '') && toggleEnterPasswordVisibility()}
                >
                  {showEnterPasswordErr && enterPassword !== '' ? (
                    <AlertIcon />
                  ) : isEnterPasswordVisible && enterPassword !== '' ? (
                    <HidePasswordIcon
                      width={20}
                      height={12}
                      color={`${showEnterPasswordErr ? '#8E0000' : isEnterPasswordValid ? '#28A745' : '#75838D'}`}
                    />
                  ) : (
                    enterPassword !== '' && (
                      <ViewPasswordIcon
                        width={24}
                        height={24}
                        color={`${showEnterPasswordErr ? '#8E0000' : isEnterPasswordValid ? '#28A745' : '#75838D'}`}
                      />
                    )
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="mb-1.5">
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isUppercase ? '#28A745' : '#8E0000'}
              />
              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isUppercase ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Has at least one uppercase and one lowercase letter
              </span>
            </p>
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isSpecialChar ? '#28A745' : '#8E0000'}
              />

              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isSpecialChar ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Has at least one special character
              </span>
            </p>
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isNumber ? '#28A745' : '#8E0000'}
              />
              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isNumber ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Has at least one number
              </span>
            </p>
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isMinLength ? '#28A745' : '#8E0000'}
              />
              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isMinLength ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Min 8 characters
              </span>
            </p>
          </div>
          <div className="relative flex flex-col">
            <label className="font-inter text-xs font-normal text-[#212121]">Confirm password</label>
            <div>
              <input
                id="confirmPasswordInput"
                style={{
                  border: `1px solid ${confirmPassword === '' || !showConfirmPasswordErr ? '' : '#8E0000'}`,
                }}
                className={`${
                  confirmPassword === '' || !showConfirmPasswordErr
                    ? 'bg-[#EBEFF3] text-[#000]'
                    : 'bg-[#F8D7DA] text-[#8E0000]'
                } my-2 w-full rounded px-5 py-3.5 text-sm  font-normal`}
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                placeholder="Confirm password"
                onFocus={() => setFocusedInput('confirm-password')}
                onChange={handleConfirmPasswordChange}
                onKeyDown={handleKeyDown}
              />
              <button className="absolute right-0 top-10 px-4 text-gray-600" onClick={toggleConfirmPasswordVisibility}>
                {confirmPassword !== '' && showConfirmPasswordErr ? (
                  <AlertIcon />
                ) : isConfirmPasswordVisible && confirmPassword !== '' ? (
                  <HidePasswordIcon
                    width={20}
                    height={12}
                    color={`${confirmPassword === '' || !showConfirmPasswordErr ? '#75838D' : '#8E0000'}`}
                  />
                ) : (
                  confirmPassword !== '' && (
                    <ViewPasswordIcon
                      width={24}
                      height={24}
                      color={`${confirmPassword === '' || !showConfirmPasswordErr ? '#75838D' : '#8E0000'}`}
                    />
                  )
                )}
              </button>
            </div>
            <span className="font-inter text-xs text-[#8E0000]">
              {showConfirmPasswordErr &&
                isEnterPasswordValid &&
                confirmPassword !== '' &&
                `${authErrors.INVALID_RESET_PASSWORD_COMBINATION}`}
              {props.isInvalid && `${authErrors.INVALID_OTP}`}
              {showConfirmPasswordErr &&
                !isEnterPasswordValid &&
                confirmPassword !== '' &&
                `${authErrors.INVALID_NEW_PASSWORD}`}
            </span>
          </div>
          <div className="mt-4">
            <button
              onClick={() => confirmPassword !== '' && isEnterPasswordValid && props.isOtpComplete && handleLogIn()}
              className={`${
                confirmPassword !== '' && isEnterPasswordValid && props.isOtpComplete && !props.isInvalid
                  ? 'cursor-pointer bg-[#0B5ED7]'
                  : 'cursor-default bg-[#75838D]'
              } w-full rounded px-8 py-2.5 text-[#fff] `}
            >
              Reset Password {isPasswordResetClick && <MiniSpinLoader />}
            </button>
          </div>
        </>
      )}
      {isLoggedIn && (
        <div className="">
          <div className="mx-auto">
            <SuccessIcon classProp="mx-auto mt-8" />
            <p className="py-4 text-center text-lg font-medium text-[#28A745]">Password changed Successfully.</p>
          </div>
          <div className="mx-auto">
            <SpinLoader />
          </div>
          <p className="py-4 text-center font-inter text-lg text-[#323333]">Please wait while logging you in</p>
        </div>
      )}
    </>
    // </Modal>
  )
}
export default ResetPasswordPage
