import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Popup from 'libs/popup/Popup'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import { toast } from 'libs/toast'
import WhiteCard from 'libs/card/WhiteCard'
import useTabTitle from 'libs/hooks/useTabTitle'
import FullPageLoader from 'libs/loader/FullPageLoader'
import SavedChangesPopup from 'pages/manufacture-orders/saved-changes-popup/SavedChangesPopup'
import { getTodaysDateTimeInUnix } from 'utils/dateTimeHandlers'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import { useGetCustomersQuery } from 'pages/contacts/customer-management/customer-endpoints/customer.endpoints'
import SidePanel from 'libs/sidepanel/SidePanel'
import QuickAddEditCustomer from 'libs/quick-add-edits/quick-add-edit-customer/QuickAddEditCustomer'
import { STATUS_OPTIONS } from '../sales-order-endpoints/salesOrders.constants'
import {
  ProductFormik,
  SalesOrderFormik,
} from '../sales-order-endpoints/salesOrdersTypes'
import {
  useGetSalesOrderByIDQuery,
  usePostSalesOrderMutation,
  usePutSalesOrderMutation,
} from '../sales-order-endpoints/salesOrders.endpoints'
import NewEditableTable from 'libs/table/NewEditableTable'

const productfilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'updated_on',
  sortDir: 'DESC',
  searchText: '',
}

const customerFilters = {
  page_no: 1,
  page_size: 10,
  sortDir: 'DESC',
}

const AddEditSalesOrder = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  useTabTitle(id ? 'Update Sales Order' : 'Add Sales Order')

  const plantId = useSelector(selectSelectedItem)
  const [savedChangesPopUp, setSavedChangesPopUp] = useState({
    isPopUpOpen: false,
  })
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [data, setData] = useState<ProductFormik[]>([])

  // Access the state object
  const { from } = location.state || {}

  const { data: salesOrder, isLoading: SalesOrderLoading } =
    useGetSalesOrderByIDQuery(
      {
        plant_id: plantId?.id,
        id: id ?? '',
      },
      {
        skip: !plantId?.id || !id,
      },
    )

  const { data: customers } = useGetCustomersQuery(
    {
      plant_id: plantId?.id,
      filters: customerFilters,
    },
    { skip: !plantId?.id },
  )

  const customerDataOptions = useMemo(
    () =>
      customers?.data?.map((customer) => ({
        _id: customer.id,
        label: customer.name,
        sublabel: customer.primaryContactNumber,
        value: customer?.id,
      })),
    [customers],
  )

  const { data: products, isLoading: productLoading } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productfilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const productOptions = useMemo(
    () =>
      // @ts-ignore
      products?.data?.map((item) => ({
        id: item._id,
        label: item.name,
        value: item,
      })),
    [products],
  )

  const productsColumns = useMemo(
    () => [
      {
        title: 'Products',
        accessor: 'product',
        flex: 2,
        main: true,
        editable: true,
        placeholder: 'Select Product',
      },
      {
        title: 'Quantity',
        accessor: 'quantity',
        editable: true,
      },
      {
        title: 'UOM',
        accessor: 'unit_of_measure',
      },
    ],
    [],
  )

  const [addSalesOrder, { isLoading: addSalesOrderLoading }] =
    usePostSalesOrderMutation()
  const [updateSalesOrder, { isLoading: updateSalesOrderLoading }] =
    usePutSalesOrderMutation()

  const onAddSalesOrderSuccess = (res: string) => {
    toast.success(res)
    if (from) {
      setSavedChangesPopUp({ isPopUpOpen: true })
    } else {
      navigate(-1)
    }
  }

  const salesOrderFormik = useFormik<SalesOrderFormik>({
    initialValues: {
      salesOrderID: '',
      status: '',
      customerID: '',
      deliveryDate: getTodaysDateTimeInUnix(),
      description: '',
      products: [],
    },
    validationSchema: Yup.object().shape({
      salesOrderID: Yup.string().required('Sales Order ID is required'),
      status: Yup.string().required('Status is required'),
      customerID: Yup.string().required('Customer Name is required'),
      deliveryDate: Yup.string().required('Expected Delivery Date is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        customerID: values.customerID,
        salesOrderID: values.salesOrderID,
        deliveryDate: values.deliveryDate,
        status: values.status,
        description: values.description,
        products:
          data
            ?.filter((d) => d?.product)
            .map((d) => ({
              product_id: d?.product._id,
              quantity: 1 * d.quantity,
            })) || [],
      }

      if (payload?.products?.length <= 0) {
        toast.error('At least one product must be added')
        return
      }

      // Add Sales Order
      if (!id) {
        addSalesOrder({ plant_id: plantId?.id, data: payload })
          .unwrap()
          .then((res) => onAddSalesOrderSuccess(res))
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail)
              ? error?.data?.detail[0]?.msg
              : error?.data?.detail
            toast.error(
              errorMessage ??
                'Something went wrong while creating the sales order',
            )
          })
      } else {
        // Update Sales Order
        updateSalesOrder({
          plant_id: plantId.id,
          id: id,
          data: payload,
        })
          .unwrap()
          .then((res) => {
            toast.success(res)
            if (from) {
              setSavedChangesPopUp({ isPopUpOpen: true })
            } else {
              navigate(-1)
            }
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail)
              ? error?.data?.detail[0]?.msg
              : error?.data?.detail
            toast.error(
              errorMessage ??
                'Something went wrong while updating the sales order',
            )
          })
      }
    },
  })

  // updating sales order
  useEffect(() => {
    if (salesOrder) {
      salesOrderFormik.resetForm({
        values: {
          salesOrderID: salesOrder.salesOrderID || '',
          status: salesOrder.status || '',
          deliveryDate: salesOrder.deliveryDate || 0,
          description: salesOrder.description || '',
          customerID: salesOrder.customer?.id || '',
          products: salesOrder.products.map((product) => ({
            product: { ...product, _id: product.id },
            quantity:
              typeof product?.quantity !== 'number' ? 0 : product?.quantity,
          })),
        },
      })
    }
  }, [salesOrder])

  const openPanel = () => {
    setTimeout(() => {
      setIsPanelOpen(!isPanelOpen)
    }, 300)
  }

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">
              {id ? 'Edit Sales Order' : 'Add Sales Order'}
            </h1>
          </div>
        </div>
        {SalesOrderLoading || productLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard className="mt-3 flex flex-col gap-6">
            <span>
              <h5 className="h5">Sales Order Details</h5>
              <p className="p-sm-regular-1">
                {id
                  ? 'Edit the existing Sales Order details to make necessary changes and updates.'
                  : 'Add the basic details that are required to create new Sales Order.'}
              </p>
            </span>
            <div className="flex flex-col gap-6">
              {/* --- process name and department */}
              <div className="grid grid-cols-2 gap-x-4 gap-y-2 sm:w-full lg:w-10/12">
                <Field
                  type="text"
                  required={true}
                  label="Sales Order ID"
                  formik={salesOrderFormik}
                  name="salesOrderID"
                  placeholder="Enter Sales Order ID"
                />
                <Field
                  type="select"
                  required={true}
                  label="Status"
                  formik={salesOrderFormik}
                  options={id ? STATUS_OPTIONS : STATUS_OPTIONS.slice(0, 2)}
                  name="status"
                  defaultValue={STATUS_OPTIONS?.find(
                    (option) =>
                      option?.value === salesOrderFormik?.values?.status,
                  )}
                  placeholder="Enter Status"
                />
                <Field
                  type="select"
                  required={true}
                  label="Customer Name"
                  formik={salesOrderFormik}
                  name="customerID"
                  options={customerDataOptions}
                  defaultValue={customerDataOptions?.find(
                    (option) =>
                      option?._id === salesOrderFormik?.values?.customerID,
                  )}
                  placeholder="Enter Customer Name"
                  title={'Add Customer'}
                  onAdd={openPanel}
                />
                <Field
                  type="date"
                  required={true}
                  label="Expected Delivery Date"
                  formik={salesOrderFormik}
                  name="deliveryDate"
                  placeholder=""
                />
                <Field
                  type="text"
                  label="Description"
                  formik={salesOrderFormik}
                  name="description"
                  placeholder="Enter Description"
                />
              </div>

              {/* ---- border ----- */}
              <hr></hr>
              {/* ---- border ----- */}

              <NewEditableTable
                defaultRows={salesOrderFormik.values.products}
                columns={productsColumns}
                options={productOptions}
                uniqueIdInOptions="_id"
                addButtonTitle="Add Product"
                onChange={(values) => {
                  setData(values)
                }}
              />

              <div
                className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}
              >
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button
                  color="success"
                  loading={addSalesOrderLoading || updateSalesOrderLoading}
                  onClick={() => {
                    // const products = data.map((formData) => {
                    //   return {
                    //     product_id: formData.product?._id,
                    //     quantity: formData.quantity,
                    //   }
                    // })
                    // salesOrderFormik.setFieldValue('products', products)
                    salesOrderFormik.handleSubmit()
                  }}
                >
                  {id ? 'Save' : 'Add'}
                </Button>
              </div>
            </div>
            {/* Saved Changes Popup */}
            {savedChangesPopUp.isPopUpOpen && (
              <Popup
                isOpen={savedChangesPopUp.isPopUpOpen}
                title="Saved Changes"
                onClose={() => {
                  setSavedChangesPopUp({ isPopUpOpen: false })
                }}
              >
                <SavedChangesPopup />
              </Popup>
            )}

            <SidePanel
              size="large"
              title="Add Customer"
              isOpen={isPanelOpen}
              onClose={() => setIsPanelOpen(false)}
            >
              <QuickAddEditCustomer close={() => setIsPanelOpen(false)} />
            </SidePanel>
          </WhiteCard>
        )}
      </>
    </MotionDiv>
  )
}

export default AddEditSalesOrder
