import { createSlice } from '@reduxjs/toolkit'

// create slice for storing and getting user data
const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    access_token: null,
    refresh_token: null,
    isLoggedIn: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      state.isLoggedIn = false
    },
    setToken: (state, action) => {
      state.access_token = action.payload.access_token
      state.refresh_token = action.payload.refresh_token
    },
    logout: (state) => {
      state.user = null
      state.access_token = null
      state.refresh_token = null
      state.isLoggedIn = false
    },
  },
})

export const { setUser, setToken, logout } = userSlice.actions

export default userSlice.reducer
