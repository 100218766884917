import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import arrowRight from 'assets/icons/misc/arrowRight.svg'

interface CardProps {
  title: string
  subtitle?: string
  content?: ReactNode
  leftIcon?: ReactNode
  rightIcon?: string | ReactNode
  rightCenterIcon?: string | ReactNode
  onClick?: () => void
  totalCount?: any
  reviewIcon?: ReactNode
  titleStyle?: string
  subtitleStyle?: string
  isActive?: boolean
  disabled?: boolean
  className?: any
  titleCenter?: any
}
/**
 * @param {Object} props - The component props.
 * @param {string} props.title - The title text displayed in the card.
 * @param {string} [props.subtitle] - The subtitle text displayed under the title. Optional.
 * @param {React.ReactNode} [props.leftIcon] - The icon displayed to the left of the title. Optional.
 * @param {string|React.ReactNode} [props.rightIcon] - The icon displayed to the right of the title. Can be a string (URL) or a ReactNode. Optional.
 * @param {Function} [props.onClick] - The function to be called when the card is clicked. Optional.
 * @param {string|React.ReactNode} [props.rightCenterIcon] - The icon displayed to the right of the title. Can be a string (URL) or a ReactNode. Optional.
 * @param {number} [props.totalCount] - The total count displayed next to the subtitle. Optional.
 * @param {React.node} [props.totalCount] - The total count displayed next to the subtitle. Optional.
 * @param {React.ReactNode} [props.content] - The icon displayed as a content.
 * @param {string} [props.titleStyle] - Additional CSS classes for styling the title. Optional.
 * @param {boolean} [props.isActive] - Boolean to indicate if the card is active. Optional.
 * @returns {JSX.Element} The rendered card component.
 */
const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  leftIcon,
  content,
  rightIcon,
  rightCenterIcon,
  onClick,
  totalCount,
  reviewIcon,
  titleStyle,
  subtitleStyle,
  isActive,
  disabled,
  className,
  titleCenter,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={`group flex items-center   rounded-lg border-2 p-4 shadow-lg  duration-300 ease-in-out hover:bg-primary-200
        ${className ? className : 'md:w-full'}
        ${disabled ? 'cursor-default opacity-70' : ' cursor-pointer '}
        ${isActive ? ' bg-primary-300' : ' bg-white'}`}
      onClick={() => {
        if (!disabled) {
          onClick && onClick()
        }
      }}
    >
      <div className={`flex-1 `}>
        <div className="flex items-center justify-between">
          <div className={`flex items-center ${titleCenter ? 'mx-auto ' : ''}  justify-between gap-2`}>
            <span>{leftIcon && <div>{leftIcon}</div>}</span>
            <h2
              className={`max-w-[250px]  truncate ${!disabled && '  duration-300 ease-in-out group-hover:text-primary'} ${titleStyle ? titleStyle : 'text-gray-800 p-xs-regular-500'}`}
            >
              {t(title)}
            </h2>
          </div>
          <div>
            {rightIcon || (
              <span>
                <img src={rightIcon === '' ? arrowRight : ''} alt="" style={{ height: '20px' }} />
              </span>
            )}
          </div>
        </div>
        {subtitle && (
          <div className={`flex ${reviewIcon ? 'gap-2' : ''} items-center`}>
            <span>{reviewIcon && <div>{reviewIcon}</div>}</span>
            <p className={`${subtitleStyle ? subtitleStyle : 'p-xs-regular-dim '}`}>
              {totalCount} {t(subtitle)}
            </p>
          </div>
        )}
        {content && content}
      </div>
      {rightCenterIcon || (
        <span>
          <img src={rightCenterIcon === '' ? arrowRight : ''} alt="" style={{ height: '20px' }} />
        </span>
      )}
    </div>
  )
}

export default Card
