import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  CustomersResponse,
  CustomersRequest,
  CustomerResponse,
  CustomerRequest,
  CustomerMutationResponse,
  CreateCustomerRequestType,
  UpdateCustomerRequestType,
  DeleteCustomerRequestType,
} from './customerTypes'
import { CountResponse } from 'store/services/utilsTypes'

export const customer = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    createCustomer: builder.mutation<
      CustomerMutationResponse,
      CreateCustomerRequestType
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `plant/${plant_id}/customer`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetCustomers'],
    }),
    deleteCustomer: builder.mutation<
      CustomerMutationResponse,
      DeleteCustomerRequestType
    >({
      query: ({ plant_id, id }) => ({
        url: `/plant/${plant_id}/customer/${id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['GetCustomers'],
    }),
    updateCustomer: builder.mutation<
      CustomerMutationResponse,
      UpdateCustomerRequestType
    >({
      query: ({ data, plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/customer/${id}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetCustomers'],
    }),
    getCustomerByID: builder.query<CustomerResponse, CustomerRequest>({
      query: ({ plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/customer/${id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the customer ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetCustomers'],
    }),
    getCustomers: builder.query<CustomersResponse, CustomersRequest>({
      query: ({ plant_id, filters }) => {
        return {
          url: buildUrl(`/plant/${plant_id}/customers`, filters),
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the customers')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetCustomers', 'salesOrderUpdate'],
    }),
    getCustomersCount: builder.query<
      CountResponse,
      {
        plant_id: string
      }
    >({
      query: ({ plant_id }) => {
        return {
          url: `plant/${plant_id}/customers/count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the count Customer')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetCustomers'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useGetCustomerByIDQuery,
  useGetCustomersQuery,
  useGetCustomersCountQuery,
} = customer
