import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import { useGetPurchaseOrdersQuery } from 'pages/orders/purchase-order/purchase-orders-endpoints/purchaseOrders.endpoints'
import { useGetWorkOrdersQuery } from 'pages/work-orders/work-orders-endpoints/workorders.endpoints'
import { useGetProductionPlanQuery } from 'pages/planning/production-plans/production-plans-endpoints/productionPlan.endpoints'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import { useGetAllSalesOrdersQuery } from 'pages/orders/sales-order/sales-order-endpoints/salesOrders.endpoints'
import {
  useGetManufactureOrderByIdQuery,
  useEditManufactureOrderMutation,
  usePostManufactureOrderMutation,
} from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import * as Yup from 'yup'
import { useEffect } from 'react'
import FullPageLoader from 'libs/loader/FullPageLoader'

type Plan = {
  _id: string
  planID: string
}

type FinishedGood = {
  _id: string
  name: string
}

type Props = {
  close: () => void
  manufactureOrderID: any
}

const QuickAddEditManufactureOrder = ({ close, manufactureOrderID }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const [addManufactureOrder, { isLoading: addManufactureOrderLoading }] =
    usePostManufactureOrderMutation()
  const [editManufactureOrder, { isLoading: editManufactureOrderLoading }] =
    useEditManufactureOrderMutation()
  const plantId = useSelector(selectSelectedItem)
  const filters = {
    page_no: 1,
    page_size: 1000,
    sortDir: 'DESC',
  }
  const salesOrderFilters = {
    page_no: 1,
    page_size: 1000,
    sort_dir: 'DESC',
  }
  const workOrderFilters = {
    page_no: 1,
    page_size: 1000,
  }

  const { data: manufactureOrder, isLoading: isManufactureLoading } =
    useGetManufactureOrderByIdQuery(
      {
        plant_id: plantId?.id,
        moID: manufactureOrderID,
      },
      {
        skip: !plantId?.id || !manufactureOrderID,
      },
    )

  const { data: purchaseOrders } = useGetPurchaseOrdersQuery(
    {
      plant_id: plantId?.id,
      filters: salesOrderFilters,
    },
    {
      skip: !plantId?.id,
    },
  )
  const { data: salesOrders } = useGetAllSalesOrdersQuery(
    {
      plant_id: plantId?.id,
      filters: salesOrderFilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const { data: workOrders } = useGetWorkOrdersQuery(
    {
      plantId: plantId?.id,
      filters: workOrderFilters,
    },
    {
      skip: !plantId?.id,
    },
  )
  const { data: products } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id,
    },
  )
  const { data: plans } = useGetProductionPlanQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      skip: !plantId?.id,
    },
  )

  // *********************************************Options**********************************//
  const planOptions = plans?.data?.map((item: any) => ({
    label: item.planID,
    value: item.planID,
    sublabel: '',
    disabled: '',
  }))

  const productOpitons = products?.data
    ?.filter((item: any) => item.category === 'Finished Goods')
    .map((item: FinishedGood) => ({
      label: item.name,
      value: item,
    }))

  const workOrderOptions = workOrders?.data?.map((item: any) => ({
    label: item.workOrderID,
    value: item._id,
    sublabel: '',
    disabled: '',
  }))

  const salesOrderOptions = salesOrders?.data?.map((item: any) => ({
    label: item.salesOrderID,
    value: item.id,
    sublabel: '',
    disabled: '',
  }))

  const purchaseOrderOptions = purchaseOrders?.data?.map((item: any) => ({
    label: item.purchaseOrderID,
    value: item.id,
    sublabel: '',
    disabled: '',
  }))

  // *********************************************Options**********************************//
  const getItem = localStorage.getItem('manufactureOrder')
  const manufactureData = getItem ? JSON.parse(getItem) : {}

  const formik = useFormik({
    initialValues: {
      manufactureOrderID: manufactureData?.manufactureOrderID || '',
      plan_id: manufactureData?.plan_id || (null as Plan | null),
      startDate: manufactureData?.startDate || (null as number | null),
      endDate: manufactureData?.endDate || (null as number | null),
      finishedGood:
        manufactureData?.finishedGood || (null as FinishedGood | null),
      status: manufactureData?.status || '',
      quantity: manufactureData?.quantity || 0,
      salesOrders: manufactureData?.salesOrders || ([] as string[]),
      workOrders: manufactureData?.workOrders || ([] as string[]),
      purchaseOrders: manufactureData?.purchaseOrders || ([] as string[]),
    },
    validationSchema: Yup.object({
      manufactureOrderID: Yup.string().required(
        'Manufacture order id is required',
      ),
      status: Yup.string().required('Status is required'),
      finishedGood: Yup.object().required('Finished good is required'),
      quantity: Yup.number()
        .required('Quantity is required')
        .min(1, 'Quantity must be greater than 0'),
      startDate: Yup.string().required('Start date is required'),
      endDate: Yup.string().required('End date is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        manufactureOrderID: values.manufactureOrderID,
        status: values.status,
        plan_id:
          values?.plan_id?.planID != null
            ? values?.plan_id?.planID
            : values?.plan_id,
        finishedGood: values.finishedGood ? values.finishedGood._id : '',
        startDate: values?.startDate,
        quantity: values?.quantity,
        salesOrders: values.salesOrders || [],
        workOrders: values.workOrders || [],
        purchaseOrders: values.purchaseOrders || [],
        endDate: values.endDate,
      }
      if (manufactureOrder == null) {
        addManufactureOrder({
          plant_id: plantId?.id,
          data: payload,
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Manufacture Order ${payload?.manufactureOrderID} is created successfully.`,
            )
            close()
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ?? 'Something went wrong. Please try again.',
            )
          })
      } else {
        editManufactureOrder({
          plant_id: plantId?.id,
          data: payload,
          moId: manufactureOrder._id,
        })
          .unwrap()
          .then(() => {
            toast.success(
              `Manufacture Order ${payload?.manufactureOrderID} is updated successfully.`,
            )
            close()
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ?? 'Something went wrong. Please try again.',
            )
          })
      }
    },
  })
  useEffect(() => {
    const manufactureOrderJSON = JSON.stringify(formik.values)
    localStorage.setItem('manufactureOrder', manufactureOrderJSON)
  }, [formik])

  const verificaton = [
    {
      name: 'In progress',
      value: 'IN_PROGRESS',
      _id: '101',
    },
    {
      name: 'Not started',
      value: 'NOT_STARTED',
      _id: '102',
    },
    {
      name: 'Completed',
      value: 'COMPLETED',
      _id: '104',
    },
    {
      name: 'Cancelled',
      value: 'CANCELLED',
      _id: '105',
    },
  ]
  const verificatonOptions = verificaton.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  useEffect(() => {
    if (manufactureOrder) {
      formik.resetForm({
        values: {
          plan_id: manufactureOrder?.plan?.planID,
          manufactureOrderID: manufactureOrder?.manufactureOrderID,
          finishedGood: manufactureOrder?.finishedGood,
          endDate: manufactureOrder?.endDate,
          status: manufactureOrder?.status,
          startDate: manufactureOrder?.startDate,
          quantity: manufactureOrder?.quantity,
          workOrders:
            manufactureOrder?.workOrders?.length == 0
              ? []
              : manufactureOrder?.workOrders?.map((item: any) => item?._id),
          purchaseOrders:
            manufactureOrder?.purchaseOrders?.length == 0
              ? []
              : manufactureOrder?.purchaseOrders?.map((item: any) => item?._id),
          salesOrders:
            manufactureOrder?.salesOrders?.length == 0
              ? []
              : manufactureOrder?.salesOrders?.map((item: any) => item?._id),
        },
      })
    }
  }, [manufactureOrder])

  return (
    <>
      {isManufactureLoading ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <div className="flex  justify-between gap-4 ">
              <div className="flex w-full flex-col gap-2">
                <div className="h-20">
                  <Field
                    type="text"
                    label="Manufacturing Order ID"
                    formik={formik}
                    required={true}
                    name="manufactureOrderID"
                    placeholder="Enter Manufacture Order ID"
                  />
                </div>
                <div className="h-20">
                  <Field
                    type="text"
                    label="Quantity"
                    formik={formik}
                    required={true}
                    name="quantity"
                    placeholder="Enter Quantity"
                  />
                </div>

                <div className="h-24">
                  <Field
                    type="multiselect"
                    label="Purchase Order"
                    formik={formik}
                    name="purchaseOrders"
                    options={purchaseOrderOptions}
                    placeholder="Select Purchase Order"
                  />
                  <span
                    className="cursor-pointer text-sm text-primary"
                    onClick={() =>
                      navigate(`/orders/purchase-order/add`, {
                        state: { from: currentPath },
                      })
                    }
                  >
                    + Add Purchase Order
                  </span>
                </div>
                <div className="h-24">
                  <Field
                    type="multiselect"
                    label="Sales Order"
                    formik={formik}
                    name="salesOrders"
                    options={salesOrderOptions}
                    placeholder="Select Sales Order"
                  />
                  <span
                    className="cursor-pointer text-sm text-primary"
                    onClick={() =>
                      navigate(`/orders/sales-order/new-sales-order`, {
                        state: { from: currentPath },
                      })
                    }
                  >
                    + Add Sales Order
                  </span>
                </div>
                <div className="h-24">
                  <Field
                    type="date"
                    label="Start Date"
                    shouldDisablePastDate
                    formik={formik}
                    name="startDate"
                  />
                </div>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="h-20">
                  <Field
                    type="select"
                    label="Plan ID"
                    formik={formik}
                    name="plan_id"
                    defaultValue={
                      formik.values.plan_id
                        ? {
                            label: formik?.values?.plan_id,
                            value: formik.values?.plan_id,
                          }
                        : null
                    }
                    options={planOptions}
                    placeholder="Select Plan ID"
                  />
                </div>
                <div className="h-20">
                  <Field
                    type="select"
                    label="Finished Good"
                    formik={formik}
                    required={true}
                    name="finishedGood"
                    options={productOpitons}
                    placeholder="Select Finished Good"
                  />
                </div>
                <div className="h-24">
                  <Field
                    type="multiselect"
                    label="Work Order"
                    formik={formik}
                    name="workOrders"
                    options={workOrderOptions}
                    placeholder="Select Work Order"
                  />
                  <span
                    className="cursor-pointer text-sm text-primary"
                    onClick={() =>
                      navigate(`/planning/work-orders/add`, {
                        state: { from: currentPath },
                      })
                    }
                  >
                    + Add Work Order
                  </span>
                </div>
                <div className="h-24">
                  <Field
                    type="select"
                    label="Status"
                    formik={formik}
                    required={true}
                    name="status"
                    defaultValue={verificatonOptions.find(
                      (option) => option?.value == formik?.values?.status,
                    )}
                    options={verificatonOptions}
                    placeholder="Select Status"
                  />
                </div>

                <div className="h-24">
                  <Field
                    type="date"
                    label="End Date"
                    shouldDisablePastDate
                    formik={formik}
                    name="endDate"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-between ">
              <div>
                {manufactureOrder ? (
                  <Button
                    color="primary"
                    onClick={() =>
                      navigate(
                        `/planning/mo/${manufactureOrder._id}/edit/basic-details`,
                        {
                          state: { from: currentPath },
                        },
                      )
                    }
                  >
                    Edit Details
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => navigate(`/planning/mo/add/basic-details`)}
                  >
                    Add Details
                  </Button>
                )}
              </div>
              <div className="flex gap-2">
                <Button onClick={close}>{t('cancel')}</Button>
                <Button
                  color="primary"
                  loading={
                    addManufactureOrderLoading || editManufactureOrderLoading
                  }
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default QuickAddEditManufactureOrder
