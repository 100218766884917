import { useState } from 'react'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import Popup from 'libs/popup/Popup'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { useGetShiftPolicyQuery } from './shift-mangement-endpoints/shiftManagement.endpoints'
import DeleteShiftPolicy from './delete-shift-policy/DeleteShiftPolicy'
export const checkForOverlap = (shifts: any) => {
  for (let i = 0; i < shifts?.length; i++) {
    for (let j = i + 1; j < shifts?.length; j++) {
      const shift1 = shifts[i]
      const shift2 = shifts[j]

      const shift1Start = shift1.startTimeing
      const shift1End = shift1.endTimeing
      const shift2Start = shift2.startTimeing
      const shift2End = shift2.endTimeing

      if (
        (shift1Start < shift2End && shift1End > shift2Start) || // Shift 1 overlaps with Shift 2
        (shift2Start < shift1End && shift2End > shift1Start) // Shift 2 overlaps with Shift 1
      ) {
        return true // Overlap found
      } else if (shift2Start < shift1End) {
        return true
      }
    }
  }
  return false // No overlap found
}
const ShiftManagementPage = () => {
  const plantId = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, shiftPolicy: '' })
  const navigate = useNavigate()

  useTabTitle('Shift Management')

  const column = [
    {
      Header: 'Policy Name',
      accessor: 'policyName',
      width: 130,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/settings/shift-management/view/${data?._id}`)
            }}
          >
            {data?.policyName}
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Total Shifts</div>,
      accessor: 'shifts',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original?.shifts?.length} Shifts</div>
      },
      disableSortBy: true,
    },

    {
      Header: 'Actions',
      accessor: 'actions',
      width: 20,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row">
            <button
              className="mr-3"
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/settings/shift-management/edit/${row.original._id}`)
              }}
            >
              <Pen />
            </button>
            <button
              className="mr-2"
              onClick={(event) => {
                event.stopPropagation()
                setDeletePopUp({ isPopUpOpen: true, shiftPolicy: row?.original })
              }}
            >
              <Bin />
            </button>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/settings/shift-management/view/${row?.original?._id}`)}
          >
            <ClickableRowArrow />
          </span>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleAddShiftPolicy = () => {
    navigate('shift-policy')
  }
  return (
    <MotionDiv>
      <>
        <div className="px-4">
          <h1 className="h4-bold-black ">Shift Management</h1>
          <p className="p-sm-regular-1">
            Manage and review shift policies to ensure efficient and organized scheduling.
          </p>
        </div>
        <FilterableSearchTable
          useQuery={useGetShiftPolicyQuery}
          columns={column}
          addButton={{ label: 'Add Shift Policy', onClick: handleAddShiftPolicy }}
          placeholders={{
            emptyMessage: 'No shift policy are added. Please add shift policy',
            filterEmptyMessage: 'No matching results found',
            search: 'Search policies by names',
          }}
        />
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Shift Policy"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, shiftPolicy: '' })
          }}
        >
          <DeleteShiftPolicy
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, shiftPolicy: '' })}
            shiftPolicy={deletePopUp.shiftPolicy}
            plantId={plantId?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default ShiftManagementPage
