import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { Filters } from '../TransactionHistory'
import { useParams } from 'react-router-dom'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}


const FilterTransaction: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const {id} = useParams()
  const initialValues = {
    transactionTypes: filters?.transactionTypes?.length == 0 ? [] : filters?.transactionTypes,
    quantity_from: filters?.quantityBetween?.length == 0 ? '' : filters?.quantityBetween[0],
    quantity_to: filters?.quantityBetween?.length == 0 ? '' : filters?.quantityBetween[1],
    quantityBetween: [],
    transactionDate: filters?.transactionDate?.length == 0 ? [] : filters?.transactionDate,
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
  }
  // *********************************** Reset Form ******************************//
  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      inventoryId: id,
      quantityBetween: [],
      transactionDate: [],
      createdBetween: [],
      updatedBetween: [],
      transactionTypes: [],
    })
    closePanel()
  }
  // ****************************** Update Form ***********************************//
  const filterFormik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
        transactionDate: values.transactionDate == null ? [] : values.transactionDate,
        transactionTypes: values.transactionTypes,
        quantityBetween:
          values.quantity_from == 0 && values.quantity_to == 0
            ? []
            : [values.quantity_from == '' ? 0 : values.quantity_from, values.quantity_to],
      }))
    },
  })

  // ************************************** category options ***************************//
  const transactionType = [
    {
      name: 'PROCUREMENT',
      value: 'PROCUREMENT',
      _id: '101',
      sublabel: '',
      disabled: '',
    },
    {
      name: 'TRANSFER',
      value: 'TRANSFER',
      _id: '102',
      sublabel: '',
      disabled: '',
    },
    {
      name: 'ADJUSTMENT',
      value: 'ADJUSTMENT',
      _id: '103',
      sublabel: '',
      disabled: '',
    },
    {
      name: 'SHIPMENT',
      value: 'SHIPMENT',
      _id: '104',
      sublabel: '',
      disabled: '',
    },
    {
      name: 'TRANSFORMATION',
      value: 'TRANSFORMATION',
      _id: '105',
      sublabel: '',
      disabled: '',
    },
  ]

  const transactionTypeOptions = transactionType.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Transaction Type */}
        <Field
          type="multiselect"
          label="Transaction type"
          multiSelectPlaceholderTexts={['Transaction type', 'Transaction types']}
          placeholder="Select transaction type"
          options={transactionTypeOptions}
          formik={filterFormik}
          name="transactionTypes"
        />
        <Field fullWidth type="dateRange" label="Transaction Between" formik={filterFormik} name="transactionDate" />
        {/* Quantity Range */}
        <div className="flex flex-col ">
          <div className="flex justify-between gap-4">
            <Field
              type="number"
              label="Quantity from"
              formik={filterFormik}
              placeholder="Quantity from"
              name="quantity_from"
            />
            <Field
              type="number"
              label="Quantity to"
              placeholder="Quantity to"
              formik={filterFormik}
              name="quantity_to"
            />
          </div>
        </div>
        {/* Created Date Range */}
        <Field
          fullWidth
          type="dateRange"
          shouldDisableFutureDate
          label="Created between"
          formik={filterFormik}
          name="createdBetween"
        />
        {/* Update Date Range */}
        <Field
          fullWidth
          type="dateRange"
          shouldDisableFutureDate
          label="Updated between"
          formik={filterFormik}
          name="updatedBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterTransaction
