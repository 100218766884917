import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { SalesOrder } from '../sales-order-endpoints/salesOrdersTypes'
import { useDeleteSalesOrderMutation } from '../sales-order-endpoints/salesOrders.endpoints'

interface Props {
  closePopUp: () => void
  salesOrder: SalesOrder
  plantId: string
}

/**
 * Component for deleting a sales order.
 *
 * @param {object} props - The component props.
 * @param {function} props.closePopUp - Function to close the popup.
 * @param {object} props.salesOrder - The sales order object to delete.
 * @param {string} props.plantId - The plant ID associated with the sales order.
 */
const DeleteSalesOrder = ({ closePopUp, salesOrder, plantId }: Props) => {
  const [deleteSalesOrder, { isLoading }] = useDeleteSalesOrderMutation()
  const navigate = useNavigate()

  /**
   * Handles the deletion of a sales order.
   * Uses the deleteSalesOrder API mutation and handles success/failure cases.
   */
  const handleDeleteSalesOrder = useCallback(() => {
    deleteSalesOrder({ plant_id: plantId, id: salesOrder?.id })
      .unwrap()
      .then((res) => {
        toast.success(res)
        closePopUp()
        navigate('/orders/sales-order')
      })
      .catch((error) => {
        const errorMessage =
          error?.data?.detail ||
          `Failed to delete Sales Order “${salesOrder?.salesOrderID}”. Please try again.`
        toast.error(errorMessage)
      })
  }, [deleteSalesOrder, plantId, salesOrder, closePopUp, navigate])

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}{' '}
        <span className="break-words pr-1 p-xs-bold">
          “Sales Order ID {salesOrder?.salesOrderID}”.
        </span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className="mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button
          color="error"
          onClick={handleDeleteSalesOrder}
          loading={isLoading}
        >
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteSalesOrder
