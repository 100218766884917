import SidePanel from 'libs/sidepanel/SidePanel'
import QuickAddEditManufactureOrder from 'libs/quick-add-edits/quick-add-edit-mo/QuickAddEditMo'
import { useEffect, useState } from 'react'

const QuickAddEditManufactureOrderPanel = ({ id, isOpen, onClose }: any) => {
  const [value, setValue] = useState(id)

  useEffect(() => {
    if (isOpen) {
      setValue(id)
    }
  }, [id, isOpen])
  const handleClose = () => {
    onClose(false)
    setValue(null)
  }
  return (
    <SidePanel
      size="veryLarge"
      isOpen={isOpen}
      title={
        value ? 'Quick Edit Manufacture Order' : 'Quick Add Manufacture Order'
      }
      onClose={handleClose}
    >
      <QuickAddEditManufactureOrder
        close={handleClose}
        manufactureOrderID={value}
      />
    </SidePanel>
  )
}

export default QuickAddEditManufactureOrderPanel
