
import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  categories: string
  quantity_from: number | string
  quantity_to: number | string
  updatedBetween: number[]
  quantityBetween: number[]
  createdBetween: number[]
}

interface FormValues {
  categories: string
  quantity_from: number | string
  quantity_to: number | string
  updatedBetween: number[]
  quantityBetween: number[]
  createdBetween: number[]
}

const FilterInventory: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const initialValues: FormValues = {
    categories: filters?.categories ?? [],
    quantity_from: filters?.quantityBetween?.length == 0 ? '' : filters?.quantityBetween[0],
    quantity_to: filters?.quantityBetween?.length == 0 ? '' : filters?.quantityBetween[1],
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
    quantityBetween: [],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      categories: [],
      quantityBetween: [],
      updatedBetween: [],
      createdBetween: [],
      sortBy: 'inventory_updated_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
        categories: values.categories,
        quantityBetween:
          values.quantity_from == 0 && values.quantity_to == 0
            ? []
            : [values.quantity_from == '' ? 0 : values.quantity_from, values.quantity_to],
      }))
    },
  })

  const categories = [
    {
      name: 'Finished Goods',
      value: 'Finished Goods',
      _id: '101',
    },
    {
      name: 'Raw Material',
      value: 'Raw Materials',
      _id: '102',
    },
  ]

  const categoriesOptions = categories.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="Category"
          placeholder="Select categories"
          multiSelectPlaceholderTexts={['Category', 'Categories']}
          options={categoriesOptions}
          formik={filterFormik}
          name="categories"
        />
        {/* Quantity Range */}
        <div className="flex flex-col">
          <div className="mt-1 flex justify-between gap-4">
            <Field
              type="number"
              label="Quantity from"
              formik={filterFormik}
              placeholder="Quantity from"
              name="quantity_from"
            />
            <Field
              type="number"
              label="Quantity to"
              placeholder="Quantity to"
              formik={filterFormik}
              name="quantity_to"
            />
          </div>
        </div>
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Created between"
          formik={filterFormik}
          name="createdBetween"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Updated between"
          formik={filterFormik}
          name="updatedBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterInventory
