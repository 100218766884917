import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useMemo } from 'react'
import { useGetRoutingByIDQuery } from '../routings-endpoints/routings.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import FullPageLoader from 'libs/loader/FullPageLoader'

type props = {
  id: string
}

const ViewOperationPanel = ({ id }: props) => {
  const plantId = useSelector(selectSelectedItem)

  const { data: routingData, isLoading: isRoutingLoading } =
    useGetRoutingByIDQuery(
      {
        plant_id: plantId?.id,
        id: id,
      },
      {
        skip: !plantId?.id || !id,
      },
    )

  const columns = useMemo(
    () => [
      {
        Header: 'Operations',
        accessor: 'name',
        Cell: ({ value }: any) => {
          return <div data-testid="operation">{value ?? '-'}</div>
        },
        disableSortBy: true,
      },

      {
        Header: 'Input Products',
        accessor: 'input_products',
        Cell: ({ value }: any) => {
          return <div data-testid="input-products">{value.length ?? '-'} Products</div>
        },
        disableSortBy: true,
      },
      {
        Header: 'Output Products',
        accessor: 'output_products',
        Cell: ({ value }: any) => {
          return <div data-testid="output-products">{value.length ?? '-'} Products</div>
        },
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <>
        {isRoutingLoading ? (
          <FullPageLoader />
        ) : (
          <div>
            <div className="bg-slate-200 my-4 p-4 rounded-lg">
              <div className="flex justify-between">
                <div className="mb-2 p-xs-regular-dim">Routing Name</div>
                <div>
                  <div
                    className="break-words p-xs-regular w-60"
                    data-testid="routing-name"
                  >
                    {routingData?.name ?? '-'}
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="mb-2 p-xs-regular-dim">Routing Id</div>
                <div>
                  <div
                    className="break-words p-xs-regular w-60"
                    data-testid="routing-id"
                  >
                    {routingData?.routing_id ?? '-'}
                  </div>
                </div>
              </div>
            </div>

            <PaginatedTable
              columns={columns}
              rows={routingData?.operations ?? []}
              hidePagination
            />
          </div>
        )}
      </>
    </MotionDiv>
  )
}

export default ViewOperationPanel
