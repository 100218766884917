// import icons from 'assets/index'

const ProductTab = ({ tabText, isActive, onClick }: { tabText: string; isActive: boolean; onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center gap-1 text-nowrap rounded px-2 py-1 text-xs ${isActive ? 'bg-primary text-white ' : 'bg-gray-100 text-gray-900 '} `}
    >
      {tabText}
      {/* {isActive && (
        <img
          className=""
          src={icons.ProductionTabCheckIcon}
          alt="Production Tab Check Icon"
        />
      )} */}
    </button>
  )
}

export default ProductTab
