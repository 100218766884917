import ProgressBar from 'libs/charts/ProgressBar'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatNumber } from 'utils/numberHandlers'
import { formatUnixDateWithTime } from 'utils/dateTimeHandlers'

export const getTableConfig = (): any => {
  const plant = useSelector(selectSelectedItem)
  const operationColumns = [
    {
      Header: 'Operations',
      accessor: 'name',
    },
    {
      Header: 'Output Products',
      accessor: 'products',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        // Access the products array
        const products = row.original.products

        if (!products || products.length === 0) return null // Handle case where products array might be empty

        return (
          <div className="flex flex-col">
            {products.map((product: any, index: number) => (
              <div key={index} className="mb-2 flex flex-col">
                <span className="p-xs-regular">{product.name}</span>
                <span className="p-xs-regular-dim">{product.code}</span>
              </div>
            ))}
          </div>
        )
      },
    },

    {
      Header: 'Quantity',
      accessor: 'products.quantity',
      disableSortBy: true,
      align: 'right',
      Cell: ({ row }: any) => {
        // Access the products array
        const products = row.original.products

        if (!products || products.length === 0) return null // Handle case where products array might be empty

        return (
          <div className="flex flex-col gap-4">
            {products.map((product: any, index: number) => (
              <div key={index} className="mb-2 flex justify-end">
                <span className="font-mono">
                  {formatNumber(product.completedQuantity)} /{' '}
                  {formatNumber(product.targetQuantity)}
                </span>
              </div>
            ))}
          </div>
        )
      },
    },

    {
      Header: 'Unit',
      accessor: 'products.unit',
      disableSortBy: true,

      Cell: ({ row }: any) => {
        // Access the products array
        const products = row.original.products

        if (!products || products.length === 0) return null // Handle case where products array might be empty

        return (
          <div className="flex flex-col gap-4">
            {products.map((product: any, index: number) => (
              <div key={index} className="mb-2 flex justify-end">
                <span>{product.unit_of_measure}</span>
              </div>
            ))}
          </div>
        )
      },
    },

    {
      Header: 'Progress',
      accessor: 'products.code',
      Cell: ({ row }: any) => {
        const products = row.original.products

        return (
          <div className="w-full">
            <div className="flex flex-col">
              {products.map((product: any, index: number) => (
                <div key={index} className="mb-2">
                  <ProgressBar percentage={product.productProgressPercentage} />
                </div>
              ))}
            </div>
          </div>
        )
      },
    },
  ]

  const assignedToColumns = [
    {
      Header: () => <span className="px-8">Assignee Name</span>,
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: () => <span className="px-8">Departments</span>,
      accessor: 'department',
      disableSortBy: true,
      Cell: ({ value }: any) => {
        return <div className="flex py-2">{value ?? '-'}</div>
      },
    },
    {
      Header: () => <span className="px-8">Designations</span>,
      accessor: 'designation',
      disableSortBy: true,
      Cell: ({ value }: any) => {
        return <div className="flex py-2">{value ?? '-'}</div>
      },
    },
  ]

  const productionEntriesColumns = [
    {
      Header: 'Operations',
      accessor: 'process',
      disableSortBy: true,
      Cell: ({ value }: any) => {
        return <div className="">{value?.name}</div>
      },
    },
    {
      Header: 'Products',
      accessor: 'product',
      disableSortBy: true,
      Cell: ({ value }: any) => (
        <div className="mb-2 flex flex-col">
          <span className="p-xs-regular">{value.name}</span>
          <span className="p-xs-regular-dim">{value.code}</span>
        </div>
      ),
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: true,
      align: 'right',
      Cell: ({ value }: any) => {
        return <div className="font-mono"> {formatNumber(value)}</div>
      },
    },
    {
      Header: <div>Unit</div>,
      accessor: 'product.unit_of_measure',
      Cell: ({ row }: any) => {
        return (
          <span className="">{row?.original?.product?.unit_of_measure}</span>
        )
      },
      width: 30,
      disableSortBy: true,
    },
    {
      Header: 'Updated by',
      accessor: 'updated_by',

      Cell: ({ row }: any) => {
        const updatedBy = row.original.updated_by
        const createdBy = row.original.created_by
        const by = updatedBy ? updatedBy : createdBy
        const name = by ? `${by.first_name} ${by.last_name}` : 'Unknown'
        const time = formatUnixDateWithTime(
          updatedBy ? row.original.updated_on : row.original.created_on,
          plant?.timezone,
        )
        return (
          <div>
            <div>{name}</div>
            <div className="p-xs-regular-dim">{time}</div>
          </div>
        )
      },
    },
    {
      Header: 'Verified by',
      accessor: 'verified_by',
      disableSortBy: true,

      Cell: ({ row }: any) => {
        const verifiedBy = row.original.verified_by
        if (verifiedBy) {
          const name = `${verifiedBy?.first_name} ${verifiedBy?.last_name}`
          const time = formatUnixDateWithTime(
            row.original.verified_on,
            plant?.timezone,
          )
          return (
            <div>
              <div>{name}</div>
              <div className="p-xs-regular-dim">{time}</div>
            </div>
          )
        } else {
          return (
            <div className="m-1 w-[4rem] rounded-[28px] bg-[#F8D7DA] px-2 py-0.5 text-xs text-[#8E0000]">
              Pending
            </div>
          )
        }
      },
    },
  ]

  const woTabs = [
    {
      _id: 1,
      name: 'Operations',
    },
    {
      _id: 2,
      name: 'Assigned Operators',
    },
    {
      _id: 3,
      name: 'Production Entries',
    },
  ]

  return {
    woTabs,
    assignedToColumns,
    operationColumns,
    productionEntriesColumns,
  }
}
