import Button from 'libs/button/Button'
import NewEditableTable from 'libs/table/NewEditableTable'

import { useGetEmployeeQuery } from 'pages/employee/employees-endpoints/employees.endpoints'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'

const assignedOperatorColumns = [
  {
    title: 'Machine Manager',
    accessor: 'machineManager',
    main: true,
    flex: 1,
    editable: true,
    serialNumber: true,
    placeholder: 'Select Machinery Manager',
  },
]

const AssignedOperators = ({ setId, setTabNumber, formik, isLoading }: any) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)

  const [data, setData] = useState([])

  const filters = {
    page_no: 1,
    page_size: 100,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
  }
  const { data: employee, isLoading: employeDataLoading } = useGetEmployeeQuery(
    {
      plant_id: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )
  const employeeOptions = useMemo(
    () =>
      employee?.data?.map((item: any) => ({
        id: item?._id,
        label: item?.employeeName,
        value: {
          _id: item?._id,
          plant_id: item?.plant?._id,
        },
      })),

    [employee?.data],
  )

  const handleSubmit = () => {
    formik.setFieldValue('operators', data)
  }

  const handleNext = () => {
    setTabNumber((prev: number) => prev + 1)

    if (!id) {
      navigate('/maintenance/machines/add/parts-details')
    } else {
      navigate(`/maintenance/machines/${id}/edit/parts-details`)
    }
  }

  useEffect(() => {
    setId(id)
    setTabNumber(2)
  }, [])

  const handleSkipStep = () => {
    if (!id) {
      navigate('/maintenance/machines/add/parts-details')
    } else {
      navigate(`/maintenance/machines/${id}/edit/parts-details`)
    }
  }

  const defaultRows = useMemo(() => {
    return formik.values?.operators?.map((operator: any) => {
      // Check if the operator is a string (i.e., the _id) or an object
      if (typeof operator === 'string') {
        // If it's a string, create a new object with machineManager._id
        return {
          machineManager: {
            _id: operator, // Assign the operator as machineManager._id
          },
        }
      } else {
        return operator
      }
    })  
  }, [formik.values.operators])

  return (
    <div className="h-screen-90 flex w-full flex-col px-2">
      <div className="flex-grow">
        <div className="h5-1">Assigned Operators</div>
        <p className="primary-gray-4 p-sm-regular">
          Assign the operator or team responsible for this machine. You can assign multiple operators if needed.
        </p>
        <div className="mt-4">
          <NewEditableTable
            defaultRows={defaultRows}
            columns={assignedOperatorColumns}
            uniqueIdInOptions="_id"
            options={employeeOptions || []}
            addButtonTitle="Add New Operator"
            onChange={(values: any) => {
              setData(values)
            }}
            loading={employeDataLoading}
            tooltipContent="Delete Operator"
          />
        </div>
      </div>
      <div className="flex justify-between mt-6">
        <div className="flex gap-3">
          <Button type="button" onClick={() => navigate('/maintenance/machines')}>
            Cancel
          </Button>
        </div>
        <div className="flex justify-end gap-2">
          {id ? (
            <>
              <div className="flex gap-3">
                <Button type="button" onClick={() => navigate(-1)}>
                  Previous
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  onClick={() => {
                    handleSubmit()
                    handleNext()
                  }}
                >
                  Next
                </Button>
              </div>
              <div>
                <Button
                  color="success"
                  type="submit"
                  loading={isLoading}
                  onClick={() => {
                    handleSubmit()
                    formik.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-3">
                <Button type="button" onClick={() => handleSkipStep()}>
                  Skip this step
                </Button>
              </div>
              <div className="flex gap-3">
                <Button type="button" onClick={() => navigate(-1)}>
                  Previous
                </Button>
              </div>
              <div>
                <Button
                  color="success"
                  type="button"
                  onClick={() => {
                    handleSubmit()
                    handleNext()
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AssignedOperators
