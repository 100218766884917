import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { t } from 'i18next'
import { useDeleteEntriesByIdMutation } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { trackEvent } from 'libs/layouts/mixpanel/mixPanel'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface Props {
  closePopUp: any
  batchData: any
  setBatchData: any
  deleteState: any
  setDeleteState: any
  processName: string
  departmentName: string
}

const DeleteEntryPopUp = ({
  closePopUp,
  batchData,
  setBatchData,
  deleteState,
  setDeleteState,
  departmentName,
  processName,
}: Props) => {
  const plant = useSelector(selectSelectedItem)

  const { data } = useUserInfoQuery({})

  const [deleteEntry, { isLoading: deleteEntryLoading }] = useDeleteEntriesByIdMutation()

  const handleDelete = () => {
    const ids = batchData?.length > 0 ? batchData : deleteState.ids

    deleteEntry({ entry_id: ids })
      .unwrap()
      .then((res: any) => {
        closePopUp()
        setDeleteState({ state: false, ids: [] })
        setBatchData([])
        toast.success(res.message)
        trackEvent('Process Entry', {
          Entry: 'Rejected Entry',
          Department: departmentName,
          Process: processName,
          Count: ids?.length,
          organization: data?.organization?.name,
          Plant: plant?.name,
        })
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <>
      <p className="mb-3 p-xs-regular">You are about to delete the entry {t('are_you_sure_you_want_to_proceed')}</p>

      <div className=" flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDelete} loading={deleteEntryLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeleteEntryPopUp
