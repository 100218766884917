import { t } from 'i18next'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import { useLocation } from 'react-router-dom'
import { formatString } from 'utils/stringHandlers'
import NewEditableTable from 'libs/table/NewEditableTable'
import QuickAddEditWorkOrder from 'libs/quick-add-edits/quick-add-edit-wo/QuickAddEditWoPanel'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { filters } from 'pages/work-orders/work-orders-endpoints/workordersType'
import { useGetWorkOrdersQuery } from 'pages/work-orders/work-orders-endpoints/workorders.endpoints'
import Popup from 'libs/popup/Popup'
import SaveChanges from '../popup/SaveChanges'

const WorkOrder = ({
  setPage,
  setId,
  manufactureOrderFormik,
  loading,
}: any) => {
  const { id } = useParams()
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<any>(null)
  const isEditMO = location?.pathname?.includes('edit')
  const [saveChangesPopUp, setSaveChangesPopUp] = useState<{
    isPopUpOpen: boolean
    moOrder: any
  }>({
    isPopUpOpen: false,
    moOrder: null,
  })

  const workOrderFilter: filters = {
    page_no: 1,
    page_size: 1000,
    moID: false,
  }

  useEffect(() => {
    setId({ id: id })
    setPage(4)
  }, [])

  const { data: WorkOrders, isLoading: isWorkOrderLoading } =
    useGetWorkOrdersQuery(
      {
        plantId: plantId?.id,
        filters: workOrderFilter,
      },
      {
        skip: !plantId?.id,
      },
    )
  // const [editWOrkOrder, { isLoading: WoUpadateLoading }] = usePutWorkOrderMutation()
  const workOrderOptions = useMemo(
    () =>
      WorkOrders?.data?.map((item: any) => ({
        id: item?.workOrderID,
        label: item?.workOrderID,
        value: {
          _id: item?._id,
          plant_id: item?.plant?._id,
          workOrderID: item?.workOrderID,
          operations: item?.operations,
          startDate: item?.startDate,
          endDate: item?.endDate,
          status: item?.status,
          assignedTo: item?.assignedTo,
        },
      })),
    [WorkOrders?.data],
  )

  const [data, setData] = useState<any[]>()

  useEffect(() => {
    const d = manufactureOrderFormik.values.workOrders
    if (d != null && d.length > 0) {
      setData(d)
    }
  }, [manufactureOrderFormik.values.workOrders])

  const workOrdersColumns = [
    {
      title: 'Work Order ID',
      accessor: 'workOrderID',
      flex: 2,
      main: true,
      editable: true,
      placeholder: 'Select Work Order',
    },

    {
      title: 'Operations',
      accessor: 'operations',
      render(value: any) {
        return value ? `${value?.length} operations` : '-'
      },
    },
    {
      title: 'Start Date',
      accessor: 'startDate',
      render(value: any) {
        return formatUnixDate(value)
      },
    },
    {
      title: 'End Date',
      accessor: 'endDate',
      render(value: any) {
        return formatUnixDate(value)
      },
    },
    {
      title: 'Status',
      accessor: 'status',
      render(value: any) {
        return formatString(value)
      },
    },
  ]
  const handleEdit = (purchase: any) => {
    setSelectedWorkOrder(purchase)
    setIsPanelOpen(true)
  }
  const handleSaveChanges = () => {
    setSaveChangesPopUp({ isPopUpOpen: true, moOrder: manufactureOrderFormik })
  }

  return (
    <div className="relative flex min-h-[70vh] flex-col gap-4 ">
      <>
        {isWorkOrderLoading ? (
          <div className="absolute left-1/2 top-1/2">
            {' '}
            <FullPageLoader />
          </div>
        ) : (
          <>
            <div className="flex justify-between">
              <div>
                <h5 className="h5-1">{'Work Orders'}</h5>
                <p className="p-sm-regular-2">
                  {!isEditMO
                    ? 'Add any Work orders that you have related to this MO.'
                    : 'Edit the existing Manufacturing Order details to make necessary changes and updates.'}
                </p>
              </div>

              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  setIsPanelOpen(true)
                  setSelectedWorkOrder(null)
                }}
              >
                {'Add Work Order'}
              </Button>
            </div>

            {/* editable - product */}
            <NewEditableTable
              defaultRows={manufactureOrderFormik.values.workOrders}
              options={workOrderOptions}
              columns={workOrdersColumns}
              uniqueIdInOptions="workOrderID"
              addButtonTitle="Select Work Order"
              onChange={(values: any) => {
                setData(values)
              }}
              onEdit={handleEdit}
            />

            <QuickAddEditWorkOrder
              id={selectedWorkOrder?.workOrderID?._id}
              onClose={() => setIsPanelOpen(false)}
              isOpen={isPanelOpen}
              manufactureOrderFormik={manufactureOrderFormik}
              data={data}
            />
            <div className="flex flex-grow  gap-3 "></div>

            <div className="mt-4 flex justify-between">
              <div className="flex gap-3">
                <Button type="button" onClick={handleSaveChanges}>
                  {t('cancel')}
                </Button>
                <Popup
                  isOpen={saveChangesPopUp.isPopUpOpen}
                  title={'Save Changes?'}
                  onClose={() => {
                    setSaveChangesPopUp({ isPopUpOpen: false, moOrder: null })
                  }}
                >
                  <SaveChanges
                    manufactureOrderFormik={manufactureOrderFormik}
                    data={data}
                    setPage={setPage}
                    tabName="workOrders"
                    loading={
                      !manufactureOrderFormik.values.isDraft ? loading : false
                    }
                  />
                </Popup>
                {false && !state?.edit && (
                  <Button
                    loading={
                      manufactureOrderFormik.values.isDraft ? loading : false
                    }
                    color="primary"
                    type="submit"
                    onClick={() => {}}
                  >
                    {t('plannings.save_as_draft')}
                  </Button>
                )}
              </div>
              <div className="flex gap-3">
                <Button
                  onClick={() => {
                    setPage(3)
                    isEditMO
                      ? navigate(`/planning/mo/${id}/edit/purchase-orders`)
                      : navigate(`/planning/mo/${id}/add/purchase-orders`)
                  }}
                >
                  {'Previous'}
                </Button>
                <Button
                  loading={
                    !manufactureOrderFormik.values.isDraft ? loading : false
                  }
                  color="success"
                  type="submit"
                  onClick={() => {
                    manufactureOrderFormik.setFieldValue('workOrders', data)
                    manufactureOrderFormik.handleSubmit()
                  }}
                >
                  {t('save')}
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  )
}

export default WorkOrder
