import { CloseRounded } from '@mui/icons-material'
import { toast } from 'libs/toast'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'libs/button/Button'
import { useDeleteDepartmentMutation } from 'pages/department/department-endpoints/department.endpoints'

function DeleteDepartment({ department, close }: any) {
  const [deletePost, { isLoading }] = useDeleteDepartmentMutation()
  const [formData, setFormData] = useState({ name: '' })

  const { t } = useTranslation()

  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const handleDelete = () => {
    if (department?._id && formData.name === department?.name) {
      deletePost({ departmentId: department?._id })
        .unwrap()
        .then(() => {
          toast.success(`The department ${department?.name} is removed successfully.`)
          close()
        })
        .catch((error) => {
          toast.error(error?.data?.detail ?? `Failed to remove a department. Please try again.`)
        })
    }
  }

  return (
    <div>
      <div className="z-50 w-96 rounded-2xl bg-white p-4 shadow-2xl">
        <div className="mb-3 flex justify-between">
          <div>
            <h5 className="h4">{t('departments.delete_department')}?</h5>
          </div>
          <div>
            <div className="cursor-pointer">
              <CloseRounded onClick={close} />
            </div>
          </div>
        </div>
        <p className="p-xs-regular">
          {t('delete_text')}
          <span className="break-words p-xs-bold"> "{department?.name}" </span>
          {t('departments.department_delete_text')} {department?.processes?.length} processes.
        </p>
        <div className="mb-2 mt-2 flex">
          {department?.process?.map((item: any) => (
            <div key={item} className="mb-1 mr-1 rounded-[28px] bg-[#F2F2F2] px-2 py-0.5 text-[#434C52] p-xs-regular">
              {item}
            </div>
          ))}
        </div>
        <p className="p-xs-regular">{t('departments.delete_message')} support@catalystdomainname.</p>

        <div>
          <label className="mb-1 p-sm-regular">
            {t('departments.department_name')} <span className="text-error">*</span>
          </label>
          <div>
            <div className="relative py-1">
              <input
                required
                type="text"
                name="name"
                value={formData?.name}
                onChange={handleInputChange}
                className={`h-[28px] w-full rounded border border-solid border-[#E0E0E0] p-2 pr-8 text-sm outline-none p-xs-regular`}
                placeholder={t('departments.conformation_placeholder')}
              />
            </div>
          </div>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={close}> {t('departments.close')}</Button>
          <Button
            disabled={formData.name !== department?.name}
            loading={isLoading}
            color="error"
            onClick={handleDelete}
          >
            {' '}
            {t('departments.delete')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeleteDepartment
