import { getAuthTokenAndRole } from './auth'
export const caseSensitiveSort = (
  rowA: any,
  rowB: any,
  columnId: string,
): number => {
  const valueA: string = rowA.values[columnId]
  const valueB: string = rowB.values[columnId]

  if (!valueA && !valueB) return 0
  if (!valueA) return 1
  if (!valueB) return -1

  return valueA.localeCompare(valueB, undefined, {
    sensitivity: 'base',
    caseFirst: 'lower',
  })
}

export const hasAccess = (moduleName: any, accessObject: any) => {
  const { modules, role } = getAuthTokenAndRole()
  const modules1 = JSON?.parse(modules || '{}')

  if (role == 'admin') return true
  for (const [key, requiredAccess] of Object.entries(accessObject)) {
    const accessLevel = modules1[moduleName]?.[key]

    // If the access level is not sufficient, return false
    if (!accessLevel) return false
    if (
      requiredAccess === 'v' &&
      !(accessLevel === 'v' || accessLevel === 'e')
    ) {
      return false
    }
    if (requiredAccess === 'e' && accessLevel !== 'e') {
      return false
    }
  }

  // If all checks pass, return true
  return true
}
