import { AnimatePresence, motion } from 'framer-motion'
import { scaleMotionProps2 } from 'utils/FramerProps'

/**
 * Component: MotionDiv
 *
 * Description: This component wraps its children with Framer Motion's motion.div
 *             for animations, utilizing AnimatePresence for presence animations.
 *
 * @param {JSX.Element} props - The props object containing the children to be wrapped.
 * @returns {JSX.Element} - Returns a JSX element with motion.div and AnimatePresence.
 */

interface Props {
  children: JSX.Element
  className?: string
}
const MotionDiv = ({ children, className }: Props) => {
  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2}>
        <div className={className ?? ''}>{children}</div>
      </motion.div>
    </AnimatePresence>
  )
}

export default MotionDiv
