function PlantsIcon({ color }: { color?: string }) {
    return (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 20.5C1.45 20.5 0.979167 20.3042 0.5875 19.9125C0.195833 19.5208 0 19.05 0 18.5V9.8C0 9.4 0.108333 9.03333 0.325 8.7C0.541667 8.36667 0.841667 8.11667 1.225 7.95L5.6 6.1C5.93333 5.96667 6.25 5.99167 6.55 6.175C6.85 6.35833 7 6.63333 7 7V7.5L10.625 6.05C10.9583 5.91667 11.2708 5.95 11.5625 6.15C11.8542 6.35 12 6.625 12 6.975V8.5H20V18.5C20 19.05 19.8042 19.5208 19.4125 19.9125C19.0208 20.3042 18.55 20.5 18 20.5H2ZM2 18.5H18V10.5H10V8.45L5 10.45V8.5L2 9.825V18.5ZM9 16.5H11V12.5H9V16.5ZM5 16.5H7V12.5H5V16.5ZM13 16.5H15V12.5H13V16.5ZM20 8.5H15L15.9 1.375C15.9333 1.125 16.0417 0.916667 16.225 0.75C16.4083 0.583333 16.625 0.5 16.875 0.5H18.125C18.375 0.5 18.5917 0.583333 18.775 0.75C18.9583 0.916667 19.0667 1.125 19.1 1.375L20 8.5Z"
          fill={color ? `${color}` : '#041220'}
        />
      </svg>
    )
  }
  
  export default PlantsIcon