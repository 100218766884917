import Button from '../../../libs/button/Button'
import { toast } from 'libs/toast'
import { t } from 'i18next'
import { useDeleteFormFieldMutation } from '../operational-process-endpoints/operational_process.endpoints'

interface Props {
  closePopUp: any
  productId: any
  fieldData: any
  processId: any
}

function DeleteFormField({ closePopUp, productId, fieldData, processId }: Props) {
  const [deleteFormField, { isLoading }] = useDeleteFormFieldMutation()

  const deleteHandler = () => {
    deleteFormField({ process_id: processId, product_id: productId, field_id: fieldData?.id })
      .unwrap()
      .then(() => {
        closePopUp()
        toast.success('Deleted Successfully')
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong')
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')} <span className="break-words p-xs-bold ">Field .</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" flex justify-end gap-3">
        <Button size="sm" onClick={closePopUp}>
          Close
        </Button>
        <Button color="error" size="sm" onClick={deleteHandler} loading={isLoading}>
          Delete Field
        </Button>
      </div>
    </div>
  )
}

export default DeleteFormField
