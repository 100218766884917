import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'
import { toast } from 'libs/toast'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useDeleteWorkCenterMutation } from '../work-center-endpoints/workcenter.endpoint'

const DeleteWorkCenter = ({ closePopUp, workCenter }: any) => {
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const [deleteWorkCenter, { isLoading: deleteLoading }] = useDeleteWorkCenterMutation()
  const handleDelete = () => {
    deleteWorkCenter({ plantId: plantId._id, wcID: workCenter._id })
      .unwrap()
      .then(() => {
        closePopUp()
        navigate('/settings/configure/work-centers')

        toast.success(
          <div>
            The Work Center <b>"{workCenter.name}"</b> is removed successfully.
          </div>,
        )
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to delete work center <span className="break-words pr-1 p-xs-bold">"{workCenter?.name}".</span>{' '}
        Are you sure you want to proceed?
      </p>
      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={handleDelete} loading={deleteLoading}>
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteWorkCenter
