function PreviousPageArrow() {
    return (
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.50016 4.99984L5.75016 1.74984C5.90294 1.59706 5.97933 1.40262 5.97933 1.1665C5.97933 0.930393 5.90294 0.735948 5.75016 0.583171C5.59738 0.430393 5.40294 0.354004 5.16683 0.354004C4.93072 0.354004 4.73628 0.430393 4.5835 0.583171L0.750163 4.4165C0.666829 4.49984 0.607808 4.59012 0.573079 4.68734C0.53836 4.78456 0.520996 4.88873 0.520996 4.99984C0.520996 5.11095 0.53836 5.21512 0.573079 5.31234C0.607808 5.40956 0.666829 5.49984 0.750163 5.58317L4.5835 9.4165C4.73628 9.56928 4.93072 9.64567 5.16683 9.64567C5.40294 9.64567 5.59738 9.56928 5.75016 9.4165C5.90294 9.26373 5.97933 9.06928 5.97933 8.83317C5.97933 8.59706 5.90294 8.40262 5.75016 8.24984L2.50016 4.99984Z"
          fill="#041220"
        />
      </svg>
    )
  }
  
  export default PreviousPageArrow
  