export enum CATEGORY {
  RAW_MATERIALS = 'RAW_MATERIALS',
  SERVICES = 'SERVICES',
}

export const CATEGORY_OPTIONS = [
  { label: 'Raw materials', value: CATEGORY.RAW_MATERIALS },
  { label: 'Services', value: CATEGORY.SERVICES },
]

export enum VENDOR_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  GOVERNMENT = 'GOVERNMENT',
  PRIVATE_COMPANY = 'PRIVATE_COMPANY',
}
export const VENDOR_TYPE_OPTIONS = [
  { label: 'Individual', value: VENDOR_TYPE.INDIVIDUAL },
  { label: 'Government', value: VENDOR_TYPE.GOVERNMENT },
  { label: 'Private Company', value: VENDOR_TYPE.PRIVATE_COMPANY },
]
