import { useTranslation } from 'react-i18next'
import DashboardTilesProdAndReview from 'libs/dashboard-tiles-prod-review/DashboardTilesProdAndReview'
import useTabTitle from 'libs/hooks/useTabTitle'

const ProductionV2 = () => {
  const { t } = useTranslation()
  useTabTitle(t('production'))
  return <DashboardTilesProdAndReview title={t('production')} showCount={false} />
}

export default ProductionV2
