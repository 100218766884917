import React from "react";

interface AlertIconProps {
  width?: number;
  height?: number;
  color?: string;
  classProp?: string;
}
const AlertIcon: React.FC<AlertIconProps> = ({
  width = 24,
  height = 24,
  color = "#8E0000",
  classProp = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={classProp}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.47 21H19.53C21.07 21 22.03 19.33 21.26 18L13.73 4.98999C12.96 3.65999 11.04 3.65999 10.27 4.98999L2.74 18C1.97 19.33 2.93 21 4.47 21ZM12 14C11.45 14 11 13.55 11 13V11C11 10.45 11.45 9.99999 12 9.99999C12.55 9.99999 13 10.45 13 11V13C13 13.55 12.55 14 12 14ZM13 18H11V16H13V18Z"
        fill={color}
      />
    </svg>
  );
};

export default AlertIcon;
