import WhiteCard from 'libs/card/WhiteCard'
import Field from 'libs/field'
import MotionDiv from 'libs/motionDiv'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useFormik } from 'formik'
import { useGetMaintenanceOrdersQuery } from 'pages/maintenance/maintenance-order/maintenance-order-endpoints/order.endpoints'
import PaginatedTable from 'libs/table/PaginatedTable'
import { formatUnixDateWithTime } from 'utils/dateTimeHandlers'
import Chip from 'libs/chip/Chip'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetMaintenanceRequestsQuery,
  usePatchLinkMaintenanceRequestMutation,
} from '../maintenance-request/maintenance-request'
import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import * as yup from 'yup'
const filter = {
  page_no: 1,
  page_size: 10,
  searchText: '',
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const LinkMaintenanceRequest = () => {
  const plantId = useSelector(selectSelectedItem)
  const [linkOrder, { isLoading: isLinkOrder }] =
    usePatchLinkMaintenanceRequestMutation()
  const params = useParams()
  const paramsArray = params?.id?.split(',') ?? []

  const navigate = useNavigate()
  const [filters, setFilters] = useState({
    page_no: 1,
    page_size: 10,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
    selectedRequests: [],
  })
  useEffect(() => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: 1,
      selectedRequests: paramsArray ? paramsArray : '',
    }))
  }, [params?.id])
  const { data: maintenanceRequest, isLoading: isMaintenanceRequest } =
    useGetMaintenanceRequestsQuery(
      {
        plantId: plantId?.id,
        filters: filters,
      },
      {
        skip: !plantId?.id,
      },
    )

  const { data: maintenanceOrder } = useGetMaintenanceOrdersQuery(
    {
      plantId: plantId?.id,
      filters: filter,
    },
    {
      skip: !plantId?.id,
    },
  )
  const columns = useMemo(
    () => [
      {
        Header: 'Request ID',
        accessor: 'requestID',
        width: 150,

        Cell: ({ row }: any) => {
          const request = row?.original
          return (
            <div
              className="hyperlink"
              onClick={() => {
                navigate(
                  `/maintenance/maintenance-request/${request?._id}/view`,
                )
              }}
            >
              {request?.requestID}
            </div>
          )
        },
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        width: 100,
        Cell: ({ row }: any) => {
          const reason = row?.original?.reason
          return (
            <div>
              {reason && reason.length > 20
                ? `${reason.slice(0, 20)}...`
                : reason}
            </div>
          )
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 200,
        Cell: ({ row }: any) => {
          const description = row?.original?.description
          return (
            <div>
              {description && description.length > 20
                ? `${description.slice(0, 20)}...`
                : description}
            </div>
          )
        },
      },
      {
        Header: 'Machines',
        accessor: 'machine',
        width: 10,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 10,
        Cell: ({ row }: any) => {
          const orderStatus = row?.original?.status
          return (
            <div>
              <Chip title={orderStatus} />
            </div>
          )
        },
      },

      {
        Header: 'Created by',
        accessor: 'created_by',
        width: 100,

        Cell: ({ row }: any) => {
          const created_by = row.original.created_by
          if (created_by) {
            const name = `${created_by?.first_name} ${created_by?.last_name}`
            const time = formatUnixDateWithTime(row.original.created_on)
            return (
              <div>
                <div>{name}</div>
                <div className="p-xs-regular-dim">{time}</div>
              </div>
            )
          }
        },
      },
    ],
    [],
  )

  const maintenanceOrderOptions = maintenanceOrder?.data
    ?.filter((item: any) => {
      if (item?.status === 'CLOSED') return false
      if (item?.status === 'CANCELLED') return false
      return true
    })
    .map((order: any) => {
      return {
        label: order?.orderID,
        value: order?._id,
      }
    })
  const formik = useFormik({
    initialValues: {
      maintenance_order: [],
    },
    validationSchema: yup.object().shape({
      maintenance_order: yup.array().min(1, 'Maintenance Order is required'),
    }),
    onSubmit: () => {
      linkOrder({
        plantId: plantId?.id,
        moID: formik?.values?.maintenance_order,
        data: { data: maintenanceRequest?.data?.map((items) => items?._id) },
      })
        .unwrap()
        .then(() => {
          const data = maintenanceOrder?.data?.filter(
            (item: any) => item?._id == formik?.values?.maintenance_order,
          )
          toast.success(
            `${maintenanceRequest?.data?.length} ${maintenanceRequest?.data?.length == 1 ? 'Request is linked' : 'Requests are linked'} to Order ${data[0]?.orderID}`,
          )
          navigate('/maintenance/maintenance-request')
        })
        .catch((error) => {
          const errorMessage = Array.isArray(error?.data?.detail)
            ? error?.data?.detail[0]?.msg
            : error?.data?.detail
          toast.error(
            errorMessage ?? 'Something went wrong while linking order',
          )
        })
    },
  })

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">Link Maintenance Request</h1>
          </div>
        </div>
        <WhiteCard>
          <span>
            <h5 className="h5">Link Maintenance Request</h5>
            <p className="p-sm-regular-1">
              Link related maintenance requests to streamline task management.
            </p>
          </span>
          <div className="w-1/3">
            <Field
              type="select"
              label="Maintenance Order"
              required={true}
              formik={formik}
              options={maintenanceOrderOptions}
              name="maintenance_order"
              placeholder="Select orders"
            />
          </div>
          <h1 className="my-3 text-xl font-medium">Selected Requests</h1>
          <PaginatedTable
            columns={columns}
            rows={maintenanceRequest?.data}
            loading={isMaintenanceRequest}
            pagination={maintenanceRequest?.paging}
          />
          <div
            className={`flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}
          >
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
              color="success"
              loading={isLinkOrder}
              onClick={() => formik.handleSubmit()}
            >
              Link
            </Button>
          </div>
        </WhiteCard>
      </>
    </MotionDiv>
  )
}

export default LinkMaintenanceRequest
