import { useNavigate, useParams } from 'react-router'
import { FormikContextType } from 'formik'
import { PlanForm } from '../../planning-wizard'
import { formatUnixDate, getRemainingDays } from 'utils/dateTimeHandlers'
import { useGetProductionPlanQuery } from '../../production-plans-endpoints/productionPlan.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import Button from 'libs/button/Button'
import { useEffect } from 'react'
import PaginatedTable from 'libs/table/PaginatedTable'

interface Props {
  formik: FormikContextType<PlanForm>
  createLoading: boolean
  setPage: any
  setId: any
}

const Summary = ({ formik, createLoading, setPage, setId }: Props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const isEdit = location.pathname.includes('edit')

  const filters = {
    planID: id,
    page_no: 1,
    page_size: 10,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
  }
  const { data, isFetching } = useGetProductionPlanQuery(
    {
      plantId: plantId?.id,
      filters: filters,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !plantId?.id,
    },
  )

  useEffect(() => {
    setId({ id: id })
    setPage(4)
  }, [])

  const columns = [
    {
      Header: 'MO ID',
      accessor: 'manufactureOrderID',
      width: 150,
    },
    {
      Header: 'Finished Good',
      accessor: 'finishedGood',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.finishedGood?.name ? (
              <div>
                <p className="text-sm">{row?.original?.finishedGood?.name}</p>
                <p className="text-md text-gray-600">
                  {row?.original?.finishedGood?.code}
                </p>
              </div>
            ) : (
              <p className="ml-6">-</p>
            )}
          </>
        )
      },
    },
    {
      Header: 'Sales Order',
      accessor: 'salesOrders',
      width: 100,
      Cell: ({ value }: any) => (
        <button className="text-primary-700" type="button">
          {value?.length} Orders
        </button>
      ),
    },
    {
      Header: 'Purchase Order',
      accessor: 'purchaseOrders',
      width: 120,
      Cell: ({ value }: any) => (
        <button className="text-primary-700" type="button">
          {value?.length} Orders
        </button>
      ),
    },
    {
      Header: 'Work Order',
      accessor: 'workOrders',
      width: 100,
      Cell: ({ value }: any) => (
        <button className="text-primary-700" type="button">
          {value?.length} Orders
        </button>
      ),
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.startDate ? (
              <div>
                <p className="p-xs-regular">
                  {formatUnixDate(row?.original?.startDate)}
                </p>
              </div>
            ) : (
              <p className="ml-6">-</p>
            )}
          </>
        )
      },
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.endDate ? (
              <div>
                <p className="p-xs-regular">
                  {formatUnixDate(row?.original?.endDate)}
                </p>
              </div>
            ) : (
              <p className="ml-6">-</p>
            )}
          </>
        )
      },
    },
  ]

  const start = formik?.values?.startDate ?? 0
  const end = formik?.values?.endDate ?? 0

  const startDate =
    start !== null ? formatUnixDate(start, plantId.timezone) : null
  const endDate = end !== null ? formatUnixDate(end, plantId.timezone) : null

  return (
    <div className="flex min-h-screen w-full flex-col px-2">
      <h1 className="h5-1">{t('summary')}</h1>
      <p className="text-sm text-gray-600">{t('plannings.summary_heading')}</p>

      <h1 className="mt-3 p-md-regular-500">{formik?.values?.planID}</h1>
      {start && (
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2 border-r-2 pr-2">
            <h2 className="text-xs">{`${startDate}`}</h2>
            <span>-{'>'}</span>
            <h2 className="text-xs">{`${endDate}`}</h2>
          </div>
          <span className="text-xs">{getRemainingDays(start, end)} days</span>
        </div>
      )}
      <span className="mt-2 text-xs text-gray-600">
        {data?.data[0].description}
      </span>
      <div className="mt-3 flex-grow items-center rounded bg-[#FFFFFF]">
        <PaginatedTable
          columns={columns}
          rows={data?.data[0]?.manufactureOrders}
          currentPageNumber={filters.page_no}
          pageSize={filters.page_size}
          loading={isFetching}
          sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
          pagination={data?.paging}
          emptyMessage={'No production plan to show.'}
          hidePagination
        />
      </div>
      <div className="mt-10 flex justify-between">
        <Button onClick={() => navigate('/planning/production-plans')}>
          {t('cancel')}
        </Button>
        <div className="flex gap-2">
          <Button
            disabled={createLoading}
            onClick={() => {
              navigate('../mo')
              setPage(3)
            }}
          >
            {t('plannings.previous')}
          </Button>
          <Button
            disabled={createLoading}
            color="success"
            onClick={() => {
              formik.setFieldValue('isDraft', false)
              formik.handleSubmit()
            }}
          >
            {isEdit ? 'Update Plan' : 'Create Plan'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Summary
