const WhiteCloseIcon = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.26634 9.66683L0.333008 8.7335L4.06634 5.00016L0.333008 1.26683L1.26634 0.333496L4.99967 4.06683L8.73301 0.333496L9.66634 1.26683L5.93301 5.00016L9.66634 8.7335L8.73301 9.66683L4.99967 5.9335L1.26634 9.66683Z"
        fill="white"
      />
    </svg>
  )
}

export default WhiteCloseIcon
