import MotionDiv from 'libs/motionDiv'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import { useMemo, useState } from 'react'
import useTabTitle from 'libs/hooks/useTabTitle'
import Tags from 'libs/tags/Tags'
import { useGetWorkCentersQuery } from './work-center-endpoints/workcenter.endpoint'
import { useNavigate } from 'react-router-dom'
import Popup from 'libs/popup/Popup'
import DeleteWorkCenter from './popup/DeleteWorkCenter'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import WorkCenterFilter from './panel/FilterWorkCenter'
import Tooltip from 'libs/tool-tip/Tooltip'

const WorkCenters = () => {
  const navigate = useNavigate()
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, workCenter: '' })
  useTabTitle('Work Centers')
  const columns = useMemo(
    () => [
      {
        Header: 'Work Center',
        accessor: 'workCenterID',
        Cell: ({ row }: any) => {
          return (
            <Tooltip content="View Details" position="right">
              <div className="flex flex-col gap-[1px]">
                <h1
                  className="hyperlink"
                  onClick={() => {
                    navigate(`/settings/configure/work-centers/${row?.original?._id}/view`)
                  }}
                >
                  {row.original?.name ?? '-'}
                </h1>
                <h1 className="text-gray-600">{row?.original?.workCenterID ?? '-'}</h1>
              </div>
            </Tooltip>
          )
        },
      },
      {
        Header: 'Shift Policies',
        accessor: 'shiftPolicies',
        width: 200,
        Cell: ({ value }: any) => {
          let shifts = value?.slice(0, 2).map((item: any) => item?.policyName)

          let remainingShifts =
            value
              ?.slice(2)
              .map((item: any) => item.policyName)
              .filter(Boolean) || []

          const remainingCount = value?.length - shifts?.length

          return (
            <Tags
              data={shifts}
              remainingCount={remainingCount}
              color={'#780078'}
              bgColor={'#FFE3FF'}
              remaining={remainingShifts}
            />
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Total Shifts',
        width: 100,
        Cell: ({ row }: any) => {
          const shiftPolicies = row.original?.shiftPolicies || []
          const shiftCount =
            shiftPolicies
              ?.map((policy: any) => policy.shifts?.length || 0)
              .reduce((acc: any, length: any) => acc + length, 0) || 0

          // Extract shift names
          const shiftNames = shiftPolicies.flatMap(
            (policy: any) => policy.shifts?.map((shift: any) => shift?.shiftName || 'Unnamed Shift') || [],
          )

          return (
            <Tooltip
              content={
                shiftNames.length > 0 ? (
                  shiftNames.map((shiftName: string, index: number) => (
                    <div key={index}>
                      <span>• </span>
                      <span>{shiftName}</span>
                    </div>
                  ))
                ) : (
                  <p>No shift are available</p>
                )
              }
              position="left"
            >
              <span className="cursor-pointer">
                {shiftCount.toString().padStart(2, '0')} {shiftCount > 1 ? 'Shifts' : 'Shift'}
              </span>
            </Tooltip>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Machines',
        accessor: 'machines',

        Cell: ({ value }: any) => {
          let machines = value?.slice(0, 2).map((item: any) => item?.name)
          let remaining = value?.slice(2).map((item: any) => item?.name)
          const remainingCount = value?.length - machines?.length
          return (
            <Tags
              data={machines}
              remainingCount={remainingCount}
              color={'#674D00'}
              bgColor={'#FFF3CD'}
              remaining={remaining}
            />
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Operations',
        accessor: 'operations',
        width: 200,
        Cell: ({ value }: any) => {
          let operations = value?.slice(0, 2).map((item: any) => item?.name)
          let remainingOperations = value?.slice(2).map((item: any) => item?.name)
          const remainingCount = value?.length - operations?.length
          return (
            <Tags
              data={operations}
              remainingCount={remainingCount}
              color={'#0B5ED7'}
              bgColor={'#E7F5FF'}
              remaining={remainingOperations}
            />
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 350,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const description = row?.original?.description
          return <div className="truncate-text w-[350px]">{description.length ? description : '-'}</div>
        },
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        width: 100,
        Cell: ({ row }: any) => (
          <div className="flex w-full items-center justify-between ">
            <div className="flex flex-row">
              <Tooltip content="Edit Details">
                <button
                  onClick={() =>
                    row?.original?._id &&
                    navigate(`/settings/configure/work-centers/${row?.original?._id}/edit/basic-details`)
                  }
                >
                  <Pen />
                </button>
              </Tooltip>
              <Tooltip content="Delete Details">
                <button
                  className="mr-6"
                  onClick={(event) => {
                    event.stopPropagation()
                    setDeletePopUp({ isPopUpOpen: true, workCenter: row?.original })
                  }}
                >
                  <Bin />
                </button>
              </Tooltip>
            </div>
            <Tooltip content="View Details" position="left">
              <span
                className="cursor-pointer"
                onClick={() => {
                  row?.original?._id && navigate(`/settings/configure/work-centers/${row?.original?._id}/view`)
                }}
              >
                <ClickableRowArrow />
              </span>
            </Tooltip>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const handleAddWorkCenter = () => {
    navigate('/settings/configure/work-centers/add/basic-details')
  }
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Work Centers</h1>

        <p className="w-[600px] p-sm-regular-1">
          Track and manage areas of your production process with each work center.
        </p>

        <FilterableSearchTable
          useQuery={useGetWorkCentersQuery}
          columns={columns}
          filterPanelComponent={WorkCenterFilter}
          addButton={{ label: 'Add Work Center', onClick: handleAddWorkCenter }}
          placeholders={{
            search: 'Search Work centers by ID, name or Machines',
            emptyMessage: 'No Work centers are added. Please add a Work center.',
            filterEmptyMessage: 'No matching results found',
          }}
          additionalFilters={[]}
        />
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Work Center"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, workCenter: '' })
          }}
        >
          <DeleteWorkCenter
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, workCenter: '' })}
            workCenter={deletePopUp.workCenter}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default WorkCenters
