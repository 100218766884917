import Button from '../../../libs/button/Button'

import { useUpdateManufactureOrdersStatusMutation } from '../manufacture-orders-endpoints/manufacture_order.endpoints'
import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  moOrder: any
  plantId: string
  quantityProduced: any
}

function CompleteMoOrder({ closePopUp, moOrder, plantId, quantityProduced }: Props) {
  const [updateMoOrder, { isLoading: updateWorkOrderLoading }] = useUpdateManufactureOrdersStatusMutation()

  const navigate = useNavigate()

  const handleUpdateMoOrder = () => {
    updateMoOrder({ plantId, moID: moOrder?._id, status: 'COMPLETED' })
      .unwrap()
      .then(() => {
        toast.success(`Manufacture Order “${moOrder?.manufactureOrderID}” is completed successfully.`)
        navigate(-1)
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(
          error?.data?.detail ?? `Failed to complete Manufacture Order “${moOrder?.manufactureOrderID}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to mark the manufacture order{' '}
        <span className="break-words pr-1 p-xs-bold">“{moOrder?.manufactureOrderID}”</span>
        as complete. This Manufacture Order is {parseFloat(quantityProduced.find((item: any) => item?.name === 'Completed')?.value?.toFixed(0)) ?? 0}% complete. {parseFloat(quantityProduced.find((item: any) => item?.name === 'Remaining')?.value?.toFixed(0)) ?? 0}% of the work remains. Are you sure you want to
        proceed?
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Cancel</Button>
        <Button color="primary" onClick={handleUpdateMoOrder} loading={updateWorkOrderLoading}>
          Mark as complete
        </Button>
      </div>
    </div>
  )
}

export default CompleteMoOrder
