import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import UnauthorizedImage from 'assets/images/Unauthorized.png'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'

const Unauthorized_403 = () => {
    const navigate = useNavigate()
  return (
    <MotionDiv>
      <WhiteCard className="h-[82vh] px-4">
        <div className="border-gray-450 border-b">
          <h1 className="text-[32px] font-bold text-error">Oops...You Can’t Access This Module - 403</h1>
        </div>
        <h1 className="font-bold text-gray-800 mb-3">
          "Sorry, it looks like you don’t have the necessary permissions to view or edit this module. Please contact
          your administrator if you believe this is an error or if you need access."
        </h1>
        <Button color='primary' onClick={()=>navigate(-2)}>Go Back to Previous Page</Button>
        <div className="my-auto flex w-full justify-center py-2">
          <img src={UnauthorizedImage} alt="Unauthorized" height="424px" width="326px" />
        </div>
      </WhiteCard>
    </MotionDiv>
  )
}

export default Unauthorized_403
