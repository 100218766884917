import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  GetShiftManagementRequest,
  GetShiftManagementResponse,
  PostShiftManagementRequest,
  PostShiftManagementResponse,
} from './shiftManagementTypes'

export const shiftManagement = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getShiftPolicy: builder.query<
      GetShiftManagementResponse,
      GetShiftManagementRequest
    >({
      query: ({ plantId, filters }) => {
        return {
          url: buildUrl(`/plant/${plantId}/shiftPolicies`, filters),
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the shift policy')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getShiftPolicy'],
    }),
    getShiftPolicyByID: builder.query({
      query: ({ plantId, shiftPolicyID }) => {
        return {
          url: `/plant/${plantId}/shiftPolicy/${shiftPolicyID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the shift policy')
            }
            return response.json()
          },
        }
      },
      providesTags: ['updateShiftPolicy'],
    }),
    postShiftPolicy: builder.mutation<
      PostShiftManagementResponse,
      PostShiftManagementRequest
    >({
      query: ({ plantId, data }) => {
        return {
          url: `/plant/${plantId}/shiftPolicy`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateShiftPolicy', 'getShiftPolicy'],
    }),
    putShiftPolicy: builder.mutation({
      query: ({ plantId, data, shiftPolicyID }) => {
        return {
          url: `/plant/${plantId}/shiftPolicy/${shiftPolicyID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateShiftPolicy', 'getShiftPolicy'],
    }),
    deleteShiftPolicy: builder.mutation({
      query: ({ plantId, shiftPolicyID }) => ({
        url: `/plant/${plantId}/shiftPolicy/${shiftPolicyID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['getShiftPolicy'],
    }),
    getShiftPolicyStats: builder.query({
      query: ({ plantId }) => ({
        url: `/plant/${plantId}/shiftPolicies/count`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['getShiftPolicy'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useDeleteShiftPolicyMutation,
  usePostShiftPolicyMutation,
  usePutShiftPolicyMutation,
  useGetShiftPolicyQuery,
  useGetShiftPolicyByIDQuery,
  useGetShiftPolicyStatsQuery,
} = shiftManagement
