import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  CreateWarehouseRequestType,
  DeleteWarehouseRequestType,
  UpdateWarehouseRequestType,
  WarehouseMutationResponse,
  WarehouseRequest,
  WarehouseResponse,
  WarehousesRequest,
  WarehousesResponse,
  WarehouseStroageCountResponse,
} from './warehouseTypes'

export const warehouse = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    createWarehouse: builder.mutation<
      WarehouseMutationResponse,
      CreateWarehouseRequestType
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/warehouse`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['warehouseUpdate'],
    }),
    updateWarehouse: builder.mutation<
      WarehouseMutationResponse,
      UpdateWarehouseRequestType
    >({
      query: ({ data, plant_id, id }) => ({
        url: `/plant/${plant_id}/warehouse/${id}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['warehouseUpdate'],
    }),
    deleteWarehouse: builder.mutation<
      WarehouseMutationResponse,
      DeleteWarehouseRequestType
    >({
      query: ({ plant_id, id }) => ({
        url: `/plant/${plant_id}/warehouse/${id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['warehouseDelete'],
    }),
    getWarehouseById: builder.query<WarehouseResponse, WarehouseRequest>({
      query: ({ plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/warehouse/${id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the warehouse')
            }
            return response.json()
          },
        }
      },
      providesTags: ['warehouseUpdate'],
    }),
    getWarehouses: builder.query<WarehousesResponse, WarehousesRequest>({
      query: ({ plant_id, filters }) => {
        const URL = buildUrl(`/plant/${plant_id}/warehouses`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the warehouse ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['warehouseUpdate', 'inventoryUpdate', 'warehouseDelete'],
    }),
    getWarehouseAndStorageCount: builder.query<
      WarehouseStroageCountResponse,
      {
        plant_id: string
      }
    >({
      query: ({ plant_id }) => {
        return {
          url: `/plant/${plant_id}/warehouses/count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the  warehouse/storage count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['warehouseUpdate'],
    }),
  }),
  overrideExisting: false,
})
export const {
  useCreateWarehouseMutation,
  useGetWarehousesQuery,
  useGetWarehouseAndStorageCountQuery,
  useGetWarehouseByIdQuery,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation,
} = warehouse
