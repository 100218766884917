import Field from 'libs/field'
import { t } from 'i18next'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetPurchaseOrdersQuery } from 'pages/orders/purchase-order/purchase-orders-endpoints/purchaseOrders.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'

const ProcurementForm = ({
  formik,
  storageAreaToOptions,
  warehouseToOptions,
  unit,
  selectedProduct,
  transaction,
}: any) => {
  const location = useLocation()
  const plantId = useSelector(selectSelectedItem)
  const isInitialRender = useRef(true)
  const filters = {
    page_no: 1,
    page_size: 1000,
    sort_dir: 'DESC',
    product_id: selectedProduct?._id,
  }

  const { data: purchaseOrdersData } = useGetPurchaseOrdersQuery(
    {
      plant_id: plantId?._id,
      filters: filters,
    },
    { skip: !plantId?._id || !selectedProduct },
  )

  const purchaseOrders =
    purchaseOrdersData?.data?.map((purchaseOrder: any) => ({
      _id: purchaseOrder.id,
      value: purchaseOrder.id,
      label: purchaseOrder.purchaseOrderID,
    })) || []

  const warehouseTo: any =
    transaction?.warehouseTo?.id === formik?.values?.warehouse_to?.id
      ? {
          label: transaction?.warehouseTo?.name,
          value: transaction?.warehouseTo?.id,
        }
      : { label: '', value: '' }

  const storageAreaTo: any =
    transaction?.warehouseTo?.id === formik?.values?.warehouse_to?.id
      ? {
          label: transaction?.storageAreaTo?.name,
          value: transaction?.storageAreaTo?.id,
        }
      : { label: '', value: '' }

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      formik.setFieldValue('storagearea_to', null)
    }
  }, [formik?.values?.warehouse_to])

  useEffect(() => {
    isInitialRender.current = true
  }, [location])

  return (
    <>
      <div className="grid w-2/3 grid-cols-2 gap-2 gap-x-4 ">
        <Field
          label={t('inventory_.purchase_order_number')}
          name="purchase_order_nu"
          type="select"
          required={true}
          options={purchaseOrders}
          defaultValue={purchaseOrders?.find(
            (option) => option?._id === formik?.values?.purchase_order_nu,
          )}
          placeholder={t('inventory_.purchase_order_number_placeholder')}
          formik={formik}
          disabled={!selectedProduct}
        />
        <Field
          label={t('inventory_.warehouse')}
          name="warehouse_to"
          type="select"
          required={true}
          placeholder={t('inventory_.warehouse_placeholder')}
          formik={formik}
          defaultValue={
            warehouseTo.value == formik?.values.warehouse_to?.id
              ? warehouseTo
              : formik?.values.warehouse_to === null
                ? ''
                : formik?.values.warehouse_to?.name
          }
          options={warehouseToOptions}
          disabled={!selectedProduct}
        />

        <Field
          disabled={!formik.values.warehouse_to}
          label={t('inventory_.storage_area')}
          name="storagearea_to"
          type="select"
          required={true}
          placeholder={t('inventory_.storage_area_placeholder')}
          formik={formik}
          defaultValue={
            storageAreaTo.value == formik?.values.storagearea_to?.id
              ? storageAreaTo
              : formik?.values.storagearea_to?.id === null
                ? ''
                : formik?.values.storagearea_to?.name
          }
          description={t('inventory_.storage_area_desc')}
          options={storageAreaToOptions}
          suffix={selectedProduct?.unit_of_measure}
        />
        <Field
          label={t('inventory_.quantity')}
          name="quantity"
          suffix={unit}
          type="number"
          required={true}
          disabled={!formik?.values.storagearea_to?.id}
          placeholder={t('inventory_.quantity_placeholder')}
          formik={formik}
        />
      </div>
    </>
  )
}

export default ProcurementForm
