import Button from 'libs/button/Button'
import { useDeleteUserMutation, useUserInfoQuery } from '../user-endpoints/user.endpoint'
import { toast } from 'libs/toast'
import { User } from '../user-endpoints/user'
interface Props {
  closePopUp: any
  data: User | null
}
const DeleteUserPopup = ({ closePopUp, data }: Props) => {
  const [deleteUser, { isLoading }] = useDeleteUserMutation()
  const { data: userInfo } = useUserInfoQuery({})

  const handleDelete = () => {
    deleteUser({ org_id: userInfo?.organization?._id, userId: data?._id })
      .unwrap()
      .then(() => { 
        closePopUp()
        toast.success(<><p>User "<b>{data?.username}</b>" removed successfully.</p></>)
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }
  return (
    <>
      <p className="p-xs-regular">
        You are about to delete a <span className="break-words p-xs-bold">"{data?.username}". </span>
        Are you sure you want to proceed?
      </p>
      <div className=" mt-1 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={handleDelete} loading={isLoading}>
          Delete
        </Button>
      </div>
    </>
  )
}

export default DeleteUserPopup
