import React from "react";

interface CopyrightIconProps {
  width?: number;
  height?: number;
  classProp?: string;
}

const CopyrightIcon: React.FC<CopyrightIconProps> = ({
  width = 12,
  height = 12,
  classProp = "",
}) => {
  return (
    <svg
      className={classProp}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_1740_16718)">
        <path
          d="M5.928 4.284C6.696 4.32 6.894 4.974 6.906 5.28H7.98C7.932 4.092 7.086 3.366 5.91 3.366C4.584 3.366 3.6 4.2 3.6 6.084C3.6 7.248 4.158 8.628 5.904 8.628C7.236 8.628 7.95 7.638 7.968 6.858H6.894C6.876 7.212 6.624 7.686 5.916 7.722C5.13 7.698 4.8 7.086 4.8 6.084C4.8 4.35 5.568 4.296 5.928 4.284ZM6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6 10.8C3.354 10.8 1.2 8.646 1.2 6C1.2 3.354 3.354 1.2 6 1.2C8.646 1.2 10.8 3.354 10.8 6C10.8 8.646 8.646 10.8 6 10.8Z"
          fill="#212121"
        />
      </g>
      <defs>
        <clipPath id="clip0_1740_16718">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyrightIcon;
