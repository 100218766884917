import Button from 'libs/button/Button'
import { useFormik } from 'formik'
import Field from 'libs/field'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useRejectMaintenanceRequestsMutation } from '../maintenance-request/maintenance-request'
import { toast } from 'libs/toast'



interface Props {
  closePopUp: any
  batchData: string[]
  setBatchData: (data: string[]) => void
  isRejectPopup: { state: boolean; ids: string[] }
}

function RejectMaintenanceRequestPopup({ closePopUp, batchData,isRejectPopup,setBatchData }: Props) {
  const plant = useSelector(selectSelectedItem)
  const ids = batchData?.length > 0 ? batchData : isRejectPopup.ids
  const [rejectTimesheet, { isLoading }] = useRejectMaintenanceRequestsMutation()
  const formik = useFormik({
    initialValues: {
      remark: '',
    },
    validationSchema: Yup.object({
      remark: Yup.string().required('Remark is required'),
    }),

    onSubmit: (values) => {
      rejectTimesheet({ plant_id: plant?.id, data: { data: ids }, remark: values?.remark })
        .unwrap()
        .then(() => {
          toast.success(`The Timesheet “${batchData?.length}” is rejected successfully.`)
          closePopUp()
          setBatchData([])
          formik?.resetForm()
        })
        .catch((error: any) => {
          toast.error(error?.data?.detail ?? `Failed to reject timesheet. Please try again.`)
        })
    },
  })

  return (
    <div>
      <p className="p-xs-regular">{`You are about to reject ${batchData?.length} Maintenance Requests. Are you sure you want to proceed?`}</p>

      <div>
        <div>
          <Field
            type="textarea"
            required={true}
            label={'Remark'}
            formik={formik}
            name="remark"
            placeholder={'Enter remarks on rejecting requests'}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button
          onClick={() => {
            closePopUp()
            formik.resetForm()
          }}
        >
          Close
        </Button>
        <Button
        loading={isLoading}
          onClick={() => {
            formik.handleSubmit()
          }}
        //   loading={isLoading}
          color="error"
        >
          {' '}
          Reject
        </Button>
      </div>
    </div>
  )
}

export default RejectMaintenanceRequestPopup
