import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useGetAllProcessesQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { useGetWorkCenterByIdQuery } from 'pages/work-center/work-center-endpoints/workcenter.endpoint'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import * as Yup from 'yup'

const defaultFilters = {
  page_no: 1,
  page_size: 1000,
  searchText: '',
}

const BasicDetails = ({ formik, setPage }: any) => {
  const { id } = useParams()
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const { data: allProcessData } = useGetAllProcessesQuery(
    {
      plantId: plantId.id,
      filters: defaultFilters,
    },
    {
      skip: !plantId.id,
    },
  )

  const { data: workCenterData, isLoading: isWorkCenterDataLoading } = useGetWorkCenterByIdQuery(
    {
      wcID: id,
      plantId: plantId?.id,
    },
    {
      skip: !id || !plantId?.id,
    },
  )

  const processOptions = allProcessData?.data?.map((item: any) => {
    return {
      label: item.name,
      value: item._id,
    }
  })

  const basicDetailsFormik = useFormik<any>({
    initialValues: {
      workCenterID: '',
      name: '',
      operations: [],
      description: '',
    },
    validationSchema: Yup.object().shape({
      workCenterID: Yup.string().required('Work Center ID is required'),
      name: Yup.string().required('Work Center Name is required'),
      operations: Yup.array().min(1, 'At least one operation is required'),
    }),
    onSubmit: (values) => {
      formik.setFieldValue('workCenterID', values?.workCenterID)
      formik.setFieldValue('name', values?.name)
      formik.setFieldValue('operations', values?.operations)
      formik.setFieldValue('description', values?.description)
      if (id) {
        navigate(`/settings/configure/work-centers/${id}/edit/machine-details`)
      } else {
        navigate('/settings/configure/work-centers/add/machine-details')
      }
    },
  })

  useEffect(() => {
    if (workCenterData) {
      basicDetailsFormik?.resetForm({
        values: {
          workCenterID: workCenterData?.workCenterID,
          name: workCenterData?.name,
          description: workCenterData?.description,
          operations: workCenterData?.operations?.map((item: any) => item?._id),
        },
      })
    }
  }, [workCenterData])

  useEffect(() => {
    if (formik?.values) {
      basicDetailsFormik?.setValues({
        workCenterID: formik.values.workCenterID,
        name: formik.values.name,
        description: formik.values.description,
        operations: formik.values.operations,
      });
    }
  }, [formik?.values]);

  useEffect(() => {
    setPage(1)
  }, [])
  return (
    <>
      {isWorkCenterDataLoading ? (
        <div className="relative mx-auto flex items-center">
          <FullPageLoader />
        </div>
      ) : (
        <div className="flex min-h-[70vh] w-full flex-col px-2">
          <div className="flex-grow">
            <div className="h5-1">Basic Details</div>
            <p className="text-primary-gray-4 text-sm">
              {id
                ? 'Edit the existing basic details to make necessary changes and updates.'
                : 'Add the basic details that are required to add new Work Center.'}
            </p>
            <div className="mt-4 flex w-8/12 justify-between gap-2">
              <div className="w-full">
                <Field
                  required={true}
                  label="Work Center ID"
                  formik={basicDetailsFormik}
                  name="workCenterID"
                  placeholder="Enter work center ID"
                />
                <Field
                  type="multiselect"
                  label="Operations"
                  required={true}
                  formik={basicDetailsFormik}
                  options={processOptions}
                  name="operations"
                  placeholder="Select Operations"
                />
              </div>
              <div className="w-full">
                <Field
                  type="text"
                  label="Work Center name"
                  formik={basicDetailsFormik}
                  required={true}
                  name="name"
                  placeholder="Enter work center name"
                />
              </div>
            </div>
            <div className="my-5 w-8/12 bg-border p-[0.5px]" />
            <div className="flex w-4/12">
              <Field
                type="textarea"
                label="Description"
                formik={basicDetailsFormik}
                name="description"
                placeholder="Enter description"
              />
            </div>
          </div>
          <div className="mt-auto flex justify-end gap-2">
            <div className="flex gap-3">
              <Button type="button" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                color="success"
                type="submit"
                onClick={() => {
                  basicDetailsFormik.handleSubmit()
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BasicDetails
