import { memo, useCallback, useEffect, useState } from 'react'
import Field from 'libs/field'
import NewEditableTable from 'libs/table/NewEditableTable'
import { getPages } from 'src/routes/paths'
import { useGetByRoleIdQuery } from '../roles-endpoints/roles.endpoint'
import { useParams } from 'react-router-dom'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useUserInfoQuery } from '../../users/user-endpoints/user.endpoint'
import BlackTick from 'assets/icons/user-management/BlackTick'

const AccessRights = ({ formik }: any) => {
  // used to set active router module
  const [activeModuleId, setActiveModuleId] = useState<string | null>(null)

  const [fieldName, setFieldName] = useState('')

  const [data, setData] = useState([])

  const { data: userInfo } = useUserInfoQuery({})

  const [filterData, setFilterData] = useState<any>([])

  const paramId = useParams()

  const getAccessRights = (module: string) => {
    const moduleData = formik?.values?.modules[module]

    // Check if moduleData is an array
    if (Array.isArray(moduleData)) {
      const accessRights = moduleData?.map((item) => item?.v === true || item?.e == true)?.filter(Boolean)?.length
      return accessRights
    } else {
      console.error(`Expected an array for module: ${module}, but received:`, moduleData)
      return 0 // Return an empty array or handle the case differently
    }
  }

  const modules = [
    { name: 'Dashboard', id: 'das', accessRights: getAccessRights('das'), subModules: '3' },
    { name: 'Inventory', id: 'inv', accessRights: getAccessRights('inv'), subModules: '1' },
    { name: 'Planning', id: 'pla', accessRights: getAccessRights('pla'), subModules: '3' },
    { name: 'Production', id: 'pro', accessRights: getAccessRights('pro'), subModules: '1' },
    { name: 'Review', id: 'rev', accessRights: getAccessRights('rev'), subModules: '1' },
    { name: 'Order', id: 'ord', accessRights: getAccessRights('ord'), subModules: '2' },
    { name: 'Employee', id: 'emp', accessRights: getAccessRights('emp'), subModules: '2' },
    { name: 'Contacts', id: 'con', accessRights: getAccessRights('con'), subModules: '2' },
    { name: 'Timesheet', id: 'tim', accessRights: getAccessRights('tim'), subModules: '1' },
  ]

  const { data: roleData, isLoading: isRoleLoading } = useGetByRoleIdQuery(
    {
      roleId: paramId?.id,
      org_id: userInfo?.organization?._id,
    },
    {
      skip: !paramId?.id || !userInfo?.organization?._id,
    },
  )

  // module and submodules from routes
  const dataRoute = getPages()

  const labelsAndIds = dataRoute?.routes
    ?.map((route: any) =>
      // Extract and flatten objects from `children` array
      route.children
        ?.map((child: any) => {
          // Extract objects from `child`'s `children` array
          const childItems = (child.children || [])
            .filter((subChild: any) => subChild?.label && subChild?.id && subChild?.key)
            .map((subChild: any) => ({ label: subChild.label, id: subChild.id, key: subChild.key }))

          // Add the direct `child`'s `label` and `id` if available
          if (child.label && child.id && child.key) {
            childItems.push({ label: child.label, id: child.id, key: child.key })
          }

          return childItems
        })
        // Flatten the results
        .flat(),
    )
    .flat()
    .filter((item: any) => item?.label && item.id && item.key) // Ensure no undefined items

  function groupByKey(data: any) {
    const keyMap = new Map()
    data.forEach((item: any) => {
      const { key } = item
      if (!keyMap.has(key)) {
        keyMap.set(key, [])
      }
      keyMap.get(key).push(item)
    })
    const groupedArray = Array.from(keyMap.values())
    return groupedArray
  }
  const accessRights = groupByKey(labelsAndIds)

  const handleRoleDataLoad = () => {
    if (roleData) {
      const modules = Object.keys(roleData.modules).reduce((acc: any, moduleKey: any) => {
        const moduleData = roleData.modules[moduleKey]

        if (moduleData && typeof moduleData === 'object') {
          acc[moduleKey] = Object.keys(moduleData).map((key) => ({
            label:
              accessRights
                .flat() // Flatten the array of arrays into a single array
                .find((obj) => obj?.id === key)?.label || null, // Find the object with the matching id
            [moduleData[key]]: true,
          }))
        } else {
          acc[moduleKey] = []
        }

        return acc
      }, [])

      const res: any = {}

      // Iterate over the keys in the data object
      Object.keys(modules).forEach((key) => {
        // Clone the existing items from data to the result
        res[key] = [...modules[key]]

        // Find the labels in data that are marked as true
        const existingLabels = new Set(modules[key].map((item: any) => item.label))

        // Find matching items in filterData that are not in the existingLabels
        const matchingItems = accessRights
          .flat()
          .filter((item) => item.key === key.slice(0, 2) && !existingLabels.has(item.label) && item.id.length < 3)

        // Add matching items from filterData to the result
        res[key].push(...matchingItems)
      })
      // Only update state if d
      setData(res)

      formik.setValues({
        name: roleData.name || '',
        plants: roleData?.plants?.map((item: any) => item?._id) ?? [],
        modules: {
          ...formik?.values.modules,
          ...res,
        },
      })
    }
  }

  // Set formik values when roleData is available
  useEffect(() => {
    if (roleData) {
      handleModuleClick(modules[0].id)
      handleRoleDataLoad()
    } else if (!paramId?.id) handleModuleClick(modules[0].id)
  }, [roleData])

  const handleModuleClick = (id: string) => {
    setActiveModuleId(id)
    const matchedAccessRights = accessRights?.find((group) => group.length > 0 && group[0].id === id)
    setFieldName(matchedAccessRights[0]?.label)

    if (matchedAccessRights) {
      const filteredData = matchedAccessRights.slice(1) // Remove the first element
      const formikValues = formik.values.modules[id.toLowerCase()]

      setFilterData(filteredData)

      // if formik data is there then set the data to formik data
      setData(Object.keys(formikValues).length !== 0 ? formikValues : filteredData)
    } else {
      setData([]) // If no match found, clear the data
    }
  }

  const handleTableChange = useCallback(
    (values: any) => {
      if (JSON.stringify(values) !== JSON.stringify(data)) {
        setData(values)
      }
    },
    [data],
  )
  // on dropdown change set the formik
  const handleSelectChange = (selectedValue: string) => {
    // Update formik values for the current module based on the selected access
    const updatedData = filterData.map((item: any) => ({
      ...item,
      [selectedValue]: true,
      // Reset other fields to false
      view: selectedValue === 'v',
      edit: selectedValue === 'e',
      none: selectedValue === 'n',
    }))

    setData(updatedData)

    formik.setFieldValue(`modules.${activeModuleId?.toLowerCase()}`, updatedData)
  }

  // Set the formik values for the current module based on the selected access
  useEffect(() => {
    if (activeModuleId) {
      // Check if the data to be set is different from the current Formik value to prevent unnecessary updates

      if (formik.values.modules[activeModuleId?.toLowerCase()] !== data) {
        // setData(res)
        formik.setFieldValue(`modules.${activeModuleId?.toLowerCase()}`, data)
      }
    }
  }, [data, activeModuleId])

  const options = [
    { label: 'None', value: 'n' },
    { label: 'Modify', value: 'e' },
    { label: 'View', value: 'v' },
  ]

  const accessRightsColumns = [
    {
      title: 'Sub Modules',
      accessor: 'label',
      fieldType: 'text',
      flex: 2,
      require: true,
      editable: true,
      placeholder: 'Enter address',
    },
    {
      title: 'View',
      accessor: 'v',
      fieldType: 'radio',
      editable: true,
      require: true,
      align: 'center' as 'center',
    },
    {
      title: 'Modify',
      accessor: 'e',
      fieldType: 'radio',
      align: 'center' as 'center',
      editable: true,
      require: true,
    },
    {
      title: 'None',
      accessor: 'n',
      fieldType: 'radio',
      align: 'center' as 'center',
      editable: true,
      require: true,
    },
  ]

  return isRoleLoading ? (
    <FullPageLoader />
  ) : (
    <>
      <div className="px-2 sm:w-full  ">
        <h5 className="h5">Access Rights</h5>
        <div className="mt-4 grid grid-cols-4">
          <div className="col-span-1 border-r-2 border-border p-2">
            {modules.map((module) => (
              <div
                key={module.id}
                className={`flex cursor-pointer justify-between p-2 ${activeModuleId === module.id ? 'bg-[#E7F5FF]' : 'bg-white'} rounded-md`}
                onClick={() => handleModuleClick(module.id)}
              >
                <h1 className="text-md ">{module.name}</h1>
                {activeModuleId === module.id ? (
                  <div className='flex justify-between items-center'>
                    <BlackTick />
                  </div>
                ) : (
                  <div className="w-12 rounded-xl bg-brand-primary-200 text-center">
                    {module?.accessRights}/{module?.subModules}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="col-span-3 ml-6 text-lg">
            <div className="flex items-center justify-between border-b-2 border-border p-2">
              <div>
                <h1 className="font-bold text-brand-primary-shade-3">{fieldName} Access</h1>
                <h1 className="text-gray-800">({data?.length} Sub-Modules)</h1>
              </div>
              <div className="w-40">
                <Field
                  type="select"
                  options={options}
                  formik={formik}
                  hideSearch
                  name="planningAccess"
                  placeholder="Select"
                  onChange={(e: any) => handleSelectChange(e)}
                />
              </div>
            </div>
            <div className="mt-6">
              <NewEditableTable
                defaultRows={activeModuleId ? formik?.values?.modules?.[activeModuleId.toLowerCase()] || [] : []}
                columns={accessRightsColumns}
                hideActions
                hideAddButton
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(AccessRights)
