
function DeleteProperty({ toggleDelete, deleteData, onPropertyChange }: any) {
  return (
    <div>
      <div>
        <p className="p-xs-regular">
          Are you sure you want to delete? Once removed, this entry{' '}
          <span className="p-xs-bold">cannot be recovered</span> . However you can create new entries for making the
          adjustments.
        </p>
        <div className="mt-4 flex justify-end">
          <button className="mr-2 rounded bg-red-500 px-4 py-2 text-xs text-white btn-cancel" onClick={toggleDelete}>
            Close
          </button>
          <button
            className="rounded bg-gray-400 px-4  py-2 text-xs text-white btn-delete"
            onClick={() => {
              deleteData()
              onPropertyChange(true)
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteProperty
