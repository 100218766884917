import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import * as Yup from 'yup'
import { usePostProductMutation } from 'pages/products/products-endpoints/products.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { toast } from 'libs/toast'

type props = {
  close: () => void
}
  const categoryOptions = [
    {
      label: 'Raw Materials',
      value: 'Raw Materials',
    },
    {
      label: 'Finished Goods',
      value: 'Finished Goods',
    },
  ]
const QuickAddProduct = ({ close }: props) => {
  const plantId = useSelector(selectSelectedItem)
  const [addProduct, { isLoading: isAddProductLoading }] = usePostProductMutation()
  const formik = useFormik({
    initialValues: {
      category: '',
      code: '',
      name: '',
      unit_of_measure: '',
    },
    validationSchema: Yup.object({
      category: Yup.string().required('Category is required'),
      code: Yup.string().required('Code is required'),
      name: Yup.string().required('Name is required'),
      unit_of_measure: Yup.string().required('Unit of Measure is required'),
    }),
    onSubmit: (values: any) => {
      const payload = {
        category: values?.category,
        code: values?.code,
        name: values?.name,
        unit_of_measure: values?.unit_of_measure,
      }
      addProduct({ plantId: plantId?.id, data: payload })
        .unwrap()
        .then((data) => {
          toast.success(`New product ${data.name} has been created successfully.`)
          close()
        })
    },
  })


  return (
    <>
      <div className="flex flex-col gap-2">
        <Field
          type="text"
          label="Product Name"
          formik={formik}
          required={true}
          name="name"
          placeholder="Enter product name (eg., 'Soya Beans')"
        />
        <Field
          type="text"
          label="Product Code"
          formik={formik}
          required={true}
          name="code"
          placeholder="Enter product code"
        />

        <Field
          type="select"
          label="Product Category"
          formik={formik}
          required={true}
          name="category"
          options={categoryOptions}
          placeholder="Enter product category"
        />
        <Field
          type="text"
          required={true}
          label="Unit of Measure"
          formik={formik}
          name="unit_of_measure"
          placeholder="Enter unit of measure"
        />
        <div className="my-2 flex justify-end gap-2">
          <Button onClick={close}>{t('cancel')}</Button>
          <Button
            color="primary"
            loading={isAddProductLoading}
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </>
  )
}

export default QuickAddProduct
