import { useDeleteProductionPlanMutation } from '../production-plans-endpoints/productionPlan.endpoints'
import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  pID: any
  plant_id: string
}

function DeletePlan({ closePopUp, pID, plant_id }: Props) {
  const [deletePlan, { isLoading: deleteSalesOrderLoading }] = useDeleteProductionPlanMutation()
  const navigate = useNavigate()

  const handleDeletePlan = () => {
    deletePlan({ plant_id, pID: pID?._id })
      .unwrap()
      .then(() => {
        toast.success(`Plan “${pID?.planID}” is removed successfully.`)
        closePopUp()
        navigate('/planning/production-plans')
      })
      .catch((error: any) => {
        toast.error(error?.data?.detail ?? `Failed to remove Sales Order “${pID?.planID}”. Please try again.`)
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')} <span className="break-words pr-1 p-xs-bold">“{pID?.planID} Plan”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDeletePlan} loading={deleteSalesOrderLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeletePlan
