import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'

import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import Chip from 'libs/chip/Chip'
import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatString } from 'utils/stringHandlers'
import { hasAccess } from 'utils/utils'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { Address } from 'pages/contacts/commonTypes'

import { useGetCustomerByIDQuery } from '../customer-endpoints/customer.endpoints'

const columns: ExtendedColumn<Address>[] = [
  {
    Header: 'Address Type',
    accessor: 'addressType',
    Cell: ({ row }: CellProps<Address>) => (
      <div className="flex items-center">
        {formatString(row.original?.addressType)}
        {row.original.isPrimary ? (
          <div className="ml-2">
            <Chip title="PRIMARY" />{' '}
          </div>
        ) : null}
      </div>
    ),
  },
  {
    Header: 'Address line 1',
    accessor: 'addressLine1',
    Cell: ({ row }: CellProps<Address>) => (
      <>{row.original?.addressLine1 ?? '-'}</>
    ),
  },
  {
    Header: 'Address line 2',
    accessor: 'addressLine2',
    Cell: ({ row }: CellProps<Address>) => {
      return <span>{row.original.addressLine2 ?? '-'}</span>
    },
  },
  {
    Header: 'City',
    accessor: 'city',
    Cell: ({ row }: CellProps<Address>) => {
      return <span>{row.original.city ?? '-'}</span>
    },
  },
  {
    Header: 'State',
    accessor: 'state',
    Cell: ({ row }: CellProps<Address>) => {
      return <span>{row.original.state ?? '-'}</span>
    },
  },
  {
    Header: 'Zip Code',
    accessor: 'zipCode',
    Cell: ({ row }: CellProps<Address>) => {
      return <span>{row.original.zipCode ?? '-'}</span>
    },
  },
  {
    Header: 'Country',
    accessor: 'country',
    Cell: ({ row }: CellProps<Address>) => {
      return <span>{row.original.country ?? '-'}</span>
    },
  },
]

const ViewAddressDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const customerAccess = hasAccess('con', { cus: 'e' })

  const { data: customer, isLoading: loading } = useGetCustomerByIDQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  return (
    <MotionDiv>
      {loading ? (
        <FullPageLoader />
      ) : (
        <>
          <WhiteCard className="flex min-h-[70vh] flex-col gap-6">
            <div className="flex w-full justify-between">
              <h5 className="h5">Address Details</h5>
              {customerAccess && (
                <div>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon />}
                    onClick={() => {
                      id &&
                        navigate(
                          `/contacts/customer-management/edit/${id}/address-details`,
                        )
                    }}
                  >
                    Edit Address Details
                  </Button>
                </div>
              )}
            </div>
            <PaginatedTable
              columns={columns}
              rows={customer?.addresses}
              hidePagination={true}
              emptyMessage={
                'No address details have been added. Please add address details.'
              }
            />
          </WhiteCard>
        </>
      )}
    </MotionDiv>
  )
}

export default ViewAddressDetails
