import React from 'react'
import Search from 'assets/icons/top-navigation/Search'
import icons from 'assets/index.ts'

/**
 * A search box component with an icon and clear functionality.
 *
 * @param {SearchBoxProps} props - The props for the component.
 * @param {string} props.value - The current value of the search input.
 * @param {(e: React.ChangeEvent<HTMLInputElement>) => void} props.onChange - The function to call when the input value changes.
 * @param {string} props.placeholder - The placeholder text for the search input.
 * @param {() => void} props.onClearSearchBox - The function to call when the clear button is clicked.
 * @returns {JSX.Element} The rendered search box component.
 */
const SearchBox: React.FC<SearchBoxProps> = ({
  value,
  onChange,
  placeholder,
  onClearSearchBox,
}: SearchBoxProps): JSX.Element => {
  return (
    <div className="relative flex w-full max-w-[420px] items-center">
      <input
        className="h-full w-full rounded border border-gray-300 bg-white px-2 pl-7 outline-none p-xs-regular placeholder:text-gray-500 focus:border-[#1193F0]"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div className="absolute left-2 flex h-full items-center">
        <Search />
      </div>
      {value !== '' && (
        <div className="absolute right-1 flex h-full cursor-pointer items-center" onClick={onClearSearchBox}>
          <img src={icons.CloseIcon} alt="Clear search" />
        </div>
      )}
    </div>
  )
}

export default SearchBox
interface SearchBoxProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClearSearchBox: () => void
  placeholder: string
}
