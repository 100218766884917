import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useDeleteVendorMutation } from '../vendor-endpoints/vendor.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { Vendor } from '../vendor-endpoints/vendorTypes'

interface Props {
  closePopUp: () => void
  vendor: Vendor
}

function DeleteVendor({ closePopUp, vendor }: Props) {
  const [deletevendor, { isLoading: deleteVendorMutation }] =
    useDeleteVendorMutation()

  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)

  const handleDeleteVendor = () => {
    deletevendor({ plant_id: plant?.id, id: vendor?.id })
      .unwrap()
      .then((res) => {
        toast.success(res)
        closePopUp()
        navigate('/contacts/vendor-management')
      })
      .catch((error) => {
        toast.error(
          error?.data?.detail ??
            `Failed to remove Vendor “${vendor?.name}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}
        <span className="break-words p-xs-bold pr-1">
          “Vendor {vendor?.name}”.
        </span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className="flex gap-3 justify-end mt-4">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button
          color="error"
          onClick={handleDeleteVendor}
          loading={deleteVendorMutation}
        >
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteVendor
