import React from "react";

interface HidePasswordIconProps {
  width?: number;
  height?: number;
  color?: string;
  classProp?: string;
}

const HidePasswordIcon: React.FC<HidePasswordIconProps> = ({
  width = 20,
  height = 12,
  color = "#75838D",
  classProp = "",
}) => {
  return (
    <svg
      className={classProp}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 12"
      fill="none"
    >
      <path
        d="M1 7C4.6 -1 15.4 -1 19 7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11C9.60603 11 9.21593 10.9224 8.85195 10.7716C8.48797 10.6209 8.15726 10.3999 7.87868 10.1213C7.6001 9.84274 7.37913 9.51203 7.22836 9.14805C7.0776 8.78407 7 8.39397 7 8C7 7.60603 7.0776 7.21593 7.22836 6.85195C7.37913 6.48797 7.6001 6.15726 7.87868 5.87868C8.15726 5.6001 8.48797 5.37913 8.85195 5.22836C9.21593 5.0776 9.60603 5 10 5C10.7956 5 11.5587 5.31607 12.1213 5.87868C12.6839 6.44129 13 7.20435 13 8C13 8.79565 12.6839 9.55871 12.1213 10.1213C11.5587 10.6839 10.7956 11 10 11Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HidePasswordIcon;
