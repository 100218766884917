import { createSlice } from '@reduxjs/toolkit'

const productionPlanning = createSlice({
  name: 'plan',
  initialState: {
    plan: '',
  },
  reducers: {
    setPlan: (state, action) => {
      state.plan = action.payload
    },
  },
})

export const { setPlan } = productionPlanning.actions

export const selectPlan = (state: any) => state?.plan?.plan

export default productionPlanning.reducer
