import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  DeleteMachineRequest,
  DeleteMachineResponse,
  GetMachineRequest,
  GetMachineResponse,
  Machine as machineRequest,
  MachineType,
} from './machineTypes'

export const machine = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getMachine: builder.query<GetMachineResponse, GetMachineRequest>({
      query: (params) => {
        const baseURL = `/plant/${params.plantId}/machines`
        const URL = params.filters ? buildUrl(baseURL, params.filters) : baseURL
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the machines')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMachine'],
    }),
    getMachineByID: builder.query<MachineType, GetMachineRequest>({
      query: ({ plantId, machineID }) => {
        return {
          url: `/plant/${plantId}/machine/${machineID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the machine by ID')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMachineByID'],
    }),
    deleteMachine: builder.mutation<
      DeleteMachineResponse,
      DeleteMachineRequest
    >({
      query: (params) => {
        return {
          url: `/plant/${params.plantId}/machine/${params.machineID}`,
          method: 'DELETE',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to delete the machine')
            }
            return response.json()
          },
        }
      },
      invalidatesTags: ['getMachine'],
    }),
    getMachinesCount: builder.query({
      query: ({ plantId }) => {
        return {
          url: `/plant/${plantId}/machines-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the machines count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMachine'],
    }),
    createMachine: builder.mutation<
      string,
      { plantId: string; body: machineRequest }
    >({
      query: ({ plantId, body }) => {
        return {
          url: `/plant/${plantId}/machine`,
          method: 'POST',
          headers: getToken(),
          body,
        }
      },
      invalidatesTags: ['getMachine'],
    }),
    updateMachine: builder.mutation<
      string,
      { machineID: string; plantId: string; body: machineRequest }
    >({
      query: ({ machineID, plantId, body }) => {
        return {
          url: `/plant/${plantId}/machine/${machineID}`,
          method: 'PUT',
          headers: getToken(),
          body,
        }
      },
      invalidatesTags: ['getMachine', 'getMachineByID'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetMachineQuery,
  useCreateMachineMutation,
  useDeleteMachineMutation,
  useGetMachineByIDQuery,
  useUpdateMachineMutation,
  useGetMachinesCountQuery,
} = machine
