import Chip from 'libs/chip/Chip'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMaintenanceOrderByIdQuery } from '../maintenance-order-endpoints/order.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatDateRange, getRemainingDays } from 'utils/dateTimeHandlers'

const ImpactedProductionPlans = () => {
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const params = useParams()
  const { data: maintenanceOrder } = useGetMaintenanceOrderByIdQuery(
    {
      plantId: plantId?.id,
      moID: params?.id,
    },
    {
      skip: !params?.id || !plantId?.id,
    },
  )

  const column = [
    {
      Header: 'Plan ID',
      accessor: 'planID',
      width: 80,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/planning/production-plans/view/${data?._id}`)
            }}
          >
            {data?.planID}
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">
          Manufacturing Order
        </div>
      ),
      accessor: 'mo',
      width: 150,
      Cell: ({ row }: any) => {
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/planning/mo`)
            }}
          >
            {row.original?.manufacture_orders?.length} MOs
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">
          Finished Goods
        </div>
      ),
      accessor: 'Finished_Goods',
      width: 150,
      Cell: ({ row }: any) => {
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/settings/configure/products`)
            }}
          >
            {row.original?.manufacture_orders?.length} Finished Goods
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Status</div>
      ),
      accessor: 'status',
      width: 80,
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
    {
      Header: 'Duration',
      accessor: 'scheduledDate',
      Cell: ({ row }: any) => {
        return (
          <>
            {row?.original?.scheduledDate ? (
              <div>
                <p className="p-xs-regular">
                  {formatDateRange(
                    row?.original?.scheduledDate[0],
                    row?.original?.scheduledDate[1],
                  )}
                </p>
                <p className="p-xs-regular-dim">
                  {getRemainingDays(
                    row?.original?.scheduledDate[0],
                    row?.original?.scheduledDate[1],
                  )}{' '}
                  days
                </p>
              </div>
            ) : (
              <p className="ml-6">-</p>
            )}
          </>
        )
      },
    },
  ]
  return (
    <>
      <h1 className="mb-4 text-xl font-medium text-brand-primary-shade-3">
        Impacted Production Plans
      </h1>
      <PaginatedTable
        columns={column}
        rows={maintenanceOrder?.productionPlans}
        hidePagination={true}
        emptyMessage={'No plan are added. Please add plan.'}
      />
    </>
  )
}

export default ImpactedProductionPlans
