import Field from 'libs/field'
import { t } from 'i18next'

import { useEffect, useRef } from 'react'
import InlineLoader from 'libs/loader/customloader/Loader'
import { formatNumber } from 'utils/numberHandlers'

const AdjustmentForm = ({
  selectedProduct,
  formik,
  unit,
  transaction,
  quantity,
  storageAreaFromOptions,
  warehouseFromOptions,
}: any) => {
  const isInitialRenderFrom = useRef(true)

  const warehouseFrom =
    transaction?.warehouseFrom?.id === formik?.values?.warehouse_from?.id
      ? {
          label: transaction?.warehouseFrom?.name,
          value: transaction?.warehouseFrom?.id,
        }
      : { label: '', value: '' }

  const storageAreaFrom =
    transaction?.storageAreaFrom?.id ===
    formik?.values?.storagearea_from?.storage_id
      ? {
          label: transaction?.storageAreaFrom?.name,
          value: transaction?.storageAreaFrom?.id,
        }
      : { label: '', value: '' }

  useEffect(() => {
    if (isInitialRenderFrom.current) {
      isInitialRenderFrom.current = false
    } else {
      formik.setFieldValue('storagearea_from', null)
    }
  }, [formik?.values?.warehouse_from])

  return (
    <div className="grid w-2/3 grid-cols-2 gap-2 gap-x-4">
      <Field
        label={t('inventory_.reason_for_adjustment')}
        name="reason_for_adjustment"
        type="text"
        required={true}
        placeholder={t('inventory_.reason_for_adjustment_placeholder')}
        description={t('inventory_.reason_for_adjustment_desc')}
        formik={formik}
      />
      <Field
        label={t('inventory_.warehouse')}
        name="warehouse_from"
        type="select"
        required={true}
        placeholder={t('inventory_.select')}
        formik={formik}
        defaultValue={
          warehouseFrom.value == formik?.values.warehouse_from?.id
            ? warehouseFrom
            : formik?.values.warehouse_from === null
              ? ''
              : formik?.values.warehouse_from?.name
        }
        options={warehouseFromOptions}
        disabled={!selectedProduct}
      />
      <Field
        label={t('inventory_.storage_area')}
        name="storagearea_from"
        disabled={!formik?.values?.warehouse_from}
        type="select"
        required={true}
        placeholder={t('inventory_.select')}
        formik={formik}
        defaultValue={
          storageAreaFrom.value == formik?.values.storagearea_from?.id
            ? storageAreaFrom
            : formik?.values.storagearea_from === null
              ? ''
              : formik?.values.storagearea_from?.name
        }
        description={t('inventory_.storage_area_desc')}
        options={storageAreaFromOptions}
      />

      <Field
        label={t('inventory_.quantity_adjusted')}
        name="quantity"
        suffix={unit}
        type="number"
        required={true}
        disabled={!formik?.values.storagearea_from?.id}
        placeholder={t('inventory_.quantity_adjusted_placeholder')}
        formik={formik}
      />
      {quantity.isFetching || quantity.isLoading ? (
        <InlineLoader color="primary" />
      ) : (
        quantity.data != null && (
          <span className="flex items-center gap-1 text-base font-bold">
            <p>Available Quantity : </p>
            <p>{formatNumber(quantity.data)}</p>
          </span>
        )
      )}
    </div>
  )
}

export default AdjustmentForm
