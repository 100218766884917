import { getAuthTokenAndRole } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'

export const auth = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (data) => {
        const formData = new URLSearchParams()
        for (const key in data) {
          formData.append(key, data[key])
        }
        return {
          url: '/login/login-via-email',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData.toString(),
        }
      },
    }),
    loginUserViaPhoneNumber: builder.mutation({
      query: ({ phone_number }) => {
        return {
          url: `/login/access-token-via-phone-number?phone_number=${phone_number}`,
          method: 'POST',
        }
      },
    }),
    resetPassword: builder.mutation({
      query: ({ data }) => {
        const formData = new URLSearchParams()
        for (const key in data) {
          formData.append(key, data[key])
        }
        return {
          url: `/login/confirm-password`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData.toString(),
        }
      },
    }),
    updateTempPassword: builder.mutation({
      query: ({ email, new_password, session }) => {
        return {
          url: `/login/set-password?username=${email}&new_password=${new_password}&session=${session}`,
          method: 'POST',
        }
      },
    }),
    sendOTPViaEmail: builder.mutation({
      query: ({ email }) => {
        return {
          url: `/login/forgot-password`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `username=${email}`,
        }
      },
    }),
    sendOTPViaPhoneNumber: builder.mutation({
      query: ({ phone_number }) => {
        return {
          url: `/login/send-verification-code-via-phone-number?phone_number=${phone_number}`,
          method: 'POST',
        }
      },
    }),
    verifyOTPViaEmail: builder.mutation({
      query: ({ email, otp }) => {
        return {
          url: `/login/verify-otp-via-email?email=${email}&otp=${otp}`,
          method: 'POST',
        }
      },
    }),
    verifyOTPViaPhoneNumber: builder.mutation({
      query: ({ phone_number, otp }) => {
        return {
          url: `/login/verify-otp-via-phone-number?phone_number=${phone_number}&otp=${otp}`,
          method: 'POST',
        }
      },
    }),
    getOTPLastUpdatedTime: builder.mutation({
      query: ({ via, contact }) => {
        return {
          url: `/login/otp-updated-time?via=${via}&contact=${contact}`,
          method: 'POST',
        }
      },
    }),
    refreshToken: builder.mutation({
      query: (data) => {
        const formData = new URLSearchParams()
        for (const key in data) {
          formData.append(key, data[key])
        }
        return {
          url: `/login/access-token-by-refresh-token`,
          method: 'POST',
          body: formData.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      },
    }),
    validateAccessToken: builder.query({
      query: ({ refresh_token }) => {
        const { access_token } = getAuthTokenAndRole()
        return {
          url: `/validate-access-token?refresh_token=${refresh_token}`,
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + access_token,
            'Content-Type': 'application/json',
          },
        }
      },
    }),
    validatePhone: builder.mutation({
      query: ({ phone_number, country_code }) => {
        const { access_token } = getAuthTokenAndRole()
        return {
          url: `/phone_validation/verify?phone_number=${phone_number}&country_code=${country_code}`,
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + access_token,
            'Content-Type': 'application/json',
          },
          body: { phone_number, country_code },
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useLoginUserMutation,
  useLoginUserViaPhoneNumberMutation,
  useRefreshTokenMutation,
  useResetPasswordMutation,
  useUpdateTempPasswordMutation,
  useSendOTPViaEmailMutation,
  useSendOTPViaPhoneNumberMutation,
  useVerifyOTPViaEmailMutation,
  useVerifyOTPViaPhoneNumberMutation,
  useGetOTPLastUpdatedTimeMutation,
  useValidatePhoneMutation,
  useLazyValidateAccessTokenQuery,
} = auth
