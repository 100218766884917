function PdfIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <mask
        id="mask0_1573_11884"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_1573_11884)">
        <path
          fill="#75838D"
          d="M7.5 7.875h.75c.213 0 .39-.072.534-.216A.726.726 0 009 7.125v-.75a.726.726 0 00-.216-.534.726.726 0 00-.534-.216H7.125a.36.36 0 00-.263.112A.36.36 0 006.75 6v3c0 .1.037.188.112.262a.36.36 0 00.526 0A.36.36 0 007.5 9V7.875zm0-.75v-.75h.75v.75H7.5zm3.75 2.25c.213 0 .39-.072.534-.216A.726.726 0 0012 8.625v-2.25a.726.726 0 00-.216-.534.726.726 0 00-.534-.216h-1.125a.36.36 0 00-.262.112A.36.36 0 009.75 6v3c0 .1.037.188.113.262a.36.36 0 00.262.113h1.125zm-.75-.75v-2.25h.75v2.25h-.75zm3-.75h.375a.36.36 0 00.262-.112.36.36 0 000-.526.36.36 0 00-.262-.112H13.5v-.75h.375a.36.36 0 00.262-.112.36.36 0 000-.526.36.36 0 00-.262-.112h-.75a.36.36 0 00-.262.112.36.36 0 00-.113.263v3c0 .1.037.188.113.262a.36.36 0 00.524 0A.36.36 0 0013.5 9V7.875zM6 13.5c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 014.5 12V3c0-.413.147-.766.44-1.06.294-.293.647-.44 1.06-.44h9c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v9c0 .412-.147.766-.44 1.06-.294.293-.647.44-1.06.44H6zM6 12h9V3H6v9zm-3 4.5c-.413 0-.766-.147-1.06-.44A1.445 1.445 0 011.5 15V5.25c0-.213.072-.39.216-.534A.726.726 0 012.25 4.5c.212 0 .39.072.534.216A.726.726 0 013 5.25V15h9.75c.213 0 .39.072.534.216a.726.726 0 01.216.534c0 .213-.072.39-.216.534a.726.726 0 01-.534.216H3z"
        ></path>
      </g>
    </svg>
  )
}

export default PdfIcon
