import Field from 'libs/field'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import NewEditableTable from 'libs/table/NewEditableTable'
import { useEffect, useState } from 'react'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import {
  usePostMachineClassMutation,
  useUpdateMachineClassMutation,
  useGetMachineClassByIdQuery,
} from '../machine-class-endpoints/machineClass.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import Divider from 'libs/divider/Divider'
import FullPageLoader from 'libs/loader/FullPageLoader'

const machineClassColumns = [
  {
    title: 'Model Number',
    accessor: 'modelNumber',
    editable: true,
    flex: 3,
    placeholder: 'Enter model number',
  },

  {
    title: 'Manufacturer',
    accessor: 'manufacturer',
    editable: true,
    flex: 3,
    placeholder: 'Enter manufacturer',
  },
  {
    title: 'Model Description',
    accessor: 'description',
    editable: true,
    flex: 3,
    placeholder: 'Enter model description',
  },
]
const AddEditMachineClass = () => {
  const paramsId = useParams()
  const plantId = useSelector(selectSelectedItem)
  const [addMachineClass, { isLoading: addMachineClassLoading }] = usePostMachineClassMutation()
  const [updateMachineClass, { isLoading: updateMachineClassLoading }] = useUpdateMachineClassMutation()
  const { data: machineData, isLoading: isMaintenanceRequestLoading } = useGetMachineClassByIdQuery(
    {
      plantId: plantId?.id,
      machineClassID: paramsId?.id,
    },
    {
      skip: !paramsId?.id || !plantId?.id,
    },
  )

  const [machineModelData, setMachineModelData] = useState([])
  const navigate = useNavigate()
  const machineClassFormik = useFormik({
    initialValues: {
      name: '',
      description: '',
      machineModels: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Machine Class Name is required'),
    }),
    onSubmit: (values) => {
      let modelNumberEmpty = true
      let manufacturerEmpty = true
      let duplicateModelNumber = false // To track duplicates

      if (values?.machineModels?.length === 0) {
        toast.error('Please enter at least one model number')
        return // Exit early if there are no machine models
      }

      // Create a set to track unique model numbers
      const modelNumbersSet = new Set()

      values?.machineModels?.forEach((data: any) => {
        if (data?.modelNumber) {
          // Check for duplicate model numbers
          if (modelNumbersSet.has(data.modelNumber)) {
            duplicateModelNumber = true
          } else {
            modelNumbersSet.add(data.modelNumber)
          }
        }

        // Check for empty modelNumber or manufacturer
        if (data?.modelNumber && !data.manufacturer) {
          manufacturerEmpty = false
        } else if (!data?.modelNumber && data.manufacturer) {
          modelNumberEmpty = false
        }
      })

      if (duplicateModelNumber) {
        toast.error('Duplicate model numbers are not allowed')
        return // Exit early if there are duplicates
      }

      if (modelNumberEmpty && manufacturerEmpty) {
        const payload = {
          name: values.name,
          description: values.description,
          machineModels: values.machineModels,
        }

        if (!paramsId.id) {
          addMachineClass({ plant_id: plantId.id, data: payload })
            .unwrap()
            .then(() => {
              toast.success(
                <div>
                  The Machine Class "<b>{payload.name}</b>" is added successfully.
                </div>,
              )
              navigate('/maintenance/machine-classes')
            })
            .catch((error) => {
              toast.error(
                error?.data?.detail ?? 'Failed to add the Machine Class ' + payload.name + '. Please try again.',
              )
            })
        } else {
          updateMachineClass({ plant_id: plantId.id, machineClassID: paramsId.id, data: payload })
            .unwrap()
            .then(() => {
              toast.success(
                <div>
                  The Machine Class "<b>{payload.name}</b>" is updated successfully.
                </div>,
              )
              navigate(-1)
            })
            .catch((error) => {
              toast.error(
                error?.data?.detail ?? 'Failed to update the Machine Class ' + payload.name + '. Please try again.',
              )
            })
        }
      } else if (duplicateModelNumber) {
        toast.error('Duplicate model numbers are not allowed')
      } else if (!manufacturerEmpty) {
        toast.error('Please enter the manufacturer')
      } else if (!modelNumberEmpty) {
        toast.error('Please enter a model number')
      }
    },
  })

  useEffect(() => {
    if (machineData && paramsId.id) {
      machineClassFormik.resetForm({
        values: {
          name: machineData?.name,
          description: machineData?.description,
          machineModels: machineData?.machineModels,
        },
      })
    }
  }, [machineData, paramsId.id])

  const handelSubmit = () => {
    machineClassFormik?.setFieldValue('machineModels', machineModelData ? machineModelData : [])
    machineClassFormik.handleSubmit()
  }

  return (
    <MotionDiv>
      {!isMaintenanceRequestLoading ? (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <h1 className="h4-bold-black ">{paramsId.id ? 'Edit Machine Class' : 'Add Machine Class'}</h1>
            </div>
          </div>

          <WhiteCard className="mt-2">
            <div>
              <h5 className="h5">Machine Class</h5>
              <p className="text-sm text-gray-600">
                {paramsId.id
                  ? 'Modify details of the selected machine class.'
                  : 'Define and categorize a new machinery class for your plant.'}
              </p>
            </div>
            <div className="flex w-5/12 justify-between gap-4">
              <Field
                type="text"
                required={true}
                label="Machine Class Name"
                formik={machineClassFormik}
                name="name"
                placeholder="Enter machine class name"
              />
            </div>
            <Divider color="gray-100" />
            <div className="flex w-5/12 justify-between gap-4">
              <Field
                type="textarea"
                label="Description"
                formik={machineClassFormik}
                name="description"
                placeholder="Enter description"
              />
            </div>
            <Divider />
            <h1 className="mt-4 text-xl font-medium text-brand-primary-shade-3">
              Machine Models
              <span data-testid="asterisk" className={'text-red'}>
                *
              </span>
            </h1>
            <NewEditableTable
              defaultRows={machineClassFormik?.values?.machineModels}
              columns={machineClassColumns}
              addButtonTitle="Add Machine Model"
              onChange={(values: any) => {
                setMachineModelData(values)
              }}
              tooltipContent="Remove Machine Model"
            />
            <div className={`my-auto mb-0 flex items-center justify-end gap-2 px-6`}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button
                color="success"
                type="submit"
                onClick={handelSubmit}
                loading={addMachineClassLoading || updateMachineClassLoading}
              >
                {paramsId.id ? 'Save' : 'Add'}
              </Button>
            </div>
          </WhiteCard>
        </div>
      ) : (
        <FullPageLoader />
      )}
    </MotionDiv>
  )
}

export default AddEditMachineClass
