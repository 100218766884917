interface LeftArrowIconProps {
  color?: string;
  classProp?: string;
  width?:any;
  height?:any;
}
const LeftArrowIcon: React.FC<LeftArrowIconProps> = ({ color = '#0B5ED7', classProp = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 25 25"
      fill="none"
      className={classProp}
    >
      <path
        d="M5.46875 11.7188H21.0938C21.301 11.7188 21.4997 11.8011 21.6462 11.9476C21.7927 12.0941 21.875 12.2928 21.875 12.5C21.875 12.7072 21.7927 12.9059 21.6462 13.0524C21.4997 13.1989 21.301 13.2812 21.0938 13.2812H5.46875C5.26155 13.2812 5.06284 13.1989 4.91632 13.0524C4.76981 12.9059 4.6875 12.7072 4.6875 12.5C4.6875 12.2928 4.76981 12.0941 4.91632 11.9476C5.06284 11.8011 5.26155 11.7188 5.46875 11.7188Z"
        fill={color}
      />
      <path
        d="M5.79218 12.5L12.2719 18.9781C12.4186 19.1248 12.501 19.3238 12.501 19.5313C12.501 19.7387 12.4186 19.9377 12.2719 20.0844C12.1252 20.2311 11.9262 20.3135 11.7187 20.3135C11.5113 20.3135 11.3123 20.2311 11.1656 20.0844L4.13437 13.0531C4.06161 12.9806 4.00389 12.8944 3.9645 12.7994C3.92512 12.7045 3.90485 12.6028 3.90485 12.5C3.90485 12.3972 3.92512 12.2955 3.9645 12.2006C4.00389 12.1057 4.06161 12.0195 4.13437 11.9469L11.1656 4.91564C11.3123 4.76894 11.5113 4.68652 11.7187 4.68652C11.9262 4.68652 12.1252 4.76894 12.2719 4.91564C12.4186 5.06233 12.501 5.2613 12.501 5.46876C12.501 5.67622 12.4186 5.87519 12.2719 6.02189L5.79218 12.5Z"
        fill={color}
      />
    </svg>
  )
}

export default LeftArrowIcon;
