const TaskDetails = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 17V15H12V17H0ZM13 10C11.6167 10 10.4375 9.5125 9.4625 8.5375C8.4875 7.5625 8 6.38333 8 5C8 3.61667 8.4875 2.4375 9.4625 1.4625C10.4375 0.4875 11.6167 0 13 0C14.3833 0 15.5625 0.4875 16.5375 1.4625C17.5125 2.4375 18 3.61667 18 5C18 6.38333 17.5125 7.5625 16.5375 8.5375C15.5625 9.5125 14.3833 10 13 10ZM0 9V7H6.3C6.41667 7.36667 6.55 7.71667 6.7 8.05C6.85 8.38333 7.03333 8.7 7.25 9H0ZM0 13V11H9.4C9.78333 11.2333 10.1917 11.4292 10.625 11.5875C11.0583 11.7458 11.5167 11.8583 12 11.925V13H0ZM12.5 8H13.5V4H12.5V8ZM13 3C13.1333 3 13.25 2.95 13.35 2.85C13.45 2.75 13.5 2.63333 13.5 2.5C13.5 2.36667 13.45 2.25 13.35 2.15C13.25 2.05 13.1333 2 13 2C12.8667 2 12.75 2.05 12.65 2.15C12.55 2.25 12.5 2.36667 12.5 2.5C12.5 2.63333 12.55 2.75 12.65 2.85C12.75 2.95 12.8667 3 13 3Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}

export default TaskDetails
