import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useNavigate } from 'react-router-dom'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'

import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import Popup from 'libs/popup/Popup'
import { useState } from 'react'
import DeleteTimeSheet from './popup/DeleteTimeSheet'
import TimeSheetFilterPanel from './panel/TimeSheetFilterPanel'
import {
  formatUnixDate,
  formatUnixDateWithTime,
  calculateTimeWithSeconds,
  calculateDuration,
} from 'utils/dateTimeHandlers'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useGetTimesheetQuery } from 'pages/employee/time-sheet/timesheet-endpoints/timesheet.endpoints'
import Chip from 'libs/chip/Chip'
import { hasAccess } from 'utils/utils'

const additionalFilters = {
  date: [],
  shifts: [],
  sortBy: 'created_on',
  sortDir: 'DESC',
}

interface State {
  state: boolean
  ids: string[]
  data: any
}

const OperatorTimeSheetPage = () => {
  useTabTitle('TimeSheets')
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const timesheetEditAccess = hasAccess('tim', { ts: 'e' })
  const [deleteState, setDeleteState] = useState<State>({
    state: false,
    ids: [],
    data: '',
  })

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      width: 150,
      Cell: ({ value }: any) => (
        <div className="p-xs-regular">{formatUnixDate(value)}</div>
      ),
    },
    {
      Header: <div className="flex w-full items-center justify-end">Shift</div>,
      accessor: 'shift.shiftName',
      Cell: ({ value }: any) => {
        return <div className="p-xs-regular">{value || '-'}</div>
      },
    },

    {
      Header: (
        <div className="flex w-full items-center justify-end">Start Time</div>
      ),
      accessor: 'startTime',
      Cell: ({ value }: any) => {
        const startTime = value / 1000
        return (
          <div className="p-xs-regular">
            {calculateTimeWithSeconds(startTime)}
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">End Time</div>
      ),
      accessor: 'endTime',
      Cell: ({ value }: any) => {
        const endTime = value / 1000
        return (
          <div className="p-xs-regular">
            {calculateTimeWithSeconds(endTime)}
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Hours Worked</div>
      ),
      accessor: 'hoursworked',
      Cell: ({ row }: any) => {
        return calculateDuration(
          row?.original?.startTime,
          row?.original?.endTime,
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">
          Job Description
        </div>
      ),
      accessor: 'jobDescription',
      Cell: ({ value }: any) => {
        return <div className="p-xs-regular">{value ? value : '-'}</div>
      },
    },

    {
      Header: (
        <div className="flex w-full items-center justify-end">
          Approval Status
        </div>
      ),
      accessor: 'approvalStatus',
      Cell: ({ row }: any) => {
        const status = row?.original?.status ? row?.original?.status : '-'
        return (
          <div>
            <Chip title={status} />
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Remarks</div>
      ),
      accessor: 'remark',
      Cell: ({ value }: any) => (
        <div className="p-xs-regular">{value ?? '-'}</div>
      ),
    },
    {
      Header: 'Approved by',
      accessor: 'approved_by',
      disableSortBy: true,

      Cell: ({ row }: any) => {
        const approvedBy = row.original.approved_by
        const name = `${approvedBy?.first_name} ${approvedBy?.last_name}`
        const time = formatUnixDateWithTime(
          row.original.approved_on,
          plant?.timezone,
        )

        return (
          <div>
            {approvedBy ? (
              <>
                <div>{name}</div>
                <div className="p-xs-regular-dim">{time}</div>
              </>
            ) : (
              <div>-</div>
            )}
          </div>
        )
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 100,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between ">
          <div className="flex flex-row gap-4">
            <button
              className={`${row?.original?.status === 'PENDING' ? '' : 'opacity-40'}`}
              onClick={
                row?.original?.status === 'PENDING'
                  ? (event: { stopPropagation: () => void }) => {
                      event.stopPropagation()
                      timesheetEditAccess &&
                        navigate(`/timesheet/${row?.original?._id}/edit-record`)
                    }
                  : undefined
              }
            >
              <Pen />
            </button>
            <button
              className={`${row?.original?.status === 'PENDING' ? '' : 'opacity-40'}`}
              onClick={
                row?.original?.status === 'PENDING'
                  ? (event: { stopPropagation: () => void }) => {
                      event.stopPropagation()
                      timesheetEditAccess &&
                        setDeleteState({
                          state: true,
                          ids: row?.original?._id,
                          data: row?.original,
                        })
                    }
                  : undefined
              }
            >
              <Bin />
            </button>
          </div>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleAddRecord = () => {
    navigate('/timesheet/add-record')
  }

  const toggleDeletePopUp = (data: any) => {
    if (!deleteState?.state) {
      setDeleteState({ state: true, ids: data?._id, data: '' })
    } else {
      setDeleteState({ state: false, ids: [], data: '' })
    }
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Timesheets</h1>
        <p className="p-sm-regular-1">Record and track your hours for jobs.</p>
        <FilterableSearchTable
          useQuery={useGetTimesheetQuery}
          columns={columns}
          addButton={{ label: 'Add Record', onClick: handleAddRecord }}
          placeholders={{
            emptyMessage: 'No timesheet records are added. Please add Record.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search by Shift and Status',
          }}
          filterPanelComponent={TimeSheetFilterPanel}
          additionalFilters={additionalFilters}
          accessRights={timesheetEditAccess}
        />

        <Popup
          isOpen={deleteState.state}
          title="Delete Timesheet Record"
          onClose={() => {
            setDeleteState({ state: false, ids: [], data: '' })
          }}
        >
          <DeleteTimeSheet
            closePopUp={toggleDeletePopUp}
            deleteState={deleteState}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default OperatorTimeSheetPage
