import { useState, useEffect } from 'react'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { t } from 'i18next'
import MotionDiv from 'libs/motionDiv'
import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import Filter from 'assets/icons/camera-page/Filter'
import PaginatedTable from 'libs/table/PaginatedTable'
import SearchBox from 'libs/searchbox/SearchBox'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import { useGetEmployeeQuery } from '../employees-endpoints/employees.endpoints'
import SidePanel from 'libs/sidepanel/SidePanel'
import DeleteEmployee from 'src/pages/employee/Employee-Management/employee-popup/DeleteEmployee.tsx'
import EmployeeFilterPanel from 'src/pages/employee/Employee-Management/employee-panel/EmployeeFilterPanel.tsx'

import { useNavigate } from 'react-router-dom'
import { EmployeeFilters } from 'pages/employee/employees-endpoints/employeemanagementTypes'

// import Popup from 'libs/Popup'

import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import Popup from 'libs/popup/Popup'
import { countryPhoneNumber } from 'utils/phoneNumberValidator'
import { hasAccess } from 'utils/utils'

const EmployeeManagementPage = () => {
  const plant = useSelector(selectSelectedItem)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch()
  const employeeDirectoryEditAccess = hasAccess('emp', { ed: 'e' })
  const employeeDirectoryAccess = hasAccess('emp', { ed: 'e' })

  const [isEmployeePopup, setIsEmployeePopup] = useState(false)
  const [employeeData, setEmployeeData] = useState('')

  const navigate = useNavigate()

  const [filters, setFilters] = useState<EmployeeFilters>({
    page_no: 1,
    page_size: 10,
    searchText: '',
    designation: [],
    department: [],
    sortBy: 'updated_on',
    sortDir: 'DESC',
  })

  const {
    data,
    isLoading: addLoading,
    isFetching,
  } = useGetEmployeeQuery(
    {
      plant_id: plant?.id,
      filters: filters,
    },
    {
      skip: !plant?.id,
    },
  )

  const [isShowFilter, setIsShowFilter] = useState(false)

  const handlePageNumberChange = (newPageSize: any) => {
    const totalItemsCount = data?.paging?.total_items_count
    const currentPage = filters?.page_no
    const startIndex = (currentPage - 1) * filters?.page_size + 1
    const totalPages = totalItemsCount
      ? Math.ceil(totalItemsCount / newPageSize)
      : 0
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
  }

  // ****************************Filter *****************************//

  //  Filter toggle
  const handleFilterToggle = () => {
    setIsShowFilter(!isShowFilter)
  }

  const handleMoData = (page_no: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: page_no,
    }))
  }

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  const activeFiltersCount = [
    filters?.department,
    filters?.designation,
  ]?.filter((filter) => {
    if (Array.isArray(filter)) {
      return filter.length > 0
    } else {
      return Boolean(filter) // checks for non-null and non-undefined values
    }
  })?.length

  // handle sort for column
  const handleSort = (columnName: any) => {
    setFilters((prevFilters: any) => {
      const newSortDir =
        prevFilters?.sortBy === columnName
          ? prevFilters?.sortDir === 'ASC'
            ? 'DESC'
            : 'ASC'
          : 'ASC'

      return {
        ...prevFilters,
        sortBy: columnName,
        sortDir: newSortDir,
      }
    })
  }

  //delete mo

  const handleDeleteClick = (row: any) => {
    setEmployeeData(row)
    setIsEmployeePopup(true)
  }

  const handleEditClick = (row: any) => {
    employeeDirectoryEditAccess &&
      navigate(
        `/employee/employee-management/${row?._id}/edit/personal-information`,
      )
  }

  //columns

  const columns = [
    {
      Header: 'Employee ID',
      accessor: 'employeeID',
      Cell: ({ row }: any) => {
        const employee = row?.original
        return (
          <div
            className={`${employeeDirectoryAccess ? 'hyperlink' : ''}`}
            onClick={() => {
              employeeDirectoryAccess &&
                navigate(
                  `/employee/employee-management/${employee?._id}/view-personal-information`,
                )
            }}
          >
            {employee?.employeeID}
          </div>
        )
      },
    },
    {
      Header: 'Employee Name',
      accessor: 'employeeName',
    },
    {
      Header: 'Department',
      accessor: 'department',
      Cell: ({ value }: any) => {
        return (
          <span className="whitespace-nowrap text-xs">
            {value?.name ?? '-'}
          </span>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Designation',
      accessor: 'designation',
      Cell: ({ value }: any) => {
        return <span>{value?.name ?? '-'}</span>
      },
      disableSortBy: true,
    },
    {
      Header: 'Contact Number',
      accessor: 'contactNumber',
      Cell: ({ value }: any) => {
        const { phoneNumber } = countryPhoneNumber(value)
        return <span>{phoneNumber ?? '-'}</span>
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
    },

    {
      Header: 'Actions',
      accessor: 'action',
      width: 130,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row">
            <button
              className={`mr-2 ${employeeDirectoryEditAccess ? '' : 'opacity-40'}`}
              onClick={(event) => {
                event.stopPropagation()
                employeeDirectoryEditAccess && handleEditClick(row?.original)
              }}
            >
              <Pen />
            </button>
            <button
              className={`mr-2 ${employeeDirectoryEditAccess ? '' : 'opacity-40'}`}
              onClick={(event) => {
                event.stopPropagation()
                if (row?.original && employeeDirectoryEditAccess) {
                  handleDeleteClick(row?.original)
                }
              }}
            >
              <Bin />
            </button>
          </div>
          <span
            className="cursor-pointer"
            onClick={() =>
              employeeDirectoryAccess &&
              navigate(
                `/employee/employee-management/${row?.original?._id}/view-personal-information`,
              )
            }
          >
            <ClickableRowArrow />
          </span>
        </div>
      ),
    },
  ]
  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">{'Employee Management'}</h1>
            <p className="w-4/4 p-sm-regular-1">
              {
                'View the details of your added Employees and easily add new ones as needed.'
              }
            </p>
          </div>
        </div>

        <WhiteCard className="mt-4">
          <div className="flex justify-between gap-4">
            <div className="flex w-full gap-4">
              <Button onClick={handleFilterToggle}>
                <Filter color="#3D4140" />
                {t('filters')}{' '}
                {activeFiltersCount === 0 ? '' : `(${activeFiltersCount})`}
              </Button>
              <SearchBox
                value={inputValue}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search Employee by name, ID, Designation, Department or email "
                onClearSearchBox={() => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    searchText: '',
                  }))
                  handleSearch('')
                }}
              />
            </div>

            {/* <div className="relative  flex gap-3 ">
              <ExportButton disabled onOptionSelect={() => {}} loading={false}>
                {t('inventory_.export_data')}
              </ExportButton>

              <ImportButton disabled onOptionSelect={() => {}}>
                {t('inventory_.import_data')}
              </ImportButton>
            </div> */}
            {employeeDirectoryEditAccess && (
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  navigate(
                    '/employee/employee-management/add/personal-information',
                  )
                }}
              >
                {'Add Employee'}
              </Button>
            )}
          </div>
          <div className="mt-3">
            <PaginatedTable
              onPageNumberChange={handleMoData}
              columns={columns}
              rows={data?.data}
              currentPageNumber={filters.page_no}
              sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
              pageSize={filters.page_size}
              onPageSizeChange={handlePageNumberChange}
              // onSingleClick={handleViewMo}
              emptyMessage={
                filters?.searchText !== ''
                  ? 'No matching results found'
                  : 'No Employee are added. Please add Employee.'
              }
              loading={addLoading || isFetching}
              pagination={data?.paging}
              onColumnSort={handleSort}
            />
          </div>
        </WhiteCard>

        <SidePanel
          isOpen={isShowFilter}
          title="Filters"
          onClose={() => {
            setIsShowFilter(false)
          }}
        >
          <EmployeeFilterPanel
            filters={filters}
            setFilters={setFilters}
            closePanel={() => {
              setIsShowFilter(false)
            }}
          />
        </SidePanel>

        <Popup
          isOpen={isEmployeePopup}
          title="Delete Employee Details"
          onClose={() => {
            setIsEmployeePopup(false)
          }}
        >
          <DeleteEmployee
            closePopUp={() => {
              setIsEmployeePopup(false)
            }}
            data={employeeData}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default EmployeeManagementPage
