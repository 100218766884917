import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Popup from 'libs/popup/Popup'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import FilterableSearchTable from 'libs/table/filterable-search-table_new/FilterableSearchTable'
import Tooltip from 'libs/tool-tip/Tooltip'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import { countryPhoneNumber } from 'utils/phoneNumberValidator'
import { hasAccess } from 'utils/utils'
import { formatString } from 'utils/stringHandlers'
import { useGetCustomersQuery } from './customer-endpoints/customer.endpoints'
import DeleteCustomer from './popup/DeleteCustomer'
import CustomerFilterPanel from './panel/CustomerFilterPanel'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { Customer } from './customer-endpoints/customerTypes'
import { CellProps } from 'react-table'

const additionalFilters = {
  created_between: [],
  updated_between: [],
  status: [],
  category: [],
  sort_by: 'updated_on',
  sort_dir: 'DESC',
}

const CustomerManagement = () => {
  useTabTitle('Customers')
  const navigate = useNavigate()

  const [deletePopUp, setDeletePopUp] = useState<{
    isPopUpOpen: boolean
    customer: Customer | null
  }>({
    isPopUpOpen: false,
    customer: null,
  })

  const hasCustomerEditAccess = hasAccess('con', { cus: 'e' })
  const hasCustomerViewAccess = hasAccess('con', { cus: 'v' })

  // handle sort for column

  const columns: ExtendedColumn<Customer>[] = useMemo(
    () => [
      {
        Header: 'Customer Name',
        accessor: 'name',
        Cell: ({ row }: CellProps<Customer>) => {
          const customer = row?.original
          const id = customer?.id
          return (
            <div
              className="link-text"
              onClick={() => {
                hasCustomerViewAccess &&
                  id &&
                  navigate(
                    `/contacts/customer-management/view/${id}/basic-details`,
                  )
              }}
            >
              {customer?.name ?? '-'}
            </div>
          )
        },
      },

      {
        Header: 'Customer Type',
        accessor: 'customerType',
        Cell: ({ row }: CellProps<Customer>) =>
          formatString(row.original.customerType),
      },
      {
        Header: 'Email',
        accessor: 'businessEmailAddress',
        Cell: ({ row }: CellProps<Customer>) =>
          row.original.businessEmailAddress ?? '-',
      },

      {
        Header: 'Contact Number',
        accessor: 'primaryContactNumber',
        Cell: ({ row }: CellProps<Customer>) => {
          const { phoneNumber } = countryPhoneNumber(
            row.original.primaryContactNumber,
          )
          return <span className="font-mono">{phoneNumber ?? '-'}</span>
        },
      },

      {
        Header: 'Primary Address',
        accessor: 'addresses',
        Cell: ({ row }: CellProps<Customer>) => {
          const value = row.original.addresses
          if (value && value?.length > 0) {
            const primaryAddress = value?.find((address) => address?.isPrimary)
            if (!primaryAddress) return '-'
            return (
              <div>
                <p>
                  {primaryAddress?.addressLine1}, {primaryAddress?.city}
                </p>
              </div>
            )
          } else {
            return <div>-</div>
          }
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'id',
        width: 10,
        Cell: ({ row }: CellProps<Customer>) => (
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row">
              <Tooltip content="Edit">
                <button
                  className={`mr-2 ${hasCustomerEditAccess ? '' : 'opacity-40'}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    row?.original?.id &&
                      hasCustomerEditAccess &&
                      navigate(`edit/${row.original.id}/basic-details`)
                  }}
                >
                  <Pen />
                </button>
              </Tooltip>

              <Tooltip content="Delete">
                <button
                  className={`mr-2 ${hasCustomerEditAccess ? '' : 'opacity-40'}`}
                  onClick={(event) => {
                    event.stopPropagation()
                    hasCustomerEditAccess &&
                      row.original &&
                      setDeletePopUp({
                        isPopUpOpen: true,
                        customer: row.original,
                      })
                  }}
                >
                  <Bin />
                </button>
              </Tooltip>
            </div>
            <Tooltip content={'View'}>
              <div
                className="flex cursor-pointer items-center justify-center p-1"
                onClick={() =>
                  row?.original?.id &&
                  hasCustomerViewAccess &&
                  navigate(
                    `/contacts/customer-management/view/${row.original.id}/basic-details`,
                  )
                }
              >
                <ClickableRowArrow />
              </div>
            </Tooltip>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const handleAddCustomer = () => {
    hasCustomerEditAccess && navigate('add/basic-details')
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Customers</h1>
        <div className="flex justify-between">
          <p className="w-[600px] p-sm-regular-1">
            View the details of your added customers and easily add new ones as
            needed.
          </p>
        </div>
        <FilterableSearchTable
          useQuery={useGetCustomersQuery}
          columns={columns}
          addButton={{
            label: 'Add Customer',
            onClick: handleAddCustomer,
          }}
          placeholders={{
            emptyMessage: 'No Customers are added. Please add Customer.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Customers by name, type, email or address ',
          }}
          filterPanelComponent={CustomerFilterPanel}
          additionalFilters={additionalFilters}
          accessRights={hasCustomerEditAccess}
        />
        {deletePopUp.customer && (
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Customer Details"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, customer: null })
            }}
          >
            <DeleteCustomer
              closePopUp={() => {
                setDeletePopUp({ isPopUpOpen: false, customer: null })
              }}
              customer={deletePopUp.customer}
            />
          </Popup>
        )}
      </>
    </MotionDiv>
  )
}

export default CustomerManagement
