import React from "react";

const Pages: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_1498_604"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_1498_604)">
        <path
          fill="#041220"
          d="M11.083 16.896a.8.8 0 01-.25-.584.8.8 0 01.25-.583l2.396-2.396H5.833c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.176v-7.5c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24s.434.08.594.24c.16.16.24.358.24.594v7.5h7.645L11.063 9.25a.763.763 0 01-.24-.583.879.879 0 01.24-.584.839.839 0 01.583-.26.763.763 0 01.583.24l3.854 3.854a.731.731 0 01.178.27.923.923 0 01.052.313.923.923 0 01-.052.313.731.731 0 01-.178.27l-3.812 3.813a.811.811 0 01-.594.25.811.811 0 01-.594-.25z"
        ></path>
      </g>
    </svg>
  );
}

export default Pages;
