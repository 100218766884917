import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import MotionDiv from 'libs/motionDiv'
import MachineClassFilter from './panel/MachineClassFilters'
import { useState } from 'react'
import Popup from 'libs/popup/Popup'
import DeleteMachineClass from './popup/DeleteMachineClass'
import { useSelector } from 'react-redux'
import useTabTitle from 'libs/hooks/useTabTitle'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useNavigate } from 'react-router-dom'
import { useGetMachineClassesQuery } from './machine-class-endpoints/machineClass.endpoints'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Tooltip from 'libs/tool-tip/Tooltip'

const MachinesClassPage = () => {
  const navigate = useNavigate()

  const handleAddMachineClass = () => {
    navigate('/maintenance/machine-classes/add')
  }
  const plantId = useSelector(selectSelectedItem)

  useTabTitle('Machine Classes')

  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, machineClassID: '' })
  const machineClassColumns = [
    {
      Header: 'Machine Class',
      accessor: 'name',
      width: 350,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <Tooltip content="View Details" position="right">
            <div
              className="hyperlink"
              onClick={() => {
                data?._id && navigate(`/maintenance/machine-classes/view/${data?._id}`)
              }}
            >
              {data?.name ?? '-'}
            </div>
          </Tooltip>
        )
      },
    },
    {
      Header: 'Models',
      accessor: 'machineModels',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const models = row?.original?.machineModels || []

        return (
          <Tooltip
            content={
              models.length > 0 ? (
                models.map((model: any) => (
                  <div key={model?._id}>
                    <span>• </span>
                    <span>{model?.modelNumber}</span>
                  </div>
                ))
              ) : (
                <p>0 models available</p>
              )
            }
            position="left"
          >
            <span className="cursor-pointer">{models.length.toString().padStart(2, '0')}</span>
          </Tooltip>
        )
      },
    },

    {
      Header: 'Machines',
      accessor: 'machines',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const data = row?.original?.machines || []
        return (
          <Tooltip
            content={
              data.length > 0 ? (
                data.map((machine: any) => (
                  <div key={machine?._id}>
                    <span>• </span>
                    <span>{machine?.name}</span>
                  </div>
                ))
              ) : (
                <p>0 machines available</p>
              )
            }
            position="left"
          >
            <span className="cursor-pointer">{data?.length.toString().padStart(2, '0')}</span>
          </Tooltip>
        )
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 350,
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const description = row?.original?.description
        return <div className="truncate-text w-[350px]">{description.length ? description : '-'}</div>
      },
    },

    {
      Header: 'Actions',
      accessor: 'actions',
      width: 100,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between ">
          <div className="ml-1 flex flex-row">
            
            <Tooltip content="Edit Details">
              <button
                onClick={() => {
                  row?.original?._id && navigate(`/maintenance/machine-classes/edit/${row?.original?._id}`)
                }}
              >
                <Pen />
              </button>
            </Tooltip>
            <Tooltip content="Delete Details">
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  setDeletePopUp({ isPopUpOpen: true, machineClassID: row?.original })
                }}
                className="mr-6"
              >
                <Bin />
              </button>
            </Tooltip>
          </div>
          <Tooltip content="View Details" position="left">
            <span
              className="cursor-pointer pb-1"
              onClick={() => {
                row?.original?._id && navigate(`/maintenance/machine-classes/view/${row?.original?._id}`)
              }}
            >
              <ClickableRowArrow />
            </span>
          </Tooltip>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Machine Classes</h1>
        <p className="p-sm-regular-1">View and manage all machine classes for streamlined categorization.</p>
        <FilterableSearchTable
          useQuery={useGetMachineClassesQuery}
          columns={machineClassColumns}
          filterPanelComponent={MachineClassFilter}
          addButton={{ label: 'Add Machine Class', onClick: handleAddMachineClass }}
          placeholders={{
            emptyMessage: 'No Machine classes are added. Please add a Machine class',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Machine Class by name',
          }}
        />
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Machine Class?"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, machineClassID: '' })
          }}
        >
          <DeleteMachineClass
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, machineClassID: '' })}
            machineClass={deletePopUp.machineClassID}
            plant_id={plantId?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default MachinesClassPage
