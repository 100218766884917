import { useState, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { t } from 'i18next'

import ViewBom from 'pages/products/view-bom/ViewBom'
import DeleteEntry from 'pages/products/delete-product/DeleteEntry'
import ViewProduct from 'pages/products/view-product/ViewProduct'
import { useGetProductQuery } from './products-endpoints/products.endpoints'
import { caseSensitiveSort } from 'utils/utils'
import MotionDiv from 'libs/motionDiv'
import { formatNumber } from 'utils/numberHandlers'
import FilterProducts from './panel/FilterProducts'
import useTabTitle from 'libs/hooks/useTabTitle'
import SidePanel from 'libs/sidepanel/SidePanel'
import Popup from 'libs/popup/Popup'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'

const additionalFilters = {
  category: [],
  createdBetween: [],
  updatedBetween: [],
}

const Products = () => {
  const navigate = useNavigate()
  useTabTitle(t('products'))

  //toggle filter
  const [showFilter, setShowFilter] = useState(false)

  //add entry panel
  const [showPanel, setShowPanel] = useState(false)
  //view entry panel
  const [viewPanel, setViewPanel] = useState(false)
  //view bom entry panel
  const [viewBomPanel, setViewBomPanel] = useState(false)
  //edit entry panel
  const [editPanel, setEditPanel] = useState(false)
  //show delete popup
  const [deletPopup, setDeletePopup] = useState(false)

  const [selectedEntry, setSelectedEntry] = useState(null)
  //toggle columns

  const popupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Check if the click is outside the popup
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.ignoreref') &&
        !(event.target as HTMLElement).closest('.rs-picker-daterange-panel')
      ) {
        setViewPanel(false)
        setEditPanel(false)

        setShowFilter(false)
        setShowPanel(false)
        setViewBomPanel(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  //toggle add entry
  const togglePanel = () => {
    navigate('newProducts')
    setShowPanel(!showPanel)
  }

  //toggle view bom entry
  const toggleViewBomPanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setViewBomPanel(!viewBomPanel)
  }
  //toggle edit entry
  const toggleEditPanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setEditPanel(!editPanel)
  }

  //toggle delete popup
  const toggleDelete = () => {
    setDeletePopup(!deletPopup)
    if (viewPanel === true) {
      setViewPanel(!viewPanel)
    }
    if (editPanel === true) {
      setEditPanel(!editPanel)
    }
  }

  const currentDate = new Date()
  const fourMonthsAgo = new Date()
  fourMonthsAgo.setMonth(currentDate.getMonth() - 4)

  const column = useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'name',
        width: 150,
        sortType: caseSensitiveSort,
        Cell: ({ row }: any) => {
          const data = row?.original
          const toggleViewPanel = (entryData: any) => {
            setSelectedEntry(entryData)
            setViewPanel(!viewPanel)
          }
          return (
            <div className="">
              <div
                className="hyperlink p-xs-regular"
                onClick={() => toggleViewPanel(data)}
              >
                {data?.name}
              </div>
              <div className="p-xs-regular-dim">{data?.code}</div>
            </div>
          )
        },
      },
      {
        Header: 'Product Category',
        accessor: 'category',
        width: 150,
        disableSortBy: true,
      },
      {
        Header: 'Product Tags',
        width: 200,
        accessor: 'tags',
        Cell: ({ value }: any) => {
          let displayCodes = value?.slice(0, 2) // Display only the first two codes
          const remainingCount = value?.length - displayCodes?.length
          if (remainingCount > 0) {
            displayCodes = [...displayCodes, `${remainingCount}+`]
          }

          return displayCodes?.length > 0 ? (
            <div className="flex flex-wrap">
              {Array.isArray(displayCodes) ? (
                displayCodes?.map((code, index) => (
                  <span
                    key={index}
                    className={`m-1 rounded-[28px] bg-[#E7F5FF] px-2 py-0.5 text-[#0B5ED7]`}
                  >
                    {code}
                  </span>
                ))
              ) : (
                <span className="text-[#434C52]">{displayCodes}</span>
              )}
            </div>
          ) : (
            <div>-</div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Used In',
        accessor: 'used_in',
        disableSortBy: true,
        width: 150,
        Cell: ({ row }: any) => {
          const data = row?.original
          const toggleViewPanel = (entryData: any) => {
            setSelectedEntry(entryData)
            setViewPanel(!viewPanel)
          }
          return (
            <div
              className="hyperlink cursor-pointer"
              onClick={() => toggleViewPanel(data)}
            >
              {formatNumber(row.original?.process_details?.length || 0)} Process
            </div>
          )
        },
      },
      {
        Header: 'BOM',
        accessor: 'bill_of_materials',
        disableSortBy: true,
        width: 150,
        Cell: ({ row }: any) => {
          if (row?.original?.bill_of_materials?.length > 0) {
            return (
              <div
                className="cursor-pointer text-[#0B5ED7] underline underline-offset-2 hover:font-semibold"
                onClick={(e) => {
                  e.stopPropagation()
                  toggleViewBomPanel(row?.original)
                }}
              >
                View BOM
              </div>
            )
          } else {
            return '-' // or any other fallback UI if needed
          }
        },
      },
    ],
    [],
  )

  const toggleViewProduct = (data: any) => {
    navigate('/settings/configure/products/editProducts', { state: data })
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">{t('products')}</h1>
        <p className="p-sm-regular-1">{t('product.products_description')}</p>
        <FilterableSearchTable
          useQuery={useGetProductQuery}
          columns={column}
          addButton={{ label: t('product.add_product'), onClick: togglePanel }}
          placeholders={{
            emptyMessage:
              'No product added to show.Add a product in 2 easy steps.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search by product name or code',
          }}
          filterPanelComponent={FilterProducts}
          additionalFilters={additionalFilters}
          onDoubleClick={toggleViewProduct}
        />
        <SidePanel
          isOpen={viewPanel}
          title={'Product Details'}
          onClose={() => {
            setSelectedEntry(null)
            setViewPanel(false)
          }}
        >
          <ViewProduct deletePopup={toggleDelete} data={selectedEntry} />
        </SidePanel>

        <SidePanel
          size="large"
          isOpen={viewBomPanel}
          title={'Bill Of Materials'}
          onClose={() => {
            setSelectedEntry(null)
            setViewBomPanel(false)
          }}
        >
          <ViewBom
            show={viewBomPanel}
            removeEntry={toggleViewBomPanel}
            editEntry={toggleEditPanel}
            deletePopup={toggleDelete}
            data={selectedEntry}
          />
        </SidePanel>

        <Popup
          isOpen={deletPopup}
          title={'Delete Product?'}
          onClose={() => {
            setSelectedEntry(null)
            setDeletePopup(false)
          }}
        >
          <DeleteEntry
            show={deletPopup}
            toggleDelete={toggleDelete}
            data={selectedEntry}
          />
        </Popup>

        <div
          className={
            showPanel || viewPanel || editPanel || deletPopup || showFilter
              ? 'sideNav-overlay-dark'
              : 'sideNav-overlay'
          }
        ></div>
      </>
    </MotionDiv>
  )
}

export default Products
