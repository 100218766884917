import { CellProps } from 'react-table'
import PaginatedTable from 'libs/table/PaginatedTable'
import { formatNumber } from 'utils/numberHandlers'
import { ExtendedColumn } from 'store/services/utilsTypes'
import {
  ProductResponse,
  PurchaseOrder,
} from '../purchase-orders-endpoints/purchaseOrdersTypes'

interface Props {
  isOpen: boolean
  closePanel: () => void
  purchaseOrder: PurchaseOrder
}

const columns: ExtendedColumn<ProductResponse>[] = [
  {
    Header: 'Raw Materials',
    accessor: 'name',
    width: 100,
    Cell: ({ row }: CellProps<ProductResponse>) => {
      return (
        <div className="">
          <div className="p-xs-regular">{row.original?.name}</div>
          <div className="p-xs-regular-dim">{row.original?.code ?? '-'}</div>
        </div>
      )
    },
    disableSortBy: true,
  },

  {
    Header: 'Quantity',
    accessor: 'quantity',
    align: 'right',
    Cell: ({ row }: CellProps<ProductResponse>) =>
      formatNumber(row.original.quantity),
    disableSortBy: true,
    width: 60,
  },
  {
    Header: 'Unit of Measure',
    accessor: 'unit_of_measure',
    disableSortBy: true,
    width: 50,
  },
]

const ViewPOProducts = ({ purchaseOrder }: Props) => {
  return (
    <div>
      <div className="flex flex-col gap-2 rounded bg-gray-100 p-3">
        {purchaseOrder?.purchaseOrderID && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Purchase Order ID</div>
            <div>
              <div className="w-60 break-words p-xs-regular">
                {purchaseOrder?.purchaseOrderID}
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between">
          <div className="p-xs-regular-dim">Product Category</div>
          <div>
            <div className="w-60 p-xs-regular">Raw Materials</div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <PaginatedTable
          columns={columns}
          emptyMessage={
            "You don't have any Row material added for this Purchase Order"
          }
          rows={purchaseOrder.products}
          hidePagination={true}
        />
      </div>
    </div>
  )
}

export default ViewPOProducts
