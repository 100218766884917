import Button from '../../../libs/button/Button'
import { t } from 'i18next'

import { useUpdateManufactureOrdersStatusMutation } from '../manufacture-orders-endpoints/manufacture_order.endpoints'
import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  moOrder: any
  plantId: string
}

function CancelMoOrder({ closePopUp, moOrder, plantId }: Props) {
  const [updateMoOrder, { isLoading: updateManufactureOrderLoading }] = useUpdateManufactureOrdersStatusMutation()

  const navigate = useNavigate()
  const handleUpdateMoOrder = () => {
    updateMoOrder({ plantId, moID: moOrder?._id, status: 'CANCELLED' })
      .unwrap()
      .then(() => {
        toast.success(`Manufacture Order “${moOrder?.manufactureOrderID}” is  cancelled successfully.`)
        navigate(-1)
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(
          error?.data?.detail ??
            `Failed to cancel Manufacture Order “${moOrder?.manufactureOrderID}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}{' '}
        <span className="break-words pr-1 p-xs-bold">“{moOrder?.manufactureOrderID}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Keep MO</Button>
        <Button color="error" onClick={handleUpdateMoOrder} loading={updateManufactureOrderLoading}>
          Cancel MO
        </Button>
      </div>
    </div>
  )
}

export default CancelMoOrder
