import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Field from 'libs/field'
import { FormikContextType } from 'formik'
import { PlanForm } from '../../planning-wizard'
import Button from 'libs/button/Button'
import { useEffect } from 'react'

type Props = {
  formik: FormikContextType<PlanForm>
  createLoading: boolean
  setPage: any
  setId: any
}

const PlanDetails = ({ formik, setPage, setId }: Props) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    setId({ id: id })
    setPage(1)
  }, [])

  return (
    <div className="flex h-screen-90 w-full flex-col px-2">
      <div className="flex-grow">
        <div className="h5-1">{t('plannings.plan_details')}</div>
        <p className="p-sm-regular">Add the basic details that are required for this plan.</p>
        <div>
          <Field
            required={true}
            label={t('plannings.plan_id')}
            formik={formik}
            name="planID"
            placeholder={t('plannings.plan_id_placeholder')}
          />
          <Field
            type="textarea"
            label={t('plannings.description')}
            formik={formik}
            name="description"
            placeholder={t('plannings.description_placeholder')}
          />
        </div>
      </div>
      <div className="mt-auto flex justify-between">
        <div className="flex gap-3">
          <Button type="button" onClick={() => navigate('/planning/production-plans')}>
            {t('cancel')}
          </Button>
        </div>
        <div>
          <Button
            color="success"
            type="submit"
            onClick={() => {
              formik.setFieldValue('should_save_draft', false)
              formik.handleSubmit()
            }}
          >
            {id ? t('next') : t('plannings.next_add_so')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PlanDetails
