import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import { useGetMachineQuery } from '../machine-endpoints/machine.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetWorkCentersQuery } from 'pages/work-center/work-center-endpoints/workcenter.endpoint'
import { useMemo } from 'react'
import { GetMachineClass } from 'pages/maintenance/machine-class/machine-class-endpoints/machineClassTypes'
import { useGetMachineClassesQuery } from 'pages/maintenance/machine-class/machine-class-endpoints/machineClass.endpoints'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  machines: string[]
  dateCreated: number[]
  status: string[]
  workCenter: string[]
  machineClass: string[]
  modelNumber: string[]
}

interface FormValues {
  machines: string[]
  status: string[]
  dateCreated: number[]
  workCenter: string[]
  machineClass: string[]
  modelNumber: string[]
}

const verificaton = [
  {
    name: 'Operational',
    value: 'OPERATIONAL',
    _id: '101',
  },
  {
    name: 'Out of Service',
    value: 'OUT_OF_SERVICE',
    _id: '102',
  },
  {
    name: 'Under Maintenance',
    value: 'UNDER_MAINTENANCE',
    _id: '104',
  },
]

const defaultFilters: any = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const MachineFilters: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const plant = useSelector(selectSelectedItem)
  const { data: machinesData } = useGetMachineQuery(
    {
      plantId: plant?.id,
      filters: defaultFilters,
    },
    {
      skip: !plant.id,
    },
  )
  const { data: workCenterData } = useGetWorkCentersQuery(
    {
      plantId: plant?.id,
      filters: defaultFilters,
    },
    {
      skip: !plant.id,
    },
  )

  const { data: machineClassesData } = useGetMachineClassesQuery(
    { plantId: plant?.id, filters: defaultFilters },
    {
      skip: !plant?.id,
    },
  )

  const initialValues: FormValues = {
    machines: filters?.machines ?? [],
    status: filters?.status ?? [],
    machineClass: filters?.machineClass ?? [],
    dateCreated: filters?.dateCreated?.length == 0 ? [] : filters?.dateCreated,
    modelNumber: filters?.modelNumber ?? [],
    workCenter: filters?.workCenter ?? [],
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 1000,
      searchText: '',
      machines: [],
      status: [],
      dateCreated: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        dateCreated: values?.dateCreated == null ? [] : values?.dateCreated,
        machines: values?.machines,
        status: values?.status,
        machineClass: values?.machineClass,
        modelNumber: values?.modelNumber,
        workCenter: values?.workCenter,
      }))
    },
  })

  const machineOptions = machinesData?.data?.map((item) => ({
    label: item.name,
    value: item.name,
    sublabel: '',
    disabled: '',
  }))

  const machineClassOptions = useMemo(
    () =>
      machineClassesData?.data?.map((item: GetMachineClass) => ({
        label: item?.name,
        value: item._id,
        disabled: '',
      })) || [],
    [machineClassesData?.data],
  )

  const modelNumberOptions = useMemo(
    () =>
      machineClassesData?.data
        ?.filter(
          (item: GetMachineClass) =>
            filterFormik.values.machineClass.length === 0 || filterFormik.values.machineClass.includes(item._id), // If machineClass is empty, return all items
        )
        ?.flatMap(
          (item: GetMachineClass) =>
            item.machineModels?.map((model: any) => ({
              label: model.modelNumber,
              value: model._id,
              disabled: '',
            })) || [], // Return an empty array if machineModels is undefined
        ) || [], // Return an empty array if no machineClasses matched
    [filterFormik.values.machineClass, machineClassesData?.data],
  )

  const workCenterOptions = workCenterData?.data?.map((item) => ({
    label: item?.name,
    value: item?._id,
    sublabel: item?.workCenterID,
    disabled: '',
  }))

  const verificatonOptions = verificaton.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))

  return (
    <>
      <div className="flex h-full flex-col gap-3">
        <Field
          type="multiselect"
          label="Machines"
          placeholder="Select Machines"
          options={machineOptions}
          formik={filterFormik}
          name="machines"
        />
        <Field
          type="multiselect"
          label="Machine Class"
          placeholder="Select Machine Class"
          options={machineClassOptions}
          formik={filterFormik}
          name="machineClass"
        />
        <Field
          type="multiselect"
          label="Model Number"
          placeholder="Select Model Number"
          options={modelNumberOptions}
          formik={filterFormik}
          name="modelNumber"
        />
        <Field
          type="multiselect"
          label="Work Center"
          placeholder="Select Work Center"
          options={workCenterOptions}
          formik={filterFormik}
          name="workCenter"
        />
        <Field
          type="multiselect"
          label="Status"
          placeholder="Select Status"
          options={verificatonOptions}
          formik={filterFormik}
          name="status"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date Created"
          formik={filterFormik}
          name="dateCreated"
        />
        <div className="my-auto mb-0 flex justify-between pt-5 ">
          <div>
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset Filters
            </Button>
          </div>
          <div className="flex gap-2 ">
            <Button onClick={closePanel}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default MachineFilters
