import { createSlice } from '@reduxjs/toolkit'

const navSlice = createSlice({
  name: 'nav',
  initialState: {
    selectedItem: {
      id: null,
      name: '',
    },
    plantDetailActive: false,
    isActive: true,
    isAdmin: true,
    collapse: true,
    configure: true,
    planId: null,
    product: '',
  },
  reducers: {
    selectItem: (state, action) => {
      const newObj = { ...action.payload, id: action.payload?._id }
     
      
      state.selectedItem = newObj
    },
    togglePlantDetail: (state) => {
      state.plantDetailActive = !state.plantDetailActive
    },
    toggleDashboardSideNav: (state) => {
      state.isActive = !state.isActive
    },
    setNavActive: (state) => {
      state.isActive = true
    },
    setNavInactive: (state) => {
      state.isActive = false
    },
    setPlantInactive: (state) => {
      state.plantDetailActive = false
    },
    setCollapseState: (state) => {
      state.collapse = !state.collapse
    },
    setConfigure: (state) => {
      state.configure = !state.configure
    },
    setPlanId: (state, action) => {
      state.planId = action.payload
    },
    setProduct: (state, action) => {
      state.selectedItem = action.payload
    },
  },
})

export const {
  setNavActive,
  setNavInactive,
  setCollapseState,
  setPlantInactive,
  selectItem,
  togglePlantDetail,
  toggleDashboardSideNav,
  setConfigure,
  setPlanId,
  setProduct,
} = navSlice.actions

export const selectSelectedItem = (state: any) => state.nav.selectedItem
export const selectPlantDetailActive = (state: any) => state.nav.plantDetailActive
export const selectIsActive = (state: any) => state.nav.isActive
export const collapseState = (state: any) => state.nav.collapse
export const configureState = (state: any) => state.nav.configure
export const selectPlanId = (state: any) => state.nav.planId
export const Product = (state: any) => state.nav.id
export default navSlice.reducer
