import Button from 'libs/button/Button'
import { useDeleteMaintenanceOrderMutation } from '../maintenance-order-endpoints/order.endpoints'
import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

const DeleteMaintenanceOrderPopup = ({ closePopUp, maintenanceOrder, plant_id }: any) => {
  const navigate=useNavigate()
  
  const handleDelete = () => {
    deleteEntry({ plant_id: plant_id , moID: maintenanceOrder._id })
      .unwrap()
      .then(() => {
        closePopUp()
        navigate('/maintenance/maintenance-order')

        toast.success(`The Order ${maintenanceOrder.orderID} is removed successfully.`)
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  const [deleteEntry, { isLoading: deleteLoading }] = useDeleteMaintenanceOrderMutation()
  return (
    <div>
      <p className="p-xs-regular">
        You are about to delete Order{' '}
        <span className="break-words pr-1 p-xs-bold"> "{maintenanceOrder?.orderID}”.</span>
        Are you sure you want to proceed?
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={handleDelete} loading={deleteLoading}>
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteMaintenanceOrderPopup
