import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import { formatNumber } from 'utils/numberHandlers'
import {
  formatUnixDate,
  formatUnixDateWithTime,
  formatUnixTime,
} from 'utils/dateTimeHandlers'
import checkboxTrue from 'assets/icons/misc/checkboxTrue.svg'
import checkboxFalse from 'assets/icons/misc/checkboxFalse.svg'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { hasAccess } from 'utils/utils'
import { useNavigate } from 'react-router-dom'

const useEntriesColumns = ({
  toggleDeleteEntryPopUp,
  AllfieldDetails,
  toggleEditPanel,
  handleShowEntries,
}: any) => {
  const plant = useSelector(selectSelectedItem)
  const reviewAccess = hasAccess('rev', { rev: 'e' })
  const reviewViewAccess = hasAccess('rev', { rev: 'v' })
  const navigate = useNavigate()
  const allProductsColumns = [
    {
      Header: 'Products',
      accessor: 'product',
      Cell: ({ value }: any) => <span>{value?.name}</span>,
      disableSortBy: true,
    },
    {
      Header: 'Product Category',
      accessor: 'product_type',
      Cell: ({ value }: any) => (
        <span className="flex ">
          <span
            className={`rounded-[28px] px-3 py-1 ${
              value == 'INPUT'
                ? 'bg-warning-100 text-warning-900'
                : ' bg-primary-200 text-primary'
            }`}
          >
            {value == 'INPUT' ? 'input' : 'Ouput'}
          </span>
        </span>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Work Orders',
      accessor: 'work_order',
      Cell: ({ value }: any) => (
        <span
          className="hyperlink"
          onClick={() => {
            value?._id && navigate(`/planning/work-orders/view/${value?._id}`)
          }}
        >
          {value?.workOrderID}
        </span>
      ),
      disableSortBy: true,
    },

    {
      Header: 'Quantity',
      align: 'right',
      accessor: 'quantity',

      Cell: ({ value }: any) => (
        <span className="flex w-full justify-end pr-2 font-mono">
          {formatNumber(value)}
        </span>
      ),
      width: 30,
      disableSortBy: true,
    },
    {
      Header: <div>Unit</div>,
      accessor: 'product.unit_of_measure',
      Cell: ({ row }: any) => {
        return (
          <span className="">{row?.original?.product?.unit_of_measure}</span>
        )
      },
      width: 30,
      disableSortBy: true,
    },
    {
      Header: 'Updated by',
      accessor: 'updated_by',

      Cell: ({ row }: any) => {
        const updatedBy = row.original.updated_by
        const createdBy = row.original.created_by
        const by = updatedBy ? updatedBy : createdBy
        const name = by ? `${by.first_name} ${by.last_name}` : 'Unknown'
        const time = formatUnixDateWithTime(
          updatedBy ? row.original.updated_on : row.original.created_on,
          plant?.timezone,
        )
        return (
          <div>
            <div>{name}</div>
            <div className="p-xs-regular-dim">{time}</div>
          </div>
        )
      },
    },
    {
      Header: 'Verified by',
      accessor: 'verified_by',
      disableSortBy: true,

      Cell: ({ row }: any) => {
        const verifiedBy = row.original.verified_by
        if (verifiedBy) {
          const name = `${verifiedBy?.first_name} ${verifiedBy?.last_name}`
          const time = formatUnixDateWithTime(
            row.original.verified_on,
            plant?.timezone,
          )
          return (
            <div>
              <div>{name}</div>
              <div className="p-xs-regular-dim">{time}</div>
            </div>
          )
        } else {
          return (
            <div className="m-1 w-[4rem] rounded-[28px] bg-[#F8D7DA] px-2 py-0.5 text-xs text-[#8E0000]">
              Pending
            </div>
          )
        }
      },
    },
    {
      Header: 'More Details ',
      Cell: ({ row }: any) => (
        <span
          className={` cursor-pointer  text-primary-700 underline`}
          onClick={(event) => {
            event.stopPropagation()
            reviewViewAccess && handleShowEntries(row.original)
          }}
        >
          Show Details
        </span>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 50,
      Cell: ({ row }: any) => {
        const verifiedBy = row.original.verified_by
        return (
          <div className="flex justify-end gap-1 ">
            <button
              className={`${!verifiedBy && reviewAccess ? '' : 'opacity-40'}`}
              onClick={
                !verifiedBy && reviewAccess
                  ? (event) => {
                      event.stopPropagation()
                      reviewAccess && toggleEditPanel(row?.original)
                    }
                  : undefined
              }
            >
              <Pen />
            </button>
            <button
              className={`${!verifiedBy ? '' : 'opacity-40'}`}
              onClick={
                !verifiedBy
                  ? (event) => {
                      event.stopPropagation()
                      reviewAccess && toggleDeleteEntryPopUp(row?.original)
                    }
                  : undefined
              }
            >
              <Bin />
            </button>
          </div>
        )
      },

      disableSortBy: true,
    },
  ]

  const createColumnObject = (fieldDetails: any) => {
    return fieldDetails.map((field: any) => ({
      Header: field?.name,
      Cell: ({ row }: any) => {
        const data = Array.isArray(row.original?.data) ? row.original.data : []
        const matchingData = data?.find(
          (dataItem: any) => dataItem.field_id === field.id,
        )

        if (matchingData?.field_info?.type === 'Date selection') {
          const { value } = matchingData
          let dates: any = []

          if (Array.isArray(value)) {
            dates = value
          } else if (value) {
            dates = [value]
          }

          if (dates.length === 1) {
            return formatUnixDate(dates[0])
          } else if (dates.length === 2) {
            return `${formatUnixDate(dates[0])} - ${formatUnixDate(dates[1])}`
          } else {
            return '-'
          }
        } else if (matchingData?.field_info?.type === 'Text input') {
          if (matchingData.value !== '') {
            let result = ''
            if (matchingData?.field_info?.has_prefix) {
              result += matchingData?.field_info.prefix_text
            }
            result += matchingData.value
            if (matchingData?.field_info?.has_suffix) {
              result += matchingData?.field_info.suffix_text
            }
            return result
          } else {
            return '-'
          }
        } else if (matchingData?.field_info?.type === 'Time selection') {
          if (matchingData.value !== ' ') {
            return formatUnixTime(matchingData?.value)
          } else {
            return '-'
          }
        } else if (matchingData?.field_info?.type === 'Number input') {
          if (matchingData.value) {
            return formatNumber(matchingData.value)
          } else {
            return '-'
          }
        } else if (matchingData?.field_info?.type === 'Checkbox') {
          return matchingData ? (
            <img
              src={matchingData.value ? checkboxTrue : checkboxFalse}
              alt=""
              style={{ height: `${field.value ? '17px' : '15px'}` }}
            />
          ) : null
        } else {
          return matchingData?.value ? matchingData.value : '-'
        }
      },
    }))
  }
  const dyanmicFieldColumns = createColumnObject(AllfieldDetails)

  const singleProductColumns = [
    {
      Header: 'Work Orders',
      accessor: 'work_order',
      Cell: ({ value }: any) => (
        <span
          className="hyperlink"
          onClick={() => {
            value?._id && navigate(`/planning/work-orders/view/${value?._id}`)
          }}
        >
          {value?.workOrderID}
        </span>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Warehouse',
      accessor: 'warehouse',
      Cell: ({ row }: any) => (
        <span>
          {row.original.product_type === 'INPUT'
            ? row.original.warehouse_from.name
            : row.original.warehouse_to.name}
        </span>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Storage area',
      accessor: 'storage_area',
      Cell: ({ row }: any) => (
        <span>
          {row.original.product_type === 'INPUT'
            ? row.original?.storage_area_from?.name
            : row.original?.storage_area_to?.name}
        </span>
      ),
      disableSortBy: true,
    },

    {
      Header: 'Quantity',
      accessor: 'quantity',
      align: 'right',
      Cell: ({ value }: any) => (
        <span className="font-mono">{formatNumber(value)}</span>
      ),
      width: 30,
      disableSortBy: true,
    },
    // {
    //   Header: 'Unit',
    //   accessor: 'unit_of_measure',
    //   align: 'left',
    //   width: 30,
    //   disableSortBy: true,
    // },
    ...dyanmicFieldColumns,
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 10,
      Cell: ({ row }: any) => {
        const verifiedBy = row.original.verified_by
        return (
          <div className="flex justify-end gap-1 ">
            <button
              className={`${!verifiedBy ? '' : 'opacity-40'}`}
              onClick={
                !verifiedBy
                  ? (event) => {
                      event.stopPropagation()
                      toggleEditPanel(row?.original)
                    }
                  : undefined
              }
            >
              <Pen />
            </button>
            <button
              className={`${!verifiedBy ? '' : 'opacity-40'}`}
              onClick={
                !verifiedBy
                  ? (event) => {
                      event.stopPropagation()
                      toggleEditPanel(row?.original)
                    }
                  : undefined
              }
            >
              <Bin />
            </button>
          </div>
        )
      },
      disableSortBy: true,
    },
  ]
  return { singleProductColumns, allProductsColumns }
}

export default useEntriesColumns
