function NextPageArrow() {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.50016 4.99984L0.250163 1.74984C0.097385 1.59706 0.0209961 1.40262 0.0209961 1.1665C0.0209961 0.930393 0.097385 0.735948 0.250163 0.583171C0.402941 0.430393 0.597385 0.354004 0.833496 0.354004C1.06961 0.354004 1.26405 0.430393 1.41683 0.583171L5.25016 4.4165C5.3335 4.49984 5.39252 4.59012 5.42725 4.68734C5.46197 4.78456 5.47933 4.88873 5.47933 4.99984C5.47933 5.11095 5.46197 5.21512 5.42725 5.31234C5.39252 5.40956 5.3335 5.49984 5.25016 5.58317L1.41683 9.4165C1.26405 9.56928 1.06961 9.64567 0.833496 9.64567C0.597385 9.64567 0.402941 9.56928 0.250163 9.4165C0.097385 9.26373 0.0209961 9.06928 0.0209961 8.83317C0.0209961 8.59706 0.097385 8.40262 0.250163 8.24984L3.50016 4.99984Z"
        fill="#041220"
      />
    </svg>
  )
}

export default NextPageArrow
