import { toastSuccess } from 'libs/toast/toastSucess'
import { toastFailure } from 'libs/toast/toastFailure'
import { useState, useRef, useEffect, ChangeEvent, FormEvent } from 'react'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'
import { useGetProductQuery } from '../products-endpoints/products.endpoints'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import {
  usePostProductMutation,
  usePostBomMutation,
  useEditBomMutation,
} from '../products-endpoints/products.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import Button from 'libs/button/Button'
import { trimStringValues } from 'utils/stringHandlers'
import { AddBomProps, Product, Category } from './AddBomTypes'

//dummy data until API is available
const categoryData = [
  { name: 'Finished Goods', code: '', _id: '' },
  { name: 'Raw Materials', code: '', _id: '' },
]

//this module is for adding and editing BOM

const AddBom: React.FC<AddBomProps> = ({
  removeEntry,
  productId,
  data,
  isEdit,
}) => {
  const popupRef = useRef<HTMLDivElement>(null)
  const selectedItem = useSelector(selectSelectedItem)
  const [formData, setFormData] = useState({
    quantity: data?.quantity || 0,
    uom: data?.unit || '',
    newProduct: data?.product || '',
    newCode: data?.code || '',
  })

  const [errors, setErrors] = useState<Record<string, string>>({})
  const [isCategoryDropdownVisible, setIsCategoryDropdownVisible] =
    useState(false)
  const [isProductDropdownVisible, setIsProductDropdownVisible] =
    useState(false)
  const [productSearchInput, setProductSearchInput] = useState<Product | any>(
    '',
  )
  const [categorySearchInput, setCategorySearchInput] = useState<
    Category | string
  >('')

  const [filteredCategoryData, setFilteredCategoryData] = useState(categoryData)
  const [addProduct, setAddProduct] = useState(false)

  const [addProducts] = usePostProductMutation()
  const [addBom, { isLoading: addBomLoading }] = usePostBomMutation()

  const filters = {
    page_no: 1,
    page_size: 1000,
    // category: 'ALL',
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: product } = useGetProductQuery(
    {
      plantId: selectedItem?.id,
      filters: filters,
    },
    {
      skip: !selectedItem?.id,
    },
  )

  const [filteredProductData, setFilteredProductData] = useState<Product[]>([])

  useEffect(() => {
    if (product) {
      const filtered = product?.data?.filter(
        (p: Product) => p._id !== productId,
      )
      setFilteredProductData(filtered)
    }

    if (isEdit && product) {
      const [findProduct] = product?.data?.filter(
        (id: Product) => id?._id === data?.bom_product_details?._id,
      )
      setProductSearchInput(findProduct)
    }
  }, [product])

  const [editBom, { isLoading: editBomLoading }] = useEditBomMutation()

  const handleTitleClick = (
    title: Category | Product,
    dropdownNumber: number,
  ) => {
    switch (dropdownNumber) {
      case 2:
        setCategorySearchInput(title)
        setIsCategoryDropdownVisible(false)
        setErrors({ ...errors, measurementType: '', category: '' })
        break
      case 3:
        setProductSearchInput(title)
        setIsProductDropdownVisible(false)
        setErrors({ ...errors, product: '' })
        break
      default:
        break
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Check if the click is outside the popup
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.ignoreref')
      ) {
        setIsCategoryDropdownVisible(false)
        setIsProductDropdownVisible(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleInputClick = (dropdownNumber: number) => {
    switch (dropdownNumber) {
      case 2:
        setIsCategoryDropdownVisible(!isCategoryDropdownVisible)
        break
      case 3:
        setIsProductDropdownVisible(!isProductDropdownVisible)
        setIsCategoryDropdownVisible(false)
        break
      default:
        break
    }
  }

  const filterData = (data: (Category | Product)[], searchValue: string) => {
    return data?.filter(
      (item) =>
        item.name.toLowerCase().includes(searchValue) ||
        (item.code && item.code.toLowerCase().includes(searchValue)),
    )
  }

  const handleSearchInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    inputType: string,
  ) => {
    const searchValue = event.target.value.toLowerCase()
    switch (inputType) {
      case 'product':
        setProductSearchInput(searchValue)
        setFilteredProductData(filterData(product?.data, searchValue))
        break
      case 'category':
        setCategorySearchInput(searchValue)
        setFilteredCategoryData(filterData(categoryData, searchValue))
        break
      default:
        break
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newErrors: Record<string, string> = {}
    // Check each field for errors
    if (formData.quantity === 0) {
      newErrors.quantity = 'Quantity cannot be zero.'
    } else if (!formData.quantity) {
      newErrors.quantity = 'Quantity cannot be empty.'
    }
    if (productSearchInput === '') {
      newErrors.product = 'Product cannot be empty.'
    }

    // Set the new errors
    setErrors(newErrors)
    // If there are no errors, submit the form

    if (Object.keys(newErrors).length === 0) {
      const BomData = {
        bom_product_id: (productSearchInput as Product)?._id,
        quantity: parseFloat(formData?.quantity.toString()),
      }
      const trimmedData = trimStringValues(BomData)

      if (!isEdit)
        addBom({
          data: trimmedData,
          productId: productId,
        })
          .unwrap()
          .then(() => {
            toastSuccess(
              `BOM for "${(productSearchInput as Product)?.name}" is added successfully`,
            )
            removeEntry()
          })
          .catch((err) => {
            toastFailure(
              err?.data?.detail ?? 'Something went wrong. Please try again',
            )
          })
      else if (isEdit) {
        editBom({
          productId: productId,
          bomId: data?._id,
          data: trimmedData,
        })
          .unwrap()
          .then(() => {
            toastSuccess(
              `BOM for "${(productSearchInput as Product)?.name}" is updated successfully`,
            )
            removeEntry()
          })
          .catch((err) => {
            toastFailure(
              err?.data?.detail ??
                `BOM for"${(productSearchInput as Product)?.name}" already exists.`,
            )
          })
      }
    }
  }
  const handleAddProduct = (e: any) => {
    e.preventDefault()
    const newErrors: Record<string, string> = {}
    if (formData.newProduct.trim() === '') {
      newErrors.newProduct = 'Product Name cannot be empty.'
    }
    if (formData.newCode.trim() === '') {
      newErrors.newCode = 'Product Code cannot be empty.'
    }
    if (categorySearchInput === '') {
      newErrors.category = 'Category cannot be empty.'
    }

    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      const productData = {
        category: (categorySearchInput as Category).name,
        name: formData.newProduct,
        code: formData.newCode,
        description: '',
        tags: [],
        is_bom: false,
        is_consumable: false,
        is_properties: false,
      }

      const data = trimStringValues(productData)

      addProducts({
        data: data,
        plantId: selectedItem?.id,
      })
        .unwrap()
        .then((res) => {
          toastSuccess(`Product "${formData.newProduct}" is added successfully`)
          handleTitleClick(res, 3)
          setAddProduct(false)
        })
        .catch((err) => {
          toastFailure(
            err?.data?.detail ?? 'Something went wrong. Please try again',
          )
        })
    }
  }
  return (
    <div>
      <div className="mb-2 mt-2 p-xs-regular-dim">
        {t('product.add_material_subtitle')}
      </div>
      <div>
        {addProduct && (
          <>
            {' '}
            <div className=" mb-3 mt-3 "></div>
            <div className=" border-l-2  border-[#E0E0E0] ps-5  ">
              <h2 className="mb-3 mt-3 p-md-bold-dark">
                {t('product.new_product')}
              </h2>
              <div>
                <label className="mb-1 p-sm-regular">
                  {t('product.product_name')}{' '}
                  <span className="text-[#DC3545]">*</span>
                </label>
                <div>
                  <div className="relative py-1">
                    <input
                      required
                      type="text"
                      name="newProduct"
                      value={formData.newProduct}
                      onChange={handleInputChange}
                      className={`h-[28px] w-full border ${
                        errors.newProduct ? 'border-[#DC3545]' : 'border-none'
                      } rounded-md  border bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                      placeholder={t('product.product_name_placeholder')}
                    />
                    {errors.newProduct && (
                      <p className="pt-1 text-xs font-normal text-[#DC3545]">
                        {errors.newProduct}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label className="mb-1 p-sm-regular">
                  {t('product.product_code')}{' '}
                  <span className="text-[#DC3545]">*</span>
                </label>
                <div>
                  <div className="relative py-1">
                    <input
                      required
                      type="text"
                      name="newCode"
                      value={formData.newCode}
                      onChange={handleInputChange}
                      className={`h-[28px] w-full border ${
                        errors.newCode ? 'border-[#DC3545]' : 'border-none'
                      } rounded-md  border bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                      placeholder={t('product.product_code_placeholder')}
                    />
                    {errors.newCode && (
                      <p className="pt-1 text-xs font-normal text-[#DC3545]">
                        {errors.newCode}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className=" relative flex flex-col">
                  <label className="mb-1 p-sm-regular">
                    {t('product.product_category')}{' '}
                    <span className="text-[#DC3545]">*</span>
                  </label>
                  <input
                    required
                    name="category"
                    type="text"
                    className={`ignoreref h-[28px] w-full ${
                      errors.category ? 'border-[#DC3545]' : 'border-none'
                    } cursor-pointer rounded-md 
                          border bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular focus:border-[#1193F0]`}
                    placeholder={t('product.product_category_placeholder')}
                    autoComplete="off"
                    onClick={() => handleInputClick(2)}
                    value={
                      typeof categorySearchInput === 'string'
                        ? categorySearchInput
                        : categorySearchInput?.name
                    }
                    onChange={(e) => handleSearchInputChange(e, 'category')}
                  />
                  <div className="absolute right-[0.5rem] top-[1.9rem]">
                    <ArrowDown />
                  </div>
                  {errors.category && (
                    <p className="pt-1 text-xs font-normal text-[#DC3545]">
                      {errors.category}
                    </p>
                  )}
                  <AnimatePresence>
                    {isCategoryDropdownVisible && (
                      <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={dropdownMotionProps}
                        ref={popupRef}
                        className="absolute top-[3.9rem] max-h-[15rem] min-h-[20px]  w-full overflow-auto rounded bg-[#fff]"
                        style={{
                          zIndex: '3',
                          boxShadow:
                            '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        {filteredCategoryData?.length > 0 ? (
                          filteredCategoryData.map((item) => (
                            <div
                              key={item.name}
                              className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3
                                    py-2 hover:bg-[#f8f8f8]"
                              onClick={() => handleTitleClick(item, 2)}
                            >
                              <div className="p-xs-regular">{item.name}</div>
                              <div className="p-xs-regular-dim">
                                {item.code}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]">
                            <div className="p-xs-regular">
                              Category does not exist
                            </div>
                          </div>
                        )}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              <div className="mt-4 flex justify-end gap-2">
                <Button size="sm" onClick={() => setAddProduct(false)}>
                  {t('cancel')}
                </Button>
                <Button size="sm" color="primary" onClick={handleAddProduct}>
                  Add & Select
                </Button>
              </div>
            </div>
          </>
        )}
        <form onSubmit={handleSubmit}>
          <div>
            <div className="relative mt-5">
              <label className="mb-1 p-sm-regular">
                {t('product.product')} <span className="text-[#DC3545]">*</span>
              </label>
              <input
                required
                name="product"
                type="text"
                className={`ignoreref h-[28px] w-full ${
                  errors.product ? 'border-[#DC3545]' : 'border-none'
                } cursor-pointer rounded-md 
                  border border-solid bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular  focus:border-[#1193F0]`}
                placeholder={t('product.select_material')}
                autoComplete="off"
                onClick={() => handleInputClick(3)}
                value={
                  typeof productSearchInput === 'string'
                    ? productSearchInput
                    : productSearchInput?.name
                }
                onChange={(e) => handleSearchInputChange(e, 'product')}
              />
              <div className="absolute right-[0.5rem] top-[1.9rem]">
                <ArrowDown />
              </div>
              {errors.product && (
                <p className="pt-1 text-xs font-normal text-[#DC3545]">
                  {errors.product}
                </p>
              )}
              <AnimatePresence>
                {isProductDropdownVisible && (
                  <motion.div
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={dropdownMotionProps}
                    ref={popupRef}
                    className="absolute max-h-[15rem] min-h-[20px] w-[380px] overflow-auto rounded bg-[#fff]"
                    style={{
                      zIndex: '3',
                      boxShadow:
                        '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    {!isEdit && false && (
                      <div className="cursor-pointer flex-col gap-3 rounded border-b border-[#EBEFF3] px-1.5 py-1.5">
                        <div
                          className="flex h-8 w-full cursor-pointer items-center justify-center bg-[#EBEFF3] p-md-regular "
                          onClick={() => {
                            setAddProduct(true)
                            setIsProductDropdownVisible(false)
                          }}
                        >
                          + {t('product.new_product')}
                        </div>
                      </div>
                    )}
                    {filteredProductData?.length > 0 ? (
                      filteredProductData.map((item) => (
                        <div
                          key={item._id}
                          className={`cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]`}
                          onClick={() => handleTitleClick(item, 3)}
                        >
                          <div className="p-xs-regular">{item.name}</div>
                          <div className="p-xs-regular-dim">{item.code}</div>
                        </div>
                      ))
                    ) : (
                      <div className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]">
                        <div className="p-xs-regular">
                          Product does not exist
                        </div>
                      </div>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <div className="mt-2">
            <label className="mb-1 p-sm-regular">
              {t('product.quantity')} <span className="text-[#DC3545]">*</span>
            </label>
            <div>
              <div className="relative flex items-center py-1">
                <input
                  required
                  type="number"
                  name="quantity"
                  value={formData.quantity}
                  min={productSearchInput?.is_accepting_decimal ? '' : 0}
                  onChange={handleInputChange}
                  className={`h-[28px] w-full border ${
                    errors.quantity ? 'border-[#DC3545]' : 'border-none'
                  } rounded-l-md bg-[#EBEFF3] p-2 text-sm outline-none p-xs-regular`}
                  placeholder={t('product.enter_quantity')}
                />
                <div className="flex h-7 items-center truncate rounded-r-md bg-[#434C52] px-2 text-[#F8FCFF] p-xs-regular-dim1">
                  {productSearchInput?.unit_of_measure}
                </div>
              </div>
              {errors.quantity && (
                <p className="pt-1 text-xs font-normal text-[#DC3545]">
                  {errors.quantity}
                </p>
              )}
            </div>
          </div>

          <div className="mb-8 mt-6 flex justify-end gap-2">
            <div>
              <Button type="button" onClick={removeEntry}>
                {t('cancel')}
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                type="submit"
                loading={addBomLoading || editBomLoading}
              >
                {isEdit ? t('save') : t('add')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddBom
