import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Wizard from 'libs/wizard/Wizard'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'
import { selectSelectedItem } from 'store/redux/navSlice'
import ViewBasicDetails from './ViewBasicDetails'
import ViewAddressDetails from './ViewAddressDetails'
import ViewSalesOrders from './ViewSalesOrders'
import { useGetCustomerByIDQuery } from '../customer-endpoints/customer.endpoints'

const tabs = [
  {
    tabTitle: 'Basic Details',
    routeName: 'basic-details',
    component: <ViewBasicDetails />,
  },
  {
    tabTitle: 'Address Details',
    routeName: 'address-details',
    component: <ViewAddressDetails />,
  },
  {
    tabTitle: 'Sales Orders',
    routeName: 'sales-order',
    component: <ViewSalesOrders />,
  },
]

const CustomerDetails = () => {
  useTabTitle('View Customer Details')
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)

  const { data: customer, isLoading: loading } = useGetCustomerByIDQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <>
      {customer && (
        <>
          <h1 className="mb-2 text-2xl font-bold">{customer.name}</h1>
          <Wizard tabs={tabs} type="tabs" />
        </>
      )}
    </>
  )
}

export default CustomerDetails
