import SidePanel from 'libs/sidepanel/SidePanel'
import Tooltip from 'libs/tool-tip/Tooltip'
import { useState } from 'react'

const Tags = ({
  data,
  remainingCount,
  remaining,
  color,
  bgColor,
  hideTooltip,
  showPanelComponent: ShowPanelComponent,
  title,
  id,
}: any) => {
  const [showFilter, setShowFilter] = useState(false)

  return data?.length > 0 ? (
    <div className="flex w-full items-center whitespace-nowrap">
      {data?.map((name: any, index: any) => (
        <span
          key={index}
          className={`m-1 rounded-[28px]  px-2 py-0.5`}
          style={{ color: color, backgroundColor: bgColor }}
        >
          {name}
        </span>
      ))}
      <></>
      {remaining?.length > 0 && (
        <Tooltip
          content={
            remaining?.length > 0 ? (
              remaining?.map((name: any, index: number) => (
                <div key={index}>
                  <span>• </span>
                  <span>{name}</span>
                </div>
              ))
            ) : (
              <p>No name available</p>
            )
          }
          position="left"
          hideTooltips={hideTooltip ?? false}
        >
          <span
            className={`m-1 cursor-pointer rounded-[28px] px-2 py-0.5 text-[#0B5ED7] underline`}
            onClick={() => setShowFilter(!showFilter)}
          >
            +{remainingCount} More
          </span>
          {ShowPanelComponent != null && (
            <SidePanel
              size="large"
              isOpen={showFilter}
              title={title}
              onClose={() => {
                setShowFilter(false)
              }}
            >
              <ShowPanelComponent
                closePanel={() => {
                  setShowFilter(false)
                }}
                id={id}
              />
            </SidePanel>
          )}
        </Tooltip>
      )}
    </div>
  ) : (
    <div>-</div>
  )
}

export default Tags
