import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  GetPlantsRequest,
  GetPlantsResponse,
  PostPlantsRequest,
  PostPlantsResponse,
} from './plantsTypes'

export const plant = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postPlants: builder.mutation<PostPlantsResponse, PostPlantsRequest>({
      query: ({ data, org_id }) => {
        return {
          url: `/org/${org_id}/plant`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['PlantsUpdate', 'UserInfo'],
    }),
    getPlants: builder.query<GetPlantsResponse, GetPlantsRequest>({
      query: ({ org_id, filters }) => {
        const URL = buildUrl(`/org/${org_id}/plant`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the plant ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['PlantsUpdate'],
    }),
    getCountry: builder.query<any, void>({
      query: () => {
        return {
          url: '/utils/country',
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the country')
            }
            return response.json()
          },
        }
      },
    }),
    getTimezone: builder.query<any, any>({
      query: ({ filters }) => {
        const URL = buildUrl(`/utils/timezone/`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the country')
            }
            return response.json()
          },
        }
      },
    }),
    editPlant: builder.mutation({
      query: ({ data, plantId }) => ({
        url: `plant/${plantId}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['PlantsUpdate', 'UserInfo'],
    }),
    deletePlant: builder.mutation({
      query: ({ plantId }) => ({
        url: `plant/${plantId}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['PlantsUpdate', 'UserInfo'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPlantsQuery,
  useDeletePlantMutation,
  useEditPlantMutation,
  useGetCountryQuery,
  usePostPlantsMutation,
  useGetTimezoneQuery,
} = plant
