import { useEffect } from 'react'
import Button from 'libs/button/Button'
import SearchBox from 'libs/searchbox/SearchBox'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PaginatedTable from 'libs/table/PaginatedTable'
import Bin from 'assets/icons/camera-page/Bin'
import Popup from 'libs/popup/Popup'
import { useGetAllEntriesByProcessIdQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import useEntriesColumns from './EntriesDashboardColumns'
import useAddOptionsData from './useAddOptionsData'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useLocation } from 'react-router-dom'
import { t } from 'i18next'
import ApproveByPopUp from '../component/ApproveByPopUp'
import MotionDiv from 'libs/motionDiv'
import FullPageLoader from 'libs/loader/FullPageLoader'
import WhiteCard from 'libs/card/WhiteCard'
import FilterProduction from './panel/FilterProduction'
import SidePanel from 'libs/sidepanel/SidePanel'
import ShowEntriesPanel from '../component/ShowEntriesPanel'
import { useDebouncedSearch } from 'libs/hooks/useSearch'
import Filter from 'assets/icons/camera-page/Filter'
import { formatNumber } from 'utils/numberHandlers'
import useTabTitle from 'libs/hooks/useTabTitle'
import DeleteEntryPopUp from '../component/DeleteEntryPopUp'
import ProductTab from '../component/ProductTab'
import { hasAccess } from 'utils/utils'

export interface Product {
  name: string
  _id: string
  code: string
}

interface DeleteState {
  state: boolean
  ids: string[]
}

const EntriesDashboard = () => {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const isReview = location.pathname.includes('review')
  const [batchData, setBatchData] = useState<string[]>([])
  const [approveSelectedEntries, setApproveSelectedEntries] = useState(false)
  const [isShowFilter, setIsShowFilter] = useState(false)
  const { searchValue, handleSearch, inputValue } = useDebouncedSearch(800)
  const reviewAccess =
    hasAccess('rev', { rev: 'e' }) || hasAccess('pro', { pro: 'e' })

  //Search functionality
  useEffect(() => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: 1,
      searchText: searchValue ? searchValue : '',
    }))
  }, [searchValue])

  // *************************** Show All Entries  ************************************ //
  const {
    activeTab,
    tabs,
    handleTabClick,
    process_name,
    AllfieldDetails,
    department_name,
    outputOptions,
    inputOptions,
  } = useAddOptionsData(params.process)

  useTabTitle(isReview ? `${t('review')}  ${process_name} ` : process_name)

  const [filters, setFilters] = useState<any>({
    page_no: 1,
    page_size: 10,
    searchText: '',
    category: 'ALL',
    quantityBetween: [],
    updatedBetween: [],
    is_verified: 'ALL',
    sortBy: 'verified_on',
    sortDir: 'DESC',
  })
  const {
    data: allEntriesByProcessId,
    isLoading,
    isFetching,
  } = useGetAllEntriesByProcessIdQuery({
    process_id: params?.process ? params.process : '',
    ...filters,
    product_id: activeTab._id,
  })

  // Active filters count
  const activeFiltersCount = [
    filters.updatedBetween?.length > 0 ? 1 : 0,
    filters.quantityBetween?.length > 0 ? 1 : 0,
    filters?.category !== 'ALL' ? 1 : 0,
    filters?.is_verified !== 'ALL' ? 1 : 0,
  ].reduce((sum, count) => sum + count, 0)

  // *************************** Show All Entries  ************************************ //

  // *************************** Pagination & filter  ************************************ //

  const [key, setKey] = useState(0)

  const handleEntrieData = (page_no: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: page_no,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  const handlePageNumberChange = (newPageSize: any) => {
    const totalItemsCount =
      allEntriesByProcessId?.paging?.total_items_count || 0
    const currentPage = filters.page_no
    const startIndex = (currentPage - 1) * filters.page_size + 1
    const totalPages = Math.ceil(totalItemsCount / newPageSize)
    let newPageNo = Math.ceil(startIndex / newPageSize)
    newPageNo = Math.min(newPageNo, totalPages)
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      page_no: newPageNo,
      page_size: newPageSize,
    }))
    setKey((prevKey) => prevKey + 1)
  }

  // *************************** Pagination & filter End ************************************ //
  const [isShowPanel, setIsShowPanel] = useState(false)

  const [deleteState, setDeleteState] = useState<DeleteState>({
    state: false,
    ids: [],
  })

  const toggleDeleteEntryPopUp = (data: any) => {
    if (!deleteState.state) {
      setDeleteState({ state: true, ids: [data?._id] })
    } else {
      setDeleteState({ state: false, ids: [] })
    }
  }

  const toggleEditPanel = (data: any) => {
    navigate(`${data?.product_type?.toLowerCase() ?? 'input'}/${data._id}`, {
      state: { entryData: data, type: data.product_type },
    })
  }

  const toggleApprovePopup = () => {
    setApproveSelectedEntries(!approveSelectedEntries)
  }

  //**********************************************************//
  const [selectedProduct, setSelectedProductData] = useState('')

  const handleShowEntries = (data: any) => {
    setSelectedProductData(data)

    setIsShowPanel(!isShowPanel)
  }

  // *************************** Columns ************************************ //

  const { singleProductColumns, allProductsColumns } = useEntriesColumns({
    toggleDeleteEntryPopUp,
    AllfieldDetails,
    toggleEditPanel,
    handleShowEntries,
  })

  // *************************** Columns End ************************************ //

  // *************************** checkbox start ************************************ //
  const handleCheckboxClick = (data: any) => {
    const entryId = data?._id
    const isChecked = batchData.includes(entryId)
    if (isChecked) {
      setBatchData((prev) => {
        return prev.filter((id) => id !== entryId)
      })
    } else {
      setBatchData((prev) => [...prev, entryId])
    }
  }

  const handleSelectAllRows = (data: any) => {
    const entryIds = data
      ?.filter((entry: any) => entry?.original?.verified_by === null)
      .map((entry: any) => entry.original?._id)

    setBatchData(entryIds)
    if (entryIds.length === batchData.length) {
      setBatchData([])
    }
  }

  const handleEntrySelection = (row: any) => {
    if (row?.verified_by === null) {
      handleCheckboxClick(row) // Trigger checkbox click on row click
    }
  }

  // *************************** checkbox end ************************************ //

  // *************************** count of not approve entry ************************************ //
  const countNotVerifiedBy: any = allEntriesByProcessId?.data.filter(
    (item: any) => item.verified_by === null,
  ).length

  return (
    <MotionDiv>
      <>
        {isLoading ? (
          <FullPageLoader />
        ) : (
          <WhiteCard>
            {/* header */}
            <div className=" flex w-full justify-between">
              <div className="flex items-center justify-between ">
                <h3 className="max-w-[500px] truncate pr-2 h5">
                  {process_name}
                </h3>

                {isReview &&
                  (countNotVerifiedBy > 0 ? (
                    <div className="m-1 w-[10rem] rounded-[28px] bg-[#F8D7DA] px-2 py-1 text-xs text-[#8E0000] ">
                      {formatNumber(countNotVerifiedBy)} Pending verifications
                    </div>
                  ) : (
                    <div className="m-1 w-[10rem] rounded-[28px] bg-[#D1E7DD] px-2 py-1 text-xs text-[#015314] ">
                      0 Pending verifications
                    </div>
                  ))}
              </div>
              <div className="flex w-full  justify-end gap-3  ">
                <SearchBox
                  value={inputValue}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search by product name"
                  onClearSearchBox={() => {
                    setFilters((prevFilters: any) => ({
                      ...prevFilters,
                      searchText: '',
                    }))
                    handleSearch('')
                  }}
                />
                <Button
                  onClick={() => setIsShowFilter(true)}
                  startIcon={<Filter color="#3D4140" />}
                >
                  Filters{' '}
                  {activeFiltersCount === 0 ? '' : `(${activeFiltersCount})`}
                </Button>
                <SidePanel
                  isOpen={isShowFilter}
                  title="Filters"
                  size="large"
                  subTitle="Filter the data according to your requirements"
                  onClose={() => {
                    setIsShowFilter(false)
                  }}
                >
                  <FilterProduction
                    closePanel={() => {
                      setIsShowFilter(false)
                    }}
                    filters={filters}
                    setFilters={setFilters}
                  />
                </SidePanel>
                {reviewAccess && (
                  <>
                    <Button
                      color="primary"
                      onClick={() => {
                        navigate('input')
                      }}
                      startIcon={<PlusIcon />}
                      disabled={inputOptions?.length > 0 ? false : true}
                    >
                      Add Input
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => {
                        navigate('output')
                      }}
                      startIcon={<PlusIcon />}
                      disabled={outputOptions?.length > 0 ? false : true}
                    >
                      Add Output
                    </Button>
                  </>
                )}
              </div>
            </div>
            <hr></hr>
            <div className="flex flex-wrap gap-3 ">
              {tabs.map((tab) => (
                <ProductTab
                  key={tab._id}
                  onClick={() => handleTabClick(tab)}
                  tabText={tab.name}
                  isActive={activeTab.name === tab.name}
                />
              ))}
            </div>
            <div className={`${isReview ? 'mb-12' : ''}`}>
              <PaginatedTable
                onPageNumberChange={handleEntrieData}
                key={key}
                columns={
                  activeTab._id == ''
                    ? allProductsColumns
                    : singleProductColumns
                }
                rows={allEntriesByProcessId?.data || []}
                currentPageNumber={filters.page_no}
                pageSize={filters.page_size}
                sorting={{ sortBy: filters.sortBy, sortDir: filters.sortDir }}
                onPageSizeChange={handlePageNumberChange}
                onSingleClick={handleEntrySelection}
                loading={isFetching}
                pagination={allEntriesByProcessId?.paging}
                emptyMessage="No Entries added to show. "
                onDoubleClick={() => {}}
                showCheckbox={isReview}
                batchData={batchData}
                onSelectAll={handleSelectAllRows}
              />
            </div>
          </WhiteCard>
        )}

        <Popup
          isOpen={approveSelectedEntries}
          title="Approve selected entries"
          onClose={() => {
            setApproveSelectedEntries(false)
          }}
        >
          <ApproveByPopUp
            data={batchData}
            closePopUp={toggleApprovePopup}
            setBatchData={setBatchData}
            departmentName={department_name}
            processName={process_name}
          />
        </Popup>

        <Popup
          isOpen={deleteState.state}
          title="Delete Entry Details"
          onClose={() => {
            setDeleteState({ state: false, ids: [] })
          }}
        >
          <DeleteEntryPopUp
            closePopUp={toggleDeleteEntryPopUp}
            deleteState={deleteState}
            batchData={batchData}
            setBatchData={setBatchData}
            setDeleteState={setDeleteState}
            departmentName={department_name}
            processName={process_name}
          />
        </Popup>

        <SidePanel
          isOpen={isShowPanel}
          title={process_name}
          size="medium"
          onClose={() => setIsShowPanel(false)}
        >
          <ShowEntriesPanel data={selectedProduct} />
        </SidePanel>

        {isReview && reviewAccess && (
          <div
            className="fixed bottom-[0] left-[92px] flex h-[4rem] w-[100%] items-center gap-4 bg-[#fff]"
            style={{ zIndex: 1 }}
          >
            <div className="pl-10 p-sm-regular">
              {formatNumber(batchData?.length)} {t('selected')}
            </div>
            <Button
              onClick={toggleApprovePopup}
              className={`shadow-sm p-xs-regular btn-primary `}
              disabled={batchData?.length === 0}
            >
              {t('approve_selected')}
            </Button>
            <Button
              className={`shadow-sm p-xs-regular btn-cancel ${batchData?.length > 0 ? '' : 'opacity-70 '}`}
              onClick={toggleDeleteEntryPopUp}
              disabled={batchData?.length === 0}
              startIcon={<Bin />}
            >
              {t('delete_selected')}
            </Button>
          </div>
        )}
      </>
    </MotionDiv>
  )
}

export default EntriesDashboard
