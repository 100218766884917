import React from "react";
import "./loader.scss";
interface MiniSpinLoaderProps {
  width?: number;
  height?: number;
  idProp?: string;
  color?: string;
}
const MiniSpinLoader: React.FC<MiniSpinLoaderProps> = ({
  width = 14,
  height = 14,
  idProp = "spinning-small-circle",
  color = "white",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      id={idProp}
    >
      <path
        d="M12.7203 8.63727C13.2778 8.79684 13.6074 9.38289 13.3664 9.91029C12.8195 11.1066 11.9432 12.1315 10.8304 12.859C9.42296 13.7791 7.73131 14.1619 6.06485 13.9373C4.39838 13.7126 2.86846 12.8956 1.75489 11.6356C0.641328 10.3756 0.0185319 8.7569 0.000406662 7.07545C-0.0177186 5.39401 0.570038 3.76223 1.65618 2.47854C2.74233 1.19484 4.25428 0.345032 5.91552 0.0845173C7.57676 -0.175997 9.27626 0.170197 10.7032 1.05979C11.8314 1.76312 12.7296 2.76895 13.3021 3.95315C13.5545 4.47523 13.2376 5.06825 12.6837 5.2398C12.1297 5.41136 11.5508 5.09299 11.2654 4.58821C10.865 3.8802 10.2918 3.27795 9.59226 2.84185C8.59338 2.21914 7.40373 1.9768 6.24086 2.15916C5.078 2.34152 4.01963 2.93639 3.25933 3.83497C2.49903 4.73356 2.0876 5.87581 2.10028 7.05282C2.11297 8.22983 2.54893 9.36294 3.32843 10.2449C4.10792 11.1269 5.17886 11.6988 6.34539 11.8561C7.51192 12.0133 8.69607 11.7454 9.68129 11.1013C10.3713 10.6502 10.9314 10.0358 11.3164 9.31929C11.5909 8.80847 12.1628 8.4777 12.7203 8.63727Z"
        fill={color}
      />
    </svg>
  );
};

export default MiniSpinLoader;
