import {
  useGetProductionPlanQuery,
  useUpdatePlanStatusMutation,
} from '../production-plans-endpoints/productionPlan.endpoints'
import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useParams } from 'react-router-dom'

interface Props {
  closePopUp: any
  pID: any
  plant_id: string
  moData: any
  statusValue: any
}

function StartPlan({ closePopUp, pID, plant_id, moData, statusValue }: Props) {
  const { id } = useParams()
  const [status, { isLoading: loadingStatus }] = useUpdatePlanStatusMutation()
  const filters = {
    planID: id,
    page_no: 1,
    page_size: 10,
    sortBy: 'created_on',
    sortDir: 'DESC',
  }
  const { data } = useGetProductionPlanQuery(
    {
      plantId: plant_id,
      filters: filters,
    },
    {
      skip: !plant_id,
    },
  )

  const checkAllStatuses = (data: any) => {
    // Check if all workOrders have status "COMPLETED"
    const allCompleted = data.every((order: any) => order.status === 'COMPLETED')

    return allCompleted
  }

  const handleStatus = () => {
    if (statusValue === 'IN_PROGRESS' && checkAllStatuses(moData)) {
      status({ plant_id, pID: pID?._id })
        .unwrap()
        .then(() => {
          closePopUp()
          toast.success(`Production Plans “${pID?.planID}” is completed successfully.`)
        })
        .catch((error: any) => {
          toast.error(error?.data?.detail ?? `Failed to Update Status for “${pID?.planID}”. Please try again.`)
        })
    } else if (statusValue === 'NOT_STARTED') {
      status({ plant_id, pID: pID?._id })
        .unwrap()
        .then(() => {
          closePopUp()
          toast.success(`Production Plan “${pID?.planID}” is started successfully.`)
        })
        .catch((error: any) => {
          toast.error(error?.data?.detail ?? `Failed to Update Status for “${pID?.planID}”. Please try again.`)
        })
    } else {
      toast.error(
        'Ensure that all tasks in the Manufacturer module are fully completed before proceeding to finalize the Production Plans.',
      )
    }
  }

  return (
    <>
      {data?.data[0]?.status === 'IN_PROGRESS' ? (
        <div>
          <p className="p-xs-regular">
            You are about to mark the plan <span className="break-words pr-1 p-xs-bold">“{pID?.planID} Plan”.</span>
            as complete. Operators will not be able to add further entries for this plan. However, admins will still be
            able to make any adjustments if required. Are you sure you want to proceed?
          </p>
          <div className="mt-4 flex justify-end gap-3">
            <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
            <Button color="primary" onClick={handleStatus}>
              Mark as complete
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <p className="p-xs-regular">
            You are about to start production for the plan{' '}
            <span className="break-words pr-1 p-xs-bold">“{pID?.planID} Plan”.</span>
            This will allow operators to add entries for this plan. Are you sure you want to proceed?
          </p>
          <div className="mt-4 flex justify-end gap-3">
            <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
            <Button color="primary" onClick={handleStatus} loading={loadingStatus}>
              Start Plan
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default StartPlan
