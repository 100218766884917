import { rootReducer } from './root.service'
import { toast } from 'libs/toast'
import Cookies from 'js-cookie'

export const utlis = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getDateTime: builder.query<any, string>({
      // <-- Changed the second type parameter to string (for time zone)
      query: (timeZone) => {
        // <-- Modified the query function to accept a time zone parameter
        return {
          url: `/timezone/${timeZone}`, // <-- Added the time zone to the URL
          method: 'GET',
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the timezone')
            }
            return response.json()
          },
        }
      },
    }),
    multipleFileUpload: builder.mutation<any, any>({
      query: ({ files, fieldName }) => {
        const formData = new FormData()
        // Append multiple files to the formData
        files.forEach((file: any) => {
          formData.append('files', file) // The 'files' key matches the parameter in FastAPI
        })
        return {
          url: `utils/upload-multiple-files?fieldName=${fieldName}`,
          method: 'PATCH',
          headers: {
            Authorization: 'Bearer ' + Cookies.get('access_token'),
          },
          body: formData,
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const { useGetDateTimeQuery, useMultipleFileUploadMutation } = utlis
