import { useDeleteInventoryMutation } from 'pages/inventory/inventory-endpoints/inventory.endpoints'
import Button from '../../../../../libs/button/Button'

import { t } from 'i18next'
import { trackEvent } from 'libs/layouts/mixpanel/mixPanel'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface Props {
  closePopUp: any
  transaction: any
  plantId: string
}

function DeleteTransactionHistoryPopUp({ closePopUp, transaction, plantId }: Props) {
  const { data: userInfo } = useUserInfoQuery({})

  const plant = useSelector(selectSelectedItem)

  const [deleteInventoryTransaction, { isLoading }] = useDeleteInventoryMutation()

  const deleteHandler = () => {
    deleteInventoryTransaction({ plant_id: plantId, id: transaction._id })
      .unwrap()
      .then(() => {
        toast.success('Deleted Transaction history succesfully')
        closePopUp()
        const transactionType =
          transaction?.transactionType?.charAt(0)?.toUpperCase() + transaction?.transactionType.slice(1).toLowerCase()
        trackEvent('Inventory', {
          Inventory: 'Delete Inventory Entry',
          Count: '1',
          Plant: plant?.name,
          TransactionType: transactionType,
          organization: userInfo && userInfo[0]?.organization?.name,
        })
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong')
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}{' '}
        <span className="break-words pr-1 p-xs-bold">
          “{t(`inventory_.${transaction?.transactionType?.toLowerCase()}`)} {t(`inventory_.transaction`)}”.
        </span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={deleteHandler} loading={isLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteTransactionHistoryPopUp
