import React from "react";

interface ArrowDownProps {
  color?: string;
}

const ArrowDown: React.FC<ArrowDownProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={color}
      viewBox="0 0 16 16"
    >
      <mask
        id="mask0_1129_1958"
        style={{ maskType: "alpha" }}
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_1129_1958)">
        <path
          fill={color ? `${color}` : "#041220"}
          d="M8 9.967a.738.738 0 01-.25-.042.585.585 0 01-.217-.142L4.467 6.717a.632.632 0 01-.184-.467c0-.189.061-.344.184-.467a.632.632 0 01.466-.183c.19 0 .345.061.467.183l2.6 2.6 2.6-2.6a.632.632 0 01.467-.183c.188 0 .344.061.466.183a.632.632 0 01.184.467.632.632 0 01-.184.467L8.467 9.783a.585.585 0 01-.217.142.738.738 0 01-.25.042z"
        ></path>
      </g>
    </svg>
  );
};

export default ArrowDown;
