import { AnimatePresence, motion } from 'framer-motion'
import { scaleMotionProps } from 'utils/FramerProps'
import DownloadIcon from 'assets/icons/maintenance/DownloadIcon'
import Button from 'libs/button/Button'
import WhiteCloseIcon from 'assets/icons/maintenance/WhiteCloseIcon'

type PopupProps = {
  children: React.ReactNode
  onClose: () => void
  isOpen: boolean
  title: string
  download: string
}

/**
 * `Popup` is a component that renders a popup with animation.
 * @param props.isOpen - Determines whether the popup is open or closed.
 * @param props.children - The content to be rendered inside the popup.
 * @param props.onClose - The function to be called when the popup is closed.
 * @param props.title - The title of the popup.
 * @returns  A panel element that animates in and out based on the `isOpen` prop.
 */

const DocumentPopup = ({
  isOpen,
  children,
  title,
  onClose,
  download,
}: PopupProps) => {
  const downloadURL = () => {
    if (download) {
      const link = document.createElement('a')
      link.href = download
      if (download) {
        link.download = download.split('/').pop() || '' // Extracts the file name from the URL
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      console.error('No URL provided for download.')
    }
  }
  return (
    <>
      <AnimatePresence>
        <motion.div
          {...scaleMotionProps}
          className={` fixed left-0 top-[3rem] flex h-full w-full items-center justify-center background-blur-black  ${
            isOpen ? '' : 'hidden'
          }`}
        >
          <div className="z-50 mt-[50px] h-full w-[800px] rounded-2xl p-4 shadow-2xl background-white">
            <div className="mb-3 flex justify-between">
              <h5 className="text-nowrap  text-brand-primary-shade-3">
                {title}
              </h5>
              <div className="flex w-full cursor-pointer items-center justify-end gap-4">
                <Button
                  onClick={downloadURL}
                  startIcon={<DownloadIcon />}
                  color="primary"
                >
                  Download
                </Button>
                <Button
                  onClick={onClose}
                  startIcon={<WhiteCloseIcon />}
                  color="error"
                >
                  Close
                </Button>
              </div>
            </div>
            {children}
          </div>
        </motion.div>
      </AnimatePresence>
      <div className={isOpen ? 'sideNav-overlay-dark' : 'sideNav-overlay'} />
    </>
  )
}

export default DocumentPopup
