import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import {
  GetMoOrdersParams,
  ManufactureOrderResponse,
  MoApiResponse,
  postManufactureOrder,
} from './manufactureordersTypes'
import { buildUrl } from 'utils/stringHandlers'

export const manufactureorder = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getAllManufactureOrders: builder.query<MoApiResponse, GetMoOrdersParams>({
      query: ({ plantId, filters, moID, is_draft }) => {
        const URL = buildUrl(`/plant/${plantId}/mo`, {
          moID,
          is_draft,
          ...filters,
        })

        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          // No need for params here as they are included in the URL
          responseHandler: async (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Manufacture Orders')
              throw new Error('Unable to fetch the Manufacture Orders')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetManufacturerOrder'],
    }),
    getManufactureOrderById: builder.query<
      ManufactureOrderResponse,
      { plant_id: string; moID?: string }
    >({
      query: ({ plant_id, moID }) => {
        return {
          url: `plant/${plant_id}/mo/${moID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: async (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Manufacture Orders')
              throw new Error('Unable to fetch the Manufacture Orders')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetManufacturerOrder'],
    }),
    postManufactureOrder: builder.mutation<
      ManufactureOrderResponse,
      postManufactureOrder
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/mo`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetManufacturerOrder'],
    }),

    editManufactureOrder: builder.mutation({
      query: ({ data, plant_id, moId }) => {
        return {
          url: `/plant/${plant_id}/mo/${moId}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['GetManufacturerOrder'],
    }),

    deleteManufactureOrder: builder.mutation({
      query: ({ plant_id, mo_id }) => ({
        url: `/plant/${plant_id}/mo/${mo_id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['GetManufacturerOrder'],
    }),

    getManufactureOrdersStats: builder.query<any, { plantId: string }>({
      query: ({ plantId }) => ({
        url: `/plant/${plantId}/mo/stats`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['GetManufacturerOrder'],
    }),
    getManufactureOrdersBarStats: builder.query<
      any,
      { plantId: string; filters: any }
    >({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(
          `/plant/${plantId}/manufacture-orders/bar`,
          filters,
        )
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['GetManufacturerOrder'],
    }),
    getManufactureOrdersOnTimeChart: builder.query<
      any,
      { plantId: string; filters: any }
    >({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(
          `/plant/${plantId}/manufacture-orders/on-time-chart`,
          filters,
        )
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
        }
      },
      providesTags: ['GetManufacturerOrder'],
    }),
    updateManufactureOrdersStatus: builder.mutation<
      any,
      { plantId: string; moID: string; status: string }
    >({
      query: ({ plantId, moID, status }) => ({
        url: `/plant/${plantId}/manufacture-order/${moID}/change-status?status=${status}`,
        method: 'PATCH',
        headers: getToken(),
      }),
      invalidatesTags: ['GetManufacturerOrder'],
    }),
    getManufactureOrderByQuantityProduced: builder.query<
      any,
      { plant_id: string; moID?: string }
    >({
      query: ({ plant_id, moID }) => {
        return {
          url: `plant/${plant_id}/manufacture-order/${moID}/quantity-produced`,
          method: 'GET',
          headers: getToken(),
          responseHandler: async (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Manufacture Orders')
              throw new Error('Unable to fetch the Manufacture Orders')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetManufacturerOrder'],
    }),
    getManufactureOrderWoStatus: builder.query<
      any,
      { plant_id: string; moID?: string }
    >({
      query: ({ plant_id, moID }) => {
        return {
          url: `plant/${plant_id}/manufacture-order/${moID}/wo/status`,
          method: 'GET',
          headers: getToken(),
          responseHandler: async (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Manufacture Orders')
              throw new Error('Unable to fetch the Manufacture Orders')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetManufacturerOrder'],
    }),
    getManufactureOrderProgressBar: builder.query<
      any,
      { plant_id: string; moID?: string }
    >({
      query: ({ plant_id, moID }) => {
        return {
          url: `plant/${plant_id}/manufacture-order/${moID}/progress-chart`,
          method: 'GET',
          headers: getToken(),
          responseHandler: async (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Manufacture Orders')
              throw new Error('Unable to fetch the Manufacture Orders')
            }
            return response.json()
          },
        }
      },
      providesTags: ['GetManufacturerOrder'],
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetAllManufactureOrdersQuery,
  usePostManufactureOrderMutation,
  useEditManufactureOrderMutation,
  useDeleteManufactureOrderMutation,
  useGetManufactureOrdersStatsQuery,
  useGetManufactureOrderByIdQuery,
  useGetManufactureOrdersBarStatsQuery,
  useGetManufactureOrdersOnTimeChartQuery,
  useUpdateManufactureOrdersStatusMutation,
  useGetManufactureOrderByQuantityProducedQuery,
  useGetManufactureOrderWoStatusQuery,
  useGetManufactureOrderProgressBarQuery,
} = manufactureorder
