import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { selectSelectedItem } from 'store/redux/navSlice'
import { toast } from 'libs/toast'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'
import Wizard from 'libs/wizard/Wizard'
import BasicDetails from './tabs/BasicDetails'
import AddressDetails from './tabs/AddressDetails'
import {
  useUpdateCustomerMutation,
  useCreateCustomerMutation,
  useGetCustomerByIDQuery,
} from '../customer-endpoints/customer.endpoints'
import {
  CustomerFormik,
  CustomerPayload,
} from '../customer-endpoints/customerTypes'

const AddEditCustomer = () => {
  const { id } = useParams()
  useTabTitle(id ? 'Edit Customer' : 'Add New Customer')
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()

  const [addCustomer, { isLoading: addCustomerLoading }] =
    useCreateCustomerMutation()

  const [updateCustomer, { isLoading: updateCustomerLoading }] =
    useUpdateCustomerMutation()

  const { data: customer, isLoading: getCustomerLoading } =
    useGetCustomerByIDQuery(
      {
        plant_id: plant?.id,
        id: id ?? '',
      },
      { skip: !plant?.id || !id },
    )

  const customerFormik = useFormik<CustomerFormik>({
    initialValues: {
      BasicDetails: {
        customerType: '',
        name: '',
        businessEmailAddress: '',
        primaryContactNumber: '',
        secondaryContactNumber: '',
        pointOfContacts: [],
      },
      AddressDetails: {
        addresses: [],
      },
    },
    onSubmit: (values) => {
      const payload: CustomerPayload = {
        ...values?.BasicDetails,
        ...values?.AddressDetails,
      }
      if (!id) {
        addCustomer({ plant_id: plant.id, data: payload })
          .unwrap()
          .then((res) => {
            toast.success(res)
            navigate('/contacts/customer-management')
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ??
                'Something went wrong while creating the customer',
            )
          })
      } else {
        updateCustomer({
          plant_id: plant.id,
          id: id,
          data: payload,
        })
          .unwrap()
          .then((res) => {
            toast.success(res)
            navigate('/contacts/customer-management')
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ??
                'Something went wrong while updating the customer',
            )
          })
      }
    },
  })

  useEffect(() => {
    if (id && customer) {
      customerFormik.resetForm({
        values: {
          BasicDetails: {
            customerType: customer?.customerType,
            name: customer?.name,
            businessEmailAddress: customer?.businessEmailAddress,
            primaryContactNumber: customer?.primaryContactNumber,
            secondaryContactNumber: customer?.secondaryContactNumber,
            pointOfContacts: customer?.pointOfContacts ?? [],
          },
          AddressDetails: {
            addresses: customer?.addresses ?? [],
          },
        },
      })
    }
  }, [customer, id])

  const tabs = [
    {
      tabTitle: 'Basic Details',
      routeName: 'basic-details',
      component: <BasicDetails customerFormik={customerFormik} />,
    },
    {
      tabTitle: 'Address Details',
      routeName: 'address-details',
      component: (
        <AddressDetails
          customerFormik={customerFormik}
          loading={updateCustomerLoading || addCustomerLoading}
        />
      ),
    },
  ]

  if (getCustomerLoading) {
    return <FullPageLoader />
  }

  return (
    <div className="flex flex-col gap-2">
      <h4 className="h4-bold-black">
        {id ? 'Edit Customer' : 'Add New Customer'}
      </h4>
      <Wizard tabs={tabs} type="steps" shouldAllowFreeNavigation={!!id} />
    </div>
  )
}

export default AddEditCustomer
