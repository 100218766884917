import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { useDeleteRolesMutation } from '../roles-endpoints/roles.endpoint'
import { useUserInfoQuery } from '../../users/user-endpoints/user.endpoint'
import { useNavigate, useParams } from 'react-router-dom'
interface Props {
  closePopUp: any
  data: any
}
const DeleteRolesPopup = ({ closePopUp, data }: Props) => {
  const [deleteRoles, { isLoading }] = useDeleteRolesMutation()
  const paramId = useParams()
  const { data: userInfo } = useUserInfoQuery({})
  const navigate = useNavigate()

  const handleDelete = () => {
    deleteRoles({ org_id: userInfo?.organization?._id, roleId: data?._id })
      .unwrap()
      .then(() => {
        if (paramId?.id) navigate(-1)
        closePopUp()
        toast.success(`Role ${data?.name} deleted successfully`)
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }
  return (
    <>
      <p className="p-xs-regular">
        You are about to delete a <span className="break-words p-xs-bold">"{data?.name}". </span>
        Are you sure you want to proceed?
      </p>
      <div className=" mt-1 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={handleDelete} loading={isLoading}>
          Delete
        </Button>
      </div>
    </>
  )
}

export default DeleteRolesPopup
