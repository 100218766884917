/* eslint-disable react/prop-types */
import Field from 'libs/field'

import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  EmployeeDepartmentOptions,
  EmployeeDesignationOptions,
} from 'pages/employee/Employee-Department/employee-departments-endpoints/employeedepartmentTypes'
import { useGetEmployeeDepartmentAndDesignationQuery } from 'pages/employee/Employee-Department/employee-departments-endpoints/employeesdepartment.endpoints'

const JobDetails = ({ employeeFormik, addEmployeeLoading, setPage }: any) => {
  const plantId = useSelector(selectSelectedItem)
  const { id } = useParams()

  const navigate = useNavigate()
  const jobdetailFormik = useFormik({
    initialValues: {
      designation: {
        name: '',
        _id: '',
      },
      department: {
        name: '',
        _id: '',
      },
    },

    onSubmit: (values) => {
      const newValues = {
        designationId: values.designation?._id,
        departmentId: values.department?._id,
      }
      employeeFormik.setFieldValue('jobDetailsFormik', newValues)
      employeeFormik.handleSubmit()
    },
  })

  const { data } = useGetEmployeeDepartmentAndDesignationQuery(
    {
      plant_id: plantId?.id,
      departmentID: jobdetailFormik?.values?.department?._id,
    },
    {
      skip: !plantId?.id && !jobdetailFormik?.values?.department?._id,
    },
  )

  const departmentsOptions =
    data?.department?.map((department: EmployeeDepartmentOptions) => ({
      label: department.name,
      value: department,
    })) ?? []

  const designationsOptions =
    data?.designation?.map((roles: EmployeeDesignationOptions) => ({
      label: roles.name,
      value: roles,
    })) ?? []

  useEffect(() => {
    jobdetailFormik.resetForm({
      values: {
        designation: employeeFormik.values.jobDetailsFormik?.designation || '',
        department: employeeFormik.values.jobDetailsFormik?.department || '',
      },
    })
  }, [employeeFormik])

  useEffect(() => {
    setPage(3)
  }, [])

  return (
    <MotionDiv>
      <div className=" flex min-h-[70vh] flex-col">
        <div className="flex flex-grow flex-col gap-3">
          <div>
            <h5 className="h5-1">{'Job Details'}</h5>
            <p className="p-sm-regular-2">
              {!id
                ? 'Add the basic details that are required to Add New Employee.'
                : 'Edit the existing employee details to make necessary changes and updates.'}
            </p>
          </div>
          <div className="grid max-w-[1200px] grid-cols-1 gap-3 md:grid-cols-2">
            <Field
              type="select"
              label={'Department'}
              formik={jobdetailFormik}
              options={departmentsOptions}
              defaultValue={[]}
              name="department"
              placeholder={'Select department'}
            />
            <Field
              type="select"
              label={'Designation'}
              formik={jobdetailFormik}
              options={designationsOptions}
              defaultValue={
                {
                  label: jobdetailFormik?.values?.designation?.name ?? '' }
              }
              name="designation"
              placeholder={'Select designation'}
              disabled={!jobdetailFormik?.values?.department?.name}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between gap-3">
          <div>
            <Button
              type="button"
              onClick={() => {
                navigate('/employee/employee-management')
              }}
            >
              {'Cancel'}
            </Button>
          </div>
          <div className="flex gap-3">
            <Button
              type="button"
              onClick={() => {
                setPage(2)
                navigate(-1)
              }}
            >
              {'Previous'}
            </Button>
            <Button
              loading={addEmployeeLoading}
              color="success"
              type="submit"
              onClick={() => {
                jobdetailFormik.handleSubmit()
              }}
            >
              {'Save'}
            </Button>
          </div>
        </div>
      </div>
    </MotionDiv>
  )
}

export default JobDetails
