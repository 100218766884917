import { toastSuccess } from 'libs/toast/toastSucess'
import { toastFailure } from 'libs/toast/toastFailure'
import { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePostPropertyMutation } from '../products-endpoints/products.endpoints'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { trimStringValues } from 'utils/stringHandlers'

const data = [
  {
    title: 'Measurable Property',
    value: 'Measurable Property',
    subtitle: 'Use this for any measurement (e.g., weight in Kg).',
  },
  {
    title: 'Fixed Property',
    subtitle: 'Specify unchanging product attributes (e.g., color = black).',
  },
]
const data2 = [
  {
    title: 'Count',
  },
  {
    title: 'Density',
  },
  {
    title: 'Frequency',
  },
  {
    title: 'Height / Width / Depth',
  },
  {
    title: 'Pressure',
  },
  {
    title: 'Temperature',
  },
  {
    title: 'Weight',
  },
]

const AddProperty = ({
  removeEntry,
  addTableData,
  productId,
  tableData,
  onPropertyChange,
}: any) => {
  const location = useLocation()
  const [formData, setFormData] = useState({
    propertyName: '',
    units: '',
  })
  const [errors, setErrors] = useState<any>({})
  const [hasUnits, setHasUnits] = useState(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [isDropdownVisible2, setIsDropdownVisible2] = useState(false)
  const [selectedTitle, setSelectedTitle] = useState('')
  const [selectedTitle2, setSelectedTitle2] = useState('')
  const [addProperty, { isLoading: propertyLoading }] =
    usePostPropertyMutation()

  const handleTitleClick = (title: any) => {
    setSelectedTitle(title)
    setIsDropdownVisible(false)

    setErrors({ ...errors, propertyType: '' })
  }
  const handleTitleClick2 = (title: any) => {
    setSelectedTitle2(title)
    setIsDropdownVisible2(false)

    // Clear the propertyType2 error when a title is selected for the second dropdown
    setErrors({ ...errors, measurementType: '' })
  }

  const handleCheckboxChange = (event: any) => {
    const isChecked = event.target.checked
    setHasUnits(isChecked)
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const popupRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (popupRef.current && !event.target.closest('.ignoreref')) {
        setIsDropdownVisible(false)
        setIsDropdownVisible2(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible)
    setIsDropdownVisible2(false)
  }

  const handleInputClick2 = () => {
    setIsDropdownVisible2(!isDropdownVisible2)
    setIsDropdownVisible(false)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const newErrors: any = {}

    // Check each field for errors
    if (formData.propertyName.trim() === '') {
      newErrors.propertyName = 'Property Name cannot be empty.'
    }
    if (selectedTitle === '') {
      newErrors.propertyType = 'Property Type cannot be empty.'
    }
    if (selectedTitle2 === '' && selectedTitle !== '') {
      newErrors.measurementType = 'Measurement Type cannot be empty.'
    }

    // Set the new errors
    setErrors(newErrors)

    // If there are no errors, submit the form
    if (
      Object.keys(newErrors).length === 0 &&
      location.pathname === '/products/newProducts'
    ) {
      const newProperty = {
        id: Date.now().toString(),
        name: formData?.propertyName,
        type: selectedTitle,
        value: selectedTitle2,
        unit: formData?.units,
      }

      if (
        tableData?.map((item: any) => item?.name).includes(newProperty?.name)
      ) {
        toastFailure('Property name already exists')
        return
      }

      addTableData(newProperty)

      setFormData({
        propertyName: '',
        units: '',
      })

      // Uncheck the "This property has units" checkbox
      setHasUnits(false)
      removeEntry()
      toastSuccess(`Property "${formData?.propertyName}" is added successfully`)
    } else if (
      Object.keys(newErrors).length === 0 &&
      location.pathname === '/products/editProducts'
    ) {
      const newProperty = [
        {
          name: formData?.propertyName,
          type: selectedTitle,
          value: selectedTitle2,
          unit: formData?.units,
        },
      ]
      const newName = newProperty[0]?.name
      if (tableData.map((item: any) => item.name).includes(newName)) {
        toastFailure('Property name already exists')
        return
      }

      const data = trimStringValues(newProperty)

      addProperty({ data: data, productId })
        .unwrap()
        .then(() => {
          toastSuccess(
            `Property "${formData?.propertyName}" is added successfully.`,
          )
          onPropertyChange(true)
          removeEntry()
        })
        .catch((err) => {
          toastFailure(
            err?.data?.detail ?? 'Failed to add property. Please try again.',
          )
        })
    }
  }

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <label className="mb-1 p-sm-regular">
              Property Name <span className="text-[#DC3545]">*</span>
            </label>
            <div>
              <div className="relative py-1">
                <input
                  required
                  type="text"
                  name="propertyName"
                  value={formData.propertyName}
                  onChange={handleInputChange}
                  className={`h-[28px] w-full border ${
                    errors.propertyName
                      ? 'border-[#DC3545]'
                      : 'border-[#E0E0E0]'
                  } rounded-md border border-solid p-2 pr-8 text-sm outline-none p-xs-regular`}
                  placeholder="Enter property name (e.g., Color)"
                />
                {errors.propertyName && (
                  <p className="pt-1 text-xs font-normal text-[#DC3545]">
                    {errors.propertyName}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="relative mt-5">
              <label className="mb-1 p-sm-regular">
                Property Type <span className="text-[#DC3545]">*</span>
              </label>
              <input
                required
                name="propertyType"
                type="text"
                autoComplete="off"
                className={`ignoreref h-[28px] w-full ${
                  errors.propertyType ? 'border-[#DC3545]' : 'border-[#E0E0E0]'
                } cursor-pointer rounded-md 
         border border-solid p-2 pr-8 text-sm outline-none p-xs-regular focus:border-[#1193F0]`}
                placeholder="Select property type"
                onClick={handleInputClick}
                value={selectedTitle}
              />
              <div className="absolute right-[0.5rem] top-[1.9rem]">
                <ArrowDown color="" />
              </div>
              {errors.propertyType && (
                <p className="pt-1 text-xs font-normal text-[#DC3545]">
                  {errors.propertyType}
                </p>
              )}
              <AnimatePresence>
                {isDropdownVisible && (
                  <motion.div
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={dropdownMotionProps}
                    ref={popupRef}
                    className="absolute min-h-[20px] w-[378px] rounded bg-[#fff]"
                    style={{
                      zIndex: '3',
                      boxShadow:
                        '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    {data.map((item) => (
                      <div
                        key={item.title}
                        className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]"
                        onClick={() => handleTitleClick(item.value)}
                      >
                        <div className="p-xs-regular">{item.title}</div>
                        <div className="p-xs-regular-dim">{item.subtitle}</div>
                      </div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>

          {selectedTitle === 'Measurable Property' && (
            <>
              <div className="relative mt-5">
                <label className="mb-1 p-sm-regular">
                  Measurement Type <span className="text-[#DC3545]">*</span>
                </label>
                <input
                  required
                  name="measurementType"
                  type="text"
                  className={` h-[28px] w-full ${
                    errors.measurementType
                      ? 'border-[#DC3545]'
                      : 'border-[#E0E0E0]'
                  } cursor-pointer rounded-md 
         border border-solid p-2 pr-8 text-sm outline-none p-xs-regular focus:border-[#1193F0]`}
                  placeholder="Select measure type"
                  onClick={handleInputClick2}
                  value={selectedTitle2}
                />
                <div className="absolute right-[0.5rem] top-[1.9rem]">
                  <ArrowDown color="" />
                </div>
                {errors.measurementType && (
                  <p className="pt-1 text-xs font-normal text-[#DC3545]">
                    {errors.measurementType}
                  </p>
                )}
                <AnimatePresence>
                  {isDropdownVisible2 && (
                    <motion.div
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={dropdownMotionProps}
                      ref={popupRef}
                      className="absolute min-h-[20px] w-[378px] rounded bg-[#fff]"
                      style={{
                        zIndex: '3',
                        boxShadow:
                          '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                      }}
                    >
                      {data2.map((item) => (
                        <div
                          key={item.title}
                          className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]"
                          onClick={() => handleTitleClick2(item.title)}
                        >
                          <div className="p-xs-regular">{item.title}</div>
                        </div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="mb-1.5 mt-5 flex gap-2 p-sm-regular">
                <input
                  type="checkbox"
                  checked={hasUnits}
                  onChange={handleCheckboxChange}
                />
                <p>This property has units</p>
              </div>
            </>
          )}
          {hasUnits && (
            <div>
              <input
                required
                type="text"
                name="units"
                value={formData.units}
                onChange={handleInputChange}
                className={`h-[28px] w-full rounded-md border border-solid border-[#E0E0E0] p-2 pr-8 text-sm outline-none p-xs-regular`}
                placeholder="Enter Units"
              />
            </div>
          )}

          <div className="mb-8 mt-6 flex justify-end gap-2">
            <div>
              <button
                className="btn-cancel"
                type="button"
                onClick={removeEntry}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="btn-primary"
                disabled={propertyLoading}
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddProperty
