import { useState, useRef, useEffect } from 'react'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'
import TextInput from '../input-type/TextInput'
import NumberInput from '../input-type/NumberInput'
import DateSelection from '../input-type/DateSelection'
import DropDown from '../input-type/DropDown'
import Checkbox from '../input-type/CheckBox'
import TimeSelection from '../input-type/TimeSelection'
import PredefindField from '../input-type/PredefindField'
import CalculateField from '../input-type/CalculatedField'
import { useTranslation } from 'react-i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'

import { usePostFromFieldMutation } from '../operational-process-endpoints/operational_process.endpoints'
import { useParams } from 'react-router-dom'
import { isValidFormula } from '../utils'
import { trimStringValues } from 'utils/stringHandlers'

const data1 = [
  {
    id: 1,
    name: 'Text input',
  },
  {
    id: 2,
    name: 'Number input',
  },
  {
    id: 3,
    name: 'Date selection',
  },
  {
    id: 4,
    name: 'Time selection',
  },
  {
    id: 5,
    name: 'Checkbox',
  },
  {
    id: 6,
    name: 'Dropdown selection',
  },
  {
    id: 7,
    name: 'Predefined field',
  },
  {
    id: 8,
    name: 'Calculated field',
  },
]

const AddField = ({ productId, closePanel, numberFields }: any) => {
  const { t } = useTranslation()

  const { processId } = useParams()

  const [formData, setFormData] = useState({
    fieldName: '',
  })

  const [addFromField, { isLoading: fromFieldsLoading }] =
    usePostFromFieldMutation()

  const [errors, setErrors] = useState<any>({})
  const [selectedTitle, setSelectedTitle] = useState('')
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [isMandatory, setIsMandatory] = useState(false)
  const [childValue, setChildValue] = useState<any>('')

  const isAlphanumeric = (value: string) => /^[a-z0-9\s]+$/i.test(value)

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    const updatedFormData = { ...formData, [name]: value }
    const updatedErrors = { ...errors, [name]: '' }

    if (isAlphanumeric(value) || value === '') {
      setFormData(updatedFormData)
    } else {
      updatedErrors[name] = 'Only alphanumeric characters are allowed.'
    }

    setErrors(updatedErrors)
  }

  const popupRef = useRef<any>(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (
        popupRef.current &&
        !popupRef.current?.contains(event.target) &&
        !event.target.closest('.ignoreref')
      ) {
        setIsDropdownVisible(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleTitleClick = (title: any) => {
    setSelectedTitle(title)
    setIsDropdownVisible(false)

    setErrors({ ...errors, selectedTitle: '' })
  }

  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const handleCheckboxChange = () => {
    setIsMandatory(!isMandatory)
  }
  const handleFormDataChange = (data: any) => {
    setChildValue(data)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const newErrors: any = {}

    // Check each field for errors
    if (formData.fieldName.trim() === '') {
      newErrors.fieldName =
        'Field name cannot be empty. Please enter a relevant name.'
    }
    if (selectedTitle === '') {
      newErrors.selectedTitle =
        'Input type cannot be empty. Please select an option from the dropdown list.'
    }

    if (childValue?.has_prefix && childValue?.prefix_text?.trim() === '') {
      newErrors.prefixText =
        'Prefix text cannot be empty, if prefix is enabled. If not required, please disable the prefix by clicking on the toggle switch above.'
    }

    if (childValue?.has_suffix && childValue?.suffix_text?.trim() === '') {
      newErrors.suffixText =
        'Suffix text cannot be empty, if suffix is enabled. If not required, please disable the suffix by clicking on the toggle switch above.'
    }
    if (childValue?.has_unit && childValue?.unit?.trim() === '') {
      newErrors.unit =
        'Units of measure cannot be empty, if enabled. If your measure does not require a unit, please disable the units by clicking on the toggle switch above.'
    }

    if (childValue?.has_max_value && childValue?.max_value?.trim() === '') {
      newErrors.maxValue =
        'Max value cannot be empty, if enabled. If your measure does not has a maximum value or cannot be determined, please disable by clicking on the toggle switch above.'
    }

    if (childValue?.has_min_value && childValue?.min_value?.trim() === '') {
      newErrors.minValue =
        'Min value cannot be empty, if enabled. If your measure does not has a minimum value or cannot be determined, please disable by clicking on the toggle switch above.'
    }
    if (childValue?.predefined?.trim() === '') {
      newErrors.predefined =
        'Please mention a field that is defined previously in any of the other processes.'
    }

    const errorMessageInFormula = isValidFormula(
      childValue?.formula?.trim(),
      childValue?.formulaValue?.trim(),
    )

    if (childValue?.formula?.trim() === '') {
      newErrors.formula =
        'Formula cannot be empty. Please use predefined fields to create a formula.'
    } else if (errorMessageInFormula != '') {
      newErrors.formula = errorMessageInFormula
    }

    if (childValue?.hasNumber && childValue?.number?.trim() === '') {
      newErrors.number =
        'Please enter the number of digits to round off. If your measure does not require rounding off, please disable the by clicking on the toggle switch above.'
    }

    const allFieldsFilled = (childValue?.options ?? []).every(
      (input: any) => input.name.trim() !== '',
    )

    if (
      childValue?.options?.length === 1 &&
      childValue?.options[0]?.name?.trim() === ''
    ) {
      newErrors.dropdown =
        'Please mention at least one option to use this field effectively.'
    }

    if (!allFieldsFilled) {
      newErrors.emptyField = 'Option field can not be blank'
    }

    if (errors?.maxValue1) {
      newErrors.maxValue1 = 'Max value must be greater than Min value.'
    }

    if (errors?.minValue1) {
      newErrors.minValue1 = 'Max value must be greater than Min value.'
    }
    // Set the new errors
    setErrors(newErrors)
    if (Object.keys(newErrors).length > 0) {
      return
    }

    const newFormData = {
      name: formData.fieldName,
      type: selectedTitle,
      is_required: isMandatory,
      ...childValue,
    }

    const data = trimStringValues(newFormData)

    addFromField({
      processId: processId,
      productId: productId,
      data: data,
    })
      .unwrap()
      .then(() => {
        closePanel()
        toast.success(`New Field ${formData.fieldName} is created.`)
      })
      .catch((err) => {
        if (
          err?.data?.detail ===
          `Unable to add field : Duplicate field name '${formData.fieldName}' found in product`
        ) {
          newErrors.fieldName = `The field name '${formData.fieldName}' is already exits. Please choose a different name.`
        }
        toast.error(
          err?.data?.detail ?? 'Something went wrong, Please try again.',
        )
      })
  }

  return (
    <div className="flex h-screen flex-col">
      <form className="flex h-full flex-col justify-between">
        <div className="flex gap-5 ">
          <div className="flex gap-5">
            <div>
              <div className="mt-5">
                <label className="mb-1 p-sm-regular ">
                  {t('field_name')} <span className="text-error">*</span>
                </label>
                <div>
                  <p className="p-xs-regular-dim">
                    {t('this_will_show_up_as_the_label_of_the_form_field')}
                  </p>
                  <div className="relative py-1">
                    <input
                      type="text"
                      name="fieldName"
                      value={formData.fieldName}
                      onChange={handleInputChange}
                      className={`h-[28px] w-[360px] border ${
                        errors.fieldName
                          ? 'border-error'
                          : 'border-inputBorderNormal'
                      } rounded-md border border-solid bg-inputBorderNormal p-2 pr-8 text-sm outline-none p-xs-regular`}
                      placeholder={t('enter_relevant_name_for_this_field')}
                      maxLength={100}
                    />
                    {errors.fieldName && (
                      <p className="w-[360px] pt-1 text-xs font-normal text-error">
                        {errors.fieldName}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="relative mt-5">
                  <label className="mb-1 p-sm-regular">
                    {t('input_type')} <span className="text-error">*</span>
                  </label>
                  <p className="mb-2 w-[360px] p-xs-regular-dim">
                    {t(
                      'you_can_specify_the_type_of_data_you_expect_people_to_enter_text_number_date_checkbox_etc',
                    )}
                  </p>
                  <div onClick={handleInputClick}>
                    <input
                      required
                      name="propertyType"
                      type="text"
                      autoComplete="off"
                      className={`ignoreref h-[28px] w-[360px] ${
                        errors.selectedTitle
                          ? 'border-error'
                          : 'border-inputBorderNormal'
                      } ${isDropdownVisible ? 'bg-white' : 'bg-inputBorderNormal'} cursor-pointer rounded-md 
         border border-solid p-2 pr-8 text-sm outline-none p-xs-regular focus:border-primary`}
                      placeholder={t('select')}
                      value={selectedTitle}
                    />
                    <div className="absolute right-[0.5rem] top-[4.6rem]">
                      <ArrowDown color={''} />
                    </div>
                  </div>
                  {errors.selectedTitle && (
                    <p className="w-[360px] pt-1 text-xs font-normal text-error">
                      {errors.selectedTitle}
                    </p>
                  )}
                  <AnimatePresence>
                    {isDropdownVisible && (
                      <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={dropdownMotionProps}
                        ref={popupRef}
                        className="scroll-bar absolute top-[100px] max-h-[12rem] min-h-[20px] w-[360px] overflow-auto rounded bg-white "
                        style={{
                          zIndex: '3',
                          boxShadow:
                            '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                        }}
                      >
                        {data1.map((item: any) => (
                          <div
                            key={item.title}
                            className="cursor-pointer flex-col gap-3 border-b border-inputBorderNormal px-3 py-2 hover:bg-[#f8f8f8]"
                            onClick={() => handleTitleClick(item.name)}
                          >
                            <div className="p-xs-regular">{item.name}</div>
                          </div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              {selectedTitle !== '' &&
                selectedTitle !== 'Calculated field' &&
                selectedTitle !== 'Predefined field' && (
                  <div className="mt-6 flex ">
                    <div>
                      <div className="p-sm-regular">{t('mandatory_field')}</div>
                      <p className="w-[310px] p-xs-regular-dim">
                        {t(
                          'enable_this_in_case_the_person_capturing_the_data_misses_it',
                        )}
                      </p>
                    </div>
                    <div>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isMandatory}
                          onChange={handleCheckboxChange}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                )}
            </div>
            <div className="flex min-h-full border-l-[1px] border-border "></div>
          </div>

          <div>
            {selectedTitle === '' && (
              <div className="flex h-full items-center justify-center pl-28 text-[#B9C4CC] h6">
                <p className="text-center">{t('please_select_input_type')}</p>
              </div>
            )}

            {selectedTitle === 'Text input' && (
              <TextInput
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {selectedTitle === 'Number input' && (
              <NumberInput
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {selectedTitle === 'Date selection' && (
              <DateSelection
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
              />
            )}
            {selectedTitle === 'Dropdown selection' && (
              <DropDown
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {selectedTitle === 'Checkbox' && (
              <Checkbox
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
              />
            )}
            {selectedTitle === 'Time selection' && (
              <TimeSelection
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
              />
            )}
            {selectedTitle === 'Predefined field' && (
              <PredefindField
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
                processId={processId}
              />
            )}
            {selectedTitle === 'Calculated field' && (
              <CalculateField
                isMandatory={isMandatory}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
                processId={processId}
                numberFields={numberFields}
              />
            )}
          </div>
        </div>
        <div>
          <div className="flex h-full items-end  justify-end gap-2 pb-2">
            <div>
              <Button onClick={closePanel}>{t('cancel')}</Button>
            </div>
            <div>
              <Button
                type="submit"
                color="primary"
                onClick={(e) => handleSubmit(e)}
                loading={fromFieldsLoading}
              >
                {t('add')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddField
