/**
 * Validates a formula.
 * @param {string} formula - The formula string to validate (formula is a converted from field names to field IDs).
 * @param {string} formulaValue - The formulaValue is a formula with the field name (no field ID is associated in it).
 * @returns {string} An error message if the formula is invalid, otherwise an empty string.
 */
export function isValidFormula(formula = '', formulaValue = '') {
  let errorMessage = ''
  let fieldNames = extractAlphaSequences(formulaValue)

  const validOperatorRegex = /[+\-\/%*]/
  const isValidOperator = (char: any) => validOperatorRegex.test(char)
  const isLetterOrNumber = /^[a-zA-Z0-9]$/

  if (isValidParentheses(formula)) {
    // Loop through each character in the string
    for (let i = 0; i < formula.length; i++) {
      if (formula[i] === '{') {
        // Check if it is an empty brace
        if (formula[i + 1] === '}') {
          errorMessage = 'Field name cannot be empty'
          break
        }

        // If it is opened, it should be closed first {{}} - wrong
        for (let j = i + 1; j < formula.length - 1; j++) {
          if (formula[j] === '{') {
            errorMessage = 'Please close the previous parentheses first'
            break
          } else if (formula[j] === '}') {
            break
          }
        }
      }
      // checking for empty delimeters
      if (formula[i] == '(') {
        if (formula[i + 1] == ')') {
          errorMessage = 'Cannot use empty parentheses'
        }
      }
      if (formula[i] == '[') {
        if (formula[i + 1] == ']') {
          errorMessage = 'Cannot use empty brackets'
        }
      }

      if (isValidOperator(formula[i])) {
        // Check if there is at least one valid operand on each side of the operator
        const leftValidOperand = /[0-9]/.test(formula[i - 1]) || ['}', ')', ']'].includes(formula[i - 1])
        const rightValidOperand = /[0-9]/.test(formula[i + 1]) || ['{', '(', '['].includes(formula[i + 1])
        if (!leftValidOperand || !rightValidOperand) {
          errorMessage = 'Place a field name or value between the operators'
          break
        }
      }

      if (['{', '(', '['].includes(formula[i])) {
        if (i !== 0 && isLetterOrNumber.test(formula[i - 1])) {
          errorMessage = 'Please place operator before the delimiters'
          break
        }
      }
      if (['}', ')', ']'].includes(formula[i])) {
        if (i !== formula.length - 1 && isLetterOrNumber.test(formula[i + 1])) {
          errorMessage = 'Please place operator after the delimiters'
          break
        }
      }
    }
    // checking for invalid field names
    for (let field of fieldNames) {
      if (formula.includes(field)) {
        errorMessage = `${field} is an invalid field name, please provide a valid one. (choose from the dropdown list)`
        break
      }
    }
  } else {
    errorMessage = 'Invalid delimeters'
  }

  return errorMessage
}

/**
 * Checks if the parentheses in a string are valid, (string includes characters also).
 * @param {string} input - The input string to check for valid parentheses.
 * @returns {boolean} True if the parentheses are valid, otherwise false.
 */
export function isValidParentheses(input: any) {
  const stack: any = []
  const openingBrackets = ['(', '{', '[']
  const closingBrackets = [')', '}', ']']
  const bracketMap: any = {
    '(': ')',
    '{': '}',
    '[': ']',
  }

  for (let i = 0; i < input.length; i++) {
    const char = input[i]
    if (openingBrackets.includes(char)) {
      stack.push(char)
    } else if (closingBrackets.includes(char)) {
      const lastOpeningBracket: any = stack.pop()
      if (!lastOpeningBracket || bracketMap[lastOpeningBracket] !== char) {
        return false
      }
    }
  }

  return stack.length === 0
}

/**
 * Converts any string to an array based on non-alphabetic letter occurrence.
 * @param {string} inputString - The input string to extract alphabetical sequences from.
 * @returns {string[]} An array containing all sequences of alphabetical characters found in the input string, or an empty array if none found.
 */
export function extractAlphaSequences(inputString: any) {
  const alphaSequencesRegex = /[a-zA-Z]+/g // Regex to match sequences of alphabetical characters
  const alphaSequences = inputString.match(alphaSequencesRegex) // Find all sequences of alphabetical characters
  return alphaSequences || [] // Return the array of alphabetical sequences, or an empty array if none found
}

/**
 * Converts field names to their corresponding field IDs in a formula.
 * @param {string} formula - The formula string with field names to be converted.
 * @param {Object[]} data - An array of objects containing field information, each object must have properties 'field_id' and 'field_name'.
 * @returns {string} The formula string with field names replaced by their corresponding field IDs.
 */
export const convertFormula = (formula = '', data = []) => {
  let convertedFormula = formula
  // Replace each field name in the formula with its corresponding field ID
  data?.forEach((field) => {
    const { id, name } = field
    const regex = new RegExp(`{${name}}`, 'g')
    convertedFormula = convertedFormula.replace(regex, `{${id}}`)
  })
  return convertedFormula
}

export const firstLetterUppercase = (string: string) => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const calculate_field_quantity = (calculated_field: any, AllFieldDetails: any) => {
  if (calculated_field && AllFieldDetails && calculated_field?.formula) {
    // Extract the formula from the calculated_field object
    const formula = calculated_field?.formula_value || calculated_field?.formula
    // Replace the placeholders with actual values from AllFieldDetails
    const evaluatedFormula = formula.replace(/\{(\w+)\}/g, (_: any, fieldName: any) => {
      return AllFieldDetails[fieldName] !== undefined ? AllFieldDetails[fieldName] : 0
    })
    // Evaluate the final expression
    const result = eval(evaluatedFormula)

    return result
  }
  return ''
}
