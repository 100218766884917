import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { formatString } from 'utils/stringHandlers'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'
import { useCreateVendorMutation } from 'pages/contacts/vendor-management/vendor-endpoints/vendor.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  CATEGORY_OPTIONS,
  VENDOR_TYPE_OPTIONS,
} from 'pages/contacts/vendor-management/vendor-endpoints/vendor.constants'
import { BasicDetailsFormik } from 'pages/contacts/vendor-management/vendor-endpoints/vendorTypes'
import { AddressDetailsFormik } from 'pages/contacts/commonTypes'

type Props = {
  close: () => void
}

const QuickAddEditVendor: React.FC<Props> = ({ close }) => {
  const plantId = useSelector(selectSelectedItem)
  const [addVendor, { isLoading: addVendorLoading }] = useCreateVendorMutation()
  // Initializing Formik
  const formik = useFormik<BasicDetailsFormik & AddressDetailsFormik>({
    initialValues: {
      vendorType: '',
      category: '',
      name: '',
      primaryContactNumber: '',
      businessEmailAddress: '',
      secondaryContactNumber: '',
      pointOfContacts: [],
      addresses: [],
    },
    validationSchema: yup.object({
      vendorType: yup.string().required('Vendor type is required'),
      category: yup.string().required('Material type is required'),
      name: yup.string().required('Vendor name is required'),
      primaryContactNumber: yup
        .string()
        .required('Primary contact number is required')
        .test(
          'valid-phone',
          'Please enter a phone number for the selected country.',
          phoneNumberValidator,
        ),
    }),
    onSubmit: (values) => {
      const payload = {
        category: values.category,
        vendorType: values.vendorType,
        name: values.name,
        primaryContactNumber: values.primaryContactNumber.toString(),
        businessEmailAddress: null,
        secondaryContactNumber: null,
        addresses: [],
        pointOfContacts: [],
      }

      addVendor({ plant_id: plantId.id, data: payload })
        .unwrap()
        .then((res) => {
          toast.success(res)
          close()
        })
        .catch((error) => {
          const errorMessage = Array.isArray(error?.data?.detail)
            ? error?.data?.detail[0]?.msg
            : error?.data?.detail
          toast.error(
            errorMessage ??
              'Failed to add the Vendor ' + payload.name + ' Please try again.',
          )
        })
    },
  })

  const defaultVendorType = useMemo(() => {
    return formik.values.vendorType
      ? formatString(formik.values.vendorType)
      : ''
  }, [formik.values.vendorType])
  const defaultCategory = useMemo(() => {
    return formik.values.category ? formatString(formik.values.category) : ''
  }, [formik.values.category])

  return (
    <div className="flex flex-col gap-2">
      <Field
        label="Vendor Type"
        placeholder="Select vendor Type"
        formik={formik}
        options={VENDOR_TYPE_OPTIONS}
        name="vendorType"
        type="select"
        defaultValue={{ label: defaultVendorType }}
        required
      />
      <Field
        label="Category"
        placeholder="Select category"
        formik={formik}
        options={CATEGORY_OPTIONS}
        name="category"
        type="select"
        defaultValue={{ label: defaultCategory }}
        required
      />
      <Field
        label="Vendor Name"
        placeholder="Enter Vendor Name"
        formik={formik}
        name="name"
        type="text"
        required
      />
      <Field
        label="Primary Contact Number"
        placeholder="Enter Contact Number"
        formik={formik}
        name="primaryContactNumber"
        type="phonenumber"
        required
      />
      <div className="my-2 flex justify-end gap-2">
        <Button onClick={close}>Cancel</Button>
        <Button
          color="primary"
          loading={addVendorLoading}
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}

export default QuickAddEditVendor
