import Bin from 'assets/icons/camera-page/Bin'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Pen from 'assets/icons/camera-page/Pen'
import MotionDiv from 'libs/motionDiv'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'
import { useGetMachineQuery } from './machine-endpoints/machine.endpoints'
import Chip from 'libs/chip/Chip'
import MachineFilters from './panel/MachineFilters'
import { useState } from 'react'
import Popup from 'libs/popup/Popup'
import DeleteMachine from './popup/DeleteMachine'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useNavigate } from 'react-router-dom'
import useTabTitle from 'libs/hooks/useTabTitle'
import Tooltip from 'libs/tool-tip/Tooltip'

const MachinesDetailsPage = () => {
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, machine: '' })
  useTabTitle('Machines')
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)

  const handleAddRecord = () => {
    navigate('/maintenance/machines/add/basic-details')
  }
  const handleEditRecord = (id: string) => {
    navigate(`/maintenance/machines/${id}/edit/basic-details`)
  }

  const machineColumns = [
    {
      Header: 'Machine',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <Tooltip content="View Details" position="right">
            <div className="flex flex-col gap-[1px]">
              <h1
                className="hyperlink"
                onClick={() => row?.original?._id && navigate(`/maintenance/machines/${row?.original?._id}/view`)}
              >
                {row.original?.name ?? '-'}
              </h1>
              <h1 className="text-gray-600">{row?.original?.serialNumber ?? '-'}</h1>
            </div>
          </Tooltip>
        )
      },
    },
    {
      Header: 'Machine Class',
      accessor: 'machineClass',
      width: 200,
      Cell: ({ row }: any) => {
        return <div>{row.original?.machineClass?.name ?? '-'}</div>
      },
      disableSortBy: true,
    },
    {
      Header: 'Model Number',
      accessor: 'modelNumber',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original?.machineClass?.models?.modelNumber ?? '-'}</div>
      },
      disableSortBy: true,
    },
    {
      Header: 'Work Center',
      width: 120,
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col gap-[1px]">
            <h1>{row?.original?.workCenter?.name ?? '-'}</h1>
            <h1 className="text-xs text-primary-gray-4">{row?.original?.workCenter?.workCenterID ?? '-'}</h1>
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Operators',
      accessor: 'operators',
      width: 80,
      Cell: ({ row }: any) => {
        const data = row?.original?.operators
        return (
          <Tooltip
            content={
              data.length > 0 ? (
                data.map((item: any) => (
                  <div key={item?._id}>
                    <span>• </span>
                    <span>{item?.employeeName}</span>
                  </div>
                ))
              ) : (
                <p>0 operators available</p>
              )
            }
            position="left"
          >
            <span className="cursor-pointer">{data.length.toString().padStart(2, '0')}</span>
          </Tooltip>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'No of Parts',
      accessor: 'partsDetails',
      Cell: ({ row }: any) => {
        const data = row?.original?.partsDetails
        return (
          <Tooltip
            content={
              data?.length > 0 ? (
                data?.map((item: any) => (
                  <div key={item?._id}>
                    <span>• </span>
                    <span>{item?.name}</span>
                  </div>
                ))
              ) : (
                <p>0 parts available</p>
              )
            }
            position="left"
          >
            <span className="cursor-pointer">{data?.length.toString().padStart(2, '0')}</span>
          </Tooltip>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Machine Status',
      accessor: 'status',
      width: 150,
      Cell: ({ value }: any) => <Chip title={value} />,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 20,
      Cell: ({ row }: any) => (
        <div className="flex w-full items-center justify-between ">
          <div className="flex flex-row">
            <Tooltip content="Edit Details">
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  handleEditRecord(row.original?._id)
                }}
              >
                <Pen />
              </button>
            </Tooltip>
            <Tooltip content="Delete Details">
              <button
                className={`mr-6`}
                onClick={(event) => {
                  event.stopPropagation()
                  setDeletePopUp({ isPopUpOpen: true, machine: row?.original })
                }}
              >
                <Bin />
              </button>
            </Tooltip>
          </div>
          <Tooltip content="View Details" position="left">
            <span
              className="cursor-pointer"
              onClick={() => row?.original?._id && navigate(`/maintenance/machines/${row?.original?._id}/view`)}
            >
              <ClickableRowArrow />
            </span>
          </Tooltip>
        </div>
      ),
      disableSortBy: true,
    },
  ]
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">Machines</h1>
        <p className="p-sm-regular-1">
          View and manage all key information related to the selected machine, including operational data, and parts.
        </p>
        <FilterableSearchTable
          useQuery={useGetMachineQuery}
          columns={machineColumns}
          addButton={{ label: 'Add Machine', onClick: handleAddRecord }}
          placeholders={{
            emptyMessage: 'No machines are added. Please add a machine.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search Machine by Name, Serial Number and Model Number',
          }}
          filterPanelComponent={MachineFilters}
        />
        <Popup
          isOpen={deletePopUp.isPopUpOpen}
          title="Delete Machine?"
          onClose={() => {
            setDeletePopUp({ isPopUpOpen: false, machine: '' })
          }}
        >
          <DeleteMachine
            closePopUp={() => setDeletePopUp({ isPopUpOpen: false, machine: '' })}
            machine={deletePopUp.machine}
            plant_id={plantId?.id}
          />
        </Popup>
      </>
    </MotionDiv>
  )
}

export default MachinesDetailsPage
