const BlackTick = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.571 6.46875L8.51475 1.17188C8.63141 1.04688 8.76995 0.984375 8.93037 0.984375C9.09079 0.984375 9.22933 1.04688 9.346 1.17188C9.46266 1.29688 9.521 1.44531 9.521 1.61719C9.521 1.78906 9.46266 1.9375 9.346 2.0625L3.97933 7.8125C3.86266 7.9375 3.72655 8 3.571 8C3.41544 8 3.27933 7.9375 3.16266 7.8125L0.654328 5.125C0.537661 5 0.481759 4.85156 0.48662 4.67969C0.491481 4.50781 0.552245 4.35938 0.668911 4.23438C0.785578 4.10938 0.92412 4.04688 1.08454 4.04688C1.24495 4.04688 1.38349 4.10938 1.50016 4.23438L3.571 6.46875Z"
        fill="#041220"
      />
    </svg>
  )
}

export default BlackTick
    