interface Props {
  children: React.ReactNode
  className?: string
}

const WhiteCard = ({ children, className }: Props) => {
  return <div className={`flex flex-col gap-2 rounded bg-white p-4 ${className}`}>{children}</div>
}

export default WhiteCard
