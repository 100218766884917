import React from 'react';

interface DividerProps {
  color?: string; // color is optional and defaults to 'border'
}

const Divider: React.FC<DividerProps> = ({ color = 'border' }) => {
  return (
    <div className={`mt-3 w-full rounded-md border border-b border-${color}`}></div>
  );
};

export default Divider;
