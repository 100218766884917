import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const DateSelection = ({  onFormDataChange,data }:any) => {
  const { t } = useTranslation();
  const [hasDateRange, setHasDateRange] = useState(data?.has_date_range ||false);
  const [hasFutureDate, setHasFutureDate] = useState(data?.has_future_date ||false);
  const [formData, setFormData] = useState({
    help_text: data?.help_text,
    has_date_range: hasDateRange,
    has_future_date: hasFutureDate,
  });

  const handleHasPrefixChange = () => {
    const newValue = !hasDateRange;
    setHasDateRange(newValue);
    setFormData((prevData) => ({
      ...prevData,
      has_date_range: newValue,
    }));
    onFormDataChange({
      ...formData,
      has_date_range: newValue, // Pass updated value to onFormDataChange
      hasFutureDate, // Pass current value of hasSuffix
    });
  };

  const handleHasSuffixChange = () => {
    const newValue = !hasFutureDate;
    setHasFutureDate(newValue);
    setFormData((prevData) => ({
      ...prevData,

      has_future_date: newValue,
    }));
    onFormDataChange({
      ...formData,
      has_future_date: newValue, // Pass updated value to onFormDataChange
      hasDateRange, // Pass current value of hasPrefix
    });
  };

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors();
  };
  useEffect(() => {
    onFormDataChange(formData);
  }, [formData]);

  return (
    <>
      <div>
        <div className="flex mt-6 gap-28">
          <div>
            <div className="p-sm-regular">{t("date_range")}</div>
            <p className="p-xs-regular-dim">
              {t(
                "enable_this_in_case_you_require_to_capture_an_interval_of_dates_for_example_you_can_use_this_to_capture_start_and_end_dates_of_a_batch"
              )}
            </p>
          </div>
          <div>
            <label className="switch">
              <input
                type="checkbox"
                checked={hasDateRange}
                onChange={handleHasPrefixChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div>
        <div className="flex mt-6 gap-28">
          <div>
            <div className="p-sm-regular">{t("enable_future_date")}</div>
            <p className="p-xs-regular-dim">
              {t("enable_this_in_case_you_require_to_capture_a_future_date")}
            </p>
          </div>
          <div>
            <label className="switch">
              <input
                type="checkbox"
                checked={hasFutureDate}
                onChange={handleHasSuffixChange}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <label className="mb-1 p-sm-regular">{t("help_text")}</label>
        <div>
          <p className="p-xs-regular-dim">
            {t("guide_the_person_filling_this_field")}
          </p>
          <div className="relative py-1">
            <input
              type="text"
              name="help_text"
              value={formData.help_text}
              onChange={handleInputChange}
              className={`bg-[#EBEFF3] w-[360px] h-[28px] border-[#E0E0E0] border-solid border rounded-md p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder={t("enter_help_text")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DateSelection;
function setErrors() {
  throw new Error("Function not implemented.");
}

