import UserRoles from 'assets/icons/dashboard/UserRoles'
import Users from 'assets/icons/dashboard/Users'
import Button from 'libs/button/Button'
import Card from 'libs/card/Card'
import FullPageLoader from 'libs/loader/FullPageLoader'
import MotionDiv from 'libs/motionDiv'
import { useNavigate } from 'react-router-dom'
import {
  useUserInfoQuery,
  useUserStatsQuery,
} from './users/user-endpoints/user.endpoint'
import { useRolesStatsQuery } from './roles/roles-endpoints/roles.endpoint'
import useTabTitle from 'libs/hooks/useTabTitle'

const UserManagement = () => {
  const navigate = useNavigate()
  useTabTitle('User-Management')

  const { data: userInfo, isLoading: userInfoLoading } = useUserInfoQuery({})
  const { data: usersCount, isFetching: userCountLoading } = useUserStatsQuery({
    org_id: userInfo?.organization?._id,
  })
  const { data: usersRolesCount, isFetching: userRolesCountLoading } =
    useRolesStatsQuery({ org_id: userInfo?.organization?._id })

  const cardDetails = [
    {
      title: 'Users',
      icon: <Users />,
      route: 'users',
      addroute: '/settings/user-management/users',
      total: `Total users: ${usersCount?.count}`,
      // TotalProcesses: `${usersCount?.paging?.total_items_count ?? 0}`,
    },
    {
      title: 'User Roles',
      icon: <UserRoles />,
      route: 'roles',
      addroute: '/settings/user-management/roles',
      total: `Total user roles: ${usersRolesCount?.count}`,
      //   TotalProcesses: `${rolesCount?.paging?.total_items_count ?? 0}`,
    },
  ]
  return (
    <MotionDiv>
      <div className="h-full">
        <h1 className="h4-bold-black">User Management</h1>
        <p className="mb-2 p-sm-regular">
          Manage user profiles and role-based permissions
        </p>
        <div className="relative h-full w-full">
          {userInfoLoading || userCountLoading || userRolesCountLoading ? (
            <div className="absolute left-64 top-16 flex items-center justify-center">
              <FullPageLoader />
            </div>
          ) : (
            <div className="mt-6 grid h-full w-full grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3">
              {cardDetails.map((card, index) => (
                <div key={`${index}_${card.title}`}>
                  <Card
                    title={card?.title}
                    subtitle={card.total}
                    //   totalCount={<span>{formatNumber(Number(card.TotalProcesses))}</span>}
                    leftIcon={card.icon}
                    rightCenterIcon={<Button color="primary"> View </Button>}
                    onClick={() => navigate(card.route)}
                    titleStyle="font-medium text-gray-800"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </MotionDiv>
  )
}

export default UserManagement
