import React, { useEffect } from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'

import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'

import { useGetShiftPolicyQuery } from 'pages/shift-management/shift-mangement-endpoints/shiftManagement.endpoints'
import { useGetAllProcessesQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  shiftPolicies: string[]
  shifts: number[]
  operations: number[]
}
interface FormValues {
  shiftPolicies: string[]
  shifts: number[]
  operations: number[]
}
const defaultFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const WorkCenterFilter: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const plant = useSelector(selectSelectedItem)

  const initialValues: FormValues = {
    shiftPolicies: filters.shiftPolicies ?? [],
    shifts: filters?.shifts ?? [],
    operations: filters?.operations ?? [],
  }

  const { data: shiftPolicies } = useGetShiftPolicyQuery(
    { plantId: plant?.id, filters: defaultFilters },
    { skip: !plant?.id },
  )
  const { data: operations } = useGetAllProcessesQuery(
    { plantId: plant?.id, filters: defaultFilters },
    { skip: !plant?.id },
  )

  const shiftPoliciesOptions = shiftPolicies?.data?.map((item: any) => {
    return {
      label: item?.policyName,
      value: item?._id,
    }
  })

  const operationsOptions = operations?.data?.map((item: any) => {
    return {
      label: item?.name,
      value: item?._id,
    }
  })

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      machineClass: [],
      shifts: [],
      sortBy: 'updated_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        shifts: values?.shifts == null ? [] : values?.shifts,
        operations: values?.operations?.length == null ? [] : values?.operations,
        shiftPolicies: values?.shiftPolicies,
      }))
    },
  })
  useEffect(() => {
    if (filterFormik?.values?.shiftPolicies) {
      filterFormik.setFieldValue('shifts', [])
    }
  }, [filterFormik?.values?.shiftPolicies])

  const shiftsOptions =
    shiftPolicies?.data
      ?.filter((item: any) => filterFormik?.values?.shiftPolicies?.includes(item?._id))
      .flatMap(
        (item: any) =>
          item?.shifts?.map((shift: any) => ({
            label: shift?.shiftName,
            value: shift?.id,
          })) || [],
      ) || []

  return (
    <>
      <div className="flex h-full flex-col gap-3">
        <Field
          type="multiselect"
          label="Shift Policies"
          placeholder="Select Shift Policy"
          options={shiftPoliciesOptions}
          formik={filterFormik}
          name="shiftPolicies"
        />
        {/* Created Date Range */}
        <Field
          type="multiselect"
          label="Shifts"
          placeholder="Select Shift"
          options={shiftsOptions}
          disabled={filterFormik?.values?.shiftPolicies?.length === 0}
          formik={filterFormik}
          name="shifts"
        />
        <Field
          type="multiselect"
          label="Operations"
          placeholder="Select Operation"
          options={operationsOptions}
          formik={filterFormik}
          name="operations"
        />
        <div className="my-auto mb-0 flex justify-between">
          <div>
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset Filters
            </Button>
          </div>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkCenterFilter
