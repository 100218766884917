const GreenCheckbox = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.18268 8.125L3.92852 6.78125C3.82157 6.66667 3.68546 6.60938 3.52018 6.60938C3.3549 6.60938 3.21879 6.66667 3.11185 6.78125C3.0049 6.89583 2.95143 7.04167 2.95143 7.21875C2.95143 7.39583 3.0049 7.54167 3.11185 7.65625L4.77435 9.4375C4.89102 9.5625 5.02713 9.625 5.18268 9.625C5.33824 9.625 5.47435 9.5625 5.59102 9.4375L8.88685 5.90625C8.99379 5.79167 9.04727 5.64583 9.04727 5.46875C9.04727 5.29167 8.99379 5.14583 8.88685 5.03125C8.7799 4.91667 8.64379 4.85938 8.47852 4.85938C8.31324 4.85938 8.17713 4.91667 8.07018 5.03125L5.18268 8.125ZM5.99935 13.25C5.1924 13.25 4.43407 13.0859 3.72435 12.7578C3.01463 12.4297 2.39727 11.9844 1.87227 11.4219C1.34727 10.8594 0.931641 10.1979 0.625391 9.4375C0.319141 8.67708 0.166016 7.86458 0.166016 7C0.166016 6.13542 0.319141 5.32292 0.625391 4.5625C0.931641 3.80208 1.34727 3.14062 1.87227 2.57812C2.39727 2.01562 3.01463 1.57031 3.72435 1.24219C4.43407 0.914062 5.1924 0.75 5.99935 0.75C6.80629 0.75 7.56463 0.914062 8.27435 1.24219C8.98407 1.57031 9.60143 2.01562 10.1264 2.57812C10.6514 3.14062 11.0671 3.80208 11.3733 4.5625C11.6796 5.32292 11.8327 6.13542 11.8327 7C11.8327 7.86458 11.6796 8.67708 11.3733 9.4375C11.0671 10.1979 10.6514 10.8594 10.1264 11.4219C9.60143 11.9844 8.98407 12.4297 8.27435 12.7578C7.56463 13.0859 6.80629 13.25 5.99935 13.25Z"
        fill="#28A745"
      />
    </svg>
  )
}

export default GreenCheckbox
