import SidePanel from 'libs/sidepanel/SidePanel'
import { useEffect, useState } from 'react'
import QuickAddEditPurchase from './QuickAddEditPurchase'

const QuickAddEditPurchaseOrder = ({
  id,
  isOpen,
  onClose,
  manufactureOrderFormik,
  data,
}: any) => {
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setValue(id)
    }
  }, [id, isOpen])

  const handleClose = () => {
    onClose(false)
    setValue(null)
  }

  return (
    <SidePanel
      size="large"
      isOpen={isOpen}
      title={value ? 'Quick Edit Purchase Order' : 'Quick Add Purchase Order'}
      onClose={handleClose}
    >
      <QuickAddEditPurchase
        close={handleClose}
        data={data}
        manufactureOrderFormik={manufactureOrderFormik}
        purchaseOrderID={value}
      />
    </SidePanel>
  )
}

export default QuickAddEditPurchaseOrder
