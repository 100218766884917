import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const phoneNumberValidator = (number: any): boolean => {
  if (!number) return false

  const normalizedValue = number.startsWith('+') ? number : `+${number}`

  const phoneNumberObject = parsePhoneNumberFromString(normalizedValue)

  const phoneNumber = phoneNumberObject?.nationalNumber

  const countryCode = phoneNumberObject?.country

  if (!phoneNumber || !countryCode) return false
  const parsedPhoneNumber: any = parsePhoneNumberFromString(phoneNumber, countryCode)
  return parsedPhoneNumber ? parsedPhoneNumber.isValid() : false
}

export interface PhoneNumberDetails {
  phoneNumber: string | null
  countryCode: string | null | undefined
}

export const countryPhoneNumber = (number: string): PhoneNumberDetails => {
  if (!number) return { phoneNumber: null, countryCode: null }

  // Normalize the phone number by ensuring it starts with '+'
  const normalizedValue = number.startsWith('+') ? number : `+${number}`

  const phoneNumberObject = parsePhoneNumberFromString(normalizedValue)

  if (!phoneNumberObject) return { phoneNumber: null, countryCode: null }

  const phoneNumber = phoneNumberObject.nationalNumber
  const countryCode = phoneNumberObject.country

  return { phoneNumber, countryCode }
}
