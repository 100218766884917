import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'libs/button/Button'
import Chip from 'libs/chip/Chip'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import QuickAddEditSalesOrder from 'libs/quick-add-edits/quick-add-edit-so/QuickAddEditSoPanel'
import FullPageLoader from 'libs/loader/FullPageLoader'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import { hasAccess } from 'utils/utils'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetCustomerByIDQuery } from '../customer-endpoints/customer.endpoints'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { SalesOrder } from '../customer-endpoints/customerTypes'
import { CellProps } from 'react-table'

const ViewSalesOrders = () => {
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const customerAccess = hasAccess('con', { cus: 'e' })
  const salesOrderAccess = hasAccess('ord', { so: 'e' })

  const { data: customer, isLoading: loading } = useGetCustomerByIDQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  const columns: ExtendedColumn<SalesOrder>[] = useMemo(
    () => [
      {
        Header: 'Sales Order ID',
        accessor: 'salesOrderID',
        disableSortBy: true,
        width: 150,
        Cell: ({ row }: CellProps<SalesOrder>) => {
          const salesorder = row?.original
          return (
            <div
              className="hyperlink"
              onClick={() => {
                salesOrderAccess &&
                  salesorder?.id &&
                  navigate(`/orders/sales-order/view/${salesorder?.id}`)
              }}
            >
              {salesorder?.salesOrderID ?? '-'}
            </div>
          )
        },
      },
      {
        Header: 'Product',
        accessor: 'products',
        disableSortBy: true,
        width: 150,
        Cell: ({ row }: CellProps<SalesOrder>) => {
          const product = row.original?.products?.length
          return (
            <div
              className="hyperlink"
              onClick={() => {
                salesOrderAccess &&
                  row?.original?.id &&
                  navigate(`/orders/sales-order/view/${row.original.id}`)
              }}
            >
              <span>{product} Products</span>
            </div>
          )
        },
      },

      {
        Header: 'Delivery Date',
        accessor: 'deliveryDate',
        disableSortBy: true,
        width: 100,
        Cell: ({ row }: CellProps<SalesOrder>) => {
          const formattedDate = formatUnixDate(row.original.deliveryDate)
          return (
            <div className="flex w-full justify-between">
              <span>{formattedDate}</span>
            </div>
          )
        },
      },

      {
        Header: 'Progress',
        accessor: 'status',
        width: 50,
        Cell: ({ row }: CellProps<SalesOrder>) => (
          <Chip title={row.original.status} />
        ),
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      {loading ? (
        <FullPageLoader />
      ) : (
        <>
          <WhiteCard className="flex min-h-[70vh] flex-col gap-6">
            <div className="flex w-full justify-between">
              <h5 className="h5">Sales Orders</h5>
              {customerAccess && (
                <div>
                  <Button
                    startIcon={<PlusIcon />}
                    color="primary"
                    onClick={() => {
                      setIsPanelOpen(true)
                    }}
                  >
                    {'Add Sales Order'}
                  </Button>
                </div>
              )}
            </div>
            <QuickAddEditSalesOrder
              onClose={() => setIsPanelOpen(false)}
              isOpen={isPanelOpen}
            />
            <div className="h-screen overflow-y-auto">
              <PaginatedTable
                columns={columns}
                rows={customer?.salesOrder}
                hidePagination={true}
                emptyMessage={
                  'No sales order found. Please add new sales order.'
                }
              />
            </div>
          </WhiteCard>
        </>
      )}
    </MotionDiv>
  )
}

export default ViewSalesOrders
