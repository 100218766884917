import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  PurchaseOrderResponse,
  PurchaseOrderRequest,
  PurchaseOrderMutationResponse,
  DeletePurchaseOrderRequestType,
  CreatePurchaseOrderRequestType,
  UpdatePurchaseOrderRequestType,
  PurchaseOrdersResponse,
  PurchaseOrdersRequest,
} from './purchaseOrdersTypes'
import {
  SatatisticsRequest,
  SatatisticsResponse,
} from 'pages/orders/sales-order/sales-order-endpoints/salesOrders.constants'

export const purchaseOrder = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postPurchaseOrder: builder.mutation<
      PurchaseOrderMutationResponse,
      CreatePurchaseOrderRequestType
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/purchase-order`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['purchaseOrderUpdate'],
    }),

    putPurchaseOrder: builder.mutation<
      PurchaseOrderMutationResponse,
      UpdatePurchaseOrderRequestType
    >({
      query: ({ plant_id, id, data }) => {
        return {
          url: `/plant/${plant_id}/purchase-order/${id}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['purchaseOrderUpdate'],
    }),

    deletePurchaseOrder: builder.mutation<
      PurchaseOrderMutationResponse,
      DeletePurchaseOrderRequestType
    >({
      query: ({ plant_id, id }) => ({
        url: `/plant/${plant_id}/purchase-order/${id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['purchaseOrderGetById'],
    }),

    getPurchaseOrders: builder.query<
      PurchaseOrdersResponse,
      PurchaseOrdersRequest
    >({
      query: ({ plant_id, filters }) => {
        return {
          url: buildUrl(`/plant/${plant_id}/purchase-orders`, filters),
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Purchase Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['purchaseOrderUpdate', 'purchaseOrderGetById'],
    }),

    getPurchaseOrderByID: builder.query<
      PurchaseOrderResponse,
      PurchaseOrderRequest
    >({
      query: ({ plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/purchase-order/${id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Sales Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['purchaseOrderUpdate'],
    }),

    getPurchaseOrdersStats: builder.query<
      SatatisticsResponse,
      SatatisticsRequest
    >({
      query: ({ plant_id }) => ({
        url: `/plant/${plant_id}/purchase-orders/stats`,
        method: 'GET',
        headers: getToken(),
      }),
      providesTags: ['purchaseOrderUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetPurchaseOrdersQuery,
  usePostPurchaseOrderMutation,
  useGetPurchaseOrderByIDQuery,
  usePutPurchaseOrderMutation,
  useDeletePurchaseOrderMutation,
  useGetPurchaseOrdersStatsQuery,
} = purchaseOrder
