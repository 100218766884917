import Button from 'libs/button/Button'

interface Props {
  closePopUp: any
  logout: any
}

function LogoutPopUP({ closePopUp, logout }: Props) {

  return (
    <div className='border-t-2'>
      <p className="p-xs-regular mt-2">
        <h1 className='text-lg'>Are you sure, you want to Logout?</h1>
        <h2 className='text-gray-600 mt-1'>Please ensure that you have saved your changes before logging out</h2>
      </p>
      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Cancel</Button>
        <Button color="error" onClick={logout}>
          Logout
        </Button>
      </div>
    </div>
  )
}

export default LogoutPopUP
