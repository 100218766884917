import MotionDiv from 'libs/motionDiv'
import icons from 'assets/index.ts'
import { useTranslation } from 'react-i18next'
import { getAuthTokenAndRole } from 'utils/auth'
import { useEffect, useState } from 'react'
import SelectDropdown from './SelectDropdown'

const items = [
  {
    key: '1',
    label: 'English',
    lan: 'en',
  },
  {
    key: '2',
    label: 'हिन्दी',
    lan: 'hi',
  },
]

const Appearance = () => {
  const { i18n } = useTranslation()

  useEffect(() => {}, [i18n.language])

  const [selectedLanguage, setSelectedLanguage] = useState(
    items.filter((e) => e.lan === localStorage.getItem('i18nextLng'))[0] ||
      items[0],
  )

  const handleSelectLanguage = (item: any) => {
    setSelectedLanguage(item)
    i18n.changeLanguage(item.lan)
  }

  const options = [
    {
      title: 'Language',
      subTitle:
        'Choose the language that Catalyst will display on your device.',
      icon: <img src={icons.Language} alt="" />,
      component: (
        <SelectDropdown
          items={items}
          selected={selectedLanguage}
          onSelect={handleSelectLanguage}
          inputWidth="100px"
          inputHeight="30px"
          dropdownWidth="200px"
          rightIconMargin="6rem"
          borderBottomStyle="1px solid #EBEFF3"
        />
      ),
      forAdminOnly: false,
    },
  ]
  const { role } = getAuthTokenAndRole()
  return (
    <MotionDiv>
      <div className="flex flex-col gap-6 p-6">
        {options.map((option) => {
          if (role === 'operator' && option.forAdminOnly) {
            return null
          }
          return (
            <div key={option.title} className="flex">
              <div className="flex w-3/4">
                <div>{option.icon}</div>
                <div className="ml-4">
                  <h2 className=" p-sm-regular">{option.title}</h2>
                  <p className=" p-xs-regular-dim">{option.subTitle}</p>
                </div>
              </div>
              <div className="flex w-1/4 justify-end">{option.component}</div>
            </div>
          )
        })}
      </div>
    </MotionDiv>
  )
}

export default Appearance
