
function RigtTickIcon({ color }:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <mask
        id="mask0_1458_8378"
        style={{ maskType: "alpha" }}
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_1458_8378)">
        <path
          fill={color ? `${color}` : "#1193F0"}
          d="M6.367 10.1l5.65-5.65a.65.65 0 01.475-.2c.183 0 .341.067.475.2.133.133.2.292.2.475a.649.649 0 01-.2.475l-6.134 6.133a.64.64 0 01-.466.2.64.64 0 01-.467-.2L3.033 8.667a.62.62 0 01-.191-.475.68.68 0 01.208-.475.649.649 0 01.475-.2c.183 0 .342.066.475.2L6.367 10.1z"
        ></path>
      </g>
    </svg>
  );
}

export default RigtTickIcon;
