import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import NewEditableTable from 'libs/table/NewEditableTable'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import Button from 'libs/button/Button'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'libs/toast'
import {
  usePostEmployeeDepartmentMutation,
  usePutEmployeeDepartmentMutation,
  useGetEmployeeDepartmentByIdQuery,
} from '../employee-departments-endpoints/employeesdepartment.endpoints'
import Field from 'libs/field'
import * as Yup from 'yup'
import FullPageLoader from 'libs/loader/FullPageLoader'

const departmentColumns = [
  {
    title: 'Designation Name',
    accessor: 'name',
    placeholder: 'Enter designation name',
    editable: true,
  },

  {
    title: 'Description (Optional)',
    accessor: 'description',
    placeholder: 'Enter description ',
    editable: true,
    flex: 2,
  },
]

const AddEmployeeDepartment = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [addEmployeeDepartment, { isLoading: addEmployeeDepartmentLoading }] = usePostEmployeeDepartmentMutation()
  const [updateEmployeeDepartment, { isLoading: updateEmployeeDepartmentLoading }] = usePutEmployeeDepartmentMutation()
  const { data: EmployeeData, isLoading: DepartmentLoading } = useGetEmployeeDepartmentByIdQuery(
    {
      plant_id: plant?.id,
      departmentID: id,
    },
    {
      skip: !plant?.id || !id,
    },
  )
  const EmployeeDepartmentFormik = useFormik({
    initialValues: {
      name: '',
      description: '',
      designations: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('department is required'),
    }),

    onSubmit: (values) => {
      const payload = {
        name: values.name,
        description: values.description ? values.description : '',
        designations: values.designations,
      }
      if (!id) {
        addEmployeeDepartment({ plant_id: plant.id, data: payload })
          .unwrap()
          .then(() => {
            toast.success(` ${values?.name} department is added successfully.`)
            navigate('/employee/employee-department')
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Failed to add department. Please try again.')
          })
      } else {
        updateEmployeeDepartment({ plant_id: plant.id, departmentID: id, data: payload })
          .unwrap()
          .then(() => {
            toast.success(` ${values?.name} department is updated successfully.`)
            navigate('/employee/employee-department')
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Failed to update department. Please try again.')
          })
      }
    },
  })

  const [data, setData] = useState([])

  const handleDepartment = () => {
    EmployeeDepartmentFormik?.setFieldValue('designations', data ? data : [])
    EmployeeDepartmentFormik.handleSubmit()
  }

  useEffect(() => {
    if (EmployeeData && id) {
      EmployeeDepartmentFormik.resetForm({
        values: {
          name: EmployeeData.name,
          description: EmployeeData.description,
          designations: EmployeeData.designations,
        },
      })
    }
  }, [EmployeeData])

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">{!id ? 'Add Department' : 'Edit Department'}</h1>
          </div>
        </div>

        {!DepartmentLoading ? (
          <WhiteCard className="mt-1">
            <div className="min-h-[70vh]">
              <h5 className="h5-1">{!id ? 'Department' : EmployeeData?.name}</h5>

              <Field
                label="Department"
                type="text"
                placeholder="Enter department name"
                name="name"
                formik={EmployeeDepartmentFormik}
                required
              />
              <Field
                label="Description"
                type="textarea"
                placeholder="Enter description"
                name="description"
                formik={EmployeeDepartmentFormik}
              />

              <h5 className="h5-1">{'Designations'}</h5>

              <div className="mt-4">
                <NewEditableTable
                  defaultRows={EmployeeDepartmentFormik?.values?.designations}
                  columns={departmentColumns}
                  addButtonTitle="Add Designation"
                  onChange={(values: any) => {
                    setData(values)
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <span className="flex gap-3">
                {' '}
                <Button
                  onClick={() => {
                    navigate('/employee/employee-department')
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  color="success"
                  onClick={() => {
                    handleDepartment()
                  }}
                  loading={addEmployeeDepartmentLoading || updateEmployeeDepartmentLoading}
                >
                  Save
                </Button>
              </span>
            </div>
          </WhiteCard>
        ) : (
          <FullPageLoader />
        )}
      </>
    </MotionDiv>
  )
}

export default AddEmployeeDepartment
