type Props = {
  size?: number
}

function CloseIcon({ size }: Props) {
  return (
    <svg
      width={size ?? '12'}
      height={size ?? '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9987 7.16683L1.91536 11.2502C1.76259 11.4029 1.56814 11.4793 1.33203 11.4793C1.09592 11.4793 0.901476 11.4029 0.748698 11.2502C0.59592 11.0974 0.519531 10.9029 0.519531 10.6668C0.519531 10.4307 0.59592 10.2363 0.748698 10.0835L4.83203 6.00016L0.748698 1.91683C0.59592 1.76405 0.519531 1.56961 0.519531 1.3335C0.519531 1.09739 0.59592 0.902941 0.748698 0.750163C0.901476 0.597385 1.09592 0.520996 1.33203 0.520996C1.56814 0.520996 1.76259 0.597385 1.91536 0.750163L5.9987 4.8335L10.082 0.750163C10.2348 0.597385 10.4293 0.520996 10.6654 0.520996C10.9015 0.520996 11.0959 0.597385 11.2487 0.750163C11.4015 0.902941 11.4779 1.09739 11.4779 1.3335C11.4779 1.56961 11.4015 1.76405 11.2487 1.91683L7.16536 6.00016L11.2487 10.0835C11.4015 10.2363 11.4779 10.4307 11.4779 10.6668C11.4779 10.9029 11.4015 11.0974 11.2487 11.2502C11.0959 11.4029 10.9015 11.4793 10.6654 11.4793C10.4293 11.4793 10.2348 11.4029 10.082 11.2502L5.9987 7.16683Z"
        fill="#041220"
      />
    </svg>
  )
}

export default CloseIcon
