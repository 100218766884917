import { Navigate } from 'react-router-dom'
import Sidebar from 'libs/layouts/dashboard/LeftSideMenu.tsx'
import App from '../app.tsx'
import Cookies from 'js-cookie'
import FullPageLoader from 'libs/loader/FullPageLoader.tsx'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint.ts'

const PrivateRoutes = () => {
  const accessToken = Cookies.get('access_token')

  const { isLoading } = useUserInfoQuery({}, { skip: !accessToken })

  return isLoading || isLoading === undefined ? (
    <div className="flex min-h-screen items-center justify-center">
      <FullPageLoader />
    </div>
  ) : accessToken ? (
    <>
      <App />
      <Sidebar />
    </>
  ) : (
    <Navigate to="/auth/login" />
  )
}

export default PrivateRoutes
