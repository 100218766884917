import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { Filters } from '../manufacture-orders-endpoints/manufactureordersTypes'
import { getTableConfig } from '../columns/ManufactureOrderColumns'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'

interface Props {
  closePanel: () => void
  setFilters: any
  filters: Filters
}

const MoFilterPanel = ({ closePanel, filters, setFilters }: Props) => {
  const plantId = useSelector(selectSelectedItem)
  const { options } = getTableConfig()

  const productfilters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'updated_on',
    sortDir: 'DESC',
    searchText: '',
  }

  const { data: products } = useGetProductQuery(
    {
      plantId: plantId?.id,
      filters: productfilters,
    },
    {
      skip: !plantId?.id,
    },
  )

  const allProduct = products?.data
    ?.filter((item: any) => item.category === 'Finished Goods')
    .map((item: any) => ({
      label: item.name,
      value: item._id,
    }))

  const initialValues = {
    status: filters?.status,
    finished_goods: filters?.finished_goods?.length == 0 ? [] : filters?.finished_goods,
    startBetween: filters?.startBetween?.length == 0 ? [] : filters?.startBetween,
    endBetween: filters?.endBetween?.length == 0 ? [] : filters?.endBetween,
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      createdBetween: [],
      updatedBetween: [],
      startBetween: [],
      endBetween: [],
      status: undefined,
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  const filterFormik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        status: values?.status,
        finished_goods: values?.finished_goods,
        startBetween: values?.startBetween?.length == 0 ? [] : values?.startBetween,
        endBetween: values?.endBetween?.length == 0 ? [] : values?.endBetween,
        createdBetween: values?.createdBetween == null ? [] : values?.createdBetween,
        updatedBetween: values?.updatedBetween == null ? [] : values?.updatedBetween,
      }))
    },
  })

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Created Date Range */}
        <Field type="dateRange" fullWidth label="Start Date" formik={filterFormik} name="startBetween" />
        {/* Update Date Range */}
        <Field type="dateRange" fullWidth label="End Date" formik={filterFormik} name="endBetween" />

        <Field
          type="multiselect"
          label="Status"
          placeholder="Show All"
          options={options}
          formik={filterFormik}
          name="status"
        />
        <Field
          type="multiselect"
          label="Finished Good"
          placeholder="Show All"
          options={allProduct}
          formik={filterFormik}
          name="finished_goods"
        />
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date Created"
          formik={filterFormik}
          name="createdBetween"
        />
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last Edited"
          formik={filterFormik}
          name="updatedBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset Filters
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            type="submit"
            color="primary"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default MoFilterPanel
