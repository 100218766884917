import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table'
import Loader from 'libs/loader/Loader'
import icons from 'assets/index'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import FullPageLoader from 'libs/loader/FullPageLoader'

interface ScrollableTableProps {
  emptyMessage: string
  noSearchResultPlaceholder?: any
  columns: any[]
  rows: any[]
  pageSize: number
  viewRow: (row: any) => void
  handleSort?: any
  loading: boolean
  fetching: boolean
  handleTableScroll: () => void
  pagination: any
  originalData?: any[]
  onDoubleClick?: (row: any) => void
  checkbox?: boolean
  handleCheckboxClick?: (row: any) => void
  batchData?: any[]
  handleSelectAllRows?: (rows: any[]) => void
  arrow?: boolean
}

const ScrollableTable: React.FC<ScrollableTableProps> = ({
  emptyMessage,
  noSearchResultPlaceholder,
  columns,
  rows: initialData,
  viewRow,
  handleSort,
  loading,
  fetching,
  handleTableScroll,
  pagination,
  checkbox = false,
  handleCheckboxClick,
  batchData = [],
  handleSelectAllRows,
  arrow = false,
}) => {
  const data = useMemo(() => initialData || [], [initialData])

  const { getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns: columns,
      data: data || [],
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  ) as any
  const [expandedRows, setExpandedRows] = useState<any>({})

  const toggleRowExpansion = (rowIndex: any) => {
    setExpandedRows((prevState: any) => {
      if (prevState[rowIndex]) {
        return {}
      } else {
        return { [rowIndex]: true }
      }
    })
  }

  //making table columns resizable
  useEffect(() => {
    const resizableColumns = document.querySelectorAll('.resizable-table th')

    resizableColumns?.forEach((col: any) => {
      let startX: any, startWidth: any

      col.addEventListener('mousedown', (e: any) => {
        startX = e.pageX
        startWidth = col.offsetWidth

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
      })

      function onMouseMove(e: any) {
        const newWidth = startWidth + (e.pageX - startX)
        col.style.width = newWidth + 'px'
      }

      function onMouseUp() {
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }
    })
  }, [])

  const clickDelay = 200

  let clickTimer: any

  const totalNotApprovedRows = rows
    ?.filter((entry: any) => entry?.original?.verified_by === null)
    .map((entry: any) => entry.original?._id)

  //scrollable logic
  const [isLastRow, setIsLastRow] = useState(false)
  const observer = useRef<IntersectionObserver | null>(null)
  const lastRowRef = useCallback(
    (node: any) => {
      if (fetching || loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (pagination.current_page < pagination.total_pages) {
            handleTableScroll()
          } else {
            setIsLastRow(true)
          }
        }
      })
      if (node && observer && observer.current) {
        observer.current.observe(node)
      }
    },
    [fetching, initialData],
  )

  // for the last row loader added 2 seconds delay to make it smoother
  const [showLoader, setShowLoader] = useState(fetching)

  useEffect(() => {
    if (fetching) {
      setShowLoader(true)
    } else {
      const timeout = setTimeout(() => {
        setShowLoader(false)
      }, 2000) // 2 seconds delay

      return () => clearTimeout(timeout) // Cleanup timeout if fetching changes again
    }
  }, [fetching])

  return (
    <div className="table-scroll-bar relative max-h-full overflow-y-auto overflow-x-hidden rounded-b-md">
      <div className=" bg-white ">
        <table {...getTableProps()} className="resizable-te table  min-w-full">
          <thead className="sticky top-0 rounded-t-md bg-[#0E1B28]">
            {headerGroups?.map((headerGroup: any, index: number) => (
              <tr
                key={index}
                style={{ borderTopLeftRadius: '8px' }}
                className="sticky overflow-hidden "
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup?.headers.map((column: any, columnIndex: any) => (
                  <th
                    key={columnIndex}
                    {...column?.getHeaderProps(column.getSortByToggleProps({}))}
                    className={`text-start p-xs-regular ${
                      columnIndex === headerGroup.headers.length - 1
                        ? 'sticky-header1 rounded-tr-md'
                        : ''
                    } ${columnIndex == 0 && ' rounded-tl-md'} `}
                    onClick={() => handleSort(column?.id)}
                  >
                    <div
                      className={`flex  items-center gap-1 ${column.align === 'right' ? ' justify-end ' : 'justify-start'} ${column.align === 'center' && ' justify-center '} border-[#75838D] px-2 py-2 text-start  p-xs-regular  ${
                        columnIndex === headerGroup.headers.length - 1
                          ? 'border-r-0'
                          : 'border-r-2'
                      }`}
                      style={{
                        minWidth: column.width,
                        maxWidth: 'auto',
                        color: '#FFFFFF',
                      }}
                    >
                      {checkbox && columnIndex === 0 && (
                        <span className="ml-2 mr-2 mt-[3px]">
                          <input
                            type="checkbox"
                            checked={
                              batchData.length === 0
                                ? false
                                : totalNotApprovedRows.length ===
                                  batchData.length
                            }
                            onChange={() =>
                              handleSelectAllRows && handleSelectAllRows(rows)
                            }
                          />
                        </span>
                      )}
                      {column?.render('Header')}

                      {column.disableSortBy !== true && (
                        <span className="ml-2 mt-[1px]">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <img src={icons.SortData} alt="" />
                            ) : (
                              <img
                                src={icons.SortData}
                                alt=""
                                style={{ transform: 'rotate(180deg)' }}
                              />
                            )
                          ) : (
                            <img src={icons.SortData} alt="" />
                          )}
                        </span>
                      )}
                      {columnIndex === headerGroup.headers.length - 1 && (
                        <span></span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows?.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="p-4 text-center p-md-regular"
                >
                  {noSearchResultPlaceholder
                    ? noSearchResultPlaceholder
                    : emptyMessage}
                </td>
              </tr>
            ) : (
              rows?.map((row: any, rowIndex: any) => {
                prepareRow(row)
                const isEvenRow = rowIndex % 2 === 0
                const isExpanded = expandedRows[rowIndex]
                return (
                  <>
                    <tr
                      key={rowIndex}
                      ref={rows.length - 1 == rowIndex ? lastRowRef : undefined}
                      className={`
                        sticky-row1
                        cursor-pointer border-x border-gray-100  hover:bg-primary-200 active:bg-primary-300 ${
                          isEvenRow ? 'bg-white' : 'bg-gray-100'
                        } `}
                      {...row.getRowProps()}
                      onClick={() => {
                        clearTimeout(clickTimer) // Clear any existing timeout
                        clickTimer = setTimeout(() => {
                          viewRow(row.original)
                          toggleRowExpansion(row.index)
                          // Perform single-click action after the delay
                        }, clickDelay)
                      }}
                    >
                      {row.cells.map((cell: any, cellIndex: any) => (
                        <td
                          key={cellIndex}
                          {...cell.getCellProps()}
                          className={`border-b border-gray-100 px-3 py-2 p-xs-regular ${
                            cellIndex === row.cells.length - 1 ? '' : ''
                          } ${cellIndex === row.cells.length - 1 && !isEvenRow ? '' : ''}`}
                          style={{ width: cell.column.width }}
                        >
                          <div
                            className={`flex flex-row px-2 py-2 ${cell?.column?.align === 'right' ? ' justify-end ' : 'justify-start'} ${cell?.column?.align === 'center' ? ' justify-center ' : ''} `}
                          >
                            {checkbox && cellIndex === 0 ? (
                              <div className="flex gap-2">
                                <input
                                  type="checkbox"
                                  className="ml-2"
                                  checked={batchData?.some(
                                    (item) => item === row?.original?._id,
                                  )}
                                  onChange={() => {
                                    if (!row?.original?.verified_by !== null)
                                      handleCheckboxClick &&
                                        handleCheckboxClick(row?.original)
                                  }}
                                  disabled={row?.original?.verified_by !== null}
                                />
                                {cell.render('Cell')}
                              </div>
                            ) : arrow && cellIndex === 0 ? (
                              <div className="flex items-center gap-3">
                                <span
                                  className={`flex items-center justify-center duration-200 ${!isExpanded && '-rotate-90'}`}
                                >
                                  <ArrowDown color="" />
                                </span>
                                {cell.render('Cell')}
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>
                    {showLoader && rowIndex === rows.length - 1 && (
                      <tr>
                        <td colSpan={columns.length} className="p-4 ">
                          <div className="flex animate-pulse items-center justify-center  ">
                            <span className="flex h-12 w-12 items-center justify-center">
                              <Loader />
                            </span>
                          </div>
                        </td>
                      </tr>
                    )}
                    {isLastRow && rowIndex === rows.length - 1 && (
                      <tr>
                        <td
                          colSpan={columns.length}
                          className="p-4 text-center p-md-regular"
                        >
                          Reached end of the table
                        </td>
                      </tr>
                    )}
                  </>
                )
              })
            )}
          </tbody>
        </table>
        {loading && (
          <div className="h-[100vh]">
            <FullPageLoader />
          </div>
        )}
      </div>
    </div>
  )
}

export default ScrollableTable
