import { useDeleteProductMutation } from '../products-endpoints/products.endpoints'
import { toastSuccess } from 'libs/toast/toastSucess'
import { toastFailure } from 'libs/toast/toastFailure'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Button from 'libs/button/Button'

function DeleteEntry({ toggleDelete, data, product }: any) {
  const location = useLocation()
  const navigate = useNavigate()
  const [deleteProduct, { isLoading }] = useDeleteProductMutation()

  const handleDelete = () => {
    if (data?._id || product?._id) {
      deleteProduct({ productId: data?._id || product?._id })
        .unwrap()
        .then(() => {
          toastSuccess(`Product  "${data?.name || product?.name}" is removed successfully.`)

          toggleDelete(data?._id || product?._id)

          if (location.pathname === '/settings/configure/products/editProducts') {
            navigate('/settings/configure/products')
          }
        })
        .catch((err) => {
          toastFailure(err?.data?.detail ?? `Failed to remove ${data.name || product?.name} product. Please try again.`)
        })
    }
  }

  return (
    <div>
      <div>
        <p className="p-xs-regular">
          Are you sure you want to delete the
          <span className="break-words p-xs-bold"> "{data?.name || product?.name}" </span>
          product? It is used in the following processes
        </p>
        <div className="mb-2 mt-2 flex">
          {data?.associatedProcess?.map((item: any) => (
            <div className="mb-1 mr-1 rounded-[28px] bg-[#F2F2F2] px-2 py-0.5 text-[#434C52] p-xs-regular">{item}</div>
          ))}
        </div>
        <p className="p-xs-regular">
          Your historical data related to containers of this type will remain unaffected. However, you may have to
          ensure that the affected process have a replacement for this container.
        </p>
        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={toggleDelete}>Close</Button>
          <Button color="error" onClick={handleDelete} loading={isLoading}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeleteEntry
