import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import Card from 'libs/card/Card'
import MotionDiv from 'libs/motionDiv'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import icons from 'assets/index.ts'
import useTabTitle from 'libs/hooks/useTabTitle'
import { hasAccess } from 'utils/utils'
import { useGetCustomersCountQuery } from './customer-management/customer-endpoints/customer.endpoints'
import { useGetVendorsCountQuery } from './vendor-management/vendor-endpoints/vendor.endpoints'

// Define types for CardDetail and vendorsCount if needed
interface CardDetail {
  icon: React.ReactNode
  title: string
  subtitle: string
  route: string
  disabled: boolean
}

// Define types for props if necessary
const Contact: React.FC = () => {
  useTabTitle('Contacts')
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()

  const { data: customersCount, isLoading: isCustomerCountLoading } =
    useGetCustomersCountQuery(
      {
        plant_id: plant?.id,
      },
      {
        skip: !plant.id,
      },
    )

  const { data: vendorsCount, isLoading: vendorsLoading } =
    useGetVendorsCountQuery(
      { plant_id: plant?.id },
      {
        skip: !plant?.id,
      },
    )
  const customerAccess = hasAccess('con', { cus: 'v' })
  const vendorAccess = hasAccess('con', { ven: 'v' })

  // Define cardDetails with proper types
  const cardDetails: CardDetail[] = [
    customerAccess && {
      icon: <img src={icons.Customer} alt="" />,
      title: 'Customers',
      subtitle: `${customersCount ? customersCount.count : '-'} Customers`,
      route: '/contacts/customer-management',
      disabled: false,
    },

    vendorAccess && {
      icon: <img src={icons.Vendor} alt="" />,
      title: 'Vendors',
      subtitle: `${vendorsCount ? vendorsCount.count : '-'} Vendors`,
      route: '/contacts/vendor-management',
      disabled: false,
    },
  ].filter(Boolean) as CardDetail[] // Ensure proper type for filtered array

  return (
    <MotionDiv>
      <div className="h-full">
        <h1 className="h4-bold-black">Contacts</h1>

        {isCustomerCountLoading || vendorsLoading ? (
          <div>
            <FullPageLoader />
          </div>
        ) : (
          <div className="relative h-full w-full">
            <div className="grid h-full w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {cardDetails.map((card, index) => (
                <div key={`${index}_${card.title}`}>
                  <Card
                    disabled={card.disabled}
                    title={card.title}
                    subtitle={card.subtitle}
                    leftIcon={card.icon}
                    rightCenterIcon={<Button color="primary">View</Button>}
                    onClick={() => navigate(card.route)}
                    titleStyle="font-medium text-gray-800"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </MotionDiv>
  )
}

export default Contact
