function Contact({ color }: { color?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <path
        fill={color || '#1193F0'}
        d="M6.99992 7.00004C6.08325 7.00004 5.29853 6.67365 4.64575 6.02087C3.99297 5.3681 3.66659 4.58337 3.66659 3.66671C3.66659 2.75004 3.99297 1.96532 4.64575 1.31254C5.29853 0.659763 6.08325 0.333374 6.99992 0.333374C7.91658 0.333374 8.70131 0.659763 9.35408 1.31254C10.0069 1.96532 10.3333 2.75004 10.3333 3.66671C10.3333 4.58337 10.0069 5.3681 9.35408 6.02087C8.70131 6.67365 7.91658 7.00004 6.99992 7.00004ZM0.333252 12V11.3334C0.333252 10.8612 0.45478 10.4271 0.697835 10.0313C0.940891 9.63546 1.26381 9.33337 1.66659 9.12504C2.5277 8.69448 3.4027 8.37157 4.29159 8.15629C5.18047 7.94101 6.08325 7.83337 6.99992 7.83337C7.91658 7.83337 8.81936 7.94101 9.70825 8.15629C10.5971 8.37157 11.4721 8.69448 12.3333 9.12504C12.736 9.33337 13.0589 9.63546 13.302 10.0313C13.5451 10.4271 13.6666 10.8612 13.6666 11.3334V12C13.6666 12.4584 13.5034 12.8507 13.177 13.1771C12.8506 13.5035 12.4583 13.6667 11.9999 13.6667H1.99992C1.54159 13.6667 1.14922 13.5035 0.822835 13.1771C0.496446 12.8507 0.333252 12.4584 0.333252 12ZM1.99992 12H11.9999V11.3334C11.9999 11.1806 11.9617 11.0417 11.8853 10.9167C11.8089 10.7917 11.7083 10.6945 11.5833 10.625C10.8333 10.25 10.0763 9.96879 9.31242 9.78129C8.54853 9.59379 7.7777 9.50004 6.99992 9.50004C6.22214 9.50004 5.45131 9.59379 4.68742 9.78129C3.92353 9.96879 3.16659 10.25 2.41659 10.625C2.29159 10.6945 2.19089 10.7917 2.1145 10.9167C2.03811 11.0417 1.99992 11.1806 1.99992 11.3334V12ZM6.99992 5.33337C7.45825 5.33337 7.85061 5.17018 8.177 4.84379C8.50339 4.5174 8.66658 4.12504 8.66658 3.66671C8.66658 3.20837 8.50339 2.81601 8.177 2.48962C7.85061 2.16324 7.45825 2.00004 6.99992 2.00004C6.54158 2.00004 6.14922 2.16324 5.82284 2.48962C5.49645 2.81601 5.33325 3.20837 5.33325 3.66671C5.33325 4.12504 5.49645 4.5174 5.82284 4.84379C6.14922 5.17018 6.54158 5.33337 6.99992 5.33337Z"
      />
    </svg>
  )
}

export default Contact
