import { useCallback } from 'react'
import { t } from 'i18next'
import { formatUnixDate, formatUnixTime } from 'utils/dateTimeHandlers'
import { formatNumber } from 'utils/numberHandlers'
import Chip from 'libs/chip/Chip'

const useColumn = (timezone: string) => {
  const stockColumns = useCallback(
    () => [
      {
        Header: t('inventory_.product_code'),
        accessor: 'code',
        disableSortBy: true,
        width: 30,
        Cell: ({ row }: any) => {
          return (
            <div className="flex text-wrap">{row.original?.code ?? '-'}</div>
          )
        },
      },
      {
        Header: t('inventory_.product_name'),
        accessor: 'name',
        disableSortBy: true,
        width: 30,
        Cell: ({ row }: any) => {
          return (
            <div>
              <div className="flex">{row.original?.name}</div>
            </div>
          )
        },
      },

      {
        Header: () => (
          <span className="flex w-full items-end justify-end">
            {t('inventory_.quantity')}
          </span>
        ),
        accessor: 'quantity',
        Cell: ({ value }: any) => {
          return (
            <span className="flex w-full items-end justify-end font-mono	">
              {' '}
              {formatNumber(value)}
            </span>
          )
        },
        disableSortBy: true,
        width: 20,
      },
      {
        Header: t('inventory_.unit'),
        accessor: 'unit_of_measure',
        Cell: ({ value }: any) => {
          return <div className=" -ml-2">{value}</div>
        },
        disableSortBy: true,
        width: 25,
      },
    ],
    [],
  )
  const activitiesColumns = useCallback(
    () => [
      {
        Header: t('inventory_.product_code'),
        accessor: 'inventory.code',
        disableSortBy: true,
        width: 100,
        Cell: ({ value }: any) => {
          return <div>{value}</div>
        },
      },
      {
        Header: t('inventory_.product_name'),
        accessor: 'inventory.name',
        disableSortBy: true,
        width: 30,
        Cell: ({ value }: any) => {
          return (
            <div>
              <div className="flex gap-3">{value}</div>
            </div>
          )
        },
      },

      {
        Header: () => (
          <span className="flex w-full items-end justify-end">
            {t('inventory_.quantity')}
          </span>
        ),
        accessor: 'quantity',
        Cell: ({ value, row }: any) => {
          let _value = formatNumber(value)

          return (
            <span className="flex w-full items-end justify-end font-mono">
              {row?.original?.warehouseFrom ? '-' : '+'}
              {_value}
            </span>
          )
        },
        disableSortBy: true,
        width: 20,
      },
      {
        Header: () => (
          <span className="flex  w-full items-start justify-start">
            {t('inventory_.unit')}
          </span>
        ),
        accessor: 'inventory.unit_of_measure',
        Cell: ({ value }: any) => {
          return <div className=" -ml-2">{value}</div>
        },
        disableSortBy: true,
        width: 25,
      },
      {
        Header: 'Transaction Type',
        accessor: 'transactionType',
        Cell: ({ value }: any) => <Chip title={value} />,
        disableSortBy: true,
        width: 20,
      },
      {
        Header: 'Date/Time',
        accessor: 'transactionDate',
        Cell: ({ value }: any) => {
          return (
            <div className="flex flex-col space-y-1">
              <span className="text-wrap text-xs font-semibold">
                {formatUnixDate(value, timezone)}
              </span>
              <span className="text-xs font-medium text-gray-600">
                {formatUnixTime(value)}
              </span>
            </div>
          )
        },
        width: 100,
        disableSortBy: true,
      },
    ],
    [],
  )
  return { stockColumns, activitiesColumns }
}

export default useColumn
