import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import icons from 'assets/index'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import {
  useAddOperationalProcessEntryMutation,
  useApproveByMutation,
} from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'

import { toast } from 'libs/toast'
import { useGetWarehousesQuery } from 'pages/warehouses/warehouses-endpoints/warehouses.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import MotionDiv from 'libs/motionDiv'
import WhiteCard from 'libs/card/WhiteCard'
import * as yup from 'yup'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { trackEvent } from 'libs/layouts/mixpanel/mixPanel'
import { calculate_field_quantity } from 'pages/operational-processes/utils'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import useAddOptionsData from 'pages/productionV2/entries/useAddOptionsData'
import { useGetWorkOrderByIDQuery } from '../work-orders-endpoints/workorders.endpoints'
import { formatNumber } from 'utils/numberHandlers'
import { WarehouseOption } from 'pages/warehouses/warehouses-endpoints/warehouseTypes'

interface Props {
  type: 'INPUT' | 'OUTPUT'
}
interface Option {
  value: any
  unit_of_measure: any
  is_accepting_decimal: any
}

const warehouseFilters = {
  page_no: 1,
  page_size: 1000,
  search_text: '',
  sort_by: 'created_on',
  sort_dir: 'DESC',
}

const AddInputOutputWorkOrderEntries = ({ type }: Props) => {
  const { data: userInfo } = useUserInfoQuery({})

  const params = useParams()

  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [approve, { isLoading: isApprove }] = useApproveByMutation()

  const { data: WorkOrderData, isLoading: workOrderLoading } =
    useGetWorkOrderByIDQuery(
      {
        plant_id: plant?.id,
        woID: params?.id,
      },
      {
        skip: !plant?.id || !params?.id,
      },
    )

  const [addOperationalProcess, { isLoading }] =
    useAddOperationalProcessEntryMutation()

  const operationsOptions = useMemo(() => {
    return WorkOrderData?.operations.map((item: any) => {
      return { label: item?.name, value: item?._id }
    })
  }, [WorkOrderData])

  useTabTitle('Add Output Entry')

  const [validationSchema, setValidationSchema] = useState<any>(
    yup.object().shape({
      product_id: yup.string().required('Product is required'),
      quantity: yup
        .number()
        .required('Quantity is required')
        .min(1, 'Quantity can not be 0'),
    }),
  )
  const formik = useFormik<any>({
    initialValues: {
      process_id: null,
      product_id: null,
      warehouse_from_id: null,
      storage_area_from_id: null,
      warehouse_to_id: null,
      storage_area_to_id: null,
      quantity: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Destructure known fields from values
      const {
        process_id,
        product_id,
        quantity,
        storage_area_from_id,
        warehouse_from_id,
        warehouse_to_id,
        storage_area_to_id,

        ...rest
      } = values

      const data = fieldDetails?.map(
        (field: { type: string; id: any; name: string | number }) => {
          if (field.type == 'Calculated field')
            return {
              field_id: field.id,
              value: calculate_field_quantity(field, values),
            }
          const value = rest[field.name]

          if (value !== undefined) {
            if (typeof value === 'object' && value !== null && value.name) {
              return {
                field_id: field.id,
                value: value.name,
              }
            } else {
              return {
                field_id: field.id,
                value: value,
              }
            }
          }
          return
        },
      )

      // const field = trimStringValues(data)

      // Create the body object in the required format
      const body: any = {
        process_id: process_id,
        product_id: product_id,
        product_type: type,
        warehouse_from_id: warehouse_from_id ? warehouse_from_id : undefined,
        storage_area_from_id: storage_area_from_id
          ? storage_area_from_id
          : undefined,
        warehouse_to_id: warehouse_to_id ? warehouse_to_id : undefined,
        storage_area_to_id: storage_area_to_id ? storage_area_to_id : undefined,
        quantity: quantity,
        workOrderID: params?.id ? params?.id : undefined,
        data: data,
      }

      // Call the function to add operational process with the constructed body
      addOperationalProcess(body)
        .unwrap()
        .then((res: any) => {
          approve({ entryIds: [res] })
            .unwrap()
            .then(() => {
              toast.success(
                'The new entry was successfully created & verified.',
              )

              navigate(-1)
              trackEvent('Process Entry', {
                Entry: 'Add Entry',
                Department: department_name,
                Process: process_name,
                Count: '1',
                Plant: plant?.name,
                organization: userInfo?.organization?.name,
              })
            })
        })
        .catch((err) => {
          toast.error(
            err?.data?.detail ?? 'Something went wrong. Please try again',
          )
        })
    },
  })

  const {
    process_name,
    inputOptions,
    outputOptions,
    handleSelectWareHouse,
    handleProductField,
    fieldDetails,
    department_name,
  } = useAddOptionsData(formik.values.process_id)

  useEffect(() => {
    setValidationSchema(yup.object().shape({}))
  }, [formik.values.product_id])

  const is_accepting_decimal = useMemo(() => {
    const options: Option[] =
      type === 'INPUT' ? inputOptions : type === 'OUTPUT' ? outputOptions : []
    return options.find(
      (item: Option) => item.value === formik.values.product_id,
    )?.is_accepting_decimal
  }, [inputOptions, outputOptions, formik.values.product_id, type])

  // setting daynamic validation schema for warehouse and storage area based on the type of entry (input/output)
  useEffect(() => {
    setValidationSchema(
      yup.object().shape({
        product_id: yup.string().required('Product is required'),
        quantity: is_accepting_decimal
          ? yup
              .number()
              .required('Quantity is required')
              .min(1, 'Quantity cannot be 0')
          : yup
              .number()
              .integer(
                'This product does not accept decimal values. Please enter a whole number.',
              )
              .required('Quantity is required')
              .min(1, 'Quantity cannot be 0'),
      }),
    )
    if (type === 'INPUT') {
      setValidationSchema((prevSchema: any) =>
        prevSchema.shape({
          warehouse_from_id: yup
            .string()
            .required('Warehouse from is required'),
          storage_area_from_id: yup
            .string()
            .required('Storage area from is required'),
        }),
      )
    } else if (type === 'OUTPUT') {
      setValidationSchema((prevSchema: any) =>
        prevSchema.shape({
          warehouse_to_id: yup.string().required('Warehouse to is required'),
          storage_area_to_id: yup
            .string()
            .required('Storage area to is required'),
        }),
      )
    }
  }, [type, formik.values.product_id])

  useEffect(() => {
    if (fieldDetails?.length > 0) {
      const validationObj = {} as any
      fieldDetails.forEach(
        (field: {
          type: string
          name: string
          is_required: any
          has_date_range: any
          has_maximum_value: any
          has_minimum_value: any
          min_value: number | yup.Reference<number> | null
          max_value: number | yup.Reference<number> | null
        }) => {
          if (field.type === 'Checkbox') {
            formik.setFieldValue(field.name, false)
          } else if (
            field.type === 'Time selection' ||
            field.type === 'Date selection'
          ) {
            formik.setFieldValue(field.name, null)
            if (field.is_required) {
              if (field.has_date_range) {
                validationObj[field.name] = yup
                  .array()
                  .required(`${field.name} is required`)
              } else {
                validationObj[field.name] = yup
                  .number()
                  .required(`${field.name} is required`)
              }
              // eslint-disable-next-line no-dupe-else-if
            }
          } else if (field.type === 'Number input') {
            if (field.is_required) {
              formik.setFieldValue(field.name, '')
              validationObj[field.name] = yup
                .string()
                .required(`${field.name} is required`)
            }
            if (!field.is_required) {
              formik.setFieldValue(field.name, '')
            }
            if (field.has_maximum_value || field.has_minimum_value) {
              let validator = yup.number()
              if (field.min_value !== null && field.has_minimum_value) {
                validator = validator.min(
                  field.min_value,
                  `${field.name} must be at least ${field.min_value}`,
                )
              }
              if (field.max_value !== null && field.has_maximum_value) {
                validator = validator.max(
                  field.max_value,
                  `${field.name} cannot be more than ${field.max_value}`,
                )
              }
              if (field.is_required) {
                validator = validator.required(`${field.name} is required`)
              }
              validationObj[field.name] = validator
            }
          } else {
            formik.setFieldValue(field.name, '')
            if (field.is_required) {
              validationObj[field.name] = yup
                .string()
                .required(`${field.name} is required`)
            }
          }
        },
      )
      setValidationSchema(
        validationSchema.concat(yup.object().shape(validationObj)),
      )
    }
  }, [fieldDetails])

  const { data: warehouses } = useGetWarehousesQuery(
    {
      plant_id: plant?.id,
      filters: warehouseFilters,
    },
    { skip: !plant?.id },
  )

  // setting custom fields on selecting input/output product
  useEffect(() => {
    if (type == 'INPUT' && formik.values.warehouse_from_id)
      handleSelectWareHouse(formik.values.warehouse_from_id)
    if (type == 'OUTPUT' && formik.values.warehouse_to_id)
      handleSelectWareHouse(formik.values.warehouse_to_id)
    if (formik.values.product_id)
      handleProductField(formik.values.product_id, type)
  }, [formik.values])

  const [storageAreaOptions, setStorageAreaOptions] = useState<
    {
      label: string
      value: string
      disabled: boolean
      sublabel: string
    }[]
  >([])
  const [warehouseOptions, setWarehouseOptions] = useState<WarehouseOption[]>(
    [],
  )
  const [availableQuantity, setAvailableQuantity] = useState<string>()

  // list only those warehouse which have at least one storage area with the selected product, then set the options in warehouseOptions also save the storage area options in storageAreaOptions
  useEffect(() => {
    const allowedWarehouses = warehouses?.data?.filter((warehouse) =>
      warehouse.storage_areas?.some((storageArea) =>
        storageArea.products?.some((product) => {
          return product.id === formik.values.product_id
        }),
      ),
    )

    const unsorted = allowedWarehouses?.map((warehouse) => {
      // Check if any storage area in the warehouse has a product with quantity less than 1
      const totalQuantity =
        warehouse.storage_areas?.reduce((acc, storageArea) => {
          const storageAreaQuantity =
            storageArea.products?.reduce((sum, product) => {
              if (product.id === formik.values.product_id) {
                return sum + product.quantity
              }
              return sum
            }, 0) || 0
          return acc + storageAreaQuantity
        }, 0) || 0
      const isDisabled = !warehouse.storage_areas?.some((storageArea) =>
        storageArea.products?.some((product) => {
          if (type == 'OUTPUT') {
            return product.id === formik.values.product_id
          } else {
            return (
              product.quantity > 0 && product.id === formik.values.product_id
            )
          }
        }),
      )
      // Return the warehouse with the added disabled field
      return { ...warehouse, disabled: isDisabled, quantity: totalQuantity }
    })

    const updatedWarehouses = unsorted?.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1
      }
      if (!a.disabled && b.disabled) {
        return -1
      }
      return 0
    })

    const selectedWH = updatedWarehouses?.find(
      (warehouse) =>
        warehouse.id === formik.values.warehouse_from_id ||
        warehouse.id === formik.values.warehouse_to_id,
    )

    const allowedStorageAreas = selectedWH?.storage_areas?.filter(
      (storageArea) =>
        storageArea.products?.some((product) => {
          return product.id === formik.values.product_id
        }),
    )

    const unsortedStorageAreas = allowedStorageAreas?.map((storageArea) => {
      const quantity =
        storageArea.products?.reduce((acc, product) => {
          if (product.id === formik.values.product_id) {
            return acc + product.quantity
          }
          return acc
        }, 0) || 0

      const isDisabled = !storageArea.products?.some((product) => {
        if (type == 'OUTPUT') {
          return product.id === formik.values.product_id
        } else {
          return product.quantity > 0 && product.id === formik.values.product_id
        }
      })
      return { ...storageArea, disabled: isDisabled, quantity: quantity }
    })

    const storageAreas = unsortedStorageAreas?.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1
      }
      if (!a.disabled && b.disabled) {
        return -1
      }
      return 0
    })

    if (Array.isArray(storageAreas)) {
      setStorageAreaOptions(
        storageAreas.map((storageArea) => {
          return {
            label: storageArea.name,
            value: storageArea.id,
            disabled: storageArea?.disabled,
            sublabel:
              'Available quantity : ' + formatNumber(storageArea?.quantity),
          }
        }),
      )
    }
    if (Array.isArray(updatedWarehouses)) {
      const _warehouseOptions = updatedWarehouses.map((item) => {
        return {
          label: item.name,
          value: item.id,
          disabled: item?.disabled,
          sublabel: 'Available quantity : ' + formatNumber(item?.quantity),
        }
      })
      // @ts-ignore
      setWarehouseOptions(_warehouseOptions)
    }
  }, [
    formik.values.product_id,
    formik.values.warehouse_from_id,
    formik.values.warehouse_to_id,
    warehouses?.data,
  ])

  useEffect(() => {
    if (formik.values.storage_area_from_id) {
      const quantity = storageAreaOptions.find(
        (item) => item.value === formik.values.storage_area_from_id,
      )?.sublabel
      quantity && setAvailableQuantity(quantity)
    }
  }, [formik.values.storage_area_from_id])

  const unit_of_measure = useMemo(() => {
    const options: Option[] =
      type === 'INPUT' ? inputOptions : type === 'OUTPUT' ? outputOptions : []
    return options.find(
      (item: Option) => item.value === formik.values.product_id,
    )?.unit_of_measure
  }, [inputOptions, outputOptions, formik.values.product_id, type])

  const isInitialRenderFrom = useRef(true)
  const isInitialRenderTo = useRef(true)

  useEffect(() => {
    if (isInitialRenderFrom.current && formik?.values?.warehouse_from_id) {
      isInitialRenderFrom.current = false
    } else if (!isInitialRenderFrom.current) {
      formik.setFieldValue('storage_area_from_id', null)
    }
  }, [formik?.values?.warehouse_from_id])

  useEffect(() => {
    if (isInitialRenderTo.current && formik?.values?.warehouse_to_id) {
      isInitialRenderTo.current = false
    } else if (!isInitialRenderTo.current) {
      formik.setFieldValue('storage_area_to_id', null)
    }
  }, [formik?.values?.warehouse_to_id])

  const isURLhavingPreviewText = useMemo(() => {
    return window.location.href.includes('preview')
  }, [])

  const isQuantityDisabled =
    type === 'INPUT'
      ? !formik?.values.storage_area_from_id
      : !formik.values.storage_area_to_id
  return (
    <>
      <MotionDiv>
        {workOrderLoading ? (
          <FullPageLoader />
        ) : (
          <>
            <div className="mb-16 flex w-full flex-col gap-2 ">
              <div className="flex w-full justify-between">
                <span className="h5">
                  Add {WorkOrderData?.workOrderID} Entry
                </span>
                <button onClick={() => navigate(-1)}>
                  <img src={icons.CloseIcon} alt="Close Icon" />
                </button>
              </div>
              <div className="flex flex-col gap-2">
                <WhiteCard className="gap-0 rounded-lg">
                  <div className="flex flex-row justify-between">
                    <span className="flex items-center gap-1 text-base font-bold">
                      <p>Product Details</p>
                      <img src={icons.HelpIcon} alt="Help Icon" />
                    </span>

                    {formik.values.storage_area_from_id &&
                      availableQuantity && (
                        <span className="flex items-center gap-1 text-base font-bold">
                          <p>{availableQuantity}</p>
                          <p>{unit_of_measure}</p>
                        </span>
                      )}
                  </div>

                  <div className="grid w-full grid-cols-4 gap-x-4 ">
                    <Field
                      label={`Operation`}
                      name="process_id"
                      type="select"
                      placeholder="Enter or Select Operation"
                      options={operationsOptions || []}
                      formik={formik}
                      required
                    />
                    <Field
                      label={`${type?.charAt(0)?.toUpperCase() + type?.slice(1)?.toLowerCase()} Product`}
                      name="product_id"
                      type="select"
                      disabled={!inputOptions && !outputOptions}
                      placeholder="Enter product name or select"
                      options={type === 'INPUT' ? inputOptions : outputOptions}
                      formik={formik}
                      required
                    />
                    {type === 'INPUT' ? (
                      <>
                        <Field
                          label="Warehouse from"
                          name="warehouse_from_id"
                          type="select"
                          placeholder="Enter warehouse name or select"
                          // @ts-ignore
                          options={warehouseOptions}
                          formik={formik}
                          disabled={formik.values.product_id == null}
                          required
                        />
                        <Field
                          label="Storage Area from"
                          name="storage_area_from_id"
                          type="select"
                          placeholder="Enter storage area name or select"
                          // @ts-ignore
                          options={storageAreaOptions}
                          formik={formik}
                          required
                          disabled={!storageAreaOptions?.length}
                        />
                      </>
                    ) : (
                      <>
                        <Field
                          label="Warehouse to"
                          name="warehouse_to_id"
                          type="select"
                          placeholder="Enter warehouse name or select"
                          // @ts-ignore
                          options={warehouseOptions}
                          formik={formik}
                          required
                        />
                        <Field
                          label="Storage Area to"
                          name="storage_area_to_id"
                          type="select"
                          placeholder="Enter storage area name or select"
                          // @ts-ignore
                          options={storageAreaOptions}
                          formik={formik}
                          disabled={storageAreaOptions?.length === 0}
                          required
                        />
                      </>
                    )}
                    <Field
                      label={`Quantity ${type === 'INPUT' ? 'Consumed' : 'Produced'}`}
                      name="quantity"
                      type="number"
                      disabled={isQuantityDisabled}
                      placeholder="Enter product quantity"
                      suffix={unit_of_measure}
                      formik={formik}
                      required
                    />
                  </div>
                </WhiteCard>
                {fieldDetails?.length ? (
                  <div className="flex flex-col gap-6">
                    <WhiteCard className="gap-0 rounded-lg">
                      <div className="grid w-full grid-cols-4 gap-x-4 ">
                        {fieldDetails?.map((item: any) => {
                          return (
                            <div key={item?._id}>
                              {item?.type === 'Number input' && (
                                <Field
                                  label={`${item.name}`}
                                  name={`${item.name}`}
                                  type="number"
                                  placeholder="Enter the value"
                                  formik={formik}
                                  description={`${item.help_text ?? ''}`}
                                  required={item.is_required}
                                  suffix={item.unit}
                                />
                              )}
                              {item?.type === 'Text input' && (
                                <Field
                                  label={`${item.name}`}
                                  name={`${item.name}`}
                                  prefix={item.prefix_text}
                                  suffix={item.suffix_text}
                                  type="text"
                                  placeholder="Enter the value"
                                  formik={formik}
                                  description={`${item.help_text ?? ''}`}
                                  required={item.is_required}
                                />
                              )}

                              {item?.type === 'Date selection' &&
                                !item?.has_date_range && (
                                  <Field
                                    label={`${item.name}`}
                                    name={`${item.name}`}
                                    type="date"
                                    placeholder="select date"
                                    formik={formik}
                                    description={`${item.help_text ?? ''}`}
                                    required={item.is_required}
                                    shouldDisableFutureDate={
                                      !item?.has_future_date
                                    }
                                  />
                                )}

                              {item?.type === 'Date selection' &&
                                item?.has_date_range && (
                                  <Field
                                    label={`${item.name}`}
                                    name={`${item.name}`}
                                    type="dateRange"
                                    placeholder="select date"
                                    formik={formik}
                                    description={`${item.help_text ?? ''}`}
                                    required={item.is_required}
                                    shouldDisableFutureDate={
                                      !item?.has_future_date
                                    }
                                  />
                                )}

                              {item?.type === 'Time selection' && (
                                <Field
                                  label={`${item.name}`}
                                  name={`${item.name}`}
                                  type="time"
                                  placeholder="Select the time"
                                  formik={formik}
                                  description={`${item.help_text ?? ''}`}
                                  required={item.is_required}
                                />
                              )}

                              {item?.type === 'Checkbox' && (
                                <Field
                                  label={`${item.name}`}
                                  name={`${item.name}`}
                                  type="checkbox"
                                  placeholder="Enter value"
                                  formik={formik}
                                  description={`${item.help_text ?? ''}`}
                                  required={item.is_required}
                                />
                              )}

                              {item?.type === 'Dropdown selection' && (
                                <Field
                                  label={`${item.name}`}
                                  name={`${item.name}`}
                                  type="select"
                                  placeholder="select"
                                  defaultValue={{
                                    label: formik.values[item.name] ?? '',
                                  }}
                                  options={item?.options?.map((item: any) => ({
                                    label: item.name,
                                    value: item.name,
                                  }))}
                                  formik={formik}
                                  description={`${item.help_text ?? ''}`}
                                  required={item.is_required}
                                />
                              )}

                              {item?.type === 'Calculated field' && (
                                <Field
                                  label={`${item.name}`}
                                  name={`${item.name}`}
                                  type="number"
                                  placeholder="Enter the value"
                                  formik={formik}
                                  description={`${item.help_text ?? ''}`}
                                  disabled
                                />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </WhiteCard>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="fixed bottom-0 left-0 flex w-full justify-end gap-2 border-t border-gray-300 bg-white px-10 py-4 shadow-2xl">
              <Button
                disabled={isURLhavingPreviewText}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                color="primary"
                disabled={isURLhavingPreviewText}
                onClick={() => formik.handleSubmit()}
                loading={isLoading || isApprove}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </MotionDiv>
    </>
  )
}

export default AddInputOutputWorkOrderEntries
