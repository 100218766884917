
function PreviewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="13"
      fill="none"
      viewBox="0 0 20 13"
    >
      <path
        fill="#041220"
        d="M10 10.334c1.042 0 1.927-.365 2.656-1.094.73-.73 1.094-1.615 1.094-2.657 0-1.041-.365-1.927-1.094-2.656-.729-.729-1.614-1.094-2.656-1.094s-1.927.365-2.656 1.094c-.73.73-1.094 1.615-1.094 2.656 0 1.042.365 1.928 1.094 2.657.729.729 1.614 1.094 2.656 1.094zm0-1.5a2.17 2.17 0 01-1.594-.657 2.17 2.17 0 01-.656-1.593c0-.625.219-1.157.656-1.594A2.17 2.17 0 0110 4.333a2.17 2.17 0 011.594.657c.437.437.656.968.656 1.593a2.17 2.17 0 01-.656 1.594A2.17 2.17 0 0110 8.834zm0 4c-1.93 0-3.694-.51-5.292-1.532-1.597-1.02-2.805-2.4-3.625-4.135A.677.677 0 011 6.907a2.442 2.442 0 010-.646.677.677 0 01.083-.26c.82-1.737 2.028-3.115 3.625-4.136C6.306.844 8.07.333 10 .333c1.93 0 3.694.51 5.292 1.532 1.597 1.02 2.805 2.399 3.625 4.135.041.07.07.156.083.26a2.457 2.457 0 010 .646.676.676 0 01-.083.26c-.82 1.737-2.028 3.115-3.625 4.136-1.598 1.021-3.361 1.532-5.292 1.532zm0-1.667c1.57 0 3.01-.413 4.323-1.24a8.144 8.144 0 003.01-3.343 8.144 8.144 0 00-3.01-3.344A7.954 7.954 0 0010 2c-1.57 0-3.01.413-4.323 1.24a8.144 8.144 0 00-3.01 3.343 8.144 8.144 0 003.01 3.344A7.954 7.954 0 0010 11.167z"
      ></path>
    </svg>
  );
}

export default PreviewIcon;
