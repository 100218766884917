import { toast } from "react-toastify";
import NewToastFailed from "assets/icons/toast/NewToastFailed";


export const toastFailure = (string:any) =>
  toast.success(string, {
    icon: NewToastFailed,
    style: {
      background: "#B20707",
    },
  });
