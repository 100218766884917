import Button from '../../../libs/button/Button'
import { t } from 'i18next'
import { useUpdateManufactureOrdersStatusMutation } from '../manufacture-orders-endpoints/manufacture_order.endpoints'

import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  moOrder: any
  plantId: string
}

function StartMoOrder({ closePopUp, moOrder, plantId }: Props) {
  const [updateMoOrder, { isLoading: updateWorkOrderLoading }] = useUpdateManufactureOrdersStatusMutation()

  const navigate = useNavigate()

  const handleUpdateMoOrder = () => {
    updateMoOrder({ plantId, moID: moOrder?._id, status: 'IN_PROGRESS' })
      .unwrap()
      .then(() => {
        toast.success(`Manufacture Order “${moOrder?.manufactureOrderID}” is started successfully.`)
        navigate(-1)
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(error?.data?.detail ?? `Failed to start Manufacture Order “${''}”. Please try again.`)
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to start Manufacture Order{' '}
        <span className="break-words pr-1 p-xs-bold">“{moOrder?.manufactureOrderID}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Cancel</Button>
        <Button color="primary" onClick={handleUpdateMoOrder} loading={updateWorkOrderLoading}>
          Start
        </Button>
      </div>
    </div>
  )
}

export default StartMoOrder
