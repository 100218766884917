import React from 'react'
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts'
import { WorkOrderChart } from 'pages/work-orders/work-orders-endpoints/workordersType'

const COLORS = ['#00AF82', '#F3BC00', '#EEEEEE']

interface OperationsPieChartProps {
  apiData: WorkOrderChart[]
  title?: string
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '14px',
          border: '1px solid #ccc',
          borderRadius: '10px',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
          textAlign: 'center',
        }}
      >
        <p className="label">{`${payload[0].name}: ${payload[0].value}`}</p>
      </div>
    )
  }

  return null
}

const CirclePieChart: React.FC<OperationsPieChartProps> = ({ apiData, title }) => {
  const totalOperations = apiData?.reduce((acc, cur) => acc + cur.value, 0)

  return (
    <div style={{ display: 'flex', backgroundColor: '#fff' }}>
      <ResponsiveContainer height={230}>
        <PieChart>
          <Pie
            data={apiData}
            cx="50%"
            cy="50%"
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={5}
            cornerRadius={10}
            dataKey="value"
          >
            {apiData.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <text
            x="50%"
            y="45%"
            textAnchor="middle"
            dominantBaseline="middle"
            className="total-operations"
            fontSize="24px"
            fontWeight="bold"
          >
            {totalOperations}
          </text>
          <text
            x="50%"
            y="55%"
            textAnchor="middle"
            dominantBaseline="middle"
            className="total-operations-title"
            fontSize="16px"
          >
           {title ? title : "Total Operations"}
          </text>
        </PieChart>
      </ResponsiveContainer>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        {apiData.map((entry, index) => (
          <div
            key={`legend-${index}`}
            style={{ display: 'flex', alignItems: 'center', marginBottom: 10, marginRight: 10 }}
          >
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: COLORS[index % COLORS.length],
                marginRight: 10,
                borderRadius: '50%',
              }}
            />
            <span className="mx-1 text-base font-bold text-primary-800">{`${entry.value.toString().padStart(2, '0')}`}</span>
            <span className="whitespace-nowrap text-sm text-gray-600">{`${entry.name}`}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CirclePieChart
