function OperationalProcesses() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 21">
      <path
        fill="#1C1B1F"
        d="M7.6 12.75L5.45 10.6a.948.948 0 00-.7-.275.948.948 0 00-.7.275.948.948 0 00-.275.7c0 .283.092.517.275.7l2.85 2.85c.2.2.433.3.7.3.267 0 .5-.1.7-.3l5.65-5.65a.948.948 0 00.275-.7.948.948 0 00-.275-.7.948.948 0 00-.7-.275.948.948 0 00-.7.275L7.6 12.75zM2 20.5c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 010 18.5v-14c0-.55.196-1.02.588-1.413A1.926 1.926 0 012 2.5h4.2c.217-.6.58-1.083 1.087-1.45A2.857 2.857 0 019 .5c.633 0 1.204.183 1.713.55.508.367.87.85 1.087 1.45H16c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0116 20.5H2zm0-2h14v-14H2v14zM9 3.75A.728.728 0 009.75 3 .728.728 0 009 2.25a.728.728 0 00-.75.75.728.728 0 00.75.75z"
      ></path>
    </svg>
  )
}

export default OperationalProcesses
