import Chip from 'libs/chip/Chip'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetMaintenanceOrderByIdQuery } from '../maintenance-order-endpoints/order.endpoints'
import { formatUnixDateWithTime } from 'utils/dateTimeHandlers'

const SelectedMaintenanceRequest = () => {
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const params = useParams()
  const { data: maintenanceOrder } = useGetMaintenanceOrderByIdQuery(
    {
      plantId: plantId?.id,
      moID: params?.id,
    },
    {
      skip: !params?.id || !plantId?.id,
    },
  )

  const column = [
    {
      Header: 'Request ID',
      accessor: 'requestID',
      width: 80,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              navigate(`/maintenance/maintenance-request/${data?._id}/view`)
            }}
          >
            {data?.requestID}
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Reason</div>
      ),
      accessor: 'reason',
      width: 150,
      Cell: ({ row }: any) => {
        const reason = row?.original?.reason
        return (
          <div>
            {reason && reason.length > 20
              ? `${reason.slice(0, 20)}...`
              : reason}
          </div>
        )
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Description</div>
      ),
      accessor: 'description',
      width: 150,
      Cell: ({ row }: any) => {
        const description = row?.original?.description
        return (
          <div>
            {description && description.length > 20
              ? `${description.slice(0, 20)}...`
              : description}
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Machines</div>
      ),
      accessor: 'machines',
      width: 100,
      Cell: ({ row }: any) => {
        return <div>{row.original?.machine}</div>
      },
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Status</div>
      ),
      accessor: 'status',
      width: 80,
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
    {
      Header: (
        <div className="flex w-full items-center justify-end">Created by</div>
      ),
      accessor: 'created_by',
      width: 120,
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2 text-xs text-brand-primary-shade-3">
              <h1>{data?.created_by?.first_name}</h1>
              <h1>{data?.created_by?.last_name}</h1>
            </div>
            <h1 className="text-xs text-gray-600">
              {formatUnixDateWithTime(data?.created_on)}
            </h1>
          </div>
        )
      },
      disableSortBy: true,
    },
  ]
  return (
    <>
      <h1 className="mb-4 text-xl font-medium text-brand-primary-shade-3">
        Selected Maintenance Requests
      </h1>
      <PaginatedTable
        columns={column}
        rows={maintenanceOrder?.maintenance_requests}
        hidePagination
      />
    </>
  )
}
export default SelectedMaintenanceRequest
