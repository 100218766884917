import { useMemo } from 'react'
import PaginatedTable from 'libs/table/PaginatedTable'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { getRelativeTime } from 'utils/dateTimeHandlers'
import {
  ProductResponse,
  Warehouse,
} from '../warehouses-endpoints/warehouseTypes'

interface Props {
  warehouse: Warehouse
}

const ProductView = ({ warehouse }: Props) => {
  const Products = warehouse?.storage_areas?.flatMap((area) =>
    area?.products?.map((product) => ({
      name: product.name,
      code: product.code,
    })),
  )

  const columns: ExtendedColumn<ProductResponse>[] = useMemo(
    () => [
      {
        Header: 'Products',
        accessor: 'name',
        width: 200,
        disableSortBy: true,
      },
      {
        Header: 'Product Code',
        accessor: 'code',
        width: 250,
      },
    ],
    [],
  )

  return (
    <div>
      <div className="flex flex-col gap-2 rounded bg-gray-100 p-3">
        <div className="flex justify-between">
          <div className=" p-xs-regular-dim">Warehouse</div>
          <div>
            <div className="w-60 break-words p-xs-regular">
              {warehouse?.name ?? '-'}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="p-xs-regular-dim">Last updated</div>
          <div>
            <div className="w-60 p-xs-regular">
              {warehouse?.updated_on
                ? getRelativeTime(warehouse?.updated_on)
                : '-'}
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="p-xs-regular-dim">Updated by</div>
          <div>
            <div className="w-60 p-xs-regular">
              {warehouse?.updated_on
                ? getRelativeTime(warehouse?.updated_on)
                : '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <PaginatedTable
          columns={columns}
          rows={Products}
          hidePagination={true}
        />
      </div>
    </div>
  )
}

export default ProductView
