import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import { useGetMachineClassesQuery } from 'pages/maintenance/machine-class/machine-class-endpoints/machineClass.endpoints'
import { GetMachineClass } from 'pages/maintenance/machine-class/machine-class-endpoints/machineClassTypes'
import { useGetWorkCentersQuery } from 'pages/work-center/work-center-endpoints/workcenter.endpoint'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import * as Yup from 'yup'

const machineStatusOptions = [
  { label: 'Operational', value: 'OPERATIONAL' },
  { label: 'Under Maintenance', value: 'UNDER_MAINTENANCE' },
  { label: 'Out of service', value: 'OUT_OF_SERVICE' },
]

const filters: any = {
  page_no: 1,
  page_size: 100,
  searchText: '',
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const BasicDetails = ({ setId, setTabNumber, formik, isLoading }: any) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)

  const { data: machineClassesData } = useGetMachineClassesQuery(
    { plantId: plant?.id, filters: filters },
    {
      skip: !plant?.id,
    },
  )
  const { data: workCenterData } = useGetWorkCentersQuery(
    {
      plantId: plant?.id,
      filters: filters,
    },
    {
      skip: !plant.id,
    },
  )

  const basicDetailsFormik = useFormik<any>({
    initialValues: {
      name: '',
      serialNumber: '',
      machineClassID: '',
      modelNumber: '',
      status: '',
      workCenterID: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Machine Name is required'),
      serialNumber: Yup.string().required('Serial Number is required'),
      machineClassID: Yup.string().required('Machine Class is required'),
      modelNumber: Yup.string().required('Model Number is required'),
      status: Yup.string().required('Machine Status is required'),
    }),

    onSubmit: (values) => {
      formik.setFieldValue('basicDetails', values)
      setTabNumber((prev: number) => prev + 1)

      if (!id) {
        navigate('/maintenance/machines/add/assigned-operators')
      } else {
        navigate(`/maintenance/machines/${id}/edit/assigned-operators`)
      }
    },
  })

  useEffect(() => {
    setId(id)
    setTabNumber(1)
  }, [])

  const machineClassOptions = useMemo(
    () => machineClassesData?.data?.map((item: GetMachineClass) => ({ label: item?.name, value: item._id })) || [],
    [machineClassesData?.data],
  )

  const modelNumberOptions = useMemo(
    () =>
      machineClassesData?.data
        .find((item: GetMachineClass) => basicDetailsFormik.values.machineClassID == item._id)
        ?.machineModels?.map((item: any) => ({ label: item.modelNumber, value: item._id })) || [],
    [basicDetailsFormik.values.machineClassID,machineClassesData?.data],
  )

  useEffect(() => {
    basicDetailsFormik.resetForm({
      values: {
        name: formik.values.basicDetails?.name || '',
        serialNumber: formik.values.basicDetails?.serialNumber || '',
        machineClassID: formik.values.basicDetails?.machineClassID || '',
        modelNumber: formik.values.basicDetails?.modelNumber || '',
        status: formik.values.basicDetails?.status || '',
        workCenterID: formik.values.basicDetails?.workCenterID || '',
        purchaseDate: formik.values.basicDetails?.purchaseDate || null,
      },
    })
  }, [formik.values.basicDetails])

  useEffect(() => {
    if (
      formik.values.basicDetails?.modelNumber != basicDetailsFormik.values.modelNumber &&
      basicDetailsFormik.values.modelNumber != ''
    ) {
      basicDetailsFormik.setFieldValue('modelNumber', null)
    }
  }, [basicDetailsFormik?.values?.machineClassID])

  const defaultModelNumber = useMemo(() => {
    return (
      {
        label: modelNumberOptions.find((item) => item.value === basicDetailsFormik.values.modelNumber)?.label ?? '' }
    )
  }, [basicDetailsFormik.values.modelNumber,machineClassesData?.data])

  const workCenterOptions = workCenterData?.data?.map((item) => ({
    label: item?.name,
    value: item?._id,
    sublabel: item?.workCenterID,
    disabled: '',
  }))

  return (
    <div className="h-screen-90  flex w-full flex-col  px-2">
      <div className="flex-grow  ">
        <div className="h5-1">Basic Details</div>
        <p className="primary-gray-4 mb-5 p-sm-regular">
          Enter the essential information about the machine, including its name, class, type, serial number and
          location.
        </p>
        <div className="mb-3  flex w-10/12 justify-between gap-3 ">
          <div className="flex  w-full flex-col gap-3">
            <Field
              required={true}
              label="Machine Name"
              formik={basicDetailsFormik}
              name="name"
              placeholder="Enter machine name"
            />
            <Field
              type="select"
              label="Machine Class"
              formik={basicDetailsFormik}
              name="machineClassID"
              placeholder="Select machine class"
              required={true}
              options={machineClassOptions}
              defaultValue={
                {
                  label: machineClassOptions.find((item) => item.value === basicDetailsFormik.values.machineClassID)
                    ?.label ?? '' }
              }
            />
            <Field
              type="date"
              label="Purchase Date"
              formik={basicDetailsFormik}
              name="purchaseDate"
              placeholder="Enter purchase date"
              shouldDisableFutureDate
            />

            <Field
              type="select"
              label="Work Center"
              formik={basicDetailsFormik}
              name="workCenterID"
              placeholder="Select work center"
              options={workCenterOptions}
              defaultValue={
                {
                  label: workCenterOptions?.find((item) => item.value === basicDetailsFormik.values.workCenterID)
                    ?.label ?? '' }
              }
            />
          </div>
          <div className="flex  w-full  flex-col gap-3">
            <Field
              required={true}
              type="text"
              label="Serial Number"
              formik={basicDetailsFormik}
              name="serialNumber"
              placeholder="Enter serial number"
            />
            <Field
              type="select"
              label="Model Number"
              formik={basicDetailsFormik}
              name="modelNumber"
              placeholder="Select model number"
              options={modelNumberOptions}
              disabled={modelNumberOptions?.length > 0 ? false : true}
              required={true}
              defaultValue={defaultModelNumber}
            />
            <Field
              type="select"
              label="Machine Status"
              formik={basicDetailsFormik}
              name="status"
              placeholder="Select machine status"
              required={true}
              hideSearch
              options={machineStatusOptions}
              defaultValue={
                {
                  label: machineStatusOptions?.find((item) => item.value === basicDetailsFormik.values.status)?.label ?? '' }
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-auto flex justify-end gap-2">
        <div className="flex gap-3">
          <Button
            type="button"
            disabled={isLoading}
            onClick={() => {
              basicDetailsFormik.resetForm()
              navigate(-1)
            }}
          >
            Cancel
          </Button>
        </div>
        {id ? (
          <>
            <div>
              <Button
                type="button"
                disabled={isLoading}
                onClick={() => {
                  basicDetailsFormik.handleSubmit()
                }}
              >
                Next
              </Button>
            </div>
            <div>
              <Button
                color="success"
                type="submit"
                loading={isLoading}
                onClick={() => {
                  formik.setFieldValue('basicDetails', basicDetailsFormik.values)
                  formik.handleSubmit()
                }}
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <div>
            <Button
              color="success"
              type="submit"
              onClick={() => {
                basicDetailsFormik.handleSubmit()
              }}
            >
              Next
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default BasicDetails
