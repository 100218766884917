import MotionDiv from 'libs/motionDiv'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'

import PersonalInformation from 'src/pages/employee/Employee-Management/tabs/PersonalInformation.tsx'
import JobDetails from 'src/pages/employee/Employee-Management/tabs/JobDetails.tsx'
import AddressDetails from 'src/pages/employee/Employee-Management/tabs/AddressDetails.tsx'
import BlueTick from 'assets/icons/product/BlueTick'
import { useFormik } from 'formik'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  useGetEmployeeQuery,
  usePostEmployeeMutation,
  usePutEmployeeMutation,
} from '../employees-endpoints/employees.endpoints'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import FullPageLoader from 'libs/loader/FullPageLoader'
import ViewAddressDetails from 'src/pages/employee/Employee-Management/view-details-tab/ViewAddressDetails.tsx'
import ViewJobDetails from 'src/pages/employee/Employee-Management/view-details-tab/ViewJobDetails.tsx'
import ViewPersonalInformation from 'src/pages/employee/Employee-Management/view-details-tab/ViewPersonalInformation.tsx'

const EmployeeManagementWizard = () => {
  const location = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [page, setPage] = useState(1)

  const isView = location?.pathname?.includes('view')

  const steps = [
    { label: 'Personal Information', path: isView ? 'view-personal-information' : 'personal-information' },
    { label: 'Address Details', path: isView ? 'view-address-details' : 'address-details' },
    { label: 'Job Details', path: isView ? 'view-job-details' : 'job-details' },
  ]

  const [lastValidPage, setLastValidPage] = useState(1)

  useEffect(() => {
    if (page > lastValidPage) {
      setLastValidPage(lastValidPage + 1)
    }
  }, [page])

  const [addEmployee, { isLoading: addEmployeeLoading }] = usePostEmployeeMutation()
  const [updateEmployee, { isLoading: updateEmployeeLoading }] = usePutEmployeeMutation()

  const loading = addEmployeeLoading || updateEmployeeLoading

  const getChangeValues = (currentValues: any, initialValues: any) => {
    return Object.keys(currentValues).reduce((value: any, key) => {
      if (currentValues[key] !== initialValues[key]) {
        value[key] = currentValues[key]
      }
      return value
    }, {})
  }

  const employeeFormik = useFormik({
    initialValues: {
      personalInformationFormik: {},
      addressDetailsFormik: {},
      jobDetailsFormik: {},
    },

    onSubmit: (values: any) => {
      const Values = getChangeValues(values, employeeFormik.initialValues)

      const isEmpty = Object.keys(Values).length === 0

      const payload = {
        ...values.personalInformationFormik,
        ...values.addressDetailsFormik,
        ...values.jobDetailsFormik,
      }

      if (!id) {
        addEmployee({ plant_id: plant.id, data: payload })
          .unwrap()
          .then(() => {
            toast.success('The Employee “' + payload.employeeID + '” is added successfully.')
            navigate('/employee/employee-management')
          })
          .catch((error: any) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while creating the employee management')
          })
      } else {
        if (!isEmpty) {
          updateEmployee({ plant_id: plant.id, employeeID: id, data: payload })
            .unwrap()
            .then(() => {
              toast.success('The Employee “' + payload.employeeID + '” is updated successfully.')
              navigate('/employee/employee-management')
            })
            .catch((error: any) => {
              toast.error(error?.data?.detail ?? 'Something went wrong while creating the employee management')
            })
        } else {
          navigate('/employee/employee-management')
        }
      }
    },
  })
  const handleNavigation = (stepIndex: number) => {
    const stepPath = steps[stepIndex].path

    if (id) {
      setPage(stepIndex + 1)
      navigate(stepPath)
    } else if (lastValidPage === 2 && (stepPath === 'personal-information' || stepPath === 'address-details')) {
      setPage(stepIndex + 1)
      navigate(stepPath)
    } else if (lastValidPage === 3) {
      setPage(stepIndex + 1)
      navigate(stepPath)
    } else if (page === 1) {
      return
    }
  }

  const { data: employeeData, isLoading: employeeLoading } = useGetEmployeeQuery(
    {
      plant_id: plant?.id,
      employeeID: id,
      filters: { page_no: 1, page_size: 10 },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !plant?.id && !id,
    },
  )

  useEffect(() => {
    const data: any = employeeData?.data?.[0]

    if (employeeData && id) {
      employeeFormik.resetForm({
        values: {
          personalInformationFormik: {
            employeeID: data?.employeeID,
            employeeName: data?.employeeName,
            gender: data?.gender,
            governmentID: data?.governmentID,
            email: data?.email,
            contactNumber: data?.contactNumber,
            emergencyContactNumber: data?.emergencyContactNumber,
            emergencyContactName: data?.emergencyContactName,
          },
          addressDetailsFormik: {
            address1: data?.address1,
            address2: data?.address1,
            country: data?.country,
            state: data?.state,
            city: data?.city,
            zipCode: data?.zipCode,
          },
          jobDetailsFormik: {
            designation: data?.designation,
            department: data?.department,
          },
        },
      })
    }
  }, [employeeData, id])

  return (
    <MotionDiv>
      <>
        <div className="flex justify-between">
          <div className="flex items-baseline gap-2">
            <h2 className="mb-2 max-w-[500px] truncate h4-bold-black">
              {isView ? employeeData?.data?.[0]?.employeeName : id ? 'Edit Employee' : 'Add New Employee'}
            </h2>
          </div>
        </div>
        <div className="flex min-h-[80vh] rounded bg-white">
          <div className="relative w-1/4 border-r border-[#D2DADF] p-6">
            {steps.map((step, index) =>
              !isView ? (
                <div key={index}>
                  <button onClick={() => handleNavigation(index)} className="flex items-center">
                    <div
                      className={`h-6 w-6 rounded-3xl border p-2 ${
                        page === index + 1 ? 'border-primary' : 'border-[#B9C4CC]'
                      } ${page > index + 1 ? 'border-primary bg-primary' : 'border-[#B9C4CC]'} flex items-center justify-center`}
                    >
                      <div className="text-xs font-bold">{page > index + 1 ? <BlueTick /> : index + 1}</div>
                    </div>
                    <p className="whitespace-nowrap rounded px-2 text-sm">{step.label}</p>
                  </button>
                  {index < steps.length - 1 && (
                    <span
                      className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                        page > index + 1 ? 'border-primary' : 'border-[#B9C4CC]'
                      }`}
                    ></span>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => handleNavigation(index)}
                  key={index}
                  className={` rounded-lg hover:bg-inputBg  ${page === index + 1 ? ' rounded-lg bg-brand-primary-200 font-bold' : ''}`}
                >
                  <button className={`flex items-center   `}>
                    <p className="whitespace-nowrap rounded px-2 py-3 text-sm">{step.label}</p>
                  </button>
                </div>
              ),
            )}
          </div>

          {!employeeLoading ? (
            <div className={` w-3/4 ${isView ? 'p-1' : 'p-6'}`} style={{ overflowX: 'auto' }}>
              <Routes>
                <Route
                  path="personal-information"
                  element={<PersonalInformation employeeFormik={employeeFormik} setPage={setPage} />}
                />
                <Route
                  path="address-details"
                  element={<AddressDetails employeeFormik={employeeFormik} setPage={setPage} />}
                />
                <Route
                  path="job-details"
                  element={
                    <JobDetails employeeFormik={employeeFormik} setPage={setPage} addEmployeeLoading={loading} />
                  }
                />
                <Route
                  path="view-personal-information"
                  element={<ViewPersonalInformation employeeData={employeeData} setPage={setPage} />}
                />
                <Route
                  path="view-address-details"
                  element={<ViewAddressDetails employeeData={employeeData} setPage={setPage} />}
                />
                <Route
                  path="view-job-details"
                  element={<ViewJobDetails employeeData={employeeData} setPage={setPage} />}
                />
              </Routes>
            </div>
          ) : (
            <FullPageLoader />
          )}
        </div>
      </>
    </MotionDiv>
  )
}

export default EmployeeManagementWizard
