import { AnimatePresence, motion } from 'framer-motion'
import { scaleMotionProps } from 'utils/FramerProps'

type PopupProps = {
  children: React.ReactNode
  onClose: () => void
  isOpen: boolean
}

/**
 * `Popup` is a component that renders a popup with animation.
 *
 * @param {object} props - The properties that define the popup.
 * @param {boolean} props.isOpen - Determines whether the popup is open or closed.
 * @param {React.ReactNode} props.children - The content to be rendered inside the popup.
 * @param {function} props.onClose - The function to be called when the popup is closed.
 * @returns {ReactElement} A panel element that animates in and out based on the `isOpen` prop.
 */

const TimepickerPopup = ({ isOpen, children }: PopupProps) => {
  return (
    <>
      <AnimatePresence>
        <motion.div
          {...scaleMotionProps}
          className={` fixed left-0 top-[3rem] flex h-full w-full items-center justify-center background-blur-black  ${
            isOpen ? '' : 'hidden'
          }`}
        >
          <div className="z-50 w-72 rounded-md  shadow-2xl background-white">
            <div className="mb-3 flex justify-between">
              <div className="cursor-pointer"></div>
            </div>
            {children}
          </div>
        </motion.div>
      </AnimatePresence>
      <div className={isOpen ? 'sideNav-overlay-dark' : 'sideNav-overlay'} />
    </>
  )
}

export default TimepickerPopup
