import React from "react";

const NewToastFailed: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        fill="#fff"
        d="M8.5 12.375a.847.847 0 00.623-.252.847.847 0 00.252-.623.847.847 0 00-.252-.623.847.847 0 00-.623-.252.847.847 0 00-.623.252.847.847 0 00-.252.623c0 .248.084.456.252.623a.847.847 0 00.623.252zm0-3.5a.847.847 0 00.623-.252A.847.847 0 009.375 8V4.5a.847.847 0 00-.252-.623.847.847 0 00-.623-.252.847.847 0 00-.623.252.847.847 0 00-.252.623V8c0 .248.084.456.252.623a.847.847 0 00.623.252zm-2.56 7a1.728 1.728 0 01-1.224-.503l-3.588-3.588a1.728 1.728 0 01-.503-1.225V5.441a1.728 1.728 0 01.503-1.225L4.716.628A1.728 1.728 0 015.94.125h5.118a1.728 1.728 0 011.225.503l3.588 3.588a1.728 1.728 0 01.503 1.225v5.118a1.728 1.728 0 01-.503 1.225l-3.588 3.588a1.728 1.728 0 01-1.225.503H5.941z"
      ></path>
    </svg>
  );
};

export default NewToastFailed;
