import AssignedInfo from 'assets/icons/maintenance/AssignedInfo'
import Info from 'assets/icons/maintenance/Info'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useGetMachineByIDQuery } from '../../machine-endpoints/machine.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import Popup from 'libs/popup/Popup'
import { useState } from 'react'
import DeleteMachine from '../../popup/DeleteMachine'

interface DeletePopUpState {
  isPopUpOpen: boolean
  machine: any
}

const ViewBasicDetails = () => {
  const plant = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState<DeletePopUpState>({
    isPopUpOpen: false,
    machine: '',
  })
  const params = useParams()
  const navigate = useNavigate()
  const { data: machineData, isLoading: isMachineDataLoading } =
    useGetMachineByIDQuery(
      {
        plantId: plant?.id,
        machineID: params?.id,
      },
      {
        skip: !plant?.id || !params?.id,
      },
    )

  const purchaseDate =
    machineData?.purchaseDate &&
    formatUnixDate(machineData?.purchaseDate, plant?.timeZone)

  return (
    <>
      {isMachineDataLoading ? (
        <div className="relative top-52 flex">
          <FullPageLoader />
        </div>
      ) : (
        <>
          <div className="mb-6 flex justify-between">
            <h1 className="text-xl font-medium text-brand-primary-shade-3">
              Basic Details
            </h1>
            <div className="flex gap-2">
              <Button
                color="error"
                onClick={() => {
                  machineData &&
                    setDeletePopUp({ isPopUpOpen: true, machine: machineData })
                }}
              >
                Delete Machine
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  machineData?._id &&
                  navigate(
                    `/maintenance/machines/${machineData?._id}/edit/basic-details`,
                  )
                }
              >
                Edit Machine
              </Button>
            </div>
          </div>
          <div>
            {/* General Information */}
            <div>
              <h1 className="mb-4 flex items-center gap-2 text-lg font-bold">
                <Info />
                General Information
              </h1>
              <div className="flex w-6/12 justify-between gap-4">
                <div className="flex gap-4">
                  <div className="flex flex-col gap-4">
                    <h1 className="p-xs-regular-dim">Machine Name</h1>
                    <h1 className="p-xs-regular-dim">Machine Class</h1>
                    <h1 className="p-xs-regular-dim">Work Center</h1>
                    <h1 className="p-xs-regular-dim">Purchase Date </h1>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h1 className="p-xs-regular">{machineData?.name ?? '-'}</h1>
                    <h1 className="p-xs-regular">
                      {machineData?.machineClass?.name ?? '-'}
                    </h1>
                    <h1 className="p-xs-regular">
                      {machineData?.workCenter?.name ?? '-'}
                    </h1>
                    <h1 className="p-xs-regular">{purchaseDate ?? '-'}</h1>
                  </div>
                </div>
                <div className="mr-6 flex gap-4">
                  <div className="flex flex-col gap-4">
                    <h1 className="p-xs-regular-dim">Serial Number</h1>
                    <h1 className="p-xs-regular-dim">Model Number </h1>
                    <h1 className="p-xs-regular-dim">Manufacturer</h1>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h1 className="p-xs-regular">
                      {machineData?.serialNumber ?? '-'}
                    </h1>
                    <h1 className="p-xs-regular">
                      {machineData?.machineClass?.models?.modelNumber ?? '-'}
                    </h1>
                    <h1 className="p-xs-regular">
                      {machineData?.machineClass?.models?.manufacturer ?? '-'}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assigned Information */}
          <div className="my-4">
            <h1 className="mb-4 flex items-center gap-2 text-lg font-bold">
              <AssignedInfo />
              Assigned Information
            </h1>
            <div className="flex gap-4">
              <div className="flex flex-col gap-4">
                <h1 className="p-xs-regular-dim">Assignees</h1>
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="flex p-xs-regular">
                  {(machineData?.operators &&
                    machineData?.operators?.length &&
                    machineData?.operators
                      ?.map((item: any) => item?.employeeName)
                      .join(', ')) ||
                    '-'}
                </h1>
              </div>
            </div>
          </div>
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Machine?"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, machine: '' })
            }}
          >
            <DeleteMachine
              closePopUp={() =>
                setDeletePopUp({ isPopUpOpen: false, machine: '' })
              }
              machine={deletePopUp.machine}
              plant_id={plant?.id}
            />
          </Popup>
        </>
      )}
    </>
  )
}

export default ViewBasicDetails
