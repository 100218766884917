import './loader.scss'
interface LoaderProps {
  size?: 'sm' | 'md' | 'lg' | 'xl'
  color?: 'primary' | 'success' | 'error' | 'default'
}
const Loader: React.FC<LoaderProps> = ({ size, color }) => {
  let _size = '20px'
  switch (size) {
    case 'sm':
      _size = '15px'
      break
    case 'md':
      _size = '20px'
      break
    case 'lg':
      _size = '30px'
      break
    case 'xl':
      _size = '40px'
      break
  }

  let className = ' border-4 border-solid'

  switch (color) {
    case 'primary':
      className += ' border-primary '
      break
    case 'success':
      className += ' border-success '
      break
    case 'error':
      className += ' border-error  '
      break
    default:
      className += ' border-white  '
      break
  }

  return (
    <div
      data-testid="custom-loader"
      className={`loader ${className}`}
      style={{
        borderTop: '4px solid transparent',
        borderRadius: '50%',
        width: _size,
        height: _size,
      }}
    />
  )
}

export default Loader
