import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { t } from 'i18next'
import { useEffect } from 'react'
import {
  useGetWorkOrderByIDQuery,
  usePostWorkOrderMutation,
  usePutWorkOrderMutation,
} from 'pages/work-orders/work-orders-endpoints/workorders.endpoints'
import { useLocation, useNavigate } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { toast } from 'libs/toast'
import * as Yup from 'yup'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useGetAllProcessesQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import EditableTable from 'libs/table/EditableTable'

type Props = {
  close: () => void
  workOrderID: any
  manufactureOrderFormik: any
  data: any
}



const QuickAddEditWork = ({ close, workOrderID, manufactureOrderFormik, data }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const plantId = useSelector(selectSelectedItem)
  const [updateWorkOrder, { isLoading: updateWorkOrderLoading }] = usePutWorkOrderMutation()
  const [addWorkOrder, { isLoading: addWorkOrderLoading }] = usePostWorkOrderMutation()

  const { data: workOrder, isFetching: isWorkOrderFetching } = useGetWorkOrderByIDQuery(
    {
      plant_id: plantId?.id,
      woID: workOrderID,
    },
    {
      skip: !plantId?.id || !workOrderID,
    },
  )
  const { data: allProcessData, isLoading: processLoading } = useGetAllProcessesQuery(
    {
      plantId: plantId?.id,
      filters: {
        page_no: 1,
        page_size: 1000,
        searchText: '',
      },
    },
    { skip: !plantId.id },
  )
  const formik = useFormik({
    initialValues: {
      workOrderID: '',
      startDate: undefined,
      endDate: undefined,
      status: '',
    },
    validationSchema: Yup.object({
      workOrderID: Yup.string().required('Work Order ID is required'),
      status: Yup.string().required('Status is required'),
      startDate: Yup.string().required('Start Date is required'),
      endDate: Yup.string().required('End Date is required'),
    }),
    onSubmit: (values: any) => {
      const payload = {
        workOrderID: values?.workOrderID,
        startDate: values?.startDate,
        endDate: values?.endDate,
        status: values?.status,
        operations:
          operationsFormik?.values?.operations
            ?.map((operation: any) => {
              const products =
                operation?.products?.map((product: any) => {
                  if (product?.targetQuantity <= 0) {
                    return toast.error(`Target quantity for product ${product?.product_name} should be greater than 0`)
                  }
                  return {
                    product_id: product?._id,
                    targetQuantity: product?.targetQuantity,
                    completedQuantity: product?.completedQuantity,
                  }
                }) || []

              // Only include the operation if the products array is not empty
              if (products.length > 0) {
                return {
                  process_id: operation?.process_id?._id,
                  products: products,
                }
              }
              return null
            })
            ?.filter((operation: any) => operation !== null) || [],
      }
      if (workOrder == null) {
        addWorkOrder({ plantId: plantId?.id, data: payload })
          .unwrap()
          .then((res) => {
            const transformedData = [
              {
                workOrderID: {
                  _id: res?.id,
                  startDate: res.startDate,
                  endDate: res.endDate,
                  operations: res.operations,
                  workOrderID: res.workOrderID,
                  status: res.status,
                },
              },
            ]

            const existingWorkOrders = data?.flat() || []
            const updatedWorkOrders = [...existingWorkOrders, transformedData]

            // check the ID that mathces the work order ID and update the existing work order
            manufactureOrderFormik.setFieldValue('workOrders', updatedWorkOrders.flat())

            toast.success('Work order - ' + payload.workOrderID + ' created successfully')
            close()
          })
          .catch((error) => {
            toast.error(error?.data?.detail ?? 'Something went wrong while creating the work order')
          })
      }
      updateWorkOrder({ plantId: plantId.id, woID: workOrder._id, data: payload })
        .unwrap()
        .then((res) => {
          const transformedData: any = [
            {
              workOrderID: {
                _id: res._id,
                startDate: res.startDate,
                endDate: res.endDate,
                operations: res.operations,
                workOrderID: res.workOrderID,
                status: res.status,
              },
            },
          ]

          const existingWorkOrders = data?.flat() || []
          
          const updateExistingOrders = existingWorkOrders.filter((wo: any) => wo.workOrderID._id !== res._id)
          

          const updatedWorkOrders = [...updateExistingOrders, transformedData]

          // check the ID that mathces the work order ID and update the existing work order
          manufactureOrderFormik.setFieldValue('workOrders', updatedWorkOrders.flat())

          toast.success('work order - ' + payload.workOrderID + ' updated successfully')
          close()
        })
        .catch((error) => {
          toast.error(error?.data?.detail ?? 'Something went wrong while updating the work order')
        })
    },
  })
  const verificaton = [
    {
      name: 'In progress',
      value: 'IN_PROGRESS',
      _id: '101',
    },
    {
      name: 'Not started',
      value: 'NOT_STARTED',
      _id: '102',
    },
    {
      name: 'Completed',
      value: 'COMPLETED',
      _id: '104',
    },
    {
      name: 'Cancelled',
      value: 'CANCELLED',
      _id: '105',
    },
  ]
  const newOperationRow = {
    process_id: null,
    products: [],
  }

  const operationsFormik = useFormik<any>({
    initialValues: {
      operations: [],
    },

    onSubmit: () => {},
  })
  const operationsOptions = allProcessData?.data
    ?.filter((item: any) => item?.output_products?.length > 0)
    ?.map((item: any) => ({
      label: item.name,
      value: item,
    }))
  const operationsColumns = [
    {
      title: 'Operation',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: 'name',
        options: operationsOptions,
        name: 'process_id',
        placeholder: 'Select Operation',
        type: 'select',
      },
    },
    {
      title: 'Output Products',
      content: {
        placeholder: 'Product Name',
        type: 'text',
        parentName: 'products',
        name: 'product_name',
        readOnly: true,
        options: [
          { value: 'product1', label: 'product1' },
          { value: 'product2', label: 'product2' },
        ],
      },
    },
    {
      title: 'Quantity',
      align: 'right',
      hideRightBorder: true,
      content: {
        placeholder: '0',
        type: 'number',
        parentName: 'products',
        name: 'targetQuantity',
      },
    },
    {
      title: 'UOM',
      flex: 1,
      content: {
        placeholder: 'Unit',
        type: 'text',
        parentName: 'products',
        name: 'unit_of_measure',
        readOnly: true,
      },
    },
  ]
  const verificatonOptions = verificaton.map((item) => ({
    label: item.name,
    value: item.value,
    sublabel: '',
    disabled: '',
  }))
  useEffect(() => {
    if (workOrder) {
      formik.resetForm({
        values: {
          workOrderID: workOrder.workOrderID,
          startDate: workOrder.startDate,
          endDate: workOrder.endDate,
          status: workOrder.status,
        },
      })
      operationsFormik.resetForm({
        values: {
          operations: workOrder.operations.map((operation: any) => ({
            process_id: {
              _id: operation?._id,
              name: operation?.name,
              output_products: operation?.products?.map((product: any) => ({
                product: {
                  _id: product?._id,
                  name: product?.name,
                  unit_of_measure: product?.unit_of_measure,
                  targetQuantity: product?.targetQuantity,
                  completedQuantity: product?.completedQuantity,
                },
              })),
            },
            products:
              operation?.products?.map((product: any) => ({
                _id: product?._id,
                targetQuantity: product?.targetQuantity,
                product_name: product?.name,
                unit_of_measure: product?.unit_of_measure,
                completedQuantity: product?.completedQuantity,
              })) || [],
          })),
        },
      })
    }
  }, [workOrder])
  return (
    <>
      {isWorkOrderFetching ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <Field
              type="text"
              label="Work Order ID"
              formik={formik}
              required={true}
              name="workOrderID"
              placeholder="Enter Work order ID"
            />

            <Field
              type="select"
              label="Status"
              formik={formik}
              required={true}
              name="status"
              defaultValue={verificatonOptions.find((option) => option?.value == formik?.values?.status)}
              options={verificatonOptions}
              placeholder="Enter Status"
            />
            <Field
              type="date"
              required={true}
              label="Start Date"
              shouldDisablePastDate
              formik={formik}
              name="startDate"
            />

            <Field type="date" required={true} label="End Date" shouldDisablePastDate formik={formik} name="endDate" />
            {/* *************************** operations **************************** */}
            <EditableTable
              formik={operationsFormik}
              heading="Operations"
              onSelect={(process: any, index: number) => {
                operationsFormik.setFieldValue(`operations.${index}.products`, []),
                  process.output_products.map((p: any, i: number) => [
                    operationsFormik.setFieldValue(`operations.${index}.products.${i}.product_name`, p.product.name),
                    operationsFormik.setFieldValue(`operations.${index}.products.${i}._id`, p.product._id),
                    operationsFormik.setFieldValue(
                      `operations.${index}.products.${i}.unit_of_measure`,
                      p.product.unit_of_measure,
                    ),
                    operationsFormik.setFieldValue(`operations.${index}.products.${i}.targetQuantity`, null),
                  ])
              }}
              loading={processLoading}
              columns={operationsColumns}
              emptyMessage="+ Click on the Add Operation button to add a new operation"
              newRow={newOperationRow}
              name="operations"
              addButtonTitle="Add Operation"
            />
            <div className="mt-4 flex justify-between ">
              <div>
                {workOrder ? (
                  <Button
                    color="primary"
                    onClick={() =>
                      navigate(`/planning/work-orders/edit/${workOrder._id}`, { state: { from: currentPath } })
                    }
                  >
                    Edit Details
                  </Button>
                ) : (
                  <Button color="primary" onClick={() => navigate(`/planning/work-orders/add`)}>
                    Add Details
                  </Button>
                )}
              </div>
              <div className="flex gap-2">
                <Button onClick={close}>{t('cancel')}</Button>
                <Button
                  color="primary"
                  loading={updateWorkOrderLoading || addWorkOrderLoading}
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default QuickAddEditWork
