import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from './modal/Modal'
import { Link } from 'react-router-dom'
import { AlertIcon, LeftArrowIcon } from 'assets/icons'
import { useSendOTPViaEmailMutation } from './auth.endpoints'
import { authErrors } from '../../../../mocks/ErrorMessages'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

type SuccessData = {
  data: any
  error?: undefined
}

type ErrorData = {
  data?: undefined
  error: FetchBaseQueryError | SerializedError
}

type Data = SuccessData | ErrorData

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [sendOTPViaEmail] = useSendOTPViaEmailMutation()
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [showEmailErr, setShowEmailErr] = useState<boolean>(false)
  const [emailTimeout, setEmailTimeout] = useState<any>(null)
  let emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
  }
  const sendOTP = async (event: any) => {
    if (!isEmailValid) {
      setShowEmailErr(true)
      return
    }
    !isEmailValid && event.preventDefault()
    await sendOTPViaEmail({ email: email }).then((res: Data) => {
      if (res?.error) {
        setShowEmailErr(true)
        setIsEmailValid(false)
      } else {
        isEmailValid &&
          navigate('/auth/verify-code', {
            state: {
              contactInfo: email,
              forgotPassword: true,
            },
          })
      }
    })
  }
  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      sendOTP(event)
    }
  }

  useMemo(() => {
    setShowEmailErr(false)
    if (emailTimeout) {
      clearTimeout(emailTimeout)
    }

    if (email !== '' && !emailRegex.test(email)) {
      setIsEmailValid(false)
      const timeOutEmail = setTimeout(() => {
        // setShowEmailErr(true);
      }, 1200)
      setEmailTimeout(timeOutEmail)
      return () => {
        clearTimeout(timeOutEmail)
      }
    } else {
      const timeOutEmail = setTimeout(() => {
        setShowEmailErr(false)
      }, 1200)
      setIsEmailValid(true)
      setEmailTimeout(timeOutEmail)
      return () => {
        clearTimeout(timeOutEmail)
      }
    }
  }, [email])
  return (
    <Modal heading="Trouble logging in">
      <div className="mt-7 flex gap-x-4 py-1">
        <Link to={'/auth/login'} className="hover:cursor-pointer">
          <LeftArrowIcon />
        </Link>
        <p className={'text-lg font-semibold text-[#323333]'}>Change password</p>
      </div>
      <div className="mb-7 mt-4">
        <p className="text-sm font-normal text-[#323333] ">
          Please enter your Email ID so that we can send you a verification code to process your request.
        </p>
      </div>

      <div className="relative mb-7 flex flex-col">
        <label className="font-inter text-xs font-normal text-[#212121]">
          Email Address<span className="text-[#8E0000]">*</span>
        </label>
        <div>
          <input
            style={
              showEmailErr
                ? {
                    border: '1px solid var(--error-700, #8E0000)',
                  }
                : {}
            }
            className={`${
              !showEmailErr ? 'bg-[#EBEFF3] text-[#000]' : 'bg-[#F8D7DA] text-[#8E0000]'
            }  my-2 w-full rounded px-5 py-3.5 text-sm font-normal`}
            type="email"
            value={email}
            onKeyDown={handleKeyDown}
            onChange={handleEmailChange}
            placeholder="Enter your registered email address"
          />
          {showEmailErr && (
            <button className="absolute right-0 top-10 px-4 text-gray-600" disabled={showEmailErr}>
              <AlertIcon />
            </button>
          )}
        </div>
        <span className="font-inter text-xs text-[#8E0000]">
          {showEmailErr && (email === '' ? authErrors.EMPTY_EMAIL : authErrors.EMAIL_INVALID)}
        </span>
      </div>
      <div className="mb-24">
        <button
          type="button"
          onClick={(event) => {
            !isEmailValid && event.preventDefault()
            sendOTP(event)
          }}
          className={`${
            email !== '' && isEmailValid ? 'cursor-pointer bg-[#0B5ED7]' : 'cursor-default bg-[#75838D]'
          } w-full rounded px-8 py-2.5 text-center text-sm text-[#fff]`}
        >
          Send Request
        </button>
      </div>
    </Modal>
  )
}

export default ForgotPasswordPage
