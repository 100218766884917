import React from "react";

interface LightLogoProps {
  width?: number;
  height?: number;
  classProp?: string;
}

const LightLogo: React.FC<LightLogoProps> = ({
  width = 248,
  height = 64,
  classProp = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={classProp}
      viewBox="0 0 248 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.6164 50.2448C31.9085 50.7156 29.1298 50.5777 26.4818 49.8411C23.8338 49.1046 21.383 47.7878 19.3071 45.9864C17.2312 44.185 15.5824 41.9441 14.4802 39.4262C13.378 36.9084 12.85 34.1768 12.9347 31.4296C13.0193 28.6824 13.7145 25.9885 14.9696 23.5433C16.2247 21.0981 18.0084 18.9629 20.1913 17.2927C22.3741 15.6226 24.9014 14.4592 27.5897 13.8871C30.278 13.315 33.06 13.3484 35.7337 13.985L33.5906 22.9861C32.2528 22.6676 30.8608 22.6508 29.5157 22.9371C28.1706 23.2234 26.9061 23.8055 25.8139 24.6411C24.7217 25.4768 23.8292 26.5452 23.2012 27.7686C22.5732 28.9921 22.2254 30.34 22.183 31.7146C22.1406 33.0892 22.4048 34.4559 22.9563 35.7157C23.5078 36.9755 24.3328 38.0968 25.3715 38.9981C26.4101 39.8995 27.6364 40.5583 28.9613 40.9269C30.2863 41.2954 31.6766 41.3644 33.0315 41.1288L34.6164 50.2448Z"
        fill="#0AA8BD"
      />
      <path
        d="M46.6024 19.9326C49.5779 23.3961 51.1688 27.8369 51.0697 32.4019C50.9706 36.9669 49.1885 41.3344 46.0655 44.6656L39.3153 38.3373C40.8779 36.6705 41.7696 34.4852 41.8192 32.2011C41.8688 29.917 41.0727 27.695 39.584 25.9621L46.6024 19.9326Z"
        fill="#E81D1D"
      />
      <ellipse
        cx="32.1851"
        cy="32.0002"
        rx="7.40741"
        ry="7.40741"
        fill="black"
      />
      <path
        d="M71.5328 43.3709C69.3634 43.3709 67.4014 42.9083 65.6468 41.9831C63.924 41.0261 62.5522 39.6702 61.5313 37.9156C60.5104 36.129 60 34.0394 60 31.6467C60 29.3816 60.4626 27.4036 61.3878 25.7128C62.3448 23.9901 63.6688 22.6661 65.3596 21.7409C67.0824 20.8158 69.0603 20.3532 71.2935 20.3532C72.9844 20.3532 74.4359 20.5765 75.6482 21.0231C76.8924 21.4379 77.8655 21.9642 78.5673 22.6023L76.2703 26.7656C74.9304 25.4257 73.4151 24.7557 71.7242 24.7557C69.6824 24.7557 68.1192 25.3778 67.0345 26.622C65.9817 27.8662 65.4553 29.5252 65.4553 31.5988C65.4553 33.9596 65.9977 35.794 67.0824 37.102C68.199 38.41 69.7463 39.064 71.7242 39.064C72.7132 39.064 73.6703 38.9205 74.5954 38.6334C75.5525 38.3462 76.2863 38.0113 76.7967 37.6284L78.663 41.2653C77.9612 41.8077 76.9722 42.3022 75.6961 42.7488C74.4519 43.1635 73.0641 43.3709 71.5328 43.3709Z"
        fill="white"
      />
      <path
        d="M94.2548 43.3709C92.2768 43.3709 90.4424 42.9083 88.7516 41.9831C87.0927 41.0261 85.7528 39.6861 84.7319 37.9634C83.7429 36.2088 83.2484 34.167 83.2484 31.8381C83.2484 29.5411 83.6791 27.5313 84.5405 25.8085C85.4337 24.0858 86.6939 22.7459 88.3209 21.7888C89.948 20.8317 91.8621 20.3532 94.0634 20.3532C95.3395 20.3532 96.5358 20.5765 97.6524 21.0231C98.769 21.4698 99.7102 22.0281 100.476 22.698C101.241 23.3361 101.752 23.9741 102.007 24.6122L102.103 20.9274H107.032V42.8924H102.151L102.055 39.0162C101.513 40.1647 100.524 41.1856 99.0881 42.0788C97.6524 42.9402 96.0414 43.3709 94.2548 43.3709ZM95.1162 39.064C96.9346 39.064 98.466 38.4419 99.7102 37.1977C100.954 35.9216 101.576 34.2627 101.576 32.2209V31.886C101.576 30.5141 101.289 29.2859 100.715 28.2012C100.141 27.1165 99.3592 26.2711 98.3702 25.6649C97.3813 25.0588 96.2966 24.7557 95.1162 24.7557C93.0744 24.7557 91.4952 25.4257 90.3786 26.7656C89.2621 28.0736 88.7038 29.7804 88.7038 31.886C88.7038 34.0234 89.2461 35.7621 90.3308 37.102C91.4474 38.41 93.0425 39.064 95.1162 39.064Z"
        fill="white"
      />
      <path
        d="M116.853 25.1386H112.977V20.9274H116.853V11.9787L122.069 10.8302V20.9274H127.142V25.1386H122.069V42.8924H116.853V25.1386Z"
        fill="white"
      />
      <path
        d="M142.013 43.3709C140.035 43.3709 138.201 42.9083 136.51 41.9831C134.851 41.0261 133.511 39.6861 132.49 37.9634C131.501 36.2088 131.007 34.167 131.007 31.8381C131.007 29.5411 131.438 27.5313 132.299 25.8085C133.192 24.0858 134.452 22.7459 136.08 21.7888C137.707 20.8317 139.621 20.3532 141.822 20.3532C143.098 20.3532 144.294 20.5765 145.411 21.0231C146.528 21.4698 147.469 22.0281 148.234 22.698C149 23.3361 149.511 23.9741 149.766 24.6122L149.861 20.9274H154.79V42.8924H149.909L149.814 39.0162C149.271 40.1647 148.282 41.1856 146.847 42.0788C145.411 42.9402 143.8 43.3709 142.013 43.3709ZM142.875 39.064C144.693 39.064 146.225 38.4419 147.469 37.1977C148.713 35.9216 149.335 34.2627 149.335 32.2209V31.886C149.335 30.5141 149.048 29.2859 148.474 28.2012C147.899 27.1165 147.118 26.2711 146.129 25.6649C145.14 25.0588 144.055 24.7557 142.875 24.7557C140.833 24.7557 139.254 25.4257 138.137 26.7656C137.021 28.0736 136.462 29.7804 136.462 31.886C136.462 34.0234 137.005 35.7621 138.089 37.102C139.206 38.41 140.801 39.064 142.875 39.064Z"
        fill="white"
      />
      <path
        d="M163.128 7.71973H168.249V42.8924H163.128V7.71973Z"
        fill="white"
      />
      <path
        d="M182.776 43.5623L173.684 20.9274H179.522L184.212 34.9008L185.264 38.2984L186.317 34.9008L191.246 20.9274H196.893L183.398 54.9515H178.038L182.776 43.5623Z"
        fill="white"
      />
      <path
        d="M208.764 43.3709C207.233 43.3709 205.638 43.1795 203.979 42.7967C202.352 42.3819 201.187 41.9034 200.485 41.361L202.208 37.2935C204.122 38.6334 206.308 39.3033 208.764 39.3033C211.221 39.3033 212.449 38.5855 212.449 37.1499C212.449 36.5118 212.194 36.0173 211.683 35.6664C211.173 35.3155 210.263 34.9008 208.955 34.4222L207.137 33.7523C205.223 32.9866 203.707 32.0774 202.591 31.0246C201.474 29.9399 200.916 28.5202 200.916 26.7656C200.916 24.7557 201.698 23.1925 203.261 22.0759C204.856 20.9274 206.977 20.3532 209.625 20.3532C210.933 20.3532 212.21 20.4967 213.454 20.7838C214.73 21.071 215.703 21.4379 216.373 21.8845L214.506 25.9999C214.092 25.5852 213.422 25.2343 212.497 24.9471C211.603 24.6281 210.598 24.4686 209.482 24.4686C207.217 24.4686 206.084 25.1545 206.084 26.5263C206.084 27.292 206.387 27.8822 206.993 28.2969C207.6 28.6797 208.589 29.1104 209.96 29.589C210.088 29.6209 210.582 29.7963 211.444 30.1154C213.486 30.9129 215.001 31.8222 215.99 32.843C216.979 33.8639 217.473 35.1719 217.473 36.7671C217.473 38.9364 216.644 40.5794 214.985 41.696C213.358 42.8126 211.284 43.3709 208.764 43.3709Z"
        fill="white"
      />
      <path
        d="M225.711 25.1386H221.835V20.9274H225.711V11.9787L230.927 10.8302V20.9274H236V25.1386H230.927V42.8924H225.711V25.1386Z"
        fill="white"
      />
    </svg>
  );
};

export default LightLogo;
