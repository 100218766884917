import { useFormik } from 'formik'
import Field from 'libs/field'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { selectSelectedItem } from 'store/redux/navSlice'

import { useGetReportingGraphByIdQuery } from 'pages/inventory/inventory-endpoints/inventory.endpoints'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'

const CustomDot = (props: any) => {
  const { cx, cy } = props
  return <circle cx={cx} cy={cy} r={5} stroke="#2268D1" strokeWidth={2} fill="#2268D1" />
}

const QuantityTimeGraph = ({ formik }: any) => {
  const plantId = useSelector(selectSelectedItem)
  const [productId, setProductId] = useState<string | null>(null)
  const initialValues = {
    product: null,
  }

  const quantityTimeFormik = useFormik<any>({
    initialValues: initialValues,
    onSubmit: () => {},
  })
  const filters = {
    date_range_type: 'Last_Month',
    date_range: formik?.values?.dateRange,
  }

  const { data } = useGetReportingGraphByIdQuery(
    {
      plant_id: plantId?.id,
      inventory_id: productId,
      filters: filters,
    },
    {
      skip: !productId,
    },
  )

  const { data: products } = useGetProductQuery(
    {
      plant_id: plantId?.id,
      filters: {
        page_no: 1,
        page_size: 1000,
        sortBy: 'created_on',
        sortDir: 'DESC',
        searchText: '',
      },
    },
    {
      skip: !plantId?.id,
    },
  )

  const productOptions =
    products?.data
      ?.filter((item: any) => item.category === 'Finished Goods')
      .map((item: any) => ({
        label: item.name,
        value: item,
      })) || []

  useEffect(() => {
    setProductId(quantityTimeFormik?.values?.product?._id)
  }, [quantityTimeFormik?.values?.product?._id])

  return (
    <div className="h-full w-full">
      <div className="mx-6 my-2 w-2/5">
        <Field
          type="select"
          formik={quantityTimeFormik}
          name="product"
          placeholder="select product"
          options={productOptions}
        />
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" interval={0} tick={{ fontSize: 14 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="linear" dataKey="quantity" stroke="#000000" dot={<CustomDot />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default QuantityTimeGraph
