// Dropdown.js
import { Dropdown, Menu, Space, Input } from 'antd'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import RigtTickIcon from 'assets/icons/camera-page/RightTickIcon'

const SelectDropdown = ({
  items,
  selected,
  onSelect,
  inputWidth,
  inputHeight,
  dropdownWidth,
  rightIconMargin,
  borderBottomStyle,
}: any) => {
  const handleMenuClick = (item: any) => {
    onSelect(item)
  }

  const menu = (
    <Menu style={{ width: dropdownWidth }}>
      {items.map((item: any, index: any) => (
        <Menu.Item
          key={item.key}
          onClick={() => handleMenuClick(item)}
          style={{
            borderBottom: index < items.length - 1 ? borderBottomStyle : 'none',
          }}
        >
          <Space>
            {item.label}
            {selected && selected.key === item.key && (
              <>
                <span style={{ marginRight: rightIconMargin }}></span>

                <RigtTickIcon color={'#041220'} />
              </>
            )}
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <Space>
        <Input
          value={selected ? selected.label : ''}
          readOnly
          suffix={<ArrowDown color={''} />}
          style={{ width: inputWidth, height: inputHeight }}
        />
      </Space>
    </Dropdown>
  )
}

export default SelectDropdown
