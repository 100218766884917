export const CATEGORY_OPTIONS = [
  {
    name: 'Finished Goods',
    value: 'FINISHED_GOOD',
    _id: '101',
  },
  {
    name: 'Raw Material',
    value: 'RAW_MATERIAL',
    _id: '102',
  },
]

export const STATUS_OPTIONS = [
  {
    label: 'In Progress',
    value: 'IN_PROGRESS',
    _id: '101',
  },
  {
    label: 'Not Started',
    value: 'NOT_STARTED',
    _id: '102',
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
    _id: '104',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
    _id: '105',
  },
]

export interface SatatisticsResponse {
  IN_PROGRESS: number
  CANCELLED: number
  COMPLETED: number
  NOT_STARTED: number
}

export interface SatatisticsRequest {
  plant_id: string
}
