import Button from '../../../libs/button/Button'
import { useNavigate } from 'react-router-dom'

interface Props {
  manufactureOrderFormik: any
  data: any
  tabName: string
  setPage: (page: number) => void
  loading: boolean
}

function SaveChanges({ manufactureOrderFormik, data, setPage, tabName, loading }: Props) {
  const navigate = useNavigate()

  const handleSave = async () => {
    manufactureOrderFormik.setFieldValue(tabName, data)
    try {
    await manufactureOrderFormik.handleSubmit()
      setPage(5)
    } catch (error) {
      console.error('Form submission failed:', error)
    }
  }

  return (
    <div>
      <p className="p-xs-regular">
        Are you sure you want to leave the form. If you leave the form, your changes will be lost. you can save your
        changes on click save button.
      </p>
      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={() => navigate('/planning/mo')}>Cancel</Button>
        <Button type="submit" color="primary" loading={loading} onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default SaveChanges
