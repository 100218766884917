import WhiteCard from 'libs/card/WhiteCard'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'
import BlueTick from 'assets/icons/product/BlueTick'

interface Props {
  tabs: {
    tabTitle: string
    routeName: string
    component: JSX.Element
  }[]
  type?: 'steps' | 'tabs'
  shouldAllowFreeNavigation?: boolean
}

const Wizard = ({
  tabs,
  type = 'tabs',
  shouldAllowFreeNavigation = false,
}: Props) => {
  const params = useParams()
  const navigate = useNavigate()

  return (
    <WhiteCard>
      <div className="flex gap-[16px]">
        <div className="flex flex-col gap-2 left min-w-[180px]">
          {type === 'tabs' &&
            tabs?.map((tab) => {
              const isSelected = params['*'] === tab.routeName
              return (
                <div key={tab.routeName}>
                  <p
                    className={`cursor-pointer rounded-lg px-3 py-1 text-sm
                    duration-200 ease-in-out
                    ${isSelected ? 'bg-brand-primary-200 font-bold' : ''}
                    ${isSelected ? 'hover:bg-primary-300' : 'hover:bg-primary-100'}
                     `}
                    onClick={() => navigate(`./${tab.routeName}`)}
                  >
                    {tab.tabTitle}
                  </p>
                </div>
              )
            })}

          {/* Steps */}
          {type === 'steps' && (
            <>
              {tabs.map((tab, index) => {
                const isSelected = params['*'] === tab.routeName
                const isCompleted =
                  !isSelected &&
                  index < tabs.findIndex((tab) => tab.routeName === params['*'])
                return (
                  <div key={index}>
                    <button
                      onClick={() => navigate(`./${tab.routeName}`)}
                      disabled={
                        !isSelected &&
                        !isCompleted &&
                        !shouldAllowFreeNavigation
                      }
                      className="disabled:bg-transparent flex items-center"
                    >
                      <div>
                        {/**do not delete this extra parent div - it prevents circle to get distord  */}
                        <div
                          className={`flex h-6 w-6 items-center justify-center rounded-full  text-xs font-bold  
                              ${isSelected ? 'border-[1.5px] border-primary-500 ' : isCompleted || shouldAllowFreeNavigation ? 'border-0' : 'border-[1.5px] border-border'}
                              ${isCompleted || shouldAllowFreeNavigation ? 'bg-primary-500 ' : ''}
                              `}
                        >
                          {isCompleted || shouldAllowFreeNavigation ? (
                            <>
                              <BlueTick />
                            </>
                          ) : (
                            index + 1
                          )}
                        </div>
                      </div>
                      <p
                        className={`rounded-lg px-3 py-1 text-sm
                        ${isSelected ? 'font-bold' : ''}
                      `}
                      >
                        {tab.tabTitle}
                      </p>
                    </button>
                    {index < tabs.length - 1 && (
                      <span
                      // className={`ml-3 flex h-[16px] border-l-2 border-dashed ${
                      //   page > index + 1 ? 'border-primary' : 'border-[#B9C4CC]'
                      // }`}
                      ></span>
                    )}
                  </div>
                )
              })}
            </>
          )}
        </div>
        <div className="bg-border pl-[1px] rounded-full"></div>
        <div className="min-h-[70vh] right w-full">
          <Routes>
            <Route
              path="*"
              element={<Navigate replace to={tabs[0].routeName} />}
            />
            {tabs.map((option) => (
              <Route
                key={option.routeName}
                path={option.routeName}
                element={option.component}
              />
            ))}
          </Routes>
        </div>
      </div>
    </WhiteCard>
  )
}

export default Wizard
