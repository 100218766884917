import { useState } from 'react'
import { toastSuccess } from 'libs/toast/toastSucess'
import { useEditProductMutation } from '../products-endpoints/products.endpoints'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { toastFailure } from 'libs/toast/toastFailure'
import { trimStringValues } from 'utils/stringHandlers'

//this module is for adding and editing BOM
const AddUom = ({ removeEntry, productId, data, state }: any) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState(
    data
      ? {
          uom: data,
        }
      : {
          uom: '',
        },
  )

  const [errors, setErrors] = useState<any>({})

  const [editProducts, { isLoading: editProductLoading }] =
    useEditProductMutation()

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const newErrors: any = {}

    if (formData.uom.trim() === '') {
      newErrors.uom = 'UOM cannot be empty.'
    }
    // Set the new errors
    setErrors(newErrors)
    // If there are no errors, submit the form
    if (Object.keys(newErrors).length === 0 && formData.uom) {
      const productData = {
        category: state.category,
        name: state.name,
        code: state.code,
        description: state.description,
        tags: state.tags,
        is_bom: state.is_bom,
        is_consumable: state.is_consumable,
        is_properties: state.is_properties,
        unit_of_measure: formData.uom,
      }

      const data = trimStringValues(productData)

      editProducts({
        data: data,
        productId: productId,
      })
        .unwrap()
        .then(() => {
          const updatedState = {
            ...state,
            unit_of_measure: formData.uom,
          }
          toastSuccess(
            `Product UOM "${formData?.uom}" is updated successfully.`,
          )
          navigate('/configure/products/editProducts', { state: updatedState })
          removeEntry()
        })
        .catch((err) => {
          toastFailure(err?.data?.detail ?? 'Error while updating UOM')
        })
    }
  }

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit}>
          <div className="mt-2">
            <label className="mb-2 p-sm-regular">
              {t('product.unit_of_measure')}
              <span className="text-red">*</span>
            </label>
            <div className="p-xs-regular-dim">
              {t('product.unit_of_measurement_description')}
            </div>
            <div>
              <div className="relative py-1">
                <input
                  required
                  type="text"
                  name="uom"
                  value={formData.uom}
                  onChange={handleInputChange}
                  className={`h-[28px] w-full border ${
                    errors.uom ? 'border-[#DC3545]' : 'border-none'
                  } rounded-md border p-2 pr-8 text-sm outline-none p-xs-regular background-input`}
                  placeholder={t('product.product_code_placeholder')}
                />
                {errors.uom && (
                  <p className="pt-1 text-xs font-normal text-red">
                    {errors.uom}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="mb-8 mt-6 flex justify-end gap-2">
            <div>
              <button
                className="btn-cancel"
                type="button"
                onClick={removeEntry}
              >
                {t('cancel')}
              </button>
            </div>
            <div>
              <button
                type="submit"
                className="btn-primary"
                onClick={handleSubmit}
                disabled={editProductLoading}
              >
                {t('save')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddUom
