import Button from 'libs/button/Button'
import { useRef } from 'react'

const UploadFile = ({ name, value, onChange }: any) => {
  const fileInputRef = useRef(null)
  const handleUploadClick = () => {
    // Trigger the file input click event
    fileInputRef?.current && (fileInputRef.current as any).click()
  }

  return (
    <div className="flex items-center gap-4">
      <Button
        onClick={handleUploadClick}
        className="flex min-w-[96px] items-center justify-center gap-[6px]	 overflow-hidden text-nowrap rounded-[5px] bg-gray-100  px-[12px] py-[10px] font-semibold text-closebtn"
      >
        Browse File
      </Button>
      <span className="primary-gray-4 p-xs-regular truncate">{value ? value.name : 'No file chosen'}</span>
      <input type="file" ref={fileInputRef} name={name} style={{ display: 'none' }} onChange={onChange} />
    </div>
  )
}

export default UploadFile
