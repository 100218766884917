import { useNavigate, useParams } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { selectSelectedItem } from 'store/redux/navSlice'
import { ExtendedColumn } from 'store/services/utilsTypes'

import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import PaginatedTable from 'libs/table/PaginatedTable'
import HeaderCard from 'libs/card/HeaderCard'
import Tags from 'libs/tags/Tags'
import Popup from 'libs/popup/Popup'

import { getRelativeTime } from 'utils/dateTimeHandlers'
import { formatNumber } from 'utils/numberHandlers'

import DeleteWareHouses from '../popup/WareHouseDelete'
import { useGetWarehouseByIdQuery } from '../warehouses-endpoints/warehouses.endpoints'
import {
  Warehouse,
  StorageAreaResponse,
} from '../warehouses-endpoints/warehouseTypes'
import { CellProps } from 'react-table'

const ViewDetails = () => {
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()

  const { data: warehouse, isLoading } = useGetWarehouseByIdQuery(
    {
      plant_id: plant?.id,
      id: id ?? '',
    },
    {
      skip: !plant?.id || !id,
    },
  )
  useTabTitle(`${warehouse?.name ?? ''}`)

  const [deleteWarehousePopUp, setDeleteWarehousePopUp] = useState<{
    isPopUpOpen: boolean
    warehouse: Warehouse | null
  }>({
    isPopUpOpen: false,
    warehouse: null,
  })

  const columns: ExtendedColumn<StorageAreaResponse>[] = useMemo(
    () => [
      {
        Header: t('warehouse.storage_area_name'),
        accessor: 'name',
        width: 200,
        disableSortBy: true,
      },
      {
        Header: t('warehouse.allow_products_name'),
        width: 250,
        accessor: 'products',
        Cell: ({ row }: CellProps<StorageAreaResponse>) => {
          const products = row.original?.products
          const productLabels = products
            ?.slice(0, 4)
            .map((item) => `${item?.name} (${item?.quantity})`)
          const remainingItems = products?.slice(4).map((item) => item?.name)
          const remainingCount = products?.length - productLabels?.length

          return (
            <Tags
              data={productLabels}
              remainingCount={remainingCount}
              color={'#674D00'}
              bgColor={'#FFF3CD'}
              remaining={remainingItems}
            />
          )
        },
        disableSortBy: true,
      },
    ],
    [],
  )

  const countTotalProducts = function (data: Warehouse) {
    if (!data) return '-'
    let products = 0
    data?.storage_areas?.forEach((storageArea: StorageAreaResponse) => {
      products += storageArea.products.length
    })
    const totalProducts = formatNumber(products)
    return totalProducts
  }

  if (isLoading) {
    return <FullPageLoader />
  }

  return (
    <MotionDiv>
      <WhiteCard className="min-h-[80vh]">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div>
              <h1 className="h5">{t('warehouse.warehouse_name')}</h1>
            </div>
            <div className="flex gap-3">
              <Button
                color="error"
                onClick={() =>
                  warehouse &&
                  setDeleteWarehousePopUp({
                    isPopUpOpen: true,
                    warehouse,
                  })
                }
              >
                {t('warehouse.delete_warehouse')}
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  warehouse &&
                  navigate(
                    `/settings/configure/warehouses/edit/${warehouse.id}`,
                  )
                }
              >
                {t('warehouse.edit_warehouse_details')}
              </Button>
            </div>
          </div>

          <div className="flex w-[390px] flex-col gap-2">
            <div className="flex justify-between ">
              <div className="p-xs-regular-dim">
                {t('warehouse.warehouse_name')}
              </div>
              <div>
                <div className="w-60 break-words p-xs-regular">
                  {warehouse?.name ?? '-'}
                </div>
              </div>
            </div>

            <div className="flex justify-between ">
              <div className=" p-xs-regular-dim">
                {t('warehouse.last_updated')}
              </div>
              <div>
                <div className="w-60 p-xs-regular">
                  {warehouse?.updated_on
                    ? getRelativeTime(warehouse.updated_on)
                    : '-'}
                </div>
              </div>
            </div>

            <div className="flex justify-between ">
              <div className=" p-xs-regular-dim">
                {t('warehouse.updated_by')}
              </div>
              <div>
                <div className="w-60 break-words p-xs-regular">
                  {warehouse?.updated_by ?? '-'}
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-2 flex gap-5">
            <HeaderCard
              value={
                warehouse ? formatNumber(warehouse.storage_areas.length) : '-'
              }
              title={t('warehouse.total_storage_areas')}
            />
            <HeaderCard
              value={warehouse ? countTotalProducts(warehouse) : '-'}
              title={t('warehouse.total_products')}
            />
          </div>
          <div className="mt-4 w-full flex-col">
            <PaginatedTable
              columns={columns}
              emptyMessage={t('warehouse.table_placeholder')}
              rows={warehouse?.storage_areas}
              loading={false}
              hidePagination={true}
            />
          </div>
          {deleteWarehousePopUp.warehouse && (
            <Popup
              isOpen={deleteWarehousePopUp?.isPopUpOpen}
              title={t('warehouse.delete_warehouse')}
              onClose={() => {
                setDeleteWarehousePopUp({
                  isPopUpOpen: false,
                  warehouse: null,
                })
              }}
            >
              <DeleteWareHouses
                closePopUp={() => {
                  setDeleteWarehousePopUp({
                    isPopUpOpen: false,
                    warehouse: null,
                  })
                }}
                warehouse={deleteWarehousePopUp.warehouse}
              />
            </Popup>
          )}
        </div>
      </WhiteCard>
    </MotionDiv>
  )
}

export default ViewDetails
