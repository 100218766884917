import { toast } from "react-toastify";
import "./toast.css";

import NewToastSuccess from "assets/icons/toast/NewToastSuccess";


export const toastSuccess = (string:any) =>
  toast.success(string, {
    icon: NewToastSuccess,
    style: {
      background: "#1F8035", // Background color
    },
  });
