import { useState, useMemo } from 'react'
import BlueTick from 'assets/icons/product/BlueTick'
import { useNavigate, Route, Routes } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { scaleMotionProps2 } from 'utils/FramerProps'
import GeneralDetails from '../tabs/general-details/GeneralDetails'
import FormFields from '../tabs/form-fields/FormFields'
import { t } from 'i18next'
import { useGetProcessByIdQuery } from '../operational-process-endpoints/operational_process.endpoints'
import useTabTitle from 'libs/hooks/useTabTitle'

const AddEditOperationalProcess = () => {
  const navigate = useNavigate()

  const [params, setParams] = useState({ productId: null, processId: null })

  const { data: process } = useGetProcessByIdQuery(
    {
      process_id: params?.processId,
    },
    {
      skip: !params?.processId,
    },
  )
  useTabTitle(
    `${params.processId ? `${t('plannings.update')} ${t('operational_process')}` : t('new_operational_process')}`,
  )

  const [page, setPage] = useState(1)

  const page2 = window.location.pathname.includes('form-fields')

  const [selectedProductId, setSelectedProductId] = useState(null)

  const combinedProducts = useMemo(() => {
    const array: any = []
    if (process?.input_products?.length) {
      array.push({
        products: process?.input_products,
        product_type: 'Input Products',
      })
    }
    if (process?.output_products?.length) {
      array.push({
        products: process?.output_products,
        product_type: 'Output Products',
      })
    }
    return array
  }, [process])

  const handleProductClick = (productId: any) => {
    setSelectedProductId(productId)
    navigate(`./form-fields/${params?.processId}/product/${productId}`)
  }

  const navigateToGeneralDetails = () => {
    if (params?.processId) {
      navigate(`./editdetails/${params?.processId ?? ''}`)
    } else {
      navigate(`./details/${params?.processId ?? ''}`)
    }
    setSelectedProductId(null)
  }

  const navigateToFormFields = () => {
    if (params?.productId && params?.processId) {
      navigate(`./form-fields/${params?.processId}/product/${params.productId}`)
    }
  }

  return (
    <AnimatePresence>
      <motion.div {...scaleMotionProps2} className="mb-20">
        <>
          <div className="flex justify-between">
            <div className="flex items-baseline gap-2 ">
              <h2 className="mb-2 max-w-[500px] truncate h4-bold-black"></h2>
            </div>
            {/* <div className="flex items-baseline gap-1 mt-4 p-md-regular">
              <div>{page2 ? 'Last updated :' : null}</div>
              <div>{page2 ? 'Just now' : null}</div>
            </div> */}
          </div>
          <div className=" flex min-h-[80vh]  rounded bg-white">
            {/* Left hand side */}
            <div className="relative w-1/4 border-r border-border  p-6">
              <div className="mb-4 flex items-center gap-2">
                <div
                  className={`h-6 w-6 rounded-3xl border p-2 ${
                    page2 ? 'border-primary bg-primary' : 'border-primary'
                  } flex items-center justify-center`}
                >
                  <div className="text-xs font-bold">
                    {page2 ? <BlueTick /> : '1'}
                  </div>
                </div>
                <p
                  className="cursor-pointer whitespace-nowrap p-sm-regular"
                  onClick={navigateToGeneralDetails}
                >
                  {t('general_details')}
                </p>
              </div>
              <div
                className={`absolute h-[16px] border-r-2 border-dashed  ${
                  page2 ? 'border-[#1192F0]' : 'border-border'
                }`}
                style={{ left: '35px', top: '48px' }}
              ></div>
              <div className="mb-4 flex items-center gap-2">
                <div
                  className={`h-6 w-6 rounded-3xl border p-2 ${
                    page === 3
                      ? 'border-primary bg-primary'
                      : page2
                        ? 'border-primary'
                        : 'border-border-dark'
                  } flex items-center justify-center`}
                >
                  <div className="text-xs font-bold">
                    {page === 3 ? <BlueTick /> : '2'}
                  </div>
                </div>

                <p
                  className={`whitespace-nowrap p-sm-regular ${params?.productId && 'cursor-pointer'}`}
                  onClick={navigateToFormFields}
                >
                  {t('form_fields')}
                </p>
              </div>

              {/* List of products */}
              {combinedProducts.map((products: any) => {
                return (
                  <div key={products.product_type} className="ml-3 ">
                    <p className="text-sm font-semibold">
                      {products.product_type}
                    </p>
                    <div className="my-2">
                      {products.products.map(({ product }: any) => {
                        return (
                          <div
                            key={product._id}
                            className={`cursor-pointer rounded-lg p-1 text-xs   ${
                              selectedProductId === product._id
                                ? 'bg-brand-primary-200 font-medium'
                                : ''
                            }`}
                            onClick={() => handleProductClick(product._id)}
                          >
                            <p className="p-[2px] px-2">{product.name}</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>

            {/* Right hand side */}
            <div className="w-3/4 p-6 " style={{ overflowX: 'auto' }}>
              <Routes>
                {/* Routes for NEW WIZARD */}
                <Route
                  path="details"
                  element={
                    <GeneralDetails setPage={setPage} setParams={setParams} />
                  }
                />
                <Route
                  path="editdetails/:processId"
                  element={
                    <GeneralDetails setPage={setPage} setParams={setParams} />
                  }
                />
                <Route
                  path="form-fields/:processId/product/:productId"
                  element={
                    <FormFields
                      setParams={setParams}
                      setSelectedProductId={setSelectedProductId}
                      combinedProducts={combinedProducts}
                    />
                  }
                />
                <Route
                  path="form-fields/:processId/product"
                  element={
                    <FormFields
                      setParams={setParams}
                      setSelectedProductId={setSelectedProductId}
                      combinedProducts={combinedProducts}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </>
      </motion.div>
    </AnimatePresence>
  )
}

export default AddEditOperationalProcess
