import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import Button from 'libs/button/Button'
import { useGetEmployeeDepartmentByIdQuery } from '../employee-departments-endpoints/employeesdepartment.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PaginatedTable from 'libs/table/PaginatedTable'

import FullPageLoader from 'libs/loader/FullPageLoader'
import Popup from 'libs/popup/Popup'
import { useState } from 'react'
import DeleteEmployeeDepartment from '../popup/DeleteEmployeeDepartment'
import { hasAccess } from 'utils/utils'
import { ExtendedColumn } from 'store/services/utilsTypes'

const columns: ExtendedColumn<object>[] = [
  {
    Header: 'Designation Name',
    accessor: 'name',
    width: 150,
    disableSortBy: true,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }: { value: number }) => value || '-',
    width: 250,
    disableSortBy: true,
  },
  {
    Header: 'Employees',
    accessor: 'employee_count',
    Cell: ({ value }: { value: number }) => value || '-',
    disableSortBy: true,
  },
]

const ViewDepartment = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const employeeRolesAccess = hasAccess('emp', { er: 'e' })
  const plant = useSelector(selectSelectedItem)
  const { data: EmployeeData, isLoading: DepartmentLoading } =
    useGetEmployeeDepartmentByIdQuery(
      {
        plant_id: plant?.id,
        departmentID: id,
      },
      {
        skip: !plant?.id && !id,
      },
    )

  const [deletePopUp, setDeletePopUp] = useState({
    isPopUpOpen: false,
    EmployeeData: null,
  })

  return (
    <MotionDiv>
      {!DepartmentLoading ? (
        <WhiteCard className="mt-3 flex flex-col gap-4">
          <span className="flex justify-between">
            <h5 className="h5">Department Details</h5>
            {employeeRolesAccess && (
              <div className="flex gap-4">
                <Button
                  onClick={() =>
                    setDeletePopUp({
                      isPopUpOpen: true,
                      EmployeeData: EmployeeData || null,
                    })
                  }
                  color="error"
                >
                  Delete Department
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    navigate(
                      `/employee/employee-department/${id}/edit-employee-department`,
                    )
                  }
                >
                  Edit Department
                </Button>
              </div>
            )}
          </span>
          <div className="grid grid-cols-[150px,1fr] gap-4 text-xs">
            <span className="text-gray-500">Department Name</span>
            <span>{EmployeeData?.name}</span>
            <span className="text-gray-500">Description</span>
            <span>{EmployeeData?.description ?? '-'}</span>
          </div>
          <h5 className="h5">Designations</h5>
          <PaginatedTable
            rows={EmployeeData?.designations}
            columns={columns}
            loading={DepartmentLoading}
            emptyMessage={'No Designations Found'}
            hidePagination
          />

          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Work Order"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, EmployeeData: null })
            }}
          >
            <DeleteEmployeeDepartment
              closePopUp={() => {
                setDeletePopUp({ isPopUpOpen: false, EmployeeData: null })
              }}
              data={deletePopUp.EmployeeData}
            />
          </Popup>
        </WhiteCard>
      ) : (
        <FullPageLoader />
      )}
    </MotionDiv>
  )
}

export default ViewDepartment
