import React from "react";

interface SuccessIconProps {
  width?: number;
  height?: number;
  color?: string;
  classProp?: string;
}
const SuccessIcon: React.FC<SuccessIconProps> = ({
  width = 50,
  height = 50,
  color = "#28A745",
  classProp = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      className={classProp}
    >
      <path
        d="M20.8333 33.75L10.4167 23.3333L13.3542 20.375L20.8333 27.8542L36.6458 12.0417L39.5833 15M39.5833 2.5H10.4167C8.10417 2.5 6.25 4.35417 6.25 6.66667V33.6042C6.25 35.0417 6.97917 36.3125 8.08333 37.0625L25 48.3333L41.8958 37.0625C43 36.3125 43.75 35.0417 43.75 33.6042V6.66667C43.75 5.5616 43.311 4.50179 42.5296 3.72039C41.7482 2.93899 40.6884 2.5 39.5833 2.5Z"
        fill={color}
      />
    </svg>
  );
};

export default SuccessIcon;
