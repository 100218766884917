import WhiteCard from 'libs/card/WhiteCard'
import useTabTitle from 'libs/hooks/useTabTitle'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import ViewBasicDetails from './tabs/ViewBasicDetails'
import ViewPartsDetails from './tabs/ViewPartsDetails'
import ViewDocuments from './tabs/ViewDocuments'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetMachineByIDQuery } from '../machine-endpoints/machine.endpoints'
import FullPageLoader from 'libs/loader/FullPageLoader'

const routes = [
  {
    label: 'Basic Details',
    route: 'basic-details',
  },
  {
    label: 'Parts Details',
    route: 'parts-details',
  },
  {
    label: 'Documents',
    route: 'documents',
  },
]

const ViewMachineDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const plant = useSelector(selectSelectedItem)
  const { data: machineData, isLoading: isMachineDataLoading } = useGetMachineByIDQuery(
    {
      plantId: plant?.id,
      machineID: params?.id,
    },
    {
      skip: !plant?.id || !params?.id,
    },
  )
  useTabTitle('View Machine Details')
  return (
    <>
      {isMachineDataLoading ? (
        <div>
          <FullPageLoader />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <div>
            <h2 className="h4-bold-black">{machineData?.name ?? '-'}</h2>
          </div>
          <WhiteCard>
            <div className="flex min-h-[70vh] flex-row">
              <div className="flex w-1/4 flex-col gap-2 border-r-2 p-4">
                {routes.map((route) => {
                  const isSelected = params['*'] === route.route
                  return (
                    <div key={route.route}>
                      <p
                        className={`cursor-pointer rounded-lg  ${isSelected ? 'bg-brand-primary-200 font-bold' : ''}  px-3 py-1 hover:bg-gray-100`}
                        onClick={() => navigate(`./${route.route}`)}
                      >
                        {route.label}
                      </p>
                    </div>
                  )
                })}
              </div>
              <div className="flex h-full w-full flex-col gap-2 p-4">
                <Routes>
                  {/* Routes for NEW WIZARD */}
                  <Route path="*" element={<Navigate replace to={'basic-details'} />} />
                  <Route path="basic-details" element={<ViewBasicDetails />} />
                  <Route path="parts-details" element={<ViewPartsDetails />} />
                  <Route path="documents" element={<ViewDocuments />} />
                </Routes>
              </div>
            </div>
          </WhiteCard>
        </div>
      )}
    </>
  )
}
export default ViewMachineDetails
  