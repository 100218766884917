import Card from 'libs/card/Card'
import { useNavigate } from 'react-router-dom'
import MotionDiv from 'libs/motionDiv'
import arrowRight from 'assets/icons/misc/arrowRight.svg'
import icons from 'assets/index.ts'
import Widgets from './widgets/Widgets'
import { hasAccess } from 'utils/utils'

interface CardDetail {
  icon: React.ReactNode
  title: string
  subtitle: string
  route: string
  disabled: boolean
}

const Dashboard = () => {
  const navigate = useNavigate()
  const inventoryDashboardAccess = hasAccess('das', { id: 'v' })
  const workorderDashboardAccess = hasAccess('das', { wd: 'view' })
  const manufactureOrderDashboardAccess = hasAccess('das', { md: 'v' })

  const cardDetails = [
    inventoryDashboardAccess && {
      icon: <img src={icons.InventoryDashboard} alt="" />,
      title: 'Inventory Report',
      route: '/dashboard/inventory',
    },
    workorderDashboardAccess && {
      icon: <img src={icons.WorkOrderDashboard} alt="" />,
      title: 'Work Order Report',
      route: '/dashboard/work-order',
    },
    manufactureOrderDashboardAccess && {
      icon: <img src={icons.ManufactureOrderDashboard} alt="" />,
      title: 'Manufacture Order Report',
      route: '/dashboard/manufacture-order',
    },
  ].filter(Boolean) as CardDetail[]

  return (
    <MotionDiv>
      <div className="h-full">
        <h1 className="h4-bold-black">Dashboard</h1>
        <div className="relative h-full w-full">
          <div className="grid h-full w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 ">
            {cardDetails.map((card, index) => (
              <div key={`${index}_${card.title}`}>
                <Card
                  key={`${index}_${card.title}`}
                  title={card?.title}
                  leftIcon={card?.icon}
                  rightCenterIcon={
                    <img src={arrowRight} alt="" style={{ height: '15px' }} />
                  }
                  onClick={() => navigate(card.route)}
                  titleStyle="font-medium text-gray-800"
                />
              </div>
            ))}
          </div>
        </div>
        {/* widgets */}
        <Widgets />
      </div>
    </MotionDiv>
  )
}

export default Dashboard
