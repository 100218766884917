import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'

export const maintenanceOrder = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postMaintenanceOrder: builder.mutation({
      query: ({ data, plantId }) => {
        return {
          url: `/plant/${plantId}/maintenance-order`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: [
        'maintenanceOrderUpdate',
        'getMaintenanceOrder',
        'getMaintenanceRequests',
      ],
    }),
    //check
    getMaintenanceOrders: builder.query({
      query: (params) => {
        let URL = ''
        params.filters
          ? (URL = buildUrl(
              `/plant/${params.plantId}/maintenance-orders`,
              params.filters,
            ))
          : (URL = `/plant/${params.plantId}/maintenance-requests`)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the maintenance orders')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMaintenanceOrder'],
    }),
    putMaintenanceOrder: builder.mutation({
      query: ({ data, plantId, moID }) => {
        return {
          url: `/plant/${plantId}/maintenance-order/${moID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: [
        'maintenanceOrderUpdate',
        'getMaintenanceRequests',
        'getMaintenanceOrder',
        'getMaintenanceOrderById',
      ],
    }),
    patchStartOrderStatus: builder.mutation({
      query: ({ plantId, moID }) => {
        return {
          url: `/plant/${plantId}/start-maintenance-order/${moID}/status`,
          method: 'PATCH',
          headers: getToken(),
        }
      },
      invalidatesTags: [
        'maintenanceOrderUpdate',
        'getMaintenanceOrderById',
        'getMaintenanceOrder',
        'getMaintenanceRequests',
        'getMaintenanceRequestById',
      ],
    }),
    getMaintenanceOrderById: builder.query({
      query: ({ plantId, moID }) => {
        return {
          url: `/plant/${plantId}/maintenance-order/${moID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Maintenance order by id')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMaintenanceOrderById'],
    }),
    getMaintenanceOrdersCount: builder.query({
      query: ({ plantId }) => {
        return {
          url: `/plant/${plantId}/maintenance-order-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Maintenance order count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getMaintenanceOrderCount'],
    }),
    deleteMaintenanceOrder: builder.mutation({
      query: ({ plant_id, moID }) => {
        return {
          url: `/plant/${plant_id}/maintenance-order/${moID}`,
          method: 'DELETE',
          headers: getToken(),
        }
      },
      invalidatesTags: [
        'maintenanceOrderUpdate',
        'getMaintenanceOrder',
        'getMaintenanceRequests',
        'getMaintenanceRequestById',
      ],
    }),
    cancelMaintenanceOrderStatus: builder.mutation({
      query: ({ plant_id, moID, remark }) => {
        return {
          url: `/plant/${plant_id}/cancel-maintenance-order/${moID}/status?remark=${remark}`,
          method: 'PATCH',
          headers: getToken(),
        }
      },
      invalidatesTags: [
        'maintenanceOrderUpdate',
        'getMaintenanceOrderById',
        'getMaintenanceOrder',
        'getMaintenanceRequests',
        'getMaintenanceRequestById',
      ],
    }),
    closeMaintenanceOrderStatus: builder.mutation({
      query: ({ plant_id, moID }) => {
        return {
          url: `/plant/${plant_id}/close-maintenance-order/${moID}/status`,
          method: 'PATCH',
          headers: getToken(),
        }
      },
      invalidatesTags: [
        'maintenanceOrderUpdate',
        'getMaintenanceOrderById',
        'getMaintenanceOrder',
        'getMaintenanceRequests',
        'getMaintenanceRequestById',
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostMaintenanceOrderMutation,
  useGetMaintenanceOrdersQuery,
  useGetMaintenanceOrderByIdQuery,
  useDeleteMaintenanceOrderMutation,
  useCancelMaintenanceOrderStatusMutation,
  usePutMaintenanceOrderMutation,
  usePatchStartOrderStatusMutation,
  useCloseMaintenanceOrderStatusMutation,
  useGetMaintenanceOrdersCountQuery,
} = maintenanceOrder
