import { useState, useRef, useEffect } from 'react'

import { toastFailure } from 'libs/toast/toastFailure'
import { toastSuccess } from 'libs/toast/toastSucess'

import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'

import NumberInput from '../input-type/NumberInput'
import TextInput from '../input-type/TextInput'
import DateSelection from '../input-type/DateSelection'
import DropDown from '../input-type/DropDown'
import Checkbox from '../input-type/CheckBox'
import TimeSelection from '../input-type/TimeSelection'
import PredefindField from '../input-type/PredefindField'
import CalculateField from '../input-type/CalculatedField'
import { usePutOperationalFormFieldMutation } from '../operational-process-endpoints/operational_process.endpoints'
import { isValidFormula } from '../utils'
import Button from '../../../libs/button/Button'
import { useParams } from 'react-router-dom'
import { trimStringValues } from 'utils/stringHandlers'

const data1 = [
  {
    id: 1,
    name: 'Text input',
  },
  {
    id: 2,
    name: 'Number input',
  },
  {
    id: 3,
    name: 'Date selection',
  },
  {
    id: 4,
    name: 'Time selection',
  },
  {
    id: 5,
    name: 'Checkbox',
  },
  {
    id: 6,
    name: 'Dropdown selection',
  },
  {
    id: 7,
    name: 'Predefined field',
  },
  {
    id: 8,
    name: 'Calculated field',
  },
]

const EditField = ({
  deletePopup,
  selectedEntry,
  totalFromChild,
  productId,
  closePanel,
  numberFields,
}: any) => {
  const [editField, { isLoading }] = usePutOperationalFormFieldMutation()
  const [errors, setErrors] = useState<any>({})
  const [selectedTitle, setSelectedTitle] = useState(selectedEntry?.type)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [isMandatory, setIsMandatory] = useState(selectedEntry?.is_required)
  const [childValue, setChildValue] = useState<any>('')
  const [formData, setFormData] = useState({
    fieldName: selectedEntry?.name || '',
    field_type: selectedEntry?.type || '',
    is_required: !!selectedEntry?.is_required || '',
  })

  const { processId } = useParams()

  const handleFormDataChange = (data: any) => {
    setChildValue(data)
  }

  const isAlphanumeric = (value: string) => /^[a-z0-9\s]+$/i.test(value)

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    const updatedFormData = { ...formData, [name]: value }
    const updatedErrors = { ...errors, [name]: '' }

    if (isAlphanumeric(value) || value === '') {
      setFormData(updatedFormData)
    } else {
      updatedErrors[name] = 'Only alphanumeric characters are allowed.'
    }

    setErrors(updatedErrors)
  }
  const popupRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click is outside the popup
      if (popupRef.current && !event.target.closest('.ignoreref')) {
        setIsDropdownVisible(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleTitleClick = (title: any) => {
    setSelectedTitle(title)
    setIsDropdownVisible(false)

    setErrors({ ...errors, selectedTitle: '' })
  }

  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const handleCheckboxChange = () => {
    setIsMandatory(!isMandatory)
    setFormData((prevFormData) => ({
      ...prevFormData,
      required: !isMandatory, // If isMandatory is true, set required to false and vice versa
    }))
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const newErrors: any = {}

    // Check each field for errors
    if (formData.fieldName.trim() === '') {
      newErrors.fieldName =
        'Field name cannot be empty. Please enter a relevant name.'
    }
    if (selectedTitle === '') {
      newErrors.selectedTitle =
        'Input type cannot be empty. Please select an option from the dropdown list.'
    }

    if (childValue?.has_prefix && childValue?.prefix_text?.trim() === '') {
      newErrors.prefixText =
        'Prefix text cannot be empty, if prefix is enabled. If not required, please disable the prefix by clicking on the toggle switch above.'
    }

    if (childValue?.has_suffix && childValue?.suffix_text?.trim() === '') {
      newErrors.suffixText =
        'Suffix text cannot be empty, if suffix is enabled. If not required, please disable the suffix by clicking on the toggle switch above.'
    }
    if (childValue?.has_unit && childValue?.unit?.trim() === '') {
      newErrors.unit =
        'Units of measure cannot be empty, if enabled. If your measure does not require a unit, please disable the units by clicking on the toggle switch above.'
    }

    if (childValue?.has_max_value && childValue?.max_value?.trim() === '') {
      newErrors.maxValue =
        'Max value cannot be empty, if enabled. If your measure does not has a maximum value or cannot be determined, please disable by clicking on the toggle switch above.'
    }

    if (childValue?.has_min_value && childValue?.min_value?.trim() === '') {
      newErrors.minValue =
        'Min value cannot be empty, if enabled. If your measure does not has a minimum value or cannot be determined, please disable by clicking on the toggle switch above.'
    }
    if (childValue?.predefined?.trim() === '') {
      newErrors.predefined =
        'Please mention a field that is defined previously in any of the other processes.'
    }

    const errorMessageInFormula = isValidFormula(
      childValue?.formula?.trim(),
      childValue?.formulaValue?.trim(),
    )

    if (childValue?.formula?.trim() === '') {
      newErrors.formula =
        'Formula cannot be empty. Please use predefined fields to create a formula.'
    } else if (errorMessageInFormula != '') {
      newErrors.formula = errorMessageInFormula
    }

    if (childValue?.has_number && childValue?.number?.trim() === '') {
      newErrors.number =
        'Please enter the number of digits to round off. If your measure does not require rounding off, please disable the by clicking on the toggle switch above.'
    }

    const allFieldsFilled = (childValue?.options ?? []).every(
      (input: any) => input.name.trim() !== '',
    )

    if (
      childValue?.options?.length === 1 &&
      childValue?.options[0]?.name?.trim() === ''
    ) {
      newErrors.dropdown =
        'Please mention at least one option to use this field effectively.'
    }

    if (!allFieldsFilled) {
      newErrors.emptyField = 'Option field can not be blank'
    }

    if (errors?.maxValue1) {
      newErrors.maxValue1 = 'Max value must be greater than Min value.'
    }

    if (errors?.minValue1) {
      newErrors.minValue1 = 'Max value must be greater than Min value.'
    }

    // Set the new errors
    setErrors(newErrors)

    if (Object.keys(newErrors).length > 0) {
      return
    }

    if (Object.keys(newErrors).length === 0) {
      const newFormData = {
        name: formData.fieldName,
        type: selectedTitle,
        is_required: isMandatory,
        ...childValue,
      }
      const data = trimStringValues(newFormData)
      editField({
        processId: processId,
        fieldId: selectedEntry?.id,
        productId: productId,
        data: data,
      })
        .unwrap()
        .then(() => {
          closePanel()
          toastSuccess(` "${formData?.fieldName}" field updated successfully.`)
        })
        .catch((err) => {
          if (
            err?.data?.detail ===
            `Unable to update field : Duplicate field name '${formData.fieldName}' found in product`
          ) {
            newErrors.fieldName = `The field name '${formData.fieldName}' is already exits. Please choose a different name.`
          }
          toastFailure(`${err?.data?.detail || 'Unknown error'}`)
        })
    }
  }

  return (
    <div className="flex h-screen flex-col">
      <form
        className="flex h-full flex-col justify-between"
        onSubmit={handleSubmit}
      >
        <div className="flex gap-5 ">
          <div>
            <div className="mt-5">
              <label className="mb-1 p-sm-regular ">
                Field Name <span className="text-[#DC3545]">*</span>
              </label>
              <div>
                <p className="p-xs-regular-dim">
                  This will show up as the label of the form field.
                </p>
                <div className="relative py-1">
                  <input
                    type="text"
                    name="fieldName"
                    value={formData.fieldName}
                    onChange={handleInputChange}
                    className={`h-[28px] w-[360px] border ${
                      errors.fieldName ? 'border-[#DC3545]' : 'border-[#E0E0E0]'
                    } rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                    placeholder="Enter relevant name for this field."
                    maxLength={100}
                  />
                  {errors.fieldName && (
                    <p className="pt-1 text-xs font-normal text-[#DC3545]">
                      {errors.fieldName}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="relative mt-5">
                <label className="mb-1 p-sm-regular">
                  Input Type <span className="text-[#DC3545]">*</span>
                </label>
                <p className="w-[360px] p-xs-regular-dim ">
                  You can specify the type of data you expect people to enter;
                  text, number, date, checkbox etc.
                </p>
                <input
                  name="propertyType"
                  type="text"
                  autoComplete="off"
                  className={`ignoreref h-[28px] w-[360px] ${
                    errors.selectedTitle
                      ? 'border-[#DC3545]'
                      : 'border-[#E0E0E0]'
                  } ${isDropdownVisible ? 'bg-[#FFFFFF]' : 'bg-[#EBEFF3]'} cursor-pointer rounded-md 
         border border-solid p-2 pr-8 text-sm outline-none p-xs-regular focus:border-[#1193F0]`}
                  placeholder="Select"
                  onClick={handleInputClick}
                  value={selectedTitle}
                  readOnly
                />

                {errors.selectedTitle && (
                  <p className="w-[360px] pt-1 text-xs font-normal text-[#DC3545]">
                    {errors.selectedTitle}
                  </p>
                )}
                <AnimatePresence>
                  {isDropdownVisible && (
                    <motion.div
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={dropdownMotionProps}
                      ref={popupRef}
                      className="scroll-bar absolute max-h-[12rem] min-h-[20px] w-[360px] overflow-auto rounded bg-[#fff] "
                      style={{
                        zIndex: '3',
                        boxShadow:
                          '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                      }}
                    >
                      {data1.map((item: any) => (
                        <div
                          key={item.title}
                          className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]"
                          onClick={() => handleTitleClick(item.name)}
                        >
                          <div className="p-xs-regular">{item.name}</div>
                        </div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>

            <div className="mt-6 flex ">
              <div>
                <div className="p-sm-regular">Mandatory field</div>
                <p className="w-[310px] p-xs-regular-dim">
                  Enable this if the person capturing the data should not miss
                  this.
                </p>
              </div>
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="required"
                    checked={isMandatory}
                    onChange={handleCheckboxChange}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="border-l-[1px] border-[#D2DADF] "></div>

          <div>
            {selectedTitle === '' && (
              <div className="flex h-full items-center justify-center pl-28 text-[#B9C4CC] h6">
                <p className="text-center">Please select input type</p>
              </div>
            )}

            {selectedTitle === 'Text input' && (
              <TextInput
                isMandatory={isMandatory}
                data={selectedEntry}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {selectedTitle === 'Number input' && (
              <NumberInput
                isMandatory={isMandatory}
                data={selectedEntry}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {selectedTitle === 'Date selection' && (
              <DateSelection
                isMandatory={isMandatory}
                data={selectedEntry}
                onFormDataChange={handleFormDataChange}
              />
            )}
            {selectedTitle === 'Dropdown selection' && (
              <DropDown
                isMandatory={isMandatory}
                data={selectedEntry}
                errors={errors}
                setErrors={setErrors}
                onFormDataChange={handleFormDataChange}
              />
            )}
            {selectedTitle === 'Checkbox' && (
              <Checkbox
                isMandatory={isMandatory}
                data={selectedEntry}
                onFormDataChange={handleFormDataChange}
              />
            )}
            {selectedTitle === 'Time selection' && (
              <TimeSelection
                isMandatory={isMandatory}
                data={selectedEntry}
                onFormDataChange={handleFormDataChange}
              />
            )}
            {selectedTitle === 'Predefined field' && (
              <PredefindField
                isMandatory={isMandatory}
                PredefinedFieldData={selectedEntry}
                onFormDataChange={handleFormDataChange}
                processId={totalFromChild?.id}
                errors={errors}
                setErrors={setErrors}
              />
            )}
            {selectedTitle === 'Calculated field' && (
              <CalculateField
                isMandatory={isMandatory}
                CalculateFieldData={selectedEntry}
                onFormDataChange={handleFormDataChange}
                errors={errors}
                setErrors={setErrors}
                processId={totalFromChild?.id}
                numberFields={numberFields}
              />
            )}
          </div>
        </div>
        <div className="mb-2 flex justify-between">
          <div className="mt-4 ">
            <Button type="button" color="error" onClick={deletePopup}>
              Delete
            </Button>
          </div>
          <div className="flex items-end justify-end  gap-2 ">
            <div>
              <Button type="button" onClick={closePanel}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                color="primary"
                loading={isLoading}
                onClick={(e) => handleSubmit(e)}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditField
