import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikProps, useFormik } from 'formik'

import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import NewEditableTable from 'libs/table/NewEditableTable'
import { ADDRESS_TYPE_OPTIONS } from 'pages/contacts/commonConstants'
import { AddressDetailsFormik } from 'pages/contacts/commonTypes'
import { CustomerFormik } from '../../customer-endpoints/customerTypes'

interface AddressDetailsProps {
  customerFormik: FormikProps<CustomerFormik>
  loading: boolean
}

const addressDetails = [
  {
    title: 'Address Type',
    accessor: 'addressType',
    options: ADDRESS_TYPE_OPTIONS,
    fieldType: 'select',
    flex: 2,
    editable: true,
    placeholder: 'Select address type',
  },

  {
    title: 'Address line 1',
    accessor: 'addressLine1',
    editable: true,
    placeholder: 'Enter address',
  },
  {
    title: 'Address line 2',
    accessor: 'addressLine2',
    editable: true,
    placeholder: 'Enter address',
  },
  {
    title: 'City',
    accessor: 'city',
    editable: true,
    placeholder: 'Enter city',
  },
  {
    title: 'State',
    accessor: 'state',
    editable: true,
    placeholder: 'Enter state',
  },
  {
    title: 'Zip Code',
    accessor: 'zipCode',
    editable: true,
    placeholder: 'Enter zip code',
  },
  {
    title: 'Country',
    accessor: 'country',
    editable: true,
    placeholder: 'Enter country',
  },
  {
    title: 'Primary',
    accessor: 'isPrimary',
    editable: true,
    fieldType: 'radio',
  },
]

const AddressDetails: React.FC<AddressDetailsProps> = ({
  customerFormik,
  loading,
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [data, setData] = useState([])

  const addressFormik = useFormik<AddressDetailsFormik>({
    initialValues: {
      addresses: [],
    },
    onSubmit: (values) => {
      // write a logic to remove _id from all the addresses of the customer and if value is '' then remove that key from the object
      values.addresses.forEach((address: any) => {
        delete address._id

        if (address.isPrimary === '') {
          delete address.isPrimary
        }
        if (address.addressLine1 === '') {
          delete address.addressLine1
        }
        if (address.addressLine2 === '') {
          delete address.addressLine2
        }
        if (address.city === '') {
          delete address.city
        }
        if (address.addressType === '') {
          delete address.addressType
        }
        if (address.state === '') {
          delete address.state
        }
        if (address.zipCode === '') {
          delete address.zipCode
        }
        if (address.country === '') {
          delete address.country
        }
      })

      values.addresses.forEach((address) => {
        if (!Object.keys(address)?.length) {
          values.addresses = values.addresses.filter((item) => item !== address)
        }
      })

      const isAddressValid = values.addresses.every((address) => {
        return (
          address.addressType &&
          address.addressLine1 &&
          address.city &&
          address.state &&
          address.zipCode &&
          address.country
        )
      })
      if (!isAddressValid) {
        toast.error('Please fill all the address fields')
        return
      }

      customerFormik.setFieldValue('AddressDetails', {
        addresses: values?.addresses,
      })
      customerFormik.handleSubmit()
    },
  })

  useEffect(() => {
    addressFormik.resetForm({
      values: {
        addresses: customerFormik?.values?.AddressDetails?.addresses,
      },
    })
  }, [customerFormik])

  const handleAddress = () => {
    addressFormik?.setFieldValue('addresses', data ? data : [])
    addressFormik.handleSubmit()
  }

  return (
    <div className="flex h-full flex-col justify-between gap-4">
      <div>
        <span>
          <h4 className="h4-bold-black">Address Details</h4>
          <p className="text-sm text-primary-gray-4">
            {!id
              ? 'Add the address details that are required to add new customer.'
              : 'Edit the existing customer details to make necessary changes and updates.'}
          </p>
        </span>
        <div className="mt-4">
          <NewEditableTable
            defaultRows={addressFormik?.values?.addresses}
            columns={addressDetails}
            addButtonTitle="Add Address"
            onChange={(values) => {
              setData(values)
            }}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Button
          onClick={() => {
            navigate('../basic-details')
          }}
        >
          Previous
        </Button>
        <span className="flex gap-3">
          {' '}
          <Button
            data-testid="cancel-button"
            onClick={() => navigate('/contacts/customer-management')}
          >
            Cancel
          </Button>
          {!id ? (
            <Button
              color="success"
              loading={loading}
              onClick={() => {
                handleAddress()
              }}
            >
              Add
            </Button>
          ) : (
            <Button
              color="success"
              loading={loading}
              onClick={() => {
                handleAddress()
              }}
            >
              Save
            </Button>
          )}
        </span>
      </div>
    </div>
  )
}

export default AddressDetails
