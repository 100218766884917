import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { t } from 'i18next'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDeleteManufactureOrderMutation } from '../manufacture-orders-endpoints/manufacture_order.endpoints'

interface Props {
  closePopUp: any
  data: any
}

const DeleteMoPopup = ({ closePopUp, data }: Props) => {
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)

  const [deleteMo, { isLoading: deleteEntryLoading }] = useDeleteManufactureOrderMutation()

  const handleDelete = () => {
    deleteMo({ mo_id: data?._id, plant_id: plant.id })
      .unwrap()
      .then(() => {
        closePopUp()
        toast.success(`Manufacturing Order ${data?.manufactureOrderID} deleted successfully`)
        navigate('/planning/mo')
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <>
      <p className="p-xs-regular">
        You are about to delete a{' '}
        <span className="break-words p-xs-bold">
          {'Manufacturing Order'} {data?.manufactureOrderID}{' '}
        </span>
        Are you sure you want to proceed?
      </p>

      <div className=" mt-1 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDelete} loading={deleteEntryLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeleteMoPopup
