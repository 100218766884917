import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { useState } from 'react'

interface SelectProps {
  options: { label: string; value: any }[]
  value: { label: string; value: any }
  onChange: (option: { label: string; value: any }) => void
}
const Select = ({ options, value, onChange }: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: any) => {
    onChange(option)
    setIsOpen(false)
  }

  return (
    <div className="relative inline-block">
      <button
        type="button"
        className="cursor-pointer rounded-md border-0 px-4  py-2.5 text-sm text-gray-500 focus:border-gray-200 focus:outline-none focus:ring-0"
        onClick={toggleDropdown}
      >
        {value ? value?.label : 'Select'}
        <KeyboardArrowDownRounded className="ml-1" />
      </button>
      {isOpen && (
        <ul className="w-200 absolute z-10 mt-1 max-h-60 overflow-y-auto rounded-md border bg-white shadow-lg">
          {options.map((option, index) => (
            <li
              key={index}
              className="cursor-pointer px-4 py-2 hover:bg-gray-100"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Select
