
function Products({ color }: { color: any }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_1355_12551"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_1355_12551)">
        <path
          fill={color ? `${color}` : "#041220"}
          d="M6.188 7.896l3.104-5.063a.765.765 0 01.312-.302.874.874 0 01.792 0c.125.063.229.163.312.302l3.104 5.063a.838.838 0 01.126.437.843.843 0 01-.105.417.81.81 0 01-.729.417H6.896a.81.81 0 01-.73-.417.843.843 0 01-.104-.417c0-.152.042-.298.125-.437zm8.395 10.437c-1.041 0-1.927-.364-2.656-1.093-.73-.73-1.094-1.615-1.094-2.657 0-1.041.365-1.927 1.094-2.656.73-.73 1.615-1.094 2.656-1.094 1.042 0 1.927.365 2.657 1.094.729.73 1.093 1.615 1.093 2.656 0 1.042-.364 1.927-1.093 2.657-.73.729-1.615 1.093-2.657 1.093zM2.5 17.083v-5c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h5c.236 0 .434.08.594.24.16.16.24.357.24.593v5c0 .236-.08.434-.24.594a.806.806 0 01-.594.24h-5a.806.806 0 01-.593-.24.806.806 0 01-.24-.594zm12.083-.416c.584 0 1.077-.202 1.48-.605.402-.402.604-.895.604-1.479 0-.583-.202-1.076-.605-1.479a2.012 2.012 0 00-1.479-.604c-.583 0-1.076.201-1.479.604a2.011 2.011 0 00-.604 1.48c0 .583.201 1.076.604 1.479.403.402.896.604 1.48.604zM4.167 16.25H7.5v-3.333H4.167v3.333zM8.375 7.5h3.25L10 4.875 8.375 7.5z"
        ></path>
      </g>
    </svg>
  );
}

export default Products;
