import React from "react";
interface TickIconProps {
  width?: number;
  height?: number;
  color?: string;
  classProp?: string;
}
const TickIcon: React.FC<TickIconProps> = ({
  width = 12,
  height = 12,
  color = "#45494D",
  classProp = "",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      className={classProp}
    >
      <g clipPath="url(#clip0_2353_19422)">
        <path
          d="M6.00008 1.66675C7.14935 1.66675 8.25155 2.12329 9.06421 2.93595C9.87687 3.74861 10.3334 4.85081 10.3334 6.00008C10.3334 7.14935 9.87687 8.25155 9.06421 9.06421C8.25155 9.87687 7.14935 10.3334 6.00008 10.3334C4.85081 10.3334 3.74861 9.87687 2.93595 9.06421C2.12329 8.25155 1.66675 7.14935 1.66675 6.00008C1.66675 4.85081 2.12329 3.74861 2.93595 2.93595C3.74861 2.12329 4.85081 1.66675 6.00008 1.66675ZM6.00008 11.3334C7.41457 11.3334 8.77112 10.7715 9.77132 9.77132C10.7715 8.77112 11.3334 7.41457 11.3334 6.00008C11.3334 4.58559 10.7715 3.22904 9.77132 2.22885C8.77112 1.22865 7.41457 0.666748 6.00008 0.666748C4.58559 0.666748 3.22904 1.22865 2.22885 2.22885C1.22865 3.22904 0.666748 4.58559 0.666748 6.00008C0.666748 7.41457 1.22865 8.77112 2.22885 9.77132C3.22904 10.7715 4.58559 11.3334 6.00008 11.3334ZM8.35425 5.02091C8.55008 4.82508 8.55008 4.50841 8.35425 4.31466C8.15841 4.12091 7.84175 4.11883 7.648 4.31466L5.3355 6.62716L4.35633 5.648C4.1605 5.45216 3.84383 5.45216 3.65008 5.648C3.45633 5.84383 3.45425 6.1605 3.65008 6.35425L4.98341 7.68758C5.17925 7.88341 5.49591 7.88341 5.68966 7.68758L8.35425 5.02091Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2353_19422">
          <rect
            width="10.6667"
            height="10.6667"
            fill="white"
            transform="translate(0.666748 0.666748)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TickIcon;
