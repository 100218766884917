import React from 'react'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useVerifyTimesheetByIdMutation } from '../timesheet-endpoints/timesheet.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'

// Define prop types with TypeScript
interface BatchApproveProps {
  closePopUp: any
  data: string[]
  setBatchData: (data: string[]) => void
}

const ApproveByPopUp: React.FC<BatchApproveProps> = ({ closePopUp, data, setBatchData }) => {
  // Use Redux selector to get plant data
  const plant = useSelector(selectSelectedItem)
  const [approve, { isLoading: isApprove }] = useVerifyTimesheetByIdMutation()

  // Handle the approval process
  const handleApprove = () => {
    if (data.length > 0) {
      approve({ plant_id: plant?._id, data: { data } })
        .unwrap()
        .then(() => {
          // Success message and reset state
          toast.success(`${data.length} entries approved`)
          closePopUp()
          setBatchData([])
        })
        .catch((err) => {
          // Error handling
          toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
        })
    }
  }

  return (
    <div>
      <p className="p-xs-regular">
        Are you sure you want to approve the selected {data.length} entries? Once approved, these entries{' '}
        <span className="break-words p-xs-bold">cannot be modified.</span>
        However, you can create new entries for making the adjustments.
      </p>

      <div className="mt-4 flex justify-end">
        <Button className="mr-2 rounded bg-red-500 px-4 py-2 text-xs text-closebtn btn-cancel" onClick={closePopUp}>
          Close
        </Button>
        <Button color="primary" size="sm" onClick={handleApprove} loading={isApprove}>
          Approve
        </Button>
      </div>
    </div>
  )
}

export default ApproveByPopUp
