import React from 'react'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'
import { useNavigate, useParams } from 'react-router-dom'

import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { selectSelectedItem } from 'store/redux/navSlice'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { formatString } from 'utils/stringHandlers'
import { hasAccess } from 'utils/utils'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { PointOfContact } from 'pages/contacts/commonTypes'

import { useGetCustomerByIDQuery } from '../customer-endpoints/customer.endpoints'
import { CustomerResponse } from '../customer-endpoints/customerTypes'

const columns: ExtendedColumn<PointOfContact>[] = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    Cell: ({ row }: CellProps<PointOfContact>) => (
      <>{row.original?.firstName ?? '-'}</>
    ),
  },

  {
    Header: 'Last Name',
    accessor: 'lastName',
    Cell: ({ row }: CellProps<PointOfContact>) => (
      <>{row.original?.lastName ?? '-'}</>
    ),
  },
  {
    Header: 'Contact Number',
    accessor: 'contactNumber',
    Cell: ({ row }: CellProps<PointOfContact>) => (
      <>{row.original?.contactNumber ?? '-'}</>
    ),
  },
  {
    Header: 'Email PointOfContact',
    accessor: 'emailAddress',
    Cell: ({ row }: CellProps<PointOfContact>) => (
      <>{row.original?.emailAddress ?? '-'}</>
    ),
  },
  {
    Header: 'Remarks',
    accessor: 'remarks',
    Cell: ({ row }: CellProps<PointOfContact>) => (
      <>{row.original?.remarks ?? '-'}</>
    ),
  },
]

const customerInfo: {
  label: string
  key: keyof CustomerResponse
}[] = [
  { label: 'Customer Name', key: 'name' },
  { label: 'Customer Type', key: 'customerType' },
  { label: 'Business Email Address', key: 'businessEmailAddress' },
  { label: 'Primary Contact Number', key: 'primaryContactNumber' },
  { label: 'Secondary Contact Number', key: 'secondaryContactNumber' },
]

const ViewBasicDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const customerAccess = hasAccess('con', { cus: 'e' })

  const { data: customer, isLoading: loading } = useGetCustomerByIDQuery(
    { plant_id: plant?.id, id: id ?? '-' },
    {
      skip: !plant?.id || !id,
    },
  )

  return (
    <MotionDiv>
      {loading ? (
        <FullPageLoader />
      ) : (
        <>
          <WhiteCard className="flex min-h-[70vh] flex-col gap-6">
            <div className="flex w-full justify-between">
              <h5 className="h5">Basic Details</h5>
              {customerAccess && (
                <div>
                  <Button
                    color="primary"
                    startIcon={<PlusIcon />}
                    onClick={() => {
                      id &&
                        navigate(
                          `/contacts/customer-management/edit/${id}/basic-details`,
                        )
                    }}
                  >
                    Edit Customer Details
                  </Button>
                </div>
              )}
            </div>
            <div className="flex max-w-[800px] justify-between">
              <div className="grid grid-cols-2 gap-4 text-xs">
                {customerInfo.map((field, fieldIndex) => (
                  <React.Fragment key={fieldIndex}>
                    <span className="text-gray-500">{field.label}</span>
                    <span>
                      {field.key === 'customerType'
                        ? formatString(
                            (customer?.[
                              field.key as keyof typeof customer
                            ] as string) || '-',
                          )
                        : (customer?.[
                            field.key as keyof typeof customer
                          ] as string) || '-'}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <PaginatedTable
              columns={columns}
              rows={customer?.pointOfContacts}
              hidePagination={true}
              emptyMessage={
                'No contacts have been added. Please add a contact.'
              }
            />
          </WhiteCard>
        </>
      )}
    </MotionDiv>
  )
}

export default ViewBasicDetails
