function Warehouse({ color }: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask
        id="mask0_1311_38830"
        style={{ maskType: 'alpha' }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_1311_38830)">
        <path
          fill={color ? `${color}` : '#041220'}
          d="M10 4.292L3.333 6.958v8.875H5v-5c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49h6.666c.459 0 .851.164 1.177.49.327.327.49.719.49 1.177v5h1.667V6.958L10 4.292zM1.667 15.833V6.958c0-.347.093-.66.28-.937.188-.278.442-.48.761-.604L9.375 2.75A1.57 1.57 0 0110 2.625c.222 0 .43.042.625.125l6.667 2.667c.32.125.573.326.76.604.188.278.281.59.281.937v8.875c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.176.49h-3.334v-6.667H6.667V17.5H3.333c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.177zM7.5 17.5v-1.667h1.667V17.5H7.5zM9.167 15v-1.667h1.666V15H9.167zm1.666 2.5v-1.667H12.5V17.5h-1.667z"
        ></path>
      </g>
    </svg>
  )
}

export default Warehouse
