function ButtonThree({ className, onClick }: { className: any; onClick: any }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_806_16522"
        style={{ maskType: 'alpha' }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_806_16522)">
        <path
          fill="#fff"
          d="M5 11.667c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 013.333 10c0-.458.163-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.326.326.49.719.49 1.177 0 .458-.164.85-.49 1.177-.326.327-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 018.333 10c0-.458.163-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.326.326.49.719.49 1.177 0 .458-.164.85-.49 1.177-.326.327-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.177c0-.458.163-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.326.326.49.719.49 1.177 0 .458-.164.85-.49 1.177-.326.327-.719.49-1.177.49z"
        ></path>
      </g>
    </svg>
  )
}

export default ButtonThree
