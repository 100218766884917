import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  updatedBetween: number[]
  createdBetween: number[]
}

interface FormValues {
  updatedBetween: number[]
  createdBetween: number[]
}

const FilterOperationalProcess: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const initialValues: FormValues = {
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      updatedBetween: [],
      createdBetween: [],
      sortBy: 'updated_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
      }))
    },
  })

  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Created between"
          formik={filterFormik}
          name="createdBetween"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Updated between"
          formik={filterFormik}
          name="updatedBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterOperationalProcess
