import { useEffect, useRef, useState, useMemo } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Keyboard from 'assets/icons/shift-management/Keyboard'
import Clock from 'assets/icons/shift-management/Clock'
import Button from 'libs/button/Button'
import { useParams } from 'react-router-dom'

interface TimePickerProps {
  name: string
  formik: any
}

const TimePicker = ({ name, formik }: TimePickerProps) => {
  const popupRef = useRef<HTMLDivElement>(null)
  const [hour, setHour] = useState<number | null>(12)
  const [minute, setMinute] = useState<number | null>(0)
  const [period, setPeriod] = useState('AM')
  const [isManualEntry, setIsManualEntry] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { id } = useParams()

  // Helper function to format time
  const formatTime = useMemo(
    () => (formikValues: any) => {
      const date = new Date(formikValues * 1000) // Convert seconds to milliseconds
      let hours = date.getUTCHours() // Use getUTCHours to avoid local time issues
      const mins = date.getUTCMinutes() // Use getUTCMinutes
      const ampm = hours >= 12 ? 'PM' : 'AM'

      if (hours > 12) hours -= 12
      if (hours === 0) hours = 12

      if (hours == null || mins == null || Number.isNaN(mins) || Number.isNaN(mins)) return ''
      return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')} ${ampm}`
    },
    [],
  )

  const updateLocalTime = () => {
    if (formik.values[name]) {
      const timestamp = formik.values[name]
      const date = new Date(timestamp * 1000) // Convert seconds to milliseconds

      let hours = date.getUTCHours()
      const mins = date.getUTCMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'

      if (hours > 12) hours -= 12
      if (hours === 0) hours = 12

      setHour(hours)
      setMinute(mins)
      setPeriod(ampm)
    } else {
      setHour(12)
      setMinute(0)
      setPeriod('AM')
    }
  }

  // Initialize hour, minute, and period based on formik values
  useEffect(() => {
    updateLocalTime()
  }, [formik.values[name], id])

  const handleHourChange = (newHour: string | number) => {
    const numericHour = Number(newHour)
    if (numericHour >= 1 && numericHour <= 12) {
      setHour(numericHour)
    } else {
      setHour(null)
    }
  }

  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMinute = Number(e.target.value)
    if (newMinute >= 0 && newMinute < 60) {
      setMinute(newMinute)
    }
  }

  const togglePeriod = () => setPeriod(period === 'AM' ? 'PM' : 'AM')

  const switchView = (isManual: boolean) => {
    setIsManualEntry(isManual)
  }

  const handleSave = () => {
    // Convert hour, minute, and period to seconds
    if (hour === null || minute === null) return

    let convertedHour = hour
    if (period === 'PM' && convertedHour < 12) {
      convertedHour += 12
    } else if (period === 'AM' && convertedHour === 12) {
      convertedHour = 0
    }

    // Calculate total seconds from the time
    const totalSeconds = convertedHour * 3600 + minute * 60

    // Update formik with the selected time in seconds
    formik.setFieldValue(name, totalSeconds)

    // Close popup
    setIsOpen(false)
  }

  const togglePopup = () => {
    updateLocalTime()
    setIsOpen((prev) => !prev)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && hour && hour.toString().length === 1) {
      setHour(null) // Clear the hour if it's a single digit and backspace is pressed
    }
  }



  return (
    <div className="relative w-full">
      <input
        className="flex w-full cursor-pointer items-center justify-between rounded-md border border-gray-300 bg-gray-50 p-2 outline-none p-xs-regular hover:border-blue-500 focus:border-blue-500"
        onClick={togglePopup}
        value={formik.values[name] ? formatTime(formik.values[name]) : ''} // Show empty string if no value is selected
        readOnly
        placeholder="00:00 AM" // Placeholder to be shown when no value is selected
      />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="popup-overlay fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="relative z-30 w-full max-w-md rounded-lg bg-white p-6 shadow-lg"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              ref={popupRef}
            >
              <h2 className="mb-4 text-sm">{isManualEntry ? 'Enter Time' : 'Select time'}</h2>

              <div className="mb-4 flex items-center justify-center">
                <div className="ml-6 flex">
                  <input
                    type="number"
                    value={hour !== null ? hour.toString().padStart(2, '0') : ''}
                    onChange={(e) => handleHourChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="h-20 w-20 rounded-lg bg-primary text-center text-4xl text-white outline-none"
                    min="1"
                    max="12"
                  />
                  <div className="mx-1 my-4 text-4xl">:</div>
                  <input
                    type="number"
                    value={minute !== null ? minute.toString().padStart(2, '0') : ''}
                    onChange={handleMinuteChange}
                    className="h-20 w-20 rounded-lg bg-gray-350 text-center text-4xl text-black outline-none"
                    min="0"
                    max="59"
                  />
                </div>

                <div className="ml-4 flex flex-col">
                  <button
                    type="button"
                    onClick={togglePeriod}
                    className={`rounded-t-lg p-1.5 text-lg font-semibold ${period === 'AM' ? 'bg-primary text-white' : 'bg-gray-350 text-black'}`}
                  >
                    AM
                  </button>
                  <button
                    type="button"
                    onClick={togglePeriod}
                    className={`rounded-b-lg p-1.5 text-lg font-semibold ${period === 'PM' ? 'bg-primary text-white' : 'bg-gray-350 text-black'}`}
                  >
                    PM
                  </button>
                </div>
              </div>

              <AnimatePresence>
                {!isManualEntry && (
                  <motion.div
                    className="relative mx-auto flex h-56 w-56 items-center justify-center rounded-full bg-blue-50"
                    initial={{ opacity: 1, height: 0 }}
                    animate={{ opacity: 1, height: '14rem' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div
                      className="absolute"
                      style={{
                        transform: `rotate(${(hour || 0 % 12) * 30}deg)`,
                        height: '30%',
                        width: '2px',
                        alignItems: 'center',
                        backgroundColor: '#1193F0',
                        transformOrigin: 'bottom center',
                        top: '20%',
                      }}
                    />
                    {[...Array(12).keys()].map((i) => {
                      const hourNumber = i + 1
                      const isSelected = hourNumber === hour
                      return (
                        <div
                          key={i}
                          className={`absolute flex items-center justify-center ${
                            isSelected ? 'text-white' : 'text-black'
                          } cursor-pointer`}
                          style={{
                            transform: `rotate(${i * 30 + 30}deg) translate(0, -5.5rem) rotate(-${i * 30 + 30}deg)`,
                            width: '3rem',
                            height: '3rem',
                            borderRadius: '50%',
                            backgroundColor: isSelected ? '#1193F0' : 'transparent',
                          }}
                          onClick={() => handleHourChange(hourNumber.toString())}
                        >
                          {hourNumber}
                        </div>
                      )
                    })}
                  </motion.div>
                )}
              </AnimatePresence>

              <div className="flex items-center justify-between">
                <div className="mt-4 flex justify-end">
                  {isManualEntry ? (
                    <span onClick={() => switchView(false)}>
                      <Clock />
                    </span>
                  ) : (
                    <span onClick={() => switchView(true)}>
                      <Keyboard />
                    </span>
                  )}
                </div>
                <div className="mt-4 flex justify-end gap-2">
                  <Button size="sm" onClick={togglePopup} type="button">
                    Cancel
                  </Button>
                  <Button size="sm" type="button" color="primary" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default TimePicker
