export const smallMotionProps = {
  initial: { x: '360px' },
  animate: { x: 0 },
  exit: { x: '360px' },
  style: { width: '360px' },
  transition: {
    type: 'spring',
    duration: 0.5,
    stiffness: 200,
    damping: 25,
  },
}

export const mediumMotionProps = {
  initial: { x: '410px' },
  animate: { x: 0 },
  exit: { x: '400px' },
  style: { width: '400px' },
  transition: {
    type: 'spring',
    duration: 0.5,
    stiffness: 200,
    damping: 25,
  },
}

export const largeMotionProps = {
  initial: { x: '550px' },
  animate: { x: 0 },
  exit: { x: '550px' },
  style: { width: '550px' },
  transition: {
    type: 'spring',
    duration: 0.5,
    stiffness: 200,
    damping: 25,
  },
}

export const veryLargeMotionProps = {
  initial: { x: '800px' },
  animate: { x: 0 },
  exit: { x: '800px' },
  style: { width: '800px' },
  transition: {
    type: 'spring',
    duration: 0.5,
    stiffness: 200,
    damping: 25,
  },
}

export const scaleMotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  style: { zIndex: 10 },
  transition: {
    duration: 0.3,
    ease: 'easeInOut',
  },
}

export const scaleMotionProps2 = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export const dropdownMotionProps = {
  closed: {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  open: {
    height: 'auto',
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
}
