import { useFormik } from 'formik'
import WhiteCard from 'libs/card/WhiteCard'
import EditableTable from 'libs/table/EditableTable'
import Field from 'libs/field'
import useTabTitle from 'libs/hooks/useTabTitle'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import MotionDiv from 'libs/motionDiv'
import { toast } from 'libs/toast'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  useGetWorkOrdersQuery,
  usePostWorkOrderMutation,
  usePutWorkOrderMutation,
} from '../work-orders-endpoints/workorders.endpoints'
import * as Yup from 'yup'
import { Filters } from '../../manufacture-orders/manufacture-orders-endpoints/manufactureordersTypes'
import WorkOrderPopup from 'pages/manufacture-orders/saved-changes-popup/WorkOrderPopup'
import Popup from 'libs/popup/Popup'
import { useGetAllProcessesQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { useGetAllManufactureOrdersQuery } from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'
import { useGetUsersByPlantQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

const AddEditWorkOrder = () => {
  const { id: paramsId } = useParams()
  useTabTitle(paramsId ? 'Edit Work Order' : 'Add Work Order')
  const navigate = useNavigate()
  const [savedChangesPopUp, setSavedChangesPopUp] = useState({
    isPopUpOpen: false,
  })

  const location = useLocation()
  // Access the state object
  const { from } = location.state || {}
  const plant = useSelector(selectSelectedItem)
  const {
    data: WorkOrder,
    isLoading: isWorkOrderLoading,
    isFetching: isWorkOrderFetching,
  } = useGetWorkOrdersQuery(
    {
      plantId: plant?.id,
      filters: { woID: paramsId, page_no: 1, page_size: 10 },
    },
    {
      skip: !plant?.id || !paramsId,
    },
  )

  const [addWorkOrder, { isLoading: addWorkOrderLoading }] =
    usePostWorkOrderMutation()
  const [updateWorkOrder, { isLoading: updateWorkOrderLoading }] =
    usePutWorkOrderMutation()
  const { data: allProcessData, isLoading: processLoading } =
    useGetAllProcessesQuery(
      {
        plantId: plant.id,
        filters: {
          page_no: 1,
          page_size: 1000,
          searchText: '',
        },
      },
      { skip: !plant.id },
    )

  const { data: plantUsers, isLoading: plantUsersLoading } =
    useGetUsersByPlantQuery(
      { plantId: plant.id },
      {
        skip: !plant.id,
      },
    )

  // mo order get api

  const Filters: Filters = {
    page_no: 1,
    page_size: 1000,
    searchText: '',
    createdBetween: [],
    updatedBetween: [],
    endBetween: [],
    startBetween: [],
    finished_goods: [],
  }
  const { data: moOrders } = useGetAllManufactureOrdersQuery(
    { plantId: plant?.id, filters: Filters },
    {
      skip: !plant?.id,
    },
  )

  const moOptions = moOrders?.data?.map((item: any) => ({
    label: item.manufactureOrderID,
    value: item,
  }))

  // work order formik
  const workOrderFormik = useFormik({
    initialValues: {
      workOrderID: '',
      status: '',
      startDate: undefined,
      endDate: undefined,
      manufactureOrderID: undefined,
    },
    validationSchema: Yup.object().shape({
      workOrderID: Yup.string().required('Work order ID is required'),
      status: Yup.string().required('Status is required'),
      startDate: Yup.string().required('Start date is required'),
      endDate: Yup.string().required('End date is required'),
    }),

    onSubmit: (values: any) => {
      if (
        values.startDate &&
        values.endDate &&
        !(values.endDate >= values.startDate)
      ) {
        toast.error('End Date should be after Start Date')
        return
      }

      if (
        values.workOrderID &&
        values.status &&
        operationsFormik?.values?.operations?.length > 0
      ) {
        const payload = {
          workOrderID: values.workOrderID,
          status: values.status,
          startDate: values.startDate ? values.startDate : undefined,
          endDate: values.endDate ? values.endDate : undefined,
          manufactureOrderID: values.manufactureOrderID?._id
            ? values.manufactureOrderID?._id
            : undefined,
          assignedTo:
            assignedOperatorsFormik?.values?.operators
              ?.map((operator: any) => operator?.operator)
              ?.filter(Boolean) || [],
          operations:
            operationsFormik?.values?.operations
              ?.map((operation: any) => {
                const products =
                  operation?.products?.map((product: any) => {
                    if (product?.targetQuantity <= 0) {
                      return toast.error(
                        `Target quantity for product ${product?.product_name} should be greater than 0`,
                      )
                    }
                    return {
                      product_id: product?._id,
                      targetQuantity: product?.targetQuantity,
                      completedQuantity: product?.completedQuantity,
                    }
                  }) || []

                // Only include the operation if the products array is not empty
                if (products.length > 0) {
                  return {
                    process_id: operation?.process_id?._id,
                    products: products,
                  }
                }
                return null
              })
              ?.filter((operation: any) => operation !== null) || [],
        }

        if (!paramsId) {
          addWorkOrder({ plantId: plant.id, data: payload })
            .unwrap()
            .then(() => {
              toast.success(
                'Work order - ' + payload.workOrderID + ' created successfully',
              )
              if (from) {
                setSavedChangesPopUp({ isPopUpOpen: true })
              } else {
                navigate(-1)
              }
            })
            .catch((error) => {
              toast.error(
                error?.data?.detail ??
                  'Something went wrong while creating the work order',
              )
            })
        } else if (WorkOrder && WorkOrder?.data?.length > 0) {
          const woId = WorkOrder?.data?.[0]?._id
          updateWorkOrder({ plantId: plant.id, data: payload, woID: woId })
            .unwrap()
            .then(() => {
              toast.success(
                'Work order - ' + payload.workOrderID + ' updated successfully',
              )
              if (from) {
                setSavedChangesPopUp({ isPopUpOpen: true })
              } else {
                navigate(-1)
              }
            })
            .catch((error) => {
              toast.error(
                error?.data?.detail ??
                  'Something went wrong while updating the work order',
              )
            })
        } else {
          toast.error('Something went wrong, Please try again.')
        }
      } else {
        toast.error('At least one operation should be present.')
      }
    },
  })

  let statusOptions = [
    { label: 'Not Started', value: 'NOT_STARTED' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ]

  // filter out canceeled status for add work order
  if (!paramsId) {
    statusOptions = statusOptions.filter(
      (option) => option.value != 'CANCELLED',
    )
  }

  // *************************** operations ****************************

  const operationsOptions = allProcessData?.data
    ?.filter((item: any) => item?.output_products?.length > 0)
    ?.map((item: any) => ({
      label: item.name,
      value: item,
    }))

  const newOperationRow = {
    process_id: null,
    products: [],
  }

  const operationsFormik = useFormik<any>({
    initialValues: {
      operations: [],
    },

    onSubmit: () => {},
  })

  const operationsColumns = [
    {
      title: 'Operation',
      flex: 2,
      content: {
        main: true,
        uniquePropertyInOption: 'name',
        options: operationsOptions,
        name: 'process_id',
        placeholder: 'Select Operation',
        type: 'select',
      },
    },
    {
      title: 'Output Products',
      content: {
        placeholder: 'Product Name',
        type: 'text',
        parentName: 'products',
        name: 'product_name',
        readOnly: true,
        options: [
          { value: 'product1', label: 'product1' },
          { value: 'product2', label: 'product2' },
        ],
      },
    },
    {
      title: 'Quantity',
      align: 'right',
      hideRightBorder: true,
      content: {
        placeholder: '0',
        type: 'number',
        parentName: 'products',
        name: 'targetQuantity',
      },
    },
    {
      title: 'UOM',
      flex: 1,
      content: {
        placeholder: 'Unit',
        type: 'text',
        parentName: 'products',
        name: 'unit_of_measure',
        readOnly: true,
      },
    },
  ]

  // *************************** assigned operators ****************************
  const operatorsOptions = plantUsers?.map((user: any) => ({
    label: user?.first_name + ' ' + user?.last_name,
    value: user?._id,
  }))

  const newOperator = {
    operator: null,
  }

  const assignedOperatorsFormik = useFormik<any>({
    initialValues: { operators: [] },
    onSubmit: () => {},
  })

  const operatorsColumns = [
    {
      title: 'Assignee Name',
      flex: 1,
      content: {
        main: true,
        uniquePropertyInOption: 'name',
        options: operatorsOptions,
        name: 'operator',
        placeholder: 'Select Operator',
        type: 'select',
      },
    },
  ]

  // *************************** resetting form with work order data ****************************
  useEffect(() => {
    if (WorkOrder) {
      const totalItemsCount = WorkOrder?.paging?.total_items_count
      const dataLength = WorkOrder?.data?.length
      if (totalItemsCount <= 0 || dataLength === 0) {
        toast.error('Something went wrong, Please try again.')
        return
      }
      const workOrderData = WorkOrder?.data?.[0]
      if (workOrderData) {
        workOrderFormik.resetForm({
          values: {
            workOrderID: workOrderData.workOrderID,
            status: workOrderData.status,
            startDate: workOrderData.startDate,
            endDate: workOrderData.endDate,
            manufactureOrderID: workOrderData.manufactureOrder,
          },
        })
        operationsFormik.resetForm({
          values: {
            operations: workOrderData.operations.map((operation: any) => ({
              process_id: {
                _id: operation?._id,
                name: operation?.name,
                output_products: operation?.products?.map((product: any) => ({
                  product: {
                    _id: product?._id,
                    name: product?.name,
                    unit_of_measure: product?.unit_of_measure,
                    targetQuantity: product?.targetQuantity,
                    completedQuantity: product?.completedQuantity,
                  },
                })),
              },
              products:
                operation?.products?.map((product: any) => ({
                  _id: product?._id,
                  targetQuantity: product?.targetQuantity,
                  product_name: product?.name,
                  unit_of_measure: product?.unit_of_measure,
                  completedQuantity: product?.completedQuantity,
                })) || [],
            })),
          },
        })
        assignedOperatorsFormik.resetForm({
          values: {
            operators: workOrderData.assignedTo.map((operator: any) => ({
              operator: operator?._id,
            })),
          },
        })
      }
    }
  }, [WorkOrder])

  const defaultMo = {
    label:
      workOrderFormik?.values?.manufactureOrderID?.manufactureOrderID ?? '',
  }

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black ">
          {paramsId ? 'Edit Work Order' : 'Add Work Order'}
        </h1>
        {!isWorkOrderLoading &&
        !isWorkOrderFetching &&
        !processLoading &&
        !plantUsersLoading ? (
          <WhiteCard className="mt-3 flex flex-col gap-6 ">
            <span>
              <h5 className="h5">Work Order Details</h5>
              <p className="text-sm text-gray-500">
                Edit the existing Work Order details to make necessary changes
                and updates.
              </p>
            </span>
            <div className="-mt-2 grid grid-cols-2 gap-x-8 gap-y-2 lg:w-5/6">
              <Field
                label="Work Order ID"
                type="text"
                placeholder="Type Work Order ID"
                name="workOrderID"
                formik={workOrderFormik}
                required
              />
              <Field
                label="Status"
                type="select"
                placeholder="Select WO Status"
                name="status"
                formik={workOrderFormik}
                options={statusOptions}
                defaultValue={statusOptions?.find(
                  (option) => option.value === workOrderFormik?.values?.status,
                )}
                required
              />
              <Field
                label="Start Date"
                type="date"
                placeholder="Type Work Order ID"
                name="startDate"
                formik={workOrderFormik}
                required
              />
              <Field
                label="End Date"
                type="date"
                placeholder="select"
                name="endDate"
                formik={workOrderFormik}
                required
              />
              <Field
                label="Manufacturing Order ID"
                type="select"
                placeholder="Type Work Order ID"
                name="manufactureOrderID"
                options={moOptions}
                defaultValue={defaultMo}
                formik={workOrderFormik}
              />
            </div>
            <hr />
            {/* *************************** operations **************************** */}
            <EditableTable
              formik={operationsFormik}
              heading="Operations"
              onSelect={(process: any, index: number) => {
                operationsFormik.setFieldValue(
                  `operations.${index}.products`,
                  [],
                ),
                  process.output_products.map((p: any, i: number) => [
                    operationsFormik.setFieldValue(
                      `operations.${index}.products.${i}.product_name`,
                      p.product.name,
                    ),
                    operationsFormik.setFieldValue(
                      `operations.${index}.products.${i}._id`,
                      p.product._id,
                    ),
                    operationsFormik.setFieldValue(
                      `operations.${index}.products.${i}.unit_of_measure`,
                      p.product.unit_of_measure,
                    ),
                    operationsFormik.setFieldValue(
                      `operations.${index}.products.${i}.targetQuantity`,
                      null,
                    ),
                  ])
              }}
              loading={processLoading}
              columns={operationsColumns}
              emptyMessage="+ Click on the Add Operation button to add a new operation"
              newRow={newOperationRow}
              name="operations"
              addButtonTitle="Add Operation"
            />

            {/* *************************** assigned operators **************************** */}
            <EditableTable
              formik={assignedOperatorsFormik}
              heading="Assigned Operators"
              loading={plantUsersLoading}
              columns={operatorsColumns}
              emptyMessage="+ Click on the Add Operator button to add a new operator"
              newRow={newOperator}
              name="operators"
              addButtonTitle="Add Operator"
            />

            <Popup
              isOpen={savedChangesPopUp.isPopUpOpen}
              title="Saved Changes"
              onClose={() => {
                setSavedChangesPopUp({ isPopUpOpen: false })
              }}
            >
              <WorkOrderPopup />
            </Popup>

            <div className="flex w-full justify-end gap-4 px-2">
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button
                type="submit"
                color="success"
                onClick={() => workOrderFormik.handleSubmit()}
                loading={addWorkOrderLoading || updateWorkOrderLoading}
              >
                Save
              </Button>
            </div>
          </WhiteCard>
        ) : (
          <FullPageLoader />
        )}
      </>
    </MotionDiv>
  )
}

export default AddEditWorkOrder
