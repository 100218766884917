import { useState, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Modal } from './modal'
import Cookies from 'js-cookie'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { authErrors } from '../../../../mocks/ErrorMessages'
import { useLoginUserMutation } from './auth.endpoints'
import { useValidatePhoneMutation } from './auth.endpoints'
import { AlertIcon, HidePasswordIcon, ViewPasswordIcon } from 'assets/icons'
import { MiniSpinLoader, SpinLoader } from 'libs/loader'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'
import { toast } from 'libs/toast'
import { useDispatch } from 'react-redux'
import { setToken, setUser } from 'store/redux/userSlice'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useLazyUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface UserInfo {
  id: string
  role: 'admin' | 'supervisor' | 'operator'
  email: string
  first_name: string
  last_name: string
  phone_number: string
  username: string
  modules: any
  organization: {
    id: string
    name: string
  }
  plants: {
    id: string
    name: string
  }[]
}

interface LoginResponse {
  is_temperory: boolean
  access_token: string
  refresh_token: string
  start_time: number
  end_time: number
  expires_in: number
  user_info: UserInfo
  session?: any
}

type SuccessData = {
  data: any
  error?: undefined
}

type ErrorData = {
  data?: undefined
  error: FetchBaseQueryError | SerializedError
}

type Data = SuccessData | ErrorData

const LoginPage = () => {
  useTabTitle('Login')
  const navigate = useNavigate()
  const [validatePhone] = useValidatePhoneMutation()
  const location = useLocation()
  const dispatch = useDispatch()
  const locationState = location.state
  const [loginUser] = useLoginUserMutation()
  const [getUser] = useLazyUserInfoQuery()
  const [openTab, setOpenTab] = useState<number>(2)
  const showTab = true
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [isContactValid, setIsContactValid] = useState<boolean>(false)
  const [showEmailErr, setShowEmailErr] = useState<boolean>(false)
  const [showPasswordErr, setShowPasswordErr] = useState<boolean>(false)
  const [showContactErr, setShowContactErr] = useState<boolean>(false)
  const [phone, setPhone] = useState<string>('')
  const [currCountry, setCurrCountry] = useState<any>(null)
  const [focusedInput, setFocusedInput] = useState<string | null>(null)
  const [isLoginClick, setIsLoginClick] = useState<boolean>(false)
  const isLoggedOut = false
  const [emailTimeout, setEmailTimeout] = useState<any>(null)
  const [passwordTimeout, setPasswordTimeout] = useState<any>(null)
  const [contactTimeout, setContactTimeout] = useState<any>(null)
  const [staySignedInChecked, setStaySignedInChecked] = useState<boolean>(false)

  const handleLoginClick = async (e: any, loggedWith: string) => {
    e.preventDefault()
    if (loggedWith === 'phone' && isContactValid && phone !== '' && phone?.length == 10) {
      //
    } else if (loggedWith === 'phone' && (phone === '' || !isContactValid || phone?.length < 10)) {
      setShowContactErr(true)
    } else if (loggedWith === 'email') {
      setIsLoginClick(true)
      loginUser({
        username: email,
        password: password,
      })
        .unwrap()
        .then(async (res: LoginResponse) => {
          if (res?.is_temperory) {
            navigate('/auth/temp-password', {
              state: { email: email, session: res?.session },
            })
            return
          }
          const cookieOptions = {
            expires: staySignedInChecked ? 30 : undefined,
          }

          const newRes = {
            data: res,
          }
          //adding cookieoptions inside the cookie to save it and handle refresh token
          const response = { ...newRes, ...cookieOptions }

          Cookies.set('access_token', response.data.access_token, {
            expires: Math.floor(res.start_time - res.end_time / (60 * 60 * 24)),
          })

          Cookies.set('refresh_token', response.data.refresh_token, {
            expires: staySignedInChecked ? 30 : undefined,
          })

          Cookies.set('role', response.data.user_info.role, {
            expires: staySignedInChecked ? 30 : undefined,
          })

          Cookies.set('username', response.data.user_info.username, {
            expires: staySignedInChecked ? 30 : undefined,
          })

          Cookies.set('expires_in', response.data.end_time - response.data.start_time, {
            expires: staySignedInChecked ? 30 : undefined,
          })

          Cookies.set('start_time', response.data.start_time, {
            expires: staySignedInChecked ? 30 : undefined,
          })
          Cookies.set('end_time', response.data.end_time, {
            expires: staySignedInChecked ? 30 : undefined,
          })
          Cookies.set('modules', JSON.stringify(response.data.user_info.modules), {
            expires: staySignedInChecked ? 30 : undefined,
          })
          await getUser({})
          dispatch(setUser(res.user_info))
          dispatch(
            setToken({
              access_token: res.access_token,
              refresh_token: res.refresh_token,
            }),
          )
          // if role is admin
          const role = Cookies.get('role')
          // if access rights are provided according to the role
          const modules = JSON.parse(Cookies.get('modules'))
          const firstModuleEntry = Object.entries(modules)[0]
          const accessRights = (modules: any) => {
            switch (modules) {
              case 'das':
                navigate('/dashboard')
                break
              case 'pro':
                navigate('/productionV2')
                break
              case 'inv':
                navigate('/inventory')
                break
              case 'rev':
                navigate('/review')
                break
              case 'ord':
                navigate('/orders')
                break
              case 'emp':
                navigate('/employee')
                break
              case 'con':
                navigate('/contacts')
                break
              case 'pla':
                navigate('/planning')
                break
              default:
                navigate('/unauthorized')
            }
          }
          if(role == 'admin'){
            navigate('/dashboard')
          }else{
            accessRights(firstModuleEntry[0])
          }
        })
        .catch((error) => {
          if (error?.data?.detail == 'Incorrect username or password') {
            setIsEmailValid(false)
            setIsPasswordValid(false)
            setIsLoginClick(false)
            setShowEmailErr(true)
            setShowPasswordErr(true)
          } else {
            toast.error('Something went wrong. Please try again later.')
          }
        })
        .finally(() => {
          setIsLoginClick(false)
        })
    } else {
      if (!isEmailValid || email === '') setShowEmailErr(true)
      if (!isPasswordValid || password === '') setShowPasswordErr(true)
    }
  }
  const handleCountryChange = async (country: any) => {
    setShowContactErr(false)
    if (contactTimeout) {
      clearTimeout(contactTimeout)
    }
    setCurrCountry(country)
    const ans: Data = await validatePhone({
      phone_number: phone,
      country_code: (country?.countryCode).toUpperCase(),
    })

    if (ans.data) setIsContactValid(true)
    else setIsContactValid(false)
    const timeoutContact = setTimeout(() => {
      if (!ans.data && phone) setShowContactErr(true)
      else setShowContactErr(false)
    }, 1200)
    setContactTimeout(timeoutContact)
    return () => {
      clearTimeout(timeoutContact)
    }
  }
  const handlePhoneChange = async (e: any) => {
    if (e[e.length - 1] == 'e') return
    setShowContactErr(false)
    if (contactTimeout) {
      clearTimeout(contactTimeout)
    }
    if (/^[0-9]+$/.test(e) || e.length == 0) {
      if (e.length == 10) setPhone(e)
      else if (e.length < 10) {
        setPhone(e)
        return
      } else return
    } else {
      return
    }

    const ans: Data = await validatePhone({
      phone_number: e,
      country_code: (currCountry?.countryCode).toUpperCase(),
    })
    if (ans.data) setIsContactValid(true)
    else setIsContactValid(false)
    const timeoutContact = setTimeout(() => {
      if (ans.data) setShowContactErr(false)
    }, 1200)
    setContactTimeout(timeoutContact)
    return () => {
      clearTimeout(timeoutContact)
    }
  }

  let emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
  const passwordRegex = new RegExp(
    '^(?=.*[0-9])' +
      '(?=.*[a-z])(?=.*[A-Z])' +
      '(?=.*[$*.^\\[\\]{}()\\-?"!@#%&/\\\\,><\':;|_~`+=])' +
      '(?=\\S+$).{8,20}$',
  )
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState)
  }
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value)
  }
  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (focusedInput === 'email') {
        document.getElementById('passwordInput')?.focus()
      } else if (focusedInput === 'password') {
        handleLoginClick(e, 'email')
      } else if (focusedInput === 'phone') {
        handleLoginClick(e, 'phone')
      }
    }
  }
  const handleFocus = (input: any) => {
    setFocusedInput(input)
  }

  useMemo(() => {
    setShowEmailErr(false)
    if (emailTimeout) {
      clearTimeout(emailTimeout)
    }

    if (email !== '' && !emailRegex.test(email)) {
      setIsEmailValid(false)
      const timeOutEmail = setTimeout(() => {
        // setShowEmailErr(true);
      }, 1200)
      setEmailTimeout(timeOutEmail)
      return () => {
        clearTimeout(timeOutEmail)
      }
    } else {
      const timeOutEmail = setTimeout(() => {
        setShowEmailErr(false)
      }, 1200)
      setIsEmailValid(true)
      setEmailTimeout(timeOutEmail)
      return () => {
        clearTimeout(timeOutEmail)
      }
    }
  }, [email, password])
  useMemo(() => {
    setShowPasswordErr(false)
    if (passwordTimeout) {
      clearTimeout(passwordTimeout)
    }
    if (password !== '' && !passwordRegex.test(password)) {
      setIsPasswordValid(false)
      const timeOutPassword = setTimeout(() => {
        // setShowPasswordErr(true);
      }, 1200)
      setPasswordTimeout(timeOutPassword)
      return () => {
        clearTimeout(passwordTimeout)
      }
    } else {
      setIsPasswordValid(true)
      const timeOutPassword = setTimeout(() => {
        setShowPasswordErr(false)
      }, 1200)
      setPasswordTimeout(timeOutPassword)
      return () => {
        clearTimeout(passwordTimeout)
      }
    }
  }, [password, email])
  const handleStaySignedInClick = () => {
    setStaySignedInChecked(!staySignedInChecked)
  }
  return isLoggedOut ? (
    <Modal heading="">
      <h2 className="mt-8 text-center font-inter text-2xl font-bold text-[#323333]">
        Signing you out
        {<span className={`text-[#0B5ED7]`}> {locationState?.loggedOutUserName}!</span>}
      </h2>
      <SpinLoader classProp="mt-20" />
      <p className="mb-24 pt-8 text-center font-inter text-lg font-medium text-[#323333]">
        Please wait while we log you out.
      </p>
    </Modal>
  ) : openTab === 2 ? (
    <Modal openTab={openTab} showTab={showTab} setOpenTab={setOpenTab} heading="Welcome to Catalyst!">
      <div className="mt-2">
        <h2 className="mb-4 text-2xl font-bold">Sign in</h2>
        {/* <p className="text-xs text-[#212121] font-inter mb-6">
          New to Catalyst?{" "}
          <span className="text-[#0B5ED7] text-xs font-bold">
            Create a New Account
          </span>{" "}
        </p> */}
        <div className="relative mb-2 flex flex-col">
          <label className="font-inter text-xs font-normal text-[#212121]">
            Email Address<span className="text-[#8E0000]">*</span>
          </label>
          <div>
            <input
              required
              style={
                showEmailErr
                  ? {
                      border: '1px solid var(--error-700, #8E0000)',
                    }
                  : {}
              }
              // disabled={!isEmailValid}
              className={`${
                !showEmailErr ? 'bg-[#EBEFF3] text-[#000]' : 'bg-[#F8D7DA] text-[#8E0000]'
              }  my-2 w-full rounded px-5 py-3.5 text-sm font-normal`}
              type="email"
              value={email}
              onFocus={() => handleFocus('email')}
              onBlur={() => setFocusedInput(null)}
              onKeyDown={handleKeyDown}
              onChange={handleEmailChange}
              placeholder="Enter your registered email address"
            />
            {showEmailErr && (
              <button className="absolute right-0 top-10 px-4 text-gray-600" disabled={showEmailErr}>
                <AlertIcon width={24} height={24} />
              </button>
            )}
          </div>
          <span className="font-inter text-xs text-[#8E0000]">
            {showEmailErr && !showPasswordErr && authErrors.EMAIL_INVALID}
          </span>
        </div>
        <div className="relative flex flex-col">
          <label className="font-inter text-xs font-normal text-[#212121]">
            Password<span className="text-[#8E0000]">*</span>
          </label>
          <div>
            <input
              required
              id="passwordInput"
              style={
                showPasswordErr
                  ? {
                      border: '1px solid var(--error-700, #8E0000)',
                    }
                  : {}
              }
              value={password}
              onChange={handlePasswordChange}
              onFocus={() => handleFocus('password')}
              onKeyDown={handleKeyDown}
              onBlur={() => setFocusedInput(null)}
              className={`${
                !showPasswordErr ? 'bg-[#EBEFF3] text-[#000]' : 'bg-[#F8D7DA] text-[#8E0000]'
              }  my-2 w-full rounded px-5 py-3.5 text-sm font-normal`}
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder="Enter your password"
            />

            <button className="absolute right-0 top-10 px-4 text-gray-600" onClick={togglePasswordVisibility}>
              {isPasswordVisible ? (
                <HidePasswordIcon width={20} height={12} color={!showPasswordErr ? '#75838D' : '#8E0000'} />
              ) : (
                <ViewPasswordIcon color={!showPasswordErr ? '#75838D' : '#8E0000'} width={24} height={24} />
              )}
            </button>
          </div>
          <span className="font-inter text-xs text-[#8E0000]">
            {showPasswordErr && !showEmailErr && authErrors.PASSWORD_INVALID}
          </span>
        </div>
        {(showEmailErr || showPasswordErr) && email === '' && password === '' && (
          <>
            <span className="font-inter text-xs font-medium leading-4 text-[#8E0000] ">
              {authErrors.EMAIL_PASSWORD_EMPTY}
            </span>
            <br />
            <br />
          </>
        )}
        {showEmailErr && showPasswordErr && email !== '' && password !== '' && (
          <>
            <span className="font-inter text-xs font-medium leading-4 text-[#8E0000] ">
              {authErrors.EMAIL_PASSWORD_INVALID}
            </span>
            <br />
            <br />
          </>
        )}
        <span className="text-sm font-medium text-[#0B5ED7] hover:cursor-pointer">
          <Link to={'/auth/forgot-password'}>Forgot Password? </Link>
        </span>
        <br />

        <br />
        <div className="mb-7 ">
          <input
            type="checkbox"
            checked={staySignedInChecked}
            onClick={() =>
              isEmailValid && isPasswordValid && password !== '' && email !== '' && handleStaySignedInClick()
            }
            disabled={!isEmailValid && !isPasswordValid}
            className={`${isEmailValid && isPasswordValid && password !== '' && email !== '' && 'cursor-pointer'}`}
            placeholder="Stay Signed In"
          />
          <label
            onClick={() =>
              isEmailValid && isPasswordValid && password !== '' && email !== '' && handleStaySignedInClick()
            }
            className={`ml-1.5 font-inter text-sm text-[#75838D] ${
              isEmailValid && isPasswordValid && password !== '' && email !== '' && ' cursor-pointer'
            }`}
          >
            Stay Signed in
          </label>
          <button
            type="submit"
            onClick={(e) => handleLoginClick(e, 'email')}
            className={`${'cursor-pointer bg-[#0B5ED7]'} float-right rounded px-12 py-2 text-sm text-[#fff]`}
          >
            Login &nbsp; &nbsp;&nbsp;
            {isLoginClick ? <MiniSpinLoader /> : '-> '}
          </button>
        </div>
      </div>
    </Modal>
  ) : (
    <Modal openTab={openTab} showTab={showTab} setOpenTab={setOpenTab} heading="Welcome to Catalyst!">
      <div className="mt-2 pb-24">
        <h2 className="mb-4 text-2xl font-bold">Sign in</h2>
        <div className="relative my-6 flex flex-col">
          <label className="font-inter text-sm font-normal text-[#75838D]">
            Phone<span className="text-[#8E0000]">*</span>
          </label>
          <div className="phone-input flex">
            <PhoneInput
              country={'in'}
              value={currCountry?.dialCode + phone}
              onMount={(country) => {
                setCurrCountry(country)
              }}
              onlyCountries={['in']}
              copyNumbersOnly={true}
              onChange={(country) => handleCountryChange(country)}
              onKeyDown={handleKeyDown}
              placeholder="Enter phone number"
              defaultErrorMessage="Invalid value"
              inputClass={`hidden `}
              dropdownClass="font-inter border-0"
              containerClass="!w-12 "
            />
            <div className="flex ">
              <input
                type="tel"
                disabled
                value={`+${currCountry?.dialCode} `}
                className={`inline-block w-10  font-inter ${showContactErr && 'text-[#8E0000] '}`}
              />
              <input
                type="text"
                pattern="[0-9]*"
                onChange={(e) => handlePhoneChange(e.target.value)}
                value={phone ? phone : ''}
                onBlur={() => setFocusedInput(null)}
                onKeyDown={handleKeyDown}
                onFocus={() => handleFocus('phone')}
                placeholder="Enter phone number"
                className={`number-input inline font-inter ${showContactErr && 'text-[#8E0000] '}`}
              />
            </div>
          </div>

          <span className="mt-3 font-inter text-sm font-normal text-[#75838D]">
            A six digit One Time Password will be sent to your phone number
          </span>
          {showContactErr && phone === '' && (
            <p className="pt-2 text-sm font-normal text-[#8E0000]">{authErrors.CONTACT_EMPTY}</p>
          )}
          {showContactErr && phone !== '' && (
            <p className="pt-2 text-sm font-normal text-[#8E0000]">{authErrors.CONTACT_INVALID}</p>
          )}
        </div>
        <div className="mb-7">
          <input
            type="checkbox"
            disabled={!isContactValid}
            checked={staySignedInChecked}
            onClick={() => isContactValid && handleStaySignedInClick()}
            placeholder="Stay Signed in"
          />
          <label
            onClick={() => isContactValid && handleStaySignedInClick()}
            className={`ml-1.5 font-inter text-sm text-[#75838D] ${isContactValid && 'cursor-pointer'}`}
          >
            Stay Signed in
          </label>
          <Link
            to={'/auth/verify-code'}
            type="button"
            onClick={(e) => handleLoginClick(e, 'phone')}
            className={`
                'cursor-pointer bg-[#0B5ED7]'
            float-right rounded px-12 py-2 text-sm text-[#fff]`}
          >
            Login &nbsp; &nbsp;&nbsp;{'-> '}
          </Link>
        </div>
      </div>
    </Modal>
  )
}

export default LoginPage
