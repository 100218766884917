import React from "react";

const Upload: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <mask
        id="mask0_1772_7168"
        style={{ maskType: "alpha" }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_1772_7168)">
        <path
          fill="#75838D"
          d="M4.5 15c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 013 13.5V12c0-.213.072-.39.216-.534a.726.726 0 01.534-.216c.212 0 .39.072.534.216A.726.726 0 014.5 12v1.5h9V12c0-.213.072-.39.216-.534a.726.726 0 01.534-.216c.213 0 .39.072.534.216A.726.726 0 0115 12v1.5c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44h-9zm3.75-9.113L6.844 7.294a.698.698 0 01-.535.215.765.765 0 01-.75-.759.687.687 0 01.216-.525l2.7-2.7a.658.658 0 01.244-.16A.83.83 0 019 3.32c.1 0 .194.015.281.047a.658.658 0 01.244.159l2.7 2.7c.15.15.222.325.216.525a.765.765 0 01-.75.76.698.698 0 01-.535-.216L9.75 5.887v5.363c0 .213-.072.39-.216.534A.726.726 0 019 12a.726.726 0 01-.534-.216.726.726 0 01-.216-.534V5.887z"
        ></path>
      </g>
    </svg>
  );
}

export default Upload;
