import React from "react";

interface FilterProps {
  color?: string;
}

const Filter: React.FC<FilterProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <mask
        id="mask0_1129_1942"
        style={{ maskType: "alpha" }}
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_1129_1942)">
        <path
          // to-do
          // fill={color ? `${color}` : "#F2F2F2"}
          fill={color ? `${color}` : "#BDBDBD"}
          d="M7.333 13.333a.645.645 0 01-.475-.191.645.645 0 01-.191-.476v-4L2.8 3.733a.635.635 0 01-.075-.7c.117-.244.32-.366.608-.366h9.334c.289 0 .491.122.608.366a.635.635 0 01-.075.7L9.333 8.666v4a.645.645 0 01-.191.476.645.645 0 01-.475.191H7.333zM8 8.2L11.3 4H4.7L8 8.2z"
        ></path>
      </g>
    </svg>
  );
};

export default Filter;
