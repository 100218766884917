export let VerifyThroughEmail = {
  HEADING: "Approve Sign in",
  BACK_BTN_TEXT: "Login via Email Address",
  DESCRIPTION:
    "For added Security, we have sent a 6-digit verification code on your email address: ",
  NOT_RECEIEVED_TEXT:
    "(Please check spam folder if the code is not present in inbox)",
};

export let VerifyThroughPhone = {
  HEADING: "OTP Verification",
  BACK_BTN_TEXT: "Login via Phone Number",
  DESCRIPTION:
    "we have sent a 6-digit one time password to your phone number: ",
  NOT_RECEIEVED_TEXT:
    "(It could take 10-15 seconds for verification code to receive)",
};

export let VerifyEmail = {
  HEADING: "Reset Password",
  BACK_BTN_TEXT: "Change password",
  DESCRIPTION:
    "To ensure that this is really you, we have sent a 6-digit verification code on your email address: ",
  NOT_RECEIEVED_TEXT:
    "(Please check spam folder if the code is not present in inbox)",
};
