import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CellProps } from 'react-table'

import Popup from 'libs/popup/Popup'
import WhiteCard from 'libs/card/WhiteCard'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import Chip from 'libs/chip/Chip'
import PaginatedTable from 'libs/table/PaginatedTable'
import MotionDiv from 'libs/motionDiv'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import { hasAccess } from 'utils/utils'
import useTabTitle from 'libs/hooks/useTabTitle'

import DeletePurchaseOrder from '../delete-purchase-order/DeletePurchaseOrder'
import { useGetPurchaseOrderByIDQuery } from '../purchase-orders-endpoints/purchaseOrders.endpoints'
import { ExtendedColumn } from 'store/services/utilsTypes'
import {
  ProductResponse,
  PurchaseOrder,
} from '../purchase-orders-endpoints/purchaseOrdersTypes'

const ViewPurchaseOrder = () => {
  const { id } = useParams()
  useTabTitle('View Purchase Order')
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)

  const purchaseOrderEditAccess = hasAccess('ord', { po: 'e' })

  const [deletePopUp, setDeletePopUp] = useState<{
    isPopUpOpen: boolean
    purchaseOrder: PurchaseOrder | null
  }>({
    isPopUpOpen: false,
    purchaseOrder: null,
  })
  const { data: purchaseOrder, isLoading: purchaseOrderLoading } =
    useGetPurchaseOrderByIDQuery(
      {
        plant_id: plantId?.id,
        id: id ?? '',
      },
      {
        skip: !plantId?.id || !id,
      },
    )

  const productColumns: ExtendedColumn<ProductResponse>[] = [
    {
      Header: 'Product',
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row }: CellProps<ProductResponse>) => {
        return (
          <div>
            <h1 className="p-xs-regular">{row.original?.name ?? '-'}</h1>
            <h2 className="p-xs-regular-dim">{row.original.code}</h2>
          </div>
        )
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: true,
      align: 'right',
    },
    {
      Header: 'Unit',
      disableSortBy: true,
      accessor: 'unit_of_measure',
    },
  ]

  return (
    <MotionDiv>
      {!purchaseOrderLoading ? (
        <WhiteCard className="mt-3 flex flex-col gap-6">
          <span className="flex justify-between">
            <h5 className="h5">Purchase Order Details</h5>
            {purchaseOrderEditAccess && (
              <div className="flex gap-4">
                <Button
                  onClick={() =>
                    setDeletePopUp({
                      isPopUpOpen: true,
                      purchaseOrder: purchaseOrder ?? null,
                    })
                  }
                  disabled={!purchaseOrder}
                  color="error"
                >
                  Delete Purchase Order
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    id && navigate(`/orders/purchase-order/edit/${id}`)
                  }
                  disabled={!purchaseOrder}
                >
                  Edit Purchase Order
                </Button>
              </div>
            )}
          </span>
          <div className="grid grid-cols-[150px,1fr] gap-4 text-xs">
            <span className="text-gray-500">Purchase Order ID</span>
            <span data-testid="purchase-order-id">
              {purchaseOrder?.purchaseOrderID ?? '-'}
            </span>
            <span className="text-gray-500">Status</span>
            <span className="-my-1 w-fit" data-testid="status">
              <Chip title={purchaseOrder?.status} />
            </span>
            <span className="text-gray-500">Supplier Name</span>
            <span data-testid="supplier-name">
              {purchaseOrder?.vendor?.name ?? '-'}
            </span>
            <span className="text-gray-500">Arrival Date</span>
            <span data-testid="arrival-date">
              {formatUnixDate(purchaseOrder?.arrivalDate)}
            </span>
          </div>

          <PaginatedTable
            rows={purchaseOrder?.products}
            columns={productColumns}
            emptyMessage={'No products have been added to this purchase order.'}
            loading={purchaseOrderLoading}
            hidePagination
          />
          {deletePopUp.isPopUpOpen && deletePopUp.purchaseOrder && (
            <Popup
              isOpen={deletePopUp.isPopUpOpen}
              title="Delete Purchase Order"
              onClose={() => {
                setDeletePopUp({ isPopUpOpen: false, purchaseOrder: null })
              }}
            >
              <DeletePurchaseOrder
                closePopUp={() => {
                  setDeletePopUp({ isPopUpOpen: false, purchaseOrder: null })
                }}
                purchaseOrder={deletePopUp.purchaseOrder}
                plantId={plantId?.id}
              />
            </Popup>
          )}
        </WhiteCard>
      ) : (
        <FullPageLoader />
      )}
    </MotionDiv>
  )
}

export default ViewPurchaseOrder
