import React from "react";

const NewToastSuccess: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#fff"
        d="M8.275 11.075L6.394 9.194a.83.83 0 00-.613-.24.83.83 0 00-.612.24.83.83 0 00-.24.612c0 .248.08.452.24.613l2.494 2.493a.84.84 0 00.612.263.84.84 0 00.612-.263l4.944-4.943a.83.83 0 00.24-.613.83.83 0 00-.24-.612.83.83 0 00-.612-.24.83.83 0 00-.613.24l-4.331 4.331zM9.5 18.25a8.52 8.52 0 01-3.412-.69 8.835 8.835 0 01-2.779-1.87 8.836 8.836 0 01-1.87-2.777A8.52 8.52 0 01.75 9.5c0-1.21.23-2.348.69-3.412a8.836 8.836 0 011.87-2.779 8.836 8.836 0 012.778-1.87A8.52 8.52 0 019.5.75c1.21 0 2.348.23 3.412.69a8.836 8.836 0 012.779 1.87 8.835 8.835 0 011.87 2.778A8.52 8.52 0 0118.25 9.5a8.52 8.52 0 01-.69 3.412 8.835 8.835 0 01-1.87 2.779 8.835 8.835 0 01-2.777 1.87 8.52 8.52 0 01-3.413.689z"
      ></path>
    </svg>
  );
};

export default NewToastSuccess;
