import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const TextInput = ({ onFormDataChange, errors, setErrors, data }:any) => {
  const { t } = useTranslation()
  const [hasPrefix, setHasPrefix] = useState(data?.has_prefix || false)
  const [hasSuffix, setHasSuffix] = useState(data?.has_suffix || false)
  const [formData, setFormData] = useState({
    prefix_text: data?.prefix_text || '',
    suffix_text: data?.suffix_text || '',
    help_text: data?.help_text,
    has_prefix: hasSuffix, // Initialize with default values
    has_suffix: hasPrefix, // Initialize with default values
  })

  const handleHasPrefixChange = () => {
    const newValue = !hasPrefix
    setHasPrefix(newValue)
    setFormData((prevData) => ({
      ...prevData,
      has_prefix: newValue,
      prefix_text: newValue ? prevData.prefix_text : '',
    }))
    onFormDataChange({
      ...formData,
      has_prefix: newValue, // Pass updated value to onFormDataChange
    })

    if (!newValue) {
      setErrors({ ...errors, prefixText: '' })
    }
  }

  const handleHasSuffixChange = () => {
    const newValue = !hasSuffix
    setHasSuffix(newValue)
    setFormData((prevData) => ({
      ...prevData,
      has_suffix: newValue,
      suffix_text: newValue ? prevData.suffix_text : '',
    }))
    onFormDataChange({
      ...formData,
      hasSuffix: newValue, // Pass updated value to onFormDataChange
      hasPrefix, // Pass current value of hasPrefix
    })
    if (!newValue) {
      setErrors({ ...errors, suffix_text: '' })
    }
  }

  const handleInputChange = (event:any) => {
    const { name, value } = event.target

    let capitalizedValue = value
    if (name === 'prefixText') {
      capitalizedValue = value.toUpperCase()
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: capitalizedValue,
    }))

    setErrors({ ...errors, [name]: '' })
  }
  useEffect(() => {
    onFormDataChange(formData)
  }, [formData])

  return (
    <>
      <div>
        <div className="flex mt-6 gap-28">
          <div>
            <div className="p-sm-regular">Has Prefix</div>
            <p className="p-xs-regular-dim">Enable this if the input has a prefix.</p>
          </div>
          <div>
            <label className="switch">
              <input type="checkbox" checked={hasPrefix} onChange={handleHasPrefixChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {hasPrefix && (
          <div className="mt-5">
            <label className="mb-1 p-sm-regular ">
              Prefix text <span className="text-[#DC3545]">*</span>
            </label>
            <div>
              <p className="p-xs-regular-dim"></p>
              <div className="relative py-1">
                <input
                  type="text"
                  name="prefix_text"
                  value={formData.prefix_text}
                  onChange={handleInputChange}
                  className={`w-full h-[28px] border ${
                    errors.prefixText ? 'border-[#DC3545]' : 'border-[#E0E0E0]'
                  } bg-[#EBEFF3] border-solid border rounded-md p-2 pr-8 text-sm outline-none p-xs-regular`}
                  placeholder="Enter prefix text"
                />
                {errors.prefixText && <p className="text-xs font-normal pt-1 text-[#DC3545]">{errors.prefixText}</p>}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="flex mt-6 gap-28">
          <div>
            <div className="p-sm-regular">Has Suffix</div>
            <p className="p-xs-regular-dim">Enable this if the input has a suffix.</p>
          </div>
          <div>
            <label className="switch">
              <input type="checkbox" checked={hasSuffix} onChange={handleHasSuffixChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {hasSuffix && (
          <div className="mt-5">
            <label className="mb-1 p-sm-regular ">
              Suffix text <span className="text-[#DC3545]">*</span>
            </label>
            <div>
              <p className="p-xs-regular-dim"></p>
              <div className="relative py-1">
                <input
                  type="text"
                  name="suffix_text"
                  value={formData.suffix_text}
                  onChange={handleInputChange}
                  className={`w-full h-[28px] border ${
                    errors.suffixText ? 'border-[#DC3545]' : 'border-[#E0E0E0]'
                  } bg-[#EBEFF3] border-solid border rounded-md p-2 pr-8 text-sm outline-none p-xs-regular`}
                  placeholder="Enter suffix text"
                />
                {errors.suffixText && <p className="text-xs font-normal pt-1 text-[#DC3545]">{errors.suffixText}</p>}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        <label className="mb-1 p-sm-regular ">{t('help_text')}</label>
        <div>
          <p className="p-xs-regular-dim">{t('guide_the_person_filling_this_field')}</p>
          <div className="relative py-1">
            <input
              type="text"
              name="help_text"
              value={formData.help_text}
              onChange={handleInputChange}
              className={`bg-[#EBEFF3]  w-[360px] h-[28px] border-[#E0E0E0] border-solid border rounded-md p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder={t('enter_help_text')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TextInput
