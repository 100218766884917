import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useGetMachineClassesQuery } from '../machine-class-endpoints/machineClass.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  machineClasses: string[]
  createdBetween: number[]
  updatedBetween: number[]
}
interface FormValues {
  machineClasses: string[]
  createdBetween: number[]
  updatedBetween: number[]
}
const defaultFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const MachineClassFilter: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const plant = useSelector(selectSelectedItem)

  const initialValues: FormValues = {
    machineClasses: filters.machineClasses ?? [],
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
  }

  const { data: machineClasses } = useGetMachineClassesQuery(
    { plantId: plant?.id, filters: defaultFilters },
    { skip: !plant?.id },
  )

  const machineClassOptions = machineClasses?.data?.map((item: any) => {
    return {
      label: item?.name,
      value: item?.name,
    }
  })

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      machineClass: [],
      createdBetween: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })

    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values?.updatedBetween?.length == null ? [] : values?.updatedBetween,
        machineClasses: values.machineClasses,
      }))
    },
  })

  return (
    <>
      <div className="flex h-full flex-col gap-3">
        <Field
          type="multiselect"
          label="Machine Classes"
          placeholder="Select Machinery Class"
          options={machineClassOptions}
          formik={filterFormik}
          name="machineClasses"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date Created"
          formik={filterFormik}
          name="createdBetween"
        />
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Last Updated"
          formik={filterFormik}
          name="updatedBetween"
        />
        <div className="my-auto mb-0 flex justify-between">
          <div>
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset Filters
            </Button>
          </div>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default MachineClassFilter
