import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const NumberInput = ({ onFormDataChange, errors, setErrors, data }: any) => {
  const { t } = useTranslation()

  const [hasMinValue, setHasMinValue] = useState(data?.has_minimum_value || false)
  const [hasMaxValue, setHasMaxValue] = useState(data?.has_maximum_value || false)
  const [hasUnit, setHasUnit] = useState(data?.has_unit || false)
  const [formData, setFormData] = useState({
    min_value: data?.min_value || 0,
    max_value: data?.max_value || 0,
    unit: data?.unit || '',
    help_text: data?.help_text || '',
    has_minimum_value: hasMinValue,
    has_maximum_value: hasMaxValue,
    has_unit: hasUnit,
  })

  const handleHasMaxValueChange = () => {
    const newValue = !hasMaxValue
    setHasMaxValue(newValue)
    setFormData((prevData) => ({
      ...prevData,
      has_maximum_value: newValue,
      max_value: newValue ? prevData?.max_value : 0,
    }))
    onFormDataChange({
      ...formData,
      has_maximum_value: newValue,
    })
    if (!newValue) {
      setErrors({ ...errors, max_value: '' })
    }
  }

  const handleHasMinValueChange = () => {
    const newValue = !hasMinValue
    setHasMinValue(newValue)
    setFormData((prevData) => ({
      ...prevData,
      has_minimum_value: newValue,
      min_value: newValue ? prevData?.min_value : 0,
    }))
    onFormDataChange({
      ...formData,
      has_minimum_value: newValue,
    })
    if (!newValue) {
      setErrors({ ...errors, min_value: '' })
    }
  }

  const handleHasUnitChange = () => {
    const newValue = !hasUnit
    setHasUnit(newValue)
    setFormData((prevData) => ({
      ...prevData,
      has_unit: newValue,
      unit: newValue ? prevData.unit : '',
    }))
    onFormDataChange({
      ...formData,
      has_unit: newValue,
    })
    if (!newValue) {
      setErrors({ ...errors, unit: '' })
    }
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    let minValueError = ''
    let maxValueError = ''

    // Validate minValue
    if (name === 'min_value') {
      const minValue = parseFloat(value)
      const maxValue = parseFloat(formData.max_value)

      if (!isNaN(maxValue) && minValue >= maxValue) {
        //  minValueError = "Min value must be less than Max value.";
        maxValueError = 'Max value must be greater than Min value.' // Display error for max value too
      }
    }

    // Validate maxValue
    if (name === 'max_value') {
      const maxValue = parseFloat(value)
      const minValue = parseFloat(formData.min_value)

      if (!isNaN(minValue) && maxValue <= minValue) {
        //  maxValueError = "Max value must be greater than Min value.";
        minValueError = 'Min value must be less than Max value.' // Display error for min value too
      }
    }

    setErrors({
      ...errors,
      minValue1: minValueError,
      maxValue1: maxValueError,
    })

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  useEffect(() => {
    onFormDataChange(formData)
  }, [formData])

  const handleKeyPress = (e: any) => {
    if (
      e.key === '-' ||
      e.key === '+' ||
      e.key === 'e' ||
      e.key === 'E' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown'
    ) {
      e.preventDefault()
    }
  }

  //stopping it from changing values through mouse wheel
  const handleMouseWheel = (e: any) => {
    e.target.blur()
  }

  return (
    <>
      <div>
        <div className="mt-6 flex gap-28">
          <div>
            <div className="p-sm-regular">{t('has_minimum_value')}</div>
            <p className="p-xs-regular-dim">{t('enable_this_if_the_input_cannot_be_less_than_a_certain_value')}</p>
          </div>
          <div>
            <label className="switch">
              <input type="checkbox" checked={hasMinValue} onChange={handleHasMinValueChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {hasMinValue && (
          <div className="mt-5">
            <label className="mb-1 p-sm-regular ">
              {t('min_value')} <span className="text-[#DC3545]">*</span>
            </label>
            <div>
              <div className="relative py-1">
                <input
                  type="number"
                  name="min_value"
                  min="0"
                  value={formData.min_value}
                  onChange={handleInputChange}
                  onWheel={handleMouseWheel}
                  className={`h-[28px] w-full border ${
                    errors?.minValue || errors?.minValue1 ? 'border-[#DC3545]' : 'border-[#E0E0E0]'
                  } rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                  placeholder={t('enter_value')}
                  onKeyDown={handleKeyPress}
                />
                {errors?.minValue && <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors?.minValue}</p>}
                {errors.minValue1 && <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors.minValue1}</p>}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="mt-6 flex gap-28">
          <div>
            <div className="p-sm-regular">{t('has_maximum_value')}</div>
            <p className="p-xs-regular-dim">{t('enable_this_if_the_input_cannot_exceed_a_certain_value')}</p>
          </div>
          <div>
            <label className="switch">
              <input type="checkbox" checked={hasMaxValue} onChange={handleHasMaxValueChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {hasMaxValue && (
          <div className="mt-5">
            <label className="mb-1 p-sm-regular ">
              {t('max_value')} <span className="text-[#DC3545]">*</span>
            </label>
            <div>
              <div className="relative py-1">
                <input
                  type="number"
                  name="max_value"
                  min="0"
                  value={formData.max_value}
                  onChange={handleInputChange}
                  onWheel={handleMouseWheel}
                  className={`h-[28px] w-full border ${
                    errors?.maxValue || errors?.maxValue ? 'border-[#DC3545]' : 'border-[#E0E0E0]'
                  } rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                  placeholder={t('enter_value')}
                  onKeyDown={handleKeyPress}
                />
                {errors?.maxValue && <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors?.maxValue}</p>}
                {errors?.maxValue1 && <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors?.maxValue1}</p>}
              </div>
            </div>
          </div>
        )}

        <div className="mt-6 flex justify-between gap-28">
          <div>
            <div className="p-sm-regular">{t('has_units')}</div>
            <p className="p-xs-regular-dim">{t('enable_this_if_the_input_has_units')}</p>
          </div>
          <div>
            <label className="switch">
              <input type="checkbox" checked={hasUnit} onChange={handleHasUnitChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {hasUnit && (
          <div className="mt-5">
            <label className="mb-1 p-sm-regular ">
              {t('units_of_measure')}
              <span className="text-[#DC3545]">*</span>
            </label>
            <div>
              <div className="relative py-1">
                <input
                  type="text"
                  name="unit"
                  value={formData.unit}
                  onChange={handleInputChange}
                  className={`h-[28px] w-full border ${
                    errors?.unit ? 'border-[#DC3545]' : 'border-[#E0E0E0]'
                  } rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                  placeholder={t('enter_units_of_measure')}
                />
                {errors?.unit && <p className="pt-1 text-xs font-normal text-[#DC3545]">{errors?.unit}</p>}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        <label className="mb-1 p-sm-regular ">{t('help_text')}</label>
        <div>
          <p className="p-xs-regular-dim">{t('guide_the_person_filling_this_field')}</p>
          <div className="relative py-1">
            <input
              required
              type="text"
              name="help_text"
              value={formData.help_text}
              onChange={handleInputChange}
              className={`h-[28px]  w-[360px] rounded-md border border-solid border-[#E0E0E0] bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder={t('enter_help_text')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default NumberInput
