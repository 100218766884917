import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { FormikContextType } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { get, isObject } from 'lodash'
import { useMemo } from 'react'
import Warning from 'assets/icons/shift-management/Warning'
import Error from 'assets/icons/shift-management/Error'

interface Option {
  arrivalDate(arrivalDate: any): any
  operations: any
  assignedTo: any
  status(arg0: string, status: any): unknown
  endDate: null
  startDate: null
  deliveryDate?: null
  value: any
  label: string
}

export interface EditableTableProps {
  heading?: string
  addButtonTitle?: string
  onSelect?: (selectedItem: Option, rowIndex: number) => void
  columns: any[]
  newRow: Record<string, any>
  emptyMessage?: string
  errorMessage?: string
  formik: FormikContextType<Record<string, Array<any>>>
  name: string
  onAddRow?: () => void
  loading: boolean
  isOverlap?: any
  error?: boolean
  onEdit?: (selectedItem: Option, rowIndex: number) => void
}

const EditableTable = ({
  heading,
  addButtonTitle,
  onSelect,
  onEdit,
  columns,
  newRow,
  emptyMessage,
  onAddRow,
  formik,
  name,
  isOverlap,
  error,
  loading,
  errorMessage,
}: EditableTableProps) => {
  const rows = formik.values[name] ?? []

  const noOfColsForGrid = useMemo(() => {
    const totalFlex = columns?.reduce((acc, column) => {
      return acc + (column.flex ?? 1)
    }, 0)
    return totalFlex + 1
  }, [columns])

  const handleDelete = (index: number) => {
    const updatedRows = rows?.filter((_, i) => i !== index)
    formik.setFieldValue(name, updatedRows)
  }

  const handleAdd = () => {
    const _newRow = { ...newRow, _id: Math.random() }
    formik.setFieldValue(name, [...rows, _newRow])
    if (onAddRow) {
      onAddRow() // Trigger the onAddRow callback
    }
  }

  const newRows = useMemo(() => {
    const _newRows = rows?.map((row, rowIndex) =>
      columns?.map((column) => {
        if (column.content.parentName && row[column.content.parentName]) {
          return row[column.content.parentName].map(
            (_: any, contIndex: number) => ({
              ...column.content,
              name: `${name}.${rowIndex}.${column.content.parentName}.${contIndex}.${column.content.name}`,
            }),
          )
        } else {
          return {
            ...column.content,
            name: `${name}.${rowIndex}.${column.content?.name}`,
          }
        }
      }),
    )

    const existingNames = _newRows.map((x) => {
      const m = x.find((x1) => x1.main)
      return get(formik.values, m.name)?.[m.uniquePropertyInOption]
    })

    const ultimateRows = _newRows?.map((row, rowIndex) =>
      row.map((subRow) => {
        if (!subRow.main || rowIndex === 0) return subRow

        const prevRow = _newRows[rowIndex - 1]?.find((x) => x?.main)
        const lastSelectedOption = prevRow?.name
          ? get(formik.values, prevRow.name)
          : null

        if (!lastSelectedOption) return subRow

        const filteredOptions = subRow.options?.filter(
          (option: any) =>
            !existingNames.includes(
              option.value?.[subRow.uniquePropertyInOption],
            ),
        )

        return {
          ...subRow,
          options: filteredOptions,
        }
      }),
    )
    return ultimateRows
  }, [rows, columns, name, formik.values])

  const shouldDisable = useMemo(() => {
    if (rows?.length === 0) return false
    const mainName = columns?.find((col) => col?.content?.main)?.content?.name
    if (mainName) {
      const lastRow = rows?.[rows.length - 1]
      return !lastRow || !lastRow[mainName]
    }
  }, [columns, rows])

  return (
    <div className="flex flex-col gap-2">
      <h5 className="h6">{heading}</h5>

      {loading ? (
        <FullPageLoader />
      ) : (
        <div className="text-xs">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${noOfColsForGrid}, 1fr)`,
            }}
            className={`bg-brand-primary-shade-3 py-1 font-normal text-white first:rounded-t-[4px]`}
          >
            {Array.isArray(columns) &&
              [
                ...columns,
                {
                  title: 'Actions',
                  flex: 1,
                  align: 'left',
                },
              ].map((column) => {
                return (
                  <div
                    key={column.title}
                    style={{
                      gridColumn: `span ${column?.flex ?? 1}`,
                    }}
                    className={`border-l border-l-[#75838D] px-2 py-1 first:border-l-0 text-${column.align ?? 'left'}`}
                  >
                    <div>{column.title}</div>
                  </div>
                )
              })}
          </div>
          {rows?.length <= 0 ? (
            <>
              {error ? (
                <div className="flex w-full items-center gap-3 bg-[#F8D7DA] p-3 text-error-550">
                  <Error />{' '}
                  {errorMessage
                    ? errorMessage
                    : 'Error: To save the policy, you must add at least one shift. Please ensure all fields are completed and add the necessary shifts.'}
                </div>
              ) : (
                <tr className="flex py-3 text-gray-500">
                  <p className="w-full text-center">{emptyMessage}</p>
                </tr>
              )}
            </>
          ) : (
            <div>
              {newRows?.map((row, rowIndex: number) => {
                return (
                  <div
                    key={rows[rowIndex]?._id}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${noOfColsForGrid}, 1fr)`,
                    }}
                    className={`border-b last:rounded-b-[4px]`}
                  >
                    {Array.isArray(row) &&
                      [...row, 'action'].map((rowItem, colIndex: number) => {
                        const column = columns[colIndex]

                        const cellWrapperClassName = `h-full border-r ${column?.hideRightBorder ? ' border-r-0 ' : ' '} first:border-l flex flex-col text-${column?.align ?? 'left'}`
                        const cellClassName =
                          'h-[50px] w-full border-b last:border-b-0'

                        if (rowItem === 'action') {
                          return (
                            <div
                              key={colIndex}
                              style={{
                                gridColumn: `span ${column?.flex ?? 1}`,
                              }}
                              className={cellWrapperClassName}
                            >
                              <div
                                className={`${cellClassName} flex flex-row items-center justify-start px-2`}
                              >
                                {onEdit && (
                                  <div className="flex h-8 w-8 items-center justify-center rounded-3xl duration-300 ease-in-out hover:bg-primary-200">
                                    <button
                                      className="px-2"
                                      type="button"
                                      onClick={() => {
                                        const _name = columns.find(
                                          (col) => col?.content?.main,
                                        )?.content?.name

                                        let val = undefined
                                        if (_name) {
                                          val = rows?.[rowIndex]?.[_name]
                                        }
                                        onEdit && onEdit(val, rowIndex)
                                      }}
                                    >
                                      <Pen />
                                    </button>
                                  </div>
                                )}

                                <div className="flex h-8 w-8 items-center justify-center rounded-3xl duration-300 ease-in-out hover:bg-error-100">
                                  <button
                                    className="px-2"
                                    type="button"
                                    onClick={() => handleDelete(rowIndex)}
                                  >
                                    <Bin />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        return (
                          <div
                            key={colIndex}
                            style={{
                              gridColumn: `span ${column?.flex ?? 1}`,
                            }}
                            className={cellWrapperClassName}
                            onClick={() => {
                              if (column?.onClick) {
                                column.onClick(rowItem, rowIndex) // Ensure rowItem and rowIndex are passed
                              }
                            }}
                          >
                            {Array.isArray(rowItem) ? (
                              rowItem.map((rowItemItem) => {
                                return (
                                  <div
                                    key={rowItemItem.name}
                                    className={cellClassName}
                                  >
                                    <Field
                                      readOnly={rowItemItem.readOnly}
                                      alignText={column?.align}
                                      placeholder={rowItemItem.placeholder}
                                      type={rowItemItem.type}
                                      name={rowItemItem.name}
                                      formik={formik}
                                      options={rowItemItem.options}
                                      isTextDisabled={
                                        rowItemItem.isTextDisabled
                                      }
                                      hideborder
                                    />
                                  </div>
                                )
                              })
                            ) : (
                              <div className={cellClassName}>
                                <Field
                                  alignText={column?.align}
                                  readOnly={rowItem.readOnly}
                                  placeholder={rowItem?.placeholder}
                                  type={rowItem?.type}
                                  name={rowItem.name}
                                  isTextDisabled={rowItem.isTextDisabled}
                                  onChange={(selectedItem: Option) => {
                                    onSelect && onSelect(selectedItem, rowIndex)
                                  }}
                                  formik={formik}
                                  options={rowItem?.options}
                                  defaultValue={
                                    get(formik.values, rowItem.name)
                                      ? columns
                                          ?.find((col) => col?.content?.main)
                                          ?.content?.options?.find(
                                            (option: any) => {
                                              const value = get(
                                                formik.values,
                                                rowItem.name,
                                              )

                                              // Check if value has _id, and match accordingly
                                              if (
                                                isObject(value) &&
                                                '_id' in value
                                              ) {
                                                return (
                                                  value._id ===
                                                  option.value?._id
                                                )
                                              } else {
                                                return value === option.value
                                              }
                                            },
                                          )
                                      : null
                                  }
                                  hideborder
                                />
                              </div>
                            )}
                          </div>
                        )
                      })}
                  </div>
                )
              })}

              {error && (
                <div className="flex w-full items-center gap-3 bg-[#F8D7DA] p-3 text-error-550">
                  <Error /> {errorMessage}
                </div>
              )}

              {isOverlap?.length > 0 && (
                <div className="flex w-full items-center gap-2 bg-[#FFF3CD] p-3">
                  <Warning />
                  Warning: {isOverlap[0]}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {addButtonTitle && (
        <Button
          startIcon={<PlusIcon />}
          onClick={handleAdd}
          disabled={shouldDisable}
          color="primary"
        >
          {addButtonTitle || 'Add'}
        </Button>
      )}
    </div>
  )
}

export default EditableTable
