import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import {
  useEditPlantMutation,
  useGetCountryQuery,
  useGetTimezoneQuery,
} from 'pages/plants/plants-endpoints/plants.endpoints'
import * as yup from 'yup'
import { useEffect, useRef } from 'react'

type Props = {
  plant: any
  close: () => void
  handleDelete: any
}

const UpdatePlant = ({ plant, close, handleDelete }: Props) => {
  const isInitialRender = useRef(true)
  const [editPlant, { isLoading }] = useEditPlantMutation()
  const { t } = useTranslation()
  const { data: country } = useGetCountryQuery()

  const formik = useFormik({
    initialValues: {
      name: plant.name,
      countryName: plant?.country?.countryName,
      countryCode: '',
      state: plant.state,
      city: plant.city,
      area_name: plant.area_name,
      zip_code: plant.zip_code,
      timezone: plant.timezone,
    },
    validationSchema: yup.object({
      name: yup.string().required('Please enter plant name'),
      countryName: yup.string().required('Please enter country name'),
      state: yup.string().required('Please enter state name'),
      city: yup.string().required('Please enter city name'),
      area_name: yup.string().required('Please enter street name'),
      zip_code: yup.string().required('Please enter zipcode'),
      timezone: yup.string().required('Please enter timezone'),
    }),
    onSubmit: (values) => {
      editPlant({
        plantId: plant?._id,
        data: {
          name: values.name,
          country: { countryName: values.countryName, countryCode: values.countryCode },
          state: values.state,
          city: values.city,
          area_name: values.area_name,
          zip_code: values.zip_code,
          timezone: values.timezone,
        },
      })
        .unwrap()
        .then(() => {
          toast.success(`The plant ${values?.name} was updated successfully.`)
          close()
        })
        .catch((err) => {
          if (err?.data?.detail === 'Plant name is already exists') {
            formik.setFieldError('name', t('departments.duplicate_validation_message'))
          }
          toast.error(err?.data?.detail)
          return
        })
    },
  })
  // These country does not contain timezone
  const countryOptions = country
    ? Object.entries(country)
        .map(([key, value]) => ({
          label: value as string,
          value: value as string,
          sublabel: key as string,
          disabled: '',
        }))
        .filter((option) => option.sublabel !== 'BV' && option.sublabel !== 'HM')
    : []

  useEffect(() => {
    if (formik.values.countryName) {
      const selectedCountry = countryOptions.find((option) => option.label === formik.values.countryName)
      if (selectedCountry) {
        formik.setFieldValue('countryCode', selectedCountry.sublabel)
      }
    }
  }, [formik.values.countryName])
  const timezoneFilters = {
    country_names: formik?.values.countryCode,
  }
  const { data: timezone } = useGetTimezoneQuery({ filters: timezoneFilters }, { skip: !formik?.values?.countryCode })

  const transformedTimeZones = timezone?.map((item: any) => ({
    label: item as string,
    value: item,
  }))

  //  on changing country made timezone null
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
    } else {
      formik.setFieldValue('timezone', null)
    }
  }, [formik?.values?.countryName])

  //****************************************Default Value ******************************** */
  const selectedCountry = { label: formik?.values?.countryName, value: formik?.values?.countryName }
  const selectedTimezone = { label: formik?.values?.timezone, value: formik?.values?.timezone }
  //****************************************Default Value ******************************** */

  return (
    <>
      <div className="flex flex-col md:mt-0 2xl:mt-2">
        <Field
          type="text"
          label="Plant name or code"
          formik={formik}
          required={true}
          placeholder="Enter a name or code to identify the plant"
          name="name"
        />
        <Field
          type="select"
          label="Country"
          placeholder="Enter country name"
          itemType="country"
          required={true}
          options={countryOptions}
          formik={formik}
          defaultValue={formik?.values?.countryName !== '' ? selectedCountry : ''}
          name="countryName"
        />
        <Field
          type="text"
          required={true}
          label="State / Province"
          formik={formik}
          placeholder="Enter State Name"
          name="state"
        />
        <Field
          type="text"
          required={true}
          label="City / Town"
          formik={formik}
          placeholder="Enter City/Town Name"
          name="city"
        />
        <Field
          type="text"
          label="Street/Area name"
          formik={formik}
          required={true}
          placeholder="Enter Street/Area name"
          name="area_name"
        />
        <Field
          type="text"
          required={true}
          label="Zip code"
          formik={formik}
          placeholder="Enter zip code"
          name="zip_code"
        />
        <Field
          type="select"
          label="Timezone"
          placeholder="Select timezone"
          itemType="timezone"
          required={true}
          options={transformedTimeZones}
          disabled={formik.values.countryName === ''}
          formik={formik}
          defaultValue={formik?.values?.timezone !== '' ? selectedTimezone : ''}
          name="timezone"
        />
      </div>
      <div className="mt-2 flex justify-between">
        <Button onClick={() => handleDelete(plant)} color="error">
          Delete
        </Button>
        <div className="flex gap-2 ">
          <Button onClick={close}>{t('cancel')}</Button>
          <Button color="primary" loading={isLoading} onClick={() => formik.handleSubmit()}>
            {t('save')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default UpdatePlant
