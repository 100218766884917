const UserRoles = () => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.6 12.75L5.45 10.6C5.26667 10.4167 5.03333 10.325 4.75 10.325C4.46667 10.325 4.23333 10.4167 4.05 10.6C3.86667 10.7833 3.775 11.0167 3.775 11.3C3.775 11.5833 3.86667 11.8167 4.05 12L6.9 14.85C7.1 15.05 7.33333 15.15 7.6 15.15C7.86667 15.15 8.1 15.05 8.3 14.85L13.95 9.2C14.1333 9.01667 14.225 8.78333 14.225 8.5C14.225 8.21667 14.1333 7.98333 13.95 7.8C13.7667 7.61667 13.5333 7.525 13.25 7.525C12.9667 7.525 12.7333 7.61667 12.55 7.8L7.6 12.75ZM2 20.5C1.45 20.5 0.979167 20.3042 0.5875 19.9125C0.195833 19.5208 0 19.05 0 18.5V4.5C0 3.95 0.195833 3.47917 0.5875 3.0875C0.979167 2.69583 1.45 2.5 2 2.5H6.2C6.41667 1.9 6.77917 1.41667 7.2875 1.05C7.79583 0.683333 8.36667 0.5 9 0.5C9.63333 0.5 10.2042 0.683333 10.7125 1.05C11.2208 1.41667 11.5833 1.9 11.8 2.5H16C16.55 2.5 17.0208 2.69583 17.4125 3.0875C17.8042 3.47917 18 3.95 18 4.5V18.5C18 19.05 17.8042 19.5208 17.4125 19.9125C17.0208 20.3042 16.55 20.5 16 20.5H2ZM2 18.5H16V4.5H2V18.5ZM9 3.75C9.21667 3.75 9.39583 3.67917 9.5375 3.5375C9.67917 3.39583 9.75 3.21667 9.75 3C9.75 2.78333 9.67917 2.60417 9.5375 2.4625C9.39583 2.32083 9.21667 2.25 9 2.25C8.78333 2.25 8.60417 2.32083 8.4625 2.4625C8.32083 2.60417 8.25 2.78333 8.25 3C8.25 3.21667 8.32083 3.39583 8.4625 3.5375C8.60417 3.67917 8.78333 3.75 9 3.75Z"
        fill="#1C1B1F"
      />
    </svg>
  )
}

export default UserRoles
