import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CellProps, Column } from 'react-table'
import Chip from 'libs/chip/Chip'
import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import { hasAccess } from 'utils/utils'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetVendorByIdQuery } from '../vendor-endpoints/vendor.endpoints'
import { Address } from 'pages/contacts/commonTypes'
import { formatString } from 'utils/stringHandlers'

const ViewAddressDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const vendorAccess = hasAccess('con', { ven: 'e' })

  const plant = useSelector(selectSelectedItem)

  const { data: vendor } = useGetVendorByIdQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  const columns: Column<Address>[] = [
    {
      Header: 'Address type',
      accessor: 'addressType',
      Cell: ({ row }: CellProps<Address>) => (
        <div className="flex items-center">
          {formatString(row.original?.addressType)}
          {row.original.isPrimary ? (
            <div className="ml-2">
              <Chip title="PRIMARY" />{' '}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      Header: 'Address line 1',
      accessor: 'addressLine1',
      Cell: ({ row }: CellProps<Address>) => row?.values?.addressLine1 ?? '-',
    },

    {
      Header: 'Address line 2',
      accessor: 'addressLine2',
      Cell: ({ row }: CellProps<Address>) => row?.values?.addressLine2 ?? '-',
    },
    {
      Header: 'City',
      accessor: 'city',
      Cell: ({ row }: CellProps<Address>) => row?.values?.city ?? '-',
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ row }: CellProps<Address>) => row?.values?.state ?? '-',
    },
    {
      Header: 'Zip Code',
      accessor: 'zipCode',
      Cell: ({ row }: CellProps<Address>) => row?.values?.zipCode ?? '-',
    },
    {
      Header: 'Country',
      accessor: 'country',
      Cell: ({ row }: CellProps<Address>) => row?.values?.country ?? '-',
    },
  ]

  return (
    <MotionDiv>
      <div className="flex w-full flex-col  gap-4 ">
        <div className="flex w-full items-center justify-between">
          <h5 className="h5">Address Details</h5>
          {vendorAccess && (
            <Button
              color="primary"
              onClick={() => {
                id &&
                  navigate(
                    `/contacts/vendor-management/edit/${id}/address-details`,
                  )
              }}
            >
              Edit Address Details
            </Button>
          )}
        </div>
        <PaginatedTable<Address>
          columns={columns}
          rows={vendor?.addresses}
          hidePagination={true}
          emptyMessage={
            'No Address details have been added yet. Please add new ones'
          }
        />
      </div>
    </MotionDiv>
  )
}

export default ViewAddressDetails
