import MotionDiv from 'libs/motionDiv'
import FilterableSearchTable from 'libs/table/filterable-search-table_new/FilterableSearchTable'
import Bin from 'assets/icons/camera-page/Bin'
import Pen from 'assets/icons/camera-page/Pen'
import { useMemo, useState } from 'react'
import useTabTitle from 'libs/hooks/useTabTitle'
import Tags from 'libs/tags/Tags'
import { getRelativeTime } from 'utils/dateTimeHandlers'
import ViewOperationPanel from './panel/ViewOperationPanel'
import { useGetAllRoutingsQuery } from './routings-endpoints/routings.endpoints'
import { useNavigate } from 'react-router-dom'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import ViewWorkCenterPanel from './panel/ViewWorkCenterPanel'
import Popup from 'libs/popup/Popup'
import DeleteRouting from './popup/DeleteRouting'
import FilterRouting from './panel/FilterRouting'
const additionalFilters = {
  created_between: [],
  updated_between: [],
  work_centers: [],
  operations: [],
  sort_by: 'created_on',
  sort_dir: 'DESC',
}

const Routings = () => {
  useTabTitle('Routings')
  const navigate = useNavigate()

  const [deletePopUp, setDeletePopUp] = useState({
    isPopUpOpen: false,
    data: null,
  })
  const columns = useMemo(
    () => [
      {
        Header: 'Routing',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return (
            <div className="flex flex-col gap-[1px]">
              <h1
                className="hyperlink"
                onClick={() =>
                  row.original?.id &&
                  navigate(
                    `/settings/configure/routings/view/${row.original?.id}`,
                  )
                }
              >
                {row.original?.name ?? '-'}
              </h1>
              <h1 className="text-gray-600">
                {row?.original?.routing_id ?? '-'}
              </h1>
            </div>
          )
        },
      },
      {
        Header: 'Operations',
        accessor: 'operations',
        width: 300,
        Cell: ({ row }: any) => {
          let operationsName = row?.original?.operations
            ?.slice(0, 3)
            .map((item: any) => item?.name)

          let remainingData =
            row?.original?.operations
              ?.slice(3)
              .map((item: any) => item.name)
              .filter(Boolean) || []

          const remainingCount =
            row?.original?.operations?.length - operationsName?.length

          return (
            <Tags
              data={operationsName}
              remainingCount={remainingCount}
              bgColor={'#E7F5FF'}
              color={'#0B5ED7'}
              remaining={remainingData}
              showPanelComponent={ViewOperationPanel}
              hideTooltip={true}
              title="Operation Details"
              id={row?.original?.id}
            />
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Work Centers',
        accessor: 'work_centers',
        width: 300,

        Cell: ({ row }: any) => {
          const workCenterNames = row?.original?.operations.map(
            (operation: any) => operation?.work_center?.name,
          )

          let remainingData = workCenterNames?.slice(0, 3).filter(Boolean) || []

          const remainingCount = workCenterNames?.length - remainingData?.length

          return (
            <Tags
              data={remainingData}
              remainingCount={remainingCount}
              bgColor={'#E7F5FF'}
              color={'#0B5ED7'}
              remaining={remainingData}
              showPanelComponent={ViewWorkCenterPanel}
              hideTooltip={true}
              title="Work Center Details"
              id={row?.original?.id}
            />
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Last Updated',
        accessor: 'updated_on',
        width: 200,
        Cell: ({ row }: any) => {
          const formattedDate = getRelativeTime(row.original?.updated_on)
          const userData = row.original?.updated_by

          const firstName = userData?.name

          const fullName = ` by ${firstName}`

          return (
            <div className="flex w-full justify-between">
              <span>
                {formattedDate}
                {fullName}
              </span>
              <span className="cursor-pointer"></span>
            </div>
          )
        },
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        width: 100,
        Cell: ({ row }) => (
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row">
              <button
                className="mr-2"
                onClick={() =>
                  row?.original?.id &&
                  navigate(
                    `/settings/configure/routings/edit/${row?.original?.id}`,
                  )
                }
              >
                <Pen />
              </button>

              <button
                onClick={() =>
                  setDeletePopUp({ isPopUpOpen: true, data: row.original })
                }
              >
                <Bin />
              </button>
            </div>
            <span
              className="mr-2 cursor-pointer"
              onClick={() =>
                row?.original?.id &&
                navigate(
                  `/settings/configure/routings/view/${row?.original.id}`,
                )
              }
            >
              <ClickableRowArrow />
            </span>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  const handleAddRouting = () => {
    navigate('/settings/configure/routings/add')
  }
  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Routings</h1>

        <p className="w-[650px] p-sm-regular-1">
          Organize and control the sequence of operations for your production
          process with each routing.
        </p>

        <FilterableSearchTable
          useQuery={useGetAllRoutingsQuery}
          columns={columns}
          addButton={{ label: 'Add Routing', onClick: handleAddRouting }}
          placeholders={{
            search: 'Search Routings by ID, name, Operations or Work Centers',
            emptyMessage: 'No routings are added. Please add routings.',
            filterEmptyMessage: 'No matching results found',
          }}
          filterPanelComponent={FilterRouting}
          additionalFilters={additionalFilters}
        />
        {deletePopUp.isPopUpOpen && (
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Routing"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, data: null })
            }}
          >
            <DeleteRouting
              closePopUp={() =>
                setDeletePopUp({ isPopUpOpen: false, data: null })
              }
              data={deletePopUp.data}
            />
          </Popup>
        )}
      </>
    </MotionDiv>
  )
}

export default Routings
