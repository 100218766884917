import Wizard from 'libs/wizard/Wizard'
import ViewBasicDetails from './ViewBasicDetails'
import ViewAddressDetails from './ViewAddressDetails'
import ViewPurchaseOrders from './ViewPurchaseOrders'
import { useParams } from 'react-router-dom'
import { useGetVendorByIdQuery } from '../vendor-endpoints/vendor.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import FullPageLoader from 'libs/loader/FullPageLoader'

const tabs = [
  {
    tabTitle: 'Basic Details',
    routeName: 'basic-details',
    component: <ViewBasicDetails />,
  },
  {
    tabTitle: 'Address Details',
    routeName: 'address-details',
    component: <ViewAddressDetails />,
  },
  {
    tabTitle: 'Purchase Orders',
    routeName: 'purchase-order',
    component: <ViewPurchaseOrders />,
  },
]

const VendorDetails = () => {
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)

  const { data: vendor, isLoading: loading } = useGetVendorByIdQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <>
      {vendor && (
        <>
          <h1 data-testid="header" className="font-bold mb-2 text-2xl">{vendor.name}</h1>
          <Wizard tabs={tabs} type="tabs" />
        </>
      )}
    </>
  )
}

export default VendorDetails
