
function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16"
    >
      <path
        fill="#434C52"
        d="M2.833 15.5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.177V3a.806.806 0 01-.593-.24.806.806 0 01-.24-.593c0-.236.08-.434.24-.594.16-.16.357-.24.594-.24H4.5c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h3.334c.236 0 .434.08.593.24.16.16.24.357.24.593h3.333c.236 0 .434.08.594.24.16.16.24.358.24.594s-.08.434-.24.593a.806.806 0 01-.594.24v10.833c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.176.49H2.833zM11.167 3H2.833v10.833h8.334V3zM4.5 12.167h1.667v-7.5H4.5v7.5zm3.333 0H9.5v-7.5H7.833v7.5z"
      ></path>
    </svg>
  );
}

export default DeleteIcon;
