import { t } from 'i18next'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import { useGetAllManufactureOrdersQuery } from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'
import { formatString } from 'utils/stringHandlers'
import NewEditableTable from 'libs/table/NewEditableTable'
import QuickAddEditManufactureOrderPanel from 'libs/quick-add-edits/quick-add-edit-mo/QuickAddEditMoPanel'
import FullPageLoader from 'libs/loader/FullPageLoader'

const ManufacturingOrders = ({ setPage, setId, formik, loading }: any) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const getItem = localStorage.getItem('manufactureOrder')
  const [isPanelOpen, setIsPanelOpen] = useState(getItem ? true : false)
  const [selectedManufactureOrder, setSelectedManufactureOrder] =
    useState<any>(null)

  const filters = {
    page_no: 1,
    page_size: 1000,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
  }

  const { data: moOrders, isLoading: isManufactureLoading } =
    useGetAllManufactureOrdersQuery(
      {
        plantId: plantId?.id,
        filters: filters,
      },
      {
        skip: !plantId?.id,
      },
    )

  const manufactureOrderOptions = useMemo(
    () =>
      moOrders?.data?.map((item: any) => ({
        id: item?.manufactureOrderID,
        label: item?.manufactureOrderID,
        value: item,
      })),
    [moOrders?.data],
  )

  useEffect(() => {
    setId({ id: id })
    setPage(3)
  }, [])

  const handleEdit = useCallback((manufactureOrders: any) => {
    setSelectedManufactureOrder(manufactureOrders)
    setIsPanelOpen(true)
  }, [])

  const manufactureOrderColumns = [
    {
      title: 'MO ID',
      accessor: 'manufactureOrderID',
      flex: 2,
      main: true,
      editable: true,
      placeholder: 'Select ID',
    },

    {
      title: 'Finished Good',
      accessor: 'finishedGood',
      render(value: any) {
        return value?.name ? value?.name : '-'
      },
    },
    {
      title: 'Sales Order',
      accessor: 'salesOrders',
      render(value: any) {
        return value?.length > 0 ? `${value?.length} Orders` : '-'
      },
    },
    {
      title: 'Purchase Order',
      accessor: 'purchaseOrders',
      render(value: any) {
        return value?.length > 0 ? `${value?.length} Orders` : '-'
      },
    },
    {
      title: 'Work Order',
      accessor: 'workOrders',
      render(value: any) {
        return value?.length > 0 ? `${value?.length} Orders` : '-'
      },
    },
    {
      title: 'Start Date',
      accessor: 'startDate',
      render(value: any) {
        return formatUnixDate(value)
      },
    },
    {
      title: 'End Date',
      accessor: 'endDate',
      render(value: any) {
        return formatUnixDate(value)
      },
    },
    {
      title: 'Status',
      accessor: 'status',
      render(value: any) {
        return value ? formatString(value) : '-'
      },
    },
  ]

  const [data, setData] = useState([])

  useEffect(() => {
    const d = formik.values.manufactureOrders
    if (d != null && d.length > 0) {
      setData(d)
    }
  }, [formik.values.manufactureOrders])

  return (
    <div className="relative flex min-h-[70vh] w-full flex-col px-2">
      <>
        {isManufactureLoading ? (
          <div className="absolute left-1/2 top-1/2">
            {' '}
            <FullPageLoader />
          </div>
        ) : (
          <>
            <div className="mb-2 flex justify-between">
              <div>
                <h5 className="h5-1">{'Manufacturing Order '}</h5>
                <p className="p-sm-regular-2">
                  {
                    'View the details of your added manufacturing orders and easily add new ones as needed.'
                  }
                </p>
              </div>
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  setIsPanelOpen(true)
                  setSelectedManufactureOrder(null)
                }}
              >
                {'Add Manufacturing Order'}
              </Button>
            </div>

            <NewEditableTable
              defaultRows={formik.values.manufactureOrders}
              options={manufactureOrderOptions}
              columns={manufactureOrderColumns}
              uniqueIdInOptions="manufactureOrderID"
              addButtonTitle="Select Manufacturing Order"
              onChange={(values: any) => {
                setData(values)
              }}
              onEdit={handleEdit}
            />

            <QuickAddEditManufactureOrderPanel
              id={selectedManufactureOrder?.manufactureOrderID?._id}
              onClose={() => {
                setIsPanelOpen(false)
                localStorage.removeItem('manufactureOrder')
              }}
              isOpen={isPanelOpen}
            />
            <div className="flex flex-grow  gap-3 "></div>
            <div className="mt-4 flex justify-between">
              <div className="flex gap-3">
                <Button
                  type="button"
                  onClick={() => navigate('/planning/production-plans')}
                >
                  {t('cancel')}
                </Button>
              </div>
              <div className="flex gap-3">
                {!id && (
                  <Button
                    onClick={() => {
                      navigate('../summary')
                      setPage(4)
                    }}
                  >
                    {t('plannings.skip_this_step')}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setPage(4)
                    navigate(`../summary`)
                  }}
                >
                  {'Skip this step'}
                </Button>
                <Button
                  onClick={() => {
                    setPage(2)
                    navigate(`../scheduling`)
                  }}
                >
                  {'Previous'}
                </Button>
                <Button
                  loading={loading}
                  color="success"
                  type="submit"
                  onClick={() => {
                    formik.setFieldValue('manufactureOrders', data)
                    formik.handleSubmit()
                  }}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  )
}

export default ManufacturingOrders
