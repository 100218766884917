import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetUsersByPlantQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface filters {
  status?: string[]
  assignedTo?: string[]
  startBetween?: any[]
  endBetween?: any[]
  createdBetween?: any[]
  updatedBetween?: any[]
  sortBy?: string
  sortDir?: 'ASC' | 'DESC'
  searchText?: string
}
interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: filters
}

const FilterWorkOrders: React.FC<Props> = ({ closePanel, setFilters, filters }) => {
  const plant = useSelector(selectSelectedItem)
  const statusOptions = [
    {
      label: 'Not Started',
      value: 'NOT_STARTED',
    },
    {
      label: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
  ]

  // for filters
  const { data: plantUsers } = useGetUsersByPlantQuery({ plantId: plant?.id }, { skip: !plant?.id })
  const operatorsOptions = plantUsers
    ?.filter((user: any) => user?.role?.toLowerCase() === 'operator')
    ?.map((user: any) => ({
      label: user?.first_name + ' ' + user?.last_name,
      value: user?._id,
    }))

  const initialValues: filters = {
    startBetween: filters?.startBetween?.length == 0 ? [] : filters?.startBetween,
    endBetween: filters?.endBetween?.length == 0 ? [] : filters?.endBetween,
    status: filters?.status == undefined ? undefined : filters?.status,
    assignedTo: filters?.assignedTo?.length == 0 ? [] : filters?.assignedTo,
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      createdBetween: [],
      updatedBetween: [],
      quantityBetween: [],
      sortBy: 'updated_on',
      sortDir: 'DESC',
      status: undefined,
      assignedTo: [],
      startBetween: [],
      endBetween: [],
    })

    closePanel()
  }

  const filterFormik = useFormik<filters>({
    initialValues: initialValues,
    onSubmit: (values: filters) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        startBetween: values.startBetween == null ? [] : values.startBetween,
        endBetween: values.endBetween == null ? [] : values.endBetween,
        status: values.status?.length == statusOptions.length ? undefined : values.status,
        assignedTo: values.assignedTo == null ? [] : values.assignedTo,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
      }))
    },
  })

  return (
    <div className="flex flex-col gap-2">
      <Field type="dateRange" label="Start between" formik={filterFormik} name="startBetween" />
      <Field type="dateRange" fullWidth label="End between" formik={filterFormik} name="endBetween" />
      <Field
        type="multiselect"
        label="Status"
        options={statusOptions}
        placeholder="Select status"
        formik={filterFormik}
        name="status"
      />
      <Field
        type="multiselect"
        label="Assigned to"
        placeholder="Select operators"
        formik={filterFormik}
        name="assignedTo"
        options={operatorsOptions}
      />
      <Field
        type="dateRange"
        shouldDisableFutureDate
        label="Created between"
        formik={filterFormik}
        name="createdBetween"
      />
      <Field
        type="dateRange"
        shouldDisableFutureDate
        fullWidth
        label="Updated between"
        formik={filterFormik}
        name="updatedBetween"
      />

      <div className="flex justify-between ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FilterWorkOrders
