import Button from '../../../libs/button/Button'
import { t } from 'i18next'
import { useUpdateWorkOrderStatusMutation } from '../work-orders-endpoints/workorders.endpoints'

import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  workOrder: any
  plantId: string
}

function CancelWorkOrder({ closePopUp, workOrder, plantId }: Props) {
  const [updateWorkOrder, { isLoading: updateWorkOrderLoading }] = useUpdateWorkOrderStatusMutation()

  const navigate = useNavigate()

  const handleUpdateWorkOrder = () => {
    updateWorkOrder({ plantId, woID: workOrder?._id, status: 'CANCELLED' })
      .unwrap()
      .then(() => {
        toast.success(`Work Order “${workOrder?.workOrderID}” is  cancelled successfully.`)
        navigate(-1)
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(
          error?.data?.detail ?? `Failed to  cancel Work Order “${workOrder?.workOrderID}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')} <span className="break-words pr-1 p-xs-bold">“{workOrder?.workOrderID}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Keep WO</Button>
        <Button color="error" onClick={handleUpdateWorkOrder} loading={updateWorkOrderLoading}>
          Cancel WO
        </Button>
      </div>
    </div>
  )
}

export default CancelWorkOrder
