import mixpanel from 'mixpanel-browser'
import { getAuthTokenAndRole } from 'utils/auth'
import { MixPanel } from 'common/config'

mixpanel.init(MixPanel, {
  debug: true,
})

export function trackEvent(eventName: any, properties: any) {
  const { username } = getAuthTokenAndRole()
  properties.userName = username
  if (!MixPanel) {
    return
  }
  mixpanel.track(eventName, properties)
}

// export function pageViewEvent(eventName) {
//     mixpanel.track_pageView(eventName);
// }
