import Button from 'libs/button/Button'
import { usePatchStartOrderStatusMutation } from '../maintenance-order-endpoints/order.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { toast } from 'libs/toast'

const StartOrderPopup = ({ closePopup, maintenanceOrder }: any) => {
  const plantId = useSelector(selectSelectedItem)
  const [startOrder] = usePatchStartOrderStatusMutation()
  const handleStartOrder = () => {
    startOrder({ plantId: plantId?.id, moID: maintenanceOrder?._id })
      .unwrap()
      .then(() => {
        toast.success('The Order is started successfully')
        closePopup()
      })
      .catch((error) => {
        const errorMessage = Array.isArray(error?.data?.detail) ? error?.data?.detail[0]?.msg : error?.data?.detail
        toast.error(errorMessage ?? 'Something went wrong while creating the maintenance order')
      })
  }
  return (
    <div>
      <p className="p-xs-regular">
        You are about to start Maintenance Order{' '}
        <span className="break-words pr-1 p-xs-bold"> "{maintenanceOrder?.orderID}”.</span>
        Are you sure you want to proceed?
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={()=>closePopup()}>Close</Button>
        <Button color="primary" onClick={handleStartOrder}>
          Start
        </Button>
      </div>
    </div>
  )
}

export default StartOrderPopup
