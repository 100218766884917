import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  SalesOrderResponse,
  SalesOrdersResponse,
  SalesOrderRequest,
  SalesOrdersRequest,
  CreateSalesOrderRequestType,
  UpdateSalesOrderRequestType,
  DeleteSalesOrderRequestType,
  SalesOrderMutationResponse,
} from './salesOrdersTypes'
import {
  SatatisticsRequest,
  SatatisticsResponse,
} from './salesOrders.constants'

export const sales = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postSalesOrder: builder.mutation<
      SalesOrderMutationResponse,
      CreateSalesOrderRequestType
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/sales-order`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['salesOrderUpdate'],
    }),
    putSalesOrder: builder.mutation<
      SalesOrderMutationResponse,
      UpdateSalesOrderRequestType
    >({
      query: ({ plant_id, id, data }) => {
        return {
          url: `/plant/${plant_id}/sales-order/${id}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['salesOrderUpdate'],
    }),
    deleteSalesOrder: builder.mutation<
      SalesOrderMutationResponse,
      DeleteSalesOrderRequestType
    >({
      query: ({ plant_id, id }) => ({
        url: `/plant/${plant_id}/sales-order/${id}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['salesOrderGetById'],
    }),
    getSalesOrderByID: builder.query<SalesOrderResponse, SalesOrderRequest>({
      query: ({ plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/sales-order/${id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Sales Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['salesOrderUpdate'],
    }),
    getAllSalesOrders: builder.query<SalesOrdersResponse, SalesOrdersRequest>({
      query: ({ plant_id, filters }) => {
        return {
          url: buildUrl(`/plant/${plant_id}/sales-orders`, filters),
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Sales Orders ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['salesOrderUpdate', 'salesOrderGetById'],
    }),
    getSalesOrdersStats: builder.query<SatatisticsResponse, SatatisticsRequest>(
      {
        query: ({ plant_id }) => ({
          url: `/plant/${plant_id}/sales-orders/stats`,
          method: 'GET',
          headers: getToken(),
        }),
        providesTags: ['salesOrderUpdate'],
      },
    ),
  }),
  overrideExisting: false,
})

export const {
  useGetAllSalesOrdersQuery,
  useGetSalesOrderByIDQuery,
  usePostSalesOrderMutation,
  usePutSalesOrderMutation,
  useDeleteSalesOrderMutation,
  useGetSalesOrdersStatsQuery,
} = sales
