import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useDeleteShiftPolicyMutation } from '../shift-mangement-endpoints/shiftManagement.endpoints'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  shiftPolicy: any
  plantId: string
}

function DeleteShiftPolicy({ closePopUp, shiftPolicy, plantId }: Props) {
  const [deleteShiftPolicy, { isLoading: deletePurchaseOrderLoading }] = useDeleteShiftPolicyMutation()
  const navigate = useNavigate()
  const handleDeleteShiftPolicy = () => {
    deleteShiftPolicy({ plantId, shiftPolicyID: shiftPolicy?._id })
      .unwrap()
      .then(() => {
        toast.success(`Shift Policy “${shiftPolicy?.policyName}” is removed successfully.`)
        navigate('/settings/shift-management')
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(
          error?.data?.detail ?? `Failed to remove Shift Policy “${shiftPolicy?.policyName}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to delete the <span className="break-words pr-1 p-xs-bold">“{shiftPolicy?.policyName}”.</span>
        Are you sure you want to proceed?
      </p>
      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={handleDeleteShiftPolicy} loading={deletePurchaseOrderLoading}>
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteShiftPolicy
