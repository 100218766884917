import Button from '../../../libs/button/Button'
import { t } from 'i18next'
import { useUpdateWorkOrderStatusMutation } from '../work-orders-endpoints/workorders.endpoints'

import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  workOrder: any
  plantId: string

}

function StartWorkOrder({ closePopUp, workOrder, plantId }: Props) {
  const [updateWorkOrder, { isLoading: updateWorkOrderLoading }] = useUpdateWorkOrderStatusMutation()

  const navigate = useNavigate()

  const handleUpdateWorkOrder = () => {
    updateWorkOrder({ plantId, woID: workOrder?._id, status: 'IN_PROGRESS' })
      .unwrap()
      .then(() => {
        toast.success(`Work Order “${workOrder?.workOrderID}” is started successfully.`)
        navigate(-1)
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(error?.data?.detail ?? `Failed to start Work Order “${workOrder?.workOrderID}”. Please try again.`)
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to start Work Order{' '}
        <span className="break-words pr-1 p-xs-bold">“{workOrder?.workOrderID}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Cancel</Button>
        <Button color="primary" onClick={handleUpdateWorkOrder} loading={updateWorkOrderLoading}>
          Start
        </Button>
      </div>
    </div>
  )
}

export default StartWorkOrder
