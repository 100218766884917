import React, { useState, ReactNode } from 'react'

interface TooltipProps {
  content: ReactNode
  children: ReactNode
  position?: 'top' | 'right' | 'bottom' | 'left' | 'center'
  hideTooltips?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  position = 'top',
  hideTooltips,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const showTooltip = () => setIsVisible(true)
  const hideTooltip = () => setIsVisible(false)

  const getPositionStyles = () => {
    switch (position) {
      case 'top':
        return { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
      case 'right':
        return { top: '50%', left: '100%', transform: 'translateY(-50%)' }
      case 'bottom':
        return { top: '100%', left: '50%', transform: 'translateX(-50%)' }
      case 'left':
        return { top: '50%', right: '100%', transform: 'translateY(-50%)' }
      case 'center':
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
      default:
        return { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
    }
  }

  const getArrowStyles = () => {
    switch (position) {
      case 'top':
        return {
          bottom: '-6px',
          left: '40%',
          transform: 'translateX(-50%)',
          borderWidth: '6px 6px 0 6px',
          borderColor: 'black transparent transparent transparent',
        }
      case 'right':
        return {
          top: '50%',
          left: '-6px',
          transform: 'translateY(-50%)',
          borderWidth: '6px 6px 6px 0',
          borderColor: 'transparent black transparent transparent',
        }
      case 'bottom':
        return {
          top: '-6px',
          left: '50%',
          transform: 'translateX(-50%)',
          borderWidth: '0 6px 6px 6px',
          borderColor: 'transparent transparent black transparent',
        }
      case 'left':
        return {
          top: '50%',
          right: '-6px',
          transform: 'translateY(-50%)',
          borderWidth: '6px 0 6px 6px',
          borderColor: 'transparent transparent transparent black',
        }
      default:
        return {
          bottom: '-6px',
          left: '50%',
          transform: 'translateX(-50%)',
          borderWidth: '6px 6px 0 6px',
          borderColor: 'black transparent transparent transparent',
        }
    }
  }

  return (
    <div
      className="inline-block relative w-full"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      style={{ position: 'relative' }}
    >
      {children}
      {isVisible && !hideTooltips && (
        <div
          className="absolute bg-black overflow-hidden p-2 rounded text-ellipsis text-white text-xs w-auto whitespace-nowrap z-50"
          style={{ ...getPositionStyles(), zIndex: 9999 }}
        >
          {content}
          <span
            className="absolute"
            style={{
              ...getArrowStyles(),
              position: 'absolute',
              width: '0',
              height: '0',
              borderStyle: 'solid',
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Tooltip
