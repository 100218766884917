import { useState, useEffect } from 'react'
import { hasAccess } from 'utils/utils' // Your access checking function
import { Navigate } from 'react-router-dom'

const AccessRoute = ({ module, permission, children }: any) => {
  const [loading, setLoading] = useState(true)
  const [accessGranted, setAccessGranted] = useState(false)

  useEffect(() => {
    const checkAccess = async () => {
      const hasPermission = await hasAccess(module, permission)

      setAccessGranted(hasPermission)
      setLoading(false)
    }

    checkAccess()
  }, [module, permission])

  if (loading) {
    return <div>Loading...</div> // Or Suspense fallback
  }

  return accessGranted ? children : <Navigate to="/unauthorized" /> // Redirect if no access
}

export default AccessRoute
