import Button from 'libs/button/Button'
import { useNavigate } from 'react-router-dom'
import { toast } from 'libs/toast'
import { useDeleteMachineMutation } from '../machine-endpoints/machine.endpoints'

const DeleteMachine = ({ closePopUp, machine, plant_id }: any) => {
  const navigate = useNavigate()
  const [deleteMachine, { isLoading: deleteLoading }] = useDeleteMachineMutation()

  const handleDelete = () => {
    deleteMachine({ plantId: plant_id, machineID: machine._id })
      .unwrap()
      .then(() => {
        closePopUp()
        navigate('/maintenance/machines')

        toast.success(
          <div>
            The Machine <b>"{machine.name}"</b> is removed successfully.
          </div>,
        )
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to delete <span className="break-words pr-1 p-xs-bold">"{machine?.name}".</span> Are you sure you
        want to proceed?
      </p>
      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={handleDelete} loading={deleteLoading}>
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteMachine
