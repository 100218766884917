import { rootReducer } from 'store/services/root.service'
import { getToken } from 'utils/auth'
import { toast } from 'libs/toast'

import {
  GetRoutingByIdRequest,
  Routing,
  GetRoutingsResponse,
  GetRoutingRequest,
} from './routingsTypes'
import { buildUrl } from 'utils/stringHandlers'
export const routings = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    getRoutingsCount: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `/plant/${plant_id}/routings/count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the routings count')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getAllRoutings'],
    }),
    getAllRoutings: builder.query<GetRoutingsResponse, GetRoutingRequest>({
      query: ({ plant_id, filters }) => {
        return {
          url: buildUrl(`/plant/${plant_id}/routings`, filters),
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the routings')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getAllRoutings'],
    }),
    getRoutingByID: builder.query<Routing, GetRoutingByIdRequest>({
      query: ({ plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/routing/${id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the get routing by ID')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getRoutings'],
    }),
    deleteRouting: builder.mutation<string, GetRoutingByIdRequest>({
      query: ({ plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/routing/${id}`,
          method: 'DELETE',
          headers: getToken(),
        }
      },
      invalidatesTags: ['getAllRoutings'],
    }),
    postRouting: builder.mutation({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/routing`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateRouting', 'getAllRoutings', 'getRoutings'],
    }),
    putRouting: builder.mutation({
      query: ({ data, plant_id, id }) => {
        return {
          url: `/plant/${plant_id}/routing/${id}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['updateRouting', 'getAllRoutings', 'getRoutings'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetRoutingsCountQuery,
  useGetRoutingByIDQuery,
  useGetAllRoutingsQuery,
  usePostRoutingMutation,
  usePutRoutingMutation,
  useDeleteRoutingMutation,
} = routings
