import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import Button from 'libs/button/Button'
import { toast } from 'libs/toast'

import { PurchaseOrder } from '../purchase-orders-endpoints/purchaseOrdersTypes'
import { useDeletePurchaseOrderMutation } from '../purchase-orders-endpoints/purchaseOrders.endpoints'

interface Props {
  closePopUp: () => void
  purchaseOrder: PurchaseOrder
  plantId: string
}

/**
 * Component for deleting a sales order.
 *
 * @param {object} props - The component props.
 * @param {function} props.closePopUp - Function to close the popup.
 * @param {object} props.purchaseOrder - The purchase order object to delete.
 * @param {string} props.plantId - The plant ID associated with the sales order.
 */

function DeletePurchaseOrder({ closePopUp, purchaseOrder, plantId }: Props) {
  const navigate = useNavigate()
  const [deletePurchaseOrder, { isLoading: deletePurchaseOrderLoading }] =
    useDeletePurchaseOrderMutation()
  const handleDeletePurchaseOrder = () => {
    deletePurchaseOrder({
      plant_id: plantId,
      id: purchaseOrder?.id,
    })
      .unwrap()
      .then((res) => {
        toast.success(res)
        closePopUp()
        navigate('/orders/purchase-order')
      })
      .catch((error) => {
        toast.error(
          error?.data?.detail ??
            `Failed to remove Purchase Order “${purchaseOrder?.purchaseOrderID}”. Please try again.`,
        )
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')}{' '}
        <span className="break-words pr-1 p-xs-bold">
          “Purchase Order ID {purchaseOrder?.purchaseOrderID}”.
        </span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button
          color="error"
          onClick={handleDeletePurchaseOrder}
          loading={deletePurchaseOrderLoading}
        >
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeletePurchaseOrder
