import PaginatedTable from 'libs/table/PaginatedTable'
import { useMemo, useState } from 'react'
import { useEffect } from 'react'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { formatNumber } from 'utils/numberHandlers'

interface Props {
  isOpen: boolean
  closePanel: () => void
  data: any
}
const ViewProduct = ({ data }: Props) => {
  const [formData, setFormData] = useState(data)

  useEffect(() => {
    setFormData(data)
  }, [data])
  const BOMColumns: ExtendedColumn<object>[] = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'bom_product_details',
        width: 100,
        Cell: ({ value }: any) => {
          return (
            <div className="">
              <div className="p-xs-regular">{value.name}</div>
              <div className="p-xs-regular-dim">{value.code}</div>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Quantity',
        accessor: 'quantity',
        align: 'right',
        Cell: ({ value }: any) => (
          <span className="font-mono">
            {value != null ? formatNumber(value) : '-'}
          </span>
        ),
        disableSortBy: true,
        width: 60,
      },
      {
        Header: 'Unit of Measure',
        accessor: 'bom_product_details.unit_of_measure',
        disableSortBy: true,
        width: 50,
      },
    ],
    [],
  )

  return (
    <div>
      <div className="flex flex-col gap-2 rounded bg-gray-100 p-3">
        {formData?.name && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Product Name</div>
            <div>
              <div className="w-60 break-words p-xs-regular">
                {formData?.name}
              </div>
            </div>
          </div>
        )}
        {formData?.code && (
          <div className="flex justify-between">
            <div className="p-xs-regular-dim">Product Code</div>
            <div>
              <div className="w-60 p-xs-regular">{formData?.code}</div>
            </div>
          </div>
        )}
        {formData?.category && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Product Category</div>
            <div>
              <div className="w-60 break-words p-xs-regular">
                {formData?.category}
              </div>
            </div>
          </div>
        )}
        {formData?.unit_of_measure && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim"> Unit Of Measure</div>
            <div>
              <div className="w-60 break-words p-xs-regular">
                {formData?.unit_of_measure}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-5">
        <PaginatedTable
          columns={BOMColumns}
          emptyMessage={"You don't have any BOM created for this Product"}
          rows={data.bill_of_materials}
          hidePagination={true}
        />
      </div>
    </div>
  )
}

export default ViewProduct
