import React, { useMemo } from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useGetShiftPolicyQuery } from 'pages/shift-management/shift-mangement-endpoints/shiftManagement.endpoints'
import { EmployeeDepartmentOptions } from 'pages/employee/Employee-Department/employee-departments-endpoints/employeedepartmentTypes'
import { useGetEmployeeDepartmentAndDesignationQuery } from 'pages/employee/Employee-Department/employee-departments-endpoints/employeesdepartment.endpoints'

// Define the Props and Filters interface for better type safety
interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}

interface Filters {
  shifts: string[]
  dateBetween: number[]
  status: string[]
  designations: string[]
  departments: string[]
}

interface FormValues {
  dateBetween: number[]
  shifts: string[]
  status: string[]
  designations: string[]
  departments: string[]
}

// Status options for the status dropdown
const statusOptions = [
  { name: 'Pending', value: 'PENDING', _id: '101' },
  { name: 'Approved', value: 'APPROVED', _id: '102' },
  { name: 'Rejected', value: 'REJECTED', _id: '103' },
]

const defaultFilters = {
  page_no: 1,
  page_size: 1000,
  sortBy: 'created_on',
  sortDir: 'DESC',
}

const FilterPanel: React.FC<Props> = ({ closePanel, setFilters, filters }) => {
  // Get selected plant from Redux state
  const plant = useSelector(selectSelectedItem)

  // Fetch shift policies based on the selected plant
  const { data: shiftPolicyList } = useGetShiftPolicyQuery(
    { plantId: plant?.id, filters: defaultFilters },
    { skip: !plant?.id },
  )

  // Fetch employee department and designation data
  const { data } = useGetEmployeeDepartmentAndDesignationQuery(
    { plant_id: plant?.id },
    { skip: !plant?.id },
  )

  // Initial form values based on current filters
  const initialValues: FormValues = {
    status: filters.status?.length === 0 ? [] : filters?.status,
    shifts: filters.shifts?.length === 0 ? [] : filters?.shifts,
    dateBetween: filters?.dateBetween?.length === 0 ? [] : filters?.dateBetween,
    designations:
      filters?.designations?.length === 0 ? [] : filters?.designations,
    departments: filters?.departments?.length === 0 ? [] : filters?.departments,
  }

  // Formik setup for form handling and submission
  const filterFormik = useFormik<FormValues>({
    initialValues,
    onSubmit: (values) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        dateBetween: values.dateBetween || [],
        designations: values.designations || [],
        departments: values.departments || [],
        shifts: values.shifts || [],
        status: values.status || [],
      }))
    },
  })

  // Transform shift  data into dropdown options

  const shiftOptions = useMemo(() => {
    return shiftPolicyList?.data?.flatMap((policy: any) =>
      policy.shifts.map((shift: any) => ({
        label: shift.shiftName,
        value: shift.id,
      })),
    )
  }, [shiftPolicyList?.data])

  // Transform department and designation data into dropdown options
  const departmentsOptions = useMemo(
    () =>
      data?.department?.map((department: EmployeeDepartmentOptions) => ({
        label: department?.name,
        value: department?._id,
      })) ?? [],
    [data?.department],
  )

  const designationsOptions = useMemo(() => {
    // Filter departments based on the selected IDs
    const selectedDepartments = data?.department?.filter((dept: any) =>
      filterFormik?.values?.departments?.includes(dept._id),
    )
    const combinedDesignations = selectedDepartments?.flatMap(
      (dept: any) => dept?.designations,
    )

    return (
      combinedDesignations?.map((designation: any) => ({
        label: designation?.name,
        value: designation?._id,
      })) ?? []
    )
  }, [data?.departments, filterFormik?.values?.departments])

  const status = statusOptions.map((item) => ({
    label: item.name,
    value: item.value,
  }))

  // Handle reset functionality to clear filters
  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      status: [],
      shifts: [],
      departments: [],
      designations: [],
      dateBetween: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-grow flex-col gap-3">
        {/* Date Range Field */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Date"
          formik={filterFormik}
          name="dateBetween"
        />

        {/* Shift Field */}
        <Field
          type="multiselect"
          label="Shift"
          placeholder="Select Shift"
          options={shiftOptions}
          formik={filterFormik}
          name="shifts"
        />

        {/* Department Field */}
        <Field
          type="multiselect"
          label="Department"
          placeholder="Show All"
          options={departmentsOptions}
          formik={filterFormik}
          name="departments"
        />

        {/* Designations Field */}
        <Field
          type="multiselect"
          label="Designations"
          placeholder="Show All"
          options={designationsOptions}
          formik={filterFormik}
          name="designations"
          disabled={filterFormik?.values?.departments.length > 0 ? false : true}
        />

        {/* Status Field */}
        <Field
          type="multiselect"
          label="Approval Status"
          placeholder="Select Status"
          options={status}
          formik={filterFormik}
          name="status"
        />
      </div>

      {/* Button actions for Reset, Cancel, and Apply */}
      <div className="pt-5">
        <div className="flex justify-between">
          <Button onClick={handleReset}>Reset</Button>
          <div className="flex gap-2">
            <Button onClick={closePanel}>Cancel</Button>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterPanel
