// create a custom hook to change the browser tab title
import { t } from 'i18next'

import { useEffect } from 'react'

const useTabTitle = (title: string) => {
  useEffect(() => {
    document.title = `${title && `${title} - ` }  ${t('catalyst')}`
  }, [title])
}

export default useTabTitle
