import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  GetProductionPlansRequest,
  GetProductionPlansResponse,
  PostProductionPlansRequest,
  PostProductionPlansResponse,
} from './productionPlansTypes'

export const productionPlan = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postProductionPlan: builder.mutation<
      PostProductionPlansResponse,
      PostProductionPlansRequest
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/plan`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['productionplanUpdate'],
    }),
    editPlan: builder.mutation({
      query: ({ data, plant_id, pID }) => ({
        url: `/plant/${plant_id}/plan/${pID}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['productionplanUpdate'],
    }),

    getProductionPlan: builder.query<
      GetProductionPlansResponse,
      GetProductionPlansRequest
    >({
      query: ({ plantId, filters }) => {
        const URL = buildUrl(`plant/${plantId}/plans`, filters)
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the production plan')
            }
            return response.json()
          },
        }
      },
      providesTags: ['productionplanUpdate'],
    }),

    deleteProductionPlan: builder.mutation({
      query: ({ plant_id, pID }) => ({
        url: `/plant/${plant_id}/plan/${pID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['productionplanUpdate'],
    }),
    updatePlanStatus: builder.mutation({
      query: ({ plant_id, pID }) => ({
        url: `/plant/${plant_id}/plan/${pID}/status`,
        method: 'PUT',
        headers: getToken(),
      }),
      invalidatesTags: ['productionplanUpdate'],
    }),

    getCountPlan: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `plant/${plant_id}/plan/stats`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the count plan')
            }
            return response.json()
          },
        }
      },
      providesTags: ['productionplanUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostProductionPlanMutation,
  useGetProductionPlanQuery,
  useGetCountPlanQuery,
  useEditPlanMutation,
  useUpdatePlanStatusMutation,
  useDeleteProductionPlanMutation,
} = productionPlan
