import { useEffect, useState } from 'react'
import { getRelativeTime } from 'utils/dateTimeHandlers'
import { t } from 'i18next'

interface Props {
  isOpen: boolean
  closePanel: () => void
  data: any
}

const ViewTransactions = ({ data }: Props) => {
  const translation = localStorage.getItem('i18nextLng')
  const [formData, setFormData] = useState(data)

  useEffect(() => {
    setFormData(data)
  }, [data])

  function getFormattedDateAndUser(data: any, date: any) {
    const formattedDate = getRelativeTime(date)

    const firstName = data?.first_name
    const lastName = data?.last_name
    const fullName = `${
      translation === 'hi'
        ? `${firstName} ${lastName} ${t('by')}`
        : ` ${t('by')} ${firstName} ${lastName}`
    }`
    return `${formattedDate} ${fullName}`
  }

  return (
    <div>
      <div className="flex flex-col gap-2 rounded bg-gray-100 p-3">
        {formData?.transactionType && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Transaction Type</div>
            <div>
              <div className="w-40 break-words p-xs-regular">
                {formData?.transactionType}
              </div>
            </div>
          </div>
        )}
        {formData?.inventory.name && (
          <div className="flex justify-between">
            <div className="p-xs-regular-dim">Product Name</div>
            <div>
              <div className="w-40 p-xs-regular ">
                {formData?.inventory.name}
              </div>
            </div>
          </div>
        )}

        {formData?.quantity && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Quantity</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {formData?.quantity}
              </div>
            </div>
          </div>
        )}
        {formData?.adjustmentReason && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Adjustment Reason</div>
            <div>
              <div className="w-40 break-words p-xs-regular">
                {formData?.adjustmentReason}
              </div>
            </div>
          </div>
        )}
        {formData?.puchaseOrderNumber && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Purchase Order Number</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {formData?.puchaseOrderNumber}
              </div>
            </div>
          </div>
        )}
        {formData?.salesOrderNumber !== null && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Sales Order Number</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {formData?.salesOrderNumber}
              </div>
            </div>
          </div>
        )}
        {formData?.warehouseTo?.name && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Warehouse To</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {formData?.warehouseTo?.name}
              </div>
            </div>
          </div>
        )}
        {formData?.warehouseFrom?.name && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Warehouse From</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {formData?.warehouseFrom?.name}
              </div>
            </div>
          </div>
        )}
        {formData?.storageAreaTo?.name && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">StorageArea To</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {formData?.storageAreaTo?.name}
              </div>
            </div>
          </div>
        )}
        {formData?.storageAreaFrom?.name && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">StorageArea From</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {formData?.storageAreaFrom?.name}
              </div>
            </div>
          </div>
        )}
        {formData?.updated_by && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Updated By</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {getFormattedDateAndUser(
                  formData?.updated_by,
                  formData?.updated_on,
                )}
              </div>
            </div>
          </div>
        )}
        {formData?.created_by && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Created By</div>
            <div>
              <div className="w-40 break-words p-xs-regular ">
                {getFormattedDateAndUser(
                  formData?.created_by,
                  formData?.created_on,
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewTransactions
