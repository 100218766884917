import React from "react";

interface ViewPasswordIconProps {
  width?: number;
  height?: number;
  color?: string;
  classProp?: string;
}
const ViewPasswordIcon: React.FC<ViewPasswordIconProps> = ({
  width = 24,
  height = 24,
  color = "#75838D",
  classProp = "",
}) => {
  return (
    <svg
      className={classProp}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 17.5C8.2 17.5 4.8 15.4 3.2 12H1C2.7 16.4 7 19.5 12 19.5C17 19.5 21.3 16.4 23 12H20.8C19.2 15.4 15.8 17.5 12 17.5Z"
        fill={color}
      />
    </svg>
  );
};

export default ViewPasswordIcon;
