
function CsvIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <mask
        id="mask0_1573_11870"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_1573_11870)">
        <path
          fill="#75838D"
          d="M4.313 11.25H6a.546.546 0 00.563-.563.546.546 0 00-.563-.562H4.687v-2.25H6a.546.546 0 00.563-.563A.546.546 0 006 6.75H4.312a.726.726 0 00-.534.216.726.726 0 00-.216.534v3c0 .213.072.39.216.534a.726.726 0 00.534.216zm3.487 0h1.688c.212 0 .39-.072.534-.216a.726.726 0 00.216-.534V9.375a.931.931 0 00-.216-.59.658.658 0 00-.534-.273H8.363v-.637h1.312a.546.546 0 00.563-.563.546.546 0 00-.563-.562H7.987a.726.726 0 00-.534.216.726.726 0 00-.216.534v1.125c0 .213.072.403.216.572a.675.675 0 00.534.253h1.126v.675H7.8a.546.546 0 00-.563.563.546.546 0 00.563.562zm4.95-1.912l-.637-2.194a.594.594 0 00-.197-.282.51.51 0 00-.329-.112.5.5 0 00-.421.216.523.523 0 00-.085.478l.994 3.412a.594.594 0 00.197.281.51.51 0 00.328.113h.3a.51.51 0 00.328-.113.594.594 0 00.197-.28l.994-3.413a.523.523 0 00-.085-.478.5.5 0 00-.421-.216.51.51 0 00-.329.112.594.594 0 00-.197.282l-.637 2.194zM3 15c-.413 0-.766-.147-1.06-.44a1.445 1.445 0 01-.44-1.06v-9c0-.412.147-.766.44-1.06C2.235 3.148 2.588 3 3 3h12c.412 0 .766.147 1.06.44.293.294.44.648.44 1.06v9c0 .412-.147.766-.44 1.06-.294.293-.647.44-1.06.44H3zm0-1.5h12v-9H3v9z"
        ></path>
      </g>
    </svg>
  )
}

export default CsvIcon
