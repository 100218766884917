import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useMemo } from 'react'
import { useGetRoutingByIDQuery } from '../routings-endpoints/routings.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import FullPageLoader from 'libs/loader/FullPageLoader'

type props = {
  id: string
}

const ViewWorkCenterPanel = ({ id }: props) => {
  const plantId = useSelector(selectSelectedItem)

  const { data: routingData, isLoading: isRoutingLoading } =
    useGetRoutingByIDQuery(
      {
        plant_id: plantId?.id,
        id: id,
      },
      {
        skip: !plantId?.id || !id,
      },
    )

  const columns = useMemo(
    () => [
      {
        Header: 'Work Center Name',
        accessor: 'workCenterName',
        Cell: ({ row }: any) => {
          return <div>{row.original?.work_center?.name ?? '-'}</div>
        },
        disableSortBy: true,
      },

      {
        Header: 'Work Center ID',
        accessor: 'input_products',
        Cell: ({ row }: any) => {
          return <div>{row.original?.work_center?.work_center_id ?? '-'}</div>
        },
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <>
        {isRoutingLoading ? (
          <FullPageLoader />
        ) : (
          <div>
            <div className="bg-slate-200 my-4 p-4 rounded-lg">
              <div className="flex justify-between">
                <div className="mb-2 p-xs-regular-dim">Routing Name</div>
                <div>
                  <div className="break-words p-xs-regular w-60" data-testid="routing-name">
                    {routingData?.name ?? '-'}
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="mb-2 p-xs-regular-dim">Routing Id</div>
                <div>
                  <div className="break-words p-xs-regular w-60" data-testid="routing-id"> 
                    {routingData?.routing_id ?? '-'}
                  </div>
                </div>
              </div>
            </div>

            <PaginatedTable
              columns={columns}
              rows={routingData?.operations ?? []}
              hidePagination
            />
          </div>
        )}
      </>
    </MotionDiv>
  )
}

export default ViewWorkCenterPanel
