import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import Divider from 'libs/divider/Divider'
import Field from 'libs/field'
import MotionDiv from 'libs/motionDiv'
import NewEditableTable from 'libs/table/NewEditableTable'
import { useGetAllProcessesQuery } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import {
  useGetRoutingByIDQuery,
  usePostRoutingMutation,
  usePutRoutingMutation,
} from '../routings-endpoints/routings.endpoints'
import { toast } from 'libs/toast'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { RoutingFormikValues } from '../routings-endpoints/routingsTypes'
import useTabTitle from 'libs/hooks/useTabTitle'

const routingColumns = [
  {
    title: 'Operations',
    accessor: 'operation',
    main: true,
    FieldType: 'select',
    flex: 1,
    editable: true,
    placeholder: 'Select Operation',
  },
  {
    title: 'Work Centers',
    accessor: 'workCenters',
    flex: 1,
    placeholder: 'Select Work Center',
  },
]

type Operation = {
  id: string
  name: string
}

type DataItem = {
  operation: Operation[]
}

const AddEditRouting = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)

  //Tab Title
  useTabTitle(id ? 'Update Routing' : 'Add Routing')

  const [error, setError] = useState(false)
  const [data, setData] = useState<DataItem[]>([])

  const [addRouting, { isLoading: isAddLoading }] = usePostRoutingMutation()
  const [editRouting, { isLoading: isEditLoading }] = usePutRoutingMutation()
  // User Info
  const { data: userInfo } = useUserInfoQuery({})
  // Routing Get By ID
  const { data: routingData, isLoading: isRoutingLoading } =
    useGetRoutingByIDQuery(
      {
        plant_id: plant?.id,
        id: id,
      },
      {
        skip: !plant?.id || !id,
      },
    )
  // Operational Process
  const { data: allProcessData, isLoading: processLoading } =
    useGetAllProcessesQuery(
      {
        plantId: plant?.id,
        filters: {
          page_no: 1,
          page_size: 1000,
          searchText: '',
        },
      },
      { skip: !plant?.id },
    )

  const operationsOptions = useMemo(
    () =>
      allProcessData?.data?.map((item: any) => ({
        id: item?._id,
        label: item?.name,
        value: {
          _id: item?._id,
          name: item?.name,
          input_products: item?.input_products,
          output_products: item?.output_products,
        },
      })),
    [allProcessData?.data],
  )

  const routingFormik = useFormik<RoutingFormikValues>({
    initialValues: {
      operations: [],
      routing_id: '',
      name: '',
    },
    validationSchema: Yup.object().shape({
      routing_id: Yup.string().required('Routing ID is required'),
      name: Yup.string().required('Routing Name is required'),
    }),
    onSubmit: (values) => {
      const payload = {
        routing_id: values?.routing_id,
        name: values?.name,
        operations: values?.operations?.map((operation: any) => ({
          id: operation?.operation?._id || '', // Provide an empty string if id is not available
          name: operation?.operation?.name || '', // Provide an empty string if name is not available
          input_products: operation?.operation?.input_products?.map(
            (product: any) => ({
              name: product?.product?.name ?? product?.name,
              id: product?.product?._id ?? product?.id,
            }),
          ),
          output_products: operation?.operation?.output_products?.map(
            (product: any) => ({
              name: product?.product?.name ?? product?.name,
              id: product?.product?._id ?? product?.id,
            }),
          ),
        })),
        ...(!id && {
          plant: { id: plant?.id, name: plant?.name },
          created_by: { id: userInfo?._id, name: userInfo?.username },
          updated_by: { id: userInfo?._id, name: userInfo?.username },
        }),
      }
      if (!id && !error) {
        addRouting({ plant_id: plant?.id, data: payload })
          .unwrap()
          .then(() => {
            toast.success(
              <div>
                The Routing <b>{`"${values?.name}"`}</b> is added successfully
              </div>,
            )
            navigate(-1)
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail)
              ? error?.data?.detail[0]?.msg
              : error?.data?.detail
            toast.error(
              errorMessage ?? 'Something went wrong while creating the routing',
            )
          })
      } else if (!error) {
        editRouting({ plant_id: plant?.id, id: id, data: payload })
          .unwrap()
          .then(() => {
            toast.success(
              <div>
                The Routing <b>{`"${values?.name}"`}</b> is edited successfully
              </div>,
            )
            navigate(-1)
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail)
              ? error?.data?.detail[0]?.msg
              : error?.data?.detail
            toast.error(
              errorMessage ?? 'Something went wrong while updating the routing',
            )
          })
      }
    },
  })

  useEffect(() => {
    if (data && data[0]?.operation) {
      setError(false)
    }
  }, [data])

  useEffect(() => {
    if (routingData) {
      routingFormik.resetForm({
        values: {
          routing_id: routingData?.routing_id,
          name: routingData?.name,
          operations: routingData?.operations?.map((item: any) => ({
            operation: {
              _id: item?.id,
              name: item?.name,
              ...item,
            },
          })),
        },
      })
    }
  }, [routingData])

  const handleRouting = () => {
    if (data && !data[0]?.operation || !data) {
      setError(true)
    } else {
      routingFormik.setFieldValue('operations', data ? data : [])
    }
    routingFormik?.handleSubmit()
  }

  const defaultRows = useMemo(() => {
    return routingFormik.values?.operations?.map((operation: any) => {
      // Check if the operator is a string (i.e., the _id) or an object
      if (typeof operation === 'string') {
        return {
          operation: {
            _id: operation,
          },
        }
      } else {
        return operation
      }
    })
  }, [routingFormik?.values?.operations])

  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">
              {id ? 'Edit Routing' : 'Add Routing'}
            </h1>
          </div>
        </div>
        <WhiteCard className=" flex min-h-[80vh] flex-col justify-between gap-6 ">
          {isRoutingLoading ? (
            <FullPageLoader />
          ) : (
            <>
              <div className="h-screen-90 flex w-full flex-col justify-between px-2">
                <div className="flex-grow">
                  <div className="h5-1">Routing Details</div>
                  <p className="primary-gray-4 p-sm-regular">
                    {id
                      ? 'Modify and edit the sequence of operations for your production process.'
                      : 'Add the basic details that are required to add new Routing.'}
                  </p>
                  <div className="mb-6 mt-4 flex w-2/3 gap-2">
                    <Field
                      type="text"
                      label="Routing ID"
                      formik={routingFormik}
                      required={true}
                      name="routing_id"
                      placeholder="Enter Routing ID"
                    />
                    <Field
                      type="text"
                      label="Routing Name"
                      formik={routingFormik}
                      required={true}
                      name="name"
                      placeholder="Enter Routing Name"
                    />
                  </div>
                  <Divider />
                  <div className="mt-4">
                    <h1 className="my-2 text-xl font-medium">Set Routing</h1>
                    <NewEditableTable
                      defaultRows={defaultRows}
                      columns={routingColumns}
                      id={id}
                      errorType={error}
                      errorMessage="Error: To save the routing, you must add at least one operation. Please select an operation."
                      uniqueIdInOptions="_id"
                      options={operationsOptions || []}
                      addButtonTitle="Add Sequence"
                      onChange={(values: any) => {
                        setData(values)
                      }}
                      loading={processLoading}
                      tooltipContent="Remove Operation"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <div className="flex gap-3">
                  <Button type="button" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    color="success"
                    type="button"
                    loading={isAddLoading || isEditLoading}
                    onClick={handleRouting}
                  >
                    {id ? 'Save' : 'Add'}
                  </Button>
                </div>
              </div>
            </>
          )}
        </WhiteCard>
      </>
    </MotionDiv>
  )
}

export default AddEditRouting
