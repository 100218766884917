import { useState } from 'react'
import { getAuthTokenAndRole } from 'utils/auth'
import { LogoutIcon } from 'assets/icons'
import LogoutModal from 'libs/layouts/auth/login/modal/LogoutModal'

const ProfileDetail = () => {
  const [showLogout, setShowLogout] = useState(false)
  const [isLogoutHovered, setIsLogoutHovered] = useState(false)
  const { role, username } = getAuthTokenAndRole()
  const firstName = username

  return (
    <div>
      <div
        className="absolute right-[-23.5rem] top-6 flex h-auto w-60 flex-col rounded-lg bg-[#fff] p-3"
        style={{ boxShadow: '0px 8px 16px 0px #00000026' }}
      >
        <div className="mb-1 flex items-center">
          <div className="inline-flex h-7 w-7  items-center justify-center gap-2  rounded-3xl border border-[#1193F0] bg-[#F8FCFF] p-2">
            <div className="text-center text-xs">
              {firstName ? firstName.charAt(0).toUpperCase() : ''}
            </div>
          </div>

          <div className="flex-col px-3 py-2">
            <div className="p-xs-regular">{firstName}</div>
            <div className="text-[10px]">
              {role ? role?.charAt(0).toUpperCase() + role.slice(1) : ''}
            </div>
          </div>
        </div>
        <div className="ml-2 flex items-center">
          <LogoutModal showLogout={showLogout} setShowLogout={setShowLogout} />
          <div
            className="flex cursor-pointer items-center gap-3"
            onClick={() => setShowLogout(true)}
            onMouseOver={() => setIsLogoutHovered(true)}
            onMouseOut={() => setIsLogoutHovered(false)}
          >
            <LogoutIcon
              classProp={`mx-auto`}
              color={isLogoutHovered ? '#E26868' : '#434C52'}
            />
            <p
              className={`text-xs ${isLogoutHovered ? 'text-[#E26868]' : 'text-[#434C52]'}`}
            >
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileDetail
