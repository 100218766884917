import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import { useGetPlantsQuery } from 'pages/plants/plants-endpoints/plants.endpoints'
import * as Yup from 'yup'
import { useUserInfoQuery } from '../../users/user-endpoints/user.endpoint'
interface Props {
  closePanel: () => void
  setFilters: any
  filters: any
}
// const defaultFilter = {
//   page_no: 1,
//   page_size: 10,
//   searchText: '',
//   sortBy: 'created_on',
//   sortDir: 'DESC',
// };

const FilterUserRoles: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const { data: userInfo } = useUserInfoQuery({})
 
  const filter = {
    page_no: 1,
    page_size: 1000,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
  }

  const { data: plants } = useGetPlantsQuery(
    {
      org_id: userInfo?.organization?._id,
      filters: filter,
    },
    {
      skip: !userInfo?.organization?._id,
    },
  )
  const filterFormik = useFormik({
    initialValues: {
     
      plants: filters?.plants?.length == 0 ? [] : filters?.plants,
      lastUpdated: filters?.updatedBetween ? filters.updatedBetween : [],
    },
    onSubmit: (values) => {
      setFilters({
        ...filters,
        plants: values.plants,
        updatedBetween: values.lastUpdated,
      })
    },
    validationSchema: Yup.object({}),
  })

 
  const plantsOptions =
    plants?.data?.map((plant: any) => ({
      label: plant?.name,
      value: plant?._id,
    })) ?? []
  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      updatedBetween: [],
      plants: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }
  return (
    <>
      <div className="flex flex-col gap-3 relative h-full">
        <Field
          type="multiselect"
          label="Applicable Plant"
          formik={filterFormik}
          name="plants"
          options={plantsOptions}
          placeholder="Show All"
        />
        <Field type="dateRange" label="Last Updated" placeholder="select" formik={filterFormik} shouldDisableFutureDate name="lastUpdated" />
          <div className="flex justify-between absolute bottom-0 w-full">
            <Button
              onClick={() => {
                handleReset()
              }}
            >
              Reset Filters
            </Button>
            <div className="flex justify-end gap-2">
              <Button onClick={closePanel}>Cancel</Button>
              <Button
                color="primary"
                onClick={() => {
                  filterFormik.handleSubmit()
                  closePanel()
                }}
              >
                Apply
              </Button>
            </div>
          </div>
      </div>
    </>
  )
}

export default FilterUserRoles