import Button from 'libs/button/Button'
import Field from 'libs/field'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import NewEditableTable from 'libs/table/NewEditableTable'
import { FormikProps, useFormik } from 'formik'
import * as yup from 'yup'
import { formatString } from 'utils/stringHandlers'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'
import { toast } from 'libs/toast'
import { EMAIL_REGEX } from 'utils/constants'
import {
  BasicDetailsFormik,
  VendorFormik,
} from '../../vendor-endpoints/vendorTypes'
import {
  CATEGORY_OPTIONS,
  VENDOR_TYPE_OPTIONS,
} from '../../vendor-endpoints/vendor.constants'

interface BasicDetailsProps {
  vendorFormik: FormikProps<VendorFormik>
}

const vendorsColumns = [
  {
    title: 'First Name',
    accessor: 'firstName',
    editable: true,
    placeholder: 'Enter first name',
    require: true,
  },
  {
    title: 'Last Name',
    accessor: 'lastName',
    editable: true,
    placeholder: 'Enter last name',
  },
  {
    title: 'Contact Number',
    accessor: 'contactNumber',
    editable: true,
    fieldType: 'number',
    placeholder: 'Enter contact number',
  },
  {
    title: 'Email Address',
    accessor: 'emailAddress',
    editable: true,
    placeholder: 'Enter email address',
  },
  {
    title: 'Remarks',
    accessor: 'remarks',
    editable: true,
    placeholder: '-',
  },
]

const BasicDetails: React.FC<BasicDetailsProps> = ({ vendorFormik }) => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const { id } = useParams()
  const basicDetailsFormik = useFormik<BasicDetailsFormik>({
    initialValues: {
      vendorType: '',
      category: '',
      name: '',
      businessEmailAddress: '',
      primaryContactNumber: '',
      secondaryContactNumber: '',
      pointOfContacts: [],
    },

    validationSchema: yup.object({
      vendorType: yup.string().required('Vendor Type is required'),
      category: yup.string().required('Material Type is required'),
      name: yup.string().required('Vendor Name is required'),
      primaryContactNumber: yup
        .string()
        .required('Primary Contact Number is required')
        .test(
          'valid-phone',
          'Please enter a phone number for the selected country.',
          phoneNumberValidator,
        ),
      secondaryContactNumber: yup
        .string()
        .nullable() // Allows the field to be null or undefined
        .test(
          'valid-phone',
          'Please enter a phone number for the selected country.',
          function (value) {
            // Perform validation only if value is not null or undefined
            if (value) {
              return phoneNumberValidator(value)
            }
            return true // Skip validation if value is null or undefined
          },
        ),
      businessEmailAddress: yup
        .string()
        .optional() // Makes the field optional (it can be empty)
        .email('Invalid email format') // Standard email validation
        .matches(
          EMAIL_REGEX,
          'Email must be a valid email (e.g., abc@gmail.com)',
        ),
    }),
    onSubmit: (values) => {
      // Check if any contact has an empty field
      const poc = values.pointOfContacts.every(
        (contact) =>
          Object.keys(contact).length === 1 &&
          Object.prototype.hasOwnProperty.call(contact, '_id'),
      )

      let hasEmptyField = false
      let hasInvalidEmail = false
      // Checking only if any input field is filled
      !poc &&
        values?.pointOfContacts?.forEach((fields) => {
          if (
            !fields.firstName ||
            !fields.emailAddress ||
            !fields.contactNumber
          ) {
            hasEmptyField = true
          }

          if (fields.emailAddress && !EMAIL_REGEX.test(fields.emailAddress)) {
            hasInvalidEmail = true
          }
          if (hasEmptyField || hasInvalidEmail) {
            return
          }
        })
      if (hasEmptyField) {
        toast.error(
          'First Name, Contact Number, and Email Address are required',
        )
      } else if (hasInvalidEmail) {
        toast.error('Please enter a valid email address for all contacts')
      } else {
        const newValues: BasicDetailsFormik = {
          category: values?.category,
          vendorType: values?.vendorType,
          name: values?.name,
          businessEmailAddress: values?.businessEmailAddress,
          primaryContactNumber: values?.primaryContactNumber.toString(),
          secondaryContactNumber: values?.secondaryContactNumber
            ? values?.secondaryContactNumber.toString()
            : null,
          pointOfContacts: values.pointOfContacts.every(
            (contact) =>
              Object.keys(contact).length === 1 &&
              Object.prototype.hasOwnProperty.call(contact, '_id'),
          )
            ? []
            : values.pointOfContacts.map((contact) => ({
                ...contact,
                contactNumber: contact.contactNumber?.toString(),
              })),
        }
        vendorFormik.setFieldValue('BasicDetails', newValues)
        if (!id) {
          navigate('../address-details')
        } else {
          navigate(`/contacts/vendor-management/edit/${id}/address-details`)
        }
      }
    },
  })

  const submitBasicDetails = () => {
    const filteredData = data?.filter((contact) =>
      Object?.values(contact)?.some((value) => value !== ''),
    )
    basicDetailsFormik?.setFieldValue(
      'pointOfContacts',
      filteredData?.length > 0 ? filteredData : [],
    )
    basicDetailsFormik.handleSubmit()
  }

  useEffect(() => {
    basicDetailsFormik.resetForm({
      values: {
        vendorType: vendorFormik.values?.BasicDetails?.vendorType || '',
        category: vendorFormik.values?.BasicDetails?.category || '',
        name: vendorFormik.values?.BasicDetails?.name || '',
        businessEmailAddress:
          vendorFormik.values?.BasicDetails?.businessEmailAddress || '',
        primaryContactNumber:
          vendorFormik.values?.BasicDetails?.primaryContactNumber || '',
        secondaryContactNumber:
          vendorFormik.values?.BasicDetails?.secondaryContactNumber || '',
        pointOfContacts:
          vendorFormik.values?.BasicDetails?.pointOfContacts || [],
      },
    })
  }, [vendorFormik])

  return (
    <div className="flex h-full flex-col justify-between ">
      <div className="flex flex-col gap-4">
        <span>
          <h4 className="h4">Basic Details</h4>

          <p className="text-sm text-primary-gray-4">
            {id
              ? 'Edit the existing Vendor details to make necessary changes and updates.'
              : 'Add the basic details that are required to Add New Vendor.'}
          </p>
        </span>
        <div className="grid w-3/4 grid-cols-2 gap-4">
          <Field
            label="Vendor Type"
            placeholder="Select"
            formik={basicDetailsFormik}
            options={VENDOR_TYPE_OPTIONS}
            name="vendorType"
            type="select"
            defaultValue={{
              label: formatString(basicDetailsFormik?.values?.vendorType) ?? '',
            }}
            required
          />

          <Field
            label="Category"
            placeholder="Select"
            formik={basicDetailsFormik}
            options={CATEGORY_OPTIONS}
            name="category"
            type="select"
            defaultValue={{
              label: formatString(basicDetailsFormik?.values?.category) ?? '',
            }}
            required
          />
        </div>
        <p className="border-b border-border-medium pb-2" />
        <div className="grid w-3/4 grid-cols-2 gap-4">
          <Field
            label="Vendor Name"
            placeholder="Enter Vendor Name"
            formik={basicDetailsFormik}
            name="name"
            type="text"
            required
          />

          <Field
            label="Business Email Address"
            placeholder="Enter Business Email Address"
            formik={basicDetailsFormik}
            name="businessEmailAddress"
            type="text"
          />
          <Field
            label="Primary Contact Number"
            placeholder="Enter Contact Number"
            formik={basicDetailsFormik}
            name="primaryContactNumber"
            type="phonenumber"
            required
          />

          <Field
            label="Secondary Contact Number"
            placeholder="Enter Contact Number"
            formik={basicDetailsFormik}
            name="secondaryContactNumber"
            type="phonenumber"
          />
        </div>{' '}
      </div>
      <hr></hr>
      <div className="flex flex-col gap-2">
        <h5 className="h5">Point Of Contacts</h5>
        <NewEditableTable
          defaultRows={basicDetailsFormik?.values?.pointOfContacts}
          columns={vendorsColumns}
          onChange={(values) => {
            setData(values)
          }}
          addButtonTitle="Add New Contact"
        />
      </div>
      <div className="flex justify-end">
        <span className="flex gap-3">
          {' '}
          <Button onClick={() => navigate('/contacts/vendor-management')}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => {
              submitBasicDetails()
            }}
          >
            Next
          </Button>
        </span>
      </div>
    </div>
  )
}

export default BasicDetails
