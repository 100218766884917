/* eslint-disable react/prop-types */
import Field from 'libs/field'
import { useFormik } from 'formik'
import { useGetManufactureOrderByIdQuery } from '../manufacture-orders-endpoints/manufacture_order.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { getTableConfig } from '../columns/ManufactureOrderColumns'
import { t } from 'i18next'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'

import { useEffect } from 'react'
import { formatString } from 'utils/stringHandlers'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useLocation } from 'react-router-dom'
import { useGetProductQuery } from 'pages/products/products-endpoints/products.endpoints'
import { useGetProductionPlanQuery } from 'pages/planning/production-plans/production-plans-endpoints/productionPlan.endpoints'

const BasicDetails = ({
  setId,
  moid,
  setPage,
  manufactureOrderFormik,
  loading,
}: any) => {
  const { id } = useParams()
  const location = useLocation()
  const isEdit = location.pathname.includes('edit')

  const { filters, options: optionsUnfiltered } = getTableConfig()
  let options = optionsUnfiltered
  if (!isEdit) {
    options = optionsUnfiltered.filter(
      (option: any) => option.value != 'CANCELLED',
    )
  }

  interface BasicDetails {
    should_is_draft: boolean
    manufactureOrderID: string
    plan_id: string
    startDate: Date | null
    endDate: Date | null
    finishedGood: string | null
    status: string
    quantity: number
  }
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()

  const productFilters = {
    page_no: 1,
    page_size: 1000,
    sortBy: 'created_on',
    sortDir: 'DESC',
    searchText: '',
  }

  useEffect(() => {
    setId({ id: id })
    setPage(1)
  }, [])

  const { data: planData } = useGetProductionPlanQuery({
    plantId: plant?.id,
    filters: filters,
  })

  const { data: products } = useGetProductQuery({
    plantId: plant?.id,
    filters: productFilters,
  })

  const { isLoading: MoLoading } = useGetManufactureOrderByIdQuery(
    {
      plant_id: plant?.id,
      moID: moid?.id,
    },
    {
      skip: !plant?.id || !moid?.id,
      refetchOnMountOrArgChange: true,
    },
  )

  const basicDetailsFormik = useFormik<BasicDetails>({
    initialValues: {
      should_is_draft: false,
      manufactureOrderID: manufactureOrderFormik?.manufactureOrderID || '',
      plan_id: manufactureOrderFormik?.plan_id,
      startDate: manufactureOrderFormik?.startDate || null,
      endDate: manufactureOrderFormik?.endDate || null,
      finishedGood: manufactureOrderFormik?.finishedGood || null,
      status: manufactureOrderFormik?.status,
      quantity: manufactureOrderFormik?.quantity,
    },

    onSubmit: (values) => {
      if (values.should_is_draft) {
        manufactureOrderFormik.setFieldValue('isDraft', true)
        manufactureOrderFormik.handleSubmit()
      } else {
        manufactureOrderFormik.handleSubmit()
      }
    },
  })

  const planOptions = planData?.data?.map((item: { planID: any }) => ({
    label: item.planID,
    value: item.planID,
  }))

  const productOptions = products?.data
    ?.filter((item: any) => item.category === 'Finished Goods')
    .map((item: any) => ({
      label: item.name,
      value: item,
    }))

  const planId = { label: manufactureOrderFormik?.values?.plan_id ?? '' }
  const StatusValue = {
    label: formatString(manufactureOrderFormik?.values?.status) ?? '',
  }
  const defaultProduct = {
    label: manufactureOrderFormik?.values?.finishedGood?.name ?? '',
  }

  return !MoLoading ? (
    <div className="flex min-h-[70vh] flex-col">
      <div className="flex flex-grow flex-col gap-3">
        <div>
          <h5 className="h5-1">{'Basic Details'}</h5>
          <p className="p-sm-regular-2">
            {isEdit
              ? 'Edit the existing Manufacturing Order details to make necessary changes and updates.'
              : 'Add the basic details that are required to create new Manufacturing Order.'}
          </p>
        </div>
        <div className="grid max-w-[1200px] grid-cols-1 gap-3 md:grid-cols-2">
          <Field
            required={true}
            label={'Manufacturing Order ID'}
            formik={manufactureOrderFormik}
            name="manufactureOrderID"
            placeholder={'Enter ID'}
          />
          <Field
            type="select"
            label={'Plan ID'}
            formik={manufactureOrderFormik}
            name="plan_id"
            placeholder={'Enter ID'}
            defaultValue={planId}
            options={planOptions}
          />
          <Field
            type="select"
            required={true}
            label={'Finished Good'}
            formik={manufactureOrderFormik}
            name="finishedGood"
            placeholder={'Select'}
            defaultValue={defaultProduct}
            options={productOptions}
          />
          <Field
            type="number"
            required={true}
            label={'Quantity'}
            formik={manufactureOrderFormik}
            name="quantity"
            placeholder={'Enter Quantity'}
            suffix={
              manufactureOrderFormik?.values?.finishedGood?.unit_of_measure
            }
          />
          <Field
            type="date"
            label={'Start Date'}
            formik={manufactureOrderFormik}
            name="startDate"
            required
          />
          <Field
            type="date"
            label={'End Date'}
            formik={manufactureOrderFormik}
            name="endDate"
            required
          />
          <Field
            type="select"
            required={true}
            label={'Status'}
            formik={manufactureOrderFormik}
            name="status"
            placeholder={'Select'}
            defaultValue={StatusValue}
            options={options}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-between">
        <div className="flex gap-3">
          <Button type="button" onClick={() => navigate('/planning/mo')}>
            {t('cancel')}
          </Button>
          {false &&
            (!isEdit || (isEdit && manufactureOrderFormik.values.isDraft)) && (
              <Button
                loading={
                  manufactureOrderFormik.values.isDraft ? loading : false
                }
                color="primary"
                type="submit"
                onClick={() => {
                  basicDetailsFormik.setFieldValue('should_is_draft', true)
                  basicDetailsFormik.handleSubmit()
                }}
              >
                {isEdit
                  ? t('plannings.update_draft')
                  : t('plannings.save_as_draft')}
              </Button>
            )}
        </div>
        <div>
          <Button
            loading={!manufactureOrderFormik.values.isDraft ? loading : false}
            color="success"
            type="submit"
            onClick={() => {
              basicDetailsFormik.handleSubmit()
            }}
          >
            {t('next')}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <FullPageLoader />
  )
}

export default BasicDetails
