import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useEffect, useState } from 'react'
import { FieldProps } from 'libs/field'
import './index.css'

export interface PhoneNumberProps extends FieldProps {
  restProps: any
  className?: string
}

const PhoneNumberInput = (props: PhoneNumberProps) => {
  const { formik, name, placeholder } = props

  const [value, setValue] = useState<string>(formik.values[name])

  const handleChange = (value: any) => {
    setValue(value)
    formik.setFieldValue(name, value)
  }
  useEffect(() => {
    setValue(formik.values[name])
  }, [formik])

  return (
    <PhoneInput
      country="in"
      placeholder={placeholder}
      value={value}
      onChange={(value) => handleChange(value)}
      containerClass={props.className}
      inputProps={{
        name: 'phone',
        id: name,
        autoFocus: false,
      }}
      countryCodeEditable={true}
    />
  )
}

export default PhoneNumberInput
