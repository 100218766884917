import Button from "libs/button/Button"

function DeleteBom({ toggleDelete, deleteData,isLoading }: any) {
  return (
    <div>
      <div>
       
        <p className="p-xs-regular">
          Are you sure you want to delete? Once removed, this entry{' '}
          <span className="p-xs-bold">cannot be recovered</span> . However you can create new entries for making the
          adjustments.
        </p>
        <div className="mt-4 flex justify-end gap-2">
          <Button onClick={toggleDelete}>
            Close
          </Button>
          <Button color="error"  loading={isLoading} onClick={()=>deleteData()}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DeleteBom
