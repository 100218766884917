import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './services/root.service'
import navSlice from './redux/navSlice'
import productionPlanning from './redux/productionPlanning'
import userSlice from './redux/userSlice'

import { isRejectedWithValue } from '@reduxjs/toolkit'
import { handleLogout } from 'libs/layouts/auth/login/modal/LogoutModal'
import { countryApiSlice } from './services/country'

const logoutOn404Middleware = () => (next: any) => (action: any) => {
  if (isRejectedWithValue(action)) {
    if (
      action.payload.status === 401 &&
      !window.location.href.includes('auth')
    ) {
      handleLogout()
    }
  }
  return next(action)
}

const store = configureStore({
  reducer: {
    [rootReducer.reducerPath]: rootReducer.reducer,
    [countryApiSlice.reducerPath]: countryApiSlice.reducer,
    user: userSlice,
    nav: navSlice,
    plan: productionPlanning,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      logoutOn404Middleware,
      rootReducer.middleware,
      countryApiSlice.middleware,
    ),
})

export default store
