import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'libs/toast'
import { selectSelectedItem } from 'store/redux/navSlice'
import BasicDetails from './tabs/BasicDetail'
import AddressDetails from './tabs/AddressDetails'
import {
  useGetVendorByIdQuery,
  useCreateVendorMutation,
  useUpdateVendorMutation,
} from '../vendor-endpoints/vendor.endpoints'
import Wizard from 'libs/wizard/Wizard'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { VendorFormik, VendorPayload } from '../vendor-endpoints/vendorTypes'

const AddEditVendor = () => {
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()

  const [addVendor, { isLoading: addVendorLoading }] = useCreateVendorMutation()
  const { data: vendor, isLoading: vendorsLoading } = useGetVendorByIdQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  const [updateVendor, { isLoading: updateVendorLoading }] =
    useUpdateVendorMutation()

  const vendorFormik = useFormik<VendorFormik>({
    initialValues: {
      BasicDetails: {
        name: '',
        vendorType: '',
        businessEmailAddress: '',
        primaryContactNumber: '',
        secondaryContactNumber: '',
        category: '',
        pointOfContacts: [],
      },
      AddressDetails: {
        addresses: [],
      },
    },
    onSubmit: (values) => {
      const payload: VendorPayload = {
        ...values?.BasicDetails,
        ...values?.AddressDetails,
      }

      if (!id) {
        addVendor({ plant_id: plant.id, data: payload })
          .unwrap()
          .then((res) => {
            navigate('/contacts/vendor-management')
            toast.success(res)
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail)
              ? error?.data?.detail[0]?.msg
              : error?.data?.detail
            toast.error(
              errorMessage ??
                'Failed to add the Vendor ' +
                  payload.name +
                  ' Please try again.',
            )
          })
      } else {
        updateVendor({
          plant_id: plant.id,
          id: id,
          data: payload,
        })
          .unwrap()
          .then((res) => {
            navigate('/contacts/vendor-management')
            toast.success(res)
          })
          .catch((error) => {
            const errorMessage = Array.isArray(error?.data?.detail)
              ? error?.data?.detail[0]?.msg
              : error?.data?.detail
            toast.error(
              errorMessage ??
                'Failed to edit the Vendor ' +
                  payload.name +
                  ' Please try again.',
            )
          })
      }
    },
  })

  useEffect(() => {
    if (vendor) {
      vendorFormik.resetForm({
        values: {
          BasicDetails: {
            name: vendor?.name,
            vendorType: vendor?.vendorType,
            businessEmailAddress: vendor?.businessEmailAddress,
            primaryContactNumber: vendor?.primaryContactNumber,
            secondaryContactNumber: vendor?.secondaryContactNumber,
            category: vendor?.category,
            pointOfContacts: vendor?.pointOfContacts,
          },
          AddressDetails: {
            addresses: vendor?.addresses,
          },
        },
      })
    }
  }, [vendor])

  const tabs = [
    {
      tabTitle: 'Basic Details',
      routeName: 'basic-details',
      component: <BasicDetails vendorFormik={vendorFormik} />,
    },
    {
      tabTitle: 'Address Details',
      routeName: 'address-details',
      component: (
        <AddressDetails
          vendorFormik={vendorFormik}
          loading={addVendorLoading || updateVendorLoading}
        />
      ),
    },
  ]

  if (vendorsLoading) {
    return <FullPageLoader />
  }

  return (
    <div className="flex flex-col gap-2">
      <h4 className="h4-bold-black">
        {id ? 'Edit Vendor Management' : 'Add New Vendor'}
      </h4>
      <Wizard tabs={tabs} type="steps" shouldAllowFreeNavigation={!!id} />
    </div>
  )
}

export default AddEditVendor
