export enum ADDRESS_TYPE {
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING',
  BOTH = 'BOTH',
}

export const ADDRESS_TYPE_OPTIONS = [
  {
    id: 'b',
    value: ADDRESS_TYPE.BILLING,
    label: 'Billing',
  },
  {
    id: 's',
    value: ADDRESS_TYPE.SHIPPING,
    label: 'Shipping',
  },
  {
    id: 'bt',
    value: ADDRESS_TYPE.BOTH,
    label: 'Both',
  },
]
