function Inventory({ color }: { color: any }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask
        id="mask0_1311_38752"
        style={{ maskType: 'alpha' }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_1311_38752)">
        <path
          fill={color ? `${color}` : '#75838D'}
          d="M4.167 18.333c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 01-.49-1.176V7.27a1.83 1.83 0 01-.604-.594 1.552 1.552 0 01-.23-.844v-2.5c0-.458.164-.85.49-1.177.327-.326.719-.49 1.177-.49h13.334c.458 0 .85.164 1.177.49.326.327.49.719.49 1.177v2.5c0 .32-.077.601-.23.844a1.83 1.83 0 01-.604.594v9.396c0 .458-.163.85-.49 1.177-.326.326-.718.49-1.177.49H4.167zm0-10.833v9.167h11.666V7.5H4.167zm-.834-1.667h13.334v-2.5H3.333v2.5zm5 5.834h3.334c.236 0 .434-.08.593-.24.16-.16.24-.358.24-.594a.806.806 0 00-.24-.593.806.806 0 00-.593-.24H8.333a.806.806 0 00-.593.24.806.806 0 00-.24.593c0 .236.08.434.24.594.16.16.357.24.593.24z"
        ></path>
      </g>
    </svg>
  )
}

export default Inventory
