const Employee = ({ color }: { color?: string }) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.125 11.875L10.0208 8.97913C10.1875 8.81246 10.3889 8.72913 10.625 8.72913C10.8611 8.72913 11.0625 8.81246 11.2292 8.97913C11.3958 9.14579 11.4792 9.34718 11.4792 9.58329C11.4792 9.8194 11.3958 10.0208 11.2292 10.1875L7.70833 13.7083C7.54167 13.875 7.34722 13.9583 7.125 13.9583C6.90278 13.9583 6.70833 13.875 6.54167 13.7083L4.77083 11.9375C4.60417 11.7708 4.52083 11.5694 4.52083 11.3333C4.52083 11.0972 4.60417 10.8958 4.77083 10.7291C4.9375 10.5625 5.13889 10.4791 5.375 10.4791C5.61111 10.4791 5.8125 10.5625 5.97917 10.7291L7.125 11.875ZM2.16667 17.3333C1.70833 17.3333 1.31597 17.1701 0.989583 16.8437C0.663194 16.5173 0.5 16.125 0.5 15.6666V3.99996C0.5 3.54163 0.663194 3.14927 0.989583 2.82288C1.31597 2.49649 1.70833 2.33329 2.16667 2.33329H3V1.49996C3 1.26385 3.07986 1.06593 3.23958 0.906209C3.39931 0.746487 3.59722 0.666626 3.83333 0.666626C4.06944 0.666626 4.26736 0.746487 4.42708 0.906209C4.58681 1.06593 4.66667 1.26385 4.66667 1.49996V2.33329H11.3333V1.49996C11.3333 1.26385 11.4132 1.06593 11.5729 0.906209C11.7326 0.746487 11.9306 0.666626 12.1667 0.666626C12.4028 0.666626 12.6007 0.746487 12.7604 0.906209C12.9201 1.06593 13 1.26385 13 1.49996V2.33329H13.8333C14.2917 2.33329 14.684 2.49649 15.0104 2.82288C15.3368 3.14927 15.5 3.54163 15.5 3.99996V15.6666C15.5 16.125 15.3368 16.5173 15.0104 16.8437C14.684 17.1701 14.2917 17.3333 13.8333 17.3333H2.16667ZM2.16667 15.6666H13.8333V7.33329H2.16667V15.6666ZM2.16667 5.66663H13.8333V3.99996H2.16667V5.66663Z"
        fill={color || '#1193F0'}
      />
    </svg>
  )
}

export default Employee
