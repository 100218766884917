function PrintIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <mask
        id="mask0_1573_11891"
        style={{ maskType: 'alpha' }}
        width="18"
        height="18"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H18V18H0z"></path>
      </mask>
      <g mask="url(#mask0_1573_11891)">
        <path
          fill="#75838D"
          d="M12 6V3.75H6V6H4.5V3c0-.212.072-.39.216-.534a.726.726 0 01.534-.216h7.5c.213 0 .39.072.534.216A.726.726 0 0113.5 3v3H12zm1.5 3.375c.213 0 .39-.072.534-.216a.726.726 0 00.216-.534.726.726 0 00-.216-.534.726.726 0 00-.534-.216.726.726 0 00-.534.216.726.726 0 00-.216.534c0 .213.072.39.216.534a.726.726 0 00.534.216zM6 14.25h6v-3H6v3zm0 1.5c-.412 0-.766-.147-1.06-.44a1.445 1.445 0 01-.44-1.06v-1.5H2.25a.726.726 0 01-.534-.216A.726.726 0 011.5 12V8.25c0-.638.219-1.172.656-1.603A2.186 2.186 0 013.75 6h10.5c.637 0 1.172.216 1.603.647.431.431.647.965.647 1.603V12c0 .213-.072.39-.216.534a.726.726 0 01-.534.216H13.5v1.5c0 .412-.147.766-.44 1.06-.294.293-.648.44-1.06.44H6zm9-4.5v-3a.726.726 0 00-.216-.534.726.726 0 00-.534-.216H3.75a.726.726 0 00-.534.216A.726.726 0 003 8.25v3h1.5v-1.5h9v1.5H15z"
        ></path>
      </g>
    </svg>
  )
}

export default PrintIcon
