import React from 'react'

interface LogoutIconProps {
  width?: number
  height?: number
  color?: string
  classProp?: string
}

const LogoutIcon: React.FC<LogoutIconProps> = ({ width = 20, height = 20, color = '#434C52', classProp = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={classProp}
    >
      <path
        d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H9.16667C9.6269 2.5 10 2.8731 10 3.33333C10 3.79357 9.6269 4.16667 9.16667 4.16667H4.16667V15.8333H9.16667C9.6269 15.8333 10 16.2064 10 16.6667C10 17.1269 9.6269 17.5 9.16667 17.5H4.16667ZM13.9377 13.5623C13.6065 13.8935 13.0675 13.8863 12.7452 13.5465C12.4348 13.2192 12.4417 12.7042 12.7606 12.3852L14.3125 10.8333H8.33333C7.8731 10.8333 7.5 10.4602 7.5 10C7.5 9.53976 7.8731 9.16667 8.33333 9.16667H14.3125L12.7606 7.61479C12.4417 7.29583 12.4348 6.78085 12.7452 6.45353C13.0675 6.1137 13.6065 6.10655 13.9377 6.43771L17.5 10L13.9377 13.5623Z"
        fill={color}
      />
    </svg>
  )
}

export default LogoutIcon
