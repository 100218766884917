import Button from 'libs/button/Button'
import PaginatedTable from 'libs/table/PaginatedTable'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetRoutingByIDQuery } from '../routings-endpoints/routings.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { getRelativeTime } from 'utils/dateTimeHandlers'
import FullPageLoader from 'libs/loader/FullPageLoader'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useState } from 'react'
import Popup from 'libs/popup/Popup'
import DeleteRouting from '../popup/DeleteRouting'

const ViewRouting = () => {
  const { id } = useParams()
  useTabTitle('View Routing')
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const { data: routingData, isLoading: isRoutingLoading } =
    useGetRoutingByIDQuery(
      {
        plant_id: plantId?.id,
        id: id,
      },
      {
        skip: !plantId?.id || !id,
      },
    )
  const [deletePopUp, setDeletePopUp] = useState<any>({
    isPopUpOpen: false,
    data: null,
  })

  const handleDelete = () => {
    setDeletePopUp({ isPopUpOpen: true, data: routingData })
  }

  const handleEdit = () => {
    navigate(`/settings/configure/routings/edit/${id}`)
  }
  const columns = [
    {
      Header: 'Sequence',
      accessor: 'sequence',
      disableSortBy: true,
      width: 10,
      Cell: ({ row }: any) => {
        return <div>{String(row.index + 1).padStart(2, '0') ?? '-'}</div>
      },
    },
    {
      Header: 'Operations',
      accessor: 'operations',
      disableSortBy: true,
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <div>
            <span>{row?.original?.name ?? '-'}</span>
          </div>
        )
      },
    },
    {
      Header: 'Work Centers',
      accessor: 'workcenter',
      disableSortBy: true,
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <div className="flex w-full justify-between">
            <span>{row?.original?.work_center?.name ?? '-'}</span>
          </div>
        )
      },
    },
  ]
  const lastUpdated = routingData?.updated_on
    ? getRelativeTime(routingData?.updated_on)
    : '-'

  return (
    <>
      <MotionDiv>
        <WhiteCard className=" flex min-h-[70vh] flex-col gap-6">
          {isRoutingLoading ? (
            <FullPageLoader />
          ) : (
            <div className="p-2">
              <div className="flex w-full justify-between">
                <h5 data-testid="routing-header" className="h5">
                  {routingData?.name ?? '-'}
                </h5>
                <div className="flex gap-2">
                  <Button color="error" onClick={handleDelete}>
                    Delete Routing
                  </Button>
                  <Button color="primary" onClick={handleEdit}>
                    Edit Routing
                  </Button>
                </div>
              </div>
              <div className="grid grid-cols-[150px,1fr] gap-4 py-6 text-xs">
                <span className="text-gray-600">Routing ID</span>
                <span data-testid="routing-id">
                  {routingData?.routing_id ?? '-'}
                </span>
                <span className="text-gray-600">Routing name</span>
                <span data-testid="routing-name">
                  {routingData?.name ?? '-'}
                </span>
                <span className="text-gray-600">Last Updated</span>
                <span data-testid="last-updated">{lastUpdated}</span>
                <span className="text-gray-600">Updated by</span>
                <span data-testid="updated-by">
                  {routingData?.updated_by?.name ?? '-'}
                </span>
              </div>
              <div className="min-h-[50vh] overflow-y-auto">
                <PaginatedTable
                  columns={columns}
                  loading={false}
                  rows={routingData?.operations}
                  hidePagination={true}
                  emptyMessage={'No routings found. Please add new routings'}
                />
              </div>
            </div>
          )}
          {deletePopUp.isPopUpOpen && (
            <Popup
              isOpen={deletePopUp.isPopUpOpen}
              title="Delete Routing"
              onClose={() => {
                setDeletePopUp({ isPopUpOpen: false, data: null })
              }}
            >
              <DeleteRouting
                closePopUp={() =>
                  setDeletePopUp({ isPopUpOpen: false, data: null })
                }
                data={deletePopUp.data}
              />
            </Popup>
          )}
        </WhiteCard>
      </MotionDiv>
    </>
  )
}

export default ViewRouting
