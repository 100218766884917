import { useEffect, useState } from 'react'
import { t } from 'i18next'
const userLocale = navigator.language
const userTimeZone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

/**
 *
 * @param unixTimestamp
 * @returns string like : 11:38 AM or '-' if unixTimestamp is undefined
 */
export const formatUnixTime = (
  unixTimestamp: number | undefined,
  timezone?: string,
) => {
  if (!unixTimestamp || typeof unixTimestamp !== 'number') return '-'

  const date = new Date(unixTimestamp * 1000)
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timezone ?? userTimeZone,
  }
  const formattedTime = new Intl.DateTimeFormat(userLocale, options).format(
    date,
  )
  return formattedTime.toUpperCase()
}

/**
 *
 * @param unixTimestamp
 * @returns string like : MM-DD-YYY or '-' if unixTimestamp is undefined
 */
export const formatUnixDate = (
  unixTimestamp: number | undefined,
  timezone?: string,
) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  if (isNaN(date.getTime())) return ''
  const formattedDate = new Intl.DateTimeFormat(userLocale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: timezone ?? userTimeZone,
  }).format(date)
  return formattedDate.replace(/\//g, '-').toUpperCase().replace(',', '')
}

/**
 *
 * @param unixTimestamp
 * @param timezone (Optional)
 * @returns MM-DD-YYYY HH:MM AM/PM or '-' if unixTimestamp is undefined
 * @example 01-01-2022 11:38 AM
 */
export const formatUnixDateWithTime = (
  unixTimestamp: number | undefined,
  timezone?: string,
) => {
  if (!unixTimestamp) return '-'
  const date = formatUnixDate(unixTimestamp, timezone)
  const time = formatUnixTime(unixTimestamp, timezone)
  return `${date} ${time}`
}

/**
 *
 * @param startDate
 * @param endDate
 * @returns n days between startDate and endDate
 * @example 3 days
 */
export const getRemainingDays = (startDate: number, endDate: number) => {
  const startDateObj = new Date(startDate * 1000)
  const endDateObj = new Date(endDate * 1000)
  const differenceMs = Math.abs(endDateObj.getTime() - startDateObj.getTime())
  const differenceDays = Math.ceil(differenceMs / (1000 * 3600 * 24))
  return differenceDays + 1
}

/**
 *
 * @param start
 * @param end
 * @returns string like : 01 Jan - 02 Jan or '-' if start or end is undefined
 */
export const formatDateRange = (start: number, end: number): string => {
  if (!start || !end) return '-'
  if (start === end) {
    return formatDateWithoutYear(start)
  }
  return `${formatDateWithoutYear(start)} - ${formatDateWithoutYear(end)}`
}

const formatDateWithoutYear = (unixTimestamp: any | string) => {
  if (!unixTimestamp) return '-'
  const date = new Date(unixTimestamp * 1000)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    timeZone: userTimeZone,
  }
  const formattedDate = new Intl.DateTimeFormat(userLocale, options).format(
    date,
  )
  return formattedDate
}

/**
 * Custom hook to get the current date and time in a specified time zone.
 * The date and time are updated every second.
 *
 * @param {string} userTimeZone - The time zone to use for formatting the date and time.
 * @returns {{ currentDate: string, currentTime: string }} An object containing the current date and time.
 *
 * @example
 * const { currentDate, currentTime } = useCurrentDateTime('America/New_York');
 * console.log(currentDate); // Outputs the current date in 'MM-DD-YYYY' format
 * console.log(currentTime); // Outputs the current time in 'HH:MM AM/PM GMT±HH:MM' format
 */
export function useCurrentDateTime(userTimeZone: string) {
  function getCurrentDateTime(userTimeZone: string) {
    const current = Date.now()
    const currentDate = new Intl.DateTimeFormat(userLocale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: userTimeZone,
    })
      .format(current)
      .replace(/\//g, '-')

    const currentTime = new Intl.DateTimeFormat(userLocale, {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'shortOffset',
      timeZone: userTimeZone,
    })
      .format(current)
      .toUpperCase()

    return { currentDate, currentTime }
  }
  const [currentDateTime, setCurrentDateTime] = useState(
    getCurrentDateTime(userTimeZone),
  )
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime(userTimeZone))
    }, 1000)
    return () => clearInterval(intervalId)
  }, [userTimeZone])

  return currentDateTime
}

export function getDatePlaceholder() {
  const date = new Date(2020, 0, 1) // Arbitrary date
  const formatParts = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date)
  let dateFormat = ''
  formatParts.forEach((part) => {
    switch (part.type) {
      case 'day':
        dateFormat += 'DD'
        break
      case 'month':
        dateFormat += 'MM'
        break
      case 'year':
        dateFormat += 'YYYY'
        break
      case 'literal':
        dateFormat += part.value
        break
    }
  })
  return dateFormat.replace(/\//g, '-')
}

/**
 * Returns the date format string based on the user's locale.
 * The format string will be in the form of 'dd-MM-yyyy' or similar,
 * depending on the locale's date format.
 *
 * @returns {string} The date format string.
 */
export function getDateFormat() {
  const date = new Date(2020, 0, 1) // Arbitrary date
  const formatParts = new Intl.DateTimeFormat(userLocale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).formatToParts(date)
  let dateFormat = ''
  formatParts.forEach((part) => {
    switch (part.type) {
      case 'day':
        dateFormat += 'dd'
        break
      case 'month':
        dateFormat += 'MM'
        break
      case 'year':
        dateFormat += 'yyyy'
        break
      case 'literal':
        dateFormat += part.value
        break
    }
  })
  return dateFormat.replace(/\//g, '-')
}

/**
 * Returns a human-readable string representing the relative time elapsed since the given timestamp.
 *
 * @param unixTime - The timestamp (in seconds) to compare with the current time.
 * @returns A string representing the relative time elapsed (e.g., "5 minutes ago").
 */
export function getRelativeTime(unixTime: number): string {
  function formatTime(unit: number, singular: string, plural: string): string {
    return `${unit} ${unit !== 1 ? t(plural) : t(singular)} ${t('ago')}`
  }

  const currentTimestamp = Math.floor(Date.now() / 1000)
  const timeDifferenceInSeconds = currentTimestamp - unixTime

  if (timeDifferenceInSeconds < 60 && timeDifferenceInSeconds >= 0) {
    return formatTime(timeDifferenceInSeconds, 'second', 'seconds')
  } else if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60)
    return formatTime(minutes, 'minute', 'minutes')
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600)
    return formatTime(hours, 'hour', 'hours')
  } else if (timeDifferenceInSeconds < 604800) {
    const days = Math.floor(timeDifferenceInSeconds / 86400)
    return formatTime(days, 'day', 'days')
  } else if (timeDifferenceInSeconds < 2628000) {
    const weeks = Math.floor(timeDifferenceInSeconds / 604800)
    return formatTime(weeks, 'week', 'weeks')
  } else if (timeDifferenceInSeconds < 31536000) {
    const months = Math.floor(timeDifferenceInSeconds / 2628000)
    return formatTime(months, 'month', 'months')
  } else {
    const years = Math.floor(timeDifferenceInSeconds / 31536000)
    return formatTime(years, 'year', 'years')
  }
}

export const DateTimeConverter = (otp_updated_time: number) => {
  const currTime = new Date()
  return Math.floor(currTime.getTime() / 1000) - otp_updated_time
}

/**
 *
 * @param seconds
 * @returns {string} The time in 12-hour format (e.g. '11:38 AM') from the given seconds.
 * @example
 * calculateTimeWithSeconds(1614556800) => '12:00 AM'
 */
export const calculateTimeWithSeconds = (seconds: number): string => {
  // Calculate total hours and minutes from seconds
  const totalHours = Math.floor(seconds / 3600)
  const totalMinutes = Math.floor((seconds % 3600) / 60)

  // Determine the period (AM/PM)
  const period = totalHours >= 12 ? 'PM' : 'AM'

  // Convert to 12-hour format
  const formattedHours = totalHours % 12 === 0 ? 12 : totalHours % 12

  // Pad minutes with leading zero if needed
  const formattedMinutes = totalMinutes.toString().padStart(2, '0')

  return `${formattedHours}:${formattedMinutes} ${period}`
}

/**
 *
 * @param startTime
 * @param endTime
 * @returns {string} The duration between the start and end times in the format 'Xh Ym'.
 * @example
 * calculateDuration(1614556800, 1614560400) => '1h 0m'
 */
export const calculateDuration = (startTime: number, endTime: number) => {
  // Calculate duration in seconds
  const durationSeconds = endTime - startTime

  // If the duration is negative, it means the time crosses midnight
  const adjustedDurationSeconds =
    durationSeconds < 0 ? durationSeconds + 24 * 3600 : durationSeconds

  // Calculate hours and minutes from the adjusted duration
  const hours = Math.floor(adjustedDurationSeconds / 3600)
  const minutes = Math.floor((adjustedDurationSeconds % 3600) / 60)

  return `${hours}h ${minutes}m`
}

/**
 *
 * @returns {number} The current date and time in Unix format.
 */
export const getTodaysDateTimeInUnix = () => {
  const date = new Date()
  return Math.floor(date.getTime() / 1000)
}
