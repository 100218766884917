import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CellProps } from 'react-table'
import { useSelector } from 'react-redux'

import Button from 'libs/button/Button'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { hasAccess } from 'utils/utils'
import { selectSelectedItem } from 'store/redux/navSlice'
import { ExtendedColumn } from 'store/services/utilsTypes'
import { PointOfContact } from 'pages/contacts/commonTypes'

import { VendorResponse } from '../vendor-endpoints/vendorTypes'
import { useGetVendorByIdQuery } from '../vendor-endpoints/vendor.endpoints'

const columns: ExtendedColumn<PointOfContact>[] = [
  {
    Header: 'First Name',
    accessor: 'firstName',
    disableSortBy: true,
    Cell: ({ row }: CellProps<PointOfContact>) => row?.values?.firstName ?? '-',
  },

  {
    Header: 'Last Name',
    accessor: 'lastName',
    disableSortBy: true,
    Cell: ({ row }: CellProps<PointOfContact>) => row?.values?.lastName ?? '-',
  },
  {
    Header: 'Contact Number',
    accessor: 'contactNumber',
    disableSortBy: true,
    Cell: ({ row }: CellProps<PointOfContact>) =>
      row?.values?.contactNumber ?? '-',
  },
  {
    Header: 'Email Address',
    accessor: 'emailAddress',
    disableSortBy: true,
    Cell: ({ row }: CellProps<PointOfContact>) =>
      row?.values?.emailAddress ?? '-',
  },
  {
    Header: 'Remarks',
    accessor: 'remarks',
    disableSortBy: true,
    Cell: ({ row }: CellProps<PointOfContact>) => row?.values?.remarks ?? '-',
  },
]

const VendorInfo: {
  label: string
  key: keyof VendorResponse
}[] = [
  { label: 'Vendor Name', key: 'name' },
  { label: 'Vendor Type', key: 'vendorType' },
  { label: 'Business Email Address', key: 'businessEmailAddress' },
  { label: 'Primary Contact Number', key: 'primaryContactNumber' },
  { label: 'Secondary Contact Number', key: 'secondaryContactNumber' },
]

const ViewBasicDetails = () => {
  const vendorAccess = hasAccess('con', { ven: 'e' })
  const { id } = useParams()
  const plant = useSelector(selectSelectedItem)
  const navigate = useNavigate()

  const { data: vendor, isLoading: loading } = useGetVendorByIdQuery(
    { plant_id: plant?.id, id: id ?? '' },
    {
      skip: !plant?.id || !id,
    },
  )

  return (
    <MotionDiv>
      {loading ? (
        <FullPageLoader />
      ) : (
        <>
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full justify-between">
              <h5 className="h5">Basic Details</h5>
              {vendorAccess && (
                <div>
                  <Button
                    color="primary"
                    onClick={() => {
                      id &&
                        navigate(
                          `/contacts/vendor-management/edit/${id}/basic-details`,
                        )
                    }}
                  >
                    Edit Vendor Details
                  </Button>
                </div>
              )}
            </div>
            <div className="flex  max-w-[800px] justify-between">
              <div className="grid grid-cols-2 gap-4 text-xs">
                {VendorInfo.map(({ label, key }, fieldIndex) => (
                  <React.Fragment key={fieldIndex}>
                    <span className="text-gray-500">{label}</span>
                    <span>
                      {typeof vendor?.[key] === 'string' ? vendor[key] : '-'}
                    </span>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h5 className="h5">Point of Contacts</h5>
              <PaginatedTable<PointOfContact>
                columns={columns}
                rows={vendor?.pointOfContacts}
                hidePagination={true}
                emptyMessage={
                  'No contact details have been added yet. Please add new ones'
                }
              />
            </div>
          </div>
        </>
      )}
    </MotionDiv>
  )
}

export default ViewBasicDetails
