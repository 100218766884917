import ButtonThree from 'assets/icons/top-navigation/ButtonThree'
import { useState } from 'react'
import Pages from 'assets/icons/top-navigation/Pages'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './breadcrumbs.scss'
import { getDashboardInfo, getCurrentInfo, getPreviousInfo } from './Index'

import { ChevronRight } from '@mui/icons-material'
import { LeftArrowIcon } from 'assets/icons'

function Breadcrumbs() {
  const location = useLocation()
  const navigate = useNavigate()
  const state = location.state

  const intermediateRoutes: any = []
  //state of ButtonThree
  const [isActive, setIsActive] = useState(false)
  //state of nav forward and backward
  const isMainPageActive = false
  const isPreviousPageActive = false

  const dashboardInfo = getDashboardInfo()
  const previousInfo = getPreviousInfo()
  const currentInfo = getCurrentInfo()

  //toggle state of Button
  const handleClick = () => {
    setIsActive(!isActive)
  }
  const handleIntermediatePages = (to: any) => {
    navigate(to, { state: state?.breadcrumbData })
  }

  const isSettings =
    location.pathname === '/settings/configure' ||
    location.pathname === '/settings/appearance' ||
    location.pathname === '/settings/shift-management'||
    location.pathname === '/timesheet' ||
    location.pathname === '/unauthorized'

  return (
    <div className="main-page-link-container">
      {!isSettings ? (
        <div className="mr-2">
          <LeftArrowIcon />
        </div>
      ) : null}

      <div className="navigation-bar">
        <Link to={dashboardInfo?.to} className={isMainPageActive ? 'main-link-active m-0' : 'main-link m-0'}>
          {dashboardInfo.label}
        </Link>

        {previousInfo.label && (
          <>
            <div className={`flex-container`}>
              <ChevronRight style={{ fontSize: 17, color: '#75838D' }} />
              {intermediateRoutes?.length > 0 && (
                <>
                  <ButtonThree
                    className={isActive ? 'svg-container2-active' : 'svg-container2'}
                    onClick={handleClick}
                  />
                  <ChevronRight style={{ fontSize: 17, color: '#75838D' }} />
                </>
              )}
            </div>
            <div
              className={isPreviousPageActive ? 'main-link-active m-0' : `main-link m-0`}
              onClick={() => handleIntermediatePages(previousInfo.to)}
            >
              {previousInfo.label}
            </div>
          </>
        )}

        {location?.pathname && (
          <>
            {currentInfo?.label && (
              <ChevronRight style={{ fontSize: 17, color: '#75838D' }} className="svg-container1" />
            )}
            <p className="disabled-link m-0">{currentInfo.label}</p>
          </>
        )}
      </div>

      {isActive && (
        <>
          <div
            className="h-auto w-[300px] rounded bg-[#FFF] p-3"
            style={{
              position: 'fixed',
              zIndex: 10,
              top: '2.8rem',
              left: '15.6rem',
              boxShadow: '0px 16px 48px 0px rgba(0, 0, 0, 0.17)',
            }}
          >
            <div>
              {intermediateRoutes?.length > 0 &&
                intermediateRoutes.map((route: any, index: number) => {
                  const modifiedPath = route.path.replace(/^\//, '')
                  const formattedPath = modifiedPath.charAt(0).toUpperCase() + modifiedPath.slice(1)

                  return (
                    <Link
                      key={index}
                      to={route.path}
                      className="flex items-center gap-3 p-xs-regular"
                      onClick={handleClick}
                    >
                      <Pages />
                      {formattedPath}
                    </Link>
                  )
                })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default Breadcrumbs
