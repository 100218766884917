import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { EMAIL_REGEX } from 'utils/constants'
import { phoneNumberValidator } from 'utils/phoneNumberValidator'
import {
  usePostUsersMutation,
  usePutUsersMutation,
  useUserInfoQuery,
} from '../user-endpoints/user.endpoint'
import { useGetRolesQuery } from '../../roles/roles-endpoints/roles.endpoint'

type DropdownOption = {
  label: string
  value: string
  sublabel?: string
  disabled?: any // Allow boolean type for disabled
}

const AddEditUser = ({ userData, close }: any) => {
  const [addUser, { isLoading: isAddUserLoading }] = usePostUsersMutation()
  const navigate = useNavigate()
  const [updateUser, { isLoading: isUpdateUserLoading }] = usePutUsersMutation()

  const { data: userInfo } = useUserInfoQuery({})

  const filter = {
    page_no: 1,
    page_size: 1000,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
  }

  const { data: userRoles } = useGetRolesQuery(
    {
      org_id: userInfo?.organization?._id,
      filters: filter,
    },
    {
      skip: !userInfo?.organization?._id,
    },
  )
  const userRolesOptions: DropdownOption[] =
    userRoles?.data?.reduce((acc: DropdownOption[], userRole: any) => {
      if (!acc.some((option) => option.label === userRole.name)) {
        acc.push({
          label: userRole.name,
          value: userRole._id,
          sublabel: '',
          disabled: userRole.name === 'admin',
        })
      }
      return acc
    }, []) ?? []

  const formik = useFormik({
    initialValues: {
      userName: userData?.username || '',
      emailID: userData?.email || '',
      firstName: userData?.first_name || '',
      lastName: userData?.last_name || '',
      phoneNumber: userData?.phone_number || '',
      userRole: userData?.role || '',
    },
    onSubmit: (values) => {
      const payload = {
        username: values.userName,
        email: values.emailID,
        first_name: values.firstName,
        last_name: values.lastName,
        phone_number: values.phoneNumber.toString(),
        organization_id: userInfo?.organization?._id,
        role_id: values.userRole,
      }

      if (userData) {
        updateUser({
          userId: userData?._id,
          data: payload,
          org_id: userInfo?.organization?._id,
        })
          .unwrap()
          .then((data) => {
            toast.success(`User ${data.username} updated successfully`)
            close()
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ?? 'Something went wrong. Please try again.',
            )
          })
      } else {
        addUser({
          org_id: userInfo?.organization?._id,
          data: payload,
        })
          .unwrap()
          .then((data) => {
            toast.success(`New User ${data.username} created successfully`)
            close()
          })
          .catch((error) => {
            toast.error(
              error?.data?.detail ?? 'Something went wrong. Please try again.',
            )
          })
      }
    },
    validationSchema: Yup.object({
      userName: Yup.string()
        .required('User Name is required')
        .min(8, 'User Name must be at least 8 characters long')
        .matches(/^\S*$/, 'User Name cannot contain spaces'),
      emailID: Yup.string()
        .required('Email ID is required')

        .email('Invalid email format') // Standard email validation
        .matches(
          EMAIL_REGEX,
          'Email must be a valid email (e.g., abc@gmail.com)',
        ),

      firstName: Yup.string()
        .required('First Name is required')
        .max(30, 'First Name must be at most 30 characters long')
        .matches(
          /^[A-Za-z]+$/,
          'First Name cannot contain special characters or numbers',
        ),
      lastName: Yup.string()
        .required('Last Name is required')
        .max(30, 'Last Name must be at most 30 characters long')
        .matches(
          /^[A-Za-z]+$/,
          'Last Name cannot contain special characters or numbers',
        ),

      phoneNumber: Yup.string()
        .required('Primary Contact Number is required')
        .test(
          'valid-phone',
          'Please enter a phone number for the selected country.',
          phoneNumberValidator,
        ),
      userRole: Yup.string().required('User Role is required'),
    }),
  })

  useEffect(() => {
    if (formik.values.userRole) {
      const userRole = userRolesOptions.find(
        (option: any) => option.value === formik.values.userRole._id,
      )

      if (userRole) {
        formik.setFieldValue('userRole', userRole.value)
      }
    }
  }, [formik.values.userRole])

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="text"
          label="User Name"
          placeholder="Enter User Name"
          formik={formik}
          required={true}
          name="userName"
        />
        <Field
          type="text"
          label="First Name"
          placeholder="Enter First Name"
          required={true}
          formik={formik}
          name="firstName"
        />
        <Field
          type="text"
          label="Last Name"
          placeholder="Enter Last Name"
          required={true}
          formik={formik}
          name="lastName"
        />
        <Field
          type="text"
          label="Email ID"
          placeholder="Enter Email ID"
          required={true}
          formik={formik}
          name="emailID"
        />
        <Field
          type="phonenumber"
          label="Phone Number"
          placeholder="Enter Phone Number"
          required={true}
          formik={formik}
          name="phoneNumber"
        />
        <Field
          type="select"
          label="User Role"
          formik={formik}
          required={true}
          name="userRole"
          options={userRolesOptions}
          defaultValue={userRolesOptions.find(
            (option: any) => option.value === formik?.values.userRole?._id,
          )}
          placeholder="select"
        />
        {/* Do not delete this comment */}
        <button
          className="flex  text-sm text-blue-600"
          onClick={() => {
            navigate(`/settings/user-management/roles/add`)
          }}
        >
          Add User Role +
        </button>
        <div className="flex justify-end gap-2">
          <Button onClick={close}>Cancel</Button>
          <Button
            color="primary"
            loading={isAddUserLoading || isUpdateUserLoading}
            onClick={() => formik.handleSubmit()}
          >
            {!userData ? 'Add' : 'Save'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default AddEditUser
