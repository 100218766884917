import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const countryApiSlice = createApi({
  reducerPath: 'countryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://country-state-city-search-rest-api.p.rapidapi.com',
    prepareHeaders: (headers) => {
      headers.set('x-rapidapi-key', '72d25b832fmsha4e04d42588c6f8p16429djsn13cf55c2f708')
      return headers
    },
  }),
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => '/allcountries',
    }),
    getStatesByCountry: builder.query({
      query: ({ countryIso2 }) => `/states-by-countrycode?countrycode=${countryIso2}`,
    }),
    getStatesByCountryByState: builder.query({
      query: ({ countryIso2, stateIso2 }) =>
        `/cities-by-countrycode-and-statecode?countrycode=${countryIso2}&statecode=${stateIso2}`,
    }),
  }),
})

export const { useGetCountriesQuery, useGetStatesByCountryQuery, useGetStatesByCountryByStateQuery } = countryApiSlice
