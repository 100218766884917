import React from "react";

const Search: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <mask
        id="mask0_810_16805"
        style={{ maskType: "alpha" }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_810_16805)">
        <path
          fill="#041220"
          d="M7.917 13.333c-1.514 0-2.795-.524-3.844-1.573C3.024 10.712 2.5 9.43 2.5 7.917c0-1.514.524-2.795 1.573-3.844S6.403 2.5 7.917 2.5c1.514 0 2.795.524 3.843 1.573 1.049 1.049 1.573 2.33 1.573 3.844a5.08 5.08 0 01-1.083 3.166l4.667 4.667a.79.79 0 01.229.583.79.79 0 01-.23.584.79.79 0 01-.583.229.79.79 0 01-.583-.23l-4.667-4.666a5.08 5.08 0 01-3.167 1.083zm0-1.666c1.041 0 1.927-.365 2.656-1.094.73-.73 1.094-1.615 1.094-2.656 0-1.042-.365-1.927-1.094-2.657-.73-.729-1.615-1.093-2.656-1.093-1.042 0-1.927.364-2.657 1.093-.729.73-1.093 1.615-1.093 2.657 0 1.041.364 1.927 1.093 2.656.73.73 1.615 1.094 2.657 1.094z"
        ></path>
      </g>
    </svg>
  );
}

export default Search
