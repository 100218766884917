import React from 'react'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { useApproveByMutation } from 'pages/operational-processes/operational-process-endpoints/operational_process.endpoints'
import { trackEvent } from 'libs/layouts/mixpanel/mixPanel'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useUserInfoQuery } from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface BatchApproveProps {
  closePopUp: any
  data: string[]
  setBatchData: any
  processName: string
  departmentName: string
}

const ApproveByPopUp: React.FC<BatchApproveProps> = ({
  closePopUp,
  data,
  setBatchData,
  processName,
  departmentName,
}) => {
  const { data: userInfo } = useUserInfoQuery({})
  const [approve, { isLoading: isApprove }] = useApproveByMutation()
  const plant = useSelector(selectSelectedItem)

  const handleApprove = () => {
    if (data?.length > 0) {
      approve({ entryIds: data })
        .unwrap()
        .then(() => {
          toast.success(`${data?.length} entries approved`)
          closePopUp()
          setBatchData([])
          trackEvent('Process Entry', {
            Entry: 'Approve Entry',
            Department: departmentName,
            Process: processName,
            Count: data?.length,
            organization: userInfo?.organization?.name,
            Plant: plant?.name,
          })
        })
        .catch((err) => {
          toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
        })
    }
  }

  return (
    <div>
      <p className="p-xs-regular">
        Are you sure you want to approve the selected {data?.length} entries? Once approved, these entries{' '}
        <span className="break-words p-xs-bold"> cannot be modified. </span>
        However, you can create new entries for making the adjustments.
      </p>

      <div className="mt-4 flex justify-end">
        <Button className="mr-2 rounded bg-red-500 px-4 py-2 text-xs text-closebtn btn-cancel" onClick={closePopUp}>
          Close
        </Button>
        <Button color="primary" size="sm" onClick={handleApprove} loading={isApprove}>
          Approve
        </Button>
      </div>
    </div>
  )
}

export default ApproveByPopUp
