import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'

import { buildUrl } from 'utils/stringHandlers'
import {
  EmployeeDepartmentApiParams,
  EmployeeDepartmentApiResponse,
  PostDepartmentResponse,
} from './employeedepartmentTypes'

export const employeedepartment = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postEmployeeDepartment: builder.mutation<PostDepartmentResponse, any>({
      query: ({ data, plant_id }) => {
        return {
          url: `plant/${plant_id}/employee-departments`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['EmployeeDepartmentUpdate'],
    }),

    getEmployeeDepartment: builder.query<
      EmployeeDepartmentApiResponse,
      EmployeeDepartmentApiParams
    >({
      query: ({ plant_id, filters }) => {
        const URL = buildUrl(`/plant/${plant_id}/employee-departments`, {
          ...filters,
        })
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Employee Department ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['EmployeeDepartmentUpdate'],
    }),
    getEmployeeDepartmentById: builder.query({
      query: ({ plant_id, departmentID }) => {
        return {
          url: `/plant/${plant_id}/employee-department/${departmentID}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Employee Department ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['getSingleDepartment'],
    }),

    getEmployeeDepartmentAndDesignation: builder.query({
      query: ({ plant_id, departmentID }) => {
        const URL = buildUrl(
          `/plant/${plant_id}/employee-departments-designations`,
          { departmentID },
        )
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Employee Department ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['EmployeeDepartmentUpdate'],
    }),
    getEmployeeDepartmentCount: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `/plant/${plant_id}/employee-department-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Employee Department ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['EmployeeDepartmentUpdate'],
    }),
    deleteEmployeeDepartment: builder.mutation({
      query: ({ plant_id, departmentID }) => ({
        url: `/plant/${plant_id}/employee-department/${departmentID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['EmployeeDepartmentUpdate'],
    }),
    putEmployeeDepartment: builder.mutation<any, any>({
      query: ({ data, plant_id, departmentID }) => {
        return {
          url: `/plant/${plant_id}/employee-department/${departmentID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['EmployeeDepartmentUpdate', 'getSingleDepartment'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetEmployeeDepartmentQuery,
  usePostEmployeeDepartmentMutation,
  usePutEmployeeDepartmentMutation,
  useGetEmployeeDepartmentByIdQuery,
  useDeleteEmployeeDepartmentMutation,
  useGetEmployeeDepartmentAndDesignationQuery,
  useGetEmployeeDepartmentCountQuery,
} = employeedepartment
