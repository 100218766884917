import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './index.css'
import { Modal } from './modal'
import Cookies from 'js-cookie'
import { LeftArrowIcon } from 'assets/icons'
import { SpinLoader } from 'libs/loader'
import {
  useGetOTPLastUpdatedTimeMutation,
  useSendOTPViaEmailMutation,
  useSendOTPViaPhoneNumberMutation,
  useVerifyOTPViaEmailMutation,
  useVerifyOTPViaPhoneNumberMutation,
} from './auth.endpoints'
import {
  VerifyEmail,
  VerifyThroughEmail,
  VerifyThroughPhone,
} from '../../../../mocks/VerificationCodePage'
import { DateTimeConverter } from 'utils/dateTimeHandlers'
import ResetPasswordPage from './ResetPassword'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

interface LoginResponse {
  data?: {
    is_temperory?: boolean
    session?: string
    expires_in?: number
    user_info?: {
      role?: string
    }
  }
  error?: any
}
type SuccessData = {
  data: any
  error?: undefined
}

type ErrorData = {
  data?: undefined
  error: FetchBaseQueryError | SerializedError
}

type Data = SuccessData | ErrorData | LoginResponse

const VerificationCodePage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [verifyOTPViaEmail] = useVerifyOTPViaEmailMutation()
  const [verifyOTPViaPhoneNumber] = useVerifyOTPViaPhoneNumberMutation()
  const [sendOTPViaEmail] = useSendOTPViaEmailMutation()
  const [sendOTPViaPhone] = useSendOTPViaPhoneNumberMutation()
  const [getLastOTPUpdatedTime] = useGetOTPLastUpdatedTimeMutation()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [otpValues, setOtpValues] = useState(['', '', '', '', '', ''])
  const [countdown, setCountdown] = useState<number>(90)
  const [userData, setUserData] = useState<any>({})
  const isOtpComplete = otpValues.every((value) => value !== '')
  const [isInvalid, setIsInvalid] = useState(false)
  const [timeLeft, setTimeLeft] = useState(30) // Start with 60 seconds
  const [isExpired, setIsExpired] = useState(false)
  function moveCursorToEnd(inputElement: any) {
    inputElement.selectionStart = inputElement.selectionEnd =
      inputElement.value.length
  }
  const setCaretToEnd = (input: HTMLElement | null) => {
    if (input instanceof HTMLInputElement) {
      if (input.setSelectionRange) {
        const len = input.value.length * 2
        input.focus()
        input.setSelectionRange(len, len)
      } else {
        input.focus()
      }
    }
  }

  const handleOtpInputChange = (index: any, value: any) => {
    setIsInvalid(false)
    if (value.match(/^[0-9]*$/)) {
      const newOtpValues = [...otpValues]

      if (value === '' && index > 0) {
        newOtpValues[index] = ''
        setOtpValues(newOtpValues)
        const previousInput = index - 1
        const inputElement = document.getElementById(`otp-${previousInput}`)

        if (inputElement) {
          inputElement.focus()
          setCaretToEnd(inputElement)
        }
      } else {
        newOtpValues[index] = value.length > 1 ? value[value.length - 1] : value

        if (index < otpValues.length - 1 && value !== '') {
          const nextInput = index + 1
          const inputElement = document.getElementById(`otp-${nextInput}`)
          if (inputElement) {
            inputElement.focus()
            setCaretToEnd(inputElement)
          }
        }
      }

      setOtpValues(newOtpValues)
    }
  }
  const handleArrowKeyPress = (event: any, index: any) => {
    if (event.key === 'ArrowLeft') {
      event.preventDefault()
      const previousInput = index - 1
      if (previousInput >= 0) {
        const inputElement = document.getElementById(`otp-${previousInput}`)
        if (inputElement) {
          inputElement.focus()
          setCaretToEnd(inputElement)
        }
      }
    } else if (event.key === 'ArrowRight') {
      event.preventDefault()
      const nextInput = index + 1
      if (nextInput < otpValues.length) {
        const inputElement = document.getElementById(`otp-${nextInput}`)
        if (inputElement) {
          inputElement.focus()
          setCaretToEnd(inputElement)
        }
      }
    } else if (event.key === 'Enter' && index === 5) {
      handleVerifyClick()
    } else if (event.key === 'Backspace' && otpValues[index] === '') {
      event.preventDefault()
      handleOtpInputChange(index, '')
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown')
      event.preventDefault()
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown && countdown > 0) {
        setCountdown(countdown - 1)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [countdown])
  const locationState = location.state

  const handleResendCode = () => {
    setOtpValues(['', '', '', '', '', ''])
    setIsInvalid(false)
  }
  const handleVerifyClick = async () => {
    if (countdown === 0) {
      handleResendCode()
      return
    }
    if (isInvalid) setIsInvalid(false)
    const isOtpComplete = otpValues.every((value) => value !== '')

    if (isOtpComplete) {
      const otpValue = otpValues.join('')
      if (
        locationState?.loggedWith === 'email' ||
        locationState?.forgotPassword
      ) {
        await verifyOTPViaEmail({
          email: locationState?.contactInfo,
          otp: otpValue,
        }).then((res: Data) => {
          if (res?.error) {
            setIsInvalid(true)
          } else {
            setTimeout(() => {}, 500)
            setTimeout(() => {
              if (res && !locationState?.forgotPassword) {
                setIsLoggedIn(true)

                Cookies.set('access_token', res?.data?.access_token, {
                  expires: Math.floor(
                    res?.data?.start_time -
                      res?.data?.end_time / (60 * 60 * 24),
                  ),
                })

                Cookies.set('refresh_token', res?.data.refresh_token, {
                  expires: undefined,
                })

                Cookies.set('role', res.data?.user_info?.role, {
                  expires: undefined,
                })

                Cookies.set('username', res?.data?.user_info?.email, {
                  expires: undefined,
                })

                Cookies.set(
                  'expires_in',
                  res?.data?.start_time - res?.data?.end_time,
                  {
                    expires: undefined,
                  },
                )
              }
            }, 1500)
            setTimeout(() => {
              if (!locationState?.forgotPassword) navigate('')
              else
                navigate('/auth/reset-password', {
                  state: {
                    email: locationState?.contactInfo,
                    userData: res,
                  },
                })
            }, 3000)
          }
        })
      } else if (
        locationState?.loggedWith === 'phone' ||
        locationState?.forgotPassword
      ) {
        await verifyOTPViaPhoneNumber({
          phone_number: locationState?.contactInfo,
          otp: otpValue,
        }).then((res: Data) => {
          if (res?.error) {
            setIsInvalid(true)
          } else {
            setTimeout(() => {}, 500)
            setTimeout(() => {
              if (res && !locationState?.forgotPassword) {
                setIsLoggedIn(true)
                Cookies.set('catalyst-user-data', JSON.stringify(res), {
                  expires: locationState?.cookieExpires,
                })
              }
            }, 1500)
            setTimeout(() => {
              if (!locationState?.forgotPassword) navigate('/dashboard')
              else
                navigate('/auth/reset-password', {
                  state: {
                    email: locationState?.contactInfo,
                  },
                })
            }, 3000)
          }
        })
      } else if (locationState?.forgotPassword) {
      }
    }
  }

  const handleResendClick = async () => {
    if (locationState.loggedWith === 'email' || locationState?.forgotPassword) {
      await sendOTPViaEmail({ email: locationState?.contactInfo }).then(
        (res: Data) => {
          let timeDiff = DateTimeConverter(res?.data?.updated_on)
          setTimeLeft(30)
          if (timeDiff > 90) setCountdown(0)
          else setCountdown(90 - timeDiff)
        },
      )
      handleResendCode()
    } else if (
      locationState.loggedWith === 'phone' ||
      locationState?.forgotPassword
    ) {
      await sendOTPViaPhone({ phone_number: locationState?.contactInfo }).then(
        (res: Data) => {
          let timeDiff = DateTimeConverter(res?.data?.updated_on)
          if (timeDiff > 90) setCountdown(0)
          else setCountdown(90 - timeDiff)
        },
      )
      handleResendCode()
    }
  }
  const handleOtpPaste = (e: any) => {
    e.preventDefault()
    setIsInvalid(false)
    const pastedText = e.clipboardData.getData('text')
    if (/^\d{6}$/.test(pastedText)) {
      const otpArray = pastedText
        .split('')
        .filter((char: any) => !isNaN(char))
        .slice(0, otpValues.length)
        .map((char: any) => parseInt(char, 10))

      setOtpValues(otpArray)
      const inputElement = document.getElementById(`otp-${5}`)
      if (inputElement) {
        inputElement.focus()
      }
    }
  }

  const verifyVia = (email: any, phone: any, forgotEmail: any) => {
    if (locationState?.loggedWith === 'phone') return phone
    else if (locationState?.loggedWith === 'email') return email
    else if (locationState?.forgotPassword) return forgotEmail
    else return ''
  }

  const getUpdatedTime = async (via: string, contact: string) => {
    return await getLastOTPUpdatedTime({ via: via, contact: contact })
      .then((res: Data) => {
        let timeDiff = DateTimeConverter(res?.data?.updated_time)
        if (timeDiff > 90 && countdown !== null) setCountdown(0)
        else setCountdown(90 - timeDiff)
      })
      .catch((err) => {
        console.error('err: ', err)
      })
  }
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000) // Update every second
      return () => clearTimeout(timer) // Cleanup timer
    } else {
      setIsExpired(true) // Mark as expired when timeLeft reaches 0
    }
  }, [timeLeft])
  useEffect(() => {
    locationState?.catalystUserData &&
      setUserData(JSON.parse(locationState?.catalystUserData)?.data?.user_info)
    getUpdatedTime(
      locationState?.loggedWith ? locationState?.loggedWith : 'email',
      locationState?.contactInfo,
    )
  }, [])
  return (
    <Modal
      name={isLoggedIn && userData?.first_name}
      heading={`${
        isLoggedIn
          ? 'Welcome Back '
          : verifyVia(
              VerifyThroughEmail.HEADING,
              VerifyThroughPhone.HEADING,
              VerifyEmail.HEADING,
            )
      }`}
    >
      {!isLoggedIn && (
        <div className="scroll-bar h-96 overflow-y-hidden hover:overflow-y-auto">
          <div className="my-4">
            <div className="mb-4 mt-2">
              <div className="flex gap-x-4 py-1">
                <Link to={'/auth/login'}>
                  <LeftArrowIcon />
                </Link>
                <p className={`text-lg font-semibold text-[#323333]`}>
                  {verifyVia(
                    VerifyThroughEmail.BACK_BTN_TEXT,
                    VerifyThroughPhone.BACK_BTN_TEXT,
                    VerifyEmail.BACK_BTN_TEXT,
                  )}
                </p>
              </div>
              <p
                className={`mt-2 font-inter text-sm font-medium leading-5 text-[#323333]`}
              >
                {verifyVia(
                  VerifyThroughEmail.DESCRIPTION,
                  VerifyThroughPhone.DESCRIPTION,
                  VerifyEmail.DESCRIPTION,
                )}
                <span className="text-sm font-medium text-[#0B5ED7]">
                  {verifyVia(
                    locationState?.contactInfo,
                    `+91 ${locationState?.contactInfo}`,
                    locationState?.contactInfo,
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="py-1.5">
            <label className="font-inter text-sm font-normal text-[#323333]">
              Verification code <span className="text-[#8E0000]">*</span>
            </label>
            <div
              id="otp"
              className="mt-5 flex flex-row justify-center text-center"
              onPaste={handleOtpPaste}
            >
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  id={`otp-${index}`}
                  // disabled={countdown === 0 || isInvalid}
                  disabled={timeLeft == 0}
                  style={
                    isInvalid
                      ? {
                          border: '1px solid var(--error-700, #8E0000)',
                        }
                      : {}
                  }
                  className={`form-control m-2 h-12 w-12 rounded text-center ${
                    isInvalid
                      ? 'bg-[#F8D7DA] font-bold text-[#8E0000]'
                      : 'bg-[#EBEFF3]'
                  } otp-input `}
                  type="text"
                  pattern="[0-9]*"
                  value={value}
                  onFocus={() =>
                    moveCursorToEnd(document.getElementById(`otp-${index}`))
                  }
                  onClick={() =>
                    setCaretToEnd(document.getElementById(`otp-${index}`))
                  }
                  onChange={(e) => handleOtpInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleArrowKeyPress(e, index)}
                />
              ))}
            </div>
          </div>
          <div className="mb-4 mt-4">
            {/*below code shows verification count*/}
            {/* <p className="font-inter text-sm font-normal">
              {countdown > 0 &&
                !isInvalid &&
                "The verification code expires in"}{" "}
              <span className="text-[#8E0000]">
                {isInvalid
                  ? "Incorrect verification code"
                  : countdown > 0
                  ? countdown + " Seconds"
                  : "Verification code expired"}{" "}
              </span>
            </p> */}
            <div className="verification-timer">
              {!isExpired ||
                (timeLeft !== 0 && (
                  <p className="font-inter text-xs text-[#75838D]">
                    The verification code was sent to you. You can retry sending
                    the code after{' '}
                    <span className="text-error">{timeLeft}</span> seconds
                  </p>
                ))}
            </div>
            <p className="mt-4 font-inter text-sm font-normal">
              Verification code not received?{' '}
              <button
                className={`cursor-pointer bg-white text-sm font-bold text-[#0B5ED7] disabled:bg-white disabled:text-gray-450`}
                onClick={handleResendClick}
                disabled={timeLeft > 0}
              >
                Resend code
              </button>
              {/* <span
                onClick={handleResendClick}
                className="text-[#0B5ED7] font-bold text-sm hover:cursor-pointer	"
              >
                {" "}
                {countdown > 0 && !isInvalid && "Resend code"}
              </span> */}
            </p>
            <span className="font-inter text-xs text-[#75838D]">
              {countdown > 0 && !isInvalid
                ? verifyVia(
                    VerifyThroughEmail.NOT_RECEIEVED_TEXT,
                    VerifyThroughPhone.NOT_RECEIEVED_TEXT,
                    VerifyEmail.NOT_RECEIEVED_TEXT,
                  )
                : 'Resend code to try again'}
            </span>
          </div>
          <ResetPasswordPage
            isOtpComplete={isOtpComplete}
            email={locationState.contactInfo}
            otpValue={otpValues}
            setIsInvalid={setIsInvalid}
            isInvalid={isInvalid}
          />

          <div>
            {/* <button
              onClick={
                countdown > 0 && !isInvalid
                  ? handleVerifyClick
                  : countdown > 0
                  ? handleResendCode
                  : handleResendClick
              }
              className={`${
                isOtpComplete && success && countdown > 0
                  ? "bg-[#28A745] flex items-center justify-center"
                  : isOtpComplete && countdown > 0
                  ? "bg-[#0B5ED7]"
                  : countdown <= 0
                  ? "bg-[#0B5ED7]"
                  : "bg-[#75838D]"
              } text-[#fff] px-8 w-full rounded py-2.5 mb-4 text-sm`}
            >
              {success && countdown > 0
                ? "Verified"
                : countdown > 0 && !isInvalid
                ? "Verify"
                : countdown > 0
                ? "Re-Enter Code"
                : "Resend code"}{" "}
              &nbsp; &nbsp;&nbsp;
              {loading ? (
                <MiniSpinLoader />
              ) : (
                !success && countdown > 0 && !isInvalid && "-> "
              )}
              {success && countdown > 0 && <MiniTickIcon />}
            </button> */}
          </div>
        </div>
      )}
      {isLoggedIn && (
        <div className="h-80">
          <div className="mx-auto mt-10">
            <SpinLoader />
          </div>
          <p className="py-4 text-center font-inter text-lg text-[#323333]">
            Please wait while logging you in
          </p>
        </div>
      )}
    </Modal>
  )
}
export default VerificationCodePage
