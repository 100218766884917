import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldProps } from '..'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { AnimatePresence, motion } from 'framer-motion'
import { dropdownMotionProps } from 'utils/FramerProps'
import humanize from 'humanize-plus'
import Search from 'assets/icons/top-navigation/Search'
import { get } from 'lodash'
import icons from 'assets/index.ts'
import { getNestedValue } from '../helpers'

export interface MultiSelectProps extends FieldProps {
  className: string
  restProps: any
  originalOptions?: any[]
}

const MultiSelect = (props: MultiSelectProps) => {
  const {
    type,
    className,
    restProps,
    formik,
    name,
    placeholder = '',
    options,
    originalOptions,
    disabled = false,
    defaultValue = '',
    multiSelectPlaceholderTexts = ['', ''],
    onChange: onChangeProp,
    title,
    hideSearch,
    onAdd,
  } = props

  const isSingleSelect = type === 'select'
  const formikValue = useMemo(() => {
    return getNestedValue(formik.values, name)
  }, [formik.values, name])
  const [selectValue, setSelectValue] = useState('')
  const [multiSelectedOptions, setMultiSelectedOptions] = useState<string[]>(
    isSingleSelect ? [] : getNestedValue(formik.values, name),
  )

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [searchText, setSearchText] = useState('')

  const searchInputRef = useRef<HTMLInputElement>(null)
  const [openUpwards, setOpenUpwards] = useState(false) // State to track dropdown direction

  useEffect(() => {
    if (type !== 'select') return
    const _val = get(formik.values, name)
    if (_val == null) {
      setSelectValue('')
      return
    }
    if (defaultValue?.label) {
      setSelectValue(defaultValue?.label)
      return
    }
    const _options = originalOptions ?? options

    const label = _options?.find((o) => {
      if (typeof _val === 'string') {
        return o?.label?.toLowerCase() === _val?.toLowerCase()
      } else {
        return o?.label === _val?.name
      }
    })?.label

    if (label) {
      setSelectValue(label)
    }
  }, [
    formik?.values,
    type,
    name,
    defaultValue?.label,
    originalOptions,
    options,
  ])

  useEffect(() => {
    if (!isSingleSelect && (!formikValue || formikValue.length === 0)) {
      setMultiSelectedOptions([])
    } else {
      formik?.values[name] != null && setMultiSelectedOptions(formikValue)
    }
  }, [formik?.values[name]])

  const filteredMultiSelectOptions = useMemo(() => {
    return (
      (Array.isArray(options) ? options : [])?.filter(
        (item) =>
          item?.label?.toLowerCase().includes(searchText?.toLowerCase()) ||
          (item?.sublabel &&
            item?.sublabel.toLowerCase().includes(searchText?.toLowerCase())),
      ) ?? []
    )
  }, [options, searchText])

  const highlightSearchText = (
    text: string | undefined,
    searchText: string,
  ) => {
    if (!searchText) return text
    const parts = text?.split(new RegExp(`(${searchText})`, 'gi'))
    return (
      <>
        {parts?.map((part, index) =>
          part?.toLowerCase() === searchText?.toLowerCase() ? (
            <strong key={index} className="font-bold">
              {part}
            </strong>
          ) : (
            part
          ),
        )}
      </>
    )
  }
  //Selecting the items and setting to formik
  const handleMultiSelect = useCallback(
    (item: { value: string; label?: string } | 'all') => {
      let result: string[] = []

      if (isSingleSelect && item !== 'all') {
        formik.setFieldValue(name, item?.value)
        setDropdownOpen(false)
        setSelectValue(item?.label ?? item.value)
        setSearchText('')
        return
      }

      if (item === 'all') {
        result =
          multiSelectedOptions.length === options?.length
            ? []
            : options?.map((option) => option.value) || []
      } else {
        result = multiSelectedOptions.includes(item.value)
          ? multiSelectedOptions.filter((option) => option !== item.value)
          : [...multiSelectedOptions, item.value]
      }

      formik.setFieldValue(name, result)
      setMultiSelectedOptions(result)
    },
    [isSingleSelect, formik, name, options, multiSelectedOptions],
  )

  const handleMultiSelectInputClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      setDropdownOpen((prev) => !prev)
    },
    [],
  )

  /** //////////////////////     HANDLE CLICK OUTSIDE - CLOSE ON CLICK    ////////////////////////////// */
  const inputRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (!isDropdownOpen) {
          setDropdownOpen(true)
        } else if (isDropdownOpen && filteredMultiSelectOptions.length > 0) {
          handleMultiSelect(filteredMultiSelectOptions[0])
          setDropdownOpen(false)
        }
      }
    },
    [isDropdownOpen, filteredMultiSelectOptions, handleMultiSelect],
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [handleKeyDown])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      (inputRef.current && inputRef.current.contains(event.target as Node)) ||
      (dropdownRef.current &&
        dropdownRef.current.contains(event.target as Node))
    ) {
      return
    }
    setDropdownOpen(false)
  }
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isDropdownOpen])
  /** //////////////////////     HANDLE CLICK OUTSIDE - CLOSE ON CLICK    ////////////////////////////// */

  useEffect(() => {
    if (isDropdownOpen) {
      if (searchInputRef.current) {
        searchInputRef.current.focus() // Focus the search input when dropdown opens
      }
      if (dropdownRef.current) {
        dropdownRef.current.scrollTop = 0 // Scroll to the top when dropdown opens
      }
      const dropdownHeight = dropdownRef.current?.offsetHeight || 0
      const inputBottom = inputRef.current?.getBoundingClientRect().bottom || 0
      const viewportHeight = window.innerHeight
      if (viewportHeight - inputBottom < dropdownHeight + 100) {
        setOpenUpwards(true)
      } else {
        setOpenUpwards(false)
      }
    }
  }, [isDropdownOpen])
  const clearSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setSelectValue('')
    formik.setFieldValue(name, '')
  }

  const value = isSingleSelect
    ? selectValue
    : multiSelectedOptions?.length > 0
      ? `${multiSelectedOptions?.length} ${humanize.pluralize(multiSelectedOptions?.length, multiSelectPlaceholderTexts[0], multiSelectPlaceholderTexts[1])} selected`
      : ''

  return (
    <div className={`relative cursor-pointer`} ref={inputRef}>
      {/* Input field for the multiselect */}
      <input
        id={name}
        type="text"
        spellCheck="false"
        autoComplete="off"
        className={`${className} cursor-pointer`}
        placeholder={placeholder}
        disabled={disabled}
        {...restProps}
        value={value}
        onClick={handleMultiSelectInputClick}
      />

      {/* Dropdown arrow */}
      <div
        onClick={(e) => {
          if (!disabled) {
            handleMultiSelectInputClick(e)
          }
        }}
      >
        {selectValue !== '' ? (
          <button
            className={`absolute right-[0.5rem] top-1/2 flex -translate-y-1/2 items-center`}
            onClick={clearSearch}
          >
            <img src={icons.CloseIcon} alt="Clear search" />
          </button>
        ) : (
          <button
            className={`absolute right-[0.5rem] top-1/2 flex -translate-y-1/2 transform items-center duration-300 ${isDropdownOpen ? '-rotate-180' : ''}`}
          >
            <ArrowDown color={disabled ? 'gray' : 'black'} />
          </button>
        )}
      </div>

      {/* Dropdown list */}
      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            id="selector"
            ref={dropdownRef}
            initial="closed"
            animate="open"
            exit="closed"
            variants={dropdownMotionProps}
            className={`table-scroll-bar absolute ${
              openUpwards ? 'bottom-[2.5rem]' : 'top-[2.5rem]'
            }  border-primary-gray-2 max-h-[12rem] min-h-[20px] w-full overflow-auto rounded border bg-[#fff]`}
            style={{
              zIndex: '3',
              boxShadow:
                '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Search input */}
            {hideSearch ? null : (
              <div className="mt-2 flex items-center gap-3 px-2">
                <div className="w-full ">
                  <input
                    type="text"
                    ref={searchInputRef}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search..."
                    className={` h-[28px] w-full rounded-md border px-8  text-sm outline-none p-xs-regular`}
                  />
                  <div className="absolute left-5 top-3">
                    <Search />
                  </div>
                  {title && title !== '' && (
                    <div
                      className="hyperlink p-2"
                      onClick={() => {
                        setDropdownOpen(false)
                        if (onAdd) {
                          onAdd()
                        }
                      }}
                    >
                      {title}
                    </div>
                  )}
                </div>
              </div>
            )}
            {filteredMultiSelectOptions.length > 0 ? (
              <>
                {/* Select All checkbox */}
                {!isSingleSelect && (
                  <div className="px-2 ">
                    <div
                      className="flex   cursor-pointer items-center gap-3 border-t border-[#EBEFF3] px-3 py-[6px] hover:bg-[#f8f8f8] "
                      onClick={() => handleMultiSelect('all')}
                    >
                      <input
                        type="checkbox"
                        checked={
                          multiSelectedOptions.length === (options?.length || 0)
                        }
                        onChange={() => handleMultiSelect('all')}
                        className="cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      />
                      <div className="py-1 p-xs-regular">Select All</div>
                    </div>
                  </div>
                )}

                {/* Options list with checkboxes */}
                <div className="px-2">
                  {filteredMultiSelectOptions.map((item) => (
                    <div
                      key={item.value}
                      className={`flex  ${!item.disabled ? 'cursor-pointer' : 'cursor-auto '} items-center gap-3 border-b border-[#EBEFF3] px-3 py-[6px] hover:bg-[#f8f8f8]`}
                      onClick={() => {
                        if (!item.disabled) {
                          onChangeProp && onChangeProp(item.value)
                          handleMultiSelect(item)
                        }
                      }}
                    >
                      {!isSingleSelect && (
                        <input
                          type="checkbox"
                          checked={
                            Array.isArray(multiSelectedOptions) &&
                            multiSelectedOptions.includes(item.value)
                          }
                          className="cursor-pointer"
                          disabled={!!item.disabled}
                        />
                      )}

                      <div className="my-1 flex flex-col">
                        <span
                          className={`p-xs-regular ${item?.disabled && ' text-disabled'}`}
                        >
                          {highlightSearchText(item.label ?? '', searchText)}
                        </span>
                        <span
                          className={`p-xs-regular-dim ${item?.disabled && ' text-disabled'} `}
                        >
                          {highlightSearchText(item?.sublabel, searchText)}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center border-b border-[#EBEFF3] px-3 py-3">
                <span className="p-xs-regular-dim">
                  {searchText.length > 0
                    ? 'No results found'
                    : 'Data Not Found'}
                </span>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default MultiSelect
