import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CellProps } from 'react-table'
import Chip from 'libs/chip/Chip'
import Popup from 'libs/popup/Popup'
import MotionDiv from 'libs/motionDiv'
import useTabTitle from 'libs/hooks/useTabTitle'
import FilterableSearchTable from 'libs/table/filterable-search-table_new/FilterableSearchTable'
import { hasAccess } from 'utils/utils'
import { formatString } from 'utils/stringHandlers'
import { countryPhoneNumber } from 'utils/phoneNumberValidator'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import { useGetVendorsQuery } from './vendor-endpoints/vendor.endpoints'
import VendorFilterPanel from './panel/VendorFilterPanel'
import DeleteVendor from './delete-vendor/DeleteVendor'
import { Vendor } from './vendor-endpoints/vendorTypes'
import { ExtendedColumn } from 'store/services/utilsTypes'

const filters = {
  vendorType: [],
}

const VendorManagement = () => {
  useTabTitle('Vendor Management')
  const navigate = useNavigate()
  const hasEditAccess = hasAccess('ord', { so: 'e' })

  const [deletePopUp, setDeletePopUp] = useState<{
    isPopUpOpen: boolean
    VendorData: Vendor | null
  }>({
    isPopUpOpen: false,
    VendorData: null,
  })

  const vendorAccess = hasAccess('con', { ven: 'v' })
  const vendorEditAccess = hasAccess('con', { ven: 'e' })

  const columns: ExtendedColumn<Vendor>[] = useMemo(
    () => [
      {
        Header: 'Vendor Name',
        accessor: 'name',
        Cell: ({ row }: CellProps<Vendor>) => {
          const vendor = row.original
          return (
            <div
              className={`${vendorAccess ? 'hyperlink' : ''}`}
              onClick={() => {
                vendorAccess &&
                  vendor?.id &&
                  navigate(
                    `/contacts/vendor-management/view/${vendor.id}/view-basic-details`,
                  )
              }}
            >
              {vendor?.name ?? '-'}
            </div>
          )
        },
      },
      {
        Header: 'Vendor Type',
        accessor: 'vendorType',
        Cell: ({ row }: CellProps<Vendor>) =>
          formatString(row.original.vendorType),
      },

      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ row }: CellProps<Vendor>) => (
          <Chip title={row.original.category} />
        ),
      },

      {
        Header: 'Contact Number',
        accessor: 'primaryContactNumber',
        Cell: ({ row }: CellProps<Vendor>) => {
          const { phoneNumber } = countryPhoneNumber(
            row.original.primaryContactNumber,
          )
          return <span>{phoneNumber ?? '-'}</span>
        },
      },
      {
        Header: 'Email',
        accessor: 'businessEmailAddress',
        Cell: ({ row }: CellProps<Vendor>) =>
          row.original.businessEmailAddress ?? '-',
      },
      {
        Header: 'Primary Address',
        accessor: 'addresses',
        Cell: ({ row }: CellProps<Vendor>) => {
          const value = row.original.addresses
          if (value && value.length > 0) {
            const primaryAddress =
              value?.find((address) => address?.isPrimary) || value[0]
            return (
              <div>
                <p>
                  {primaryAddress?.addressLine1}, {primaryAddress?.city}
                </p>
              </div>
            )
          } else {
            return <div>-</div>
          }
        },
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        accessor: 'id',
        width: 10,
        Cell: ({ row }: CellProps<Vendor>) => {
          return (
            <div className="flex w-full items-center justify-between">
              <button
                className={`mr-2 ${vendorAccess ? '' : 'opacity-40'}`}
                onClick={(e) => {
                  e.stopPropagation()
                  vendorEditAccess &&
                    row?.original?.id &&
                    navigate(`edit/${row.original.id}/basic-details`)
                }}
              >
                <Pen />
              </button>
              <button
                className={`mr-2 ${vendorAccess ? '' : 'opacity-40'}`}
                onClick={(e) => {
                  e.stopPropagation()
                  vendorEditAccess &&
                    row.original &&
                    setDeletePopUp({
                      isPopUpOpen: true,
                      VendorData: row.original,
                    })
                }}
              >
                <Bin />
              </button>
              <span
                className="cursor-pointer"
                onClick={() =>
                  vendorAccess &&
                  row?.original?.id &&
                  navigate(
                    `/contacts/vendor-management/view/${row.original.id}/view-basic-details`,
                  )
                }
              >
                <ClickableRowArrow />
              </span>
            </div>
          )
        },
        disableSortBy: true,
      },
    ],
    [navigate, vendorAccess, vendorEditAccess],
  )

  return (
    <MotionDiv>
      <>
        <h1 className="h4-bold-black">Vendor Management</h1>
        <p className="p-sm-regular-1">
          View the details of your added Vendor and easily add new ones as
          needed.
        </p>

        <FilterableSearchTable
          useQuery={useGetVendorsQuery}
          columns={columns}
          addButton={{
            label: 'Add Vendor',
            onClick: () => navigate('add/basic-details'),
          }}
          placeholders={{
            emptyMessage: 'No vendors have been added. Please add a vendor.',
            filterEmptyMessage: 'No matching results found.',
            search: 'Search Vendor by Name, Type, Contact Number or Email',
          }}
          filterPanelComponent={VendorFilterPanel}
          additionalFilters={filters}
          accessRights={hasEditAccess}
        />
        {deletePopUp?.VendorData && (
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Vendor"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, VendorData: null })
            }}
          >
            <DeleteVendor
              closePopUp={() =>
                setDeletePopUp({ isPopUpOpen: false, VendorData: null })
              }
              vendor={deletePopUp?.VendorData}
            />
          </Popup>
        )}
      </>
    </MotionDiv>
  )
}

export default VendorManagement
