import { useEffect, useState } from "react";
import Bin from "assets/icons/camera-page/Bin";
import PlusCircleIcon from "assets/icons/configure/PlusCircleIcon";
import DeleteIcon from "assets/icons/configure/DeleteIcon";
import { useTranslation } from "react-i18next";

const DropDown = ({
  onFormDataChange,
  errors,
  setErrors,
  data,
}:any) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    options: data?.options ||  [],
    help_text: data?.help_text || "",
  });

  const [stateInputs, setStateInputs] = useState(
    data?.options && data?.options?.length > 0
      ? data?.options
      : [{ id: 1, name: "" }]
  );
  const [isEmpty, setIsEmpty] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [count, setCount] = useState(2);
  const allFieldsFilled = stateInputs.every(
    (input:any) => input.name.trim() !== ""
  );
  useEffect(() => {
    // Check if any input field is empty
    const isEmpty = stateInputs.some((input:any) => input.name.trim() === "");
    setIsEmpty(isEmpty);

    // Check for duplicate values
    const values = stateInputs.map((input:any) => input.name.trim());
    const isDuplicate = new Set(values).size !== values.length;
    setDuplicateError(isDuplicate);

    // Update formData with options and helpText
    setFormData((prevFormData) => ({
      ...prevFormData,
      options: stateInputs,
    }));

    if (allFieldsFilled) {
      setErrors((prevErrors:any) => ({
        ...prevErrors,
        dropdown: "",
        emptyField: "",
      }));
    }
  }, [stateInputs, allFieldsFilled]);

  const handleInputChange = (index:any, event:any) => {
    const { value } = event.target;
    setStateInputs((prevInputs:any) => {
      const updatedInputs = prevInputs.map((input:any, i:any) =>
        i === index ? { ...input, name: value } : input
      );
      return updatedInputs;
    });
    setErrors((prevErrors:any) => ({ ...prevErrors, name: "" }));
  };

  const addStateInput = () => {
    setCount(count + 1);
    if (isEmpty || duplicateError) {
      return;
    }
    const newItemId = count;
    const newInput = { id: newItemId, name: "" };
    setStateInputs((prevInputs:any) => [...prevInputs, newInput]);
  };

  const removeStateInput = (id:any) => {
    if (stateInputs.length === 1) {
      return; // Do not remove the input if only one is present
    }
    const updatedInputs = stateInputs.filter((input:any) => input.id !== id);
    setStateInputs(updatedInputs);
  };

  useEffect(() => {
    // Send formData to parent component
    onFormDataChange(formData);
  }, [formData]);


  return (
    <>
      <div>
        <label className="p-sm-regular-400 text-[#0E1B28]">
          {t("options")} <span className="text-[#DC3545]">*</span>
        </label>
      </div>
      <div className="flex flex-col">
        {stateInputs.map((input:any, index:any) => (
          <div key={input.index} className="flex items-center">
            <input
              type="text"
              name="name"
              value={input.name}
              onChange={(e) => handleInputChange(index, e)}
              className={`w-[340px] h-[28px] bg-[#EBEFF3] border ${
                duplicateError &&
                stateInputs.findIndex(
                  (item:any, i:any) => item.name === input.name && index !== i
                ) !== -1
                  ? "border-[#DC3545]"
                  : "border-[#E0E0E0]"
              } border-solid rounded p-2 text-sm outline-none p-xs-regular mt-4`}
              placeholder={t("enter_option_value")}
            />
            {stateInputs.length === 1 ? (
              <button className="ml-2 mt-3" type="button">
                <Bin />
              </button>
            ) : (
              <button
                onClick={() => removeStateInput(input.id)}
                type="button"
                className="ml-2 mt-3"
              >
                <DeleteIcon />
              </button>
            )}
          </div>
        ))}
      </div>
      {duplicateError && (
        <p className="text-[#DC3545] text-xs font-normal mt-2">
          Please ensure that the option names are unique.
        </p>
      )}

      {errors.dropdown && (
        <p className="text-xs font-normal pt-1 text-[#DC3545]">
          {errors.dropdown}
        </p>
      )}
      {errors.emptyField && (
        <p className="text-xs font-normal pt-1 text-[#DC3545]">
          {errors.emptyField}
        </p>
      )}

      <button
        className={`btn-cancel1 bg-[#FFFFFF] p-xs-regular-dim1 mt-3 border-[1.5px] border-[#1193F0] text-[#1193F0]`}
        type="button"
        onClick={addStateInput}
      >
        <PlusCircleIcon isPressedCard={undefined} />
        {t("add_option")}
      </button>
      <div className="mt-5">
        <label className="mb-1 p-sm-regular ">{t("help_text")}</label>
        <div>
          <p className="p-xs-regular-dim">
            {t("guide_the_person_filling_this_field")}
          </p>
          <div className="relative py-1">
            <input
              type="text"
              name="help_text"
              value={formData.help_text}
              onChange={(e) =>
                setFormData({ ...formData, help_text: e.target.value })
              }
              className={`bg-[#EBEFF3]  w-[360px] h-[28px] border-[#E0E0E0] border-solid border rounded-md p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder={t("enter_help_text")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DropDown;
