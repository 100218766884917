import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useDeleteTimesheetMutation } from 'pages/employee/time-sheet/timesheet-endpoints/timesheet.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { formatUnixDate } from 'utils/dateTimeHandlers'

interface Props {
  closePopUp: any
  deleteState: any
}

const DeleteTimeSheet = ({ closePopUp, deleteState }: Props) => {
  const plant = useSelector(selectSelectedItem)
  const [deleteEntry, { isLoading: deleteEntryLoading }] =
    useDeleteTimesheetMutation()

  const handleDelete = () => {
    deleteEntry({ plant_id: plant?._id, data: { data: [deleteState?.ids] } })
      .unwrap()
      .then(() => {
        closePopUp()
        toast.success(
          <div>
            The timesheet record for{' '}
            <b>
              "Date {formatUnixDate(deleteState?.data?.date, plant?.timezone)}
            </b>{' '}
            is removed successfully.
          </div>,
        )
      })
      .catch((err) => {
        toast.error(
          err?.data?.detail ?? 'Something went wrong. Please try again',
        )
      })
  }

  return (
    <>
      <p className="mb-3 p-xs-regular">
        {`Are you sure you want to delete 1 entries? Once removed, these entries`}
        <span className="p-xs-bold"> cannot be recovered</span>
        <span>
          . However, you can create new entries for making the adjustments.
        </span>
      </p>

      <div className=" flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button
          color="error"
          onClick={handleDelete}
          loading={deleteEntryLoading}
        >
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeleteTimeSheet
