import Field from 'libs/field'
import { t } from 'i18next'
import { useEffect, useRef } from 'react'
import InlineLoader from 'libs/loader/customloader/Loader'
import { formatNumber } from 'utils/numberHandlers'

const TransferForm = ({
  selectedProduct,
  storageAreaToOptions,
  warehouseToOptions,
  warehouseFromOptions,
  storageAreaFromOptions,
  formik,
  unit,
  transaction,
  quantity,
}: any) => {
  const warehouseFrom =
    transaction?.warehouseFrom?.id === formik?.values?.warehouse_from?.id
      ? {
          label: transaction?.warehouseFrom?.name,
          value: transaction?.warehouseFrom?.id,
        }
      : { label: '', value: '' }

  const warehouseTo =
    transaction?.warehouseTo?.id === formik?.values?.warehouse_to?.id
      ? {
          label: transaction?.warehouseTo?.name,
          value: transaction?.warehouseTo?.id,
        }
      : { label: '', value: '' }

  const storageAreaFrom =
    transaction?.warehouseFrom?.id === formik?.values?.warehouse_from?.id
      ? {
          label: transaction?.storageAreaFrom?.name,
          value: transaction?.storageAreaFrom?.id,
        }
      : { label: '', value: '' }

  const storageAreaTo =
    transaction?.warehouseTo?.id === formik?.values?.warehouse_to?.id
      ? {
          label: transaction?.storageAreaTo?.name,
          value: transaction?.storageAreaTo?.id,
        }
      : { label: '', value: '' }

  const isInitialRenderTo = useRef(true)
  const isInitialRenderFrom = useRef(true)

  useEffect(() => {
    if (isInitialRenderTo.current) {
      isInitialRenderTo.current = false
    } else {
      formik.setFieldValue('storagearea_to', null)
    }
  }, [formik?.values?.warehouse_to, selectedProduct?._id])

  useEffect(() => {
    if (isInitialRenderFrom.current) {
      isInitialRenderFrom.current = false
    } else {
      formik.setFieldValue('storagearea_from', null)
    }
  }, [formik?.values?.warehouse_from, selectedProduct?._id])

  return (
    <div className="grid w-2/3 grid-cols-2 gap-2 gap-x-4">
      <Field
        label={t('inventory_.warehouse_from')}
        name="warehouse_from"
        type="select"
        required={true}
        defaultValue={
          warehouseFrom.value == formik?.values.warehouse_from?.id
            ? warehouseFrom
            : formik?.values.warehouse_from === null
              ? ''
              : formik?.values.warehouse_from?.name
        }
        placeholder={t('inventory_.select')}
        formik={formik}
        options={warehouseFromOptions}
        disabled={!selectedProduct}
      />
      <Field
        label={t('inventory_.warehouse_to')}
        name="warehouse_to"
        type="select"
        required={true}
        placeholder={t('inventory_.select')}
        formik={formik}
        defaultValue={
          warehouseTo.value == formik?.values.warehouse_to?.id
            ? warehouseTo
            : formik?.values.warehouse_to === null
              ? ''
              : formik?.values.warehouse_to?.name
        }
        options={warehouseToOptions}
        disabled={!selectedProduct}
      />
      <Field
        label={t('inventory_.storage_area_from')}
        name="storagearea_from"
        disabled={!formik.values.warehouse_from}
        type="select"
        required={true}
        placeholder={t('inventory_.select')}
        formik={formik}
        defaultValue={
          storageAreaFrom.value == formik?.values.storagearea_from?.id
            ? storageAreaFrom
            : formik?.values.storagearea_from?.id === null
              ? ''
              : formik?.values.storagearea_from?.name
        }
        description={t('inventory_.storage_area_desc')}
        options={storageAreaFromOptions}
      />
      <Field
        label={t('inventory_.storage_area_to')}
        name="storagearea_to"
        disabled={!formik.values.warehouse_to}
        type="select"
        required={true}
        defaultValue={
          storageAreaTo.value == formik?.values.storagearea_to?.id
            ? storageAreaTo
            : formik?.values.storagearea_to?.id === null
              ? ''
              : formik?.values.storagearea_to?.name
        }
        placeholder={t('inventory_.select')}
        formik={formik}
        description={t('inventory_.storage_area_desc')}
        options={storageAreaToOptions}
      />
      <div>
        <Field
          label={t('inventory_.quantity')}
          name="quantity"
          suffix={unit}
          type="number"
          disabled={
            !formik?.values.storagearea_from?.id ||
            !formik?.values.storagearea_to?.id
          }
          required={true}
          placeholder={t('inventory_.quantity_placeholder')}
          formik={formik}
        />
        {quantity.isFetching || quantity.isLoading ? (
          <InlineLoader color="primary" />
        ) : (
          quantity.data != null && (
            <span className="flex items-center gap-1 text-base font-bold">
              <p>Available Quantity : </p>
              <p>{formatNumber(quantity.data)}</p>
            </span>
          )
        )}
      </div>
    </div>
  )
}

export default TransferForm
