import { Link, useLocation, useNavigate } from 'react-router-dom'
import Modal from './modal/Modal'
import Cookies from 'js-cookie'
import { useState, useMemo, useEffect } from 'react'
import { AlertIcon, HidePasswordIcon, LeftArrowIcon, SuccessIcon, TickIcon, ViewPasswordIcon } from 'assets/icons'
import SpinLoader from 'libs/loader/mega-spin-loader/Loader'
import { MiniSpinLoader } from 'libs/loader'
import { authErrors } from '../../../../mocks/ErrorMessages'
import { useUpdateTempPasswordMutation } from './auth.endpoints'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

interface LoginResponse {
  data?: {
    is_temperory?: boolean
    session?: string
    expires_in?: number
    user_info?: {
      role?: string
    }
  }
  error?: any
}
type SuccessData = {
  data: any
  error?: undefined
}

type ErrorData = {
  data?: undefined
  error: FetchBaseQueryError | SerializedError
}

type Data = SuccessData | ErrorData | LoginResponse

const TempPassword = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [focusedInput, setFocusedInput] = useState<string | null>(null)
  const [isPasswordResetClick, setIsPasswordResetClick] = useState<boolean>(false)
  // const [
  //   loginUser,
  //   { data: loginData, isLoading: isLoginLoading, error: loginErr },
  // ] = useLoginUserMutation();
  const [resetPassword] = useUpdateTempPasswordMutation()
  const [enterPassword, setEnterPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isEnterPasswordVisible, setIsEnterPasswordVisible] = useState(false)
  const locationState = location.state
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)
  const [isEnterPasswordValid, setIsEnterPasswordValid] = useState(false)
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false)
  const [showConfirmPasswordErr, setShowConfirmPasswordErr] = useState(false)
  const [showEnterPasswordErr, setShowEnterPasswordErr] = useState(false)
  const [isUppercase, setIsUppercase] = useState(false)
  const [isSpecialChar, setIsSpecialChar] = useState(false)
  const [isNumber, setIsNumber] = useState(false)
  const [userData, setUserData] = useState<any>({})
  const [isMinLength, setIsMinLength] = useState(false)

  const toggleEnterPasswordVisibility = () => {
    setIsEnterPasswordVisible((prevState) => !prevState)
  }
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prevState) => !prevState)
  }
  const handleEnterPasswordChange = (event: any) => {
    setEnterPassword(event.target.value)
  }
  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value)
  }
  const handleLogIn = async () => {
    if (!isEnterPasswordValid || !isConfirmPasswordValid) {
      if (!isEnterPasswordValid) setShowEnterPasswordErr(true)
      setShowConfirmPasswordErr(true)
      return
    }
    setIsPasswordResetClick(true)
    await resetPassword({
      email: locationState?.email,
      new_password: enterPassword,
      session: locationState?.session,
    }).then((res: Data) => {
      if (res?.data?.detail == 'Incorrect username or password') {
        setIsEnterPasswordValid(false)
        setIsConfirmPasswordValid(false)
        setShowEnterPasswordErr(true)
        setShowConfirmPasswordErr(true)
        setIsPasswordResetClick(false)
        if (res?.error?.status === 404) {
          alert('Email Address does not exists!')
          navigate('/auth/forgot-password')
        }
      } else {
        setIsPasswordResetClick(false)
        setIsLoggedIn(true)

        // Cookies.set(
        //   "catalyst-user-data",
        //   JSON.stringify(locationState?.userData),
        //   {
        //     expires: 60,
        //   }
        // );
        setTimeout(() => {
          navigate('auth/login')
        }, 1500)
      }
    })
  }
  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (focusedInput === 'enter-password') {
        document.getElementById('confirmPasswordInput')?.focus()
      } else if (focusedInput === 'confirm-password' && isConfirmPasswordValid && isEnterPasswordValid) {
        await handleLogIn()
      } else if (focusedInput === 'confirm-password' && (!isConfirmPasswordValid || !isEnterPasswordValid))
        setShowConfirmPasswordErr(true)
      if (!isEnterPasswordValid) setShowEnterPasswordErr(true)
    }
  }
  useEffect(() => {
    let userdata = Cookies.get('catalyst-user-data')
    let user = userdata && JSON.parse(userdata)
    setUserData(user?.data?.user_info)
  }, [])
  useMemo(() => {
    setIsUppercase(/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(enterPassword))
    setIsSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(enterPassword))
    setIsNumber(/[0-9]/.test(enterPassword))
    setIsMinLength(enterPassword.length >= 8)
  }, [enterPassword])
  useMemo(() => {
    setShowConfirmPasswordErr(false)
    setIsConfirmPasswordValid(enterPassword === confirmPassword)
  }, [confirmPassword, enterPassword])
  useMemo(() => {
    setShowEnterPasswordErr(false)
    setIsEnterPasswordValid(isUppercase && isSpecialChar && isNumber && isMinLength)
  }, [isUppercase, isSpecialChar, isNumber, isMinLength, confirmPassword])
  return (
    <Modal
      name={isLoggedIn && userData?.first_name}
      heading={isLoggedIn ? 'Welcome Back ' : 'Set up a New Password'}
      textSize={true}
    >
      {!isLoggedIn && (
        <>
          {' '}
          <div className="mb-4 mt-2 flex gap-x-4 py-1">
            <Link to={'/auth/login'}>
              <LeftArrowIcon />
            </Link>
            <p>Create new password</p>
          </div>
          <div className="mb-3.5">
            <p className="text-xs font-normal text-[#75838D] ">
              Your new password must be different from temporary password.
            </p>
          </div>
          <div className="mb-0.5">
            <div className="relative flex flex-col">
              <label className="font-inter text-xs font-normal text-[#212121]">Enter password</label>
              <div
                onMouseLeave={() => setShowEnterPasswordErr(!isEnterPasswordValid)}
                onMouseEnter={() => setShowEnterPasswordErr(false)}
              >
                <input
                  style={{
                    border: `1px solid ${
                      showEnterPasswordErr && enterPassword !== ''
                        ? '#8E0000'
                        : isEnterPasswordValid
                          ? '#28A745'
                          : '#fff'
                    }`,
                  }}
                  className={`${
                    showEnterPasswordErr && enterPassword !== ''
                      ? 'bg-[#F8D7DA] text-[#8E0000]'
                      : isEnterPasswordValid
                        ? 'bg-[#DDFFE5] text-[#28A745]'
                        : 'bg-[#EBEFF3] text-[#000]'
                  } my-2 w-full rounded px-5 py-3.5 text-sm  font-normal`}
                  type={isEnterPasswordVisible ? 'text' : 'password'}
                  placeholder="Enter new password"
                  onChange={handleEnterPasswordChange}
                  onFocus={() => {
                    setFocusedInput('enter-password')
                    setShowEnterPasswordErr(false)
                  }}
                  onKeyDown={handleKeyDown}
                />
                <button
                  className="absolute right-0 top-10 px-4 text-gray-600"
                  onClick={() => (!showEnterPasswordErr || enterPassword !== '') && toggleEnterPasswordVisibility()}
                >
                  {showEnterPasswordErr && enterPassword !== '' ? (
                    <AlertIcon />
                  ) : isEnterPasswordVisible && enterPassword !== '' ? (
                    <HidePasswordIcon
                      width={20}
                      height={12}
                      color={`${showEnterPasswordErr ? '#8E0000' : isEnterPasswordValid ? '#28A745' : '#75838D'}`}
                    />
                  ) : (
                    enterPassword !== '' && (
                      <ViewPasswordIcon
                        width={24}
                        height={24}
                        color={`${showEnterPasswordErr ? '#8E0000' : isEnterPasswordValid ? '#28A745' : '#75838D'}`}
                      />
                    )
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="mb-1.5">
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isUppercase ? '#28A745' : '#8E0000'}
              />
              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isUppercase ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Has at least one uppercase and one lowercase letter
              </span>
            </p>
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isSpecialChar ? '#28A745' : '#8E0000'}
              />

              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isSpecialChar ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Has at least one special character
              </span>
            </p>
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isNumber ? '#28A745' : '#8E0000'}
              />
              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isNumber ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Has at least one number
              </span>
            </p>
            <p className="flex gap-x-2 pb-1">
              <TickIcon
                width={12}
                height={12}
                classProp="my-auto"
                color={enterPassword === '' ? '#45494D' : isMinLength ? '#28A745' : '#8E0000'}
              />
              <span
                className={`${
                  enterPassword === '' ? 'text-[#45494D]' : isMinLength ? 'text-[#28A745]' : 'text-[#8E0000]'
                } font-roboto text-xs font-normal leading-4`}
              >
                Min 8 characters
              </span>
            </p>
          </div>
          <div className="relative flex flex-col">
            <label className="font-inter text-xs font-normal text-[#212121]">Confirm password</label>
            <div>
              <input
                id="confirmPasswordInput"
                style={{
                  border: `1px solid ${confirmPassword === '' || !showConfirmPasswordErr ? '#fff' : '#8E0000'}`,
                }}
                className={`${
                  confirmPassword === '' || !showConfirmPasswordErr
                    ? 'bg-[#EBEFF3] text-[#000]'
                    : 'bg-[#F8D7DA] text-[#8E0000]'
                } my-2 w-full rounded px-5 py-3.5 text-sm  font-normal`}
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                placeholder="Confirm password"
                onFocus={() => setFocusedInput('confirm-password')}
                onChange={handleConfirmPasswordChange}
                onKeyDown={handleKeyDown}
              />
              <button className="absolute right-0 top-10 px-4 text-gray-600" onClick={toggleConfirmPasswordVisibility}>
                {confirmPassword !== '' && showConfirmPasswordErr ? (
                  <AlertIcon />
                ) : isConfirmPasswordVisible && confirmPassword !== '' ? (
                  <HidePasswordIcon
                    width={20}
                    height={12}
                    color={`${confirmPassword === '' || !showConfirmPasswordErr ? '#75838D' : '#8E0000'}`}
                  />
                ) : (
                  confirmPassword !== '' && (
                    <ViewPasswordIcon
                      width={24}
                      height={24}
                      color={`${confirmPassword === '' || !showConfirmPasswordErr ? '#75838D' : '#8E0000'}`}
                    />
                  )
                )}
              </button>
            </div>
            <span className="font-inter text-xs text-[#8E0000]">
              {showConfirmPasswordErr &&
                isEnterPasswordValid &&
                confirmPassword !== '' &&
                `${authErrors.INVALID_RESET_PASSWORD_COMBINATION}`}
              {showConfirmPasswordErr &&
                !isEnterPasswordValid &&
                confirmPassword !== '' &&
                `${authErrors.INVALID_NEW_PASSWORD}`}
            </span>
          </div>
          <div className="mt-4">
            <button
              onClick={() => confirmPassword !== '' && isEnterPasswordValid && handleLogIn()}
              className={`${
                confirmPassword !== '' && isEnterPasswordValid
                  ? 'cursor-pointer bg-[#0B5ED7]'
                  : 'cursor-default bg-[#75838D]'
              } w-full rounded px-8 py-2.5 text-[#fff] `}
            >
              Reset Password {isPasswordResetClick && <MiniSpinLoader />}
            </button>
          </div>
        </>
      )}
      {isLoggedIn && (
        <div className="">
          <div className="mx-auto">
            <SuccessIcon classProp="mx-auto mt-8" />
            <p className="py-4 text-center text-lg font-medium text-[#28A745]">Password updated Successfully.</p>
          </div>
          <div className="mx-auto mb-[7rem]">
            <SpinLoader />
          </div>
        </div>
      )}
    </Modal>
  )
}

export default TempPassword
