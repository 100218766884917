const AssignedInfo = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_24573_58710"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_24573_58710)">
        <path
          d="M14 7V5H22V7H14ZM14 11V9H22V11H14ZM14 15V13H22V15H14ZM8 14C7.16667 14 6.45833 13.7083 5.875 13.125C5.29167 12.5417 5 11.8333 5 11C5 10.1667 5.29167 9.45833 5.875 8.875C6.45833 8.29167 7.16667 8 8 8C8.83333 8 9.54167 8.29167 10.125 8.875C10.7083 9.45833 11 10.1667 11 11C11 11.8333 10.7083 12.5417 10.125 13.125C9.54167 13.7083 8.83333 14 8 14ZM2 20V18.1C2 17.75 2.08333 17.4167 2.25 17.1C2.41667 16.7833 2.65 16.5333 2.95 16.35C3.7 15.9 4.49583 15.5625 5.3375 15.3375C6.17917 15.1125 7.06667 15 8 15C8.93333 15 9.82083 15.1125 10.6625 15.3375C11.5042 15.5625 12.3 15.9 13.05 16.35C13.35 16.5333 13.5833 16.7833 13.75 17.1C13.9167 17.4167 14 17.75 14 18.1V20H2ZM4.15 18H11.85C11.2667 17.6667 10.65 17.4167 10 17.25C9.35 17.0833 8.68333 17 8 17C7.31667 17 6.65 17.0833 6 17.25C5.35 17.4167 4.73333 17.6667 4.15 18ZM8 12C8.28333 12 8.52083 11.9042 8.7125 11.7125C8.90417 11.5208 9 11.2833 9 11C9 10.7167 8.90417 10.4792 8.7125 10.2875C8.52083 10.0958 8.28333 10 8 10C7.71667 10 7.47917 10.0958 7.2875 10.2875C7.09583 10.4792 7 10.7167 7 11C7 11.2833 7.09583 11.5208 7.2875 11.7125C7.47917 11.9042 7.71667 12 8 12Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  )
}
export default AssignedInfo
