import { t } from 'i18next'
import Button from 'libs/button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import { formatString } from 'utils/stringHandlers'
import { useLocation } from 'react-router-dom'
import NewEditableTable from 'libs/table/NewEditableTable'
import QuickAddEditPurchaseOrder from 'libs/quick-add-edits/quick-add-edit-po/QuickAddEditPoPanel'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { filters } from 'pages/work-orders/work-orders-endpoints/workordersType'
import { useGetPurchaseOrdersQuery } from 'pages/orders/purchase-order/purchase-orders-endpoints/purchaseOrders.endpoints'
import Popup from 'libs/popup/Popup'
import SaveChanges from '../popup/SaveChanges'

const PurchaseOrder = ({
  setPage,
  setId,
  manufactureOrderFormik,
  loading,
}: any) => {
  const { id } = useParams()
  const plantId = useSelector(selectSelectedItem)
  const navigate = useNavigate()
  const location = useLocation()

  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<any>(null)
  const isEditMO = location.pathname.includes('edit')
  const [saveChangesPopUp, setSaveChangesPopUp] = useState<{
    isPopUpOpen: boolean
    moOrder: any
  }>({
    isPopUpOpen: false,
    moOrder: null,
  })

  const purchaseOrderFilter: filters = {
    page_no: 1,
    page_size: 1000,
  }

  useEffect(() => {
    setId({ id: id })
    setPage(3)
  }, [])

  const { data: purchaseOrders, isLoading: isPurchaseOrderLoading } =
    useGetPurchaseOrdersQuery(
      {
        plant_id: plantId?.id,
        filters: purchaseOrderFilter,
      },
      {
        skip: !plantId?.id,
      },
    )

  const purchaseOrdersFormik = useFormik({
    initialValues: {
      should_is_draft: false,
    },
    onSubmit: (values) => {
      if (values.should_is_draft) {
        manufactureOrderFormik.setFieldValue('isDraft', true)
        manufactureOrderFormik.handleSubmit()
      } else {
        manufactureOrderFormik.handleSubmit()
      }
    },
  })

  const purchaseOrderOptions = useMemo(
    () =>
      purchaseOrders?.data?.map((item: any) => ({
        id: item?.purchaseOrderID,
        label: item?.purchaseOrderID,
        value: {
          _id: item?.id,
          purchaseOrderID: item?.purchaseOrderID,
          supplierName: item?.vendor?.name,
          products: item?.products?.map((product: any) => ({
            product_id: product?.id,
            quantity: product?.quantity,
          })),
          arrivalDate: item?.arrivalDate,
          status: item?.status,
        },
      })),
    [purchaseOrders?.data],
  )

  const purchaseOrdersColumns = [
    {
      title: 'Purchase Order ID',
      accessor: 'purchaseOrderID',
      flex: 2,
      main: true,
      editable: true,
      placeholder: 'Select Purchase Order',
    },

    {
      title: 'Supplier Name',
      accessor: 'supplierName',
    },
    {
      title: 'Raw Materials',
      accessor: 'products',
      render(value: any) {
        return value ? `${value?.length} products` : '-'
      },
    },
    {
      title: 'Arrival Date',
      accessor: 'arrivalDate',
      render(value: any) {
        return formatUnixDate(value)
      },
    },
    {
      title: 'Status',
      accessor: 'status',
      render(value: any) {
        return formatString(value)
      },
    },
  ]

  const handleEdit = useCallback((purchase: any) => {
    setSelectedPurchaseOrder(purchase)
    setIsPanelOpen(true)
  }, [])

  const [data, setData] = useState<any[]>()
  useEffect(() => {
    const d = manufactureOrderFormik.values.purchaseOrders
    if (d != null && d.length > 0) {
      setData(d)
    }
  }, [manufactureOrderFormik.values.purchaseOrders])
  const handleSaveChanges = () => {
    setSaveChangesPopUp({ isPopUpOpen: true, moOrder: manufactureOrderFormik })
  }

  return (
    <div className="relative flex min-h-[70vh] flex-col gap-4 ">
      <>
        {isPurchaseOrderLoading ? (
          <div className="absolute left-1/2 top-1/2">
            {' '}
            <FullPageLoader />
          </div>
        ) : (
          <>
            <div className="flex justify-between">
              <div>
                <h5 className="h5-1">{'Purchase Orders'}</h5>
                <p className="p-sm-regular-2">
                  {
                    'Select any Purchase orders you have in connection with this MO.'
                  }
                </p>
              </div>

              <Button
                startIcon={<PlusIcon />}
                color="primary"
                onClick={() => {
                  setIsPanelOpen(true)
                  setSelectedPurchaseOrder(null)
                }}
              >
                {'Add Purchase Order'}
              </Button>
            </div>
            <NewEditableTable
              defaultRows={manufactureOrderFormik.values.purchaseOrders}
              options={purchaseOrderOptions}
              columns={purchaseOrdersColumns}
              uniqueIdInOptions="purchaseOrderID"
              addButtonTitle="Select Purchase Order"
              onChange={(values: any) => {
                setData(values)
              }}
              onEdit={handleEdit}
            />
            <QuickAddEditPurchaseOrder
              id={selectedPurchaseOrder?.purchaseOrderID?._id}
              onClose={() => setIsPanelOpen(false)}
              isOpen={isPanelOpen}
              manufactureOrderFormik={manufactureOrderFormik}
              data={data}
            />
            <div className="flex flex-grow  gap-3 "></div>

            <div className="mt-4 flex justify-between">
              <div className="flex gap-3">
                <Button type="button" onClick={handleSaveChanges}>
                  {t('cancel')}
                </Button>
                <Popup
                  isOpen={saveChangesPopUp.isPopUpOpen}
                  title={'Save Changes?'}
                  onClose={() => {
                    setSaveChangesPopUp({ isPopUpOpen: false, moOrder: null })
                  }}
                >
                  <SaveChanges
                    manufactureOrderFormik={manufactureOrderFormik}
                    data={data}
                    setPage={setPage}
                    tabName="purchaseOrders"
                    loading={
                      !manufactureOrderFormik.values.isDraft ? loading : false
                    }
                  />
                </Popup>
                {false && !isEditMO && (
                  <Button
                    loading={
                      manufactureOrderFormik.values.isDraft ? loading : false
                    }
                    color="primary"
                    type="submit"
                    onClick={() => {
                      purchaseOrdersFormik.setFieldValue(
                        'should_is_draft',
                        true,
                      )
                      purchaseOrdersFormik.handleSubmit()
                    }}
                  >
                    {t('plannings.save_as_draft')}
                  </Button>
                )}
              </div>
              <div className="flex gap-3">
                <Button
                  onClick={() => {
                    setPage(3)
                    isEditMO
                      ? navigate(`/planning/mo/${id}/edit/work-orders`)
                      : navigate(`/planning/mo/${id}/add/work-orders`)
                  }}
                >
                  {'Skip this step'}
                </Button>

                <Button
                  onClick={() => {
                    setPage(3)
                    isEditMO
                      ? navigate(`/planning/mo/${id}/edit/sales-orders`)
                      : navigate(`/planning/mo/${id}/add/sales-orders`)
                  }}
                >
                  {'Previous'}
                </Button>
                <Button
                  loading={
                    !manufactureOrderFormik.values.isDraft ? loading : false
                  }
                  color="success"
                  type="submit"
                  onClick={() => {
                    manufactureOrderFormik.setFieldValue('purchaseOrders', data)
                    manufactureOrderFormik.handleSubmit()
                  }}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  )
}

export default PurchaseOrder
