import { useTranslation } from 'react-i18next'
import Button from 'libs/button/Button'

const ViewDepartment = ({ department, close, handleUpdate, handleDelete }: any) => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow">
        <div className="flex justify-between ">
          <div className="mb-2 p-xs-regular-dim">{t('departments.department_name')}</div>
          <div>
            <div className="w-60 break-words p-xs-regular">{department.name}</div>
          </div>
        </div>

        {department.description && (
          <div className="flex justify-between">
            <div className="mb-2 p-xs-regular-dim">{t('departments.description')}</div>
            <div>
              <div className="w-60 break-words p-xs-regular">{department.description}</div>
            </div>
          </div>
        )}

        <div className="flex justify-between">
          <div className="mb-2 p-xs-regular-dim">{t('departments.associated_process')}</div>
          <div>
            {department.processes && department.processes.length > 0 ? (
              <div className="flex w-60 flex-wrap p-xs-regular">
                {department.processes.map((process: any, index: any) => (
                  <span key={index} className={`bg-lightGray mb-1 mr-1 rounded-[28px] px-2 py-0.5 text-gray-800`}>
                    {process.name}
                  </span>
                ))}
              </div>
            ) : (
              <div className="flex w-60 flex-wrap p-xs-regular-dim">
                Currently, there are no linked processes for this department. Once linked, you'll see them listed here.
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-auto">
        <div className="mt-5 flex justify-between">
          <Button color="error" onClick={() => handleDelete(department)}>
            {t('departments.delete')}
          </Button>
          <div className="flex gap-2">
            <Button onClick={close}>{t('departments.close')}</Button>
            <Button color="primary" onClick={() => handleUpdate(department)}>
              {t('departments.edit')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewDepartment
