import AssignedInfo from 'assets/icons/maintenance/AssignedInfo'
import Info from 'assets/icons/maintenance/Info'
import TaskDetails from 'assets/icons/maintenance/TaskDetails'
import Button from 'libs/button/Button'
import { useGetMaintenanceOrderByIdQuery } from '../maintenance-order-endpoints/order.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import Popup from 'libs/popup/Popup'
import StartOrderPopup from '../popups/StartOrderPopup'
import { useState } from 'react'

import CancleOrderPopup from '../popups/CancleOrderPopup'
import CloseMaintenanceOrderPopup from '../popups/CloseOrderPopup'
import DeleteMaintenanceOrderPopup from '../popups/DeleteMaintenanceOrderPopup'
import FullPageLoader from 'libs/loader/FullPageLoader'

const BasicDetails = () => {
  const plantId = useSelector(selectSelectedItem)
  const params = useParams()
  const navigate = useNavigate()
  const [startOrderPopup, setStartOrderPopup] = useState({
    isPopUpOpen: false,
    maintenanceOrder: '',
  })
  const [cancelPopUp, setCancelPopUp] = useState({
    isPopUpOpen: false,
    maintenanceOrderID: '',
  })
  const [deletePopUp, setDeletePopUp] = useState({
    isPopUpOpen: false,
    maintenanceOrderID: '',
  })
  const [closePopUp, setClosePopUp] = useState({
    isPopUpOpen: false,
    maintenanceOrderID: '',
  })
  const { data: maintenanceOrder, isLoading: isMaintenanceOrderLoading } =
    useGetMaintenanceOrderByIdQuery(
      {
        plantId: plantId?.id,
        moID: params?.id,
      },
      {
        skip: !params?.id || !plantId?.id,
      },
    )
  const deadline = formatUnixDate(maintenanceOrder?.deadline, plantId?.timezone)
  const createdOn = formatUnixDate(
    maintenanceOrder?.created_on,
    plantId?.timezone,
  )
  const handleStartPopup = () => {
    setStartOrderPopup({
      isPopUpOpen: true,
      maintenanceOrder: maintenanceOrder,
    })
  }

  return (
    <>
      {isMaintenanceOrderLoading ? (
        <div className="relative top-52 flex">
          <FullPageLoader />
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <h1 className="text-xl font-medium text-brand-primary-shade-3">
              Basic Details
            </h1>
            {maintenanceOrder?.status != 'CLOSED' && (
              <div className="flex gap-2">
                {maintenanceOrder?.status !== 'CANCELLED' && (
                  <>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation()
                        setCancelPopUp({
                          isPopUpOpen: true,
                          maintenanceOrderID: maintenanceOrder,
                        })
                      }}
                    >
                      Cancel Order
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(
                          `/maintenance/maintenance-order/edit/${maintenanceOrder?._id}`,
                        )
                      }
                    >
                      Edit
                    </Button>
                  </>
                )}

                {maintenanceOrder?.status == 'IN_PROGRESS' ? (
                  <Button
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation()
                      setClosePopUp({
                        isPopUpOpen: true,
                        maintenanceOrderID: maintenanceOrder,
                      })
                    }}
                  >
                    {' '}
                    Close
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation()
                        setDeletePopUp({
                          isPopUpOpen: true,
                          maintenanceOrderID: maintenanceOrder,
                        })
                      }}
                    >
                      {' '}
                      Delete
                    </Button>
                    {maintenanceOrder?.status == 'ASSIGNED' && (
                      <Button color="primary" onClick={handleStartPopup}>
                        Start
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div>
            <div className="mb-4 mt-8 flex justify-between">
              {/* General Information */}
              <div>
                <h1 className="mb-4 flex items-center gap-2 text-lg font-bold">
                  <Info />
                  General Information
                </h1>
                <div className="flex gap-4">
                  <div className="flex flex-col gap-4">
                    <h1 className="text-xs text-gray-600">Order ID</h1>
                    <h1 className="text-xs text-gray-600">Task Name</h1>
                    <h1 className="text-xs text-gray-600">Priority Level</h1>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h1 className="text-xs">{maintenanceOrder?.orderID}</h1>
                    <h1 className="text-xs">{maintenanceOrder?.task_name}</h1>
                    <h1 className="text-xs">
                      {maintenanceOrder?.priority_level}
                    </h1>
                  </div>
                </div>
              </div>
              {/* Requested By */}
              <div className="mr-6 flex gap-4">
                <div className="flex flex-col gap-2">
                  <h1 className="text-xs text-gray-600">Requested by</h1>
                  {/* <h1 className="text-xs text-gray-600">Approved by</h1> */}
                  {maintenanceOrder?.order_history?.started_by && (
                    <h1 className="text-xs text-gray-600">Started by</h1>
                  )}

                  {maintenanceOrder?.order_history?.cancelled_by && (
                    <h1 className="text-xs text-gray-600">Cancelled by</h1>
                  )}
                  {maintenanceOrder?.order_history?.closed_by && (
                    <h1 className="text-xs text-gray-600">Closed by</h1>
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="text-xs">
                    {maintenanceOrder?.created_by?.first_name}{' '}
                    {maintenanceOrder?.created_by?.last_name}
                  </h1>

                  {maintenanceOrder?.order_history?.started_by && (
                    <h1 className="text-xs">
                      {maintenanceOrder?.order_history?.started_by?.first_name}{' '}
                      {maintenanceOrder?.order_history?.started_by?.last_name}
                    </h1>
                  )}
                  {maintenanceOrder?.order_history?.cancelled_by && (
                    <h1 className="text-xs">
                      {
                        maintenanceOrder?.order_history?.cancelled_by
                          ?.first_name
                      }{' '}
                      {maintenanceOrder?.order_history?.cancelled_by?.last_name}
                    </h1>
                  )}
                  {maintenanceOrder?.order_history?.closed_by && (
                    <h1 className="text-xs">
                      {maintenanceOrder?.order_history?.closed_by?.first_name}{' '}
                      {maintenanceOrder?.order_history?.closed_by?.last_name}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Assigned Information */}
          <div className="my-4">
            <h1 className="mb-4 flex items-center gap-2 text-lg font-bold">
              <AssignedInfo />
              Assigned Information
            </h1>
            <div className="flex gap-4">
              <div className="flex flex-col gap-4">
                <h1 className="text-xs text-gray-600">Assignees</h1>
                <h1 className="text-xs text-gray-600">Date Created</h1>
                <h1 className="text-xs text-gray-600">Deadline</h1>
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="flex text-xs">
                  {maintenanceOrder?.assignees?.map(
                    (item: any, index: number) => {
                      return (
                        <span key={item.id}>
                          {item.first_name} {item.last_name}
                          {index < maintenanceOrder?.assignees.length - 1 &&
                            ', '}
                        </span>
                      )
                    },
                  )}
                </h1>
                <h1 className="text-xs">{createdOn}</h1>
                <h1 className="text-xs">{deadline}</h1>
              </div>
            </div>
          </div>
          {/* Task Details */}
          <div className="my-4 w-8/12">
            <h1 className="mb-4 flex items-center gap-2 text-lg font-bold">
              <TaskDetails />
              Task Details
            </h1>
            <div className="flex gap-4">
              <div className="flex flex-col gap-4">
                <h1 className="text-xs text-gray-600">Machines</h1>
                <h1 className="text-xs text-gray-600">Reason</h1>
                <h1 className="text-xs text-gray-600">Description</h1>
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="text-xs">{maintenanceOrder?.machine}</h1>
                <h1 className="text-xs">{maintenanceOrder?.reason}</h1>
                <h1 className="text-xs">{maintenanceOrder?.description}</h1>
              </div>
            </div>
          </div>
          <Popup
            isOpen={startOrderPopup.isPopUpOpen}
            title="Start Maintenance Order"
            onClose={() =>
              setStartOrderPopup({ isPopUpOpen: false, maintenanceOrder: '' })
            }
          >
            <StartOrderPopup
              closePopup={() =>
                setStartOrderPopup({ isPopUpOpen: false, maintenanceOrder: '' })
              }
              maintenanceOrder={startOrderPopup.maintenanceOrder}
            />
          </Popup>
          <Popup
            isOpen={cancelPopUp.isPopUpOpen}
            title={`Cancel ${maintenanceOrder?.orderID}?`}
            onClose={() => {
              setCancelPopUp({ isPopUpOpen: false, maintenanceOrderID: '' })
            }}
          >
            <CancleOrderPopup
              closePopUp={() =>
                setCancelPopUp({ isPopUpOpen: false, maintenanceOrderID: '' })
              }
              maintenanceOrder={cancelPopUp.maintenanceOrderID}
              plant_id={plantId?.id}
            />
          </Popup>
          <Popup
            isOpen={closePopUp.isPopUpOpen}
            title="Closing Order?"
            onClose={() => {
              setClosePopUp({ isPopUpOpen: false, maintenanceOrderID: '' })
            }}
          >
            <CloseMaintenanceOrderPopup
              closePopUp={() =>
                setClosePopUp({ isPopUpOpen: false, maintenanceOrderID: '' })
              }
              maintenanceOrder={closePopUp.maintenanceOrderID}
              plant_id={plantId?.id}
            />
          </Popup>
          <Popup
            isOpen={deletePopUp.isPopUpOpen}
            title="Delete Maintenance Order"
            onClose={() => {
              setDeletePopUp({ isPopUpOpen: false, maintenanceOrderID: '' })
            }}
          >
            <DeleteMaintenanceOrderPopup
              closePopUp={() =>
                setDeletePopUp({ isPopUpOpen: false, maintenanceOrderID: '' })
              }
              maintenanceOrder={deletePopUp.maintenanceOrderID}
              plant_id={plantId._id}
            />
          </Popup>
        </>
      )}
    </>
  )
}

export default BasicDetails
