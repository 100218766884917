function MoreIcon() {
  return (
    <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.00016 13.6667C1.54183 13.6667 1.14947 13.5035 0.82308 13.1771C0.496691 12.8507 0.333496 12.4583 0.333496 12C0.333496 11.5417 0.496691 11.1493 0.82308 10.8229C1.14947 10.4965 1.54183 10.3333 2.00016 10.3333C2.4585 10.3333 2.85086 10.4965 3.17725 10.8229C3.50364 11.1493 3.66683 11.5417 3.66683 12C3.66683 12.4583 3.50364 12.8507 3.17725 13.1771C2.85086 13.5035 2.4585 13.6667 2.00016 13.6667ZM2.00016 8.66666C1.54183 8.66666 1.14947 8.50347 0.82308 8.17708C0.496691 7.85069 0.333496 7.45833 0.333496 6.99999C0.333496 6.54166 0.496691 6.1493 0.82308 5.82291C1.14947 5.49652 1.54183 5.33333 2.00016 5.33333C2.4585 5.33333 2.85086 5.49652 3.17725 5.82291C3.50364 6.1493 3.66683 6.54166 3.66683 6.99999C3.66683 7.45833 3.50364 7.85069 3.17725 8.17708C2.85086 8.50347 2.4585 8.66666 2.00016 8.66666ZM2.00016 3.66666C1.54183 3.66666 1.14947 3.50347 0.82308 3.17708C0.496691 2.85069 0.333496 2.45833 0.333496 1.99999C0.333496 1.54166 0.496691 1.1493 0.82308 0.822912C1.14947 0.496523 1.54183 0.333328 2.00016 0.333328C2.4585 0.333328 2.85086 0.496523 3.17725 0.822912C3.50364 1.1493 3.66683 1.54166 3.66683 1.99999C3.66683 2.45833 3.50364 2.85069 3.17725 3.17708C2.85086 3.50347 2.4585 3.66666 2.00016 3.66666Z"
        fill="#75838D"
      />
    </svg>
  )
}

export default MoreIcon;
