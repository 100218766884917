
function AppSetting({ color }: { color: any }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"  viewBox="0 0 20 20">
      <mask
        id="mask0_5334_13857"
        style={{ maskType: 'alpha', backgroundColor: color }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_5334_13857)">
        <path
          fill={color}
          d="M11.563 18.333H8.437a.779.779 0 01-.541-.208.83.83 0 01-.271-.52l-.25-1.938a3.17 3.17 0 01-.51-.25 6.903 6.903 0 01-.47-.313l-1.812.75a.933.933 0 01-.583.021.774.774 0 01-.458-.354L2 12.833a.783.783 0 01-.104-.583.8.8 0 01.312-.5l1.563-1.188a1.99 1.99 0 01-.021-.28v-.563c0-.09.007-.184.02-.282L2.209 8.25a.8.8 0 01-.312-.5A.783.783 0 012 7.167l1.542-2.688a.655.655 0 01.448-.364c.201-.049.399-.039.593.03l1.813.75c.153-.11.312-.214.479-.312.167-.097.333-.18.5-.25l.25-1.937a.83.83 0 01.27-.521.779.779 0 01.543-.208h3.124c.209 0 .39.07.542.208a.83.83 0 01.271.52l.25 1.938c.18.07.35.153.51.25.16.098.316.202.47.313l1.812-.75A.932.932 0 0116 4.125a.774.774 0 01.458.354L18 7.167c.111.18.146.375.104.583a.8.8 0 01-.312.5l-1.563 1.187c.014.098.021.191.021.282v.562c0 .09-.014.184-.042.281l1.563 1.188a.8.8 0 01.312.5.783.783 0 01-.104.583L16.438 15.5a.82.82 0 01-.47.365.915.915 0 01-.593-.01l-1.77-.75a5.7 5.7 0 01-.48.312c-.167.097-.333.18-.5.25l-.25 1.937a.83.83 0 01-.27.521.779.779 0 01-.543.208zm-1.521-5.416a2.81 2.81 0 002.062-.854A2.81 2.81 0 0012.958 10a2.81 2.81 0 00-.854-2.063 2.81 2.81 0 00-2.062-.854c-.82 0-1.51.285-2.073.854A2.828 2.828 0 007.125 10c0 .806.281 1.493.844 2.063.562.569 1.253.854 2.073.854zm0-1.667c-.348 0-.643-.121-.886-.365A1.205 1.205 0 018.792 10c0-.347.121-.642.364-.885s.538-.365.886-.365c.347 0 .642.122.885.365s.365.538.365.885-.122.642-.365.885a1.205 1.205 0 01-.885.365zm-.875 5.417h1.646l.291-2.209c.43-.11.83-.274 1.198-.49a4.981 4.981 0 001.01-.78l2.063.854.813-1.417-1.792-1.354c.07-.195.118-.4.146-.615a5.136 5.136 0 000-1.312 2.938 2.938 0 00-.146-.615l1.791-1.354-.812-1.417-2.063.875a4.63 4.63 0 00-1.01-.802 4.672 4.672 0 00-1.198-.49l-.27-2.208H9.187l-.292 2.209c-.43.11-.83.274-1.198.49a4.98 4.98 0 00-1.01.78l-2.063-.854-.813 1.417 1.792 1.333c-.07.209-.118.417-.146.625a5.05 5.05 0 000 1.313c.028.208.077.416.146.625l-1.792 1.354.813 1.417 2.063-.875c.305.32.642.587 1.01.802a4.67 4.67 0 001.198.49l.27 2.208z"
        ></path>
      </g>
    </svg>
  )
}

export default AppSetting
