import Button from 'libs/button/Button'

import { useNavigate } from 'react-router-dom'
import { useDeleteMachineClassMutation } from '../machine-class-endpoints/machineClass.endpoints'
import { toast } from 'libs/toast'
import Tooltip from 'libs/tool-tip/Tooltip'

const DeleteMachineClass = ({ closePopUp, machineClass, plant_id }: any) => {
  const navigate = useNavigate()
  const [deleteEntry, { isLoading: deleteLoading }] = useDeleteMachineClassMutation()

  const handleDelete = () => {
    deleteEntry({ plantId: plant_id, machineClassID: machineClass._id })
      .unwrap()
      .then(() => {
        closePopUp()
        navigate('/maintenance/machine-classes')

        toast.success(
          <div>
            The Machine class <b>"{machineClass.name}"</b> is removed successfully.
          </div>,
        )
      })
      .catch((err) => {
        toast.error(err?.data?.detail ?? 'Something went wrong. Please try again')
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to delete <span className="break-words pr-1 p-xs-bold">"{machineClass?.name}".</span>This Machine
        Class contains machine models and machines. Deleting this class will remove all associated machines. Are you
        sure you want to proceed?
        <div className="flex gap-2 py-2">
          <div className=" rounded-lg bg-lightGray px-2 py-1">
            <Tooltip
              content={
                machineClass?.machineModels?.length > 0 ? (
                  machineClass?.machineModels?.map((model: any) => (
                    <div key={model?._id}>
                      <span>• </span>
                      <span>{model?.modelNumber}</span>
                    </div>
                  ))
                ) : (
                  <p>No models available</p>
                )
              }
              position="right"
            >
              <span className="cursor-pointer">
                {String(machineClass?.machineModels?.length).padStart(2, '0') ?? '00'}
              </span>

              {machineClass?.machineModels?.length > 1 ? ' Machine Models' : ' Machine Model'}
            </Tooltip>{' '}
          </div>

          <div className="relative rounded-lg bg-lightGray px-2 py-1">
            <Tooltip
              content={
                machineClass?.machines?.length > 0 ? (
                  machineClass?.machines?.map((machine: any) => (
                    <div key={machine?._id}>
                      <span>• </span>
                      <span>{machine?.name}</span> {/* Assuming machineName exists */}
                    </div>
                  ))
                ) : (
                  <p>No machines available</p>
                )
              }
              position="right"
            >
              <span className="cursor-pointer">{String(machineClass?.machines?.length).padStart(2, '0') ?? '00'}</span>

              {machineClass?.machines?.length > 1 ? ' Machines' : ' Machine'}
            </Tooltip>{' '}
          </div>
        </div>
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={handleDelete} loading={deleteLoading}>
          Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteMachineClass
