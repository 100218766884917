function LastPageArrow() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.50016 5L0.250163 1.75C0.097385 1.59722 0.0209961 1.40278 0.0209961 1.16667C0.0209961 0.930556 0.097385 0.736111 0.250163 0.583333C0.402941 0.430556 0.597385 0.354167 0.833496 0.354167C1.06961 0.354167 1.26405 0.430556 1.41683 0.583333L5.25016 4.41667C5.3335 4.5 5.39252 4.59028 5.42725 4.6875C5.46197 4.78472 5.47933 4.88889 5.47933 5C5.47933 5.11111 5.46197 5.21528 5.42725 5.3125C5.39252 5.40972 5.3335 5.5 5.25016 5.58333L1.41683 9.41667C1.26405 9.56944 1.06961 9.64583 0.833496 9.64583C0.597385 9.64583 0.402941 9.56944 0.250163 9.41667C0.097385 9.26389 0.0209961 9.06944 0.0209961 8.83333C0.0209961 8.59722 0.097385 8.40278 0.250163 8.25L3.50016 5ZM9.16683 0C9.40294 0 9.60086 0.0798611 9.76058 0.239583C9.9203 0.399306 10.0002 0.597222 10.0002 0.833333V9.16667C10.0002 9.40278 9.9203 9.60069 9.76058 9.76042C9.60086 9.92014 9.40294 10 9.16683 10C8.93072 10 8.7328 9.92014 8.57308 9.76042C8.41336 9.60069 8.3335 9.40278 8.3335 9.16667V0.833333C8.3335 0.597222 8.41336 0.399306 8.57308 0.239583C8.7328 0.0798611 8.93072 0 9.16683 0Z"
        fill="#041220"
      />
    </svg>
  )
}

export default LastPageArrow
