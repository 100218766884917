/**
 * Formats a given number according to the 'en-IN' locale with no minimum fraction digits.
 * If the input is not a number (NaN), it returns a hyphen ('-').
 *
 * @param  number - The number to format.
 * @returns  The formatted number as a string.
 * @example formatNumber(1234567) => '12,34,567'
 */
export function formatNumber(number?: number): string {
  if (number == null || isNaN(number)) {
    return '-'
  }
  return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(
    number,
  )
}
