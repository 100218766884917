import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import {
  EmployeeManagementParams,
  EmployeeManagementResponse,
  PostEmployeeManagementParams,
  PostEmployeeResponse,
} from 'pages/employee/employees-endpoints/employeemanagementTypes'
import { buildUrl } from 'utils/stringHandlers'

export const employee = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postEmployee: builder.mutation<
      PostEmployeeResponse,
      PostEmployeeManagementParams
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `/plant/${plant_id}/employee`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['employeeUpdate'],
    }),

    getEmployee: builder.query<
      EmployeeManagementResponse,
      EmployeeManagementParams
    >({
      query: ({ plant_id, filters, employeeID }) => {
        const URL = buildUrl(`/plant/${plant_id}/employees`, {
          employeeID,
          ...filters,
        })
        return {
          url: URL,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the Employee ')
            }
            return response.json()
          },
        }
      },
      providesTags: ['employeeUpdate'],
    }),
    deleteEmployee: builder.mutation({
      query: ({ plant_id, employeeID }) => ({
        url: `/plant/${plant_id}/employee/${employeeID}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['employeeUpdate'],
    }),

    putEmployee: builder.mutation({
      query: ({ plant_id, employeeID, data }) => {
        return {
          url: `/plant/${plant_id}/employee/${employeeID}`,
          method: 'PUT',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['employeeUpdate'],
    }),

    getCountEmployee: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `plant/${plant_id}/employee-count`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the count Employee')
            }
            return response.json()
          },
        }
      },
      providesTags: ['employeeUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetEmployeeQuery,
  usePostEmployeeMutation,
  usePutEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetCountEmployeeQuery,
} = employee
