import { useState } from "react";
import { useTranslation } from "react-i18next";

const TimeSelection = ({ onFormDataChange,data }:any) => {

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    help_text:  data?.help_text || '',
  });

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    onFormDataChange(formData);
  };

  return (
    <>
      <div className="mt-5">
        <label className="mb-1 p-sm-regular ">{t("help_text")}</label>
        <div>
          <p className="p-xs-regular-dim">
            {t("guide_the_person_filling_this_field")}
          </p>
          <div className="relative py-1">
            <input
            required
              type="text"
              name="help_text"
              value={formData.help_text}
              onChange={handleInputChange}
              className={`bg-[#EBEFF3]  w-[360px] h-[28px] border-[#E0E0E0] border-solid border rounded-md p-2 pr-8 text-sm outline-none p-xs-regular`}
              placeholder={t("enter_help_text")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeSelection;
