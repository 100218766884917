import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Text, Rectangle } from 'recharts'
import numeral from 'numeral';

interface ChartData {
  name: string
  value: number | undefined
}

interface PieChartsProps {
  apiData: ChartData[]
  productionData?: any
}

const COLORS = ['#00AF82', '#EEEEEE']

const PieCharts: React.FC<PieChartsProps> = ({ apiData, productionData }) => {
  const [chartData, setChartData] = useState<ChartData[]>([])


  useEffect(() => {
    // Calculate total value to normalize the data
    const totalValue = apiData?.reduce((acc, data) => acc + (data?.value || 0), 0)

    // Normalize the data so it sums to 100%, but keep actual values for labels
    const normalizedData = apiData?.map((data) => ({
      ...data,
      value: Math.round(((data.value || 0) / totalValue) * 100)
    }))

    setChartData(normalizedData)
  }, [apiData])

  const renderCustomizedLabel = ({ cx, cy, outerRadius, index }: any) => {
    const offsetX = index === 0 ? -outerRadius : outerRadius
    const adjustedX = cx + offsetX
    const adjustedY = cy + outerRadius - 80

    const textAnchor = index === 0 ? 'start' : 'end'
    const rectX = index === 0 ? adjustedX - 10 : adjustedX - 20
    const rectY = adjustedY - 10

    return (
      <g>
        <Rectangle x={rectX + 10} y={rectY} width={8} height={45} fill={COLORS[index % COLORS.length]} />
        <Text
          x={textAnchor === 'start' ? adjustedX + 15 : adjustedX - 15}
          y={adjustedY}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fontWeight="bold"
          fill="#000"
        >
          {`${apiData[index]?.value || 0}%`}
        </Text>
        <Text
          x={textAnchor === 'start' ? adjustedX + 15 : adjustedX - 15}
          y={adjustedY + 25}
          textAnchor={textAnchor}
          dominantBaseline="central"
          style={{
            fontSize: '12px',
            lineHeight: '1.2',
          }}
          fill="#000"
        >
          {`${chartData[index]?.name}`}
        </Text>
      </g>
    )
  }

  const renderCenterText = () => {
    const totalQuantity = productionData?.quantity < 1000 ? productionData?.quantity : numeral(productionData?.quantity).format('0.00a')
    return (
      <g>
        <text x="50%" y="40%" dy={-10} textAnchor="middle" fill="#000" fontSize="14px" fontWeight="bold">
          {totalQuantity} {productionData?.finishedGood?.unit_of_measure}
        </text>
        <text x="50%" y="35%" dy={20} textAnchor="middle" fill="#000" fontSize="12px">
          Production Target
        </text>
      </g>
    )
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            padding: '14px',
            border: '1px solid #ccc',
            height: '50px',
            width: '165px',
            textAlign: 'center',
            borderRadius: '10px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
            fontFamily: 'inter',
            fontWeight: '400',
            lineHeight: '15px',
          }}
        >
          <p className="label ">{`${payload[0].name}: ${payload[0]?.value}%`}</p>
        </div>
      )
    }

    return null
  }

  return (
    <div className="mt-4 w-full rounded-lg" style={{ position: 'relative' }}>
      <ResponsiveContainer height={230}>
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            startAngle={180}
            endAngle={0}
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {chartData?.map((_, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
          </Pie>
          <Tooltip content={<CustomTooltip />} cursor={true} />
          {productionData && renderCenterText()}
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PieCharts
