import React from 'react'
import Field from 'libs/field'
import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import { useGetCountryQuery, useGetTimezoneQuery } from 'pages/plants/plants-endpoints/plants.endpoints'

interface Props {
  closePanel: () => void
  setFilters: (filters: any) => void
  filters: Filters
}
interface FormValues {
  timezone: string[]
  country: string[]
  status: string[]
  updatedBetween: number[]
  createdBetween: number[]
}

interface Filters {
  status: string[]
  country: string[]
  timezone: string[]
  updatedBetween: number[]
  createdBetween: number[]
}

const FilterPlant: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const { data: country } = useGetCountryQuery()

  const initialValues: FormValues = {
    timezone: filters.timezone ?? [],
    country: filters.country ?? [],
    status: filters.status ?? [],
    createdBetween: filters?.createdBetween?.length == 0 ? [] : filters?.createdBetween,
    updatedBetween: filters?.updatedBetween?.length == 0 ? [] : filters?.updatedBetween,
  }

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      timezone: [],
      country: [],
      status: [],
      createdBetween: [],
      updatedBetween: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  const filterFormik = useFormik<FormValues>({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        createdBetween: values.createdBetween == null ? [] : values.createdBetween,
        updatedBetween: values.updatedBetween == null ? [] : values.updatedBetween,
        status: values.status,
        timezone: values.timezone,
        country: values.country,
      }))
    },
  })

  const status = [
    {
      name: 'Active',
      label: 'active',
      id: '101',
    },
    {
      name: 'Inactive',
      label: 'inactive',
      id: '102',
    },
  ]
  // These country  does not contain timezone
  const countryOptions = country
    ? Object.entries(country)
        .map(([key, value]) => ({
          label: value as string,
          value: key as string,
          sublabel: key as string,
          disabled: '',
        }))
        .filter((option) => option.value !== 'BV' && option.value !== 'HM')
    : []

  const timezoneFilters = {
    country_names: filterFormik?.values.country,
  }
  const { data: timezone } = useGetTimezoneQuery(
    { filters: timezoneFilters },
    { skip: filterFormik.values.country.length === 0 },
  )
  const transformedTimeZones = timezone?.map((item: any) => ({
    label: item as string,
    value: item,
  }))
  const statusOptions = status?.map((item: any) => ({
    label: item.name,
    value: item.label,
    sublabel: '',
    disabled: '',
  }))
  return (
    <>
      <div className="flex flex-col gap-3">
        {/* Country */}
        <Field
          type="multiselect"
          label="Country"
          placeholder="Select country"
          itemType="country"
          options={countryOptions}
          formik={filterFormik}
          name="country"
        />
        {/* Timezone */}
        <Field
          type="multiselect"
          label="Timezone"
          placeholder="Select timezone"
          itemType="timezone"
          options={transformedTimeZones}
          formik={filterFormik}
          disabled={filterFormik?.values?.country?.length === 0}
          name="timezone"
        />

        {/* Status */}
        <Field
          type="multiselect"
          label="Status"
          placeholder="Status"
          itemType="status"
          options={statusOptions}
          formik={filterFormik}
          name="status"
        />
        {/* Created Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Created between"
          formik={filterFormik}
          name="createdBetween"
        />
        {/* Update Date Range */}
        <Field
          type="dateRange"
          shouldDisableFutureDate
          fullWidth
          label="Updated between"
          formik={filterFormik}
          name="updatedBetween"
        />
      </div>
      <div className="flex justify-between pt-5 ">
        <div>
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset
          </Button>
        </div>
        <div className="flex gap-2">
          <Button onClick={closePanel}>Cancel</Button>

          <Button
            color="primary"
            type="submit"
            onClick={() => {
              filterFormik.handleSubmit()
              closePanel()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  )
}

export default FilterPlant
