import { api } from 'common/config'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define your API service
export const rootReducer = createApi({
  reducerPath: 'catalyst',
  baseQuery: fetchBaseQuery({ baseUrl: api }),
  tagTypes: [
    'GetCustomers',
    'GetWorkOrders',
    'GetEmployeeCount',
    'PlantsUpdate',
    'UserInfo',
    'inventoryUpdate',
    'INVENTORY_TRANSACTIONS',
    'updateProcess',
    'updateProcessProduct',
    'updateProcessMetric',
    'updateProcessEntry',
    'productUpdate',
    'getProperty',
    'bomUpdate',
    'productionplanUpdate',
    'getproductionplan',
    'CameraUpdate',
    'ContainerUpdate',
    'DepartmentUpdate',
    'ProductionUpdate',
    'getProduction',
    'updateDashboard',
    'warehouseUpdate',
    'warehouseDelete',
    'GetManufacturerOrder',
    'salesOrderUpdate',
    'purchaseOrderUpdate',
    'employeeUpdate',
    'vendorUpdate',
    'EmployeeDepartmentUpdate',
    'getSingleDepartment',
    'getSingleVendor',
    'approveEntries',
    'getShiftPolicy',
    'updateShiftPolicy',
    'getSingleWorkOrder',
    'timesheetUpdate',
    'RolesInfo',
    'getMaintenanceRequests',
    'GetMaintenanceRequestsCount',
    'maintenanceOrderUpdate',
    'getMaintenanceOrder',
    'getMaintenanceOrderById',
    'getMaintenanceOrderCount',
    'getMaintenanceRequestById',
    'getMachineClasses',
    'getMachineClassById',
    'getMachine',
    'getWorkCenters',
    'getWorkCenterById',
    'getMachineByID',
    'getRoutings',
    'purchaseOrderGetById',
    'salesOrderGetById',
    'getAllRoutings',
    'updateRouting'
  ] as const,
  endpoints: () => ({}),
})