import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { toast } from 'libs/toast'
import Button from 'libs/button/Button'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useDeleteCustomerMutation } from '../customer-endpoints/customer.endpoints'
import { Customer } from '../customer-endpoints/customerTypes'

interface Props {
  closePopUp: () => void
  customer: Customer
}

const DeleteCustomer = ({ closePopUp, customer }: Props) => {
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)
  const [deleteCustomer, { isLoading: loading }] = useDeleteCustomerMutation()

  const handleDelete = () => {
    deleteCustomer({ id: customer?.id, plant_id: plant.id })
      .unwrap()
      .then((res) => {
        closePopUp()
        toast.success(res)
        navigate('/contacts/customer-management')
      })
      .catch((err) => {
        toast.error(
          err?.data?.detail ?? 'Something went wrong. Please try again',
        )
      })
  }

  return (
    <>
      <p className="p-xs-regular">
        You are about to delete a{' '}
        <span className="break-words p-xs-bold">
          “{'Customer'} {customer?.name}”
        </span>
        .Are you sure you want to proceed?
      </p>
      <div className="flex gap-3 justify-end mt-1">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDelete} loading={loading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeleteCustomer
