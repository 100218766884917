function PlusCircleIcon({ isPressedCard }: any) {
  const color = isPressedCard ? '#041220' : '#1193F0'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" fill="none" viewBox="0 0 18 17">
      <path
        fill={color}
        d="M8.166 9.333v2.5c0 .236.08.434.24.594.16.16.358.24.594.24s.434-.08.594-.24c.16-.16.24-.357.24-.594v-2.5h2.5c.235 0 .433-.08.593-.24.16-.159.24-.357.24-.593a.806.806 0 00-.24-.594.806.806 0 00-.594-.24h-2.5v-2.5a.806.806 0 00-.24-.593.806.806 0 00-.593-.24.806.806 0 00-.594.24.806.806 0 00-.24.594v2.5h-2.5a.806.806 0 00-.593.24.806.806 0 00-.24.593c0 .236.08.434.24.594.16.16.357.24.593.24h2.5zm.834 7.5a8.115 8.115 0 01-3.25-.656 8.416 8.416 0 01-2.646-1.781 8.415 8.415 0 01-1.781-2.646A8.115 8.115 0 01.667 8.5c0-1.153.218-2.236.656-3.25a8.415 8.415 0 011.781-2.646A8.416 8.416 0 015.75.823 8.115 8.115 0 019 .167c1.153 0 2.236.218 3.25.656a8.416 8.416 0 012.646 1.781 8.415 8.415 0 011.78 2.646 8.115 8.115 0 01.657 3.25 8.115 8.115 0 01-.656 3.25 8.415 8.415 0 01-1.781 2.646 8.416 8.416 0 01-2.646 1.781 8.114 8.114 0 01-3.25.656zm0-1.666c1.86 0 3.437-.646 4.729-1.938 1.292-1.291 1.938-2.868 1.938-4.729 0-1.861-.646-3.437-1.938-4.73-1.292-1.29-2.868-1.937-4.73-1.937-1.86 0-3.437.646-4.728 1.938C2.979 5.063 2.333 6.639 2.333 8.5c0 1.86.646 3.437 1.938 4.728C5.562 14.521 7.139 15.167 9 15.167z"
      ></path>
    </svg>
  )
}

export default PlusCircleIcon
