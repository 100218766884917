function Maintenance({ color }: { color?: string }) {
return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">

<path 
 fill={color || '#1193F0'}d="M14.2917 17.25L9.25 12.1667C8.97222 12.2778 8.69097 12.3611 8.40625 12.4167C8.12153 12.4722 7.81944 12.5 7.5 12.5C6.11111 12.5 4.93056 12.0139 3.95833 11.0417C2.98611 10.0694 2.5 8.88889 2.5 7.5C2.5 7 2.56944 6.52431 2.70833 6.07292C2.84722 5.62153 3.04167 5.19444 3.29167 4.79167L6.33333 7.83333L7.83333 6.33333L4.79167 3.29167C5.19444 3.04167 5.62153 2.84722 6.07292 2.70833C6.52431 2.56944 7 2.5 7.5 2.5C8.88889 2.5 10.0694 2.98611 11.0417 3.95833C12.0139 4.93056 12.5 6.11111 12.5 7.5C12.5 7.81944 12.4722 8.12153 12.4167 8.40625C12.3611 8.69097 12.2778 8.97222 12.1667 9.25L17.25 14.2917C17.4167 14.4583 17.5 14.6597 17.5 14.8958C17.5 15.1319 17.4167 15.3333 17.25 15.5L15.5 17.25C15.3333 17.4167 15.1319 17.5 14.8958 17.5C14.6597 17.5 14.4583 17.4167 14.2917 17.25ZM14.8958 15.4792L15.4583 14.9167L10.125 9.58333C10.375 9.30556 10.5556 8.98264 10.6667 8.61458C10.7778 8.24653 10.8333 7.875 10.8333 7.5C10.8333 6.66667 10.566 5.94097 10.0312 5.32292C9.49653 4.70486 8.83333 4.33333 8.04167 4.20833L9.58333 5.75C9.75 5.91667 9.83333 6.11111 9.83333 6.33333C9.83333 6.55556 9.75 6.75 9.58333 6.91667L6.91667 9.58333C6.75 9.75 6.55556 9.83333 6.33333 9.83333C6.11111 9.83333 5.91667 9.75 5.75 9.58333L4.20833 8.04167C4.33333 8.83333 4.70486 9.49653 5.32292 10.0312C5.94097 10.566 6.66667 10.8333 7.5 10.8333C7.86111 10.8333 8.22222 10.7778 8.58333 10.6667C8.94444 10.5556 9.27083 10.3819 9.5625 10.1458L14.8958 15.4792Z" />

</svg>)
}

export default Maintenance
