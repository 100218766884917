const DownloadIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00033 8.38317C5.91144 8.38317 5.8281 8.36928 5.75033 8.3415C5.67255 8.31373 5.60033 8.2665 5.53366 8.19984L3.13366 5.79984C3.00033 5.6665 2.93644 5.51095 2.94199 5.33317C2.94755 5.15539 3.01144 4.99984 3.13366 4.8665C3.26699 4.73317 3.42533 4.66373 3.60866 4.65817C3.79199 4.65261 3.95033 4.7165 4.08366 4.84984L5.33366 6.09984V1.33317C5.33366 1.14428 5.39755 0.985948 5.52533 0.858171C5.6531 0.730393 5.81144 0.666504 6.00033 0.666504C6.18921 0.666504 6.34755 0.730393 6.47533 0.858171C6.6031 0.985948 6.66699 1.14428 6.66699 1.33317V6.09984L7.91699 4.84984C8.05032 4.7165 8.20866 4.65261 8.39199 4.65817C8.57533 4.66373 8.73366 4.73317 8.86699 4.8665C8.98921 4.99984 9.0531 5.15539 9.05866 5.33317C9.06421 5.51095 9.00033 5.6665 8.86699 5.79984L6.46699 8.19984C6.40032 8.2665 6.3281 8.31373 6.25033 8.3415C6.17255 8.36928 6.08921 8.38317 6.00033 8.38317ZM2.00033 11.3332C1.63366 11.3332 1.31977 11.2026 1.05866 10.9415C0.797548 10.6804 0.666992 10.3665 0.666992 9.99984V8.6665C0.666992 8.47761 0.730881 8.31928 0.858659 8.1915C0.986437 8.06373 1.14477 7.99984 1.33366 7.99984C1.52255 7.99984 1.68088 8.06373 1.80866 8.1915C1.93644 8.31928 2.00033 8.47761 2.00033 8.6665V9.99984H10.0003V8.6665C10.0003 8.47761 10.0642 8.31928 10.192 8.1915C10.3198 8.06373 10.4781 7.99984 10.667 7.99984C10.8559 7.99984 11.0142 8.06373 11.142 8.1915C11.2698 8.31928 11.3337 8.47761 11.3337 8.6665V9.99984C11.3337 10.3665 11.2031 10.6804 10.942 10.9415C10.6809 11.2026 10.367 11.3332 10.0003 11.3332H2.00033Z"
        fill="white"
      />
    </svg>
  )
}

export default DownloadIcon
