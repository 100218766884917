import Button from 'libs/button/Button'
import { useLocation, useNavigate } from 'react-router-dom'

function SavedChangesPopup() {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location?.state?.from
  const value = path?.includes('production-plans')
  return (
    <div data-testid="navigation-confirmation-popup">
      <p className="p-xs-regular">
        Your changes have been saved. What would you like to do next?
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={() => navigate('/order/sales-order')}>View SO</Button>
        <Button color="primary" onClick={() => navigate(-1)}>
          {value ? 'Edit Plan' : 'Edit MO'}
        </Button>
      </div>
    </div>
  )
}

export default SavedChangesPopup
