import { useEffect, useState } from 'react'
import { t } from 'i18next'
import PreviewIcon from 'assets/icons/configure/PreviewIcon'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import Button from 'libs/button/Button'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import AddField from 'pages/operational-processes/add-field/AddField'
import SidePanel from 'libs/sidepanel/SidePanel'
import { useNavigate, useParams } from 'react-router-dom'
import Popup from 'libs/popup/Popup'
import EditField from 'pages/operational-processes/edit-field/EditField'
import { useGetProcessByIdQuery } from '../../operational-process-endpoints/operational_process.endpoints'
import DeleteFormField from 'pages/operational-processes/popup/DeleteFormField'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import FullPageLoader from 'libs/loader/FullPageLoader'

const FormFields = ({
  setParams,
  setSelectedProductId,
  combinedProducts,
}: any) => {
  const [isAddFieldOpen, setAddFieldOpen] = useState(false)
  const [isEditFieldOpen, setEditFieldOpen] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [fieldData, setFieldData] = useState('')
  const { processId, productId } = useParams()

  const { data: process, isLoading: processLoading } = useGetProcessByIdQuery(
    {
      process_id: processId,
    },
    {
      skip: !processId,
    },
  )

  const [fields, setFields] = useState([])
  useEffect(() => {
    if (process?.input_products) {
      let product = process.input_products?.find(
        (_product: any) => _product.product._id === productId,
      )
      if (!product) {
        product = process.output_products?.find(
          (_product: any) => _product.product._id === productId,
        )
      }
      if (product) {
        setFields(product.fields)
        setSelectedProductId(product.product._id)
      }
    }
  }, [process, productId])

  useEffect(() => {
    setParams({
      processId: processId,
      productId: productId,
    })
  }, [processId, productId])

  const navigate = useNavigate()
  const columns = [
    {
      Header: 'Field Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Field Type',
      accessor: 'type',
      disableSortBy: true,
    },

    {
      Header: 'Mandatory',
      accessor: 'required',
      Cell: ({ row }: any) => (
        <div className="flex flex-wrap">
          {row.original.is_required ? 'Yes' : 'No'}
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Edit and Delete',
      accessor: 'actions',
      Cell: ({ row }: any) => (
        <div className="flex justify-end gap-1">
          <button
            onClick={(event) => {
              event.stopPropagation()
              handleEditField(row?.original)
            }}
          >
            <Pen />
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation()
              toggleDeletePopUp(row?.original)
            }}
          >
            <Bin />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleAddField = () => {
    setAddFieldOpen(!isAddFieldOpen)
  }
  const handleEditField = (data: any) => {
    setFieldData(data)
    setEditFieldOpen(!isEditFieldOpen)
  }

  const handlePreviewForm = () => {
    const productType = process.input_products?.find(
      (_product: any) => _product.product._id === productId,
    )
      ? 'input'
      : 'output'
    navigate(`/settings/configure/preview/${processId}/${productType}`)
  }

  const handelOperationalTabel = () => {
    navigate('/settings/configure/operationalprocesses')
  }

  const toggleDeletePopUp = (data: any) => {
    setFieldData(data)
    setDeletePopUp(!deletePopUp)
    setEditFieldOpen(false)
  }

  // Check if there are any products available in the combined products array
  const isProduct = combinedProducts?.length > 0 ? false : true

  if (processLoading) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <FullPageLoader />
      </div>
    )
  }

  return (
    <MotionDiv>
      <>
        <div className="flex justify-between ">
          <div>
            <h5 className="h5-1">{t('form_fields')}</h5>
            <p className="mb-7 p-sm-regular-1">
              {t(
                'add_the_basic_details_that_are_required_to_identify_this_task',
              )}
            </p>
          </div>
          <div className="flex gap-3 ">
            <Button onClick={handlePreviewForm} disabled={isProduct}>
              <PreviewIcon />
              {t('preview')}
            </Button>
            <Button
              color="primary"
              onClick={handleAddField}
              disabled={isProduct}
            >
              <PlusIcon />
              {t('add_field')}
            </Button>
          </div>
        </div>
        <Popup
          isOpen={deletePopUp}
          title="FormField"
          onClose={() => {
            setDeletePopUp(false)
          }}
        >
          <DeleteFormField
            closePopUp={toggleDeletePopUp}
            productId={productId}
            fieldData={fieldData}
            processId={processId}
          />
        </Popup>
        {!isProduct ? (
          <div className="mt-2 w-full flex-col">
            <PaginatedTable
              columns={columns}
              emptyMessage={
                'No storage areas are added. Please add Storage areas using the Add storage area button.'
              }
              rows={fields}
              onSingleClick={() => {}}
              onPageNumberChange={() => {}}
              loading={processLoading}
              hidePagination={true}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center  ">
            <p>Please select at least one product for add field </p>
          </div>
        )}

        <SidePanel
          size="veryLarge"
          isOpen={isAddFieldOpen}
          title={t('add_field')}
          onClose={() => {
            setAddFieldOpen(false)
          }}
        >
          <AddField
            closePanel={() => {
              setAddFieldOpen(false)
            }}
            productId={productId}
            numberFields={fields}
          />
        </SidePanel>
        <SidePanel
          size="veryLarge"
          isOpen={isEditFieldOpen}
          title="Edit Field"
          onClose={() => {
            setEditFieldOpen(false)
          }}
        >
          {/* @ts-ignore */}
          <EditField
            selectedEntry={fieldData}
            closePanel={() => {
              setEditFieldOpen(false)
            }}
            deletePopup={() => toggleDeletePopUp(fieldData)}
            productId={productId}
            numberFields={fields}
          />
        </SidePanel>
        <div
          className={`fixed bottom-0 left-0 right-0 flex h-[4rem] items-center justify-end gap-2 bg-white px-6`}
        >
          <Button className="btn-cancel" onClick={handelOperationalTabel}>
            {t('cancel')}
          </Button>
          <Button
            color="success"
            type="button"
            onClick={handelOperationalTabel}
          >
            {t('Finish')}
          </Button>
        </div>
      </>
    </MotionDiv>
  )
}

export default FormFields
