import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { RefObject } from '@fullcalendar/core/preact'
import interactionPlugin from '@fullcalendar/interaction'

import { useRef, useState } from 'react'
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material'
import './index.css'
import Select from './Select'

const yearsArray = Array.from({ length: 130 }, (_, i) => {
  const year = 1970 + i
  return { label: year.toString(), value: year }
})

const monthsArray = Array.from({ length: 12 }, (_, i) => ({
  label: new Date(0, i).toLocaleString('default', { month: 'long' }),
  value: i + 1,
}))

const formatDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0')
  const monthName = date.toLocaleString('default', { month: 'short' })
  return `${day} ${monthName}`
}

const formatDayHeader = ({ date }: { date: Date }) => {
  return date.toLocaleString('default', { weekday: 'long' })
}

type Props = {
  dates: { start: Date | null; end: Date | null }
  onChange: (newDates: { start: Date | null; end: Date | null }) => void
}

const Calender = ({ dates, onChange }: Props) => {
  const calendarRef: RefObject<FullCalendar> = useRef(null)

  const [selectedOption, setSelectedOption] = useState(monthsArray[new Date().getMonth()])

  const [selectedYear, setSelectedYear] = useState({
    label: new Date().getFullYear().toString(),
    value: new Date().getFullYear(),
  })

  const handleDateRangeSelect = ({ date: selected }: any) => {
    const { start, end } = dates
    // unselect start date
    if (start?.getTime() === selected.getTime()) {
      onChange({ start: null, end })
      return
    }

    // unselect end date
    if (end?.getTime() === selected.getTime()) {
      onChange({ start, end: null })
      return
    }

    if (start == null) {
      if (end == null || selected < end) {
        onChange({ start: selected, end })
      } else {
        onChange({ start: end, end: selected })
      }
    } else if (end == null) {
      if (selected < start) {
        onChange({ start: selected, end: start })
      } else {
        onChange({ start, end: selected })
      }
    } else {
      if (selected < start) {
        onChange({ start: selected, end })
      } else {
        onChange({ start, end: selected })
      }
    }
  }

  const handlePrevMonth = () => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef.current.getApi()
      const currentMonth = calendarApi.getDate().getMonth()
      const newMonthIndex = currentMonth === 0 ? 11 : currentMonth - 1
      setSelectedOption(monthsArray[newMonthIndex])
      calendarApi.prev()
      if (currentMonth === 0) {
        setSelectedYear((prevYear) => ({
          label: (prevYear.value - 1).toString(),
          value: prevYear.value - 1,
        }))
      }
    }
  }

  const handleNextMonth = () => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef.current.getApi()
      const currentMonth = calendarApi.getDate().getMonth()
      const newMonthIndex = currentMonth === 11 ? 0 : currentMonth + 1
      setSelectedOption(monthsArray[newMonthIndex])
      calendarApi.next()
      if (currentMonth === 11) {
        setSelectedYear((prevYear) => ({
          label: (prevYear.value + 1).toString(),
          value: prevYear.value + 1,
        }))
      }
    }
  }

  const handleMonthChange = (event: any) => {
    if (calendarRef?.current) {
      const selectedMonth = event.value
      const calendarApi = calendarRef.current.getApi()
      const currentYear = calendarApi.getDate().getFullYear()
      calendarApi.gotoDate(new Date(currentYear, selectedMonth - 1, 1))
    }
  }

  const handleYearChange = (event: any) => {
    if (calendarRef?.current) {
      const selectedYear = event.value
      const calendarApi = calendarRef.current.getApi()
      const currentMonth = calendarApi.getDate().getMonth()
      calendarApi.gotoDate(new Date(selectedYear, currentMonth, 1))
    }
  }

  return (
    <>
      <div className="w-full">
        <div className="b flex  flex-row gap-2 rounded-t-lg border-2 border-b-0 border-border bg-background-2 py-2">
          <button onClick={handlePrevMonth} className="p-2">
            <ChevronLeftRounded />
          </button>
          <div className="flex min-w-64 justify-center gap-2">
            <Select
              options={monthsArray}
              value={selectedOption}
              onChange={(newVal) => {
                setSelectedOption(newVal)
                handleMonthChange(newVal)
              }}
            />

            <Select
              options={yearsArray}
              value={selectedYear}
              onChange={(newVal) => {
                setSelectedYear(newVal)
                handleYearChange(newVal)
              }}
            />
          </div>
          <button onClick={handleNextMonth} className="p-2">
            <ChevronRightRounded />
          </button>
        </div>
        <FullCalendar
          ref={calendarRef}
          headerToolbar={false}
          firstDay={1}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dayHeaderContent={formatDayHeader}
          selectable={false}
          dayCellContent={({ date }) => {
            return <p className="text-sm ">{formatDate(date)}</p>
          }}
          dayCellClassNames={(info) => {
            if (info.date.getTime() === dates?.start?.getTime() || info.date.getTime() === dates?.end?.getTime()) {
              return ['bg-primary text-white']
            } else if (
              dates?.start &&
              dates?.end &&
              info.date.getTime() > dates?.start?.getTime() &&
              info.date.getTime() < dates?.end?.getTime()
            ) {
              return ['bg-blue-300']
            } else {
              return []
            }
          }}
          dateClick={handleDateRangeSelect}
        />
      </div>
    </>
  )
}

export default Calender
