import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikProps, useFormik } from 'formik'
import Button from 'libs/button/Button'
import NewEditableTable from 'libs/table/NewEditableTable'
import { toast } from 'libs/toast'
import { VendorFormik } from '../../vendor-endpoints/vendorTypes'
import { ADDRESS_TYPE_OPTIONS } from 'pages/contacts/commonConstants'
import { AddressDetailsFormik } from 'pages/contacts/commonTypes'

const vendorsColumns = [
  {
    title: 'Address Type',
    accessor: 'addressType',
    options: ADDRESS_TYPE_OPTIONS,
    fieldType: 'select',
    flex: 2,
    editable: true,
    placeholder: 'Select address type',
  },

  {
    title: 'Address line 1',
    accessor: 'addressLine1',
    editable: true,
    flex: 2,
    require: true,
    placeholder: 'Enter address',
  },
  {
    title: 'Address line 2',
    accessor: 'addressLine2',
    editable: true,
    require: true,
    flex: 2,
    placeholder: 'Enter address',
  },
  {
    title: 'City',
    accessor: 'city',
    editable: true,
    require: true,
    placeholder: 'Enter city',
  },
  {
    title: 'State',
    accessor: 'state',
    editable: true,
    require: true,
    placeholder: 'Enter state',
  },
  {
    title: 'Zip Code',
    accessor: 'zipCode',
    require: true,
    editable: true,
    placeholder: 'Enter zip code',
  },
  {
    title: 'Country',
    accessor: 'country',
    editable: true,
    require: true,
    placeholder: 'Enter country',
  },
  {
    title: 'Primary',
    fieldType: 'radio',
    accessor: 'isPrimary',
    editable: true,
  },
]

interface AddressDetailsProps {
  vendorFormik: FormikProps<VendorFormik>
  loading: boolean
}
const AddressDetails: React.FC<AddressDetailsProps> = ({
  vendorFormik,
  loading,
}) => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const { id } = useParams()
  const AddressDetailsFormik = useFormik<AddressDetailsFormik>({
    initialValues: {
      addresses: [],
    },
    onSubmit: (values) => {
      values.addresses.forEach((address: any) => {
        delete address._id
        if (address.addressLine2 === '') {
          delete address.addressLine2
        }
        if (address.isPrimary === '') {
          delete address.isPrimary
        }
        if (address.addressLine1 === '') {
          delete address.addressLine1
        }
        if (address.city === '') {
          delete address.city
        }
        if (address.addressType === '') {
          delete address.addressType
        }
        if (address.state === '') {
          delete address.state
        }
        if (address.zipCode === '') {
          delete address.zipCode
        }
        if (address.country === '') {
          delete address.country
        }
      })

      values.addresses.forEach((address) => {
        if (!Object.keys(address)?.length) {
          values.addresses = values.addresses.filter((item) => item !== address)
        }
      })

      const isAddressValid = values.addresses.every((address) => {
        return (
          address.addressType &&
          address.addressLine1 &&
          address.city &&
          address.state &&
          address.zipCode &&
          address.country
        )
      })
      if (!isAddressValid) {
        toast.error('Please fill all the address fields')
        return
      }
      vendorFormik.setFieldValue('AddressDetails', values)
      vendorFormik.handleSubmit()
    },
  })

  useEffect(() => {
    AddressDetailsFormik.resetForm({
      values: {
        addresses: vendorFormik?.values?.AddressDetails?.addresses,
      },
    })
  }, [vendorFormik])

  const handleAddress = () => {
    AddressDetailsFormik?.setFieldValue('addresses', data ? data : [])
    AddressDetailsFormik.handleSubmit()
  }

  return (
    <div className="flex h-full flex-col gap-10">
      <div>
        <span>
          <h4 className="h4-bold-black">Billing Addresses</h4>
          <p className="text-sm text-primary-gray-4">
            {id
              ? 'Edit the existing Vendor details to make necessary changes and updates.'
              : 'Add the basic details that are required to Add New Vendor.'}
          </p>
        </span>
      </div>

      <NewEditableTable
        defaultRows={vendorFormik?.values?.AddressDetails?.addresses}
        columns={vendorsColumns}
        onChange={(values) => {
          setData(values)
        }}
        addButtonTitle="Add Address"
      />
      <div className="flex justify-between">
        <Button
          data-testid="cancel-button"
          onClick={() => navigate('/contacts/vendor-management')}
        >
          Cancel
        </Button>

        <span className="flex gap-3">
          {' '}
          <Button
            onClick={() => {
              navigate(-1)
            }}
          >
            Previous
          </Button>
          <Button
            color="success"
            loading={loading}
            onClick={() => {
              handleAddress()
            }}
          >
            {id ? 'Save' : 'Add'}
          </Button>
        </span>
      </div>
    </div>
  )
}

export default AddressDetails
