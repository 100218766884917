import Button from 'libs/button/Button'

interface Props {
  closePopUp: any
  handleDelete: any
  rowIndex: number
}

const DeletePopup = ({ closePopUp, handleDelete, rowIndex }: Props) => {
  return (
    <>
      <p className="mb-4 p-xs-regular">Are you sure you want to remove this row?</p>
      <div className=" mt-1 flex justify-end gap-3">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" onClick={() => handleDelete(rowIndex)}>
          Remove
        </Button>
      </div>
    </>
  )
}

export default DeletePopup
