import { FormikContextType, useFormik } from 'formik'
import Calender from 'libs/full-calendar'
import { useNavigate, useParams } from 'react-router-dom'
import { PlanForm } from '../../planning-wizard'
import { toastFailure } from 'libs/toast/toastFailure'
import { t } from 'i18next'
import Button from 'libs/button/Button'
import { useEffect } from 'react'

interface Props {
  formik: FormikContextType<PlanForm>
  createLoading: boolean
  setPage: any
  setId: any
}

const Scheduling = ({ formik, setPage, setId }: Props) => {
  const navigate = useNavigate()
  const {id} = useParams()
  
  const dateFormik = useFormik({
    initialValues: {
      should_update: false,
      should_save_draft: false,
      startDate: formik.values.startDate ? formik.values.startDate : null,
      endDate: formik.values.endDate ? formik.values.endDate : null,
    },

    onSubmit: (values) => {
      if (formik.values.startDate && !formik.values.endDate) {
        toastFailure('End Date is required')
      } else if (!formik.values.startDate && formik.values.endDate) {
        toastFailure('Start Date is required')
      } else if (values.should_save_draft) {
        formik.handleSubmit()
      } else if (values.should_update) {
        formik.handleSubmit()
        setPage(3)
      } else {
        formik.handleSubmit()
      }
    },
  })

  useEffect(() => {
    setId({ id: id })
    setPage(2)
  }, [])

  return (
    <div className="flex w-full flex-col gap-5 px-2">
      <div>
        <h5 className="h5-1">{t('plannings.scheduling')}</h5>
        <p className="text-sm">{t('plannings.scheduling_heading')}</p>
      </div>
      <Calender
        dates={{
          start: formik.values.startDate ? new Date(formik.values.startDate * 1000) : null,
          end: formik.values.endDate ? new Date(formik.values.endDate * 1000) : null,
        }}
        onChange={(newDates) => {
          formik.setFieldValue('startDate', newDates.start ? Math.floor(newDates.start.getTime() / 1000) : null)
          formik.setFieldValue(
            'endDate',
            newDates.start
              ? newDates.end
                ? Math.floor(newDates.end.getTime() / 1000)
                : Math.floor(newDates.start.getTime() / 1000)
              : null,
          )
        }}
      />
      <div className="flex justify-between gap-2">
        <div className="flex gap-2">
          <div>
            <Button onClick={() => navigate('/planning/production-plans')}>{t('cancel')}</Button>
          </div>
        </div>
        <div className="flex gap-2">
          {!id && (
            <Button
              onClick={() => {
                navigate('../mo')
                setPage(3)
              }}
            >
              {t('plannings.skip_this_step')}
            </Button>
          )}

          <Button
            onClick={() => {
              navigate('../plan-details')
              setPage(1)
            }}
          >
            {t('plannings.previous')}
          </Button>

          <Button
            color="success"
            onClick={() => {
              dateFormik.setFieldValue('should_save_draft', false)
              dateFormik.handleSubmit()
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Scheduling
