function Dashboard({ color }: { color: any }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask
        id="mask0_1311_38746"
        style={{ maskType: 'alpha' }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_1311_38746)">
        <path
          fill={color ? `${color}` : '#041220'}
          d="M10.833 6.667V3.333c0-.236.08-.434.24-.593.16-.16.358-.24.594-.24h5c.236 0 .434.08.593.24.16.16.24.357.24.593v3.334c0 .236-.08.434-.24.593a.806.806 0 01-.593.24h-5a.806.806 0 01-.594-.24.806.806 0 01-.24-.593zM2.5 10V3.333c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h5c.236 0 .434.08.594.24.16.16.24.357.24.593V10c0 .236-.08.434-.24.594a.806.806 0 01-.594.24h-5a.806.806 0 01-.593-.24A.807.807 0 012.5 10zm8.333 6.667V10c0-.236.08-.434.24-.594.16-.16.358-.24.594-.24h5c.236 0 .434.08.593.24.16.16.24.358.24.594v6.667c0 .236-.08.434-.24.593a.806.806 0 01-.593.24h-5a.807.807 0 01-.594-.24.806.806 0 01-.24-.593zm-8.333 0v-3.334c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24h5c.236 0 .434.08.594.24.16.16.24.357.24.593v3.334c0 .236-.08.434-.24.593a.806.806 0 01-.594.24h-5a.806.806 0 01-.593-.24.806.806 0 01-.24-.593zm1.667-7.5H7.5v-5H4.167v5zm8.333 6.666h3.333v-5H12.5v5zm0-10h3.333V4.167H12.5v1.666zm-8.333 10H7.5v-1.666H4.167v1.666z"
        ></path>
      </g>
    </svg>
  )
}

export default Dashboard
