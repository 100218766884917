import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import * as Yup from 'yup'
import { useCancelMaintenanceOrderStatusMutation } from '../maintenance-order-endpoints/order.endpoints'
import { toast } from 'libs/toast'
const CancleOrderPopup = ({ closePopUp, maintenanceOrder, plant_id }: any) => {
  const [cancelOrderStatus, { isLoading }] = useCancelMaintenanceOrderStatusMutation()
  const formik = useFormik({
    initialValues: {
      remark: '',
    },
    validationSchema: Yup.object({
      remark: Yup.string().required('Remark is required'),
    }),

    onSubmit: (values) => {
      cancelOrderStatus({ plant_id: plant_id, moID: maintenanceOrder._id, remark: values?.remark })
        .unwrap()
        .then(() => {
          closePopUp()
          toast.success(`The Order “${maintenanceOrder?.orderID}” is cancel successfully.`)
        })
        .catch((error: any) => {
          toast.error(error?.data?.detail ?? `Failed to cancel order. Please try again.`)
        })
    },
  })

  return (
    <div>
      <p className="p-xs-regular">{`You are about to cancel a Maintenance Order “${maintenanceOrder?.orderID}". Are you sure you want to proceed?`}</p>

      <div>
        <div>
          <Field
            type="textarea"
            required={true}
            label={'Remark'}
            formik={formik}
            name="remark"
            placeholder={'Enter remarks on cancelling requests'}
          />
        </div>
      </div>

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={() => closePopUp()}>Keep Order</Button>
        <Button
          onClick={() => {
            formik.handleSubmit()
          }}
          loading={isLoading}
          color="error"
        >
          {' '}
          Cancel Order
        </Button>
      </div>
    </div>
  )
}

export default CancleOrderPopup
