import { useFormik } from 'formik'
import Button from 'libs/button/Button'
import Field from 'libs/field'
import * as Yup from 'yup'
import { useUserInfoQuery } from '../user-endpoints/user.endpoint'
import { useGetRolesQuery } from '../../roles/roles-endpoints/roles.endpoint'

interface Props {
  closePanel: () => void
  setFilters: any
  filters: any
}
type DropdownOption = {
  label: string
  value: string
  sublabel?: string
  disabled?: any // Allow boolean type for disabled
}

const FilterUsers: React.FC<Props> = ({ closePanel, setFilters, filters }: Props) => {
  const { data: userInfo } = useUserInfoQuery({})

  const filter = {
    page_no: 1,
    page_size: 1000,
    searchText: '',
    sortBy: 'created_on',
    sortDir: 'DESC',
  }

  const { data: userRoles } = useGetRolesQuery(
    {
      org_id: userInfo?.organization?._id,
      filters: filter,
    },
    {
      skip: !userInfo?.organization?._id,
    },
  )
  const userRolesOptions: DropdownOption[] =
  userRoles?.data?.reduce((acc: DropdownOption[], userRole: any) => {
    if (!acc.some((option) => option.label === userRole.name)) {
      acc.push({
        label: userRole.name,
        value: userRole._id,
        sublabel: '',
        disabled: '',
      })
    }
    return acc
  }, []) ?? []

  const filterFormik = useFormik({
    initialValues: {
      userRole: filters?.userRole ? filters.userRole : [],
      lastUpdated: filters?.updatedBetween ? filters.updatedBetween : [],
    },
    onSubmit: (values) => {
      setFilters({
        ...filters,
        userRole: values.userRole,
        updatedBetween: values.lastUpdated,
      })
    },
    validationSchema: Yup.object({}),
  })

  const handleReset = () => {
    setFilters({
      page_no: 1,
      page_size: 10,
      searchText: '',
      updatedBetween: [],
      userRole: [],
      sortBy: 'created_on',
      sortDir: 'DESC',
    })
    closePanel()
  }

  return (
    <>
      <div className="flex flex-col gap-3">
        <Field
          type="multiselect"
          label="User Role"
          formik={filterFormik}
          name="userRole"
          options={userRolesOptions}
          placeholder="Select Role"
        />
        <Field type="dateRange" label="Last Updated" shouldDisableFutureDate formik={filterFormik} name="lastUpdated" />
        <div className="flex justify-between">
          <Button
            onClick={() => {
              handleReset()
            }}
          >
            Reset Filters
          </Button>
          <div className="flex justify-end gap-2">
            <Button onClick={closePanel}>Cancel</Button>
            <Button
              color="primary"
              onClick={() => {
                filterFormik.handleSubmit()
                closePanel()
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterUsers
