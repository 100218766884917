import {
  DarkLogo,
  CopyrightIcon,
} from "assets/icons";

const Modal = (props:any) => {
  return (
    <div className="w-[427px] mx-auto my-auto rounded-lg bg-[#FDFEFF] ">
      {/* <div className="flex ">
        //login via email, phone header goes here
      </div> */}
      <div className="p-5">
        <div className="text-center">
          <h1
            className={`text-[#323333] font-inter ${
              props.textSize ? "text-2xl" : "text-3xl"
            } font-bold leading-9 py-2.5`}
          >
            {props.heading}{" "}
            <span className="text-[#0B5ED7]">
              {props.name && props.name + "!"}
            </span>
          </h1>
        </div>
        {props.children}
        <div className="my-2.5 ">
          <DarkLogo width={142} height={50} classProp="mx-auto" />
        </div>
        <div className="text-xs font-inter text-center">
          <div className="flex items-center justify-center">
            <CopyrightIcon />
            &nbsp; 2024 Catalyst
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
