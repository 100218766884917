import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes/paths'
import { ToastContainer } from 'react-toastify'
import { CloseButton } from 'libs/toast/closeToast'
import store from 'store/store'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import 'react-toastify/dist/ReactToastify.css'
import 'rsuite/dist/rsuite-no-reset.min.css'
import './index.css'
import FullPageLoader from 'libs/loader/FullPageLoader'

const selectedLanguage = localStorage.getItem('i18nextLng') || 'en'
i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    debug: true,
    fallbackLng: 'en',
    detection: {
      order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: true },
    lng: selectedLanguage,
  })

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      closeButton={CloseButton}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    {/* <AppWrapper /> */}
    <RouterProvider router={router} fallbackElement={<FullPageLoader />} />
  </Provider>,
)
