import DashboardTilesProdAndReview from 'libs/dashboard-tiles-prod-review/DashboardTilesProdAndReview'
import useTabTitle from 'libs/hooks/useTabTitle'
import { useTranslation } from 'react-i18next'

const Review = () => {
  const { t } = useTranslation()
  useTabTitle(t('review'))
  return <DashboardTilesProdAndReview title={t('review')} showCount={true} />
}

export default Review
