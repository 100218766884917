import { useState } from 'react'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import AddDepartment from 'pages/department/components/add'
import DeleteDepartment from 'pages/department/components/delete'
import ViewDepartment from 'pages/department/components/view'
import UpdateDepartment from 'pages/department/components/edit'
import { caseSensitiveSort } from 'utils/utils'
import { useGetDepartmentsQuery } from './department-endpoints/department.endpoints'
import { getRelativeTime } from 'utils/dateTimeHandlers'
import { t } from 'i18next'
import SidePanel from 'libs/sidepanel/SidePanel'
import MotionDiv from 'libs/motionDiv'
import { AnimatePresence, motion } from 'framer-motion'
import { scaleMotionProps } from 'utils/FramerProps'
import FilterDepartment from './components/panel/FilterDepartment'
import { formatNumber } from 'utils/numberHandlers'
import useTabTitle from 'libs/hooks/useTabTitle'
import FilterableSearchTable from 'libs/table/filterable-search-table/FilterableSearchTable'

const additionalFilters = {
  createdBetween: [],
  updatedBetween: [],
  sortBy: 'updated_on',
  sortDir: 'DESC',
}

const Department = () => {
  useTabTitle(t('departments.title'))
  const [addPanel, setAddPanel] = useState(false)
  const [viewPanel, setViewPanel] = useState(false)
  const [editPanel, setEditPanel] = useState(false)
  const [departmentToDelete, setDepartmentToDelete] = useState(null)
  const [selectedEntry, setSelectedEntry] = useState(null)

  //toggle edit entry
  const openUpdatePanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setEditPanel(!editPanel)
  }

  const column = [
    {
      Header: 'Department',
      accessor: 'name',
      width: 150,
      sortType: caseSensitiveSort,
      Cell: ({ row }: any) => {
        const data = row?.original
        const toggleViewPanel = (entryData: any) => {
          setSelectedEntry(entryData)
          setViewPanel(!viewPanel)
        }
        return (
          <div className="hyperlink" onClick={() => toggleViewPanel(data)}>
            {data?.name}
          </div>
        )
      },
    },
    {
      Header: 'Associated Process',
      accessor: 'processes',
      width: 200,
      Cell: ({ value }: any) => {
        let displayProducts = value
          ?.slice(0, 3)
          .map((product: any) => product.name)
        const remainingCount = value?.length - displayProducts?.length

        return displayProducts?.length > 0 ? (
          <div className="flex flex-wrap">
            {displayProducts?.map((productName: any, index: any) => (
              <span
                key={index}
                className={`m-1 rounded-[28px] bg-warning-100 px-2 py-0.5 text-warning-900`}
              >
                {productName}
              </span>
            ))}
            <div>
              {remainingCount > 0 && (
                <button className="ml-4 mt-1 text-primary-700 underline underline-offset-2 hover:font-semibold">
                  +{formatNumber(remainingCount)} more
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>-</div>
        )
      },
      disableSortBy: true,
    },

    {
      Header: 'Last updated',
      accessor: 'updated_on',
      Cell: ({ value }: any) => {
        const formattedDate = getRelativeTime(value)
        return formattedDate
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      width: 50,
      Cell: ({ row }: any) => (
        <div className="flex justify-end gap-1 ">
          <button
            onClick={(event) => {
              event.stopPropagation()
              openUpdatePanel(row?.original)
            }}
          >
            <Pen />
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation()
              setDepartmentToDelete(row?.original)
            }}
          >
            <Bin />
          </button>
        </div>
      ),
      disableSortBy: true,
    },
  ]

  const handleAddDepartment = () => {
    setAddPanel(true)
  }
  return (
    <MotionDiv>
      <>
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="h4-bold-black ">{t('departments.title')}</h1>
            <p className="w-4/5 p-sm-regular-1">{t('departments.subtitle')}</p>
          </div>
        </div>
        <FilterableSearchTable
          useQuery={useGetDepartmentsQuery}
          columns={column}
          addButton={{
            label: t('departments.add_department'),
            onClick: handleAddDepartment,
          }}
          placeholders={{
            emptyMessage:
              'No departments are added. Please add departments to create processes.',
            filterEmptyMessage: 'No matching results found',
            search: 'Search by department name',
          }}
          filterPanelComponent={FilterDepartment}
          additionalFilters={additionalFilters}
        />

        <SidePanel
          isOpen={addPanel}
          title={t('departments.new_department')}
          onClose={() => setAddPanel(false)}
        >
          <AddDepartment close={() => setAddPanel(false)} />
        </SidePanel>
        {selectedEntry && (
          <SidePanel
            isOpen={viewPanel}
            title={t('departments.department')}
            onClose={() => setViewPanel(false)}
          >
            <ViewDepartment
              close={() => setViewPanel(false)}
              handleUpdate={openUpdatePanel}
              handleDelete={setDepartmentToDelete}
              department={selectedEntry}
            />
          </SidePanel>
        )}

        <SidePanel
          isOpen={editPanel}
          title={t('departments.edit_department')}
          onClose={() => setEditPanel(false)}
        >
          <UpdateDepartment
            handleDelete={setDepartmentToDelete}
            close={() => setEditPanel(false)}
            department={selectedEntry}
          />
        </SidePanel>

        <div>
          <AnimatePresence>
            {departmentToDelete && (
              <motion.div
                {...scaleMotionProps}
                className={`fixed left-0 top-[3rem] flex h-full w-full items-center justify-center bg-overlay  ${
                  departmentToDelete ? '' : 'hidden'
                }`}
              >
                <DeleteDepartment
                  department={departmentToDelete}
                  close={() => setDepartmentToDelete(null)}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </>
    </MotionDiv>
  )
}

export default Department
