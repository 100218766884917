import { getToken } from 'utils/auth'
import { rootReducer } from 'store/services/root.service'
import { toast } from 'libs/toast'
import { buildUrl } from 'utils/stringHandlers'
import {
  GetDepartmentsRequest,
  GetDepartmentsRespone,
  PostDepartmentRequest,
  PostDepartmentResponse,
} from './departmentTypes'

export const dashboard = rootReducer.injectEndpoints({
  endpoints: (builder) => ({
    postDepartment: builder.mutation<
      PostDepartmentResponse,
      PostDepartmentRequest
    >({
      query: ({ data, plant_id }) => {
        return {
          url: `/department?plant_id=${plant_id}`,
          method: 'POST',
          headers: getToken(),
          body: data,
        }
      },
      invalidatesTags: ['DepartmentUpdate'],
    }),
    getDepartments: builder.query<GetDepartmentsRespone, GetDepartmentsRequest>(
      {
        query: ({ plantId, filters }) => {
          const URL = buildUrl(`/department?plantId=${plantId}`, filters)
          return {
            url: URL,
            method: 'GET',
            headers: getToken(),
            responseHandler: (response) => {
              if (!response.ok) {
                toast.error('Unable to fetch the departments')
              }
              return response.json()
            },
          }
        },
        providesTags: ['DepartmentUpdate'],
      },
    ),
    editDepartment: builder.mutation({
      query: ({ data, departmentId }) => ({
        url: `/department/${departmentId}`,
        method: 'PUT',
        headers: getToken(),
        body: data,
      }),
      invalidatesTags: ['DepartmentUpdate', 'updateProcessEntry'],
    }),
    deleteDepartment: builder.mutation({
      query: ({ departmentId }) => ({
        url: `/department/${departmentId}`,
        method: 'DELETE',
        headers: getToken(),
      }),
      invalidatesTags: ['DepartmentUpdate'],
    }),
    getDepartmentCount: builder.query({
      query: ({ plant_id }) => {
        return {
          url: `/department/department-count?plant_id=${plant_id}`,
          method: 'GET',
          headers: getToken(),
          responseHandler: (response) => {
            if (!response.ok) {
              toast.error('Unable to fetch the depatment counts')
            }
            return response.json()
          },
        }
      },
      providesTags: ['DepartmentUpdate'],
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostDepartmentMutation,
  useGetDepartmentsQuery,
  useEditDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetDepartmentCountQuery,
} = dashboard
