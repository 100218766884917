export function trimStringValues(
  obj: Record<string, any>,
): Record<string, any> {
  const trimmedObj: Record<string, any> = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      trimmedObj[key] = typeof value === 'string' ? value.trim() : value
    }
  }
  return trimmedObj
}

export function formatString(input: string = ''): string {
  // Ensure input is a string
  if (typeof input !== 'string') {
    return ''
  }
  let formatted = input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
  formatted = formatted.replace(
    /_([a-zA-Z])/g,
    (_, letter) => ` ${letter.toUpperCase()}`,
  )
  return formatted
}

/**
 *
 * @param str
 * @returns Capitalize the first letter of a string
 */
export const capitalizeFirstLetter = (str: string) => {
  if (!str || typeof str !== 'string' || str === '') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Build URL with query params
 * @param endpoint - The endpoint to hit
 * @param params - The query params
 * @returns The URL with query params
 */
export function buildUrl(endpoint: string, params?: any) {
  if (!params) return endpoint
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        if (value.length === 0) return ''
        return value
          .map(
            (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
          )
          .join('&')
      } else if (value == null) {
        return ''
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      }
    })
    .filter((param) => param)
    .join('&')
  if (endpoint.includes('?')) {
    return `${endpoint}&${queryString}`
  }
  return `${endpoint}?${queryString}`
}
