import Button from 'libs/button/Button'
import { toast } from 'libs/toast'
import { t } from 'i18next'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useDeleteEmployeeMutation } from 'pages/employee/employees-endpoints/employees.endpoints'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  data: any
}

const DeleteEmployee = ({ closePopUp, data }: Props) => {
  const navigate = useNavigate()
  const plant = useSelector(selectSelectedItem)

  const [DeleteEmployee, { isLoading: deleteEntryLoading }] = useDeleteEmployeeMutation()
  

  const handleDelete = () => {
    DeleteEmployee({ employeeID: data?._id, plant_id: plant.id })
   
      .unwrap()
      .then(() => {
        closePopUp()
        toast.success(`Employee “${data?.employeeID}” is removed successfully.`)
        navigate('/employee/employee-management')
      })
      .catch((err: any) => {
        closePopUp()
        toast.error(err?.data?.detail ?? `Failed to remove Employee “${data?.employeeID}”. Please try again.`)
      })
  }

  return (
    <>
      <p className="p-xs-regular">
        You are about to delete an{' '}
        <span className="break-words p-xs-bold">
          {'Employee '} {data?.employeeID}{' '}
        </span>
        Are you sure you want to proceed?
      </p>

      <div className=" mt-1 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDelete} loading={deleteEntryLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </>
  )
}

export default DeleteEmployee
