import { usePostDepartmentMutation } from 'pages/department/department-endpoints/department.endpoints'
import { useTranslation } from 'react-i18next'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'libs/toast'
import Field from 'libs/field'
import Button from 'libs/button/Button'
import { trimStringValues } from 'utils/stringHandlers'
import { PostDepartmentResponse } from 'pages/department/department-endpoints/departmentTypes'

type Props = {
  close: () => void
}

type FormValues = {
  description: string
  departmentName: string
}

const AddDepartment = ({ close }: Props) => {
  const [addDepartment, { isLoading }] = usePostDepartmentMutation()

  const selectedItem = useSelector(selectSelectedItem)
  const { t } = useTranslation()

  const formik = useFormik<FormValues>({
    initialValues: {
      departmentName: '',
      description: '',
    },
    validationSchema: yup.object({
      departmentName: yup
        .string()
        .required(t('departments.department_validation')),
      description: yup.string(),
    }),
    onSubmit: (values) => {
      const data = {
        name: values.departmentName,
        description: values.description,
      }
      const department = trimStringValues(data)
      addDepartment({
        plant_id: selectedItem?.id,
        data: department as PostDepartmentResponse,
      })
        .unwrap()
        .then((data) => {
          toast.success(`New department ${data?.name} is created successfully.`)
          close()
        })
        .catch((error) => {
          if (error?.data?.detail === 'Department already exits') {
            formik.setFieldError(
              'departmentName',
              t('departments.duplicate_validation_message'),
            )
          }

          toast.error(
            error?.data?.detail ?? 'Something went wrong. Please try again.',
          )
        })
    },
  })

  return (
    <>
      <p className="p-xs-regular-dim2  ">
        {t('departments.new_department_subtitle')}
      </p>

      <div className="flex flex-col gap-2">
        <Field
          label={t('departments.department_name')}
          formik={formik}
          name="departmentName"
          placeholder={t('departments.department_placeholder')}
          required
        />
        <Field
          type="textarea"
          label={t('departments.description')}
          formik={formik}
          name="description"
          placeholder={t('departments.description_placeholder')}
        />
        <div className="mt-2 flex flex-row-reverse gap-3">
          <Button
            color="primary"
            loading={isLoading}
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {t('add')}
          </Button>
          <Button onClick={close}>{t('cancel')}</Button>
        </div>
      </div>
    </>
  )
}

export default AddDepartment
