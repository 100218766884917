import Button from 'libs/button/Button'
import WhiteCard from 'libs/card/WhiteCard'
import MotionDiv from 'libs/motionDiv'
import PaginatedTable from 'libs/table/PaginatedTable'
import ProductTab from 'pages/work-orders/components/ProductTab'
import { useState } from 'react'
import { useGetMachineClassByIdQuery } from '../machine-class-endpoints/machineClass.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useNavigate, useParams } from 'react-router-dom'
import DeleteMachineClass from '../popup/DeleteMachineClass'
import Popup from 'libs/popup/Popup'
import FullPageLoader from 'libs/loader/FullPageLoader'
import Chip from 'libs/chip/Chip'
import useTabTitle from 'libs/hooks/useTabTitle'

const machineryClassTab = [
  {
    _id: 1,
    name: 'Machine Model',
  },
  {
    _id: 2,
    name: 'Machine Details',
  },
]

const ViewMachineClass = () => {
  useTabTitle('View Machine Class')
  const params = useParams()
  const navigate = useNavigate()
  const plantId = useSelector(selectSelectedItem)
  const [deletePopUp, setDeletePopUp] = useState({ isPopUpOpen: false, machineClassID: '' })
  const { data: machineClass, isLoading: machineClassLoading } = useGetMachineClassByIdQuery(
    {
      plantId: plantId?.id,
      machineClassID: params?.id,
    },
    {
      skip: !params?.id || !plantId?.id,
    },
  )

  const machineColumns = [
    {
      Header: 'Machine',
      width: 200,
      Cell: ({ row }: any) => {
        return (
          <div className="flex flex-col gap-[1px]">
            <h1
              className="hyperlink"
              onClick={() => {
                row?.original?._id && navigate(`/maintenance/machines/${row?.original?._id}/view/*`)
              }}
            >
              {row.original?.name ?? '-'}
            </h1>
            <h1 className="text-gray-600">{row?.original?.serialNumber ?? '-'}</h1>
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Model Number',
      accessor: 'modelNumber',
      width: 150,
      Cell: ({ row }: any) => {
        return <div>{row.original.machineClass?.models?.modelNumber ?? '-'}</div>
      },
      disableSortBy: true,
    },
    {
      Header: 'Work Center',
      width: 120,
      Cell: () => {
        return (
          <div className="flex flex-col gap-[1px]">
            <h1 className="text-gray-600">{'-'}</h1>
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Operators',
      accessor: 'operators',
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div>{row.original?.operators?.length > 0 ? String(row.original.operators.length).padStart(2, '0') : 0}</div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'No of Parts',
      accessor: 'partsDetails',
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div>
            {row.original?.partsDetails?.length > 0 ? String(row.original?.partsDetails.length).padStart(2, '0') : 0}
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: 'Machine Status',
      accessor: 'status',
      width: 150,
      Cell: ({ value }: any) => <Chip title={value} />,
      disableSortBy: true,
    },
  ]
  const MachineModels = [
    {
      Header: 'Machine Model Number',
      accessor: 'modelNumber',
      disableSortBy: true,
    },
    {
      Header: 'Number of Machines',
      accessor: 'machine',
      Cell: ({ row }: any) => {
        const machineData = machineClass?.machines || []
        const matchingMachineCount = machineData?.filter(
          (machine: any) => machine?.modelNumber === row.original?._id,
        ).length

        return <div>{matchingMachineCount > 0 ? matchingMachineCount : '-'}</div>
      },
      disableSortBy: true,
    },

    {
      Header: 'Manufacturer',
      accessor: 'manufacturer',
      disableSortBy: true,
    },
    {
      Header: 'Model Description',
      accessor: 'description',
      Cell: ({ value }: any) => {
        return <div>{value ? value : '-'}</div>
      },
      disableSortBy: true,
    },
  ]
  const [activeTab, setActiveTab] = useState(machineryClassTab[0])
  const filteredData = (name: string) => {
    switch (name) {
      case 'Machine Model':
        return machineClass?.machineModels
      case 'Machine Details':
        return machineClass?.machines
      default:
        return []
    }
  }

  const columns = (name: string) => {
    switch (name) {
      case 'Machine Model':
        return MachineModels
      case 'Machine Details':
        return machineColumns
      default:
        return []
    }
  }
  const columnsArray = columns(activeTab.name)
  const MachineData = filteredData(activeTab.name)

  return (
    <MotionDiv>
      <div className="w-full">
        {!machineClassLoading ? (
          <WhiteCard className="mt-3 flex flex-col gap-6">
            <div className="flex justify-between">
              <h5 className="h5">{machineClass?.name}</h5>
              <div className="flex gap-4">
                <Button
                  color="error"
                  onClick={() => {
                    setDeletePopUp({ isPopUpOpen: true, machineClassID: machineClass })
                  }}
                >
                  Delete Machinery Class
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    params.id && navigate(`/maintenance/machine-classes/edit/${params.id}`)
                  }}
                >
                  Edit Machinery Class
                </Button>
              </div>
            </div>

            <div>
              <div className="mb-4 mt-4 flex justify-between">
                {/* General Information */}
                <div>
                  <div className="flex gap-4">
                    <div className="flex flex-col gap-4">
                      <span className="flex gap-4 p-xs-regular-dim ">Machine Class</span>
                      <h1 className="p-xs-regular-dim">Description</h1>
                    </div>
                    <div className="flex flex-col gap-4">
                      <span className="p-xs-regular">{machineClass?.name}</span>
                      <h1 className="text-xs">
                        {' '}
                        <div className="w-[500px] p-xs-regular">
                          {machineClass?.description ? machineClass?.description : '-'}
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="mr-12 flex gap-4">
                  <div className="flex flex-col gap-4">
                    <h1 className="pr-16 text-xs text-gray-600">Machine Models</h1>
                    <h1 className="pr-16 text-xs text-gray-600">Total Machines</h1>
                  </div>
                  <div className="flex flex-col gap-4">
                    <h1 className="text-xs">
                      {machineClass?.machineModels?.length > 0
                        ? String(machineClass?.machineModels?.length).padStart(2, '0')
                        : 0}
                    </h1>

                    <h1 className="text-xs">
                      {machineClass?.machines?.length > 0 ? String(machineClass?.machines?.length).padStart(2, '0') : 0}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-1 flex flex-wrap gap-3">
              {machineryClassTab.map((tab: any) => (
                <ProductTab
                  key={tab._id}
                  onClick={() => {
                    setActiveTab(tab)
                  }}
                  tabText={tab.name}
                  isActive={activeTab.name === tab.name}
                />
              ))}
            </div>
            <PaginatedTable
              columns={columnsArray}
              rows={MachineData}
              emptyMessage={
                activeTab.name === 'Machine Model'
                  ? 'No Machine module are added. Please add a Machine module'
                  : 'No Machine are added. Please add the Machine '
              }
              hidePagination={true}
            />

            <Popup
              isOpen={deletePopUp.isPopUpOpen}
              title="Delete Machine Class?"
              onClose={() => {
                setDeletePopUp({ isPopUpOpen: false, machineClassID: '' })
              }}
            >
              <DeleteMachineClass
                closePopUp={() => setDeletePopUp({ isPopUpOpen: false, machineClassID: '' })}
                machineClass={deletePopUp.machineClassID}
                plant_id={plantId?.id}
              />
            </Popup>
          </WhiteCard>
        ) : (
          <FullPageLoader />
        )}
      </div>
    </MotionDiv>
  )
}

export default ViewMachineClass
