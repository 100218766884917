export let authErrors = {
  CONTACT_INVALID: "Phone Number is Invalid",
  CONTACT_EMPTY: "Phone number cannot be empty.",
  EMPTY_EMAIL: "Please Enter an Email Address",
  EMAIL_PASSWORD_EMPTY: "Please enter both Email and Password",
  EMAIL_INVALID: "Please enter a valid email address",
  PASSWORD_INVALID: "Please enter a valid Password",
  EMAIL_PASSWORD_INVALID:
    "The email and password combination you entered appears to be incorrect. Please try again.",
  INVALID_RESET_PASSWORD_COMBINATION:
    "Confirm password and entered password does not match",
  INVALID_NEW_PASSWORD: "Entered password should not match the old password ",
  INVALID_OTP: "Invalid verification code provided, please try again."
};
