import { formatNumber } from 'utils/numberHandlers'
import { formatUnixDate, formatUnixTime } from 'utils/dateTimeHandlers'

interface Props {
  data: any
}

const ShowEntriesPanel = ({ data }: Props) => {
  return (
    <div>
      <div className="mt-3 flex flex-col gap-2  rounded-lg bg-gray-100 p-3">
        <p>Product Details</p>
        <div className="flex justify-between">
          <div className=" p-xs-regular-dim"> Product Type</div>
          <div className="ml-5 ">
            <div className="break-words  p-xs-regular">{data.product_type}</div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className=" p-xs-regular-dim "> Product Name</div>
          <div className="ml-5 ">
            <div className="flex break-words  p-xs-regular">
              {data.product?.name}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className=" p-xs-regular-dim  ">Quantity</div>
          <div className="ml-5 ">
            <div className="break-words  p-xs-regular">
              {formatNumber(data.quantity)}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className=" w-[500px]  p-xs-regular-dim">Unit</div>
          <div className="ml-5 ">
            <div className="break-words  p-xs-regular">
              {data.product.unit_of_measure}
            </div>
          </div>
        </div>

        {data.work_order.workOrderID && (
          <div className="flex justify-between">
            <div className="   p-xs-regular-dim">Work Order</div>
            <div className="ml-5 ">
              <div className=" break-words p-xs-regular">
                {data.work_order.workOrderID}
              </div>
            </div>
          </div>
        )}

        {data.warehouse_from.name && (
          <div className="flex justify-between">
            <div className="   p-xs-regular-dim">Warehouse From</div>
            <div className="ml-5 ">
              <div className=" break-words p-xs-regular">
                {data.warehouse_from.name}
              </div>
            </div>
          </div>
        )}
        {data.warehouse_to.name && (
          <div className="flex justify-between">
            <div className=" p-xs-regular-dim">Warehouse To</div>
            <div className="ml-5 ">
              <div className=" break-words p-xs-regular">
                {data.warehouse_to.name}
              </div>
            </div>
          </div>
        )}
        {data.storage_area_from.name && (
          <div className="flex justify-between">
            <div className="p-xs-regular-dim">Storage Area From</div>
            <div className="ml-5 ">
              <div className="break-word p-xs-regular">
                {data.storage_area_from.name}
              </div>
            </div>
          </div>
        )}

        {data.storage_area_to.name && (
          <div className="flex justify-between">
            <div className="p-xs-regular-dim">Storage Area To</div>
            <div className="ml-5 ">
              <div className="break-words p-xs-regular">
                {data.storage_area_to.name}
              </div>
            </div>
          </div>
        )}
      </div>
      {data?.data?.length > 0 && (
        <div className="mt-4 flex flex-col gap-3 rounded-lg bg-gray-100 p-3 ">
          <p>Entry Details</p>
          {data?.data.map((item: any, index: any) => {
            let value = item.value
            if (item?.field_info?.type === 'Date selection') {
              if (item?.field_info?.has_date_range) {
                value = `${formatUnixDate(item.value[0])} ~ ${formatUnixDate(item.value[1])}`
              } else {
                value = formatUnixDate(item.value)
              }
            }
            if (item?.field_info?.type === 'Time selection') {
              value = formatUnixTime(item.value)
            }
            if (item?.field_info?.type === 'Checkbox') {
              value = item.value ? 'Checked' : 'Not Checked'
            }
            if (value == null || value === '') {
              value = '-'
            }
            return (
              <div key={index} className="flex justify-between ">
                <div className=" w-2/5 p-xs-regular-dim">
                  {item?.field_info?.name}
                </div>
                <div className="ml-5  ">
                  <div className="break-words  p-xs-regular">{value}</div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ShowEntriesPanel
