import { useNavigate } from 'react-router-dom'
import { toast } from 'libs/toast'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import Button from 'libs/button/Button'
import { useDeleteRoutingMutation } from '../routings-endpoints/routings.endpoints'

const DeleteRouting = ({ closePopUp, data }: any) => {
  const selectedPlant = useSelector(selectSelectedItem)
  
  const navigate = useNavigate()
  const [DeleteRouting, { isLoading }] = useDeleteRoutingMutation()
  
  const handleDelete = () => {
    if (data?.id && data?.name && selectedPlant?.id) {
      DeleteRouting({
        id: data?.id,
        plant_id: selectedPlant?.id,
      })
        .unwrap()
        .then(() => {
          toast.success(<div>The Routing <b>“{data?.name}”</b> is deleted successfully.</div>)
          closePopUp()
          navigate('/settings/configure/routings')
        })
        .catch((err:any) => {
          toast.error(err?.data?.detail ?? `Failed to delete the Routing“${data?.name}”. Please try again.`)
        })
    }
  }

  return (
    <div>
      <p className="p-xs-regular">
        You are about to delete routing <span className="break-words p-xs-bold">“{data?.name}”. </span>
    Are you sure you want to proceed?
      </p>

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={closePopUp}>Close</Button>
        <Button color="error" type="button" loading={isLoading} onClick={handleDelete}>
         Delete
        </Button>
      </div>
    </div>
  )
}

export default DeleteRouting
