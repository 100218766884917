import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import { formatUnixDate } from 'utils/dateTimeHandlers'
import { formatNumber } from 'utils/numberHandlers'
import ClickableRowArrow from 'assets/icons/camera-page/ClickableRowArrow'
import Chip from 'libs/chip/Chip'
import { useNavigate } from 'react-router-dom'
import ProgressBar from 'libs/charts/ProgressBar'
import { hasAccess } from 'utils/utils'

export const getTableConfig = (
  handleDeleteClick: (row: any) => void = () => {},
  handleEditClick: (row: any) => void = () => {},
  activeTab: { name: string } = { name: 'DefaultTab' },
): any => {
  const navigate = useNavigate()
  const manufactureOrderEditAccess = hasAccess('pla', { mo: 'e' })
  const manufactureOrderAccess = hasAccess('pla', { mo: 'v' })
  const salesOrderAccess = hasAccess('ord', { so: 'v' })
  const purchaseOrderAccess = hasAccess('ord', { po: 'v' })
  const workOrderAccess = hasAccess('pla', { wo: 'v' })

  const columns = [
    {
      Header: 'MO ID',
      accessor: 'manufactureOrderID',
      Cell: ({ row }: any) => {
        const data = row?.original
        return (
          <div
            className="hyperlink"
            onClick={() => {
              manufactureOrderAccess &&
                navigate(`/planning/mo/view/${data?._id}`)
            }}
          >
            {data?.manufactureOrderID}
          </div>
        )
      },
    },
    {
      Header: 'Finished Good',
      accessor: 'finishedGood',
      width: 200,
      Cell: ({ value }: any) => (
        <div>
          <p className="p-sm-regular">{value?.name}</p>
          <p className="p-xs-regular-dim">{value?.code}</p>
        </div>
      ),
    },
    {
      Header: 'Sales Order',
      accessor: 'salesOrders',
      Cell: ({ row }: any) => {
        const salesOrders = row?.original?.salesOrders?.length
        return (
          <div
            className={`${salesOrders !== undefined ? 'hyperlink' : ''}`}
            onClick={() =>
              manufactureOrderAccess &&
              navigate(`/planning/mo/view/${row?.original?._id}`)
            }
          >
            {salesOrders !== undefined
              ? `${formatNumber(salesOrders)} Order`
              : '-'}
          </div>
        )
      },
    },
    {
      Header: 'Purchase Order',
      accessor: 'purchaseOrders',
      Cell: ({ row }: any) => {
        const purchaseOrders = row?.original?.purchaseOrders?.length
        return (
          <div
            className={`${purchaseOrders !== undefined ? 'hyperlink' : ''}`}
            onClick={() =>
              manufactureOrderAccess &&
              navigate(`/planning/mo/view/${row?.original?._id}`)
            }
          >
            {purchaseOrders !== undefined
              ? `${formatNumber(purchaseOrders)} Order`
              : '-'}
          </div>
        )
      },
    },
    {
      Header: 'Work Order',
      accessor: 'workOrders',
      Cell: ({ row }: any) => {
        const workOrders = row?.original?.workOrders?.length
        return (
          <div
            className={`${workOrders !== undefined ? 'hyperlink' : ''}`}
            onClick={() =>
              manufactureOrderAccess &&
              navigate(`/planning/mo/view/${row?.original?._id}`)
            }
          >
            {workOrders !== undefined
              ? `${formatNumber(workOrders)} Order`
              : '-'}
          </div>
        )
      },
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ value }: any) => {
        return (
          <span className="whitespace-nowrap text-xs">
            {value !== null ? formatUnixDate(value) : '-'}
          </span>
        )
      },
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ value }: any) => {
        return (
          <span className="whitespace-nowrap text-xs">
            {value !== null ? formatUnixDate(value) : '-'}
          </span>
        )
      },
    },
  ]

  if (activeTab?.name !== 'Draft Orders') {
    columns.push({
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => <Chip title={value} />,
    })
  }

  columns.push({
    Header: 'Actions',
    accessor: 'action',
    width: 130,
    Cell: ({ row }: any) => (
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-row">
          <button
            className={`mr-2 ${manufactureOrderEditAccess ? '' : 'opacity-40'}`}
            onClick={(event) => {
              event.stopPropagation()
              manufactureOrderEditAccess && handleEditClick(row?.original)
            }}
          >
            <Pen />
          </button>
          <button
            className={`mr-2 ${manufactureOrderEditAccess ? '' : 'opacity-40'}`}
            onClick={(event) => {
              event.stopPropagation()
              if (row?.original) {
                manufactureOrderEditAccess && handleDeleteClick(row?.original)
              }
            }}
          >
            <Bin />
          </button>
        </div>
        <span
          className="cursor-pointer"
          onClick={() =>
            manufactureOrderAccess &&
            navigate(`/planning/mo/view/${row?.original?._id}`)
          }
        >
          <ClickableRowArrow />
        </span>
      </div>
    ),
  })

  const options = [
    {
      value: 'NOT_STARTED',
      label: 'Not Started',
    },
    {
      value: 'IN_PROGRESS',
      label: 'In Progress',
    },
    {
      value: 'COMPLETED',
      label: 'Completed',
    },
    {
      value: 'CANCELLED',
      label: 'Cancelled',
    },
  ]

  const filters = {
    page_no: 1,
    page_size: 1000,
    createdBetween: [],
    updatedBetween: [],
    endBetween: [],
    startBetween: [],
    status: undefined,
    finished_goods: [],
  }

  const moTabs = [
    {
      _id: 1,
      name: 'Sales Orders',
    },
    {
      _id: 2,
      name: 'Purchase Orders',
    },
    {
      _id: 3,
      name: 'Work Orders',
    },
  ]

  const salesOrderFilters = {
    page_no: 1,
    page_size: 10,
    searchText: '',
    createdBetween: [],
    updatedBetween: [],
    status: [],
    category: [],
    sortBy: 'created_on',
    sortDir: 'DESC',
  }

  const purchaseOrdersColumns = [
    {
      Header: 'Purchase Order ID',
      accessor: 'purchaseOrderID',
      Cell: ({ row }: any) => (
        <div
          className={`${purchaseOrderAccess ? 'hyperlink' : ''}`}
          onClick={() => {
            purchaseOrderAccess &&
              navigate(`/orders/purchase-order/view/${row?.original?._id}`)
          }}
        >
          {row?.original?.purchaseOrderID}
        </div>
      ),
    },
    {
      Header: 'Supplier Name',
      accessor: 'vendor',
      Cell: ({ value }: any) => <div>{value?.name}</div>,
    },
    {
      Header: 'Raw Materials',
      accessor: 'products',
      Cell: ({ value }: any) => (
        <div>
          <p className="text-primary-700 underline underline-offset-2 ">
            {value?.length} Raw Material
          </p>
        </div>
      ),
    },
    {
      Header: 'Arrival Date',
      accessor: 'arrivalDate',
      Cell: ({ value }: any) => {
        return value ? formatUnixDate(value) : '-'
      },
    },

    // {
    //   Header: 'Quantity',
    //   accessor: 'quantity',
    // },
  ]

  const salesOrdersColumns = [
    {
      Header: 'Sale Order ID',
      accessor: 'salesOrderID',
      Cell: ({ row }: any) => (
        <div
          className={`${salesOrderAccess ? 'hyperlink' : ''}`}
          onClick={() => {
            if (salesOrderAccess)
              navigate(`/orders/sales-order/view/${row?.original?._id}`)
          }}
        >
          {row?.original?.salesOrderID}
        </div>
      ),
    },
    {
      Header: 'Customer Name',
      accessor: 'customer',
      Cell: ({ value }: any) => <div>{value?.name}</div>,
    },
    {
      Header: 'Product',
      accessor: 'products',
      Cell: ({ row }: any) => {
        return (
          <div
            onClick={() => {
              if (salesOrderAccess)
                navigate(`/orders/sales-order/view/${row?.original?._id}`)
            }}
          >
            <p className={`${salesOrderAccess ? 'hyperlink' : ''}`}>
              {row?.original?.products?.length} Products
            </p>
          </div>
        )
      },
    },
    {
      Header: 'Delivery Date',
      accessor: 'deliveryDate',
      Cell: ({ value }: any) => {
        return (
          <span className="whitespace-nowrap text-xs">
            {value !== null ? formatUnixDate(value) : '-'}
          </span>
        )
      },
    },
  ]

  const workOrdersColumns = [
    {
      Header: 'Work Order ID',
      accessor: 'workOrderID',
      Cell: ({ row }: any) => (
        <div
          className={`${workOrderAccess ? 'hyperlink' : ''}`}
          onClick={() => {
            workOrderAccess &&
              navigate(`/planning/work-orders/view/${row?.original?._id}`)
          }}
        >
          {row?.original?.workOrderID}
        </div>
      ),
    },
    {
      Header: 'Operations',
      accessor: 'operations',
      Cell: ({ row }: any) => (
        <div>
          <p
            className={`${workOrderAccess ? 'hyperlink' : ''}`}
            onClick={() => {
              workOrderAccess &&
                navigate(`/planning/work-orders/view/${row?.original?._id}`)
            }}
          >
            {row?.original?.operaitons?.length} Operation
          </p>
        </div>
      ),
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ value }: any) => {
        return (
          <span className="whitespace-nowrap text-xs">
            {value !== null ? formatUnixDate(value) : '-'}
          </span>
        )
      },
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ value }: any) => {
        return (
          <span className="whitespace-nowrap text-xs">
            {value !== null ? formatUnixDate(value) : '-'}
          </span>
        )
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: any) => <Chip title={value} />,
    },
    {
      Header: 'Assigned to',
      accessor: 'assignedTo',
      Cell: ({ value }: any) => (
        <div>
          <p className="text-primary-700 underline underline-offset-2 ">
            {value?.length} Assignees
          </p>
        </div>
      ),
    },
    {
      Header: 'Progress',
      accessor: 'workOrderProgressPercentage',
      Cell: ({ value }: { value: number }) => {
        const workOrderPercentage = parseInt(value.toFixed(0)) // Convert back to number if ProgressBar expects it

        return (
          <div className="flex w-full flex-col">
            <div className="mb-2">
              <ProgressBar percentage={workOrderPercentage} />
            </div>
          </div>
        )
      },
    },
  ]

  return {
    columns,
    options,
    filters,
    moTabs,
    salesOrderFilters,
    purchaseOrdersColumns,
    salesOrdersColumns,
    workOrdersColumns,
  }
}
