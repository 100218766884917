import { useState, useMemo, useRef, useEffect, ChangeEvent } from 'react'

import { Chips } from 'primereact/chips'

import BlueTick from 'assets/icons/product/BlueTick'
import PlusIcon from 'assets/icons/camera-page/PlusIcon'
import { toastSuccess } from 'libs/toast/toastSucess'
import { toastFailure } from 'libs/toast/toastFailure'
import AddProperty from './add-new-property/AddProperty'
import Pen from 'assets/icons/camera-page/Pen'
import Bin from 'assets/icons/camera-page/Bin'
import DeleteProperty from './delete-property/DeleteProperty'
import EditProperty from './edit-new-property/EditProperty'
import {
  useAddEditBomQuantityMutation,
  usePostProductMutation,
} from './products-endpoints/products.endpoints'
import { useNavigate, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useEditProductMutation } from './products-endpoints/products.endpoints'
import ArrowDown from 'assets/icons/camera-page/ArrowDown'
import { dropdownMotionProps } from 'utils/FramerProps'
import AddBom from './add-bom/AddBom'
import DeleteBom from './delete-bom/DeleteBom'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useGetBomQuery } from './products-endpoints/products.endpoints'
import { useDeleteBomMutation } from './products-endpoints/products.endpoints'
import { t } from 'i18next'
import MotionDiv from 'libs/motionDiv'
import { formatNumber } from 'utils/numberHandlers'
import Button from 'libs/button/Button'
import useTabTitle from 'libs/hooks/useTabTitle'
import SidePanel from 'libs/sidepanel/SidePanel'
import Popup from 'libs/popup/Popup'
import { trimStringValues } from 'utils/stringHandlers'
import {
  Errors,
  FormData,
  TableData,
  ProductCategory,
  CheckboxState,
} from './products-endpoints/NewProductsTypes'
import { ExtendedColumn } from 'store/services/utilsTypes'

const productCategory = [
  { product_category: 'Finished Goods', product_code: '' },
  { product_category: 'Raw Materials', product_code: '' },
]

const NewProducts = () => {
  const selectedItem = useSelector(selectSelectedItem)
  const location = useLocation()
  const state = location.state
  const navigate = useNavigate()
  const [addProducts, { isLoading }] = usePostProductMutation()
  const [formData, setFormData] = useState<FormData>({
    productName: '',
    productCode: '',
    description: '',
    UOM: '',
    bom_quantity: '',
  })
  useTabTitle(t('product.new_product'))
  const [errors, setErrors] = useState<Errors>({})
  const [tagsValue, setValue] = useState([])
  const [page, setPage] = useState(1)
  //add entry panel
  const [showPanel, setShowPanel] = useState(false)
  const [showBomPanel, setShowBomPanel] = useState(false)
  const [tableData, setTableData] = useState<TableData[]>([])
  const [_, setKey] = useState(0)
  const filters = {
    page_size: 25,
  }
  //edit entry panel
  const [editPanel, setEditPanel] = useState(false)
  const [editBomPanel, setEditBomPanel] = useState(false)
  //show delete popup
  const [deletPopup, setDeletePopup] = useState(false)
  const [deletBomPopup, setDeleteBomPopup] = useState(false)
  const [entryToDelete, setEntryToDelete] = useState<any>(null)
  const [selectedEntry, setSelectedEntry] = useState<any>()
  const [productId, setProductId] = useState<string>('')
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [searchInput, setSearchInput] = useState<any>('')
  const [filteredData, setFilteredData] =
    useState<ProductCategory[]>(productCategory)
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({
    propertiesChecked: false,
    consumableChecked: false,
    bomChecked: false,
    shouldAcceptDecimal: false,
  })

  const [editProducts, { isLoading: isEditProductLoading }] =
    useEditProductMutation()

  const { data: bomDetails, isLoading: productBomLoading } = useGetBomQuery(
    {
      productId: productId,
    },
    {
      skip: !productId,
    },
  )

  const [addBomQty, { isLoading: BomQuantityLoading }] =
    useAddEditBomQuantityMutation()

  const [deleteBom] = useDeleteBomMutation()

  const popupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // Check if the click is outside the popup
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.ignoreref')
      ) {
        setEditPanel(false)
        setShowPanel(false)
        setEditBomPanel(false)
        setShowBomPanel(false)
        setIsDropdownVisible(false)
      }
    }

    // Adding event listener to detect clicks outside the popups
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  //toggle edit entry
  const toggleEditPanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setEditPanel(!editPanel)
  }
  const toggleBomEditPanel = (entryData: any) => {
    setSelectedEntry(entryData)
    setEditBomPanel(!editBomPanel)
  }

  //toggle delete popup
  const toggleDelete = (entryData: any) => {
    setEntryToDelete(entryData)
    setDeletePopup(!deletPopup)
  }
  const toggleBomDelete = (entryData: any) => {
    setEntryToDelete(entryData)
    setDeleteBomPopup(!deletBomPopup)
  }

  const deleteBomData = () => {
    if (entryToDelete) {
      deleteBom({ productId: productId, bomId: entryToDelete.id })
        .unwrap()
        .then(() => {
          toastSuccess(
            `BOM for Product "${entryToDelete?.product.name}" is removed successfully.`,
          )
          setDeleteBomPopup(!deletBomPopup)
        })
        .catch((err) => {
          toastFailure(
            err?.data?.detail ??
              `Failed to delete BOM for Product "${entryToDelete?.product.name}""`,
          )
        })
    }
  }

  const handlePrevious = () => {
    switch (page) {
      case 2:
        setPage(1)
        break
      case 3:
        setPage(2)
        break
      default:
        break
    }
  }

  //toggle add entry
  const togglePanel = () => {
    setShowPanel(!showPanel)
  }
  const toggleBomPanel = () => {
    setShowBomPanel(!showBomPanel)
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    // Check if the name is 'containerName' and the length is more than 100 characters
    if (name === 'productName' && value.length >= 101) {
      setErrors({
        ...errors,
        [name]: 'Product Name cannot exceed 100 characters.',
      })
    } else {
      setFormData({ ...formData, [name]: value })
      setErrors({ ...errors, [name]: '' })
    }
  }

  const handleTagsChange = (e: any) => {
    setValue(e.value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const newErrors: any = {}

    // Check each field for errors
    if (formData.productName.trim() === '') {
      newErrors.productName = 'The product name cannot be empty.'
    }
    if (formData.productCode.trim() === '') {
      newErrors.productCode = 'The product code cannot be empty.'
    }
    if (formData.UOM.trim() === '') {
      newErrors.UOM = 'The unit of measure cannot be empty.'
    }
    if (searchInput === '') {
      newErrors.productCategory = 'The product category cannot be empty.'
    }

    // Set the new errors
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0 && !productId) {
      const productData = {
        name: formData.productName,
        code: formData.productCode,
        description: formData.description,
        tags: tagsValue,
        category: searchInput.product_category,
        unit_of_measure: formData.UOM,
        is_bom: !!checkboxState.bomChecked,
        is_consumable: checkboxState.consumableChecked,
        is_properties: checkboxState.propertiesChecked,
        is_accepting_decimal: checkboxState?.shouldAcceptDecimal,
      }

      const data = trimStringValues(productData)
      // First API call to create a product
      addProducts({
        data: data,
        plantId: selectedItem?.id,
      })
        .unwrap()
        .then((res) => {
          setProductId(res?._id)
          const updatedState = {
            name: formData.productName,
            code: formData.productCode,
          }
          navigate(location.pathname, { state: updatedState })
          // Check if the product was successfully created
          if (res?._id) {
            // Second API call using the retrieved product ID
            toastSuccess(
              `Product "${formData.productName}" is added successfully`,
            )
            switch (true) {
              case checkboxState.bomChecked:
                setPage(2)
                break
              case !checkboxState.bomChecked && checkboxState.propertiesChecked:
                setPage(3)
                break
              case !checkboxState.bomChecked &&
                !checkboxState.propertiesChecked:
                navigate('/settings/configure/products')
                break
              default:
                break
            }
          }
        })
        .catch((err) => {
          if (
            err?.data?.detail ===
            `Product code - ${formData.productCode} already exists with this plant`
          ) {
            newErrors.productCode = 'This product code already exists.'
            toastFailure(
              err?.data?.detail ?? 'This product code already exists.',
            )
          } else {
            toastFailure(
              err?.data?.detail ?? 'Something went wrong. Please try again',
            )
          }
        })
    }
    if (Object.keys(newErrors).length === 0 && productId) {
      let productData = {
        name: formData.productName,
        code: formData.productCode,
        description: formData.description,
        tags: tagsValue,
        category: searchInput.product_category,
        is_bom: checkboxState.bomChecked,
        is_consumable: checkboxState.consumableChecked,
        is_properties: checkboxState.propertiesChecked,
        is_accepting_decimal: checkboxState?.shouldAcceptDecimal,
      }

      if (formData.productName !== state.name) {
        productData = {
          ...productData,
          name: formData.productName,
        }
      }
      if (formData.productCode !== state.code) {
        productData = {
          ...productData,
          code: formData.productCode,
        }
      }

      const data = trimStringValues(productData)

      editProducts({
        data: data,
        productId: productId,
      })
        .unwrap()
        .then(() => {
          toastSuccess(
            `Product "${formData?.productName}" is updated successfully.`,
          )
          switch (true) {
            case checkboxState.bomChecked:
              setPage(2)
              break
            case !checkboxState.bomChecked && checkboxState.propertiesChecked:
              setPage(3)
              break
            default:
              break
          }
          const updatedState = {
            name: formData.productName,
            code: formData.productCode,
          }
          navigate(location.pathname, { state: updatedState })
          navigate('/settings/configure/products')
        })
        .catch((err) => {
          toastFailure(
            err?.data?.detail ?? 'Something went wrong. Please try again',
          )
        })
    }
  }

  const handleSubmission = async () => {
    const newErrors: { UOM?: string; bomQuantity?: string } = {}
    // Check each field for errors
    if (formData.UOM.trim() === '') {
      newErrors.UOM = 'UOM cannot be empty.'
    }
    if (formData?.bom_quantity?.trim() === '') {
      newErrors.bomQuantity = 'The Quantity cannot be empty.'
      return
    }

    // Set the new errors
    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0 && formData.UOM) {
      const productData = {
        category: searchInput.product_category,
        name: formData.productName,
        code: formData.productCode,
        description: formData.description,
        tags: tagsValue,
        is_bom: checkboxState.bomChecked,
        is_consumable: checkboxState.consumableChecked,
        is_properties: checkboxState.propertiesChecked,
        unit_of_measure: formData.UOM,
        is_accepting_decimal: checkboxState.shouldAcceptDecimal,
      }

      const data = trimStringValues(productData)
      try {
        const params = {
          product_id: productId,
          bom_quantity: formData.bom_quantity,
        }
        await addBomQty(params)
        const productUpdate = await editProducts({
          data: data,
          productId: productId,
        })

        if ('data' in productUpdate && productUpdate.data?._id) {
          navigate('/settings/configure/products')
        }
      } catch (e) {}
    }
  }

  const addTableData = (newData: any) => {
    setTableData((prevTableData) => [...prevTableData, newData])
    setKey((prevKey) => prevKey + 1)
  }
  const editTableData = (updatedData: any) => {
    const indexToEdit = tableData.findIndex(
      (entry) => entry.id === updatedData.id,
    )

    if (indexToEdit !== -1) {
      // Create a new array with the entry updated at the specified index
      const updatedTableData = [...tableData]
      updatedTableData[indexToEdit] = updatedData

      // Update the table data with the edited entry
      setTableData(updatedTableData)
      setKey((prevKey) => prevKey + 1)
    }
  }

  const deleteTabledata = () => {
    setTableData((prevTableData) =>
      prevTableData.filter((entry) => entry !== entryToDelete),
    )
    setKey((prevKey) => prevKey + 1)
    toastSuccess(`Property "${entryToDelete?.name}"  is removed successfully.`)
    setDeletePopup(!deletPopup)
    setEditPanel(false)
  }
  const handleInputClick = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase()
    setSearchInput(searchValue)

    const filtered = productCategory?.filter(
      (item: any) =>
        item.product_category.toLowerCase().includes(searchValue) ||
        (item.code && item.product_code.toLowerCase().includes(searchValue)),
    )

    setFilteredData(filtered)
  }

  const handleTitleClick = (title: any) => {
    setSearchInput(title)
    setIsDropdownVisible(false)
    setErrors({ ...errors, productCategory: '' })
  }

  const handleCheckboxChange = (checkboxName: keyof CheckboxState) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }))
  }

  const BOMColumns: ExtendedColumn<object>[] = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'product',
        Cell: ({ row }: any) => (
          <div className="">
            <div className="p-xs-regular">
              {row.original?.bom_product_details?.name}
            </div>
            <div className="p-xs-regular-dim">
              {row.original?.bom_product_details?.product?.code}
            </div>
          </div>
        ),
        disableSortBy: true,
      },

      {
        Header: 'Quantity',
        align: 'right',
        accessor: 'quantity',
        Cell: ({ value }: any) => {
          return <span className="font-mono">{formatNumber(value)}</span>
        },
      },
      {
        Header: 'Unit of Measure',
        accessor: 'unit',
        Cell: ({ row }: any) => (
          <span className="flex w-full justify-start">
            {row.original?.bom_product_details?.unit_of_measure}
          </span>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: any) => (
          <div className="flex justify-end gap-1">
            <button
              onClick={(event) => {
                event.stopPropagation()
                toggleBomEditPanel(row?.original)
              }}
            >
              <Pen />
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation()
                toggleBomDelete(row?.original)
              }}
            >
              <Bin />
            </button>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [],
  )

  return (
    <MotionDiv>
      <>
        <div className="flex justify-between">
          <div className="flex items-baseline gap-2 ">
            <h2 className="mb-2 max-w-[500px] truncate h2">
              {page === 2 ? formData.productName : 'New Product'}
            </h2>
            <h6 className="p-md-regular">
              {page === 2 ? formData.productCode : ''}
            </h6>
          </div>
          <div className="mt-4 flex items-baseline gap-1 p-md-regular">
            <div>{page === 2 ? 'Last updated :' : null}</div>
            <div>{page === 2 ? 'Just now' : null}</div>
          </div>
        </div>
        <div className="flex min-h-[65vh] w-full rounded  bg-[#FFFFFF] p-4">
          <div className="relative border-r border-[#D2DADF] py-2 pl-2  pr-6">
            <div className="mb-4 flex items-center gap-2">
              <div
                className={`h-6 w-6 rounded-3xl border p-2 ${
                  page === 2 || page === 3
                    ? 'border-[#1192F0] bg-[#1192F0]'
                    : 'border-[#1192F0]'
                } flex items-center justify-center`}
              >
                <div className="text-xs font-bold">
                  {page === 2 || page === 3 ? <BlueTick /> : '1'}
                </div>
              </div>
              <p className="whitespace-nowrap p-sm-regular">
                {t('product.product_details')}
              </p>
            </div>
            <div
              className={`absolute h-[16px] border-r-2 border-dashed  ${
                page === 2
                  ? 'border-[#1192F0]'
                  : checkboxState.bomChecked
                    ? 'border-[#B9C4CC]'
                    : 'border-[#B9C4CC] opacity-40'
              }`}
              style={{ left: '19px', top: '32px' }}
            ></div>
            <div className="mb-4 flex items-center gap-2">
              <div
                className={`h-6 w-6 rounded-3xl border p-2 ${
                  page === 2
                    ? 'border-[#1192F0]'
                    : checkboxState.bomChecked
                      ? 'border-[#B9C4CC]'
                      : 'border-[#B9C4CC] opacity-40'
                } flex items-center justify-center`}
              >
                <div className="text-xs font-bold">2</div>
              </div>
              <p
                className={`p-sm-regular ${checkboxState.bomChecked ? '' : 'opacity-40 '}whitespace-nowrap`}
              >
                {t('product.bill_of_materials')}
              </p>
            </div>
          </div>
          <div className="basis-full" style={{ overflow: 'auto' }}>
            {page === 1 && (
              <div className="ml-6">
                <h5 className="h5-1">{t('product.product_details')}</h5>
                <p className="mb-4 p-sm-regular-1">
                  {t('product.product_detail_subtitle')}
                </p>
                <div className="w-full flex-col">
                  <div className="flex w-[630px] flex-wrap justify-between gap-6">
                    <div>
                      <label className=" p-sm-regular">
                        {t('product.product_name')}{' '}
                        <span className="text-[#DC3545]">*</span>
                      </label>
                      <div>
                        <div className="relative py-1">
                          <input
                            autoComplete="off"
                            required
                            type="text"
                            name="productName"
                            value={formData.productName}
                            onChange={handleInputChange}
                            className={` h-[28px] border ${
                              errors.productName
                                ? 'border-[#DC3545]'
                                : 'border-none'
                            } w-[300px] rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8  text-sm outline-none p-xs-regular`}
                            placeholder={t('product.product_name_placeholder')}
                            maxLength={101}
                          />
                          {errors.productName && (
                            <p className="pt-1 text-xs font-normal text-[#DC3545]">
                              {errors.productName}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className=" p-sm-regular">
                        {t('product.product_code')}{' '}
                        <span className="text-[#DC3545]">*</span>
                      </label>
                      <div>
                        <div className="relative py-1">
                          <input
                            autoComplete="off"
                            required
                            type="text"
                            name="productCode"
                            value={formData.productCode}
                            onChange={handleInputChange}
                            className={`h-[28px] border ${
                              errors.productCode
                                ? 'border-[#DC3545]'
                                : 'border-none'
                            } w-[300px] rounded-md border border-solid bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular`}
                            placeholder={t('product.product_code_placeholder')}
                          />
                          {errors.productCode && (
                            <p className="pt-1 text-xs font-normal text-[#DC3545]">
                              {errors.productCode}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className=" relative flex flex-col">
                        <label className="mb-1 p-sm-regular">
                          {t('product.product_category')}
                          <span className="text-[#DC3545]">*</span>
                        </label>
                        <input
                          autoComplete="off"
                          required
                          name="product_category"
                          type="text"
                          className={`ignoreref h-[28px] w-[300px] ${
                            errors.productCategory
                              ? 'border-[#DC3545]'
                              : 'border-none'
                          } cursor-pointer rounded-md 
                          border bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular focus:border-[#1193F0]`}
                          placeholder={t(
                            'product.product_category_placeholder',
                          )}
                          onClick={handleInputClick}
                          value={searchInput.product_category}
                          onChange={handleSearchInputChange}
                        />
                        <div className="absolute right-[0.5rem] top-[1.9rem]">
                          <ArrowDown />
                        </div>
                        {errors.productCategory && (
                          <p className="pt-1 text-xs font-normal text-[#DC3545]">
                            {errors.productCategory}
                          </p>
                        )}
                        <AnimatePresence>
                          {isDropdownVisible && (
                            <motion.div
                              initial="closed"
                              animate="open"
                              exit="closed"
                              variants={dropdownMotionProps}
                              ref={popupRef}
                              className="absolute top-[3.9rem] max-h-[15rem] min-h-[20px] w-[300px] overflow-auto rounded bg-[#fff]"
                              style={{
                                zIndex: '3',
                                boxShadow:
                                  '0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 1px 8px 0px rgba(0, 0, 0, 0.05)',
                              }}
                            >
                              {filteredData?.length > 0 ? (
                                filteredData.map((item) => (
                                  <div
                                    key={item.product_code}
                                    className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3
                                    py-2 hover:bg-[#f8f8f8]"
                                    onClick={() => handleTitleClick(item)}
                                  >
                                    <div className="p-xs-regular">
                                      {item.product_category}
                                    </div>
                                    <div className="p-xs-regular-dim">
                                      {item.product_code}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="cursor-pointer flex-col gap-3 border-b border-[#EBEFF3] px-3 py-2 hover:bg-[#f8f8f8]">
                                  <div className="p-xs-regular">
                                    Category does not exist
                                  </div>
                                </div>
                              )}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    </div>
                    <div>
                      <label className="mb-1 p-sm-regular">
                        {t('product.unit_of_measure')}{' '}
                        <span className="text-[#DC3545]">*</span>
                      </label>
                      <div>
                        <div className="relative mb-3 py-1">
                          <input
                            autoComplete="off"
                            required
                            type="text"
                            name="UOM"
                            value={formData.UOM}
                            onChange={handleInputChange}
                            className={`h-[28px] w-[300px] border ${
                              errors.UOM ? 'border-[#DC3545]' : 'border-none'
                            } rounded-md  border p-2 pr-8 text-sm outline-none p-xs-regular background-input`}
                            placeholder={t(
                              'product.unit_of_measure_placeholder',
                            )}
                          />
                          {errors.UOM && (
                            <p className="pt-1 text-xs font-normal text-red">
                              {errors.UOM}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-4 flex w-[630px] justify-between">
                      <span className="p-sm-regular">
                        {t('product.consumable')}
                      </span>
                      <div>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={checkboxState.consumableChecked}
                            onChange={() =>
                              handleCheckboxChange('consumableChecked')
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="p-xs-regular-dim">
                      {t('product.consumable_subtitle')}
                    </p>

                    <div className="mt-4 flex w-[630px] justify-between">
                      <span className="p-sm-regular">
                        {t('product.bill_of_materials')}
                      </span>
                      <div>
                        <label className="switch">
                          <input
                            autoComplete="off"
                            type="checkbox"
                            checked={checkboxState.bomChecked}
                            onChange={() => handleCheckboxChange('bomChecked')}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="p-xs-regular-dim">
                      {t('product.bill_of_materials_subtitle')}
                    </p>
                    <div className="mt-4 flex w-[630px] justify-between">
                      <span className="p-sm-regular">{'Decimal'}</span>
                      <div>
                        <label className="switch">
                          <input
                            autoComplete="off"
                            type="checkbox"
                            checked={checkboxState?.shouldAcceptDecimal}
                            onChange={() =>
                              handleCheckboxChange('shouldAcceptDecimal')
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="p-xs-regular-dim">
                      {'Enable this to add decimal quantity'}
                    </p>
                  </div>
                  <div className="mt-4">
                    <label className="p-sm-regular">
                      {t('product.description')}
                    </label>
                    <div>
                      <div className="relative py-1">
                        <textarea
                          required
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          className="h-[80px] w-[630px] rounded-md border border-none bg-[#EBEFF3] p-2 pr-8 text-sm outline-none p-xs-regular"
                          placeholder={t('product.product_description')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="justify-between">
                    <label className="mb-1 p-sm-regular">
                      {t('product.product_tags')}
                    </label>
                    <div className=" w-[630px] rounded-md border border-none bg-[#EBEFF3] p-2 text-sm outline-none p-xs-regular">
                      <Chips
                        onChange={handleTagsChange}
                        value={tagsValue}
                        allowDuplicate={false}
                        variant="outlined"
                        placeholder={
                          tagsValue?.length > 0
                            ? undefined
                            : t('product.tag_description')
                        }
                      />
                    </div>
                    <p className="p-xs-regular-dim">
                      Note: Press <b>Enter</b> to create a tag{' '}
                    </p>
                  </div>
                </div>
                <div className="flex justify-end gap-2 ">
                  <Button onClick={() => navigate(-1)}>{t('cancel')}</Button>
                  <Button
                    type="submit"
                    color="success"
                    loading={isLoading}
                    onClick={handleSubmit}
                  >
                    {!checkboxState.bomChecked &&
                    !checkboxState.propertiesChecked
                      ? t('finish')
                      : t('next')}
                  </Button>
                </div>
              </div>
            )}
            {page === 2 && (
              <div className="ml-6">
                <div className="flex justify-between">
                  <h5 className="h5-1">{t('product.bill_of_materials')}</h5>
                  <Button color="primary" onClick={toggleBomPanel}>
                    <PlusIcon /> {t('product.add_material')}
                  </Button>
                </div>
                <p className="mb-7 w-2/3 p-sm-regular-1">
                  {t('product.bill_of_materials_title')}
                </p>
                <div className="mt-2">
                  <label className="mb-1 p-sm-regular">
                    {t('product.quantity')}{' '}
                    <span className="text-[#DC3545]">*</span>
                  </label>
                  <div>
                    <div className=" flex w-1/4 items-center py-1">
                      <input
                        required
                        type="number"
                        name="bom_quantity"
                        value={formData.bom_quantity}
                        min={0}
                        onChange={handleInputChange}
                        className={`h-[28px] w-full border ${
                          errors.bomQuantity
                            ? 'border-[#DC3545]'
                            : 'border-none'
                        } rounded-l-md bg-[#EBEFF3] p-2 text-sm outline-none p-xs-regular`}
                        placeholder={t('product.enter_quantity')}
                      />
                      <div className="flex h-7 items-center truncate rounded-r-md bg-[#434C52] px-2 text-[#F8FCFF] p-xs-regular-dim1">
                        {formData?.UOM}
                      </div>
                    </div>
                    {errors.bomQuantity && (
                      <p className="pt-1 text-xs font-normal text-[#DC3545]">
                        {errors.bomQuantity}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-2 w-full flex-col">
                  <PaginatedTable
                    columns={BOMColumns}
                    emptyMessage={
                      "Add properties by clicking on '+ Add Property'"
                    }
                    rows={
                      (bomDetails && bomDetails[0]?.bill_of_materials) || []
                    }
                    pageSize={filters.page_size}
                    onSingleClick={() => {}}
                    onPageNumberChange={() => {}}
                    loading={productBomLoading}
                    hidePagination={true}
                  />
                </div>
                <div className="mt-2 flex justify-end gap-2  ">
                  <Button onClick={handlePrevious}>
                    {t('product.previous')}
                  </Button>
                  <Button
                    color="success"
                    onClick={handleSubmission}
                    loading={isEditProductLoading || BomQuantityLoading}
                  >
                    {' '}
                    {!checkboxState.propertiesChecked ? t('finish') : t('next')}
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div style={{ overflowY: 'auto' }}>
            <SidePanel
              isOpen={showPanel}
              title={'New Property'}
              onClose={() => {
                setSelectedEntry(null)
                setShowPanel(false)
              }}
            >
              <AddProperty
                show={showPanel}
                removeEntry={togglePanel}
                addTableData={addTableData}
                tableData={tableData}
              />
            </SidePanel>

            <SidePanel
              isOpen={editPanel}
              title={'Edit Property'}
              onClose={() => {
                setSelectedEntry(null)
                setEditPanel(false)
              }}
            >
              <EditProperty
                show={editPanel}
                data={selectedEntry}
                editTableData={editTableData}
                toggleDelete={toggleDelete}
                removeEntry={toggleEditPanel}
              />
            </SidePanel>

            <Popup
              isOpen={deletPopup}
              title={'Remove Entry?'}
              onClose={() => {
                setSelectedEntry(null)
                setDeletePopup(false)
              }}
            >
              <DeleteProperty
                entryToDelete={entryToDelete}
                show={deletPopup}
                toggleDelete={toggleDelete}
                deleteData={deleteTabledata}
              />
            </Popup>

            <SidePanel
              isOpen={showBomPanel}
              title={t('product.new_material')}
              onClose={() => {
                setSelectedEntry(null)
                setShowBomPanel(false)
              }}
            >
              <AddBom
                removeEntry={toggleBomPanel}
                productId={productId}
                isEdit={false}
              />
            </SidePanel>

            <SidePanel
              isOpen={editBomPanel}
              title={t('product.edit_material')}
              onClose={() => {
                setSelectedEntry(null)
                setEditBomPanel(false)
              }}
            >
              <AddBom
                data={selectedEntry}
                removeEntry={() => toggleBomEditPanel}
                isEdit={true}
                productId={productId}
              />
            </SidePanel>

            <Popup
              isOpen={deletBomPopup}
              title={'Remove Entry?'}
              onClose={() => {
                setSelectedEntry(null)
                setDeletePopup(false)
              }}
            >
              <DeleteBom
                entryToDelete={entryToDelete}
                show={deletBomPopup}
                toggleDelete={toggleBomDelete}
                deleteData={deleteBomData}
              />
            </Popup>
          </div>
          <div
            className={
              showPanel || editPanel
                ? 'sideNav-overlay-dark'
                : 'sideNav-overlay'
            }
          ></div>
        </div>
      </>
    </MotionDiv>
  )
}

export default NewProducts
