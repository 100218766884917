import Button from 'libs/button/Button'
import PaginatedTable from 'libs/table/PaginatedTable'
import { useGetMachineByIDQuery } from '../../machine-endpoints/machine.endpoints'
import { selectSelectedItem } from 'store/redux/navSlice'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import Documents from '../../popup/Documents'
import DocumentPopup from 'libs/popup/DocumentPopup'

interface ImageData{
  fileName: string
  url: string
  description: string
}

interface PopUpState {
  isPopUpOpen: boolean;
  imageData: ImageData;
}

const ViewDocuments = () => {
  const plant = useSelector(selectSelectedItem)
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [popUp, setPopUp] = useState<PopUpState>({ isPopUpOpen: false, imageData: { fileName: '', url: '', description: '' } })

  const { data: machineData, isLoading: isMachineDataLoading } = useGetMachineByIDQuery(
    {
      plantId: plant?.id,
      machineID: params?.id,
    },
    {
      skip: !plant?.id || !params?.id,
    },
  )

  const column = [
    {
      Header: 'Documents',
      accessor: 'requestID',
      width: 100,
      Cell: ({ row }: any) => {
        return (
          <div
            className="hyperlink"
            onClick={(event) => {
              event.stopPropagation()
              row?.original?.url && setPopUp({ isPopUpOpen: true, imageData: row?.original })
            }}
          >
            {row?.original?.fileName ?? '-'}
          </div>
        )
      },
      disableSortBy: true,
    },
    {
      Header: <div className="flex w-full items-center justify-end">Document Description</div>,
      accessor: 'description',
      width: 100,
      Cell: ({ row }: any) => {
        return <div>{row?.original?.description ?? '-'}</div>
      },
      disableSortBy: true,
    },
  ]

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="items-center text-xl font-medium text-brand-primary-shade-3">Documents</h1>
        <Button color="primary" onClick={()=> navigate(`/maintenance/machines/${machineData?._id}/edit/documents`, {state: {from: location}})}>Manage Document</Button>
      </div>
      <div className="py-3">
        <PaginatedTable
          columns={column}
          rows={machineData?.documents}
          loading={isMachineDataLoading}
          hidePagination
          emptyMessage="No document has been added. Please add the document."
        />
      </div>
      <DocumentPopup
        title={popUp?.imageData?.fileName ?? '-'}
        download={popUp?.imageData?.url}
        isOpen={popUp.isPopUpOpen}
        onClose={() => {
          setPopUp({ isPopUpOpen: false, imageData: { fileName: '', url: '', description: '' } })
        }}
      >
        <Documents url={popUp.imageData?.url} />
      </DocumentPopup>
    </>
  )
}
export default ViewDocuments
