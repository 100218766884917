import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import MoreOptionsSelect from 'libs/field/components/MoreOptionsSelect'
import QuantityTimeGraph from './QuantityTimeGraph'
import Field from 'libs/field'
import { useFormik, FormikProps } from 'formik'

import {
  useGetWorkOrderChartByPlantIdQuery,
  useGetWorkOrdersBarStatusQuery,
} from 'pages/work-orders/work-orders-endpoints/workorders.endpoints'
import { useSelector } from 'react-redux'
import { selectSelectedItem } from 'store/redux/navSlice'
import PieCharts from 'libs/charts/PieChart'
import BarGraph from 'libs/charts/BarGraph'
import FullPageLoader from 'libs/loader/FullPageLoader'
import { useGetManufactureOrdersBarStatsQuery } from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'
import { useGetManufactureOrdersOnTimeChartQuery } from 'pages/manufacture-orders/manufacture-orders-endpoints/manufacture_order.endpoints'
import useTabTitle from 'libs/hooks/useTabTitle'
import {
  usePutUserPreferencesMutation,
  useUserInfoQuery,
} from 'pages/settings/user-management/users/user-endpoints/user.endpoint'

interface Widget {
  id: string
  title: string
  component: React.ComponentType<any>
  apiData?: any
}
interface FormValues {
  dateRange: number[] | null
}

const Widgets: React.FC = () => {
  const { data: userInfo } = useUserInfoQuery({})
  const [updateUserPreference] = usePutUserPreferencesMutation()

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: { dateRange: null },
    onSubmit: () => {
      const today = new Date()
      today.setHours(23, 59, 59, 999)
      const pastDate = new Date(today)
      pastDate.setDate(today.getDate() - 29)
      pastDate.setHours(0, 0, 0, 0)
      const secondsRange = [pastDate.getTime(), today.getTime()].map((ms) => Math.floor(ms / 1000))
      formik.setFieldValue('dateRange', secondsRange)
    },
  })
  useTabTitle('Dashboard')

  const plantId = useSelector(selectSelectedItem)
  const { data: workOrderChartData, isLoading: chartDataLoading } = useGetWorkOrderChartByPlantIdQuery(
    { plant_id: plantId?.id, filters: formik.values },
    { skip: !plantId?.id },
  )
  const {
    data: workOrder,
    isLoading: statusDataLoading,
    isFetching: statusDataFetching,
  } = useGetWorkOrdersBarStatusQuery({ plantId: plantId?.id, filters: formik.values }, { skip: !plantId?.id })
  const { data: manufactureOrderChartData, isLoading: manufactureChartDataLoading } =
    useGetManufactureOrdersOnTimeChartQuery({ plantId: plantId?.id, filters: formik.values }, { skip: !plantId?.id })
  const { data: manufactureOrderStatus, isLoading: MoStatusDataLoading } = useGetManufactureOrdersBarStatsQuery(
    { plantId: plantId?.id, filters: formik.values },
    { skip: !plantId?.id },
  )

  const [widgets, setWidgets] = useState<Widget[]>([])
  const [widgetData, setWidgetData] = useState<Record<string, any>>({})

  useEffect(() => {
    if (workOrder && workOrderChartData && manufactureOrderStatus && manufactureOrderChartData) {
      const workOrderChart = [
        { name: 'On-Time', value: workOrderChartData?.onTime },
        { name: 'Delayed', value: 100 - workOrderChartData?.onTime },
      ]
      const manufactureOrderChart = [
        { name: 'On-Time', value: manufactureOrderChartData?.onTime },
        { name: 'Delayed', value: 100 - manufactureOrderChartData?.onTime },
      ]

      const defaultWidgets: Widget[] = [
        { id: 'barGraph', title: 'Work Orders Status', component: BarGraph, apiData: workOrder },
        {
          id: 'workOrderPieChart',
          title: 'On-Time Work Orders',
          component: PieCharts,
          apiData: workOrderChart,
        },
        {
          id: 'manufactureOrderBarGraph',
          title: 'Manufacture Orders Status',
          component: BarGraph,
          apiData: manufactureOrderStatus,
        },
        {
          id: 'manufactureOrderPieChart',
          title: 'On-Time Manufacture Orders',
          component: PieCharts,
          apiData: manufactureOrderChart,
        },
        { id: 'quantityTimeGraph', title: 'Products Quantity Over Time', component: QuantityTimeGraph },
      ]

      if (userInfo?.preferences) {
        const orderedWidgets =
          (userInfo?.preferences?.chart
            ?.map((id: string) => defaultWidgets?.find((widget) => widget.id === id))
            ?.filter(Boolean) as Widget[]) || []
        setWidgets(orderedWidgets)
      }
    }
  }, [workOrder, workOrderChartData, userInfo, manufactureOrderChartData, manufactureOrderStatus])

  useEffect(() => {
    if (widgets?.length > 0) {
      const updatedWidgetData = widgets.reduce(
        (acc, widget) => {
          acc[widget.id] = widget.apiData
          return acc
        },
        {} as Record<string, any>,
      )
      setWidgetData(updatedWidgetData)
    }
  }, [widgets, workOrder, workOrder, workOrderChartData, userInfo, manufactureOrderChartData, manufactureOrderStatus])

  const [movingWidgetId, setMovingWidgetId] = useState<string | null>(null)

  const saveWidgetOrder = async (newOrder: string[]) => {
    if (!Array.isArray(newOrder)) {
      console.error('Invalid widget order:', newOrder)
      return
    }

    const preferencesObject = newOrder.reduce(
      (acc, widgetId, index) => {
        acc[index + 1] = widgetId
        return acc
      },
      {} as Record<string, string>,
    )

    try {
      await updateUserPreference({ data: { pref: preferencesObject } }).unwrap()
    } catch (error) {
      console.error('Failed to save widget order', error)
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const reorderedWidgets = Array.from(widgets)
    const [movedWidget] = reorderedWidgets.splice(result.source.index, 1)
    reorderedWidgets.splice(result.destination.index, 0, movedWidget)

    setWidgets(reorderedWidgets)
    setMovingWidgetId(null)
    saveWidgetOrder(reorderedWidgets.map((widget) => widget.id))
  }

  const handleRemove = (index: number) => {
    const updatedWidgets = widgets.filter((_, idx) => idx !== index)
    setWidgets(updatedWidgets)
    saveWidgetOrder(updatedWidgets.map((widget) => widget.id))
  }

  const handleMove = (id: string) => {
    setMovingWidgetId(id)
  }

  const loading =
    chartDataLoading || statusDataLoading || manufactureChartDataLoading || MoStatusDataLoading || statusDataFetching

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="my-2 text-lg font-bold text-[#041220]">Widgets</h1>
        <div className="m-2">
          <div className="my-2 ml-32 w-52">
            <Field type="dateRange" formik={formik} name="dateRange" shouldDisableFutureDate={true} />
          </div>
          <h1 className="ml-6 text-xs text-gray-600">
            Showing data for custom date range.{' '}
            <span
              className={
                Array.isArray(formik.values.dateRange) && formik.values.dateRange.length > 1 ? '' : 'hyperlink'
              }
              onClick={() => {
                formik.handleSubmit()
              }}
            >
              Reset to 30 days.
            </span>
          </h1>
        </div>
      </div>
      {loading ? (
        <FullPageLoader />
      ) : widgets?.length === 0 ? (
        <div className="w-1/3 rounded-lg bg-white px-4 py-2 text-center">
          <h1 className="my-2 text-base text-brand-primary-shade-3">No widgets added yet</h1>
          <h1 className="mb-4 text-sm text-gray-600">
            Add widgets to your dashboard to personalize and track the data that's important to you. Go to the Reports
            page and add widgets by clicking on the <span className="p-sm-bold-dark">"Add to Dashboard"</span> link.
          </h1>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="widgets" direction="horizontal">
            {(provided) => (
              <div className="mt-2 grid grid-cols-3 gap-2" {...provided.droppableProps} ref={provided.innerRef}>
                {widgets?.map((widget, index) => {
                  const Component = widget.component
                  return (
                    <Draggable
                      key={widget.id}
                      draggableId={widget.id}
                      index={index}
                      isDragDisabled={movingWidgetId !== widget.id}
                    >
                      {(provided) => (
                        <div
                          className="rounded-lg bg-white p-2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...(movingWidgetId === widget.id ? provided.dragHandleProps : {})}
                        >
                          <div className="flex items-center justify-between">
                            <h1 className="p-2 text-base font-bold">{widget.title}</h1>
                            <MoreOptionsSelect
                              onMove={() => handleMove(widget.id)}
                              onRemove={() => handleRemove(index)}
                            />
                          </div>
                          <Component apiData={widgetData[widget.id]} formik={formik} />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  )
}

export default Widgets
