
function Production({ color }: { color: any }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <mask
        id="mask0_2450_35905"
        style={{ maskType: 'alpha' }}
        width="20"
        height="20"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z"></path>
      </mask>
      <g mask="url(#mask0_2450_35905)">
        <path
          fill={color ? `${color}` : '#75838D'}
          d="M4.167 17.5a2.411 2.411 0 01-1.771-.73 2.41 2.41 0 01-.73-1.77c0-.694.244-1.285.73-1.77a2.411 2.411 0 011.77-.73h11.667c.695 0 1.285.243 1.771.73.486.485.73 1.076.73 1.77s-.244 1.285-.73 1.77a2.411 2.411 0 01-1.77.73H4.166zm0-1.667h11.666c.236 0 .434-.08.594-.24.16-.159.24-.357.24-.593a.806.806 0 00-.24-.594.806.806 0 00-.594-.24H4.167a.806.806 0 00-.594.24.807.807 0 00-.24.594c0 .236.08.434.24.594.16.16.358.24.594.24zm4.166-5a.806.806 0 01-.593-.24A.807.807 0 017.5 10V3.333c0-.236.08-.434.24-.593.16-.16.357-.24.593-.24H15c.236 0 .434.08.594.24.16.16.24.357.24.593V10c0 .236-.08.434-.24.594a.806.806 0 01-.594.24H8.333zM2.458 9.125a.748.748 0 01-.562-.24.8.8 0 01-.23-.572c0-.223.08-.41.24-.563a.8.8 0 01.573-.23h2.5c.222 0 .41.077.563.23.152.153.229.34.229.563a.8.8 0 01-.23.572.748.748 0 01-.562.24H2.46zm8.375-2.458h1.688c.236 0 .43-.08.583-.24a.826.826 0 00.23-.594.79.79 0 00-.23-.583.79.79 0 00-.583-.23h-1.709a.79.79 0 00-.583.23.79.79 0 00-.229.583c0 .236.08.434.24.594.16.16.357.24.593.24zm-6.687 0a.772.772 0 01-.584-.24.826.826 0 01-.229-.594c0-.236.08-.43.24-.583a.826.826 0 01.594-.23h.791a.79.79 0 01.584.23.79.79 0 01.229.583.826.826 0 01-.23.594.772.772 0 01-.583.24h-.812z"
        ></path>
      </g>
    </svg>
  )
}

export default Production

