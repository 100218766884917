import { useDeleteWorkOrderMutation } from '../work-orders-endpoints/workorders.endpoints'
import Button from '../../../libs/button/Button'
import { t } from 'i18next'

import { toast } from 'libs/toast'
import { useNavigate } from 'react-router-dom'

interface Props {
  closePopUp: any
  workOrder: any
  plantId: string
}

function DeleteWorkOrder({ closePopUp, workOrder, plantId }: Props) {
  const [deleteWorkOrder, { isLoading: deleteWorkOrderLoading }] = useDeleteWorkOrderMutation()

  const navigate = useNavigate()

  const handleDeleteWorkOrder = () => {
    deleteWorkOrder({ plantId, woID: workOrder?._id })
      .unwrap()
      .then(() => {
        toast.success(`Work Order “${workOrder?.workOrderID}” is removed successfully.`)
        navigate('/planning/work-orders')
        closePopUp()
      })
      .catch((error: any) => {
        toast.error(error?.data?.detail ?? `Failed to remove Work Order “${workOrder?.workOrderID}”. Please try again.`)
      })
  }

  return (
    <div>
      <p className="p-xs-regular">
        {t('you_are_about_to_delete_a')} <span className="break-words pr-1 p-xs-bold">“{workOrder?.workOrderID}”.</span>
        {t('are_you_sure_you_want_to_proceed')}
      </p>

      <div className=" mt-4 flex justify-end gap-3">
        <Button onClick={closePopUp}>{t('inventory_.close')}</Button>
        <Button color="error" onClick={handleDeleteWorkOrder} loading={deleteWorkOrderLoading}>
          {t('inventory_.delete')}
        </Button>
      </div>
    </div>
  )
}

export default DeleteWorkOrder
